.dk-pipe-profileind-Dropdown {
    .dk-pipe-profileind-Dropdown-toggle {
        box-shadow: none;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #ddd;
        margin: 0;
        padding: 0;
        position: relative;

        &::after {
            display: none;
        }

        i {
            font-size: 16px;
        }

        .crossUserFilter.dk-lrftCross {
            position: absolute;
            right: -7px;
            top: -8px;
        }
    }

    .dk-pipe-profileind-DropdownMenu {
        padding: 0;
        transition: inherit !important;
        position: absolute !important;
        left: 45px !important;
        top: -55px !important;
        border: 1px solid #234158;
        background: #234158;

        &:after {
            border-color: transparent #234158 transparent transparent;
            _border-color: #000 #234158 #000 #000;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            content: "";
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            height: 0;
            left: -12px;
            line-height: 0;
            position: absolute;
            top: 24px;
            width: 0;
        }

        .d-flex {
            .dk-profileind-DropdownItem {
                display: inline-block;
                margin: 0 3px;
                padding: 8px 5px !important;
                width: 35px;

                i {
                    width: 100%;
                    text-align: center;
                    font-size: 1.23rem;
                }

                .proflieCount {
                    color: #fff;
                    display: block;
                    font-size: 12px;
                    padding: 4px 0 0;
                    text-align: center;
                    width: 100%;
                    font-size: 1rem;
                }
            }
        }
    }
}