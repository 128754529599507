.dk-candidateProfile80{
    &.dkg-edit-livejobs-Modal-234{
        min-width: 600px ;
        max-width: 600px ;
        .modal-content{
            border-radius: 0;
            .modal-header.dk-candModalHeader{
                border-radius: 0;
                padding: 10px 15px  !important;
            }
            .dkg-editjobs-modalbody{
                background-color: #eaeaea;
                padding: 15px;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dkg-editjobsRow{
                    .dkg-editjobsCol{
                        .dkg-editjobs-formgrp{
                            display: flex;
                            align-self: center;
                            margin-bottom: 1.25rem;
                            .dkg-editjob-label{
                                font-size: 13px;
                                margin-bottom: 0;
                                min-width: 135px;
                                display: flex;
                                align-items: center;
                            }
                            .dkg-editjob-input{
                                border: 1px solid #ddd;
                                text-align: center;
                            }
                            .dkg-editjobs-dropdownCon{
                                width: 100%;
                                .dkg-editjobs-dropdown{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    .dkg-editjobs-dropdown-toggle{
                                        box-shadow: none;
                                        background-color: #fff;
                                        padding: 7px 10px;
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-around;
                                        align-items: center;
                                        text-transform: capitalize;
                                        font-size: 13px;
                                        color: gray;
                                        margin-left: 0;
                                        margin-right: 0;
                                        border: 1px solid #ddd;
                                        margin: 0;
                                        &:after {
                                            color: gray;
                                            display: inline-block;
                                            font-size: 16px;
                                            position: absolute;
                                            right: 11px;
                                            top: 16px;
                                        }
                                    }
                                    .dkg-editjobs-dropdownMenu{
                                        border-radius: 0;
                                        padding: 0;
                                        width: 100%;
                                        overflow-y: auto;
                                        max-height: 220px;
                                        .dkg-editjobs-dropdown-item{
                                            border-bottom: 1px solid #ddd;
                                            padding: 5px 7px;

                                            &:last-child {
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-editjobs-btnCon{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                div{

                                    div{
                                        .dkg-editjob-btn{
                                            background-color: #26597d;
                                            &:hover{
                                                color: #fff;
                                                opacity: 0.8;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}