.dk-useFilterNumCon {
    .dropdown {
        display: flex;
        justify-content: center;
        align-items: center;

        button.dk-userNumfliterbtn.dropdown-toggle.btn {
            box-shadow: none;
            padding: 0 !important;
            margin: 0;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            line-height: 30px;
            background-color: #eaeaea;
            border: 1px solid #ddd;

            i {

                font-size: 14px;
            }
        }

        button.dk-userNumfliterbtn.dropdown-toggle::after {
            display: none;
        }

        .dk-userNumfliterMenu.dropdown-menu {
            padding: 5px;
            left: 25px !important;
            top: -15px !important;
            transform: translate(22px, -5px) !important;
            -webkit-transform: translate(22px, -5px) !important;
            background-color: #234158;

            a.dropdown-item {
                padding: 0;
                display: inline-block;
                margin: 0 5px;

                img {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    display: block;

                }

                .recNoCount {
                    color: #fff;
                    font-size: 12px;
                    padding: 4px 0 0;
                    display: block;
                    width: 100%;
                    text-align: center;
                }
            }

            a.dropdown-item:hover {
                .recNoCount {
                    color: #333;
                }
            }
        }

        .dk-userNumfliterMenu.dropdown-menu:after {
            content: "";
            width: 0;
            height: 0;
            border-color: transparent #234158 transparent transparent;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            line-height: 0;
            _border-color: #000 #ccc #000 #000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            position: absolute;
            left: -12px;
            top: 28px;
        }
    }
}