.dkg-todayads-reorderModal {
    .modal-dialog {
        min-width: 40%;

        .modal-content {
            border-radius: 5px;
            box-shadow: none;
            border: none;

            .dkg-todayads-reorderModalheader {
                padding: 10px 15px;
                background: #d9d9d9;
                border-radius: 5px 5px 0 0;

                .modal-title {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                    color: #333;
                }

                .close {
                    padding: 0;
                    color: #333;
                    opacity: 1;
                    text-shadow: none;
                    font-size: 20px;
                    position: absolute;
                    right: 28px;
                    top: 28px;
                    outline: 0;
                }
            }

            .dkg-todayads-reorderModalBody {
                ul {
                    height: calc(100vh - 340px);
                    overflow-y: auto;

                    .Mukul {
                        .dk-valueFields {
                            border: 1px solid #ddd;
                            border-bottom: none;

                            .dk-handle {
                                border-bottom: none;
                            }

                            input {
                                border-bottom: none !important;
                            }
                        }

                        &:last-child {
                            .dk-valueFields {
                                border-bottom: 1px solid #ddd;
                            }
                        }
                    }
                }

                .dk-res-reorder-save-btnCon {
                    div {
                        div {
                            .dk-toolsPanelSaveBtn {
                                display: inline;
                                background: #26597d !important;
                                border: none;
                                color: #fff;
                                text-transform: uppercase;
                                padding: 6px 10px;
                                font-weight: 500;
                                font-size: 14px;
                                outline: none;
                                cursor: pointer;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}