.dk-dailyTrackTopLeft {
    margin-top: 10px;
    display: flex;
}

.dk-dailyTrackTopLeft .dk-dateTimeDay {
    border: 1px solid #ccc;
    padding: 6px 8px;
    font-size: 14px;
    background: #f2f2f2;
    min-width: 190px;
    max-width: 190px;
}

.dk-dailyTrackTopLeft .dk-dateTimeDay span.dk-calIcon {
    font-size: 16px;
    margin-left: 16px;
}

.dk-dailyTrackTopLeft .dk-dailytopIcon {
    background-color: #eaeaea;
    border: 1px solid #ddd;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dk-dailyTrackTopLeft .dk-dailytopIcon i {
    color: #333;
}

.dk-dailyTrackTopLeft .dk-appstrackTableSearch .input-group {
    width: 100%;
    max-width: 150px;
    margin-right: 8px;
}

.dk-dailyTrackTopLeft .dk-appstrackTableSearch .input-group input {
    border: 1px solid #ddd;
    background-color: #eaeaea;
    padding: 0 10px;
    box-shadow: none;
    border-radius: 0;
    height: 35px;
}

.dk-dailyTrackTopLeft .dk-appstrackTableSearch .input-group .input-group-append button {
    padding: 0.35rem 0.65rem;
    margin: 0;
    background-color: #eaeaea !important;
    color: #808080;
    box-shadow: none;
    border: 1px solid #ddd;
    position: relative;
    top: 0;
}

.dk-dailyTrackTableCon {
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden !important;
    height: calc(100vh - 165px);
    padding-bottom: 0;
    position: relative;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable {
    margin-bottom: 0;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable thead tr th:first-child {
    /* padding: 0 !important;
    border: none;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row; */
    color: #333;
    padding-left: 0;
}

.dk-dailyappsTableSearch .input-group {
    min-width: 175px;
    max-width: 175px;
    width: 100%;
    margin-right: 8px;
    background-color: #fff;
    border: 1px solid #ddd;
    height: 35px;
}

.dk-dailyappsTableSearch .input-group input {
    border: none;
    background-color: #eaeaea;
    padding: 0 10px;
    box-shadow: none;
    border-radius: 0;
}

.dk-dailyappsTableSearch .input-group {
    position: relative;
}

.dk-dailyappsTableSearch .input-group .input-group-append button.btn {
    position: absolute;
    right: 1px;
    top: 2px;
    padding: .35rem .65rem;
    margin: 0;
    background-color: #eaeaea !important;
    color: #808080;
    box-shadow: none;
    border: 1px solid transparent;
    border-left: none;

}

.dk-dailyappsTableSearch .input-group .input-group-append button.btn:hover {
    box-shadow: none;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable thead tr th {
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 1px 9px;
    /* background: #2C394B;
    color: #e2dada;
    border: 1px solid #616167; */
    border: 1px solid #999;
    background-color: #eaeaea;
    min-width: 100px;
    max-width: 100px;
    position: sticky;
    top: 0;
    z-index: 99;
    cursor: text;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable thead tr th:last-child {
    border: 1px solid #616767;
    background-color: transparent;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable thead tr th img.dk-dailytablehimg {
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable thead tr th:last-child {
    /* background: #2C394B; */
    background-color: #eaeaea;
    color: #333;
    vertical-align: middle;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable tbody tr td {
    background: #252c37;
    color: #bdb4b4;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    padding: 7px 15px;
    border: 1px solid #878080;
    vertical-align: middle;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable tbody tr td:first-child {
    margin-left: 10px;
}

.dk-appdailyplusIcon {
    background-color: #eaeaea;
    border: 1px solid #ddd;
    min-width: 36px;
    min-height: 35px;
    display: inline-block;
    line-height: 35px;
}

.dk-dailyTrackTableCon .dk-dailyTrackTable tfoot tr td {
    position: sticky;
    bottom: 0;
    background: #3a3a52;
    color: #e2dada;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 15px !important;
    border: 1px solid #616767;
    vertical-align: middle;
    border: none;
}