// .dk-dbarchiveMain {
//     margin-top: 160px;

//     .dk-dbarchivePageHead {
//         .dk-dbarchiveLeft {
//             display: flex;
//             align-items: center;

//             .dk-mailshotsSearch {
//                 .input-group {
//                     width: 100%;
//                     max-width: 140px;

//                     input {
//                         padding: 0.5rem 0.55rem;
//                         background-color: #eaeaea !important;
//                         margin: 0;
//                         box-shadow: none;
//                         border: 1px solid #ddd;
//                         line-height: 1.3rem;
//                         height: 35px;
//                         border-radius: 0;
//                     }

//                     .input-group-append {
//                         margin-left: -1px;

//                         button {
//                             padding: 0.5rem 0.55rem;
//                             background-color: #eaeaea !important;
//                             margin: 0;
//                             box-shadow: none;
//                             border: 1px solid #ddd;
//                             border-left: none;
//                             line-height: 1.3rem;
//                             height: 35px;
//                             border-radius: 0;

//                             i {
//                                 color: #6c757d;
//                             }
//                         }
//                     }
//                 }
//             }

//             button.dk-cricleBtn {
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 width: 35px;
//                 height: 35px;
//                 line-height: 35px;
//                 background: #eaeaea !important;
//                 color: #333;
//                 border: 1px solid #ddd;
//                 border-radius: 50%;
//                 outline: none;
//                 box-shadow: none;

//                 i.fa.fa-filter {
//                     font-size: 12px;
//                     margin-top: 3px;
//                 }

//                 i {
//                     margin-top: 3px;
//                 }
//             }
//         }

//         .dk-dbarchiveCetre {
//             h2 {
//                 font-size: 23px;
//                 text-transform: uppercase;
//                 text-align: center;
//                 font-weight: 700;
//                 margin-top: 5px;
//             }
//         }

//         .dk-pipelineright {
//             display: flex;
//             align-items: center;
//             justify-content: flex-end;

//             .dk-btnCicleCon {
//                 display: flex;

//                 button {
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     width: 35px;
//                     height: 35px;
//                     line-height: 35px;
//                     background: #eaeaea !important;
//                     color: #333;
//                     border: 1px solid #ddd;
//                     border-radius: 50%;
//                     outline: none;
//                     box-shadow: none;
//                 }
//             }

//             .dk-qulaifyRightFilter {

//                 .ui.selection.dropdown.dk-overdues-task-slect,
//                 .ui.selection.dropdown.dk-overdues-task-slect:hover {
//                     border-radius: 0;
//                     min-width: 135px;
//                     background-color: #eaeaea !important;
//                     border: 1px solid #ddd;

//                     .dropdown.icon {
//                         top: 0.8581em;
//                         color: #605a5a;
//                     }
//                 }

//                 .ui.selection.dropdown .menu {
//                     border-radius: 0;
//                     min-width: 200px;
//                     min-height: 16rem;
//                     position: absolute;
//                     right: 0;
//                     left: inherit;
//                     overflow: inherit;

//                     .item {
//                         font-size: 0.9125rem;
//                         min-height: 33px;
//                         line-height: 19px;

//                         span.description {
//                             border-radius: 0;
//                             font-size: 0.8125rem;
//                             background: #3a506b;
//                         }
//                     }

//                     .active.item {
//                         font-weight: normal;
//                     }

//                     .item:hover {
//                         background-color: #eaeaea !important;
//                         color: #333 !important;
//                     }
//                 }

//                 .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
//                 .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
//                     font-size: 14px;
//                     color: #605a5a;
//                 }
//             }
//         }
//     }

//     .dk-dbarchiveTableCon {
//         margin-top: 10px;
//         overflow-y: auto;
//         overflow-x: auto;
//         height: calc(100vh - 240px);
//         padding-bottom: 0;
//         position: relative;

//         .dk-dbarchiveTable {
//             margin-bottom: 0;

//             thead tr th {
//                 background: #485569;
//                 color: #fff;
//                 font-size: 13px;
//                 text-align: center;
//                 white-space: nowrap;
//                 padding: 9px 15px;
//                 border: 1px solid #ccc;
//                 border-bottom: none;
//                 vertical-align: middle;
//                 position: sticky;
//                 top: 0;
//                 border-top: none;
//                 z-index: 9;

//                 &:nth-child(07) {
//                     min-width: 210px;
//                     max-width: 210px;
//                     white-space: nowrap;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                 }
//             }

//             tbody tr td {
//                 background: #fff;
//                 color: #333;
//                 font-size: 13px;
//                 font-weight: 400;
//                 text-align: center;
//                 white-space: nowrap;
//                 padding: 2px 15px;
//                 border-left: 1px solid #ddd;
//                 vertical-align: middle;
//                 text-transform: capitalize;

//                 &:nth-child(07) {
//                     min-width: 210px;
//                     max-width: 210px;
//                     white-space: nowrap;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                 }

//                 &.caseDiv div {
//                     color: #fff;
//                     cursor: pointer;
//                     padding: 0 15px;
//                     height: 37px;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                 }

//                 .pipleFilterCols {
//                     display: flex;

//                     .dk-chevron-rightIcon {
//                         margin-right: 8px;

//                         i {
//                             background: #297483;
//                             padding: 5px;
//                             text-align: center;
//                             border-radius: 2px;
//                             color: #fff;
//                             margin-right: 8px;
//                             cursor: pointer;
//                         }
//                     }

//                     .dk-checkinputIcon {
//                         margin-left: 10px;
//                         margin-right: 10px;
//                         position: relative;

//                         input[type="checkbox"] {
//                             position: absolute;
//                             top: 5px;
//                             cursor: pointer;
//                             -webkit-appearance: none;
//                             appearance: none;
//                             background: #fff;
//                             border-radius: 1px;
//                             box-sizing: border-box;
//                             width: 14px;
//                             height: 14px;
//                             border-width: 0;
//                             transition: all 0.3s linear;
//                             border: solid #ccc 1px;
//                             z-index: 0;
//                         }
//                     }

//                     .openFlagOptions {
//                         margin-left: 10px;
//                         cursor: pointer;
//                         margin-top: 4px;

//                         i {
//                             color: #d0cece;
//                         }
//                     }

//                     .openFlagOptions {
//                         margin-left: 0;

//                         .dk-pipeFlagFilterCon.dk-circleDropdown {
//                             .dropdown {
//                                 display: inline-block;
//                                 margin-right: 4px;

//                                 .dropdown-toggle {
//                                     display: inline-block;
//                                     width: 16px;
//                                     height: 16px;
//                                     background: #d3d3d3;
//                                     border-radius: 50%;
//                                     position: relative;
//                                     bottom: 2px;
//                                     margin: 0 !important;
//                                     padding: 0;
//                                     box-shadow: none;
//                                     outline: none;

//                                     &::after {
//                                         display: none;
//                                     }
//                                 }

//                                 .dropdown-menu {
//                                     padding: 6px 3px;
//                                     min-width: fit-content;
//                                     width: 100%;
//                                     transform: inherit !important;
//                                     top: -7px !important;
//                                     left: 30px !important;
//                                     right: inherit !important;

//                                     &::after {
//                                         content: "";
//                                         width: 0;
//                                         height: 0;
//                                         border-color: transparent #ccc transparent transparent;
//                                         border-style: solid;
//                                         border-width: 8.5px 12px 8.5px 0;
//                                         line-height: 0;
//                                         _border-color: #000 #ccc #000 #000;
//                                         _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
//                                         position: absolute;
//                                         left: -12px;
//                                         top: 6px;
//                                     }

//                                     a {
//                                         display: inline-block;
//                                         width: 16px;
//                                         height: 16px;
//                                         border-radius: 50%;
//                                         margin: 0 3px;
//                                     }

//                                     a.gray {
//                                         background: #d3d3d3;
//                                     }

//                                     a.yellow {
//                                         background: #d9d933;
//                                     }

//                                     a.red {
//                                         background: #ff0000;
//                                     }

//                                     a.green {
//                                         background: #008000;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 .dk-tableImgCricle {
//                     img {
//                         min-height: 22px;
//                         border-radius: 50%;
//                         height: 30px;
//                         width: 30px;
//                     }
//                 }

//                 .commentBoxCon {
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;

//                     .numCon {
//                         width: 25px;
//                         height: 25px;
//                         background-color: #eaeaea;
//                         border-radius: 50%;
//                         line-height: 25px;
//                         cursor: pointer;
//                     }
//                 }
//             }

//             tbody tr td:nth-child(06) {
//                 text-align: left;

//                 &:hover {
//                     color: #2c71b2;
//                     cursor: pointer;
//                 }
//             }

//             tbody tr td:nth-child(09),
//             tbody tr td:nth-child(10),
//             tbody tr td:nth-child(11) {
//                 background-color: #5c737d;
//                 color: #fff;
//                 cursor: pointer;
//                 padding: 0;
//             }

//             tbody tr:last-child td {
//                 border-bottom: 1px solid #808080;
//             }

//             tfoot.newCVtFoot {
//                 tr td {
//                     background: #fff;
//                     color: #333;
//                     font-size: 14px;
//                     text-align: center;
//                     white-space: nowrap;
//                     padding: 9px 15px !important;
//                     vertical-align: middle;
//                     border: none;
//                     z-index: 1;

//                     &:nth-child(14) {
//                         border-left: 1px solid #ddd;
//                     }

//                     &:nth-child(14),
//                     &:nth-child(15),
//                     &:nth-child(16),
//                     &:nth-child(17) {
//                         border-right: 1px solid #ddd;
//                     }
//                 }
//             }
//         }
//     }
// }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 40%;
        max-width: 40%;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 44%;
        max-width: 44%;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 44%;
        max-width: 44%;
    }
}