.dkg-searchdb-page-mainCon {
    // background-color: #F3F6F8;
    background-color: #eaeaea;
    height: calc(100vh - 98px);
    margin-top: 98px;
    overflow-y: hidden;
    position: relative;
    .dkg-criculer-progressBar {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .dkg-searchdb-page-Con {
        height: 100%;
        .dkg-searchdb-boxCon {
            margin-top: 40px;
            .dkg-searchdb-topHeaderCon {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 15px;

                .dkg-srchdb-leftCon {
                    .dkg-creative-new-tab {
                        background: #2e75b6;
                        border: 1px solid #2e75b6;
                        border-radius: 25px;
                        color: #fff;
                        display: inline-block;
                        font-size: 14px;
                        outline: none;
                        padding: 7px 15px;
                        text-transform: capitalize;

                        &:hover {
                            background: #234158;
                            border-color: #234158;
                            color: #fff;
                            transition: all .5s;
                        }
                    }

                    .MuiBackdrop-root {
                        .MuiBox-root.css-8atqhb {
                            width: 37%;
                        }
                    }
                }

                .dkg-center-searchdb {
                    .dkg-searchdb-totalCount {
                        color: #2e75b6;
                        font-size: 16px;
                        font-weight: 600;
                        padding-left: 10px;
                        // text-transform: capitalize;
                    }
                }

                .dkg-searchdb-searchboxCon {
                    .dkg-srchinput-grp {
                        transition: transform 250ms ease-in-out;
                        font-size: 14px;
                        line-height: 18px;
                        color: #575756;
                        background-color: #fff;
                        background-repeat: no-repeat;
                        background-size: 18px 18px;
                        background-position: 95% center;
                        border-radius: 50px;
                        border: 1px solid #ccc;
                        transition: all 250ms ease-in-out;
                        backface-visibility: hidden;
                        transform-style: preserve-3d;
                        min-width: 380px;
                        padding: 0;

                        .dkg-srchinput {
                            border: none;
                            border-radius: 10px;
                            background-color: transparent;
                            height: 37px;

                            &:focus {
                                outline: 0;
                                box-shadow: none;
                            }

                            &:focus::placeholder {
                                color: transparent;
                            }
                        }

                        .input-group-prepend {
                            border: none;

                            .input-group-text {
                                border: none;
                                // background-color: transparent;
                                border: none;
                                cursor: pointer;
                                border-top-right-radius: 15px;
                                border-bottom-right-radius: 15px;
                                width: 35px;
                                background-color: #3a70ab;
                                color: #fff;

                                &:hover {
                                    background-color: #3a70ab;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

                .dk-create-project-btnCon {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    button.dk-create-project-btn {
                        background: #2e75b6;
                        border: 1px solid #2e75b6;
                        border-radius: 25px;
                        color: #fff;
                        display: inline-block;
                        font-size: 14px;
                        height: 37px;
                        outline: none;
                        padding: 0 18px;

                        &:hover {
                            background: #234158;
                            border-color: #234158;
                            color: #fff;
                            transition: all .5s;
                        }
                    }
                }
            }

            .dkg-searchdb-filter-title {
                font-size: 17px;
                color: #5a5858;
                font-weight: 600;
                margin-bottom: 12px;
                position: relative;
                text-transform: uppercase;
                font-weight: bold;
                padding-left: 10px;
                // border-bottom: 2px solid #ccc;

                .dkg-clear-title {
                    position: absolute;
                    right: 30px;
                    top: 2px;
                    color: #676B6E;
                    font-size: 12px;
                }
            }
            .dkg-search-cv-maincontent{
                height: calc(100vh - 205px);
                overflow-y: auto;
                .dkg-searchdb-leftCon {
                    flex: 0 0 100%;
                    max-width: 100%;
                    display: flex;
                    margin-bottom: 15px;
    
                    .dkg-searchdb-filter-cols {
                        flex: 0 0 18.69%;
                        max-width: 18.69%;
                        // flex: 0 0 15.450%;
                        // max-width: 15.450%;
                        margin: 0 10px;
                        background-color: #fff;
                        border-radius: 5px;
    
                        &.dkg-srchdb-hideSrchInput {
                            .dkg-searchdb-dropdown-u12 {
                                .dk-candsMMToggleBtn {
                                    .dkg-srchdb-plus-dropdown{
                                        .dkg-search-cv-db-star-span{
                                            .dkg-search-cv-db-star{
                                                color: red;
                                                margin-left: 0px !important;
                                                position: relative;
                                                top: -5px;
                                                right: -5px;
                                                width: 8px;
                                                height: 8px;
                                            }
                                        }
                                    }
                                    .dk-progress-stageMenu {
                                        input {
                                            display: none;
                                        }
                                    }
                                }
                            }
    
                            &.dkg-srchdb-openUpper-Dropdown {
                                .dkg-searchdb-dropdown-u12 {
                                    .dk-candsMMToggleBtn {
                                        .dk-progress-stageMenu {
                                            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px 0px;
                                            display: block;
                                            width: 100%;
                                            bottom: 31px !important;
                                            top: inherit !important;
                                            transform: inherit !important;
                                            background: rgb(38, 89, 125);
                                            border-color: rgb(150, 200, 218);
                                            border-radius: 0px;
                                            border-top: rgb(150, 200, 218);
                                            padding: 0px;
                                        }
                                    }
                                }
                            }
                        }
    
                        .dkg-searchdb-filter-title {
                            font-size: 15px;
                            color: #551C1B;
                            margin-bottom: 15px;
                            position: relative;
                            text-transform: uppercase;
                            font-weight: normal;
    
                            .dkg-clear-title {
                                position: absolute;
                                right: 30px;
                                top: 2px;
                                color: #676B6E;
                                font-size: 12px;
                            }
                        }
    
                        .dkg-searchdb-filter-body {
                            .dkg-searchdb-input-Con {
                                display: flex;
    
                                &.dkg-jobtitle-booleanCon {
                                    position: relative;
    
                                    .dkg-plus-iconCon {
                                        position: absolute;
                                        left: 5px;
                                        top: 3px;
    
                                        svg {
                                            width: 13px;
                                            height: 13px;
                                        }
                                    }
    
                                }
    
                                input.dkg-boolen-input {
                                    background-color: transparent;
                                    border-bottom: 1px solid #ddd;
                                    padding-left: 30px;
                                    text-align: left;
    
                                    &:focus {
                                        outline: 0;
                                        box-shadow: none;
                                        border-bottom: 2px solid #ddd;
                                    }
                                }
    
                                input {
                                    background-color: transparent;
                                    border-bottom: 1px solid #ddd;
                                    padding-left: 8px;
                                    text-align: left;
                                    color: #676B6E;
                                    font-size: 12px;
    
                                    &:focus {
                                        outline: 0;
                                        box-shadow: none;
                                        border-bottom: 3px solid #9CBFD8;
                                    }
                                }
    
                            }
    
                            .dkg-searchdb-tag-Con {
                                .dkg-searchdb-tag {
                                    background: #CDEEFE;
                                    border: 1px solid #CDEEFE;
                                    border-radius: 30px;
                                    color: #333;
                                    display: inline-flex;
                                    font-size: 12px;
                                    list-style: none;
                                    margin: 3px;
                                    padding: 3px 10px;
                                    position: relative;
                                }
                            }
    
                            .dkg-searchdb-filter-listCon {
                                list-style: none;
    
                                li {
                                    margin-bottom: 0.25rem;
    
                                    a {
                                        font-size: 12px;
                                        color: #3a70ab;
                                    }
                                }
                            }
                        }
                    }
                } 
            }
            .dkg-searchdb-RightCon {
                flex: 0 0 79%;
                max-width: 79%;
                padding-left: 12px;

                .dkg-searchdb-rightbox-cols {
                    .accordion {
                        .dkg-searchdb-rightbox-12 {
                            .dkg-searchdb-rightbox-Header {
                                .btn.btn-link {
                                    margin: 0;
                                    padding: 0;
                                    text-transform: capitalize;
                                    text-decoration: none;
                                    font-size: 14px;
                                    color: #676B6E;

                                    .dkg-arrow-iconCon {
                                        svg {
                                            width: 17px;
                                            height: 17px;
                                            color: #676B6E;
                                            position: relative;
                                            top: -7px;
                                        }
                                    }
                                }
                            }

                            .dkg-searchdb-rightbox-Collapse {
                                .dkg-searchdb-rightbox-Body {
                                    display: flex;
                                    flex: 0 0 100%;
                                    max-width: 100%;

                                    .dkg-srchdb-collapse-leftCon {
                                        flex: 0 0 50%;
                                        max-width: 50%;

                                        .dkg-srchdb-collapse-box {
                                            ul {
                                                list-style: none;
                                                width: 95%;

                                                li {
                                                    background-color: #fff;
                                                    height: 35px;
                                                    border: 1px solid #ddd;
                                                    border-bottom: none;
                                                    line-height: 35px;
                                                    padding: 0 5px;

                                                    &:last-child {
                                                        border-bottom: 1px solid #ddd;
                                                    }

                                                    a {
                                                        color: #333;
                                                        font-size: 14px;

                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .dkg-srchdb-collapse-rightCon {
                                        flex: 0 0 50%;
                                        max-width: 50%;

                                        .dkg-srchdb-collapse-box {
                                            ul {
                                                list-style: none;
                                                width: 95%;
                                                display: flex;
                                                justify-content: flex-end;

                                                li {
                                                    background-color: #fff;
                                                    height: 35px;
                                                    border: 1px solid #ddd;
                                                    border-bottom: none;
                                                    line-height: 35px;
                                                    padding: 0 5px;

                                                    &:last-child {
                                                        border-bottom: 1px solid #ddd;
                                                    }

                                                    a {
                                                        color: #333;
                                                        font-size: 13px;

                                                        svg {
                                                            width: 13px;
                                                            height: 13px;
                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}