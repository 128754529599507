.dk-savedprojectCon {
    margin-top: 115px;

    .dk-savedTopHead {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dk-savedLeftTopHead {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .dk-SrchInputBox {
                .input-group {
                    max-width: 160px;

                    input {
                        border-radius: 0;
                        background-color: #eaeaee;
                        border: 1px solid #ddd;
                        height: 35px;

                        &:focus {
                            box-shadow: none;
                            outline: 0;
                        }
                    }

                    .input-group-append {
                        button {
                            padding: .5rem .55rem;
                            background-color: #eaeaee !important;
                            margin: 0;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            border-left: none;
                            line-height: 1.3rem;

                            i {
                                color: #6c757d;
                            }
                        }
                    }
                }
            }

            .dk-usrCricelIcon {
                .mailshotUsrDrop {
                    .dropdown {
                        button.mailshotUsrBtn.dropdown-toggle.btn {
                            box-shadow: none;
                            background-color: transparent;
                            padding: 0 !important;
                            margin: 0;
                            background-color: #eaeaea;
                            border: 1px solid #ddd;
                            border-radius: 50%;
                            height: 35px;
                            width: 35px;

                            img {
                                border-radius: 50%;
                                height: 35px;
                                width: 35px;
                            }
                        }

                        button.mailshotUsrBtn.dropdown-toggle::after {
                            display: none;
                        }

                        .mailshotUsrMenu.dropdown-menu {
                            padding: 5px;
                            left: 22px !important;
                            top: -9px !important;
                            transform: translate(22px, 0px) !important;
                            -webkit-transform: translate(22px, 0px) !important;
                            background-color: #fff;

                            a.dropdown-item {
                                padding: 0;
                                display: inline-block;
                                width: 35px;
                                height: 35px;
                                margin: 0 5px;

                                img {
                                    width: 100%;
                                    height: auto;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .mailshotUsrMenu.dropdown-menu:after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-color: transparent #ccc transparent transparent;
                            border-style: solid;
                            border-width: 8.5px 12px 8.5px 0;
                            line-height: 0;
                            _border-color: #000 #ccc #000 #000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            left: -12px;
                            top: 15px;
                        }
                    }
                }

                .dk-resuserFilterCon {
                    position: relative;

                    .MuiSvgIcon-root.crossUserFilter.dkgddf {
                        top: -5px;
                        right: -8px !important;
                    }
                }
            }

            .dk-srchCricelIcon {
                background-color: #eaeaee;
                border: 1px solid #ddd;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;

                a {
                    color: #333;
                }
            }
        }

        .dk-savedCentreTopHead {
            h3.title {
                margin: 0;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                top: 10px;
            }
        }

        .dk-savedRightTopHead {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .dk-createbtnCon {
                a {
                    display: inline-block;
                    background: #2e75b6;
                    color: #fff;
                    padding: 8px 12px;
                    font-size: 14px;
                    border-radius: 3px;
                    margin-left: 15px;
                    white-space: nowrap;
                }
            }
        }
    }

    .dk-savedProTableCon {
        margin-top: 10px;
        height: calc(100vh - 235px);

        .dk-savedProTable {
            margin-bottom: 0;

            thead {
                tr th {
                    color: #ccc;
                    vertical-align: middle;
                    text-transform: uppercase;
                    font-size: 13px;
                    text-align: center;
                    padding-bottom: 3px;
                    background: #26597d;
                    border: 1px solid #4483af;
                    vertical-align: middle;
                    padding: 10px 12px;
                }
            }

            tbody {
                tr td {
                    text-align: center;
                    vertical-align: middle;
                    background: #eaeaea;
                    border-left: 1px solid #cecece;
                    vertical-align: middle;
                    font-size: 14px;
                    text-align: center;
                    padding: 5px 12px;

                    &:nth-child(02),
                    &:nth-child(03) {
                        text-align: left;
                    }

                    .dk-recrImg {
                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }
                    }

                    span.projectTitle {
                        font-weight: 700;
                    }

                    .dk-candidate {
                        a {
                            color: #2e75b6;
                            font-weight: 600;
                            white-space: nowrap;
                        }
                    }

                    .dk-action {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        a {
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            background: #ccc;
                            border-radius: 3px;
                            line-height: 30px;
                            margin: 0 3px;
                            font-size: 14px;

                            &.dk-report {
                                background: #e6a000;
                                color: #fff;
                            }

                            &.dk-edit {
                                background: #2e75b6;
                                color: #fff;
                            }

                            &.dk-delete {
                                background: #e2445b;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}