.dk-reportMain{
    padding: 0 15px;
    width: 100%;
    max-width: 1600px;
    margin: auto;
    margin-top:160px;
    .dk-reportHead{
        .title{
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            color: #333;
        }
        .dk-reportLeft-panel{
            .dk-reportSearch{
                display: inline-block;
                width: 100%;
                max-width: 160px;
                position: relative;
                input{
                    width: 100%;
                    height: 30px;
                    border-radius: 3px;
                    outline: none;
                    border: 1px solid #ddd !important;
                    box-shadow: none !important;
                    text-align: left;
                }
                .dk-searchBtn{
                    border: none;
                    outline: none;
                    background: transparent;
                    position: absolute;
                    right: 10px;
                    top:4px;
                    font-size: 14px;
                    color: #8f8f8f;
                }
            }
        }
        .dk-reportLeft-panel{
            .dk-reportFilter{
                .dropdown{
                    .dropdown-toggle{
                        margin:0 !important;
                        padding:4px 12px;
                        box-shadow: none !important;
                        width: 100%;
                        min-width:100px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border:1px solid #ccc;
                        border-radius: 3px;
                        text-transform: inherit;
                        font-size: 13px;
                    }
                    .dropdown-menu{
                        padding: 0;
                        border:none;
                        width: 100%;
                        min-width: 100px;
                        margin: 0 !important;
                        border-radius: 0;
                        transform: inherit !important;
                        left: inherit !important;
                        right: 0 !important;
                        top:29px !important;
                        a{
                            display: block;
                            padding:3px 12px;
                            font-size: 13px;
                            border-bottom: 1px solid #ddd;
                            background: #f2f2f2;
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .dk-reportTable-main{
        display: block;
        margin-top: 5px;
        table{
            margin-bottom: 0;
            tr{
                th{
                    background: #485569;
                    border: 1px solid gray;
                    border-bottom: none;
                    border-top: none;
                    color: #fff;
                    font-size: 13px;
                    padding: 9px 15px;
                    position: -webkit-sticky;
                    position: sticky;
                    text-align: center;
                    top: 0;
                    vertical-align: middle;
                    white-space: nowrap;
                    z-index: 9;
                }
                td{
                    background: #fff;
                    color: #4d4a4a;
                    font-size: 14px;
                    padding: 9px 15px!important;
                    text-align: center;
                    vertical-align: middle;
                    white-space: nowrap;
                    z-index: 1;
                    border: 1px solid #ddd;
                    &:nth-child(01){
                        width: 50px;
                    }
                }
            }
        }
    }
}