.dk-pipepaginationCon{
    flex: 0 0 100%;
    max-width: 100%;
    background-color: #44546a;
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul{
        list-style: none;
        padding: 6px 15px;
        margin-bottom: 0;
        li{
            color: #fff;
            z-index: 999;
            font-size: 13px;
        }
    }
}