.dk-dkGlobalSummary-Main {
    margin-top: 125px;
    padding: 0 25px;

    @media(max-width:991px) {
        margin-top: 180px;
        padding: 0 15px;
    }

    .col-md-3 {
        flex: 0 0 30%;
        max-width: 30%;

        @media(max-width:767px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .col-md-6 {
        flex: 0 0 40%;
        max-width: 40%;

        @media(max-width:767px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .dk-information-cols {
        border: 1px solid #ddd;
        font-family: "Roboto", sans-serif !important;

        @media(max-width:767px) {
            margin-bottom: 15px;
        }

        .title {
            text-transform: uppercase;
            border-bottom: 1px solid #ddd;
            font-size: 16px;
            font-weight: 600;
            padding: 6px 0;
            text-align: center;
            background: #f2f2f2;
        }

        .dk-userImg {
            padding: 3px 10px;
            border-bottom: 1px solid #ddd;
            text-align: center;
            background: #f2f2f2;

            img {
                width: 65px;
                height: 65px;
                border-radius: 50%;
                padding: 3px;
                border: 1px solid #ddd;
            }
        }

        .dk-infoTable {
            table {
                border: none;
                margin-bottom: 0;

                tr {
                    td {
                        border: none;
                        border-right: 1px solid #ddd;
                        border-bottom: 0.5px solid #ddd;
                        height: 36px;
                        background: #f2f2f2;
                        vertical-align: middle;
                        text-align: center;

                        &:last-child {
                            border-right: none;
                            background: #fff;
                            width: 61%;
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        .dk-infoTable.dk-businessDetailTable {
            table {
                tr {
                    td {
                        &:first-child {
                            width: 30%;
                        }
                    }
                }
            }
        }

        .dk-infoTable.dk-businessOverallTable {
            table {
                tr {
                    td {
                        height: 90px;
                        text-align: center;
                        vertical-align: middle;
                        font-weight: 500;
                        color: #777;
                        font-size: 15px;

                        .dk-logo {
                            img {
                                width: 100%;
                                max-width: 150px;
                            }

                            a {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .dk-summarySh-cols {
        width: 100%;
        max-width: 100%;
        border: 1px solid #ccc;
        margin-right: 0;
        border-right: none;

        &:last-child {
            border-right: 1px solid #ccc;
        }

        .title {
            text-align: center;
            font-weight: 500;
            text-transform: uppercase;
            border-bottom: 1px solid #ccc;
            padding: 8px 0;
            font-size: 16px;
            background: #f2f2f2;
        }

        .dk-summarySh-panel::-webkit-scrollbar {
            width: 0px;
        }

        .dk-summarySh-panel::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
        }

        .dk-summarySh-panel::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0px solid slategrey;
        }

        .dk-summarySh-panel {
            height: calc(100vh - 177px);
            flex-wrap: wrap;
            overflow-y: auto;
            display: flex;

            li {
                list-style-type: none;
                width: 100%;
                height: auto;
                border-bottom: 1px solid #ccc;
                display: flex;
                align-items: center;
                justify-content: center;

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        &:nth-child(02) {
            .dk-summarySh-panel {
                li {
                    min-height: 40px;
                }
            }
        }

        &:nth-child(04) {
            .dk-summarySh-panel {
                li {
                    min-height: 110px;
                }
            }
        }

        &:nth-child(01) {
            .dk-summarySh-panel {
                li {
                    min-height: 40px;
                }
            }
        }
    }

    .dk-summarySh-cols.dk-summarySh-trainer {
        max-width: 7%;

        .dk-summarySh-panel {
            .dk-sheduleUser {
                text-align: center;
                padding: 5px 0;

                img {
                    width: 100%;
                    max-width: 40px;
                    border-radius: 50%;
                }
            }
        }
    }
}