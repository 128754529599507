/* .dk-campaignsPageMain{
    margin-top: 172px;
}
.dk-campaignsPageMain .dk-TitleCont h2.dk-reSPageTitle {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
} */
.dk-compgBlockCont {
    padding: 0 15px;
    margin-top: 55px;
    padding-top: 25px;
    background-color: #eaeaea;
    height: calc(100vh - 56px)
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgLeft {
    display: flex;
    align-items: center;
}

.dk-topBlockCont .dk-compgPageHead .dk-compgLeft a.dk-campgiansIcon {
    display: inline-block;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
    color: #333 !important;
    text-align: center;
    line-height: 33px;
    border: 1px solid #ddd;
    cursor: pointer;
}

.dk-topBlockCont .dk-compgPageHead .dk-compgLeft a.dk-campgiansIcon i {
    font-size: 14px;
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgLeft .dk-mailshotsSearch .input-group {
    width: 100%;
    max-width: 152px;
    margin-right: 3px;
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgLeft .dk-mailshotsSearch .input-group input {
    border: 1px solid #ddd !important;
    background-color: #fff;
    padding: 0 10px;
    box-shadow: none !important;
    border-radius: 0;
    height: 35px;
    padding-right: 0;
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgLeft .dk-mailshotsSearch .input-group .input-group-append button {
    padding: .35rem .65rem;
    margin: 0;
    background-color: #fff !important;
    color: #808080;
    box-shadow: none;
    border: 1px solid #ddd;
    border-left: none;
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
    color: #525252;
    margin-top: 4px;
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgTopRight a {
    display: inline-block;
    background: #fff;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 0;
    outline: 0;
    font-size: 15px;
    font-weight: 400;
    min-width: 90px;
    max-width: 90px;
    border-radius: 5px;
    text-align: center;
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgTopRight a.liveBtn {
    background-color: #3f9e6f;
    border: 1px solid #3f9e6f;
    color: #fff;
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgTopRight a.closeBtn {
    background-color: #e24454;
    border: 1px solid #e24454;
    color: #fff;
}

.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgTopRight a.liveBtn.dkactivepage,
.dk-compgBlockCont .dk-topBlockCont .dk-compgPageHead .dk-compgTopRight a.closeBtn.dkactivepage {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #333;
}

.dk-campiangnsCon {
    margin: 10px 0 0;
}

.dk-campiangnsCon .dk-campiangnsSrcoll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 216px);
}

.dk-campiangnsCon .dk-campiangnsSrcoll .dk-campiangnsMainTable {
    border: 1px solid #cccccc;
    margin-bottom: 25px;
    border-radius: 5px;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable {
    margin-bottom: 0;
    border: none;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td {
    width: 16.6%;
    white-space: nowrap;
    background: #fff;
    color: #333;
    padding: 5px 15px;
    border: none;
    border-right: 1px solid #ccc;
    position: relative;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    height: 64px;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td:last-child {
    border-right: none;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td:nth-child(02) {
    min-width: 45%;
    white-space: nowrap;
    text-align: center;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td.dk-hotTags .boxCols {
    width: 0;
    height: 0;
    border-color: #3f9e6f transparent transparent;
    border-style: solid;
    border-width: 66px 66px 0 0;
    _border-color: #3f9e6f #000 #000 #000;
    line-height: 0;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td.dk-hotTags {
    border-left: none !important;
    border-radius: 5px 0 0;
    overflow: hidden;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td.dk-hotTags .boxCols.redTag {
    width: 0;
    height: 0;
    border-color: #e24454 transparent transparent;
    border-style: solid;
    border-width: 66px 66px 0 0;
    line-height: 0;
    _border-color: #e24454 #000 #000 #000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td span.dk-tabletdTitle {
    display: block;
    font-size: 14px;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td span.textblue {
    color: #3488de;
    font-size: 14px;
    cursor: pointer;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody td:nth-child(01) {
    /* min-width: 120px; */
    text-align: center;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody td.dk-hotTags .dk-workTableRecImg img {
    width: 30px;
    border-radius: 50%;
    margin-top: 4px;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td.dk-hotTags {
    position: relative;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td.dk-hotTags .dk-camdeleteBox {
    position: absolute;
    top: 10px;
    left: 9px;
    z-index: 9;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td.dk-hotTags .dk-camdeleteBox a {
    font-size: 15px;
    margin: 0 5px;
    color: #333;
    cursor: pointer;
    display: none;

}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody td.dk-hotTags .dk-checkBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 9;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td.dk-hotTags .dk-checkBox a {
    font-size: 15px;
    margin: 0 5px;
    color: #808080;
    cursor: pointer;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td.dk-hotTags .dk-checkBox a:last-child i {
    margin-top: 6px;
}

.dk-campiangnsMainTable .dk-campaignsFirstTable tbody.dk-camp1stblocktbody tr td .dk-workTitle {
    margin-left: 0;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    cursor: text;
    color: #333;
    margin-bottom: 5px;
    text-align: center;
}

.dk-campiangnsMainTable .dk-campaignsSecondTable {
    margin-bottom: 0;
    border-bottom: none;
}

.dk-campiangnsMainTable .dk-campaignsSecondTable tbody.dk-camp2ndblocktbody tr td {
    width: 12.5%;
    white-space: nowrap;
    background: #fff;
    color: #333;
    padding: 5px 15px;
    border: none;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    position: relative;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    height: 64px;
}

.dk-campiangnsMainTable .dk-campaignsSecondTable tbody.dk-camp2ndblocktbody tr td:last-child {
    border-right: none;
}

.dk-campiangnsMainTable .dk-campaignsSecondTable tbody.dk-camp2ndblocktbody tr td span.dk-tabletdTitle {
    display: block;
    font-size: 14px;
}

.dk-campiangnsMainTable .dk-campaignsSecondTable tbody.dk-camp2ndblocktbody tr td span.textblue {
    color: #3488de;
    font-size: 14px;
    cursor: pointer;
}

.dk-campiangnsboxCon {
    border: 1px solid #cccccc;
    margin-bottom: 25px;
    border-radius: 5px;
    background-color: #fff;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 {
    display: flex;
    flex-flow: row wrap;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 .dk-campiagnsFboxcols {
    min-height: 66px;
    max-height: 66px;
    min-width: 70px;
    padding: 5px 10px;
    text-align: center;
    color: #6e6969;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols1,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols2,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols3,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols4,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols5,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols6,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols7,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols8 {
    background-color: #fff;
    flex-basis: 8.58%;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols1 {
    position: relative;
    border-radius: 5px 0 0;
    overflow: hidden;
    flex-basis: 7.47%;
    border-right: 1px solid #ccc;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols2,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols3,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols4,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols5,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols6,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols7 {
    border-right: 1px solid #ccc;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols2 {
    flex-basis: 41%;
    border-right: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols1 .boxCols {
    width: 66px;
    height: 68px;
    background-color: #3f9e6f;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 0 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 0 100%, 0% 100%);
    cursor: pointer;
    border-color: #3f9e6f;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols1 .boxCols.dkcam-redTag {
    width: 66px;
    height: 68px;
    background-color: #e24454;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 0 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 0 100%, 0% 100%);
    cursor: pointer;
    border-color: #e24454;
}

.dk-campContextMenu {
    z-index: 99;
}

.dk-campaignDeleteItem {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 3px 0 rgba(0.34, 0.36, 38, 0.15);
    position: relative;
    z-index: 99999;
    cursor: pointer;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols1 .dk-workTableRecImg {
    position: relative;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols1 .dk-workTableRecImg img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    left: 42px;
    top: 18px;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols3,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols4,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols5,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols6,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols7,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols8 {
    padding-top: 11px;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols3,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols5,
.dk-campiangnsboxCon .dk-campiangnsboxCon1 #dk-firstBox_cols7 {
    border-right: 1px solid #ddd;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 .dk-campiagnsFboxcols span.dk-tabletdTitle {
    display: block;
    font-size: 14px;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon1 .dk-campiagnsFboxcols span.textblue {
    color: #3488de;
    font-size: 14px;
    cursor: pointer;
}

.dk-campiagnsDrpDwn {
    display: flex;
    justify-content: center;
    position: relative;
}

.dk-campiagnsDrpDwn .ui.selection.dropdown.dk-overdues-task-slect,
.dk-campiagnsDrpDwn .ui.selection.dropdown.dk-overdues-task-slect:hover {
    background-color: transparent !important;
    border: none !important;
    min-width: 75px;
    max-width: 75px;
    padding: 0 !important;
    color: #3488de;
    font-size: 14px;
    min-height: 1.58rem;
}

.dk-campiagnsDrpDwn .ui.selection.dropdown.dk-overdues-task-slect>.dropdown.icon {
    display: none;
}

.dk-campiagnsDrpDwn .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
.dk-campiagnsDrpDwn .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
    color: #3488de;
    font-size: 14px;
}

.dk-campiagnsDrpDwn .ui.selection.active.dropdown .menu {
    min-width: 130px;
    max-width: 130px;
    border-radius: 0;
}

.dk-campiagnsDrpDwn .ui.dropdown .menu .selected.item,
.dk-campiagnsDrpDwn .ui.dropdown.selected,
.dk-campiagnsDrpDwn .ui.selection.dropdown .menu>.item {
    font-weight: normal;
}

.dk-workTitle {
    cursor: pointer;
    font-size: 15px;
}

.dk-workTitle:hover {
    color: #3488de;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols {
    min-height: 66px;
    max-height: 66px;
    min-width: 70px;
    padding: 5px 10px;
    text-align: center;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding-top: 11px;
    color: #6e6969;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon2 {
    display: flex;
    flex-flow: row wrap;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols {
    background-color: #fff;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols:last-child {
    border-right: none;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols#dk-secBox_cols1,
.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols#dk-secBox_cols2,
.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols#dk-secBox_cols3 {
    background-color: #fff;
    /* min-width: 24.26%;
   max-width: 24.26%; */
    flex-basis: 16.16%;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols#dk-secBox_cols4,
.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols#dk-secBox_cols5,
.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols#dk-secBox_cols6 {
    flex-basis: 17.16%;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols span.dk-tabletdTitle {
    display: block;
    font-size: 14px;
}

.dk-campiangnsboxCon .dk-campiangnsboxCon2 .dk-campiagnsSecondboxcols span.textblue {
    color: #3488de;
    font-size: 14px;
    cursor: pointer;
}