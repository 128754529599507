.batch-actions-menu-wrapper {
    transition: bottom .2s ease;
    position: fixed;
    width: 800px;
    height: 63px;
    z-index: 9999;
    background: #f7f2f2;
    border-radius: 5px;
    transform: translateX(-50%);
    left: 430px;
    box-shadow: 0 22px 104px -6px rgb(0 0 0 / 34%);
    flex-direction: row;
    display: flex;
    // bottom: 30px;97
    user-select: none;
    border: 1px solid #a19a9a;
    // bottom: 60px;
    left: 50%;
    bottom: 145px;
    display: none;

    &.dkg-sendout-checkbox-bottom {
        top: inherit;
        bottom: 30px;
    }

    &.activePopup {
        display: inline-flex;
    }

    .num-of-actions_wrapper {
        width: 63px;
        color: #fff;
        background: #34976e;
        border-radius: 5px 0 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        cursor: default;

        .num-of-actions {
            font-size: 30px;
        }
    }

    .batch-actions-title-section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        cursor: default;
        position: relative;
        padding-left: 10px;
        background-color: #d9d9d9;
        display: none;

        .dk-rowSelected {
            width: 100%;
            font-weight: 300;
            font-size: 18px;
            padding-left: 15px;
            border-bottom: 1px solid #808080;
            padding-bottom: 5px;
        }

        .title {
            color: #333;
            font-size: 20px;
            padding-left: 20px;
            font-weight: 100;
        }

        .plus-dots {
            position: absolute;
            top: 36px;
            right: 50px;
        }
    }

    .batch-actions-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        background: #234158;
        color: #fff;
        border-left: 1px solid #a19a9a;
        font-size: 12px;
        white-space: nowrap;
        min-width: 100px;
        max-width: 100px;
        &.bulkemail-checkboxbg{
            background-color: #cd5360;
            color: #fff;
        }
        &.newcase-checkboxbg {
            background-color: #5c737d;
            color: #fff;
        }

        &.jobspec-checkboxbg {
            background-color: #26596d;
            color: #fff;
        }

        &.calling-checkboxbg {
            background-color: #d58b5d;
            color: #fff;
        }

        &.processing-checkboxbg {
            background-color: #57496d !important;
            color: #fff;

            &:hover {
                background-color: #fff !important;
                color: #333 !important;
            }
        }

        // &.interseted-checkboxbg {
        //     background-color: #2d9b99;
        //     color: #fff;
        // }
        &.interseted-checkboxbg {
            background-color: #3a9469 !important;
            color: #fff;

            &:hover {
                background-color: #fff !important;
                color: #333 !important;
            }
        }

        &.sendouts-checkboxbg {
            background-color: #316a67;
            color: #fff;
        }

        &.joboffer-checkboxbg {
            background-color: #629bd3;
            color: #fff;
        }

        // &.notreachable-checkboxbg {
        //     background-color: #d58b5d;
        //     color: #fff;
        // }
        &.notreachable-checkboxbg {
            background-color: #02a786 !important;
            color: #fff;

            &:hover {
                background-color: #fff !important;
                color: #333 !important;
            }
        }

        &.recadmin-checkboxbg {
            background-color: #715f8f;
            color: #fff;
        }

        &.closed-checkboxbg {
            background-color: #da3d5e !important;
            color: #fff !important;

            &:hover {
                background-color: #fff !important;
                color: #333 !important;
            }
        }

        &.db-ready-checkboxbg {
            background-color: #e2445c;
            color: #fff;
        }

        &.dkg-newblocked-checkboxbg {
            background-color: #d60101;
            color: #fff
        }

        &.archived-checkboxbg {
            background-color: #6a0707;
            color: #fff;

            &:hover {
                background-color: #fff !important;
                color: #333 !important;
            }
        }

        &.dkg-newdb-checkboxbg {
            padding: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .dkg-batch-actions-dropdown {
                margin: 0;

                .dkg-batch-actions-dropdown-toggle {
                    margin: 0;
                    margin-top: 2px;
                    width: 100px;
                    color: #fff;
                    text-transform: capitalize;
                    padding: 0;
                    height: 59px;
                    font-size: 13px;
                    box-shadow: none;

                }

                .dkg-batch-actions-dropdown-menu {
                    padding: 0;
                    border-radius: 0;
                    width: 153px;

                    .dkg-batch-actions-dropdown-item {
                        padding: 6px 10px;
                        color: #333;
                        font-size: 13px;
                        border-bottom: 1px solid #b37777;
                        text-align: left;
                        width: 100%;
                        position: relative;

                        &:last-child {
                            border-bottom: none;
                        }

                        &:hover {
                            background-color: #f7f2f2 !important;
                            color: #333 !important;

                            .dkg-db-move-righticon {
                                color: #333;
                            }
                        }

                        .dkg-db-move-righticon {
                            position: absolute;
                            right: 10px;
                            top: 7px;
                        }


                    }
                }
            }

            &:hover {
                background-color: #f7f2f2 !important;
                color: #333 !important;

                .dkg-batch-actions-dropdown {
                    .dkg-batch-actions-dropdown-toggle {
                        color: #333 !important;
                    }
                }
            }
        }

        &:hover {
            color: #333;
            background-color: #fff;
        }

        .action-icon {
            font-size: 21px;
            position: relative;
        }

        .action-name {
            font-size: 13px;
            position: relative;
            bottom: -4px;
        }

        &:last-child {
            background-color: #d9d9d9;
            color: #333;

            &:hover {
                background-color: #fff;
            }
        }

        &.dk-checkAll {
            color: #333;
            border-left: 1px solid #a19a9a;
            background-color: #fff;

            &:hover {
                background-color: #d9d9d9;
            }
        }

        &.dk-deleteModal {
            background-color: #fff;
            color: #333;
            border-right: 2px solid #a19a9a;

            &:hover {
                background-color: #d9d9d9;
            }
        }
    }

    .batch-actions-delete-item {
        display: flex;
        width: 50px;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-left: 1px solid #d4d4d4;
        background-color: #fff;
        color: #333;

        &:hover {
            color: #333;
            background: #d9d9d9;
        }
    }
}

.batch-actions-menu-wrapper.dk-action-popupMain {
    .batch-actions-item {
        min-width: 140px;
        max-width: 140px;

        &.dkg-business-qualified-item {
            &:hover {
                background-color: #fff !important;
                color: #333 !important;

                span {
                    color: #333;
                }
            }
        }
    }
}

.dk-smsMessage-selectMsg {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 22px 104px -6px #00000057;
    width: 100%;
    max-width: 800px;
    margin: auto;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    height: 63px;

    .batch-actions-title-section {
        width: 100%;
        max-width: 63px;
        background: #34976e;
        color: #fff;
        font-weight: 500;
        font-size: 30px;
        border-radius: 5px 0 0 5px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dk-rowSelected {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        cursor: default;
        position: relative;
        min-width: 250px;
        width: 100%;
        font-weight: 300;
        font-size: 22px;
        padding-left: 15px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;

        .dk-selectedDot {
            line-height: 8px;

            span.dk-circle {
                display: inline-block;
                width: 8px;
                height: 8px;
                background: #016e42;
                border-radius: 50%;
                margin-left: 1px;
            }
        }
    }

    .dk-checkAll {
        width: 200px;
        height: 63px;
        border-left: 1px solid #ddd;
        text-align: center;
        font-weight: 400;
        padding: 12px 0;
        cursor: pointer;
        white-space: nowrap;

        i {
            font-size: 17px;
        }

        span {
            display: block;
        }


    }
}

.dk-smsMessage-selectMsg.activePopup {
    display: block;
}

.batch-actions-item.dkg-delete-tab-12 {
    color: #333;
    border-right: 1px solid #a19a9a;
    background-color: #fff;

    &:hover {
        background-color: #d9d9d9;

    }
}