.modal-dialog.dk-qualifyPopup{
    min-width: 50%;.modal-content {
        background-color: transparent;
        .modal-header.dk-qualifyAddnewheader {
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #26597d;
            padding: 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-align: center;
        }
        .modal-header.dk-qualifyViewheader{
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #2196f3;
            padding: 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-align: center;
            .dkqualifyaddtitle.modal-title.h4 {
                color: #fff;
                width: 100%;
                font-weight: 600;
                font-size: 20px;
            }
            .close{
                color: #fff;
                opacity: 1;
            }
        }
        .modal-header.dk-qualifyEditHeader{
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fee69c;
            padding: 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-align: center;
        }
        .dk-qualifyAddNewBody{
            background-color: #fff;
            padding: 10px 25px 20px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            form {
                .form-group{
                    display: flex;
                    margin-bottom: 1rem;
                    align-items: center;
                    label {
                        min-width: 90px;
                        font-size: 14px;
                        margin-bottom: 0;
                        text-align: left !important;
                    }
                    input{
                        background-color: #fff;
                        border-radius: 0;
                        font-size: .87rem;
                        padding: .5rem;
                        border: 1px solid #ddd;
                        text-align: center;
                        box-shadow: none;
                        height: 32px;                    
                    }
                    .dk-qualifyDrpDwn{
                        width: 100%;
                        .ui.selection.dropdown.dk-overdues-task-slect {
                            border-radius: 0;
                            background-color: #fff;
                            font-size: 0.85rem;
                            height: 32px;
                            border: 1px solid #ddd;
                        }
                        .ui.selection.dropdown .menu {
                            border-radius: 0;
                        }
                    }
                }
                .dk-adstrackbtnCont{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .dk-adstrackaddbtn{
                        display: inline-block;
                        background: #26597d;
                        border: none;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 8px 25px;
                        font-weight: 500;
                        font-size: 14px;
                        outline: none;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}
.modal-header.dk-qualifyAddnewheader .dkqualifyaddtitle.modal-title.h4 {
    color: #fff;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
}
.modal-header.dk-qualifyAddnewheader .close{
    color: #fff;
    opacity: 1;
}
.modal-header.dk-qualifyViewheader .dkqualifyviewtitle.modal-title.h4 {
    color: #fff;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
}
.modal-header.dk-qualifyEditHeader .dkqualifyedittitle.modal-title.h4 {
    color: #333;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
}
.modal-header.dk-qualifyEditHeader .close {
    opacity: 1;
}
