.dk-kipdashMainCon {
    margin-top: 170px;

    .dashsideMenuCon {
        .dk-kpidash-nav-pills {
            .dk-kpidashTab {
                border-bottom: 1px solid #686d72;
                background: #26597d;

                .nav-link {
                    color: #fff;
                    border-radius: 0;
                    font-size: .85rem;
                }
            }
        }
    }

    .dk-tabPaneCon {
        .dk-kpiColsMain {
            padding: 0;
            height: auto;
            background: transparent;

            .dk-kpiTable {
                .table-responsive::-webkit-scrollbar {
                    width: 0px;
                }

                .table-responsive::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
                }

                .table-responsive::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    outline: 0px solid slategrey;
                }
            }
        }
    }

    .dashsideMenuCon {
        margin-bottom: 0;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dk-monthList {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dk-candidateSearch-box {
                margin-left: 6px;

                .dropdown {
                    .dropdown-toggle {
                        background: #2e75b6 !important;
                        border-radius: 3px;
                        box-shadow: none;
                        color: #fff !important;
                        font-family: Roboto, sans-serif !important;
                        font-size: 13px;
                        margin: 0;
                        min-width: 110px;
                        padding: 7px 10px;

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        background: #fff;
                        border: none;
                        border-radius: 0;
                        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
                        margin: 35px 0 0 !important;
                        min-width: 110px;
                        padding: 0;
                        -webkit-transform: inherit !important;
                        transform: inherit !important;

                        a.active {
                            background: #2e75b6;
                            color: #fff;
                            font-size: 14px;
                        }

                        a {
                            padding: 6px 10px;
                            text-decoration: none;
                            text-align: inherit;
                            white-space: nowrap;
                            width: 100%;
                            display: block;
                            font-weight: 400;
                            border: 0;
                            clear: both;
                        }
                    }
                }
            }

            ul {
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
                margin-top: 10px;
                margin-right: 5px;

                li {
                    list-style-type: none;

                    &:first-child {
                        a {
                            border-left: 1px solid #616167;
                            border-radius: 3px 0 0 3px;
                        }
                    }

                    &:last-child {
                        a {
                            border-radius: 0 3px 3px 0;
                        }
                    }

                    a {
                        background: #4a5057;
                        border-bottom: 1px solid #616167;
                        border-right: 1px solid #616167;
                        border-top: 1px solid #616167;
                        color: #fff;
                        cursor: pointer;
                        float: left;
                        padding: 7px 25px;
                        text-align: center;
                        width: 85px;
                    }

                    a.active {
                        background: #2e75b6;
                    }
                }
            }
        }

        .dk-kpidashTab {
            background: transparent !important;
            border: none !important;
            padding: 8px 0;

            a {
                padding: 0;
                margin-right: 22px;
                color: #333 !important;
                font-size: 14px !important;
                padding-bottom: 3px;
                border-bottom: 3px solid transparent;
            }

            a.active {
                color: #26597d !important;
                background: transparent !important;
                border-bottom: 3px solid #26597d;
                text-align: center;
            }
        }
    }

    .dk-kpidashMainCon {
        padding-left: 0;
        background: #eaeaea;
        position: relative;

        .dk-kpiLeftPanel {
            width: 100%;
            max-width: 170px;
            margin-right: 25px;

            ul {
                background: #244158;
                border-radius: 0 0 5px 0;
                height: calc(100vh - 172px);
                list-style: none;
                margin: 0;
                min-height: auto;
                overflow-y: auto;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    width: 100%;

                    a {
                        border-bottom: 1px solid #3e5b73;
                        color: #ddd;
                        display: block;
                        font-family: Roboto, sans-serif !important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 15px;
                        text-transform: inherit;
                        width: 100%;
                    }

                    a.active {
                        background: #eaeaea;
                        color: #333;
                    }
                }
            }
        }

        .dk-kpiRightPanel {
            width: 100%;
        }
    }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .col-md-2.dashsideMenuCon {
        flex: 0 0 10.666667%;
        max-width: 10.666667%;
    }

    .col-md-10.dk-kpidashMainCon {
        flex: 0 0 89.333333%;
        max-width: 89.333333%;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .col-md-2.dashsideMenuCon {
        flex: 0 0 10.666667%;
        max-width: 10.666667%;
    }

    .col-md-10.dk-kpidashMainCon {
        flex: 0 0 89.333333%;
        max-width: 89.333333%;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .col-md-2.dashsideMenuCon {
        flex: 0 0 10.666667%;
        max-width: 10.666667%;
    }

    .col-md-10.dk-kpidashMainCon {
        flex: 0 0 89.333333%;
        max-width: 89.333333%;
    }
}