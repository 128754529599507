.dkg-target-summMain {
    margin-top: 155px;
    padding-top: 15px;
    overflow-y: auto;
    height: calc(100vh - 155px);

    .dkg-target-summCon {
        .dk-kpiCaseHead {
            padding: 0 45px;
            width: 100%;
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 25px;

            .dk-sendoutCaseSt-rightPanel {
                display: flex;
                align-items: center;

                button.dk-filterBtn {
                    border: 1px solid #7d7d87;
                    background: transparent;
                    margin-right: 10px;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;
                    min-width: 35px;
                    outline: none;

                    &:hover {
                        background: #7d7d87;
                        transition: all 0.5s;
                        color: #374252;
                    }
                }

                .dropdown {
                    .dropdown-toggle {
                        margin: 0;
                        border-radius: 3px;
                        background: #2e75b6 !important;
                        color: #fff !important;
                        font-size: 13px;
                        box-shadow: none;
                        font-family: "Roboto", sans-serif !important;
                        padding: 7px 10px;
                        min-width: 110px;

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        transform: inherit !important;
                        min-width: 110px;
                        border-radius: 0;
                        background: #fff;
                        border: none;
                        padding: 0;
                        margin: 35px 0 0 !important;
                        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
                        z-index: 99999;

                        a {
                            padding: 6px 10px;
                            font-size: 13px;
                        }

                        a.active {
                            font-size: 14px;
                            background: #2e75b6;
                        }
                    }
                }
            }

            .dk-kpiCase-yearsDropdown {
                display: flex;
                align-items: center;

                .dk-searchBox {
                    max-width: 190px;
                }

                .addMonthBtn {
                    border: 1px solid #7d7d87;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;
                    min-width: 35px;

                    &:hover {
                        background: #7d7d87;
                        transition: all 0.5s;
                        color: #374252;
                    }
                }

                .dropdown-toggle {
                    background: #3c97b6 !important;
                    border-radius: 3px;
                    box-shadow: none;
                    color: #fff !important;
                    font-size: 13px;
                    margin: 0;
                    min-width: 95px;
                    padding: 9px 10px;
                    text-align: left;
                    position: relative;
                    &::after {
                        position: absolute ;
                        top: 17px;
                        right:  10px;
                       font-size: 16px;
                    }
                }

                .dropdown-menu {
                    background: #fff;
                    border: none;
                    border-radius: 0;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    margin: 35px 0 0 !important;
                    min-width: 95px;
                    padding: 0;
                    -webkit-transform: inherit !important;
                    transform: inherit !important;
                    z-index: 99999;

                    a {
                        padding: 6px 10px;
                        font-size: 13px;

                        &:hover {
                            background: #f8f9fa;
                        }
                    }

                    a.active {
                        background: #3c97b6;
                    }
                }
            }

            .dk-kpiCase-months {
                display: flex;
                justify-content: flex-end;
                margin-left: 0;
                width: 100%;
                flex-wrap: wrap;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: flex-end;

                    li {
                        display: inline-block;
                        list-style-type: none;
                        width: 100%;
                        max-width: 7.33%;

                        &:first-child {
                            a {
                                border-left: 1px solid #ddd;
                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;
                            }
                        }
                        &:last-child{
                            a{
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;
                            }
                        }
                        a {
                            background: #eaeaea;
                            border-bottom: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            border-top: 1px solid #ddd;
                            color: #333;
                            cursor: pointer;
                            float: left;
                            padding: 7px 15px;
                            text-align: center;
                            width: 100%;
                        }

                        a.active {
                            background: #3c97b6;
                            color: #fff;
                        }

                    }
                }

                .addMonthBtn {
                    border: 1px solid #7d7d87;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;

                    &:hover {
                        background: #7d7d87;
                        color: #fff;
                        transition: all .5s;
                    }
                }
            }
        }

        .dkg-target-summ-pageCon {
            // height: calc(100vh - 163px);

            .dk-kpiCaseHead {
                padding: 0 15px;
                width: 100%;
                max-width: 140px;
                flex-wrap: wrap;

                .dk-sendoutCaseSt-rightPanel {
                    display: flex;
                    align-items: center;

                    button.dk-filterBtn {
                        border: 1px solid #7d7d87;
                        background: transparent;
                        margin-right: 10px;
                        border-radius: 50%;
                        color: #7d7d87;
                        display: inline-block;
                        font-size: 15px;
                        height: 35px;
                        line-height: 35px;
                        margin-left: 12px;
                        text-align: center;
                        width: 35px;
                        min-width: 35px;
                        outline: none;

                        &:hover {
                            background: #7d7d87;
                            transition: all 0.5s;
                            color: #374252;
                        }
                    }

                    .dropdown {
                        .dropdown-toggle {
                            margin: 0;
                            border-radius: 3px;
                            background: #2e75b6 !important;
                            color: #fff !important;
                            font-size: 13px;
                            box-shadow: none;
                            font-family: "Roboto", sans-serif !important;
                            padding: 7px 10px;
                            min-width: 110px;

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            transform: inherit !important;
                            min-width: 110px;
                            border-radius: 0;
                            background: #fff;
                            border: none;
                            padding: 0;
                            margin: 35px 0 0 !important;
                            box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
                            z-index: 99999;

                            a {
                                padding: 6px 10px;
                                font-size: 13px;
                            }

                            a.active {
                                font-size: 14px;
                                background: #2e75b6;
                            }
                        }
                    }
                }

                .dk-kpiCase-yearsDropdown {
                    display: flex;
                    align-items: center;

                    .dk-searchBox {
                        max-width: 190px;
                    }

                    .addMonthBtn {
                        border: 1px solid #7d7d87;
                        border-radius: 50%;
                        color: #7d7d87;
                        display: inline-block;
                        font-size: 15px;
                        height: 35px;
                        line-height: 35px;
                        margin-left: 12px;
                        text-align: center;
                        width: 35px;
                        min-width: 35px;

                        &:hover {
                            background: #7d7d87;
                            transition: all 0.5s;
                            color: #374252;
                        }
                    }

                    .dropdown-toggle {
                        background: #3c97b6 !important;
                        border-radius: 3px;
                        box-shadow: none;
                        color: #fff !important;
                        font-size: 13px;
                        margin: 0;
                        min-width: 110px;
                        padding: 7px 10px;
                        z-index: 99999;

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        background: #fff;
                        border: none;
                        border-radius: 0;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                        margin: 35px 0 0 !important;
                        min-width: 110px;
                        padding: 0;
                        -webkit-transform: inherit !important;
                        transform: inherit !important;
                        z-index: 99999;

                        a {
                            padding: 6px 10px;
                            font-size: 13px;

                            &:hover {
                                background: #f8f9fa;
                            }
                        }

                        a.active {
                            background: #3c97b6;
                        }
                    }
                }

                .dk-kpiCase-months {
                    display: flex;
                    justify-content: flex-end;
                    margin-left: 0;
                    width: 100%;
                    flex-wrap: wrap;
                    margin-top: 10px;

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        width: 100%;
                        flex-wrap: wrap;

                        li {
                            display: inline-block;
                            list-style-type: none;
                            width: 100%;
                            max-width: 100%;

                            &:first-child {
                                a {
                                    border-left: 1px solid #ddd;
                                }
                            }

                            a {
                                background: #eaeaea;
                                border-bottom: 1px solid #ddd;
                                border-right: 1px solid #ddd;
                                border-top: 1px solid #ddd;
                                color: #333;
                                cursor: pointer;
                                float: left;
                                padding: 7px 15px;
                                text-align: center;
                                width: 100%;
                            }

                            a.active {
                                background: #3c97b6;
                                color: #fff;
                            }

                        }
                    }

                    .addMonthBtn {
                        border: 1px solid #7d7d87;
                        border-radius: 50%;
                        color: #7d7d87;
                        display: inline-block;
                        font-size: 15px;
                        height: 35px;
                        line-height: 35px;
                        margin-left: 12px;
                        text-align: center;
                        width: 35px;

                        &:hover {
                            background: #7d7d87;
                            color: #fff;
                            transition: all .5s;
                        }
                    }
                }
            }

            .dkg-trsumm-moduleCon {
                margin-top: 0;
                width: 100%;
                display: flex;
                // height: calc(100vh - 225px);

                .dkg-target-boxcols.w-dk {
                    width: 45%;

                    .dkg-target-summ-box {
                        max-width: 100%;
                        margin-bottom: 12px;
                        height: calc(100vh - 187px);

                        .dkg-target-summ-boxBody {
                            table {
                                tr {
                                    td {
                                        &:first-child {
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .dkg-target-boxcols {
                    display: flex;
                    // margin: auto;
                    // max-width: 1100px;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;

                    .dkg-target-summ-box {
                        display: inline-block;
                        margin: 0 15px 25px;
                        width: 100%;
                        // max-width: 45%;
                        max-width: 30%;
                        border-radius: 5px;
                        border: 1px solid #ddd;
                        background-color: #F1F6FC;

                        .dkg-target-summ-boxHeader {
                            align-items: center;
                            background: #eaeaea;
                            border-bottom: 1px solid #ddd;
                            border-radius: 5px 5px 0 0;
                            display: flex;
                            justify-content: center;
                            text-transform: uppercase;
                            width: 100%;

                            .dkg-target-summ-Headertitle {
                                color: #646060;
                                font-size: 16px;
                                font-weight: 600;
                                padding: 8px 10px;
                                text-transform: uppercase;
                            }
                        }

                        .dkg-target-summ-boxBody {
                            // min-height: 215px;
                            max-height: 280px;
                            overflow-y: auto;

                            .dkg-target-summ-boxtable {
                                border-top: none;
                                margin-bottom: 0;

                                thead {
                                    tr {
                                        th {
                                            text-align: center;
                                            // font-size: 15px;
                                            font-weight: 500;
                                            vertical-align: middle;
                                            border: none;
                                            border-top: none;
                                            padding: 7px 0;
                                            color: #6c757d;
                                            font-size: 14px;
                                            // font-weight: 600;
                                            text-transform: capitalize;
                                            padding-top: 15px;
                                            background-color: #F1F6FC;
                                        }
                                    }
                                }

                                tbody {
                                    tr {
                                        td {
                                            border: none;
                                            vertical-align: middle;
                                            text-align: center;
                                            color: #2c71b2 !important;
                                            padding: 7px 10px;
                                            width: 15%;
                                            background-color: #F1F6FC;
                                            // &:first-child {
                                            //     text-align: left;
                                            // }
                                            &.dkg-targets-jobofffers {
                                                color: #d58b5d !important;
                                            }

                                            &.dkg-targets-refused {
                                                color: #d91010 !important;
                                            }

                                            &.dkg-targets-netsale {
                                                color: #3a9469 !important;
                                            }

                                            &.dkg-targets-black-color &.dkg-target-summ-text {
                                                color: #2c71b2 !important;
                                            }

                                            &.dkg-targets-black-color {
                                                color: #333 !important;
                                            }

                                            &.dkg-target-summ-text {
                                                &.dkg-targets-black-color {
                                                    color: #333 !important;
                                                }
                                            }

                                            .dk-ManagerImg {
                                                text-align: center;

                                                img {
                                                    display: inline-block;
                                                    width: 35px;
                                                    height: 35px;
                                                    border-radius: 50%;
                                                }
                                            }
                                        }

                                        &:first-child {
                                            td {
                                                border-top: none;
                                                border-left: none;

                                                &:first-child {
                                                    border-left: none;
                                                }

                                                &:last-child {
                                                    border-right: none;
                                                }
                                            }
                                        }

                                        &:last-child {
                                            border-bottom: none;

                                        }
                                    }
                                }

                                &.dkg-clientsales-cols {
                                    thead {
                                        tr th {
                                            font-weight: normal !important;
                                            text-transform: capitalize;
                                            background-color: #F1F6FC;
                                        }
                                    }

                                    tbody {
                                        tr {
                                            td {
                                                background-color: #F1F6FC;
                                                &:first-child {
                                                    color: #333;
                                                }

                                                &.dkg-targets-jobofffers {
                                                    color: #d58b5d !important;
                                                }

                                                &.dkg-targets-refused {
                                                    color: #d91010 !important;
                                                }

                                                &.dkg-targets-netsale {
                                                    color: #3a9469 !important;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.dkg-users-table-cols {
                                    tbody {
                                        tr {
                                            td {
                                                padding: 3px 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }
}