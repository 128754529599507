.dk-resIncmetaxPageMain{
    margin-top: 120px;
    .dk-reSLeftheaderCont{
        input.form-control.dk-topSerchInput {
            max-width: 127px;
            border-right: none;
            background-color: #f2f2f2;
            border-radius: 0;
            font-size: .8rem;
            padding: .5rem;
            border: 1px solid #ddd;
            border-right: 0;
            height: 34px;
        }
        input.form-control.dk-topSerchInput:focus{
            outline: 0;
            box-shadow: none;
        }
        .input-group-append.dk-toplefSerchIcon {
            margin-left: 0;
            height: 34px;
            button, button:hover {
                padding: .49rem .65rem!important;
                margin-top: 0;
                background-color: #f2f2f2!important;
                color: #333;
                border: 1px solid #ddd;
                box-shadow: none;
                margin-bottom: 0;
                box-shadow: none;
                border-left: none;
            }
        }
        a.addRdrbtn, a.addRdrbtn:hover {
            padding: .49rem .65rem!important;
            margin-top: 0;
            background-color: #f2f2f2!important;
            color: #333;
            border: 1px solid #ddd;
            box-shadow: none;
            margin-bottom: 0;
            height: 34px;
        }
    }
    .dk-reSCenterheaderCont{
        h2.dk-reSPageTitle {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            margin-top: 4px;
            margin-bottom: 0;
            width: 100%;
            text-align: center;
        }
    }
    .dk-reSRightheaderCont{
        display: flex;
        justify-content: flex-end;
        .dk-resTopRightBtnCont{
            a.dk-projRightbtn {
                background: #f2f2f2!important;
                border: 1px solid #ccc;
                text-transform: uppercase;
                font-size: 14px;
                outline: none;
                color: #333;
                max-width: 150px;
                padding: .334rem .57rem;
                box-shadow: none;
                margin-top: 0;
                margin-bottom: 0;
            }
        
        }
    }
    .dk-ResloginTableCon {
        width: 100%;
        overflow-y: auto;
        height: 100vh;
        max-height: 75vh;
        padding-bottom: 60px;
        padding-left: 15px;
        padding-right: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .dk-ResloginTable{
            display: table;
            width: 100%;
            thead {
                tr th {
                    border-bottom: none;
                    padding: 10px 18px;
                    background: #fff;
                    text-align: center;
                    vertical-align: middle;
                    border-top: 0;
                    font-weight: normal;
                }
               
                tr th:nth-child(02){
                    min-width: 120px;
                }
                tr th:nth-child(03){
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                tr th:nth-child(04){
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                tr th:nth-child(05){
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                tr th:nth-child(07){
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                } 
                tr th:nth-child(06){
                    max-width: 220px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            tbody{
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                tr td {
                    padding: 10px 18px!important;
                    white-space: nowrap;
                    background-color: #eaeaea!important;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    .dk-inComeTableIcn {
                        display: flex;
                        justify-content: flex-start; 
                        align-items: center;
                        img {
                            margin-right: 1rem;
                            height: 40px;
                            width: 40px;
                            border-radius: 0;
                        } 
                    }
                    a.dk-calCulateBtn, a.dk-calCulateBtn:hover {
                        display: inline-block;
                        padding: .35rem .75rem;
                        background-color: #1b6867;
                        color: #fff!important;
                        border-radius: 5px;
                    }
                    .dk-resAction{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            font-size: 16px;
                            border-radius: 3px;
                        }
                        a.dk-view-btn {
                            background-color: #2196f3;
                            color: #fff;
                        }
                        a.dk-edit-btn {
                            background-color: #fee69c;
                            color: #333;
                        }
                        a.dk-delete-btn {
                            background-color: #dc3545;
                            color: #fff;
                        }
                    }
                }
                tr td:nth-child(02){
                    min-width:d120px;
                }
                tr td:dth-child(03){
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                tr td:nth-child(04){
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                tr td:nth-child(05){
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                tr td:nth-child(07){
                    max-width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                } 
                tr td:nth-child(06){
                    max-width: 220px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            } 
        }
    }
}