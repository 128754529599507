.dkg-verifyemail-mainCon{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    height: calc(100vh - 110px);
    .dkg-verifyemail-topsec{
        height: 30%;
        background-color: #eaeaea;
        padding:15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        margin-top: 35px;
        .dkg-verifyemail-Header{
            margin-bottom: 0.8rem;
            width: 100%;
            max-width: 60%;
            margin-right: auto;
            .dkg-emailverify-label{
                font-size: 1rem;
                color: #4d4a4a;
                margin-bottom: 0;
                text-transform: uppercase;
            }
            .dkg-ev-inputfiledCon{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .dkg-input-emailverify{
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    max-width: 100%;
                    height: 42px;
                    text-align: left;
                }
                .dkg-verify-email-btnCon{
                    div{
                        div{
                            .dkg-verify-email-btn{
                                background-color: #26597d;
                                color: #fff;
                                text-transform: capitalize;
                                font-size: 1.10rem;
                                padding: 0.53rem 0.75rem;
                                display: inline-block;
                                min-width: 130px;
                            }
                            .dkg-without-bg-btn{
                                display: inline-block;
                                background-color: transparent !important;
                                color: #26597d;
                                min-width: 145px;
                                text-transform: capitalize;
                                font-size: 1.10rem;
                                padding: 0.53rem 0.75rem;
                                box-shadow: none;
                                &:hover{
                                    background-color: #f2f2f2 !important;
                                    color: #333 !important;
                                }
                            }
                        }
                    }
                    .dkg-verify-email-btn{
                        background-color: #26597d;
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.10rem;
                        padding: 0.53rem 0.75rem;
                        display: inline-block;
                        min-width: 130px;
                    }
                    .dkg-without-bg-btn{
                        display: inline-block;
                        background-color: transparent !important;
                        color: #26597d;
                        min-width: 145px;
                        text-transform: capitalize;
                        font-size: 1.10rem;
                        padding: 0.53rem 0.75rem;
                        box-shadow: none;
                        &:hover{
                            background-color: #f2f2f2 !important;
                            color: #333 !important;
                        }
                    }
                }
            }
        }
        .dkg-verifyemail-tableCon{
            padding-bottom: 0;
            position: relative;
            margin-top: 10px;
            .dkg-verifyemail-table{
                thead{
                    tr{
                        th{
                            background-color: #d9dfe7;
                            border: 1px solid #ccc;
                            border-bottom: none;
                            color: #333;
                            font-size: 13px;
                            font-weight: 700;
                            padding: 9px 7px;
                            position: -webkit-sticky;
                            position: sticky;
                            text-align: center;
                            top: 0;
                            vertical-align: middle;
                            white-space: nowrap;
                            z-index: 9;
                            text-transform: capitalize;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                           background-color: #fff;
                            border: 1px solid transparent;
                            color: #4d4a4a;
                            font-size: 13px;
                            font-weight: 400;
                            padding:0 7px!important;
                            position: relative;
                            text-align: left;
                            vertical-align: middle;
                            white-space: nowrap;
                            height: 37px;
                            &:nth-child(01){
                                text-align: center;
                                padding: 0 !important;
                                .dkg-status-cols{
                                    height: 40px;
                                    line-height: 40px;
                                }
                                .dkg-status-drpcon {
                                    .dkg-status-drpdown {
                                        position: relative;
                                
                                        button.dkg-status-drpdown-toggle.dropdown-toggle.btn,
                                        button.dkg-status-drpdown-toggle.dropdown-toggle.btn:hover {
                                            box-shadow: none;
                                            background-color: transparent;
                                            padding: 0 !important;
                                            margin: 0;
                                            white-space: nowrap;
                                            font-size: 13px;
                                            text-transform: capitalize;
                                            color: #fff;
                                            width: 100%;
                                
                                            .caseDiv {
                                                min-height: 35px;
                                                line-height: 35px;
                                                padding: 0 10px;
                                            }
                                        }
                                
                                        button.dkg-status-drpdown-toggle.dropdown-toggle::after {
                                            display: none;
                                        }
                                
                                        .dkg-status-drpdownmenu.dropdown-menu {
                                            border-radius: 0;
                                            padding: 0;
                                            transform: translate(-1px, 35px) !important;
                                
                                            // border: none;
                                            .dkg-status-drpdown-item {
                                                text-align: left;
                                                font-size: 13px;
                                                padding: 6px 10px;
                                                border-bottom: 1px solid #aaa;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.dkg-verifyemail-table-2{
                    tbody{
                        tr{
                            td{
                                background-color: #f2f2f2;
                            }
                        }
                    }
                }
            }
        }
    }
    .dkg-verifyemail-devideline{
        // border: 1px solid #ddd;
        // position: relative;
        // top: 0px;
        height: 8%;
    }
    .dkg-verifyemail-bottomsec{
        height: 30%;
        background-color: #D9DFE7;
        padding:15px;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        .dkg-verifyemail-Header{
            margin-bottom: 0.8rem;
            width: 100%;
            max-width: 60%;
            margin-right: auto;
            .dkg-emailverify-label{
                font-size: 1rem;
                color: #4d4a4a;
                margin-bottom: 0;
                text-transform: uppercase;
            }
            .dkg-ev-inputfiledCon{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .dkg-input-emailverify{
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    max-width: 100%;
                    height: 42px;
                    text-align: left;
                }
                .dkg-verify-email-btnCon{
                    div{
                        div{
                            .dkg-verify-email-btn{
                                display: inline-block;
                                background-color: #26597d;
                                color: #fff;
                                text-transform: capitalize;
                                font-size: 1.10rem;
                                padding: 0.53rem 0.75rem;
                                min-width: 130px;
                            }
                             .dkg-without-bg-btn{
                                display: inline-block;
                                background-color: transparent !important;
                                color: #26597d;
                                min-width: 145px;
                                text-transform: capitalize;
                                font-size: 1.10rem;
                                padding: 0.53rem 0.75rem;
                                box-shadow: none !important;
                                &:hover{
                                    background-color: #f2f2f2 !important;
                                    color: #333 !important;
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
        .dkg-verifyemail-tableCon{
            padding-bottom: 0;
            position: relative;
            margin-top: 10px;
            .dkg-verifyemail-table{
                thead{
                    tr{
                        th{
                            background-color: #eaeaea;
                            border: 1px solid transparent;
                            border-bottom: none;
                            color: #333;
                            font-size: 13px;
                            font-weight: 700;
                            padding: 9px 7px;
                            position: -webkit-sticky;
                            position: sticky;
                            text-align: center;
                            top: 0;
                            vertical-align: middle;
                            white-space: nowrap;
                            z-index: 9;
                            text-transform: capitalize;
                        }
                    }
                }
                tbody{
                    
                    tr{
                        td{
                           background-color: #fff;
                            border: 1px solid #ddd;
                            color: #4d4a4a;
                            font-size: 13px;
                            font-weight: 400;
                            padding:0 7px!important;
                            position: relative;
                            text-align: left;
                            vertical-align: middle;
                            white-space: nowrap;
                            height: 37px;
                            &:nth-child(01){
                                text-align: center;
                                padding: 0 !important;
                                .dkg-status-cols{
                                    height: 40px;
                                    line-height: 40px;
                                }
                                .dkg-status-drpcon {
                                    .dkg-status-drpdown {
                                        position: relative;
                                
                                        button.dkg-status-drpdown-toggle.dropdown-toggle.btn,
                                        button.dkg-status-drpdown-toggle.dropdown-toggle.btn:hover {
                                            box-shadow: none;
                                            background-color: transparent;
                                            padding: 0 !important;
                                            margin: 0;
                                            white-space: nowrap;
                                            font-size: 13px;
                                            text-transform: capitalize;
                                            color: #fff;
                                            width: 100%;
                                
                                            .caseDiv {
                                                min-height: 35px;
                                                line-height: 35px;
                                                padding: 0 10px;
                                            }
                                        }
                                
                                        button.dkg-status-drpdown-toggle.dropdown-toggle::after {
                                            display: none;
                                        }
                                
                                        .dkg-status-drpdownmenu.dropdown-menu {
                                            border-radius: 0;
                                            padding: 0;
                                            transform: translate(-1px, 35px) !important;
                                
                                            // border: none;
                                            .dkg-status-drpdown-item {
                                                text-align: left;
                                                font-size: 13px;
                                                padding: 6px 10px;
                                                border-bottom: 1px solid #aaa;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.dkg-verifyemail-table-2{
                    tbody{
                        tr{
                            td{
                                background-color: #f2f2f2;
                            }
                        }
                    }
                }
            }
        }
    }
}