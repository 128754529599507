.modal-dialog.dk-addNewResModalDialog {
    min-width: 40%;
    .modal-content {
        background-color: transparent;
        .modal-header.dk-resourcePopupHedaer {
            border-radius: 0;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            background-color: #26597d;
            padding: 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            .modal-title {
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
            }
            .close{
                color: #fff;
                opacity: 1;
            }
        }
        .modal-body{
            padding: 10px 25px 20px;
            background-color: #fff;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .dk-resourcePopupbtnCont {
                width: 100%;
                text-align: center;
                button, button:hover{
                    background: #26597d!important;
                    border: none;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 8px 25px;
                    font-weight: 500;
                    font-size: 14px;
                    outline: none;
                    cursor: pointer;
                    border-radius: 5px;
                }
            }
            form{
                .form-group.dk-imgRowDiv{
                    margin-bottom: 3rem;
                }
                .form-group {
                    display: -webkit-flex;
                    display: flex;
                    margin-bottom: 1rem;
                    -webkit-align-items: center;
                    align-items: center;
                    label {
                        min-width: 160px;
                        font-size: 14px;
                        margin-bottom: 0;
                        text-align: left!important;
                    }
                    .dk-flagUploadFilter {
                        padding-top: 2rem;
                        .dk-imgincFlagCon {
                            width: 100%;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-align-items: center;
                            align-items: center;
                            img {
                                position: absolute;
                                right: 16px;
                                top: 0;
                                width: 130px;
                                height: 110px;
                                padding-bottom: 2rem;
                            }
                        }
                    }
                    input.form-control {
                        background-color: #fff;
                        border-radius: 0;
                        font-size: .87rem;
                        padding: .5rem;
                        border: 1px solid #c1c9dc !important;
                        text-align: center;
                        box-shadow: none;
                        height: 31px;
                    }
                }
                .dk-addresbtnCont{
                    width: 100%;
                    text-align: center;
                    button, button:hover{
                        background: #26597d!important;
                        border: none;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 8px 25px;
                        font-weight: 500;
                        font-size: 14px;
                        outline: none;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                }

            }
        }
    }
}