.dk-todayMainPageCon {
    margin-top: 105px;
}

.dk-todayMainPageCon {
    background-color: #fff !important;
    padding: 0 15px;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsLeft {
    display: flex;
    align-items: center;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsLeft .dk-mailshotsSearch .input-group {
    width: 100%;
    max-width: 140px;
    margin-right: 8px;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsLeft .dk-mailshotsSearch .input-group input {
    border: 1px solid #ddd !important;
    background-color: #eaeaea;
    padding: 0 10px;
    padding-right: 30px;
    box-shadow: none !important;
    border-radius: 0;
    height: 35px;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsLeft button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #eaeaea !important;
    color: #333;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsLeft button:active {
    background: #eaeaea !important;
    border-color: 1px solid #ddd !important;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsLeft .dk-filterUserTabs.dropdown svg {
    right: -8px;
    top: 0px;
}

.dk-todayMainPageCon .dk-todayadsPageHead h2.title {
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 8px;
    text-transform: uppercase;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsTopRight button {
    display: inline-block;
    background: #eaeaea;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 0;
    outline: 0;
    font-size: 15px;
    font-weight: 400;
    width: 35px;
    height: 35px;
    /* border-radius: 50%; */
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsTopRight .dk-reSLoginFilter .dk-cli-status-dropdown.dropdown .dropdown-toggle {
    margin: 0;
    padding: 5px 10px;
    border-radius: inherit;
    width: inherit;
    box-shadow: none;
    background-color: #eaeaea !important;
    color: #333;
    font-size: 14px;
    text-transform: capitalize;
    min-width: 110px;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsTopRight .dk-reSLoginFilter .dk-cli-status-dropdown.dropdown .dropdown-menu {
    border-radius: 0;
    padding: 0;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsTopRight .dk-reSLoginFilter .dk-cli-status-dropdown.dropdown .dropdown-menu .dropdown-item {
    padding: 8px 10px;
    position: relative;
}

.dk-todayadsTopRight .dk-reSLoginFilter .dk-cli-status-dropdown.dropdown .dropdown-menu .dropdown-item .dkg-lv-statuscounter {
    position: absolute;
    right: 8px;
    color: #fff;
    background-color: #234158;
    padding: 1px 5px;
    font-size: 12px;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsTopRight .dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect {
    min-width: 135px;
    max-width: 135px !important;
}

.dk-todayAdsTableCon {
    cursor: pointer;
    overflow-y: inherit;
    height: inherit;
    padding-bottom: 10px;
    position: relative;
}

.dk-todayAdsTableCon .dk-todayAdsTable thead tr th {
    background: #252c37;
    color: #e2dada;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 15px;
    border: 1px solid #616767;
    border-bottom: none;
    vertical-align: middle;
    position: sticky;
    top: 0;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td {
    background: #2C394B !important;
    color: #bdb4b4;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    padding: 5px 15px;
    border: 1px solid #5a6169;
    vertical-align: middle;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td .dk-todayAdimgCon img.img-todayAdCricle {
    min-height: 22px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td .dk-adsStatusBtn .dropdown button.makeStyles-tblDdlActive-6 {
    margin: 0;
    padding: .375rem 1rem !important;
    color: #ffffff !important;
    min-width: 90px;
    background-color: #31a886 !important;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td .dk-adsStatusBtn .dropdown button.makeStyles-tblDdlInActive-7 {
    margin: 0;
    padding: .375rem 1rem !important;
    color: #ffffff !important;
    min-width: 90px;
    background-color: #dc3545 !important;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td .dk-adsStatusBtn .dropdown button.dropdown-toggle::after {
    display: none;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu {
    padding: 0;
    border-radius: 0;
    min-width: 120px;
    max-width: 120px;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu a.dropdown-item:first-child {
    background-color: #31a886 !important;
    color: #fff;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu a.dropdown-item:last-child {
    background-color: #dc3545 !important;
    color: #fff;
}

.dk-todayAdsTableCon .dk-todayAdsTable tbody tr td .dk-adstractAction {
    display: flex;
    justify-content: center;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsLeft .dk-filterUserTabs.dropdown .dk-resuserFilterCon {
    position: relative;
}

.dk-todayMainPageCon .dk-todayadsPageHead .dk-todayadsLeft .dk-filterUserTabs.dropdown .dk-resuserFilterCon .crossUserFilter {
    position: absolute;
    right: -8px;
    top: -5px;
}