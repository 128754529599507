.dk-logins-office-mainPageCon {
    margin-top: 125px;

    .dk-login-officeTopHead {
        .dk-login-officeTopleft {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .dk-resSrchInpuBbox {
                width: 100%;
                max-width: 160px;

                .input-group {
                    input {
                        background-color: #fff;
                        border: 1px solid #d1cccc;
                        border-radius: 0;
                        box-shadow: none;
                        color: #495057;
                        font-size: 13px;
                        outline: none;
                        text-align: left;
                        border-right: none;

                        &:focus {
                            outline: 0;
                            box-shadow: none;

                            &::placeholder {
                                color: transparent;
                            }
                        }
                    }

                    .input-group-prepend {
                        .input-group-text {
                            background-color: #eaeaea !important;
                            border: 1px solid #d1cccc;
                            box-shadow: none;
                            color: #333;
                            margin: 0;
                            padding: 0 10px;

                            &:hover {
                                outline: 0;
                                background-color: #234158 !important;
                                border: 1px solid #234158;
                                color: #fff;
                                cursor: pointer;

                                i {
                                    color: #fff;
                                }
                            }

                            i {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }

            .dk-resCrcleIconCon {
                a {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-color: #eaeaea;
                    border: 1px solid #ddd;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #333;

                    &:hover {
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                    }
                }
            }
        }

        .dk-login-officeTopCentre {
            .dk-resPageTitle {
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 600;
                margin-top: 10px;
                margin-bottom: 0;
                width: 100%;
                text-align: center;
            }
        }

        .dk-login-officeTopRight {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .dk-resCrcleIconCon {
                a {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-color: #eaeaea;
                    border: 1px solid #ddd;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #333;

                    &:hover {
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                    }
                }
            }

            .dk-logins-rightFilter {
                .dkg-catedropdown {
                    .dropdown-toggle.btn.btn-primary {
                        box-shadow: none;
                        color: #797474;
                        padding: 0.435rem 0.75rem !important;
                        margin: 0;
                        font-family: Verdana, sans-serif;
                        font-size: 12px;
                        background: #fff !important;
                        border: 1px solid #c1c9dc;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 1.5385;
                        min-height: 2.304em;
                        white-space: nowrap;
                        border-radius: 0.1875rem;
                        min-width: 135px;
                        text-transform: capitalize;
                        position: relative;

                        &::after {
                            position: absolute;
                            right: 10px;
                        }
                    }

                    .dropdown-menu {
                        border-radius: 0;
                        margin: 0;
                        padding: 0;

                        .dkg-catedropdown-item {
                            border-bottom: 1px solid #ddd;
                            font-size: 12px;
                            padding: 7px 8px;
                            background-color: #fff;

                            &:hover {
                                background-color: #f2f2f2;
                            }

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }

            }
        }

        .dk-logins-officeTableCon {
            margin-top: 10px;
            overflow-y: auto;
            overflow-x: auto;
            height: calc(100vh - 177px);
            padding-bottom: 0;
            position: relative;

            .dk-logins-officeTable {
                margin-bottom: 0;
                box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);

                thead {
                    tr th {
                        border: none;
                        padding: 10px 15px;
                        background: #234158;
                        color: #fff;
                        text-align: center;
                        vertical-align: middle;
                        font-size: 13px;
                        border-top: 1px solid #234158;
                        vertical-align: middle;
                        position: sticky;
                        top: 0;
                        z-index: 9;
                        white-space: nowrap;
                    }
                }

                tbody {
                    tr td {
                        font-size: 13px;
                        border: none;
                        padding: 10px 18px;
                        white-space: nowrap;
                        background-color: #eaeaea;
                        font-weight: 400;
                        text-align: center;
                        vertical-align: middle;

                        .dk-logins-statusdropdown {
                            .dropdown-toggle.btn.btn-primary {
                                min-width: 90px;
                                background-color: #31a886 !important;
                                margin: 0;
                                padding: 0.5rem 1rem;
                                color: #fff;
                                font-size: 0.85rem;

                                &::after {
                                    display: none;
                                }
                            }

                            .dropdown-menu {
                                border-radius: 0;
                                margin: 0;
                                padding: 0;
                                min-width: 120px !important;

                                .dk-statusActive {
                                    background-color: #31a886;
                                    color: #fff;
                                    font-size: 0.9rem;
                                    padding: 6px 10px;
                                }

                                .dk-statusInactive {
                                    background-color: #d8345f;
                                    color: #fff;
                                    font-size: 0.9rem;
                                    padding: 6px 10px;
                                }
                            }
                        }

                        .dk-gotobtn-urlCon {
                            .dk-gotobtn-url {
                                display: inline-block;
                                background-color: #4285f4 !important;
                                color: #fff !important;
                                border-radius: 0;
                                padding: 0.5rem 1rem;
                                font-size: 0.85rem;
                            }
                        }

                        .dk-resAction {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}