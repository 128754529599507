.dk-salesbookes-dashMain {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    margin-top: 125px;
    padding: 0 15px;
    padding-left: 6px;
    height: calc(100vh - 150px);
    overflow-y: auto;

    .dk-kpiCaseHead {
        padding: 0 12px;
        width: 100%;
        // max-width: 140px;
        // flex-wrap: wrap;
        display: flex;

        .dk-sendoutCaseSt-rightPanel {
            display: flex;
            align-items: center;

            button.dk-filterBtn {
                border: 1px solid #7d7d87;
                background: transparent;
                margin-right: 10px;
                border-radius: 50%;
                color: #7d7d87;
                display: inline-block;
                font-size: 15px;
                height: 35px;
                line-height: 35px;
                margin-left: 12px;
                text-align: center;
                width: 35px;
                min-width: 35px;
                outline: none;

                &:hover {
                    background: #7d7d87;
                    transition: all 0.5s;
                    color: #374252;
                }
            }

            .dropdown {
                .dropdown-toggle {
                    margin: 0;
                    border-radius: 3px;
                    background: #2e75b6 !important;
                    color: #fff !important;
                    font-size: 13px;
                    box-shadow: none;
                    font-family: "Roboto", sans-serif !important;
                    padding: 7px 10px;
                    min-width: 110px;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    transform: inherit !important;
                    min-width: 110px;
                    border-radius: 0;
                    background: #fff;
                    border: none;
                    padding: 0;
                    margin: 35px 0 0 !important;
                    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
                    z-index: 99999;

                    a {
                        padding: 6px 10px;
                        font-size: 13px;
                    }

                    a.active {
                        font-size: 14px;
                        background: #2e75b6;
                    }
                }
            }
        }

        .dk-kpiCase-yearsDropdown {
            display: flex;
            align-items: center;

            .dk-searchBox {
                max-width: 190px;
            }

            .addMonthBtn {
                border: 1px solid #7d7d87;
                border-radius: 50%;
                color: #7d7d87;
                display: inline-block;
                font-size: 15px;
                height: 35px;
                line-height: 35px;
                margin-left: 12px;
                text-align: center;
                width: 35px;
                min-width: 35px;

                &:hover {
                    background: #7d7d87;
                    transition: all 0.5s;
                    color: #374252;
                }
            }

            .dropdown-toggle {
                background: #5A4C72 !important;
                border-radius: 3px;
                box-shadow: none;
                color: #fff !important;
                font-size: 13px;
                margin: 0;
                min-width: 110px;
                padding: 7px 10px;
                z-index: 99999;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                background: #fff;
                border: none;
                border-radius: 0;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                margin: 35px 0 0 !important;
                min-width: 110px;
                padding: 0;
                -webkit-transform: inherit !important;
                transform: inherit !important;
                z-index: 99999;

                a {
                    padding: 6px 10px;
                    font-size: 13px;

                    &:hover {
                        background: #f8f9fa;
                    }
                }

                a.active {
                    background: #3c97b6;
                }
            }
        }

        .dk-kpiCase-months {
            display: flex;
            justify-content: flex-end;
            margin-left: 0;
            width: 100%;
            flex-wrap: wrap;
            margin-top: 2px;
            margin-bottom: 10px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: flex-end;
                width: 100%;
                flex-wrap: wrap;

                li {
                    display: inline-block;
                    list-style-type: none;
                    width: 100%;
                    max-width: 7.33%;

                    &:first-child {
                        a {
                            border-left: 1px solid #ddd;
                        }
                    }

                    a {
                        background: #eaeaea;
                        border-bottom: 1px solid #ddd;
                        border-right: 1px solid #ddd;
                        border-top: 1px solid #ddd;
                        color: #333;
                        cursor: pointer;
                        float: left;
                        padding: 7px 15px;
                        text-align: center;
                        width: 100%;
                    }

                    a.active {
                        background: #5A4C72;
                        border: 1px solid transparent;
                        color: #fff;

                    }

                }
            }

            .addMonthBtn {
                border: 1px solid #7d7d87;
                border-radius: 50%;
                color: #7d7d87;
                display: inline-block;
                font-size: 15px;
                height: 35px;
                line-height: 35px;
                margin-left: 12px;
                text-align: center;
                width: 35px;

                &:hover {
                    background: #7d7d87;
                    color: #fff;
                    transition: all .5s;
                }
            }
        }
    }

    .dk-leftBoxes-main {
        display: flex;
        width: 50%;
        flex-wrap: wrap;
        height: calc(100vh - 255px);

        .dk-leftBoxes-panel {
            width: 100%;
            display: flex;
            align-items: start;

            .dk-leftBoxes-cols {
                background: #eaeaea;
                border: 1px solid #cfcfcf;
                border-radius: 5px;
                width: 100%;
                margin: 12px;

                .dk-textPanel {
                    min-height: 245px;
                    max-height: 245px;

                    ul.dk-bottomLeftLists {
                        margin-bottom: 0;
                        max-height: 355px;
                        min-height: 355px;
                        overflow-y: auto;
                        padding-left: 0;

                        li {
                            align-items: center;
                            background: #eaeaea;
                            cursor: pointer;
                            display: flex;
                            font-size: 13px;
                            justify-content: space-between;
                            list-style-type: none;
                            padding: 5px 10px;
                            text-align: left;
                            width: 100%;

                            span {
                                display: inline-block !important;
                                margin-right: 2px;

                                &.rightItem {
                                    color: #2c71b2;
                                    cursor: pointer;
                                    display: inline-block;
                                    font-size: 13px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                // .dk-textPanel.sm-height {
                //     min-height: 210px;
                //     max-height: 210px;
                // }

                .title {
                    border-bottom: 1px solid #ccc;
                    background: #ddd;
                    text-transform: uppercase;
                    font-size: 14px;
                    display: block;
                    text-align: center;
                    padding: 8px 0;
                    border-radius: 5px 5px 0 0;
                }
            }
        }
    }

    .dk-rightBoxes-main {
        width: 50%;
        // margin-left: 6px;
        display: flex;

        .dk-leftBoxes-panel {
            width: 100%;
            margin: 6px 12px;

            .dk-leftBoxes-cols {
                background: #eaeaea;
                border: 1px solid #cfcfcf;
                border-radius: 5px;
                width: 100%;
                margin: 6px 0;

                .title {
                    border-bottom: 1px solid #ccc;
                    background: #ddd;
                    text-transform: uppercase;
                    font-size: 14px;
                    display: block;
                    text-align: center;
                    padding: 8px 0;
                    border-radius: 5px 5px 0 0;
                }

                .dk-textPanel {
                    height: calc(100vh - 255px);

                    ul.dk-bottomLeftLists {
                        margin-bottom: 0;
                        max-height: 355px;
                        min-height: 355px;
                        overflow-y: auto;
                        padding-left: 0;

                        li {
                            align-items: center;
                            background: #eaeaea;
                            cursor: pointer;
                            display: flex;
                            font-size: 13px;
                            justify-content: space-between;
                            list-style-type: none;
                            padding: 5px 10px;
                            text-align: left;
                            width: 100%;

                            span {
                                display: inline-block !important;
                                margin-right: 2px;

                                img.dkg-recuiter-img {
                                    border-radius: 50%;
                                    height: 30px;
                                    margin-right: 10px;
                                    width: 30px;
                                }

                                &.rightItem {
                                    color: #2c71b2;
                                    cursor: pointer;
                                    display: inline-block;
                                    font-size: 13px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }
}