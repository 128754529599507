.dkg-addnew-payment-modalheader-21{
    &.dkg-cvparse-modal-header{
       background-color: #3c97b6!important;
    //    .modal-title{
    //    }
    //    .close{
       
    //    }
    }
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}

.success {
    color: green;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}

.dkg-addnew-payment-modalbody-21{

    &.dkg-mailbox-cvsrc-modalbody{
        padding-top: 25px !important;
        .form-group{
            input{
                text-align: left;
                height: 35px;
            }
            .dkg-addnew-date-timeCon {
                .MuiFormControl-root {
                    

                    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                        input {
                            background-color: #fff;
                            height: 20px;
                            text-align: left !important;
                            padding-left: 7px;
                        }

                        .MuiInputAdornment-positionEnd {
                            margin-left: 0;

                            .MuiIconButton-root {
                                padding: 8px 7px !important;

                                // .MuiIconButton-label {
                                //     svg {
                                //         color: #fff;
                                //         font-size: 1.25rem;
                                //     }
                                // }
                            }
                        }
                    }
                }

                .dkg-readonly-datetime-picker {
                    color: #234158;

                    .MuiFormControl-root {
                        &.MuiFormControl-marginNormal {
                            margin: 0;
                            width: 100%;
                        }

                        .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                            input {
                                text-align: center;
                                background-color: #f2f2f2;
                                height: 35px;
                            }

                            .MuiInputAdornment-positionEnd .MuiIconButton-root {
                                cursor: text;
                            }
                        }
                    }

                }
            }
            .dkg-payment-task-drop12{
                .dropdown{
                    .dropdown-toggle{
                        justify-content: flex-start !important;
                    }
                }
                .MuiFormControl-root {
                    background: white;
                    .MuiInputBase-root.MuiOutlinedInput-root{
                        border-radius: 0;
                        .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input{
                            color: grey;
                            font-size: 13px;
                            font-style: normal;
                            em{
                                font-style: normal;
                            }
                        }
                        input{
                            color: grey;
                            font-size: 13px;
                            font-style: normal;
                        }
                    }
                }
            }
            .dkg-duplicate-btn{
                padding: 7px 10px ;
                background-color: #26597d;
                border-radius: 0;
                border: 1px solid #ccc;
                border-left: 0;
                svg{
                    color: #fff;
                }
                &:hover{
                    background-color: #3c97b6;
                    transition: ease-in 5sec;
                }
                &:focus{
                    outline: 0;

                }
            }
        }
        .dkg-parse-cv-bottomsec{
            color: #343434;
            margin-top: 40px;
            .dkg-select-filetitle{
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                border-top: 2px solid #ddd;
                color: #545454;
            }
            .dkg-select-fileCon{
                height: calc(100vh - 445px);
                overflow-y: auto;
                .dkg-select-file-list{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    list-style: none;
                    background-color: #ffffff;
                    padding: 7px 10px;
                    border: 1px solid #ddd;
                    border-bottom: none;
                    &:last-child{
                        border-bottom: 1px solid #ddd;
                    }
                    .dkg-file-list-leftCon{
                        input[type="checkbox"] {
                            background: #eaeaea;
                        }
                    }
                    .dkg-file-list-rightCon{
                        font-size: 1rem;
                    }
                    .dkg-file-list-actions{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .action-icon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 24px;
                            height: 24px;
                            border-radius: 4px;
                            &.dkg-viewicon{
                                color: #fff;
                                background-color: #234158;
                            }
                            &.dkg-deleteicon{
                                color: #fff;
                                background-color: #dc3545;
                            }
                            svg{
                                width: 12px;
                                height: 12px;
                                color: #f2f2f2;
                            }
                            &:hover{
                                box-shadow: 0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
                                transition: all .5s ease;
                            }
                        }
                    }
                }
            }
        }
        .dkg-parse-cv-bottom-parsebtn{
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 100%;
            .dkg-create-payment-taskbtncon{
                width: 100%;
                min-width: 100%;
                div {
                    width: 100%;
                    div{
                        width: 100%;
                        button.dkg-create-payment-taskbtn{
                            &.dkg-parsecv-btn{
                                margin-left: 0 !important;
                                border-radius: 0 !important;
                                width: 100%;
                                padding: 11px 12px !important;
                                &:hover{
                                    background-color:  #3c97b6 !important;
                                    border: 1px solid #3c97b6;
                                }
                                .MuiButton-label{
                                    font-size: 1rem;
                                }
                            }
    
                        }
                    }
                }
            }
        }
    }
}