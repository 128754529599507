.modal-dialog.dkg-clientinfo-Modal-234 {
    min-width: 600px !important;
    max-width: 600px;

    .modal-content {
        background-color: transparent;
        border-radius: 5px;

        .modal-header.dkg-clientinfo-ModalHedaer {
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            background-color: #485569;
            padding: 10px;
            border-radius: 5px 5px 0 0;

            .modal-title {
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
            }

            .close {
                color: #fff;
                opacity: 1;
            }
        }

        .modal-body.dkg-clientinfo-ModalBody {
            padding: 10px 25px 20px;
            background-color: #fff;
            border-radius: 0 0 5px 5px;

            .dkg-mody-title {
                font-size: 13px;
                text-align: left;
                color: #333;
            }
        }
    }
}