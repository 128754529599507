.dkg-savads-mainpageCon{
    margin-top: 0;
    padding: 0 15px;
    .dkg-savedads-mainCon{
        margin-top: 120px !important;
        .dkg-savedads-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 5px;
            .dkg-left-savedads-Con{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .dk-searchCols{
                    margin-right: 8px;
                    max-width: 160px;
                    position: relative;
                    width: 100%;
                    input{
                        background-color: #fff;
                        border: 1px solid #ddd;
                        color: #818181;
                        height: 32px;
                        padding: 0 12px;
                        text-align: left;
                    }
                    button{
                        background-color: #037db2;
                        border: 1px solid #818181;
                        color: #818181;
                        display: inline-block;
                        font-size: 13px;
                        height: 32px;
                        outline: none;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 0;
                        width: 32px;
                        svg{
                            width: 14px;
                            height: 14px;
                            color: #fff;
                        }
                    }
                }
            }
            .title{
                font-size: 17px;
            }
            .dkg-right-savedads-Con{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .dkg-addnew-btn {
                    background-color: #fff;
                    border: 1px solid #ccc;
                    box-shadow: none;
                    color: #333;
                    font-size: 13px;
                    margin: 0;
                    padding: 7px 9px;
                    text-transform: capitalize;
                }
            }
        }
        .dkg-edit-jobfiles-tableCon {
            height: calc(100vh - 210px);
            overflow-y: auto;
            padding-bottom: 0;
            position: relative;
            .dkg-edit-jobfiles-table {
                thead {
                    tr{
                        th{
                            background: #037db2;
                            border: 1px solid gray;
                            border-bottom: none;
                            color: #fff;
                            font-size: 13px;
                            padding: 9px 7px;
                            position: -webkit-sticky;
                            position: sticky;
                            text-align: center;
                            top: 0;
                            vertical-align: middle;
                            white-space: nowrap;
                            z-index: 9;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            background: #fff;
                            border: 1px solid #ddd;
                            color: #4d4a4a;
                            font-size: 13px;
                            font-weight: 400;
                            padding: 9px 7px;
                            position: relative;
                            text-align: center;
                            text-transform: capitalize;
                            vertical-align: middle;
                            white-space: nowrap;
                            .dkg-file-actions {
                                align-items: center;
                                display: flex;
                                justify-content: center;
                                .dkg-viewfile-icon {
                                    background-color: #2196f3;
                                    border-radius: 3px;
                                    color: #fff;
                                    font-size: 14px;
                                    height: 30px!important;
                                    line-height: 30px;
                                    width: 30px!important;
                                }
                                .dkg-downfile-icon {
                                    background-color: #31a886;
                                    border-radius: 3px;
                                    color: #fff;
                                    font-size: 14px;
                                    height: 30px!important;
                                    line-height: 30px;
                                    width: 30px!important;
                                }
                                .dkg-edit-icon {
                                    background-color: #fee69c;
                                    border-radius: 3px;
                                    color: #333;
                                    font-size: 14px;
                                    height: 30px;
                                    line-height: 30px;
                                    width: 30px;
                                }
                                .dkg-delete-icon {
                                    background-color: #dc3545;
                                    border-radius: 3px;
                                    color: #fff;
                                    font-size: 14px;
                                    height: 30px;
                                    line-height: 30px;
                                    width: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}