.modal-dialog.dk-livejobrightModalDailog {
    min-width: 95%;
    position: fixed;
    right: 2px;
    top: -15px;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    .modal-content {
        background-color: transparent;

        .dk-detViewheader {
            border-radius: 0;
            padding: 0.65rem 0;
            background-color: #26597d;
            border-bottom: 1px solid darkgrey;

            .dk-detViewTitle {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;

                .modalTitleCon {
                    width: 100%;
                    display: flex;
                    position: relative;
                    padding: 0;
                    margin-left: 10px;
                    color: #fff;
                    font-size: 14px;
                }
            }

            .close {
                color: #fff;
                opacity: 1;

                span {
                    color: #fff;
                    padding: 0.30rem 1rem 0.95rem 1rem;
                    font-weight: normal;
                }

                span:hover {
                    background-color: #eaeaea;
                    padding: 0.30rem 1rem 0.95rem 1rem;
                    color: #333;
                }
            }
            &.dkg-edit-jobinfo-modal-header{
                border-bottom: 1px solid #ccc;
                .close {
                    color: #333;
                    opacity: 1;

                    span {
                        color: #333;
                    }
                }
            }
        }

        .modal-body {
            background-color: #fff;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 0;
            background-color: #fff;

            .dk-cliDetVPageCon.dklistViewPopContent {
                margin-top: 0;
            }

            .dk-LivejobPopCon {
                .dk-clientDetViewTopSec::-webkit-scrollbar {
                    display: none;
                    /* for Chrome, Safari, and Opera */
                    -ms-overflow-style: none;
                    /* for Internet Explorer, Edge */
                    scrollbar-width: none;
                    /* for  */
                }

                .dk-clientDetViewTopSec {
                    height: calc(100vh - 41px);
                    overflow-y: hidden;
                    overflow-x: hidden;

                    .dk-DetViewBottomSecCon {
                        background: #fff;
                        height: calc(100vh - 41px);

                        .dk-ClieDetViewSideMenu1 {
                            .nav-item {
                                max-width: 14.26% !important;
                            }
                        }

                        .dk-ClieDetViewSideMenu .nav.nav-pills {
                            width: 100%;
                            margin-bottom: 0;
                            background-color: #eaeaea;
                            .nav-item {
                                width: 100%;
                                max-width: 7.1428%;

                                .nav-link {
                                    background-color: #eaeaea;
                                    color: #333;
                                    padding: 11px 7px;
                                    border-radius: 0;
                                    text-align: center;
                                    font-size: 1rem;
                                
                                }

                                .nav-link.active,
                                & .show>.nav-link {
                                    color: #244158;
                                    background-color: #fff;
                                }

                                & .nav-item:first-child .nav-link {
                                    border-top-left-radius: 5px;
                                }
                            }
                        }

                        .dk-ClieDetViewMainCont {
                            padding: 1.4rem;
                            // &.dkg-livejobs-suMMTabpane{
                            //     padding: 0;
                            // }
                            .tab-content {
                                .tab-pane {
                                    .dk-ClentViewTabPane {
                                        color: #333;
                                        margin-top: 0;
                                        .dkg-sendouts-tabsCon {
                                            .dkg-sendouts-tabRow {
                                                margin-left: -19px;
                                                margin-top: 10px;

                                                .dkg-sendouts-sideMenuCon {
                                                    background-color: #244158;
                                                    flex: 0 0 14.666667%;
                                                    padding-left: 0;
                                                    padding-right: 0;

                                                    .dkg-sendouts-sideMenu.nav-pills {
                                                        display: block;

                                                        .dkg-sendouts-navitem {
                                                            .dkg-sendouts-navlink {
                                                                background: #244158;
                                                                border-bottom: 1px solid #3e5b73;
                                                                color: #fff;
                                                                border-radius: 0;
                                                                text-align: left;
                                                                padding: 11px 12px;
                                                                text-transform: capitalize;

                                                                &.active {
                                                                    width: 100%;
                                                                    border: 0;
                                                                    background-color: #fff;
                                                                    color: #333;
                                                                }
                                                            }

                                                            &:first-child {
                                                                .dkg-sendouts-navlink {
                                                                    border-top: 1px solid #3e5b73;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .dkg-sendouts-rightMainCon {
                                                    flex: 0 0 83.333333%;
                                                    max-width: 83.333333%;
                                                    margin-left: 2%;

                                                    .dkg-sendouts-tabContent {
                                                        .dkg-sendouts-tabpane {
                                                            background-color: #eaeaea;
                                                            height: calc(100vh - 120px);
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .dkg-interviewnotes-editor12 {
                                            margin-top: 1rem;

                                            .dkg-jobtitleInput {

                                                &:focus,
                                                &:focus-visible {
                                                    box-shadow: none;
                                                    outline: 0;
                                                }
                                            }

                                            .wrapper-class {
                                                padding: 0 !important;
                                                border: none !important;

                                                .rdw-editor-toolbar.toolbar-class {
                                                    margin-bottom: 0;

                                                    .rdw-inline-wrapper {
                                                        .rdw-option-wrapper {

                                                            &:nth-child(05),
                                                            &:nth-child(06),
                                                            &:nth-child(07) {
                                                                display: none;
                                                            }
                                                        }
                                                    }

                                                    .rdw-option-wrapper.rdw-option-disabled {
                                                        display: none;
                                                    }
                                                }

                                                .editor-class.rdw-editor-main {
                                                    min-height: inherit !important;
                                                    max-height: inherit !important;
                                                    background-color: #fff;
                                                    height: calc(100vh - 291px);
                                                    padding: 15px;
                                                    .DraftEditor-root{
                                                        .DraftEditor-editorContainer{
                                                            .public-DraftEditor-content{
                                                                div{
                                                                    div{
                                                                        .public-DraftStyleDefault-block{
                                                                            span{
                                                                                background-color: transparent !important;
                                                                                color: inherit !important;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .dkg-cost-explain-Con{
                                            padding-top: 15px;
                                            .dkg-cost-explain-header{
                                                background: #ddd;
                                                border: 1px solid #cecece;
                                                border-radius: 5px 5px 0 0;
                                                height: 40px;
                                                padding: 5px;
                                                position: relative;
                                                width: 100%;
                                                .dkg-cost-explain-title{
                                                    color: #3e3e3e;
                                                    font-size: 20px;
                                                    font-weight: 400;
                                                    text-align: center;
                                                }
                                            }
                                            .dkg-cost-explain-body{
                                                .dkg-cost-explain-textArea{
                                                    height: calc(100vh - 290px);
                                                    border-top: none;
                                                    border: 1px solid #cecece;
                                                    border-radius: 0 0 5px 5px;
                                                    padding: 5px 10px;
                                                    &:focus{
                                                        outline: 0;
                                                        box-shadow: none;
                                                    }
                                                }
                                                .dkg-open-url-btnCon{
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    width: 100%;
                                                    .dkg-open-url-btn{
                                                       background-color: #234158;
                                                        border: 1px solid #234158;
                                                        border-radius: 0.1875rem;
                                                        color: #fff;
                                                        display: inline-block;
                                                        font-size: 13px;
                                                        line-height: 1.5385;
                                                        padding: 5px 12px;
                                                        text-align: center;
                                                        text-transform: uppercase;
                                                    }
                                                }
                                            }

                                        }
                                        .dkg-savebtnCon {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            width: 100%;
                                            margin-top: 20px;

                                            div {

                                                div {
                                                    .dkg-create-payment-taskbtn {
                                                        background: #234158;
                                                        border: 1px solid #234158;
                                                        border-radius: 0.1875rem;
                                                        color: #fff;
                                                        display: inline-block;
                                                        font-size: 13px;
                                                        line-height: 1.5385;
                                                        margin-left: 120px;
                                                        padding: 5px 12px;
                                                        text-align: center;
                                                        text-transform: uppercase;

                                                    }
                                                }
                                            }
                                        }

                                        .row.dk-Adsbox {
                                            .col-md-12 {
                                                .dkg-edit-interview-title {
                                                    border-bottom: none;
                                                    font-size: 15px;
                                                    font-weight: 700;
                                                    padding: 11px 0;
                                                    text-align: center;
                                                    text-transform: uppercase;
                                                    width: 100%;
                                                }
                                            }
                                        }

                                        .dkg-edit-jobfiles-mainCon {
                                            .dkg-jobfile-titleCon {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                margin-bottom: 10px;

                                                .dkg-jobfile-title {
                                                    font-size: 18px;
                                                    font-weight: 600;
                                                    margin-top: 7px;
                                                    text-transform: uppercase;
                                                }
                                            }

                                            .dkg-edit-jobfiles-tableCon {
                                                .dkg-edit-jobfiles-table {
                                                    thead {
                                                        tr {
                                                            th {
                                                                background: #485569;
                                                                border: 1px solid gray;
                                                                border-bottom: none;
                                                                color: #fff;
                                                                font-size: 13px;
                                                                padding: 9px 7px;
                                                                position: -webkit-sticky;
                                                                position: sticky;
                                                                text-align: center;
                                                                top: 0;
                                                                vertical-align: middle;
                                                                white-space: nowrap;
                                                                z-index: 9;
                                                            }
                                                        }
                                                    }

                                                    tbody {

                                                        tr {
                                                            td {
                                                                background: #fff;
                                                                border: 1px solid #ddd;
                                                                color: #4d4a4a;
                                                                font-size: 13px;
                                                                font-weight: 400;
                                                                padding: 9px 7px;
                                                                position: relative;
                                                                text-align: center;
                                                                text-transform: capitalize;
                                                                vertical-align: middle;
                                                                white-space: nowrap;

                                                                .dkg-viewfile-iconCon {
                                                                    display: flex;
                                                                    justify-content: center;
                                                                    align-items: center;

                                                                    .dkg-viewfile-icon {
                                                                        border-radius: 3px;
                                                                        font-size: 14px;
                                                                        height: 30px !important;
                                                                        line-height: 30px;
                                                                        width: 30px !important;
                                                                        background-color: #2196f3;
                                                                        color: #fff;
                                                                    }
                                                                }

                                                                .dkg-file-actions {
                                                                    display: flex;
                                                                    justify-content: center;
                                                                    align-items: center;

                                                                    .dkg-viewfile-icon {
                                                                        border-radius: 3px;
                                                                        font-size: 14px;
                                                                        height: 30px !important;
                                                                        line-height: 30px;
                                                                        width: 30px !important;
                                                                        background-color: #2196f3;
                                                                        color: #fff;
                                                                    }

                                                                    .dkg-download-icon {
                                                                        border-radius: 3px;
                                                                        font-size: 14px;
                                                                        height: 30px !important;
                                                                        line-height: 30px;
                                                                        width: 30px !important;
                                                                        background-color: #31a886;
                                                                        color: #fff;
                                                                    }

                                                                    .dkg-editfile-icon {
                                                                        border-radius: 3px;
                                                                        font-size: 14px;
                                                                        height: 30px;
                                                                        line-height: 30px;
                                                                        width: 30px;
                                                                        background-color: #fee69c;
                                                                        color: #333;
                                                                    }

                                                                    .dkg-deletefile-icon {
                                                                        border-radius: 3px;
                                                                        font-size: 14px;
                                                                        height: 30px;
                                                                        line-height: 30px;
                                                                        width: 30px;
                                                                        background-color: #dc3545;
                                                                        color: #fff;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.dkg-likedislike-tabpane {
                                            color: #000;

                                            .dkg-likedislike-Con-234 {
                                                background-color: #eaeaea;
                                                margin-left: 0;
                                                margin-right: 0;
                                                border-radius: 5px;
                                            }
                                        }

                                        &.dkg-jobs-sendout-tabpane-78 {
                                            margin: -20px;

                                            .dkg-payment-qt-pageCon {
                                                margin-top: 0;

                                                .dkg-payment-qt-tab-mainCon {
                                                    border-top: none;
                                                    background-color: #eaeaea;
                                                    margin-top: 25px !important;

                                                    .dkg-payment-qt-tabCon {
                                                        .dkg-payment-qt-tab-navpills {
                                                            height: calc(100vh - 103px);

                                                            .dkg-payment-qt-tab-nav-item {
                                                                a {
                                                                    &.active {
                                                                        background-color: #eaeaea;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .dkg-payment-rt-tabCon-10 {
                                                        color: #20394a;
                                                        .dkg-payment-qt-tabcontent {
                                                            .dkg-payment-qt-tabpane {
                                                                &:first-child {
                                                                    .dkg-payment-qt-rightpanel {
                                                                        .dk-moduleTraining-rightCols {
                                                                            background-color: #F2F2F2;

                                                                        }
                                                                        // .dkg-tabs-rightPanel-12{
                                                                        //     .dk-tabs-rightCols-12{
                                                                        //         .dk-tabs-right-detail-12{
                                                                        //             .dk-tabs-right-nav-12{
                                                                        //                 display: none;
                                                                        //             }
                                                                        //         }
                                                                        //     }
                                                                        // }
                                                                    }
                                                                }
                                                                // .dkg-payment-qt-rightpanel {
                                                                //     // .dk-moduleTraining-rightCols {
                                                                //     //     background-color: #F2F2F2;

                                                                //     // }
                                                                //     .dkg-tabs-rightPanel-12{
                                                                //         .dk-tabs-rightCols-12{
                                                                //             .dk-tabs-right-detail-12{
                                                                //                 .dk-tabs-right-nav-12{
                                                                //                     display: none;
                                                                //                 }
                                                                //             }
                                                                //         }
                                                                //     }
                                                                // }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &.dkg-new-jobdet-tbpane-234{
                                            height: calc(100vh - 110px);
                                            // overflow-y: hidden;
                                            overflow-x: hidden;
                                            padding-right: 5px;
                                            &::-webkit-scrollbar-track {
                                                background: #F1F1F1;
                                              }
                                            &::-webkit-scrollbar-thumb {
                                            background-color: #ddd;
                                            border-radius: 4px;
                                            cursor: pointer;
                                            }

                                        }
                                        // &.dkg-new-jobdet-tbpane-234::-webkit-scrollbar {
                                        //     display: none;
                                        // }
                                        // &.dkg-livejob-status-tabpane{

                                        // }
                                        .dkg-company-list-mainCon{
                                            width: 100%;
                                            max-width: 50%;
                                            padding: 0 15px;
                                            margin: 0 auto;
                                            .dkg-company-list-tableCon{
                                                margin-top: 6px;
                                                overflow-y: auto;
                                                overflow-x: auto;
                                                height: calc(100vh - 280px);
                                                padding-bottom: 0;
                                                position: relative;
                                                top: 30px;
                                                .dkg-company-list-table{
                                                    thead{
                                                        tr{
                                                            th{
                                                                background: #485569;
                                                                color: #fff;
                                                                font-size: 13px;
                                                                text-align: center;
                                                                white-space: nowrap;
                                                                padding: 9px 7px;
                                                                border: 1px solid #808080;
                                                                border-bottom: none;
                                                                vertical-align: middle;
                                                                position: sticky;
                                                                top: 0;
                                                                z-index: 9;

                                                            }
                                                        }
                                                    }
                                                    tbody{
                                                        tr{
                                                            td{
                                                                background: #fff;
                                                                color: #4d4a4a;
                                                                font-size: 13px;
                                                                font-weight: 400;
                                                                text-align: center;
                                                                white-space: nowrap;
                                                                padding: 5px 7px;
                                                                border: 1px solid #ddd;
                                                                vertical-align: middle;
                                                                text-transform: capitalize;

                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .dkg-payment-qt-pageCon{
                                            &.dkg-comman-training-pageCon{
                                                &.dkg-comman-training-234{
                                                    margin-top: 0 !important;
                                                    .dkg-payment-qt-tab-mainCon{
                                                        .col-md-2.pl-0.dkg-payment-qt-tabCon{
                                                            flex: 0 0 16.666667%;
                                                            max-width: 16.666667%;
                                                        }
                                                        .col-md-10.dkg-payment-rt-tabCon-10{
                                                            flex: 0 0 82.333333%;
                                                            max-width: 82.333333%;
                                                            
                                                            .dkg-payment-qt-tabcontent {
                                                                color: #037db2;
                                                                .dkg-payment-qt-tabpane{
                                                                    .dkg-payment-qt-rightpanel{
                                                                        .dkg-tabs-rightPanel-12{
                                                                            .dk-tabs-rightCols-12{
                                                                                .dk-tabs-right-detail-12{
                                                                                    .dk-tabs-right-tabpane-12{
                                                                                        &.addnewClass{
                                                                                            &.dkg-hidewithoutselct{
                                                                                                display: block  !important;
                                                                                                .dk-rightDetail-panel-13{
                                                                                                    .dk-getStartedRight-detail-13 {
                                                                                                        display: flex;

                                                                                                        .dk-tabs-right-nav-13 {
                                                                                                            width: 100%;
                                                                                                            max-width: 195px;
                                                                                                            min-width: 195px;
                                                                                                            background: #eaeaea;
                                                                                                            display: inline-block;
                                                                                                            height: calc(100vh - 168px);
                                                                                                            margin-right: 0;

                                                                                                            .dk-tabs-right-navitem-13 {
                                                                                                                .dk-tabs-right-navlink-13 {
                                                                                                                    align-items: flex-start;
                                                                                                                    border-bottom: 1px solid #ddd;
                                                                                                                    border-left: 1px solid #ddd;
                                                                                                                    color: #757575;
                                                                                                                    display: block;
                                                                                                                    flex-shrink: inherit;
                                                                                                                    font-size: 15px;
                                                                                                                    font-weight: 400;
                                                                                                                    max-width: 100%;
                                                                                                                    min-height: auto;
                                                                                                                    min-width: inherit !important;
                                                                                                                    opacity: 1;
                                                                                                                    outline: none;
                                                                                                                    overflow: visible;
                                                                                                                    padding: 12px 15px;
                                                                                                                    position: relative;
                                                                                                                    text-transform: capitalize;
                                                                                                                    width: 100%;
                                                                                                                    text-align: left;
                                                                                                                    &.active,
                                                                                                                    &.addActiveClass {
                                                                                                                        background: #fff;
                                                                                                                        color: #606060;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        .dk-tabs-right-tabcontent-13 {
                                                                                                            width: 100%;
                                                                                                            .dk-tabs-right-tabpane-13 {
                                                                                                                padding: 24px;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .col-md-12.dkg-jobspecpopup-tab100{
                                                            flex: 0 0 100%;
                                                            max-width: 100%;
                                                            padding-left: 0;
                                                            .dkg-payment-qt-tabcontent {
                                                                color: #037db2;
                                                                .dkg-payment-qt-tabpane{
                                                                    .dkg-payment-qt-rightpanel{
                                                                        .dkg-tabs-rightPanel-12{
                                                                            .dk-tabs-rightCols-12{
                                                                                .dk-tabs-right-detail-12{
                                                                                    .dk-tabs-right-tabpane-12{
                                                                                        &.addnewClass{
                                                                                            &.dkg-hidewithoutselct{
                                                                                                display: block  !important;
                                                                                                .dk-rightDetail-panel-13{
                                                                                                    .dk-getStartedRight-detail-13 {
                                                                                                        display: flex;

                                                                                                        .dk-tabs-right-nav-13 {
                                                                                                            width: 100%;
                                                                                                            max-width: 195px;
                                                                                                            min-width: 195px;
                                                                                                            background: #eaeaea;
                                                                                                            display: inline-block;
                                                                                                            height: calc(100vh - 168px);
                                                                                                            margin-right: 0;

                                                                                                            .dk-tabs-right-navitem-13 {
                                                                                                                .dk-tabs-right-navlink-13 {
                                                                                                                    align-items: flex-start;
                                                                                                                    border-bottom: 1px solid #ddd;
                                                                                                                    border-left: 1px solid #ddd;
                                                                                                                    color: #757575;
                                                                                                                    display: block;
                                                                                                                    flex-shrink: inherit;
                                                                                                                    font-size: 15px;
                                                                                                                    font-weight: 400;
                                                                                                                    max-width: 100%;
                                                                                                                    min-height: auto;
                                                                                                                    min-width: inherit !important;
                                                                                                                    opacity: 1;
                                                                                                                    outline: none;
                                                                                                                    overflow: visible;
                                                                                                                    padding: 12px 15px;
                                                                                                                    position: relative;
                                                                                                                    text-transform: capitalize;
                                                                                                                    width: 100%;
                                                                                                                    text-align: left;
                                                                                                                    &.active,
                                                                                                                    &.addActiveClass {
                                                                                                                        background: #fff;
                                                                                                                        color: #606060;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        .dk-tabs-right-tabcontent-13 {
                                                                                                            width: 100%;
                                                                                                            .dk-tabs-right-tabpane-13 {
                                                                                                                padding: 24px;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .jobSummaryDetails {
                                        // background: #eaeaea;
                                        // border: 1px solid #ddd;
                                        // border-radius: 5px;
                                        // outline: 0;
                                        padding: 0;
                                        .dk-right-boxjobDetails {

                                            // overflow-y: auto;
                                            background-color: #243b4c;
                                            border: 1px solid #d3d3d3;
                                            padding: 15px;
                                            border-radius: 5px;
                                           height: calc(100vh - 148px);
                                            overflow-y: auto;
                                            &::-webkit-scrollbar-track {
                                                background: #F1F1F1;
                                            }
                                            &::-webkit-scrollbar-thumb {
                                                background-color: #ddd;
                                                border-radius: 4px;
                                                cursor: pointer;
                                              }
                                            .left-title-blocks {
                                                color: #fff;
                                                padding: 10px 0px 10px 0px;
                                                font-size: 12px !important;
                                                border-bottom: 1px solid #456e8c;
                                                white-space: nowrap;

                                                &:last-child {
                                                    border-bottom: none;
                                                }

                                                &.col-md-7 {
                                                    .p-3 {
                                                        font-size: 13px !important;
                                                    }
                                                }

                                                .fist-col-div {
                                                    background: #28485f;
                                                    border-right: 1px solid #456e8c;
                                                    text-transform: uppercase;
                                                    font-size: 13px;
                                                }
                                                .dkg-answer-block{
                                                    background-color: #eaeaea;
                                                    color: #333;
                                                    height: 43px;
                                                }
                                            }

                                            .p-3 {
                                                padding: 0.835rem 0.8rem !important;
                                            }
                                        }

                                        .dk-boxjobDetails {
                                            border: 1px solid #d3d3d3;
                                            padding: 15px;
                                            background-color: #28485f;
                                            border-radius: 5px;
                                            height: calc(100vh - 148px);
                                            overflow-y: auto;
                                            &::-webkit-scrollbar-track {
                                                background: #F1F1F1;
                                            }
                                            &::-webkit-scrollbar-thumb {
                                                background-color: #ddd;
                                                border-radius: 4px;
                                                cursor: pointer;
                                              }
                                            h1 {
                                                margin-bottom: 7px;
                                                color: #f4d03e;
                                                text-align: left;
                                                font-size: 18px;
                                                font-weight: 700;

                                                &.dkg-jobdetail-maintitle {
                                                    font-size: 20px;
                                                }
                                            }

                                            h3 {
                                                margin: 0 0 10px;
                                                padding: 0;
                                                font-size: 14px;
                                                font-weight: 700;
                                                color: #f4d03e;
                                            }

                                            div {
                                                margin-bottom: 22px;

                                                p {
                                                    span {
                                                        color: inherit !important;
                                                        background-color: inherit !important;
                                                        font-family: inherit !important;
                                                        margin-bottom: 0 !important;
                                                    }
                                                }
                                            }

                                            p {
                                                color: #fff;
                                                opacity: .8;
                                            }

                                            li {
                                                list-style-type: disc;
                                                margin: 0 35px;
                                                color: #fff;
                                                opacity: .8;
                                                span{
                                                    background-color: transparent !important;
                                                }
                                            }


                                        }

                                        .row.m-0 {
                                            // height: calc(100vh - 150px);
                                            // overflow-y: auto;

                                            .col-md-8.p-3.dk-suMMRightCon.dk-jobsuMMBottomSec {
                                                flex: 0 0 64.666667%;
                                                max-width: 64.666667%;
                                            }

                                            .col-md-4.p-3.dk-suMMRightCon.dk-jobsuMMBottomSec {
                                                flex: 0 0 35.333333%;
                                                max-width: 35.333333%;
                                                // height: calc(100vh - 115px);
                                                // overflow-y: auto;
                                                // height: calc(100vh - 133px);
                                                // overflow-y: auto;
                                            }
                                        }
                                    }

                                    .summaryTabPane {

                                        .dk-suMMLeftCon {
                                            // background: #eaeaea;
                                            background-color: #eaeaea;
                                            border: 1px solid #cecece;
                                            border-radius: 5px;
                                            padding: 15px;
                                            margin: 0;
                                            height: calc(100vh - 121px);
                                            overflow-y: auto;
                                            .dkg-company-rigt-dessc{
                                                background-color: #fff;
                                                padding: 10px 15px;
                                                border-radius: 5px;
                                                height: 150px;
                                                overflow-y: auto;
                                            }
                                            &.dkg-editcom-summbG {
                                                background-color: #eaeaea;
                                            }

                                            .dk-topinputGroup {
                                                .input-group {
                                                    .input-group-text {
                                                        background: #ddd;
                                                        border: 1px solid #cecece;
                                                        border-right: 0px;
                                                        border-radius: 0px;
                                                        padding: 0.775rem 1.2rem;
                                                        font-size: .95rem;
                                                        min-width: 140px;
                                                    }

                                                    input {
                                                        padding: 0.775rem 1.2rem;
                                                        border-radius: 0;
                                                        height: 44px;
                                                        font-size: 1.12rem;
                                                        color: #333;

                                                        &:focus {
                                                            outline: 0;
                                                            box-shadow: none;
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-toprightGroup {
                                                display: flex;

                                                .firstBox {
                                                    display: flex;
                                                    margin-top: 3px;

                                                    span {
                                                        margin-top: 8px;
                                                        margin-right: 8px;
                                                    }

                                                    .dk-AmDropDwnCon {
                                                        color: #333;

                                                        .dk-AmDropDwn.dropdown {
                                                            .dk-AmDropDwnToggle {
                                                                img {
                                                                    width: 37px;
                                                                    height: 37px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .secondBox {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    height: 37px;
                                                    width: 37px;
                                                    border: 1px solid #cecece;
                                                    border-radius: 50%;
                                                    background: #2685a5;
                                                    margin-top: 2px;
                                                    position: relative;
                                                    margin-left: 4px;

                                                    span.counterNumber {
                                                        color: #fff;

                                                    }
                                                }

                                                .thridBox {
                                                    padding-left: 0.64rem;

                                                    .AmImgConDrop {
                                                        .dk-suMMSrchDropDwnCon {
                                                            .dk-suMMSrchDropDwn {
                                                                a {
                                                                    img {
                                                                        width: 37px;
                                                                        height: 37px;
                                                                        border-radius: 50%;
                                                                        border: 2px dotted #999;
                                                                    }
                                                                }
                                                            }

                                                            .dk-suMMSrchDropDwnMenu {
                                                                border-radius: 0;
                                                                padding: 0;

                                                                input {
                                                                    border-bottom: 1px solid #ccc;
                                                                }

                                                                ul {
                                                                    padding: 0;
                                                                    max-height: 220px;
                                                                    overflow-y: auto;

                                                                    .dk-assignRecruitersCon {
                                                                        p {
                                                                            padding: 0.25rem 1rem;
                                                                            margin-bottom: 0;
                                                                            font-size: 0.95rem;
                                                                        }

                                                                        .dk-reclistItem {
                                                                            padding: 0.25rem 1rem;

                                                                            &.active {
                                                                                .dk-assignRecCon {
                                                                                    span {
                                                                                        color: #fff;
                                                                                    }
                                                                                }
                                                                            }

                                                                            .dk-assignRecCon {
                                                                                display: flex;
                                                                                position: relative;
                                                                                align-items: center;

                                                                                img.recruiterimg {
                                                                                    width: 35px;
                                                                                    height: 35px;
                                                                                    border-radius: 50%;
                                                                                    border: 2px dotted #ddd;
                                                                                    margin-right: 10px;
                                                                                }

                                                                                span {
                                                                                    font-size: 0.85rem;
                                                                                    font-weight: bold;
                                                                                    color: #333;
                                                                                }

                                                                                .crossIcon {
                                                                                    position: absolute;
                                                                                    right: 10px;

                                                                                    i {
                                                                                        font-size: 16px;
                                                                                        color: #333;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    .dk-remaingRecruiters {
                                                                        p {
                                                                            padding: 0.25rem 1rem;
                                                                            margin-bottom: 0;
                                                                            font-size: 0.95rem;
                                                                        }

                                                                        .dk-reclistItem {
                                                                            padding: 0.25rem 1rem;

                                                                            &.active {
                                                                                .dk-unassignRecCon {
                                                                                    span {
                                                                                        color: #fff;
                                                                                    }
                                                                                }
                                                                            }

                                                                            .dk-unassignRecCon {
                                                                                img.recruiterimg {
                                                                                    width: 35px;
                                                                                    height: 35px;
                                                                                    border-radius: 50%;
                                                                                    border: 2px dotted #ddd;
                                                                                    margin-right: 10px;
                                                                                }

                                                                                span {
                                                                                    font-size: 0.85rem;
                                                                                    opacity: .8;
                                                                                    font-weight: bold;
                                                                                    color: #212529;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-suMMformGroupCon {
                                                display: flex;
                                                width: 100%;

                                                label {
                                                    align-items: center;
                                                    min-width: 90px;
                                                    font-size: 0.90rem;
                                                    white-space: nowrap;
                                                }

                                                .dk-statusDrpDwnCon {
                                                    width: 100%;
                                                    display: flex;

                                                    .dk-simpleDrpDwnCon {
                                                        .dk-simpleDrpDwn {
                                                            .dk-simpleDrpDwnToggle {
                                                                background-color: #fff;
                                                                border: 1px solid #ddd;
                                                            }
                                                        }
                                                    }

                                                    .dk-statusDrpDwn {
                                                        width: 100%;

                                                        .dk-statusDrpDwnToggle {
                                                            box-shadow: none;
                                                            display: flex;
                                                            width: 100%;
                                                            margin: 0;
                                                            padding: 0.35rem 0.75rem;
                                                            justify-content: center;
                                                            align-items: center;
                                                            border-radius: 0;
                                                            text-transform: capitalize;
                                                            font-size: 0.95rem;

                                                            &::after {
                                                                display: none;
                                                            }
                                                        }

                                                        .dk-statusDrpDwnMenu {
                                                            border-radius: 0;
                                                            padding: 0;
                                                            width: 100%;

                                                            .dropdown-item {
                                                                padding: 0.35rem 0.75rem;
                                                                font-size: 0.9rem;
                                                            }
                                                        }
                                                    }
                                                }

                                                .dk-simpleDrpDwnCon {
                                                    width: 100%;

                                                    .dk-simpleDrpDwn {
                                                        .dk-simpleDrpDwnToggle {
                                                            color: #333;
                                                            box-shadow: none;
                                                            white-space: nowrap;
                                                            margin: 0;
                                                            background-color: #fff;
                                                            border: 1px solid #ccc;
                                                            padding: 0.40rem 0.75rem;
                                                            text-transform: capitalize;
                                                            display: flex;
                                                            width: 100%;
                                                            justify-content: center;
                                                            font-size: 0.90rem;
                                                            position: relative;

                                                            &::after {
                                                                position: absolute;
                                                                right: 5px;
                                                                top: 13px;
                                                            }
                                                        }

                                                        .dk-simpleDrpDwnMenu {
                                                            border-radius: 0;
                                                            max-height: 180px;
                                                            width: 100%;
                                                            overflow-y: auto;
                                                            padding: 0;
                                                            border-color: #96c8da;
                                                            box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                            .dropdown-item {
                                                                padding: 0.35rem 0.75rem;
                                                                font-size: 0.9rem;
                                                            }

                                                            input {
                                                                border-bottom: 1px solid #ccc;
                                                            }
                                                        }
                                                    }
                                                }

                                                // dk-statusDrpDwnCon
                                                input {
                                                    display: flex;
                                                    width: 100%;
                                                    margin: 0;
                                                    padding: 0.35rem 0.75rem;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 0;
                                                    text-transform: capitalize;
                                                    font-size: 0.95rem;
                                                    text-align: center;

                                                    &:focus {
                                                        outline: 0;
                                                        box-shadow: none;
                                                    }
                                                }

                                                .input-group {
                                                    input {
                                                        border-right: 0;
                                                    }

                                                    .input-group-prepend {
                                                        background-color: #fff;

                                                        .input-group-text {
                                                            background-color: #fff;
                                                        }
                                                    }
                                                }
                                            }

                                            .dkg-editcompany-textArea-Con {
                                                .dkg-title {
                                                    background: #dddddd;
                                                    border: 1px solid #cecece;
                                                    border-bottom: none;
                                                    width: 100%;
                                                    padding: 5px;
                                                    height: 40px;
                                                    border-radius: 5px 5px 0 0;

                                                    h3 {
                                                        text-align: center;
                                                        color: #3e3e3e;
                                                        font-size: 20px;
                                                        font-weight: normal;
                                                        margin-bottom: 0;
                                                        width: 100%;
                                                    }
                                                }

                                                .dkg-editcompany-textArea {
                                                    border-radius: 0 0 5px;
                                                }
                                            }
                                        }

                                        .dk-jobsuMMBottomSec {
                                            color: #3e3e3e;

                                            .dk-jobsuMMBottomBoxBg {
                                                color: #333;
                                                .dkg-salary-tab-box{
                                                    margin-top: 1.4rem;
                                                    .dkg-salary-tab-header{
                                                        background: #dddddd;
                                                        border: 1px solid #cecece;
                                                        border-radius: 5px 5px 0 0;
                                                        border-bottom: none;
                                                        width: 100%;
                                                        padding: 5px;
                                                        height: 40px;
                                                        position: relative;
                                                        .dkg-salary-header-title{
                                                            text-align: center;
                                                            color: #3e3e3e;
                                                            font-size: 20px;
                                                            font-weight: normal;
                                                        }
                                                    }
                                                    .dkg-salary-tabbody{
                                                        height: 250px;
                                                        background-color: #eaeaea;
                                                        border: 1px solid #cecece;
                                                        border-radius: 0 0 5px 5px;
                                                        padding: 7px 15px;
                                                        overflow-y: auto;
                                                        .jodit-react-container{
                                                            .jodit-container{
                                                                .jodit-container:not(.jodit_inline) .jodit-workplace {
                                                                    background-color: #eaeaea !important;
                                                                    p{
                                                                        background-color: #eaeaea !important;
                                                                        span{
                                                                            background-color: #eaeaea !important;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .jodit-container .jodit-workplace .jodit-wysiwyg{
                                                                background-color: #eaeaea !important;
                                                                p{
                                                                    background-color: #eaeaea !important;
                                                                    span{
                                                                        background-color: #eaeaea !important;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-salary-tabbody {
                                                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                                                        scrollbar-width: none;  /* Firefox */
                                                    }
                                                    .dkg-salary-tabbody::-webkit-scrollbar { 
                                                        display: none;  /* Safari and Chrome */
                                                    }
                                                }
                                                .dkg-salary-curr-dropdown-toggle {
                                                    color: red;

                                                    .dk-simpleDrpDwnCon {
                                                        .dk-simpleDrpDwn {
                                                            .dk-simpleDrpDwnToggle {
                                                                min-width: 90px;
                                                                height: 39px;
                                                                border: 1px solid #ddd;
                                                                line-height: 24px;
                                                            }
                                                        }
                                                    }
                                                }

                                                .dk-suMMformGroupCon {
                                                    min-height: 32px;
                                                }

                                                .dk-BoxHeader {
                                                    background: #dddddd;
                                                    border: 1px solid #cecece;
                                                    border-bottom: none;
                                                    width: 100%;
                                                    padding: 5px;
                                                    height: 40px;
                                                    border-radius: 5px 5px 0 0;

                                                    h3 {
                                                        text-align: center;
                                                        color: #3e3e3e;
                                                        font-size: 20px;
                                                        font-weight: normal;
                                                        position: relative;
                                                        .dkg-pophover-icon{
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            width: 18px;
                                                            height: 18px;
                                                            background-color: #eaeaea;
                                                            position: absolute;
                                                            top: 3px;
                                                            right: 65px;
                                                            border-radius: 50%;
                                                            border: 1px solid #ddd;
                                                            cursor: pointer;
                                                            svg{
                                                                width: 14px;
                                                                height: 14px;
                                                            }
                                                        }
                                                    }
                                                }

                                                .dk-boxBody {
                                                    background: #eaeaea;
                                                    border: 1px solid #cecece;
                                                    border-radius: 0 0 5px 5px;
                                                    padding: 15px;
                                                    &.dkg-livejobs-boxbody{
                                                        padding-top: 15px;
                                                    }
                                                    .dk-suMMformGroupCon {
                                                        display: flex;
                                                        width: 100%;
                                                        margin-bottom: 0.3rem;

                                                        .label-hover:hover {
                                                            color: blue;
                                                        }

                                                        input {
                                                            border-bottom: 1px solid #ccc;
                                                        }

                                                        label {
                                                            align-items: center;
                                                            font-size: 0.90rem;
                                                            white-space: nowrap;
                                                            padding-right: 0.5rem;
                                                            min-width: 110px !important;
                                                            &.dkg-detil-rightlabel{
                                                                min-width: 135px !important;
                                                            }
                                                            &.dkg-gross-slary-label{
                                                                cursor: pointer;
                                                            }
                                                            svg{
                                                                width: 15px;
                                                                height: 15px;
                                                            }

                                                        }

                                                        label.dk-benfitslabel {
                                                            min-width: 20px;
                                                            max-width: 20px;
                                                        }

                                                        label.relocationlabel {
                                                            min-width: 147px !important;
                                                        }

                                                        .dk-simpleDrpDwnCon {
                                                            width: 100%;

                                                            .dk-simpleDrpDwn {
                                                                .dk-simpleDrpDwnToggle {
                                                                    color: #333;
                                                                    box-shadow: none;
                                                                    white-space: nowrap;
                                                                    margin: 0;
                                                                    background-color: #fff;
                                                                    border: 1px solid #ccc;
                                                                    padding: 0.35rem 0.75rem;
                                                                    text-transform: capitalize;
                                                                    display: flex;
                                                                    width: 100%;
                                                                    justify-content: center;
                                                                    font-size: 0.90rem;
                                                                    position: relative;

                                                                    &::after {
                                                                        position: absolute;
                                                                        right: 5px;
                                                                        top: 13px;
                                                                    }
                                                                }

                                                                .dk-simpleDrpDwnMenu {
                                                                    border-radius: 0;
                                                                    max-height: 180px;
                                                                    width: 100%;
                                                                    overflow-y: auto;
                                                                    padding: 0;
                                                                    border-color: #96c8da;
                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                    .dropdown-item {
                                                                        padding: 0.35rem 0.75rem;
                                                                        font-size: 0.9rem;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .dkg-salary-curr-dropdown-toggle {
                                                            .dk-simpleDrpDwn {
                                                                .dk-simpleDrpDwnToggle {
                                                                    color: #333;
                                                                    box-shadow: none;
                                                                    white-space: nowrap;
                                                                    margin: 0;
                                                                    background-color: #fff;
                                                                    border: 1px solid #ccc;
                                                                    padding: 0.35rem 0.75rem;
                                                                    text-transform: capitalize;
                                                                    display: flex;
                                                                    width: 100%;
                                                                    justify-content: center;
                                                                    font-size: 0.90rem;
                                                                    position: relative;

                                                                    &::after {
                                                                        position: absolute;
                                                                        right: 5px;
                                                                        top: 17px;
                                                                    }
                                                                }

                                                                .dk-simpleDrpDwnMenu {
                                                                    border-radius: 0;
                                                                    max-height: 180px;
                                                                    width: 100%;
                                                                    overflow-y: auto;
                                                                    padding: 0;
                                                                    border-color: #96c8da;
                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                    .dropdown-item {
                                                                        padding: 0.35rem 0.75rem;
                                                                        font-size: 0.9rem;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        input {
                                                            display: flex;
                                                            width: 100%;
                                                            margin: 0;
                                                            padding: 0.35rem 0.75rem;
                                                            justify-content: center;
                                                            align-items: center;
                                                            border-radius: 0;
                                                            text-transform: capitalize;
                                                            font-size: 0.95rem;
                                                            text-align: center;

                                                            &:focus {
                                                                outline: 0;
                                                                box-shadow: none;
                                                            }
                                                        }

                                                        input.dk-benfitsInput {
                                                            max-height: 30px;
                                                        }

                                                        .input-group {
                                                            input {
                                                                border-right: 0;
                                                            }

                                                            .input-group-prepend {
                                                                background-color: #fff;

                                                                .input-group-text {
                                                                    background-color: #fff;
                                                                }
                                                            }

                                                            .dkg-salary-curr-dropdown {
                                                                .dkg-salary-curr-dropdown-toggle {
                                                                    margin: 0;
                                                                    box-shadow: none;
                                                                    border: 1px solid #ccc;
                                                                    background-color: #fff;
                                                                    padding: 10px 12px;
                                                                    font-size: 0.95rem;

                                                                    svg {
                                                                        width: 13px;
                                                                        height: 13px;
                                                                    }
                                                                }

                                                                .dkg-salary-curr-dropdown-menu {
                                                                    border-radius: 0;
                                                                    width: 100%;
                                                                    padding: 0;
                                                                    margin: 0;
                                                                    max-height: 230px;
                                                                    overflow-y: auto;

                                                                    .dkg-salary-curr-dropdown-item {
                                                                        text-align: left;
                                                                        padding: 7px 9px;
                                                                        border-bottom: 1px solid #ccc;
                                                                        font-size: 0.95rem;

                                                                        svg {
                                                                            width: 14px;
                                                                            height: 14px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .dk-boxKpi {
                                                    background: #eaeaea;
                                                    border-top: 1px solid #b9b9b9;
                                                    border-radius: 0 0 5px 5px;
                                                    // height: calc(100vh - 512px);
                                                    // overflow-y: auto;
                                                    .dk-suMMformGroupCon {
                                                        display: flex;
                                                        border-bottom: 1px solid #cecece;
                                                        width: 100%;
                                                        margin-bottom: 0rem;
                                                        &:last-child{
                                                            border-radius: 0 0 5px 5px;
                                                        }
                                                        .label-hover:hover {
                                                            color: blue;
                                                        }

                                                        input {
                                                            border-bottom: 1px solid #ccc;
                                                        }

                                                        label {
                                                            align-items: center;
                                                            min-width: 117px;
                                                            font-size: 0.90rem;
                                                            white-space: nowrap;
                                                            padding-right: 0.5rem;
                                                        }

                                                        label.dk-benfitslabel {
                                                            min-width: 20px;
                                                            max-width: 20px;
                                                        }

                                                        label.relocationlabel {
                                                            min-width: 125px;
                                                        }

                                                        .dk-simpleDrpDwnCon {
                                                            width: 100%;

                                                            .dk-simpleDrpDwn {
                                                                .dk-simpleDrpDwnToggle {
                                                                    color: #333;
                                                                    box-shadow: none;
                                                                    white-space: nowrap;
                                                                    margin: 0;
                                                                    background-color: #fff;
                                                                    border: 1px solid #ccc;
                                                                    padding: 0.35rem 0.75rem;
                                                                    text-transform: capitalize;
                                                                    display: flex;
                                                                    width: 100%;
                                                                    justify-content: center;
                                                                    font-size: 0.90rem;
                                                                    position: relative;

                                                                    &::after {
                                                                        position: absolute;
                                                                        right: 5px;
                                                                        top: 13px;
                                                                    }
                                                                }

                                                                .dk-simpleDrpDwnMenu {
                                                                    border-radius: 0;
                                                                    max-height: 180px;
                                                                    width: 100%;
                                                                    overflow-y: auto;
                                                                    padding: 0;
                                                                    border-color: #96c8da;
                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                    .dropdown-item {
                                                                        padding: 0.35rem 0.75rem;
                                                                        font-size: 0.9rem;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        input {
                                                            display: flex;
                                                            width: 100%;
                                                            margin: 0;
                                                            padding: 0.35rem 0.75rem;
                                                            justify-content: center;
                                                            align-items: center;
                                                            border-radius: 0;
                                                            text-transform: capitalize;
                                                            font-size: 0.95rem;
                                                            text-align: center;

                                                            &:focus {
                                                                outline: 0;
                                                                box-shadow: none;
                                                            }
                                                        }

                                                        input.dk-benfitsInput {
                                                            max-height: 30px;
                                                        }

                                                        .input-group {
                                                            input {
                                                                border-right: 0;
                                                            }

                                                            .input-group-prepend {
                                                                background-color: #fff;

                                                                .input-group-text {
                                                                    background-color: #fff;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .dkg-editcompany-headerCon {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                border-width: 1px 1px 0px;
                                                border-top-style: solid;
                                                border-right-style: solid;
                                                border-bottom-style: initial;
                                                border-left-style: solid;
                                                border-top-color: rgb(204, 204, 204);
                                                border-right-color: rgb(204, 204, 204);
                                                border-bottom-color: initial;
                                                border-left-color: rgb(204, 204, 204);
                                                border-image: initial;
                                                padding: 11px 8px;
                                                position: relative;
                                                background-color: #eaeaea;

                                                .dkg-editcompany-leftCon {
                                                    position: absolute;
                                                    left: 10px;
                                                    top: 6px;

                                                    .dk-simpleDrpDwnCon {
                                                        width: 100%;

                                                        .dk-simpleDrpDwn {
                                                            .dk-simpleDrpDwnToggle {
                                                                color: #333;
                                                                box-shadow: none;
                                                                white-space: nowrap;
                                                                margin: 0;
                                                                background-color: #eaeaea;
                                                                border: 1px solid #ccc;
                                                                padding: 0.40rem 0.75rem;
                                                                text-transform: capitalize;
                                                                display: flex;
                                                                width: 100%;
                                                                justify-content: flex-start;
                                                                font-size: 0.90rem;
                                                                position: relative;
                                                                min-width: 180px;

                                                                &::after {
                                                                    position: absolute;
                                                                    right: 5px;
                                                                    top: 13px;
                                                                }
                                                            }

                                                            .dk-simpleDrpDwnMenu {
                                                                border-radius: 0;
                                                                max-height: 180px;
                                                                width: 100%;
                                                                overflow-y: auto;
                                                                padding: 0;
                                                                border-color: #96c8da;
                                                                box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                .dropdown-item {
                                                                    padding: 0.35rem 0.75rem;
                                                                    font-size: 0.9rem;
                                                                }

                                                                input {
                                                                    border-bottom: 1px solid #ccc;
                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                            }

                                            .dkg-edit-jobfiles-mainCon {
                                                .dkg-add-new-btnCon {
                                                    margin-bottom: 5px;

                                                    .dkg-addnew-btn {
                                                        box-shadow: none;
                                                        border: 1px solid #ccc;
                                                        background-color: #fff;
                                                        color: #333;
                                                        font-size: 13px;
                                                        text-transform: capitalize;
                                                        padding: 7px 9px;
                                                        // min-width: 110px;
                                                        margin: 0;
                                                    }
                                                }

                                                .dkg-edit-jobfiles-tableCon {
                                                    .dkg-edit-jobfiles-table {
                                                        thead {
                                                            tr {
                                                                th {
                                                                    background: #485569;
                                                                    border: 1px solid gray;
                                                                    border-bottom: none;
                                                                    color: #fff;
                                                                    font-size: 13px;
                                                                    padding: 9px 7px;
                                                                    position: -webkit-sticky;
                                                                    position: sticky;
                                                                    text-align: center;
                                                                    top: 0;
                                                                    vertical-align: middle;
                                                                    white-space: nowrap;
                                                                    z-index: 9;
                                                                }
                                                            }
                                                        }

                                                        tbody {

                                                            tr {
                                                                td {
                                                                    background: #fff;
                                                                    border: 1px solid #ddd;
                                                                    color: #4d4a4a;
                                                                    font-size: 13px;
                                                                    font-weight: 400;
                                                                    padding: 9px 7px;
                                                                    position: relative;
                                                                    text-align: center;
                                                                    text-transform: capitalize;
                                                                    vertical-align: middle;
                                                                    white-space: nowrap;

                                                                    .dkg-viewfile-iconCon {
                                                                        display: flex;
                                                                        justify-content: center;
                                                                        align-items: center;

                                                                        .dkg-viewfile-icon {
                                                                            border-radius: 3px;
                                                                            font-size: 14px;
                                                                            height: 30px !important;
                                                                            line-height: 30px;
                                                                            width: 30px !important;
                                                                            background-color: #2196f3;
                                                                            color: #fff;
                                                                        }
                                                                    }

                                                                    .dkg-file-actions {
                                                                        display: flex;
                                                                        justify-content: center;
                                                                        align-items: center;

                                                                        .dkg-viewfile-icon {
                                                                            border-radius: 3px;
                                                                            font-size: 14px;
                                                                            height: 30px !important;
                                                                            line-height: 30px;
                                                                            width: 30px !important;
                                                                            background-color: #2196f3;
                                                                            color: #fff;
                                                                        }

                                                                        .dkg-download-icon {
                                                                            border-radius: 3px;
                                                                            font-size: 14px;
                                                                            height: 30px !important;
                                                                            line-height: 30px;
                                                                            width: 30px !important;
                                                                            background-color: #31a886;
                                                                            color: #fff;
                                                                        }

                                                                        .dkg-editfile-icon {
                                                                            border-radius: 3px;
                                                                            font-size: 14px;
                                                                            height: 30px;
                                                                            line-height: 30px;
                                                                            width: 30px;
                                                                            background-color: #fee69c;
                                                                            color: #333;
                                                                        }

                                                                        .dkg-deletefile-icon {
                                                                            border-radius: 3px;
                                                                            font-size: 14px;
                                                                            height: 30px;
                                                                            line-height: 30px;
                                                                            width: 30px;
                                                                            background-color: #dc3545;
                                                                            color: #fff;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .dk-suMMRightCon {
                                            .dk-suMMformGroupCon {
                                                display: flex;
                                                width: 100%;
                                                border-bottom: 1px solid #cecece;

                                                input {
                                                    border-bottom: 1px solid #ccc;
                                                }

                                                label {
                                                    align-items: center;
                                                    min-width: 134px;
                                                    font-size: 0.90rem;

                                                    &.dkg-sendout-label-234 {
                                                        margin-right: 10px;
                                                    }
                                                }

                                                .dk-simpleDrpDwnCon {
                                                    width: 100%;

                                                    .dk-simpleDrpDwn {
                                                        .dk-simpleDrpDwnToggle {
                                                            color: #333;
                                                            box-shadow: none;
                                                            white-space: nowrap;
                                                            margin: 0;
                                                            background-color: #fff;
                                                            border: 1px solid #ccc;
                                                            padding: 0.35rem 0.75rem;
                                                            text-transform: capitalize;
                                                            display: flex;
                                                            width: 100%;
                                                            justify-content: center;
                                                            font-size: 0.90rem;
                                                            position: relative;

                                                            &::after {
                                                                position: absolute;
                                                                right: 5px;
                                                                top: 13px;
                                                            }
                                                        }

                                                        .dk-simpleDrpDwnMenu {
                                                            border-radius: 0;
                                                            max-height: 180px;
                                                            width: 100%;
                                                            overflow-y: auto;
                                                            padding: 0;
                                                            border-color: #96c8da;
                                                            box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                            .dropdown-item {
                                                                padding: 0.35rem 0.75rem;
                                                                font-size: 0.9rem;
                                                            }
                                                        }
                                                    }
                                                }

                                                input {
                                                    display: flex;
                                                    width: 100%;
                                                    margin: 0;
                                                    padding: 0.35rem 0.75rem;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 0;
                                                    text-transform: capitalize;
                                                    font-size: 0.95rem;
                                                    text-align: center;

                                                    &:focus {
                                                        outline: 0;
                                                        box-shadow: none;
                                                    }
                                                }

                                                .input-group {
                                                    input {
                                                        border-right: 0;
                                                    }

                                                    .input-group-prepend {
                                                        background-color: #fff;

                                                        .input-group-text {
                                                            background-color: #fff;
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-suMMRightConBoxBg {

                                                .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
                                                .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
                                                    height: 571px;
                                                    background-color: #fff;

                                                    p {
                                                        font-size: 14px;
                                                    }
                                                }

                                                .dk-suMMRightBoxHeader {
                                                    background: #dddddd;
                                                    border: 1px solid #cecece;
                                                    border-bottom: none;
                                                    width: 100%;
                                                    padding: 5px;
                                                    height: 40px;
                                                    border-radius: 5px 5px 0 0;
                                                    position: relative;

                                                    h3 {
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        color: #3e3e3e;
                                                        font-size: 18px;
                                                        font-weight: normal;
                                                        position: relative;
                                                        .dkg-pophover-iconCon{
                                                            .dkg-pophover-label{
                                                                position: relative;
                                                                .dkg-pophover-icon{
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: center;
                                                                    width: 22px;
                                                                    height: 22px;
                                                                    background-color: #eaeaea;
                                                                    position: relative;
                                                                    left: 8px;
                                                                    top: 3px;
                                                                    border-radius: 50%;
                                                                    border: 1px solid #ddd;
                                                                    svg{
                                                                        width: 12px;
                                                                        height: 12px;
                                                                    }
                                                                }
                                                                .notdisplayed {
                                                                    display: none;
                                                                }
                                                                .displayed {
                                                                    display: flex;
                                                                    flex-wrap: wrap;
                                                                    margin: auto;
                                                                    position: absolute;
                                                                    top: -9px;
                                                                    left: 35px;
                                                                    background-color: #396A95;
                                                                    color: #fff;
                                                                    padding: 8px;
                                                                    border: 1px solid transparent;
                                                                    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.20), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
                                                                    border-radius: 4px;
                                                                    z-index: 999999;

                                                                    p{
                                                                        font-size: 12px;
                                                                    }
                                                                    &:after {
                                                                        content: "";
                                                                        width: 0;
                                                                        height: 0;
                                                                        border-color: transparent #396A95 transparent transparent;
                                                                        border-style: solid;
                                                                        border-width: 8.5px 12px 8.5px 0;
                                                                        line-height: 0;
                                                                        _border-color: #000 #396A95 #000 #000;
                                                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                                                        position: absolute;
                                                                        left: -12px;
                                                                        top: 10px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .dkg-pophover-icon{
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            width: 18px;
                                                            height: 18px;
                                                            background-color: #eaeaea;
                                                            position: relative;
                                                            left: 8px;
                                                            top: 3px;
                                                            border-radius: 50%;
                                                            border: 1px solid #d3bfbf;
                                                            cursor: pointer;
                                                            display: none;
                                                            svg{
                                                                width: 14px;
                                                                height: 14px;
                                                            }
                                                            &:hover {
                                                                box-shadow: 0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
                                                                transition: all .5s ease;
                                                            }
                                                        }
                                                    }

                                                    .dkg-detail-icon-con {
                                                        position: absolute;
                                                        right: 10px;
                                                        top: 10px;

                                                        .dkg-addnewfile {
                                                            cursor: pointer;

                                                            i {
                                                                cursor: pointer;

                                                                input {
                                                                    left: -20px;
                                                                    cursor: pointer;
                                                                    height: 24px;
                                                                    opacity: 0;
                                                                    position: relative;
                                                                    width: 25px;
                                                                    z-index: 9;
                                                                }
                                                            }

                                                            input {
                                                                left: -20px;
                                                                cursor: pointer;
                                                                height: 24px;
                                                                opacity: 0;
                                                                position: relative;
                                                                width: 25px;
                                                                z-index: 9;
                                                            }
                                                        }
                                                    }
                                                }

                                                .dk-suMMRightboxBody {
                                                    background: #eaeaea;
                                                    border-top: 1px solid #b9b9b9;
                                                    border-radius: 0 0 5px 5px;
                                                    // height: calc(100vh - 512px);
                                                    // overflow-y: auto;
                                                    .dk-suMMformGroupCon {
                                                        min-height: 34px;
                                                        &:last-child{
                                                            border-radius: 0 0 5px 5px;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.dkg-livejobs-tabpane-234 {
                                            .dkg-livejobs-mainRow-23 {
                                                margin-left: -19px;
                                                margin-right: -19px;

                                                .dkg-livejobs-mainCol-23 {
                                                    padding-left: 0;

                                                    .dkg-livejobs-maintabCon {
                                                        .dkg-livejobs-maintabs-pills {
                                                            display: flex;
                                                            flex: 0 0 100%;

                                                            .flex-row.nav.nav-pills {
                                                                display: flex;
                                                                width: 100%;
                                                                background-color: #fff;

                                                                .nav-item {
                                                                    min-width: 12.5%;
                                                                    max-width: 12.5%;

                                                                    .nav-link {
                                                                        text-align: center !important;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .tab-content{
                                                            .tab-pane{
                                                                &.dkg-savedads-tabpane{
                                                                    .dkg-savedads-mainCon{
                                                                        .dkg-savedads-header{
                                                                            display: flex;
                                                                            align-items: center;
                                                                            justify-content: space-between;
                                                                            width: 100%;
                                                                            margin-top: 10px;
                                                                            margin-bottom: 5px;
                                                                            .dkg-left-savedads-Con{
                                                                                display: flex;
                                                                                align-items: center;
                                                                                justify-content: flex-start;
                                                                                .dk-searchCols{
                                                                                    margin-right: 8px;
                                                                                    max-width: 160px;
                                                                                    position: relative;
                                                                                    width: 100%;
                                                                                    input{
                                                                                        background-color: #fff;
                                                                                        border: 1px solid #ddd;
                                                                                        color: #818181;
                                                                                        height: 32px;
                                                                                        padding: 0 12px;
                                                                                        text-align: left;
                                                                                    }
                                                                                    button{
                                                                                        background-color: #037db2;
                                                                                        border: 1px solid #818181;
                                                                                        color: #818181;
                                                                                        display: inline-block;
                                                                                        font-size: 13px;
                                                                                        height: 32px;
                                                                                        outline: none;
                                                                                        position: absolute;
                                                                                        right: 0;
                                                                                        text-align: center;
                                                                                        top: 0;
                                                                                        width: 32px;
                                                                                        svg{
                                                                                            width: 14px;
                                                                                            height: 14px;
                                                                                            color: #fff;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            .title{
                                                                                font-size: 17px;
                                                                            }
                                                                            .dkg-right-savedads-Con{
                                                                                display: flex;
                                                                                align-items: center;
                                                                                justify-content: flex-end;
                                                                                .dkg-addnew-btn {
                                                                                    background-color: #fff;
                                                                                    border: 1px solid #ccc;
                                                                                    box-shadow: none;
                                                                                    color: #333;
                                                                                    font-size: 13px;
                                                                                    margin: 0;
                                                                                    padding: 7px 9px;
                                                                                    text-transform: capitalize;
                                                                                }
                                                                            }
                                                                        }
                                                                        .dkg-edit-jobfiles-tableCon {
                                                                            height: calc(100vh - 210px);
                                                                            overflow-y: auto;
                                                                            padding-bottom: 0;
                                                                            position: relative;
                                                                            .dkg-edit-jobfiles-table {
                                                                                thead {
                                                                                    tr{
                                                                                        th{
                                                                                            background: #037db2;
                                                                                            border: 1px solid gray;
                                                                                            border-bottom: none;
                                                                                            color: #fff;
                                                                                            font-size: 13px;
                                                                                            padding: 9px 7px;
                                                                                            position: -webkit-sticky;
                                                                                            position: sticky;
                                                                                            text-align: center;
                                                                                            top: 0;
                                                                                            vertical-align: middle;
                                                                                            white-space: nowrap;
                                                                                            z-index: 9;
                                                                                        }
                                                                                    }
                                                                                }
                                                                                tbody{
                                                                                    tr{
                                                                                        td{
                                                                                            background: #fff;
                                                                                            border: 1px solid #ddd;
                                                                                            color: #4d4a4a;
                                                                                            font-size: 13px;
                                                                                            font-weight: 400;
                                                                                            padding: 9px 7px;
                                                                                            position: relative;
                                                                                            text-align: center;
                                                                                            text-transform: capitalize;
                                                                                            vertical-align: middle;
                                                                                            white-space: nowrap;
                                                                                            .dkg-file-actions {
                                                                                                align-items: center;
                                                                                                display: flex;
                                                                                                justify-content: center;
                                                                                                .dkg-viewfile-icon {
                                                                                                    background-color: #2196f3;
                                                                                                    border-radius: 3px;
                                                                                                    color: #fff;
                                                                                                    font-size: 14px;
                                                                                                    height: 30px!important;
                                                                                                    line-height: 30px;
                                                                                                    width: 30px!important;
                                                                                                }
                                                                                                .dkg-downfile-icon {
                                                                                                    background-color: #31a886;
                                                                                                    border-radius: 3px;
                                                                                                    color: #fff;
                                                                                                    font-size: 14px;
                                                                                                    height: 30px!important;
                                                                                                    line-height: 30px;
                                                                                                    width: 30px!important;
                                                                                                }
                                                                                                .dkg-edit-icon {
                                                                                                    background-color: #fee69c;
                                                                                                    border-radius: 3px;
                                                                                                    color: #333;
                                                                                                    font-size: 14px;
                                                                                                    height: 30px;
                                                                                                    line-height: 30px;
                                                                                                    width: 30px;
                                                                                                }
                                                                                                .dkg-delete-icon {
                                                                                                    background-color: #dc3545;
                                                                                                    border-radius: 3px;
                                                                                                    color: #fff;
                                                                                                    font-size: 14px;
                                                                                                    height: 30px;
                                                                                                    line-height: 30px;
                                                                                                    width: 30px;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .dk-Adsbox {
                                            .interviewsTable {
                                                padding-top: 0.51rem;

                                                .dkg-livejobs-adsboxRow {
                                                    .dkg-livejobs-adsboxCol {
                                                        .dkg-livejobs-adsboxRow-234 {
                                                            .dkg-livejobs-adsboxleftSide {
                                                                background-color: #244158;
                                                                flex: 0 0 14.666667%;
                                                                padding-left: 0;
                                                                padding-right: 0;
                                                                margin-top: 14px;
                                                                height: calc(100vh - 250px);

                                                                .dkg-livejobs-adsbox-navpills {
                                                                    display: block !important;
                                                                    width: 100%;
                                                                    background-color: #fff;

                                                                    .nav-item {
                                                                        max-width: inherit;

                                                                        .nav-link {
                                                                            background-color: #244158 !important;
                                                                            border-bottom: 1px solid #3e5b73 !important;
                                                                            color: #fff;
                                                                            text-align: left !important;

                                                                            &.active {
                                                                                background-color: #eaeaea !important;
                                                                                color: #333;
                                                                            }
                                                                        }

                                                                        &:first-child {
                                                                            border-top: none;
                                                                            .nav-link{
                                                                                &.active{
                                                                                    border-top: 1px solid #ddd !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            .dkg-livejobs-adsboxRightSide {
                                                                flex: 0 0 83.333333%;
                                                                max-width: 83.333333%;
                                                                margin-left: 2%;
                                                                .tab-content{
                                                                    .dkg-taxcalculator-tabpane{
                                                                        .dkg-salary-tab-box{
                                                                            margin-top: 14px;
                                                                            .dkg-salary-tab-header{
                                                                                background: #dddddd;
                                                                                border: 1px solid #cecece;
                                                                                border-bottom: none;
                                                                                width: 100%;
                                                                                padding: 5px;
                                                                                height: 40px;
                                                                                border-radius: 5px 5px 0 0;
                                                                                position: relative;
                                                                                .dkg-salary-header-title{
                                                                                    text-align: center;
                                                                                    color: #3e3e3e;
                                                                                    font-size: 20px;
                                                                                    font-weight: normal;
                                                                                }
                                                                            }
                                                                            .dkg-salary-tabbody{
                                                                                background-color: #ddd;
                                                                                border: 1px solid #cecece;
                                                                                border-radius: 0 0 5px 5px;
                                                                                display: flex;
                                                                                align-items: center;
                                                                                justify-content: center;
                                                                                height: 200px;
                                                                                margin-top: 100px;
                                                                                .dkg-income-inputgrp{
                                                                                    width: 100%;
                                                                                    max-width: 95%;
                                                                                    margin: 0 auto;
                                                                                    display: flex;
                                                                                    align-items: center;
                                                                                    .dkg-input-url{
                                                                                        margin: 0;
                                                                                        border: 1px solid #ddd;
                                                                                        padding: 8px 15px;
                                                                                        height: 38px;
                                                                                        text-align: left;
                                                                                    }
                                                                                    .dkg-open-url-btn{
                                                                                        background: #26597d;
                                                                                        border: none;
                                                                                        color: #fff;
                                                                                        border-radius: 0;
                                                                                        cursor: pointer;
                                                                                        display: inline-block;
                                                                                        font-size: 14px;
                                                                                        font-weight: 500;
                                                                                        min-width: 80px;
                                                                                        outline: none;
                                                                                        padding: 8px 15px;
                                                                                        text-transform: uppercase;
                                                                                        white-space: nowrap;
                                                                                        height: 38px;
                                                                                        margin: 0;
                                                                                        &:hover{
                                                                                            box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
                                                                                        }
                                                                                    }
                                                                                }

                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.dkg-livejob-status-tabpane {
                                            .dk-Adsbox {
                                                margin-top: 1.5rem;

                                                .dkg-job-staus-col {
                                                    &.dkg-edit-jobstus-leftcol {
                                                        flex: 0 0 38.666667%;
                                                        max-width: 38.666667%;
                                                    }

                                                    &.dkg-edit-jobstus-rightcol {
                                                        flex: 0 0 55%;
                                                        max-width: 55%;
                                                    }

                                                    .dk-suMMformGroupCon {
                                                        display: flex;
                                                        align-items: center;

                                                        .dk-statusDrpDwnCon {
                                                            width: 100%;

                                                            .dropdown {
                                                                width: 100%;
                                                                display: flex;

                                                                .dropdown-toggle,
                                                                .dk-statusDrpDwn.dropdown-toggle {
                                                                    width: 100%;
                                                                    box-shadow: none;
                                                                    margin: 0;
                                                                    padding: 9px 10px;
                                                                    text-transform: capitalize;
                                                                    font-size: 12px;
                                                                    position: relative;
                                                                    background-color: #fff;
                                                                    border: 1px solid #ddd;
                                                                    border-top-color: rgb(204, 204, 204);
                                                                    border-right-color: rgb(204, 204, 204);
                                                                    border-bottom-color: rgb(204, 204, 204);
                                                                    border-left-color: rgb(204, 204, 204);
                                                                    height: calc(1.5em + 0.75rem + 2px);
                                                                    line-height: 12px;

                                                                    &::after {
                                                                        position: absolute;
                                                                        right: 10px;
                                                                        top: 12px;
                                                                        font-size: 14px;
                                                                    }
                                                                }

                                                                .dk-statusDrpDwnMenu,
                                                                .dk-simpleDrpDwnMenu {
                                                                    border-radius: 0;
                                                                    padding: 0;
                                                                    width: 100%;

                                                                    input {
                                                                        border-bottom: 1px solid #ddd;
                                                                        // width: 100%;
                                                                        // max-width: 95%;
                                                                        // margin: 12px auto;
                                                                    }

                                                                    .list-unstyled {
                                                                        .dropdown-item {
                                                                            font-size: 13px;
                                                                            padding: 7px 10px;
                                                                            border-bottom: 1px solid #ddd;

                                                                            &:last-child {
                                                                                border-bottom: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .dk-AmDropDwnCon {
                                                            .dk-AmDropDwn {
                                                                .dk-AmDropDwnToggle {
                                                                    background-color: transparent;
                                                                    box-shadow: none;
                                                                    margin: 0;
                                                                    padding: 0 !important;

                                                                    &::after {
                                                                        display: none;
                                                                    }

                                                                    .tooltipMain {
                                                                        .filterImg {
                                                                            width: 37px;
                                                                            height: 37px;
                                                                            border: 2px dotted rgb(153, 153, 153);
                                                                            border-radius: 50%;
                                                                        }
                                                                    }
                                                                }

                                                                .dk-AmDropDwnMenu {
                                                                    background: #234158;
                                                                    border: 1px solid #234158;
                                                                    left: 22px !important;
                                                                    padding: 5px;
                                                                    top: -10px !important;
                                                                    transform: translate(22px) !important;
                                                                    -webkit-transform: translate(22px) !important;

                                                                    &:after {
                                                                        border-color: transparent #234158 transparent transparent;
                                                                        _border-color: #000 #ccc #000 #000;
                                                                        border-style: solid;
                                                                        border-width: 8.5px 12px 8.5px 0;
                                                                        content: "";
                                                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                                                        height: 0;
                                                                        left: -12px;
                                                                        line-height: 0;
                                                                        position: absolute;
                                                                        top: 15px;
                                                                        width: 0;
                                                                    }

                                                                    .d-flex {
                                                                        a.dropdown-item {
                                                                            display: inline-block;
                                                                            height: 35px;
                                                                            margin: 0 3px;
                                                                            padding: 0;
                                                                            width: 35px;

                                                                            .tooltipMain {
                                                                                .filterImg {
                                                                                    border-radius: 50%;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .thridBox {
                                                            .AmImgConDrop {
                                                                .dk-suMMSrchDropDwnCon {
                                                                    .dk-suMMSrchDropDwn {
                                                                        a {
                                                                            img {
                                                                                border: 2px dotted #999;
                                                                                border-radius: 50%;
                                                                                height: 37px;
                                                                                width: 37px;
                                                                            }
                                                                        }
                                                                    }

                                                                    .dk-suMMSrchDropDwnMenu {
                                                                        border-radius: 0;
                                                                        padding: 0;

                                                                        input {
                                                                            border-bottom: 1px solid #ccc;
                                                                        }

                                                                        ul {
                                                                            padding: 0;
                                                                            max-height: 220px;
                                                                            overflow-y: auto;

                                                                            .dk-assignRecruitersCon {
                                                                                p {
                                                                                    padding: 0.25rem 1rem;
                                                                                    margin-bottom: 0;
                                                                                    font-size: 0.95rem;
                                                                                }

                                                                                .dk-reclistItem {
                                                                                    padding: 0.25rem 1rem;

                                                                                    &.active {
                                                                                        .dk-assignRecCon {
                                                                                            span {
                                                                                                color: #fff;
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    .dk-assignRecCon {
                                                                                        display: flex;
                                                                                        position: relative;
                                                                                        align-items: center;

                                                                                        img.recruiterimg {
                                                                                            width: 35px;
                                                                                            height: 35px;
                                                                                            border-radius: 50%;
                                                                                            border: 2px dotted #ddd;
                                                                                            margin-right: 10px;
                                                                                        }

                                                                                        span {
                                                                                            font-size: 0.85rem;
                                                                                            font-weight: bold;
                                                                                            color: #333;
                                                                                        }

                                                                                        .crossIcon {
                                                                                            position: absolute;
                                                                                            right: 10px;

                                                                                            i {
                                                                                                font-size: 16px;
                                                                                                color: #333;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            .dk-remaingRecruiters {
                                                                                p {
                                                                                    padding: 0.25rem 1rem;
                                                                                    margin-bottom: 0;
                                                                                    font-size: 0.95rem;
                                                                                }

                                                                                .dk-reclistItem {
                                                                                    padding: 0.25rem 1rem;

                                                                                    &.active {
                                                                                        .dk-unassignRecCon {
                                                                                            span {
                                                                                                color: #fff;
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    .dk-unassignRecCon {
                                                                                        img.recruiterimg {
                                                                                            width: 35px;
                                                                                            height: 35px;
                                                                                            border-radius: 50%;
                                                                                            border: 2px dotted #ddd;
                                                                                            margin-right: 10px;
                                                                                        }

                                                                                        span {
                                                                                            font-size: 0.85rem;
                                                                                            opacity: .8;
                                                                                            font-weight: bold;
                                                                                            color: #212529;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                        .dkg-salary-tab-rightCon{
                                            // background-color: #eaeaea;
                                            border-radius: 5px;
                                            // height: calc(100vh - 80px);
                                            // height: 650px;
                                            .dkg-salary-tab-box{
                                                margin-top: 1.4rem;
                                                &:last-child{
                                                    margin-top: 23px;
                                                    .dkg-salary-tab-header{
                                                        background: #dddddd;
                                                        border: 1px solid #cecece;
                                                        border-bottom: none;
                                                        width: 100%;
                                                        padding: 5px;
                                                        height: 40px;
                                                        border-radius: 5px 5px 0 0;
                                                        position: relative;
                                                        .dkg-salary-header-title{
                                                            text-align: center;
                                                            color: #3e3e3e;
                                                            font-size: 20px;
                                                            font-weight: normal;
                                                        }
                                                    }
                                                    .dkg-salary-tabbody{
                                                        height: 93px;
                                                        background-color: #eaeaea;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        border: 1px solid #cecece;
                                                        border-radius: 0 0 5px 5px;
                                                        .dkg-income-inputgrp{
                                                            width: 100%;
                                                            max-width: 95%;
                                                            margin: 0 auto;
                                                            display: flex;
                                                            align-items: center;
                                                            .dkg-input-url{
                                                                margin: 0;
                                                                border: 1px solid #ddd;
                                                                padding: 8px 15px;
                                                                height: 38px;
                                                                text-align: left;
                                                                background-color: #fff;
                                                            }
                                                            .dkg-open-url-btn{
                                                                background: #26597d;
                                                                border: none;
                                                                color: #fff;
                                                                border-radius: 0;
                                                                cursor: pointer;
                                                                display: inline-block;
                                                                font-size: 14px;
                                                                font-weight: 500;
                                                                min-width: 80px;
                                                                outline: none;
                                                                padding: 8px 15px;
                                                                text-transform: uppercase;
                                                                white-space: nowrap;
                                                                height: 38px;
                                                                margin: 0;
                                                                &:hover{
                                                                    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
                                                                }
                                                            }
                                                        }

                                                    }
                                                }
                                                &:first-child{
                                                    .dkg-salary-tab-header{
                                                        background: #dddddd;
                                                        border: 1px solid #cecece;
                                                        border-radius: 5px 5px 0 0;
                                                        border-bottom: none;
                                                        width: 100%;
                                                        padding: 5px;
                                                        height: 40px;
                                                        position: relative;
                                                        .dkg-salary-header-title{
                                                            text-align: center;
                                                            color: #3e3e3e;
                                                            font-size: 18px;
                                                            font-weight: normal;
                                                        }
                                                    }
                                                    .dkg-salary-tabbody{
                                                        height: 92px;
                                                        background-color: #eaeaea;
                                                        border: 1px solid #cecece;
                                                        border-radius: 0 0 5px 5px;
                                                        padding: 7px 15px;
                                                        overflow-y: auto;
                                                        div{
                                                            p{
                                                                background-color: transparent !important;
                                                                font-size: 13px;
                                                                span{
                                                                    background-color: transparent !important;
                                                                }
                                                            }
                                                        }
                                                        .jodit-react-container{
                                                            .jodit-container{
                                                                .jodit-container:not(.jodit_inline) .jodit-workplace {
                                                                    background-color: #eaeaea !important;
                                                                    p{
                                                                        background-color: #eaeaea !important;
                                                                        span{
                                                                            background-color: #eaeaea !important;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .jodit-container .jodit-workplace .jodit-wysiwyg{
                                                                background-color: #eaeaea !important;
                                                                p{
                                                                    background-color: #eaeaea !important;
                                                                    span{
                                                                        background-color: #eaeaea !important;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-salary-tabbody {
                                                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                                                        scrollbar-width: none;  /* Firefox */
                                                    }
                                                    .dkg-salary-tabbody::-webkit-scrollbar { 
                                                        display: none;  /* Safari and Chrome */
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.dkg-jobspecpopup-col-12{
                                padding:1.4rem;
                                .dkg-jobspecpopup-tabContent{
                                    .dkg-jobspecpopup-tabpane{
                                        .dkg-jobspecpopup-contentMain{
                                            color: #20394a;
                                            .dkg-jobspec-company-mainCon{
                                                &.dkg-jobspec-modal-company-mainCon{
                                                    .dkg-jobspec-company-header{
                                                        margin: 0 !important;
                                                        position: relative;
                                                        top: -10px;
                                                    }
                                                    .dkg-jobspec-company-mainRow{
                                                        .dkg-jobspec-company-sideMenu{
                                                            .dkg-jobspec-company-navpills{
                                                                height: calc(100vh - 128px) !important;
                                                            }
                                                        }
                                                        .dkg-jobspec-rightCon{
                                                            .dkg-jobspec-tabcontent{
                                                                .dkg-jobspec-tabpane{
                                                                    height: calc(100vh - 150px) !important;
                                                                    padding-bottom: 0;
                                                                    .dkg-companynew-info-mainCon{
                                                                        height: calc(100vh - 150px);
                                                                        padding-bottom: 0;
                                                                    }
                                                                    .dkg-jobspec-read-editor-Con{
                                                                       height: calc(100vh - 150px);
                                                                       padding-bottom: 0;
                                                                    }
                                                                    .dkg-company-video-mainCon,
                                                                    .dkg-company-album-mainCon{
                                                                       height: calc(100vh - 150px);
                                                                       padding-bottom: 60px;
                                                                    }
                                                                    .dkg-company-sm-mainCon{
                                                                        height: calc(100vh - 150px);
                                                                        padding-bottom: 105px;
                                                                     }
                                                                }
                                                            }
                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.dkg-editDetViewBottomSecCon{
                            .dk-ClentViewTabPane.summaryTabPane.dkg-livejobs-tabpane-234{
                                .dkg-livejobs-mainRow-23 {
                                    margin: 0;
                                    .dkg-livejobs-mainCol-23 {
                                        padding-left: 0;

                                        .dkg-livejobs-maintabCon {
                                            .dkg-livejobs-maintabs-pills {
                                                display: flex;
                                                flex: 0 0 100%;

                                                .flex-row.nav.nav-pills {
                                                    display: flex;
                                                    width: 100%;
                                                    background-color: #fff;

                                                    .nav-item {
                                                        min-width: 12.5%;
                                                        max-width: 12.5%;

                                                        .nav-link {
                                                            text-align: center !important;
                                                        }
                                                    }
                                                }
                                            }
                                            .tab-content{
                                                .tab-pane{
                                                    &.dkg-savedads-tabpane{
                                                        .dkg-savedads-mainCon{
                                                            .dkg-savedads-header{
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: space-between;
                                                                width: 100%;
                                                                margin-top: 10px;
                                                                margin-bottom: 5px;
                                                                .dkg-left-savedads-Con{
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: flex-start;
                                                                    .dk-searchCols{
                                                                        margin-right: 8px;
                                                                        max-width: 160px;
                                                                        position: relative;
                                                                        width: 100%;
                                                                        input{
                                                                            background-color: #fff;
                                                                            border: 1px solid #ddd;
                                                                            color: #818181;
                                                                            height: 32px;
                                                                            padding: 0 12px;
                                                                            text-align: left;
                                                                        }
                                                                        button{
                                                                            background-color: #037db2;
                                                                            border: 1px solid #818181;
                                                                            color: #818181;
                                                                            display: inline-block;
                                                                            font-size: 13px;
                                                                            height: 32px;
                                                                            outline: none;
                                                                            position: absolute;
                                                                            right: 0;
                                                                            text-align: center;
                                                                            top: 0;
                                                                            width: 32px;
                                                                            svg{
                                                                                width: 14px;
                                                                                height: 14px;
                                                                                color: #fff;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                .title{
                                                                    font-size: 17px;
                                                                }
                                                                .dkg-right-savedads-Con{
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: flex-end;
                                                                    .dkg-addnew-btn {
                                                                        background-color: #fff;
                                                                        border: 1px solid #ccc;
                                                                        box-shadow: none;
                                                                        color: #333;
                                                                        font-size: 13px;
                                                                        margin: 0;
                                                                        padding: 7px 9px;
                                                                        text-transform: capitalize;
                                                                    }
                                                                }
                                                            }
                                                            .dkg-edit-jobfiles-tableCon {
                                                                height: calc(100vh - 210px);
                                                                overflow-y: auto;
                                                                padding-bottom: 0;
                                                                position: relative;
                                                                .dkg-edit-jobfiles-table {
                                                                    thead {
                                                                        tr{
                                                                            th{
                                                                                background: #037db2;
                                                                                border: 1px solid gray;
                                                                                border-bottom: none;
                                                                                color: #fff;
                                                                                font-size: 13px;
                                                                                padding: 9px 7px;
                                                                                position: -webkit-sticky;
                                                                                position: sticky;
                                                                                text-align: center;
                                                                                top: 0;
                                                                                vertical-align: middle;
                                                                                white-space: nowrap;
                                                                                z-index: 9;
                                                                            }
                                                                        }
                                                                    }
                                                                    tbody{
                                                                        tr{
                                                                            td{
                                                                                background: #fff;
                                                                                border: 1px solid #ddd;
                                                                                color: #4d4a4a;
                                                                                font-size: 13px;
                                                                                font-weight: 400;
                                                                                padding: 9px 7px;
                                                                                position: relative;
                                                                                text-align: center;
                                                                                text-transform: capitalize;
                                                                                vertical-align: middle;
                                                                                white-space: nowrap;
                                                                                .dkg-file-actions {
                                                                                    align-items: center;
                                                                                    display: flex;
                                                                                    justify-content: center;
                                                                                    .dkg-viewfile-icon {
                                                                                        background-color: #2196f3;
                                                                                        border-radius: 3px;
                                                                                        color: #fff;
                                                                                        font-size: 14px;
                                                                                        height: 30px!important;
                                                                                        line-height: 30px;
                                                                                        width: 30px!important;
                                                                                    }
                                                                                    .dkg-downfile-icon {
                                                                                        background-color: #31a886;
                                                                                        border-radius: 3px;
                                                                                        color: #fff;
                                                                                        font-size: 14px;
                                                                                        height: 30px!important;
                                                                                        line-height: 30px;
                                                                                        width: 30px!important;
                                                                                    }
                                                                                    .dkg-edit-icon {
                                                                                        background-color: #fee69c;
                                                                                        border-radius: 3px;
                                                                                        color: #333;
                                                                                        font-size: 14px;
                                                                                        height: 30px;
                                                                                        line-height: 30px;
                                                                                        width: 30px;
                                                                                    }
                                                                                    .dkg-delete-icon {
                                                                                        background-color: #dc3545;
                                                                                        border-radius: 3px;
                                                                                        color: #fff;
                                                                                        font-size: 14px;
                                                                                        height: 30px;
                                                                                        line-height: 30px;
                                                                                        width: 30px;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dk-ClentViewTabPane{
                                                        .dk-Adsbox {
                                                            margin-top: 0;
                                                            .dkg-job-staus-col {
                                                                &.dkg-edit-jobstus-leftcol {
                                                                    flex: 0 0 38.666667%;
                                                                    max-width: 38.666667%;
                                                                }

                                                                &.dkg-edit-jobstus-rightcol {
                                                                    flex: 0 0 55%;
                                                                    max-width: 55%;
                                                                }

                                                                .dk-suMMformGroupCon {
                                                                    display: flex;
                                                                    align-items: center;

                                                                    .dk-statusDrpDwnCon {
                                                                        width: 100%;

                                                                        .dropdown {
                                                                            width: 100%;
                                                                            display: flex;

                                                                            .dropdown-toggle,
                                                                            .dk-statusDrpDwn.dropdown-toggle {
                                                                                width: 100%;
                                                                                box-shadow: none;
                                                                                margin: 0;
                                                                                padding: 9px 10px;
                                                                                text-transform: capitalize;
                                                                                font-size: 12px;
                                                                                position: relative;
                                                                                background-color: #fff;
                                                                                border: 1px solid #ddd;
                                                                                border-top-color: rgb(204, 204, 204);
                                                                                border-right-color: rgb(204, 204, 204);
                                                                                border-bottom-color: rgb(204, 204, 204);
                                                                                border-left-color: rgb(204, 204, 204);
                                                                                height: calc(1.5em + 0.75rem + 2px);
                                                                                line-height: 12px;

                                                                                &::after {
                                                                                    position: absolute;
                                                                                    right: 10px;
                                                                                    top: 12px;
                                                                                    font-size: 14px;
                                                                                }
                                                                            }

                                                                            .dk-statusDrpDwnMenu,
                                                                            .dk-simpleDrpDwnMenu {
                                                                                border-radius: 0;
                                                                                padding: 0;
                                                                                width: 100%;

                                                                                input {
                                                                                    border-bottom: 1px solid #ddd;
                                                                                    // width: 100%;
                                                                                    // max-width: 95%;
                                                                                    // margin: 12px auto;
                                                                                }

                                                                                .list-unstyled {
                                                                                    .dropdown-item {
                                                                                        font-size: 13px;
                                                                                        padding: 7px 10px;
                                                                                        border-bottom: 1px solid #ddd;

                                                                                        &:last-child {
                                                                                            border-bottom: none;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    .dk-AmDropDwnCon {
                                                                        .dk-AmDropDwn {
                                                                            .dk-AmDropDwnToggle {
                                                                                background-color: transparent;
                                                                                box-shadow: none;
                                                                                margin: 0;
                                                                                padding: 0 !important;

                                                                                &::after {
                                                                                    display: none;
                                                                                }

                                                                                .tooltipMain {
                                                                                    .filterImg {
                                                                                        width: 37px;
                                                                                        height: 37px;
                                                                                        border: 2px dotted rgb(153, 153, 153);
                                                                                        border-radius: 50%;
                                                                                    }
                                                                                }
                                                                            }

                                                                            .dk-AmDropDwnMenu {
                                                                                background: #234158;
                                                                                border: 1px solid #234158;
                                                                                left: 22px !important;
                                                                                padding: 5px;
                                                                                top: -10px !important;
                                                                                transform: translate(22px) !important;
                                                                                -webkit-transform: translate(22px) !important;

                                                                                &:after {
                                                                                    border-color: transparent #234158 transparent transparent;
                                                                                    _border-color: #000 #ccc #000 #000;
                                                                                    border-style: solid;
                                                                                    border-width: 8.5px 12px 8.5px 0;
                                                                                    content: "";
                                                                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                                                                    height: 0;
                                                                                    left: -12px;
                                                                                    line-height: 0;
                                                                                    position: absolute;
                                                                                    top: 15px;
                                                                                    width: 0;
                                                                                }

                                                                                .d-flex {
                                                                                    a.dropdown-item {
                                                                                        display: inline-block;
                                                                                        height: 35px;
                                                                                        margin: 0 3px;
                                                                                        padding: 0;
                                                                                        width: 35px;

                                                                                        .tooltipMain {
                                                                                            .filterImg {
                                                                                                border-radius: 50%;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    .thridBox {
                                                                        .AmImgConDrop {
                                                                            .dk-suMMSrchDropDwnCon {
                                                                                .dk-suMMSrchDropDwn {
                                                                                    a {
                                                                                        img {
                                                                                            border: 2px dotted #999;
                                                                                            border-radius: 50%;
                                                                                            height: 37px;
                                                                                            width: 37px;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                .dk-suMMSrchDropDwnMenu {
                                                                                    border-radius: 0;
                                                                                    padding: 0;

                                                                                    input {
                                                                                        border-bottom: 1px solid #ccc;
                                                                                    }

                                                                                    ul {
                                                                                        padding: 0;
                                                                                        max-height: 220px;
                                                                                        overflow-y: auto;

                                                                                        .dk-assignRecruitersCon {
                                                                                            p {
                                                                                                padding: 0.25rem 1rem;
                                                                                                margin-bottom: 0;
                                                                                                font-size: 0.95rem;
                                                                                            }

                                                                                            .dk-reclistItem {
                                                                                                padding: 0.25rem 1rem;

                                                                                                &.active {
                                                                                                    .dk-assignRecCon {
                                                                                                        span {
                                                                                                            color: #fff;
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .dk-assignRecCon {
                                                                                                    display: flex;
                                                                                                    position: relative;
                                                                                                    align-items: center;

                                                                                                    img.recruiterimg {
                                                                                                        width: 35px;
                                                                                                        height: 35px;
                                                                                                        border-radius: 50%;
                                                                                                        border: 2px dotted #ddd;
                                                                                                        margin-right: 10px;
                                                                                                    }

                                                                                                    span {
                                                                                                        font-size: 0.85rem;
                                                                                                        font-weight: bold;
                                                                                                        color: #333;
                                                                                                    }

                                                                                                    .crossIcon {
                                                                                                        position: absolute;
                                                                                                        right: 10px;

                                                                                                        i {
                                                                                                            font-size: 16px;
                                                                                                            color: #333;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        .dk-remaingRecruiters {
                                                                                            p {
                                                                                                padding: 0.25rem 1rem;
                                                                                                margin-bottom: 0;
                                                                                                font-size: 0.95rem;
                                                                                            }

                                                                                            .dk-reclistItem {
                                                                                                padding: 0.25rem 1rem;

                                                                                                &.active {
                                                                                                    .dk-unassignRecCon {
                                                                                                        span {
                                                                                                            color: #fff;
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .dk-unassignRecCon {
                                                                                                    img.recruiterimg {
                                                                                                        width: 35px;
                                                                                                        height: 35px;
                                                                                                        border-radius: 50%;
                                                                                                        border: 2px dotted #ddd;
                                                                                                        margin-right: 10px;
                                                                                                    }

                                                                                                    span {
                                                                                                        font-size: 0.85rem;
                                                                                                        opacity: .8;
                                                                                                        font-weight: bold;
                                                                                                        color: #212529;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                            }
                                                        }
                                                        .interviewsTable {
                                                            padding-top: 0.51rem;

                                                            .dkg-livejobs-adsboxRow {
                                                                .dkg-livejobs-adsboxCol {
                                                                    .dkg-livejobs-adsboxRow-234 {
                                                                        .dkg-livejobs-adsboxleftSide {
                                                                            background-color: #244158;
                                                                            flex: 0 0 14.666667%;
                                                                            padding-left: 0;
                                                                            padding-right: 0;
                                                                            margin-top: 14px;
                                                                            height: calc(100vh - 250px);

                                                                            .dkg-livejobs-adsbox-navpills {
                                                                                display: block !important;
                                                                                width: 100%;
                                                                                background-color: #fff;

                                                                                .nav-item {
                                                                                    max-width: inherit;

                                                                                    .nav-link {
                                                                                        background-color: #244158 !important;
                                                                                        border-bottom: 1px solid #3e5b73 !important;
                                                                                        color: #fff;
                                                                                        text-align: left !important;

                                                                                        &.active {
                                                                                            background-color: #eaeaea !important;
                                                                                            color: #333;
                                                                                        }
                                                                                    }

                                                                                    &:first-child {
                                                                                        border-top: none;
                                                                                        .nav-link{
                                                                                            &.active{
                                                                                                border-top: 1px solid #ddd !important;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        .dkg-livejobs-adsboxRightSide {
                                                                            flex: 0 0 83.333333%;
                                                                            max-width: 83.333333%;
                                                                            margin-left: 2%;
                                                                            .tab-content{
                                                                                color: inherit;
                                                                                .dkg-taxcalculator-tabpane{
                                                                                    .dkg-salary-tab-box{
                                                                                        margin-top: 14px;
                                                                                        .dkg-salary-tab-header{
                                                                                            background: #dddddd;
                                                                                            border: 1px solid #cecece;
                                                                                            border-bottom: none;
                                                                                            width: 100%;
                                                                                            padding: 5px;
                                                                                            height: 40px;
                                                                                            border-radius: 5px 5px 0 0;
                                                                                            position: relative;
                                                                                            .dkg-salary-header-title{
                                                                                                text-align: center;
                                                                                                color: #3e3e3e;
                                                                                                font-size: 20px;
                                                                                                font-weight: normal;
                                                                                            }
                                                                                        }
                                                                                        .dkg-salary-tabbody{
                                                                                            background-color: #ddd;
                                                                                            border: 1px solid #cecece;
                                                                                            border-radius: 0 0 5px 5px;
                                                                                            display: flex;
                                                                                            align-items: center;
                                                                                            justify-content: center;
                                                                                            height: 200px;
                                                                                            margin-top: 100px;
                                                                                            .dkg-income-inputgrp{
                                                                                                width: 100%;
                                                                                                max-width: 95%;
                                                                                                margin: 0 auto;
                                                                                                display: flex;
                                                                                                align-items: center;
                                                                                                .dkg-input-url{
                                                                                                    margin: 0;
                                                                                                    border: 1px solid #ddd;
                                                                                                    padding: 8px 15px;
                                                                                                    height: 38px;
                                                                                                    text-align: left;
                                                                                                }
                                                                                                .dkg-open-url-btn{
                                                                                                    background: #26597d;
                                                                                                    border: none;
                                                                                                    color: #fff;
                                                                                                    border-radius: 0;
                                                                                                    cursor: pointer;
                                                                                                    display: inline-block;
                                                                                                    font-size: 14px;
                                                                                                    font-weight: 500;
                                                                                                    min-width: 80px;
                                                                                                    outline: none;
                                                                                                    padding: 8px 15px;
                                                                                                    text-transform: uppercase;
                                                                                                    white-space: nowrap;
                                                                                                    height: 38px;
                                                                                                    margin: 0;
                                                                                                    &:hover{
                                                                                                        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                        }
                                                                                        .dkg-salary-tabbody {
                                                                                            -ms-overflow-style: none;  /* Internet Explorer 10+ */
                                                                                            scrollbar-width: none;  /* Firefox */
                                                                                        }
                                                                                        .dkg-salary-tabbody::-webkit-scrollbar { 
                                                                                            display: none;  /* Safari and Chrome */
                                                                                        }
                                                                                    }
                                                                                }
                                                                                .tab-pane{
                                                                                    .dk-ClentViewTabPane{
                                                                                        .dkg-interviewnotes-editor12 {
                                                                                            margin-top: 1rem;

                                                                                            .dkg-jobtitleInput {

                                                                                                &:focus,
                                                                                                &:focus-visible {
                                                                                                    box-shadow: none;
                                                                                                    outline: 0;
                                                                                                }
                                                                                            }

                                                                                            .wrapper-class {
                                                                                                padding: 0 !important;
                                                                                                border: none !important;

                                                                                                .rdw-editor-toolbar.toolbar-class {
                                                                                                    margin-bottom: 0;

                                                                                                    .rdw-inline-wrapper {
                                                                                                        .rdw-option-wrapper {

                                                                                                            &:nth-child(05),
                                                                                                            &:nth-child(06),
                                                                                                            &:nth-child(07) {
                                                                                                                display: none;
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    .rdw-option-wrapper.rdw-option-disabled {
                                                                                                        display: none;
                                                                                                    }
                                                                                                }

                                                                                                .editor-class.rdw-editor-main {
                                                                                                    min-height: inherit !important;
                                                                                                    max-height: inherit !important;
                                                                                                    background-color: #fff;
                                                                                                    height: calc(100vh - 291px);
                                                                                                    padding: 15px;
                                                                                                    .DraftEditor-root{
                                                                                                        .DraftEditor-editorContainer{
                                                                                                            .public-DraftEditor-content{
                                                                                                                div{
                                                                                                                    div{
                                                                                                                        .public-DraftStyleDefault-block{
                                                                                                                            span{
                                                                                                                                background-color: transparent !important;
                                                                                                                                color: inherit !important;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        .dkg-cost-explain-Con{
                                                                                            padding-top: 15px;
                                                                                            .dkg-cost-explain-header{
                                                                                                background: #ddd;
                                                                                                border: 1px solid #cecece;
                                                                                                border-radius: 5px 5px 0 0;
                                                                                                height: 40px;
                                                                                                padding: 5px;
                                                                                                position: relative;
                                                                                                width: 100%;
                                                                                                .dkg-cost-explain-title{
                                                                                                    color: #3e3e3e;
                                                                                                    font-size: 20px;
                                                                                                    font-weight: 400;
                                                                                                    text-align: center;
                                                                                                }
                                                                                            }
                                                                                            .dkg-cost-explain-body{
                                                                                                .dkg-cost-explain-textArea{
                                                                                                    height: calc(100vh - 290px);
                                                                                                    border-top: none;
                                                                                                    border: 1px solid #cecece;
                                                                                                    border-radius: 0 0 5px 5px;
                                                                                                    padding: 5px 10px;
                                                                                                    &:focus{
                                                                                                        outline: 0;
                                                                                                        box-shadow: none;
                                                                                                    }
                                                                                                }
                                                                                                .dkg-open-url-btnCon{
                                                                                                    display: flex;
                                                                                                    justify-content: center;
                                                                                                    align-items: center;
                                                                                                    width: 100%;
                                                                                                    .dkg-open-url-btn{
                                                                                                       background-color: #234158;
                                                                                                        border: 1px solid #234158;
                                                                                                        border-radius: 0.1875rem;
                                                                                                        color: #fff;
                                                                                                        display: inline-block;
                                                                                                        font-size: 13px;
                                                                                                        line-height: 1.5385;
                                                                                                        padding: 5px 12px;
                                                                                                        text-align: center;
                                                                                                        text-transform: uppercase;
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                        }
                                                                                        .dkg-savebtnCon {
                                                                                            display: flex;
                                                                                            justify-content: center;
                                                                                            align-items: center;
                                                                                            width: 100%;
                                                                                            margin-top: 20px;

                                                                                            div {

                                                                                                div {
                                                                                                    .dkg-create-payment-taskbtn {
                                                                                                        background: #234158;
                                                                                                        border: 1px solid #234158;
                                                                                                        border-radius: 0.1875rem;
                                                                                                        color: #fff;
                                                                                                        display: inline-block;
                                                                                                        font-size: 13px;
                                                                                                        line-height: 1.5385;
                                                                                                        margin-left: 120px;
                                                                                                        padding: 5px 12px;
                                                                                                        text-align: center;
                                                                                                        text-transform: uppercase;

                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                    }
                                                                                    .summaryTabPane {

                                                                                        .dk-suMMLeftCon {
                                                                                            // background: #eaeaea;
                                                                                            background-color: #eaeaea;
                                                                                            border: 1px solid #cecece;
                                                                                            border-radius: 5px;
                                                                                            padding: 15px;
                                                                                            margin: 0;
                                                                                            height: calc(100vh - 121px);
                                                                                            overflow-y: auto;
                                                                                            .dkg-company-rigt-dessc{
                                                                                                background-color: #fff;
                                                                                                padding: 10px 15px;
                                                                                                border-radius: 5px;
                                                                                                height: 150px;
                                                                                                overflow-y: auto;
                                                                                            }
                                                                                            &.dkg-editcom-summbG {
                                                                                                background-color: #eaeaea;
                                                                                            }

                                                                                            .dk-topinputGroup {
                                                                                                .input-group {
                                                                                                    .input-group-text {
                                                                                                        background: #ddd;
                                                                                                        border: 1px solid #cecece;
                                                                                                        border-right: 0px;
                                                                                                        border-radius: 0px;
                                                                                                        padding: 0.775rem 1.2rem;
                                                                                                        font-size: .95rem;
                                                                                                        min-width: 140px;
                                                                                                    }

                                                                                                    input {
                                                                                                        padding: 0.775rem 1.2rem;
                                                                                                        border-radius: 0;
                                                                                                        height: 44px;
                                                                                                        font-size: 1.12rem;
                                                                                                        color: #333;

                                                                                                        &:focus {
                                                                                                            outline: 0;
                                                                                                            box-shadow: none;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            .dk-toprightGroup {
                                                                                                display: flex;

                                                                                                .firstBox {
                                                                                                    display: flex;
                                                                                                    margin-top: 3px;

                                                                                                    span {
                                                                                                        margin-top: 8px;
                                                                                                        margin-right: 8px;
                                                                                                    }

                                                                                                    .dk-AmDropDwnCon {
                                                                                                        color: #333;

                                                                                                        .dk-AmDropDwn.dropdown {
                                                                                                            .dk-AmDropDwnToggle {
                                                                                                                img {
                                                                                                                    width: 37px;
                                                                                                                    height: 37px;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .secondBox {
                                                                                                    display: flex;
                                                                                                    justify-content: center;
                                                                                                    align-items: center;
                                                                                                    height: 37px;
                                                                                                    width: 37px;
                                                                                                    border: 1px solid #cecece;
                                                                                                    border-radius: 50%;
                                                                                                    background: #2685a5;
                                                                                                    margin-top: 2px;
                                                                                                    position: relative;
                                                                                                    margin-left: 4px;

                                                                                                    span.counterNumber {
                                                                                                        color: #fff;

                                                                                                    }
                                                                                                }

                                                                                                .thridBox {
                                                                                                    padding-left: 0.64rem;

                                                                                                    .AmImgConDrop {
                                                                                                        .dk-suMMSrchDropDwnCon {
                                                                                                            .dk-suMMSrchDropDwn {
                                                                                                                a {
                                                                                                                    img {
                                                                                                                        width: 37px;
                                                                                                                        height: 37px;
                                                                                                                        border-radius: 50%;
                                                                                                                        border: 2px dotted #999;
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            .dk-suMMSrchDropDwnMenu {
                                                                                                                border-radius: 0;
                                                                                                                padding: 0;

                                                                                                                input {
                                                                                                                    border-bottom: 1px solid #ccc;
                                                                                                                }

                                                                                                                ul {
                                                                                                                    padding: 0;
                                                                                                                    max-height: 220px;
                                                                                                                    overflow-y: auto;

                                                                                                                    .dk-assignRecruitersCon {
                                                                                                                        p {
                                                                                                                            padding: 0.25rem 1rem;
                                                                                                                            margin-bottom: 0;
                                                                                                                            font-size: 0.95rem;
                                                                                                                        }

                                                                                                                        .dk-reclistItem {
                                                                                                                            padding: 0.25rem 1rem;

                                                                                                                            &.active {
                                                                                                                                .dk-assignRecCon {
                                                                                                                                    span {
                                                                                                                                        color: #fff;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }

                                                                                                                            .dk-assignRecCon {
                                                                                                                                display: flex;
                                                                                                                                position: relative;
                                                                                                                                align-items: center;

                                                                                                                                img.recruiterimg {
                                                                                                                                    width: 35px;
                                                                                                                                    height: 35px;
                                                                                                                                    border-radius: 50%;
                                                                                                                                    border: 2px dotted #ddd;
                                                                                                                                    margin-right: 10px;
                                                                                                                                }

                                                                                                                                span {
                                                                                                                                    font-size: 0.85rem;
                                                                                                                                    font-weight: bold;
                                                                                                                                    color: #333;
                                                                                                                                }

                                                                                                                                .crossIcon {
                                                                                                                                    position: absolute;
                                                                                                                                    right: 10px;

                                                                                                                                    i {
                                                                                                                                        font-size: 16px;
                                                                                                                                        color: #333;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    .dk-remaingRecruiters {
                                                                                                                        p {
                                                                                                                            padding: 0.25rem 1rem;
                                                                                                                            margin-bottom: 0;
                                                                                                                            font-size: 0.95rem;
                                                                                                                        }

                                                                                                                        .dk-reclistItem {
                                                                                                                            padding: 0.25rem 1rem;

                                                                                                                            &.active {
                                                                                                                                .dk-unassignRecCon {
                                                                                                                                    span {
                                                                                                                                        color: #fff;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }

                                                                                                                            .dk-unassignRecCon {
                                                                                                                                img.recruiterimg {
                                                                                                                                    width: 35px;
                                                                                                                                    height: 35px;
                                                                                                                                    border-radius: 50%;
                                                                                                                                    border: 2px dotted #ddd;
                                                                                                                                    margin-right: 10px;
                                                                                                                                }

                                                                                                                                span {
                                                                                                                                    font-size: 0.85rem;
                                                                                                                                    opacity: .8;
                                                                                                                                    font-weight: bold;
                                                                                                                                    color: #212529;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            .dk-suMMformGroupCon {
                                                                                                display: flex;
                                                                                                width: 100%;

                                                                                                label {
                                                                                                    align-items: center;
                                                                                                    min-width: 90px;
                                                                                                    font-size: 0.90rem;
                                                                                                    white-space: nowrap;
                                                                                                }

                                                                                                .dk-statusDrpDwnCon {
                                                                                                    width: 100%;
                                                                                                    display: flex;

                                                                                                    .dk-simpleDrpDwnCon {
                                                                                                        .dk-simpleDrpDwn {
                                                                                                            .dk-simpleDrpDwnToggle {
                                                                                                                background-color: #fff;
                                                                                                                border: 1px solid #ddd;
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    .dk-statusDrpDwn {
                                                                                                        width: 100%;

                                                                                                        .dk-statusDrpDwnToggle {
                                                                                                            box-shadow: none;
                                                                                                            display: flex;
                                                                                                            width: 100%;
                                                                                                            margin: 0;
                                                                                                            padding: 0.35rem 0.75rem;
                                                                                                            justify-content: center;
                                                                                                            align-items: center;
                                                                                                            border-radius: 0;
                                                                                                            text-transform: capitalize;
                                                                                                            font-size: 0.95rem;

                                                                                                            &::after {
                                                                                                                display: none;
                                                                                                            }
                                                                                                        }

                                                                                                        .dk-statusDrpDwnMenu {
                                                                                                            border-radius: 0;
                                                                                                            padding: 0;
                                                                                                            width: 100%;

                                                                                                            .dropdown-item {
                                                                                                                padding: 0.35rem 0.75rem;
                                                                                                                font-size: 0.9rem;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .dk-simpleDrpDwnCon {
                                                                                                    width: 100%;

                                                                                                    .dk-simpleDrpDwn {
                                                                                                        .dk-simpleDrpDwnToggle {
                                                                                                            color: #333;
                                                                                                            box-shadow: none;
                                                                                                            white-space: nowrap;
                                                                                                            margin: 0;
                                                                                                            background-color: #fff;
                                                                                                            border: 1px solid #ccc;
                                                                                                            padding: 0.40rem 0.75rem;
                                                                                                            text-transform: capitalize;
                                                                                                            display: flex;
                                                                                                            width: 100%;
                                                                                                            justify-content: center;
                                                                                                            font-size: 0.90rem;
                                                                                                            position: relative;

                                                                                                            &::after {
                                                                                                                position: absolute;
                                                                                                                right: 5px;
                                                                                                                top: 13px;
                                                                                                            }
                                                                                                        }

                                                                                                        .dk-simpleDrpDwnMenu {
                                                                                                            border-radius: 0;
                                                                                                            max-height: 180px;
                                                                                                            width: 100%;
                                                                                                            overflow-y: auto;
                                                                                                            padding: 0;
                                                                                                            border-color: #96c8da;
                                                                                                            box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                            .dropdown-item {
                                                                                                                padding: 0.35rem 0.75rem;
                                                                                                                font-size: 0.9rem;
                                                                                                            }

                                                                                                            input {
                                                                                                                border-bottom: 1px solid #ccc;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                // dk-statusDrpDwnCon
                                                                                                input {
                                                                                                    display: flex;
                                                                                                    width: 100%;
                                                                                                    margin: 0;
                                                                                                    padding: 0.35rem 0.75rem;
                                                                                                    justify-content: center;
                                                                                                    align-items: center;
                                                                                                    border-radius: 0;
                                                                                                    text-transform: capitalize;
                                                                                                    font-size: 0.95rem;
                                                                                                    text-align: center;

                                                                                                    &:focus {
                                                                                                        outline: 0;
                                                                                                        box-shadow: none;
                                                                                                    }
                                                                                                }

                                                                                                .input-group {
                                                                                                    input {
                                                                                                        border-right: 0;
                                                                                                    }

                                                                                                    .input-group-prepend {
                                                                                                        background-color: #fff;

                                                                                                        .input-group-text {
                                                                                                            background-color: #fff;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            .dkg-editcompany-textArea-Con {
                                                                                                .dkg-title {
                                                                                                    background: #dddddd;
                                                                                                    border: 1px solid #cecece;
                                                                                                    border-bottom: none;
                                                                                                    width: 100%;
                                                                                                    padding: 5px;
                                                                                                    height: 40px;
                                                                                                    border-radius: 5px 5px 0 0;

                                                                                                    h3 {
                                                                                                        text-align: center;
                                                                                                        color: #3e3e3e;
                                                                                                        font-size: 20px;
                                                                                                        font-weight: normal;
                                                                                                        margin-bottom: 0;
                                                                                                        width: 100%;
                                                                                                    }
                                                                                                }

                                                                                                .dkg-editcompany-textArea {
                                                                                                    border-radius: 0 0 5px;
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        .dk-jobsuMMBottomSec {
                                                                                            color: #3e3e3e;

                                                                                            .dk-jobsuMMBottomBoxBg {
                                                                                                color: #333;

                                                                                                .dkg-salary-curr-dropdown-toggle {
                                                                                                    color: red;

                                                                                                    .dk-simpleDrpDwnCon {
                                                                                                        .dk-simpleDrpDwn {
                                                                                                            .dk-simpleDrpDwnToggle {
                                                                                                                min-width: 90px;
                                                                                                                height: 39px;
                                                                                                                border: 1px solid #ddd;
                                                                                                                line-height: 24px;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .dk-suMMformGroupCon {
                                                                                                    min-height: 32px;
                                                                                                }

                                                                                                .dk-BoxHeader {
                                                                                                    background: #dddddd;
                                                                                                    border: 1px solid #cecece;
                                                                                                    border-bottom: none;
                                                                                                    width: 100%;
                                                                                                    padding: 5px;
                                                                                                    height: 40px;
                                                                                                    border-radius: 5px 5px 0 0;

                                                                                                    h3 {
                                                                                                        text-align: center;
                                                                                                        color: #3e3e3e;
                                                                                                        font-size: 20px;
                                                                                                        font-weight: normal;
                                                                                                        position: relative;
                                                                                                        .dkg-pophover-icon{
                                                                                                            display: flex;
                                                                                                            align-items: center;
                                                                                                            justify-content: center;
                                                                                                            width: 18px;
                                                                                                            height: 18px;
                                                                                                            background-color: #eaeaea;
                                                                                                            position: absolute;
                                                                                                            top: 3px;
                                                                                                            right: 65px;
                                                                                                            border-radius: 50%;
                                                                                                            border: 1px solid #ddd;
                                                                                                            cursor: pointer;
                                                                                                            svg{
                                                                                                                width: 14px;
                                                                                                                height: 14px;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .dk-boxBody {
                                                                                                    background: #eaeaea;
                                                                                                    border: 1px solid #cecece;
                                                                                                    border-radius: 0 0 5px 5px;
                                                                                                    padding: 15px;
                                                                                                    &.dkg-livejobs-boxbody{
                                                                                                        padding-top: 15px;
                                                                                                    }
                                                                                                    .dk-suMMformGroupCon {
                                                                                                        display: flex;
                                                                                                        width: 100%;
                                                                                                        margin-bottom: 0.3rem;

                                                                                                        .label-hover:hover {
                                                                                                            color: blue;
                                                                                                        }

                                                                                                        input {
                                                                                                            border-bottom: 1px solid #ccc;
                                                                                                        }

                                                                                                        label {
                                                                                                            align-items: center;
                                                                                                            font-size: 0.90rem;
                                                                                                            white-space: nowrap;
                                                                                                            padding-right: 0.5rem;
                                                                                                            min-width: 110px !important;
                                                                                                            &.dkg-detil-rightlabel{
                                                                                                                min-width: 135px !important;
                                                                                                            }
                                                                                                            &.dkg-gross-slary-label{
                                                                                                                cursor: pointer;
                                                                                                            }
                                                                                                            svg{
                                                                                                                width: 15px;
                                                                                                                height: 15px;
                                                                                                            }

                                                                                                        }

                                                                                                        label.dk-benfitslabel {
                                                                                                            min-width: 20px;
                                                                                                            max-width: 20px;
                                                                                                        }

                                                                                                        label.relocationlabel {
                                                                                                            min-width: 147px !important;
                                                                                                        }

                                                                                                        .dk-simpleDrpDwnCon {
                                                                                                            width: 100%;

                                                                                                            .dk-simpleDrpDwn {
                                                                                                                .dk-simpleDrpDwnToggle {
                                                                                                                    color: #333;
                                                                                                                    box-shadow: none;
                                                                                                                    white-space: nowrap;
                                                                                                                    margin: 0;
                                                                                                                    background-color: #fff;
                                                                                                                    border: 1px solid #ccc;
                                                                                                                    padding: 0.35rem 0.75rem;
                                                                                                                    text-transform: capitalize;
                                                                                                                    display: flex;
                                                                                                                    width: 100%;
                                                                                                                    justify-content: center;
                                                                                                                    font-size: 0.90rem;
                                                                                                                    position: relative;

                                                                                                                    &::after {
                                                                                                                        position: absolute;
                                                                                                                        right: 5px;
                                                                                                                        top: 13px;
                                                                                                                    }
                                                                                                                }

                                                                                                                .dk-simpleDrpDwnMenu {
                                                                                                                    border-radius: 0;
                                                                                                                    max-height: 180px;
                                                                                                                    width: 100%;
                                                                                                                    overflow-y: auto;
                                                                                                                    padding: 0;
                                                                                                                    border-color: #96c8da;
                                                                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                    .dropdown-item {
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        font-size: 0.9rem;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        .dkg-salary-curr-dropdown-toggle {
                                                                                                            .dk-simpleDrpDwn {
                                                                                                                .dk-simpleDrpDwnToggle {
                                                                                                                    color: #333;
                                                                                                                    box-shadow: none;
                                                                                                                    white-space: nowrap;
                                                                                                                    margin: 0;
                                                                                                                    background-color: #fff;
                                                                                                                    border: 1px solid #ccc;
                                                                                                                    padding: 0.35rem 0.75rem;
                                                                                                                    text-transform: capitalize;
                                                                                                                    display: flex;
                                                                                                                    width: 100%;
                                                                                                                    justify-content: center;
                                                                                                                    font-size: 0.90rem;
                                                                                                                    position: relative;

                                                                                                                    &::after {
                                                                                                                        position: absolute;
                                                                                                                        right: 5px;
                                                                                                                        top: 17px;
                                                                                                                    }
                                                                                                                }

                                                                                                                .dk-simpleDrpDwnMenu {
                                                                                                                    border-radius: 0;
                                                                                                                    max-height: 180px;
                                                                                                                    width: 100%;
                                                                                                                    overflow-y: auto;
                                                                                                                    padding: 0;
                                                                                                                    border-color: #96c8da;
                                                                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                    .dropdown-item {
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        font-size: 0.9rem;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        input {
                                                                                                            display: flex;
                                                                                                            width: 100%;
                                                                                                            margin: 0;
                                                                                                            padding: 0.35rem 0.75rem;
                                                                                                            justify-content: center;
                                                                                                            align-items: center;
                                                                                                            border-radius: 0;
                                                                                                            text-transform: capitalize;
                                                                                                            font-size: 0.95rem;
                                                                                                            text-align: center;

                                                                                                            &:focus {
                                                                                                                outline: 0;
                                                                                                                box-shadow: none;
                                                                                                            }
                                                                                                        }

                                                                                                        input.dk-benfitsInput {
                                                                                                            max-height: 30px;
                                                                                                        }

                                                                                                        .input-group {
                                                                                                            input {
                                                                                                                border-right: 0;
                                                                                                            }

                                                                                                            .input-group-prepend {
                                                                                                                background-color: #fff;

                                                                                                                .input-group-text {
                                                                                                                    background-color: #fff;
                                                                                                                }
                                                                                                            }

                                                                                                            .dkg-salary-curr-dropdown {
                                                                                                                .dkg-salary-curr-dropdown-toggle {
                                                                                                                    margin: 0;
                                                                                                                    box-shadow: none;
                                                                                                                    border: 1px solid #ccc;
                                                                                                                    background-color: #fff;
                                                                                                                    padding: 10px 12px;
                                                                                                                    font-size: 0.95rem;

                                                                                                                    svg {
                                                                                                                        width: 13px;
                                                                                                                        height: 13px;
                                                                                                                    }
                                                                                                                }

                                                                                                                .dkg-salary-curr-dropdown-menu {
                                                                                                                    border-radius: 0;
                                                                                                                    width: 100%;
                                                                                                                    padding: 0;
                                                                                                                    margin: 0;
                                                                                                                    max-height: 230px;
                                                                                                                    overflow-y: auto;

                                                                                                                    .dkg-salary-curr-dropdown-item {
                                                                                                                        text-align: left;
                                                                                                                        padding: 7px 9px;
                                                                                                                        border-bottom: 1px solid #ccc;
                                                                                                                        font-size: 0.95rem;

                                                                                                                        svg {
                                                                                                                            width: 14px;
                                                                                                                            height: 14px;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .dk-boxKpi {
                                                                                                    background: #eaeaea;
                                                                                                    border-top: 1px solid #b9b9b9;
                                                                                                    border-radius: 0 0 5px 5px;
                                                                                                    // height: calc(100vh - 512px);
                                                                                                    // overflow-y: auto;
                                                                                                    .dk-suMMformGroupCon {
                                                                                                        display: flex;
                                                                                                        border-bottom: 1px solid #cecece;
                                                                                                        width: 100%;
                                                                                                        margin-bottom: 0rem;
                                                                                                        &:last-child{
                                                                                                            border-radius: 0 0 5px 5px;
                                                                                                        }
                                                                                                        .label-hover:hover {
                                                                                                            color: blue;
                                                                                                        }

                                                                                                        input {
                                                                                                            border-bottom: 1px solid #ccc;
                                                                                                        }

                                                                                                        label {
                                                                                                            align-items: center;
                                                                                                            min-width: 117px;
                                                                                                            font-size: 0.90rem;
                                                                                                            white-space: nowrap;
                                                                                                            padding-right: 0.5rem;
                                                                                                        }

                                                                                                        label.dk-benfitslabel {
                                                                                                            min-width: 20px;
                                                                                                            max-width: 20px;
                                                                                                        }

                                                                                                        label.relocationlabel {
                                                                                                            min-width: 125px;
                                                                                                        }

                                                                                                        .dk-simpleDrpDwnCon {
                                                                                                            width: 100%;

                                                                                                            .dk-simpleDrpDwn {
                                                                                                                .dk-simpleDrpDwnToggle {
                                                                                                                    color: #333;
                                                                                                                    box-shadow: none;
                                                                                                                    white-space: nowrap;
                                                                                                                    margin: 0;
                                                                                                                    background-color: #fff;
                                                                                                                    border: 1px solid #ccc;
                                                                                                                    padding: 0.35rem 0.75rem;
                                                                                                                    text-transform: capitalize;
                                                                                                                    display: flex;
                                                                                                                    width: 100%;
                                                                                                                    justify-content: center;
                                                                                                                    font-size: 0.90rem;
                                                                                                                    position: relative;

                                                                                                                    &::after {
                                                                                                                        position: absolute;
                                                                                                                        right: 5px;
                                                                                                                        top: 13px;
                                                                                                                    }
                                                                                                                }

                                                                                                                .dk-simpleDrpDwnMenu {
                                                                                                                    border-radius: 0;
                                                                                                                    max-height: 180px;
                                                                                                                    width: 100%;
                                                                                                                    overflow-y: auto;
                                                                                                                    padding: 0;
                                                                                                                    border-color: #96c8da;
                                                                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                    .dropdown-item {
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        font-size: 0.9rem;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        input {
                                                                                                            display: flex;
                                                                                                            width: 100%;
                                                                                                            margin: 0;
                                                                                                            padding: 0.35rem 0.75rem;
                                                                                                            justify-content: center;
                                                                                                            align-items: center;
                                                                                                            border-radius: 0;
                                                                                                            text-transform: capitalize;
                                                                                                            font-size: 0.95rem;
                                                                                                            text-align: center;

                                                                                                            &:focus {
                                                                                                                outline: 0;
                                                                                                                box-shadow: none;
                                                                                                            }
                                                                                                        }

                                                                                                        input.dk-benfitsInput {
                                                                                                            max-height: 30px;
                                                                                                        }

                                                                                                        .input-group {
                                                                                                            input {
                                                                                                                border-right: 0;
                                                                                                            }

                                                                                                            .input-group-prepend {
                                                                                                                background-color: #fff;

                                                                                                                .input-group-text {
                                                                                                                    background-color: #fff;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            .dkg-editcompany-headerCon {
                                                                                                display: flex;
                                                                                                align-items: center;
                                                                                                justify-content: center;
                                                                                                border-width: 1px 1px 0px;
                                                                                                border-top-style: solid;
                                                                                                border-right-style: solid;
                                                                                                border-bottom-style: initial;
                                                                                                border-left-style: solid;
                                                                                                border-top-color: rgb(204, 204, 204);
                                                                                                border-right-color: rgb(204, 204, 204);
                                                                                                border-bottom-color: initial;
                                                                                                border-left-color: rgb(204, 204, 204);
                                                                                                border-image: initial;
                                                                                                padding: 11px 8px;
                                                                                                position: relative;
                                                                                                background-color: #eaeaea;

                                                                                                .dkg-editcompany-leftCon {
                                                                                                    position: absolute;
                                                                                                    left: 10px;
                                                                                                    top: 6px;

                                                                                                    .dk-simpleDrpDwnCon {
                                                                                                        width: 100%;

                                                                                                        .dk-simpleDrpDwn {
                                                                                                            .dk-simpleDrpDwnToggle {
                                                                                                                color: #333;
                                                                                                                box-shadow: none;
                                                                                                                white-space: nowrap;
                                                                                                                margin: 0;
                                                                                                                background-color: #eaeaea;
                                                                                                                border: 1px solid #ccc;
                                                                                                                padding: 0.40rem 0.75rem;
                                                                                                                text-transform: capitalize;
                                                                                                                display: flex;
                                                                                                                width: 100%;
                                                                                                                justify-content: flex-start;
                                                                                                                font-size: 0.90rem;
                                                                                                                position: relative;
                                                                                                                min-width: 180px;

                                                                                                                &::after {
                                                                                                                    position: absolute;
                                                                                                                    right: 5px;
                                                                                                                    top: 13px;
                                                                                                                }
                                                                                                            }

                                                                                                            .dk-simpleDrpDwnMenu {
                                                                                                                border-radius: 0;
                                                                                                                max-height: 180px;
                                                                                                                width: 100%;
                                                                                                                overflow-y: auto;
                                                                                                                padding: 0;
                                                                                                                border-color: #96c8da;
                                                                                                                box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                .dropdown-item {
                                                                                                                    padding: 0.35rem 0.75rem;
                                                                                                                    font-size: 0.9rem;
                                                                                                                }

                                                                                                                input {
                                                                                                                    border-bottom: 1px solid #ccc;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                }
                                                                                            }

                                                                                            .dkg-edit-jobfiles-mainCon {
                                                                                                .dkg-add-new-btnCon {
                                                                                                    margin-bottom: 5px;

                                                                                                    .dkg-addnew-btn {
                                                                                                        box-shadow: none;
                                                                                                        border: 1px solid #ccc;
                                                                                                        background-color: #fff;
                                                                                                        color: #333;
                                                                                                        font-size: 13px;
                                                                                                        text-transform: capitalize;
                                                                                                        padding: 7px 9px;
                                                                                                        // min-width: 110px;
                                                                                                        margin: 0;
                                                                                                    }
                                                                                                }

                                                                                                .dkg-edit-jobfiles-tableCon {
                                                                                                    .dkg-edit-jobfiles-table {
                                                                                                        thead {
                                                                                                            tr {
                                                                                                                th {
                                                                                                                    background: #485569;
                                                                                                                    border: 1px solid gray;
                                                                                                                    border-bottom: none;
                                                                                                                    color: #fff;
                                                                                                                    font-size: 13px;
                                                                                                                    padding: 9px 7px;
                                                                                                                    position: -webkit-sticky;
                                                                                                                    position: sticky;
                                                                                                                    text-align: center;
                                                                                                                    top: 0;
                                                                                                                    vertical-align: middle;
                                                                                                                    white-space: nowrap;
                                                                                                                    z-index: 9;
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        tbody {

                                                                                                            tr {
                                                                                                                td {
                                                                                                                    background: #fff;
                                                                                                                    border: 1px solid #ddd;
                                                                                                                    color: #4d4a4a;
                                                                                                                    font-size: 13px;
                                                                                                                    font-weight: 400;
                                                                                                                    padding: 9px 7px;
                                                                                                                    position: relative;
                                                                                                                    text-align: center;
                                                                                                                    text-transform: capitalize;
                                                                                                                    vertical-align: middle;
                                                                                                                    white-space: nowrap;

                                                                                                                    .dkg-viewfile-iconCon {
                                                                                                                        display: flex;
                                                                                                                        justify-content: center;
                                                                                                                        align-items: center;

                                                                                                                        .dkg-viewfile-icon {
                                                                                                                            border-radius: 3px;
                                                                                                                            font-size: 14px;
                                                                                                                            height: 30px !important;
                                                                                                                            line-height: 30px;
                                                                                                                            width: 30px !important;
                                                                                                                            background-color: #2196f3;
                                                                                                                            color: #fff;
                                                                                                                        }
                                                                                                                    }

                                                                                                                    .dkg-file-actions {
                                                                                                                        display: flex;
                                                                                                                        justify-content: center;
                                                                                                                        align-items: center;

                                                                                                                        .dkg-viewfile-icon {
                                                                                                                            border-radius: 3px;
                                                                                                                            font-size: 14px;
                                                                                                                            height: 30px !important;
                                                                                                                            line-height: 30px;
                                                                                                                            width: 30px !important;
                                                                                                                            background-color: #2196f3;
                                                                                                                            color: #fff;
                                                                                                                        }

                                                                                                                        .dkg-download-icon {
                                                                                                                            border-radius: 3px;
                                                                                                                            font-size: 14px;
                                                                                                                            height: 30px !important;
                                                                                                                            line-height: 30px;
                                                                                                                            width: 30px !important;
                                                                                                                            background-color: #31a886;
                                                                                                                            color: #fff;
                                                                                                                        }

                                                                                                                        .dkg-editfile-icon {
                                                                                                                            border-radius: 3px;
                                                                                                                            font-size: 14px;
                                                                                                                            height: 30px;
                                                                                                                            line-height: 30px;
                                                                                                                            width: 30px;
                                                                                                                            background-color: #fee69c;
                                                                                                                            color: #333;
                                                                                                                        }

                                                                                                                        .dkg-deletefile-icon {
                                                                                                                            border-radius: 3px;
                                                                                                                            font-size: 14px;
                                                                                                                            height: 30px;
                                                                                                                            line-height: 30px;
                                                                                                                            width: 30px;
                                                                                                                            background-color: #dc3545;
                                                                                                                            color: #fff;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .dk-ClieDetViewSideMenu{
                            .dkg-detailview-navpills{
                                .dkg-detailview-navitem{
                                    max-width: 7.69230% !important;
                                    &.dkg-edit-detailview-navitem{
                                        max-width: 8.333% !important;

                                    }
                                }
                            }
                        }
                    }
                }

                .mainSpecTab {
                    background-color: #eaeaea;

                    .specsLeftTab {
                        // background: #eaeaea;
                        flex: 0 0 10.666667%;
                        // max-width: 10.666667%;

                        .nav.nav-pills {
                            display: block;

                            .nav-link {
                                background: #fff;
                                color: #333;
                                border-bottom: none;
                                border-radius: 0;
                                text-align: center;
                                padding: 9px 12px;
                                text-align: left;
                            }

                            .active {
                                width: 100%;
                                border: 0;
                                background-color: #eaeaea;
                            }
                        }

                    }

                    .input-group {
                        .input-group-text {
                            border-radius: 0;
                            background-color: #F2F2F2;
                        }

                        input {
                            height: auto;
                        }
                    }

                    .col-md-10.col-sm-10 {
                        flex: 0 0 85.333333%;
                        max-width: 85.333333%;
                        .tab-content{
                            .tab-pane{
                                .dk-Adsbox {
                                    margin-top: 0;
                                    .dkg-job-staus-col {
                                        &.dkg-edit-jobstus-leftcol {
                                            flex: 0 0 38.666667%;
                                            max-width: 38.666667%;
                                        }

                                        &.dkg-edit-jobstus-rightcol {
                                            flex: 0 0 55%;
                                            max-width: 55%;
                                        }

                                        .dk-suMMformGroupCon {
                                            display: flex;
                                            align-items: center;

                                            .dk-statusDrpDwnCon {
                                                width: 100%;

                                                .dropdown {
                                                    width: 100%;
                                                    display: flex;

                                                    .dropdown-toggle,
                                                    .dk-statusDrpDwn.dropdown-toggle {
                                                        width: 100%;
                                                        box-shadow: none;
                                                        margin: 0;
                                                        padding: 9px 10px;
                                                        text-transform: capitalize;
                                                        font-size: 12px;
                                                        position: relative;
                                                        background-color: #fff;
                                                        border: 1px solid #ddd;
                                                        border-top-color: rgb(204, 204, 204);
                                                        border-right-color: rgb(204, 204, 204);
                                                        border-bottom-color: rgb(204, 204, 204);
                                                        border-left-color: rgb(204, 204, 204);
                                                        height: calc(1.5em + 0.75rem + 2px);
                                                        line-height: 12px;

                                                        &::after {
                                                            position: absolute;
                                                            right: 10px;
                                                            top: 12px;
                                                            font-size: 14px;
                                                        }
                                                    }

                                                    .dk-statusDrpDwnMenu,
                                                    .dk-simpleDrpDwnMenu {
                                                        border-radius: 0;
                                                        padding: 0;
                                                        width: 100%;

                                                        input {
                                                            border-bottom: 1px solid #ddd;
                                                            // width: 100%;
                                                            // max-width: 95%;
                                                            // margin: 12px auto;
                                                        }

                                                        .list-unstyled {
                                                            .dropdown-item {
                                                                font-size: 13px;
                                                                padding: 7px 10px;
                                                                border-bottom: 1px solid #ddd;

                                                                &:last-child {
                                                                    border-bottom: none;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-AmDropDwnCon {
                                                .dk-AmDropDwn {
                                                    .dk-AmDropDwnToggle {
                                                        background-color: transparent;
                                                        box-shadow: none;
                                                        margin: 0;
                                                        padding: 0 !important;

                                                        &::after {
                                                            display: none;
                                                        }

                                                        .tooltipMain {
                                                            .filterImg {
                                                                width: 37px;
                                                                height: 37px;
                                                                border: 2px dotted rgb(153, 153, 153);
                                                                border-radius: 50%;
                                                            }
                                                        }
                                                    }

                                                    .dk-AmDropDwnMenu {
                                                        background: #234158;
                                                        border: 1px solid #234158;
                                                        left: 22px !important;
                                                        padding: 5px;
                                                        top: -10px !important;
                                                        transform: translate(22px) !important;
                                                        -webkit-transform: translate(22px) !important;

                                                        &:after {
                                                            border-color: transparent #234158 transparent transparent;
                                                            _border-color: #000 #ccc #000 #000;
                                                            border-style: solid;
                                                            border-width: 8.5px 12px 8.5px 0;
                                                            content: "";
                                                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                                            height: 0;
                                                            left: -12px;
                                                            line-height: 0;
                                                            position: absolute;
                                                            top: 15px;
                                                            width: 0;
                                                        }

                                                        .d-flex {
                                                            a.dropdown-item {
                                                                display: inline-block;
                                                                height: 35px;
                                                                margin: 0 3px;
                                                                padding: 0;
                                                                width: 35px;

                                                                .tooltipMain {
                                                                    .filterImg {
                                                                        border-radius: 50%;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .thridBox {
                                                .AmImgConDrop {
                                                    .dk-suMMSrchDropDwnCon {
                                                        .dk-suMMSrchDropDwn {
                                                            a {
                                                                img {
                                                                    border: 2px dotted #999;
                                                                    border-radius: 50%;
                                                                    height: 37px;
                                                                    width: 37px;
                                                                }
                                                            }
                                                        }

                                                        .dk-suMMSrchDropDwnMenu {
                                                            border-radius: 0;
                                                            padding: 0;

                                                            input {
                                                                border-bottom: 1px solid #ccc;
                                                            }

                                                            ul {
                                                                padding: 0;
                                                                max-height: 220px;
                                                                overflow-y: auto;

                                                                .dk-assignRecruitersCon {
                                                                    p {
                                                                        padding: 0.25rem 1rem;
                                                                        margin-bottom: 0;
                                                                        font-size: 0.95rem;
                                                                    }

                                                                    .dk-reclistItem {
                                                                        padding: 0.25rem 1rem;

                                                                        &.active {
                                                                            .dk-assignRecCon {
                                                                                span {
                                                                                    color: #fff;
                                                                                }
                                                                            }
                                                                        }

                                                                        .dk-assignRecCon {
                                                                            display: flex;
                                                                            position: relative;
                                                                            align-items: center;

                                                                            img.recruiterimg {
                                                                                width: 35px;
                                                                                height: 35px;
                                                                                border-radius: 50%;
                                                                                border: 2px dotted #ddd;
                                                                                margin-right: 10px;
                                                                            }

                                                                            span {
                                                                                font-size: 0.85rem;
                                                                                font-weight: bold;
                                                                                color: #333;
                                                                            }

                                                                            .crossIcon {
                                                                                position: absolute;
                                                                                right: 10px;

                                                                                i {
                                                                                    font-size: 16px;
                                                                                    color: #333;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .dk-remaingRecruiters {
                                                                    p {
                                                                        padding: 0.25rem 1rem;
                                                                        margin-bottom: 0;
                                                                        font-size: 0.95rem;
                                                                    }

                                                                    .dk-reclistItem {
                                                                        padding: 0.25rem 1rem;

                                                                        &.active {
                                                                            .dk-unassignRecCon {
                                                                                span {
                                                                                    color: #fff;
                                                                                }
                                                                            }
                                                                        }

                                                                        .dk-unassignRecCon {
                                                                            img.recruiterimg {
                                                                                width: 35px;
                                                                                height: 35px;
                                                                                border-radius: 50%;
                                                                                border: 2px dotted #ddd;
                                                                                margin-right: 10px;
                                                                            }

                                                                            span {
                                                                                font-size: 0.85rem;
                                                                                opacity: .8;
                                                                                font-weight: bold;
                                                                                color: #212529;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }
                                                }
                                            }
                                        }

                                    }
                                }
                            }
                        }

                    }
                }
            }
            &.dkg-full-jobdetail-modalmody{
                .dkg-full-jobdetail-maincon{
                    .dkg-full-jobdetail-mainRow{
                        .dkg-full-jobdetail-mainCol{
                            left: 50%;
                            position: absolute;
                            top: -47px;
                            transform: translate(-50%, 9px);
                            .dkg-full-jobdetail-navpills{
                                position: relative;
                                top: 0;
                                width: 100%;
                                justify-content: center;
                                .dkg-full-jobdetail-navitem{
                                    .dkg-full-jobdetail-navlink{
                                        color: #666;
                                        border-radius: 0;
                                        background-color: transparent;
                                        &.active{
                                            color: #333;
                                        }
                                    }
                                }
                                &.dkg-full-edit-jobdetail-navpills{
                                    .dkg-full-jobdetail-navitem{
                                        .dkg-full-jobdetail-navlink{
                                            color: #ccc;
                                            background-color: transparent;
                                            &.active{
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .dkg-full-jobdetail-mainCol-12{
                            color: #20394a;
                            .dkg-full-jobdetail-tabcontent{
                                .dkg-full-jobdetail-tabpane{
                                    color: #212529;
                                    .dkg-edit-livejobs-mainRow-23 {
                                        margin: 0;
                                        .dkg-edit-livejobs-mainCol-23 {
                                            padding-left: 0;
                                            .dkg-edit-livejobs-maintabCon {
                                                .dkg-edit-livejobs-maintabs-pills {
                                                    display: flex;
                                                    flex: 0 0 100%;
                                                    .flex-row.nav.nav-pills {
                                                        background-color: #eaeaea;
                                                        margin-bottom: 0;
                                                        width: 100%;
                                                        .nav-item {
                                                            min-width: 12.5%;
                                                            max-width: 12.5%;

                                                            .nav-link {
                                                                background-color: #eaeaea;
                                                                border-radius: 0;
                                                                color: #333;
                                                                font-size: .95rem;
                                                                padding: .65rem 1rem;
                                                                text-align: center !important;
                                                                &.active {
                                                                    background-color: #fff;
                                                                    color: #244158;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .dkg-edit-livejobs-col-234{
                                                    .dkg-edit-livejobs-tabcontent{
                                                        .dkg-edit-jobdetail-tabpane{
                                                            height: calc(100vh - 80px);
                                                            .dkg-edit-jobdetail-tabpaneCon{
                                                                padding-left: 0;
                                                                .dkg-edit-livejobs-adsboxRow{
                                                                    .dkg-edit-livejobs-adsboxCol{
                                                                        .dkg-edit-livejobs-adsboxRow-234{
                                                                            .dkg-edit-livejobs-sidemenu{
                                                                                background-color: #244158;
                                                                                flex: 0 0 14.666667%;
                                                                                height: calc(100vh - 94px);
                                                                                border-bottom-left-radius: 5px;
                                                                                margin-top: 14px;
                                                                                .dkg-edit-adsbox-navpills{
                                                                                    display: flex !important;
                                                                                    flex-direction: column;
                                                                                    width: 100%;
                                                                                    .dkg-edit-adsbox-navitem{
                                                                                        width: 100%;
                                                                                        max-width: 100%;
                                                                                        .dkg-edit-adsbox-nav-link{
                                                                                            background-color: #244158;
                                                                                            border-bottom: 1px solid #3e5b73 !important;
                                                                                            color: #fff;
                                                                                            text-align: left;
                                                                                            width: 100%;
                                                                                            border-radius: 0;
                                                                                            padding: 8px;
                                                                                            &.active{
                                                                                                background-color: #eaeaea;
                                                                                                color: #333;

                                                                                            }
                                                                                        }
                                                                                        &:first-child{
                                                                                            .nav-link{
                                                                                                &.active {
                                                                                                    border-top: 1px solid #ddd !important;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            .dkg-editjob-adsboxRightSide{
                                                                                .dkg-editjob-adsbox-jobcontent{
                                                                                    .dkg-editjob-adsbox-tabpane{
                                                                                       padding: 15px;
                                                                                        .dk-ClentViewTabPane {
                                                                                            .dk-Adsbox{
                                                                                                .dk-suMMLeftCon {
                                                                                                    .dk-topinputGroup{
                                                                                                        .input-group {
                                                                                                            .input-group-text {
                                                                                                                background: #ddd;
                                                                                                                border: 1px solid #cecece;
                                                                                                                border-right: 0px;
                                                                                                                border-radius: 0px;
                                                                                                                padding: 0.775rem 1.2rem;
                                                                                                                font-size: .95rem;
                                                                                                                min-width: 140px;
                                                                                                            }

                                                                                                            input {
                                                                                                                padding: 0.775rem 1.2rem;
                                                                                                                border-radius: 0;
                                                                                                                height: 44px;
                                                                                                                font-size: 1.12rem;
                                                                                                                color: #333;
                                                                                                                border: 1px solid #ddd;
                                                                                                                &:focus {
                                                                                                                    outline: 0;
                                                                                                                    box-shadow: none;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    .dk-suMMformGroupCon{
                                                                                                        display: flex;
                                                                                                        width: 100%;
                                                                                                        label{
                                                                                                            color: #333;
                                                                                                            margin-bottom: 0 !important;
                                                                                                            padding: 7px;
                                                                                                            font-size: .9rem;
                                                                                                            min-width: 125px;
                                                                                                            white-space: nowrap;
                                                                                                            background-color: #ddd;
                                                                                                        }
                                                                                                        .dk-statusDrpDwnCon{
                                                                                                            width: 100%;
                                                                                                            .dk-simpleDrpDwn {
                                                                                                                width: 100%;
                                                                                                                .dk-simpleDrpDwnToggle {
                                                                                                                    color: #333;
                                                                                                                    box-shadow: none;
                                                                                                                    white-space: nowrap;
                                                                                                                    margin: 0;
                                                                                                                    background-color: #fff;
                                                                                                                    border: 1px solid #ccc;
                                                                                                                    padding: 0.40rem 0.75rem;
                                                                                                                    text-transform: capitalize;
                                                                                                                    display: flex;
                                                                                                                    width: 100%;
                                                                                                                    justify-content: center;
                                                                                                                    font-size: 0.90rem;
                                                                                                                    position: relative;

                                                                                                                    &::after {
                                                                                                                        position: absolute;
                                                                                                                        right: 5px;
                                                                                                                        top: 13px;
                                                                                                                    }
                                                                                                                }

                                                                                                                .dk-simpleDrpDwnMenu {
                                                                                                                    border-radius: 0;
                                                                                                                    max-height: 180px;
                                                                                                                    width: 100%;
                                                                                                                    overflow-y: auto;
                                                                                                                    padding: 0;
                                                                                                                    border-color: #96c8da;
                                                                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                    .dropdown-item {
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        font-size: 0.9rem;
                                                                                                                    }

                                                                                                                    input {
                                                                                                                        border-bottom: 1px solid #ccc;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        .dk-simpleDrpDwnCon{
                                                                                                            width: 100%;
                                                                                                            .dk-simpleDrpDwn {
                                                                                                                .dk-simpleDrpDwnToggle {
                                                                                                                    color: #333;
                                                                                                                    box-shadow: none;
                                                                                                                    white-space: nowrap;
                                                                                                                    margin: 0;
                                                                                                                    background-color: #fff;
                                                                                                                    border: 1px solid #ddd;
                                                                                                                    padding: 0.55rem 0.75rem;
                                                                                                                    text-transform: capitalize;
                                                                                                                    display: flex;
                                                                                                                    width: 100%;
                                                                                                                    justify-content: center;
                                                                                                                    font-size: 0.9rem;
                                                                                                                    position: relative;
                                                                                                                    border-radius: 0;
                                                                                                                    &::after {
                                                                                                                        position: absolute;
                                                                                                                        right: 5px;
                                                                                                                        top: 13px;
                                                                                                                    }
                                                                                                                }

                                                                                                                .dk-simpleDrpDwnMenu {
                                                                                                                    border-radius: 0;
                                                                                                                    max-height: 180px;
                                                                                                                    width: 100%;
                                                                                                                    overflow-y: auto;
                                                                                                                    padding: 0;
                                                                                                                    border-color: #96c8da;
                                                                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                    .dropdown-item {
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        font-size: 0.9rem;
                                                                                                                    }

                                                                                                                    input {
                                                                                                                        border-bottom: 1px solid #ccc;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            .dk-jobsuMMBottomSec {
                                                                                                color: #3e3e3e;

                                                                                                .dk-jobsuMMBottomBoxBg {
                                                                                                    color: #333;
                                                                                                    .dkg-salary-tab-box{
                                                                                                        margin-top: 1.4rem;
                                                                                                        .dkg-salary-tab-header{
                                                                                                            background: #dddddd;
                                                                                                            border: 1px solid #cecece;
                                                                                                            border-radius: 5px 5px 0 0;
                                                                                                            border-bottom: none;
                                                                                                            width: 100%;
                                                                                                            padding: 5px;
                                                                                                            height: 40px;
                                                                                                            position: relative;
                                                                                                            .dkg-salary-header-title{
                                                                                                                text-align: center;
                                                                                                                color: #3e3e3e;
                                                                                                                font-size: 20px;
                                                                                                                font-weight: normal;
                                                                                                            }
                                                                                                        }
                                                                                                        .dkg-salary-tabbody{
                                                                                                            height: 286px;
                                                                                                            background-color: #eaeaea;
                                                                                                            border: 1px solid #cecece;
                                                                                                            border-radius:  0 0 5px 5px;
                                                                                                            padding: 15px;
                                                                                                            overflow-y: auto;
                                                                                                        }
                                                                                                        .dkg-salary-tabbody {
                                                                                                            -ms-overflow-style: none;  /* Internet Explorer 10+ */
                                                                                                            scrollbar-width: none;  /* Firefox */
                                                                                                        }
                                                                                                        .dkg-salary-tabbody::-webkit-scrollbar { 
                                                                                                            display: none;  /* Safari and Chrome */
                                                                                                        }
                                                                                                    }
                                                                                                    .dkg-salary-curr-dropdown-toggle {
                                                                                                        color: red;

                                                                                                        .dk-simpleDrpDwnCon {
                                                                                                            .dk-simpleDrpDwn {
                                                                                                                .dk-simpleDrpDwnToggle {
                                                                                                                    min-width: 90px;
                                                                                                                    height: 39px;
                                                                                                                    border: 1px solid #ddd;
                                                                                                                    line-height: 24px;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    .dk-suMMformGroupCon {
                                                                                                        min-height: 32px;
                                                                                                    }

                                                                                                    .dk-BoxHeader {
                                                                                                        background: #dddddd;
                                                                                                        border: 1px solid #cecece;
                                                                                                        border-bottom: none;
                                                                                                        width: 100%;
                                                                                                        padding: 5px;
                                                                                                        height: 40px;
                                                                                                        border-radius: 5px 5px 0 0;

                                                                                                        h3 {
                                                                                                            text-align: center;
                                                                                                            color: #3e3e3e;
                                                                                                            font-size: 20px;
                                                                                                            font-weight: normal;
                                                                                                            position: relative;
                                                                                                            .dkg-pophover-icon{
                                                                                                                display: flex;
                                                                                                                align-items: center;
                                                                                                                justify-content: center;
                                                                                                                width: 18px;
                                                                                                                height: 18px;
                                                                                                                background-color: #eaeaea;
                                                                                                                position: absolute;
                                                                                                                top: 3px;
                                                                                                                right: 65px;
                                                                                                                border-radius: 50%;
                                                                                                                border: 1px solid #ddd;
                                                                                                                cursor: pointer;
                                                                                                                svg{
                                                                                                                    width: 14px;
                                                                                                                    height: 14px;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    .dk-boxBody {
                                                                                                        background: #eaeaea;
                                                                                                        border: 1px solid #cecece;
                                                                                                        border-radius: 0 0 5px 5px;
                                                                                                        padding: 15px;
                                                                                                        &.dkg-livejobs-boxbody{
                                                                                                            padding-top: 15px;
                                                                                                        }
                                                                                                        .dk-suMMformGroupCon {
                                                                                                            display: flex;
                                                                                                            width: 100%;
                                                                                                            margin-bottom: 0.3rem;

                                                                                                            .label-hover:hover {
                                                                                                                color: blue;
                                                                                                            }

                                                                                                            input {
                                                                                                                border-bottom: 1px solid #ccc;
                                                                                                            }

                                                                                                            label {
                                                                                                                align-items: center;
                                                                                                                font-size: 0.90rem;
                                                                                                                white-space: nowrap;
                                                                                                                padding-right: 0.5rem;
                                                                                                                min-width: 110px !important;
                                                                                                                &.dkg-detil-rightlabel{
                                                                                                                    min-width: 135px !important;
                                                                                                                }
                                                                                                                &.dkg-gross-slary-label{
                                                                                                                    cursor: pointer;
                                                                                                                }
                                                                                                                svg{
                                                                                                                    width: 15px;
                                                                                                                    height: 15px;
                                                                                                                }

                                                                                                            }

                                                                                                            label.dk-benfitslabel {
                                                                                                                min-width: 20px;
                                                                                                                max-width: 20px;
                                                                                                            }

                                                                                                            label.relocationlabel {
                                                                                                                min-width: 147px !important;
                                                                                                            }

                                                                                                            .dk-simpleDrpDwnCon {
                                                                                                                width: 100%;

                                                                                                                .dk-simpleDrpDwn {
                                                                                                                    .dk-simpleDrpDwnToggle {
                                                                                                                        color: #333;
                                                                                                                        box-shadow: none;
                                                                                                                        white-space: nowrap;
                                                                                                                        margin: 0;
                                                                                                                        background-color: #fff;
                                                                                                                        border: 1px solid #ccc;
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        text-transform: capitalize;
                                                                                                                        display: flex;
                                                                                                                        width: 100%;
                                                                                                                        justify-content: center;
                                                                                                                        font-size: 0.90rem;
                                                                                                                        position: relative;

                                                                                                                        &::after {
                                                                                                                            position: absolute;
                                                                                                                            right: 5px;
                                                                                                                            top: 13px;
                                                                                                                        }
                                                                                                                    }

                                                                                                                    .dk-simpleDrpDwnMenu {
                                                                                                                        border-radius: 0;
                                                                                                                        max-height: 180px;
                                                                                                                        width: 100%;
                                                                                                                        overflow-y: auto;
                                                                                                                        padding: 0;
                                                                                                                        border-color: #96c8da;
                                                                                                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                        .dropdown-item {
                                                                                                                            padding: 0.35rem 0.75rem;
                                                                                                                            font-size: 0.9rem;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            .dkg-salary-curr-dropdown-toggle {
                                                                                                                .dk-simpleDrpDwn {
                                                                                                                    .dk-simpleDrpDwnToggle {
                                                                                                                        color: #333;
                                                                                                                        box-shadow: none;
                                                                                                                        white-space: nowrap;
                                                                                                                        margin: 0;
                                                                                                                        background-color: #fff;
                                                                                                                        border: 1px solid #ccc;
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        text-transform: capitalize;
                                                                                                                        display: flex;
                                                                                                                        width: 100%;
                                                                                                                        justify-content: center;
                                                                                                                        font-size: 0.90rem;
                                                                                                                        position: relative;

                                                                                                                        &::after {
                                                                                                                            position: absolute;
                                                                                                                            right: 5px;
                                                                                                                            top: 17px;
                                                                                                                        }
                                                                                                                    }

                                                                                                                    .dk-simpleDrpDwnMenu {
                                                                                                                        border-radius: 0;
                                                                                                                        max-height: 180px;
                                                                                                                        width: 100%;
                                                                                                                        overflow-y: auto;
                                                                                                                        padding: 0;
                                                                                                                        border-color: #96c8da;
                                                                                                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                        .dropdown-item {
                                                                                                                            padding: 0.35rem 0.75rem;
                                                                                                                            font-size: 0.9rem;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            input {
                                                                                                                display: flex;
                                                                                                                width: 100%;
                                                                                                                margin: 0;
                                                                                                                padding: 0.35rem 0.75rem;
                                                                                                                justify-content: center;
                                                                                                                align-items: center;
                                                                                                                border-radius: 0;
                                                                                                                text-transform: capitalize;
                                                                                                                font-size: 0.95rem;
                                                                                                                text-align: center;
                                                                                                                height: auto;
                                                                                                                &:focus {
                                                                                                                    outline: 0;
                                                                                                                    box-shadow: none;
                                                                                                                }
                                                                                                            }

                                                                                                            input.dk-benfitsInput {
                                                                                                                max-height: 30px;
                                                                                                            }

                                                                                                            .input-group {
                                                                                                                input {
                                                                                                                    border-right: 0;
                                                                                                                }

                                                                                                                .input-group-prepend {
                                                                                                                    background-color: #fff;

                                                                                                                    .input-group-text {
                                                                                                                        background-color: #fff;
                                                                                                                    }
                                                                                                                }

                                                                                                                .dkg-salary-curr-dropdown {
                                                                                                                    .dkg-salary-curr-dropdown-toggle {
                                                                                                                        margin: 0;
                                                                                                                        box-shadow: none;
                                                                                                                        border: 1px solid #ccc;
                                                                                                                        background-color: #fff;
                                                                                                                        padding: 10px 12px;
                                                                                                                        font-size: 0.95rem;

                                                                                                                        svg {
                                                                                                                            width: 13px;
                                                                                                                            height: 13px;
                                                                                                                        }
                                                                                                                    }

                                                                                                                    .dkg-salary-curr-dropdown-menu {
                                                                                                                        border-radius: 0;
                                                                                                                        width: 100%;
                                                                                                                        padding: 0;
                                                                                                                        margin: 0;
                                                                                                                        max-height: 230px;
                                                                                                                        overflow-y: auto;

                                                                                                                        .dkg-salary-curr-dropdown-item {
                                                                                                                            text-align: left;
                                                                                                                            padding: 7px 9px;
                                                                                                                            border-bottom: 1px solid #ccc;
                                                                                                                            font-size: 0.95rem;

                                                                                                                            svg {
                                                                                                                                width: 14px;
                                                                                                                                height: 14px;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    .dk-boxKpi {
                                                                                                        background: #eaeaea;
                                                                                                        border-top: 1px solid #b9b9b9;
                                                                                                        border-radius: 0 0 5px 5px;
                                                                                                        // height: calc(100vh - 512px);
                                                                                                        // overflow-y: auto;
                                                                                                        .dk-suMMformGroupCon {
                                                                                                            display: flex;
                                                                                                            border-bottom: 1px solid #cecece;
                                                                                                            width: 100%;
                                                                                                            margin-bottom: 0rem;
                                                                                                            &:last-child{
                                                                                                                border-radius: 0 0 5px 5px;
                                                                                                            }
                                                                                                            .label-hover:hover {
                                                                                                                color: blue;
                                                                                                            }

                                                                                                            input {
                                                                                                                border-bottom: 1px solid #ccc;
                                                                                                            }

                                                                                                            label {
                                                                                                                align-items: center;
                                                                                                                min-width: 117px;
                                                                                                                font-size: 0.90rem;
                                                                                                                white-space: nowrap;
                                                                                                                padding-right: 0.5rem;
                                                                                                            }

                                                                                                            label.dk-benfitslabel {
                                                                                                                min-width: 20px;
                                                                                                                max-width: 20px;
                                                                                                            }

                                                                                                            label.relocationlabel {
                                                                                                                min-width: 125px;
                                                                                                            }

                                                                                                            .dk-simpleDrpDwnCon {
                                                                                                                width: 100%;

                                                                                                                .dk-simpleDrpDwn {
                                                                                                                    .dk-simpleDrpDwnToggle {
                                                                                                                        color: #333;
                                                                                                                        box-shadow: none;
                                                                                                                        white-space: nowrap;
                                                                                                                        margin: 0;
                                                                                                                        background-color: #fff;
                                                                                                                        border: 1px solid #ccc;
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        text-transform: capitalize;
                                                                                                                        display: flex;
                                                                                                                        width: 100%;
                                                                                                                        justify-content: center;
                                                                                                                        font-size: 0.90rem;
                                                                                                                        position: relative;

                                                                                                                        &::after {
                                                                                                                            position: absolute;
                                                                                                                            right: 5px;
                                                                                                                            top: 13px;
                                                                                                                        }
                                                                                                                    }

                                                                                                                    .dk-simpleDrpDwnMenu {
                                                                                                                        border-radius: 0;
                                                                                                                        max-height: 180px;
                                                                                                                        width: 100%;
                                                                                                                        overflow-y: auto;
                                                                                                                        padding: 0;
                                                                                                                        border-color: #96c8da;
                                                                                                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                        .dropdown-item {
                                                                                                                            padding: 0.35rem 0.75rem;
                                                                                                                            font-size: 0.9rem;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            input {
                                                                                                                display: flex;
                                                                                                                width: 100%;
                                                                                                                margin: 0;
                                                                                                                padding: 0.35rem 0.75rem;
                                                                                                                justify-content: center;
                                                                                                                align-items: center;
                                                                                                                border-radius: 0;
                                                                                                                text-transform: capitalize;
                                                                                                                font-size: 0.95rem;
                                                                                                                text-align: center;

                                                                                                                &:focus {
                                                                                                                    outline: 0;
                                                                                                                    box-shadow: none;
                                                                                                                }
                                                                                                            }

                                                                                                            input.dk-benfitsInput {
                                                                                                                max-height: 30px;
                                                                                                            }

                                                                                                            .input-group {
                                                                                                                input {
                                                                                                                    border-right: 0;
                                                                                                                }

                                                                                                                .input-group-prepend {
                                                                                                                    background-color: #fff;

                                                                                                                    .input-group-text {
                                                                                                                        background-color: #fff;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    .dkg-salary-tab-box{
                                                                                                        margin-top: 1.4rem;
                                                                                                        .dkg-salary-tab-header{
                                                                                                            background: #dddddd;
                                                                                                            border: 1px solid #cecece;
                                                                                                            border-radius: 5px 5px 0 0;
                                                                                                            border-bottom: none;
                                                                                                            width: 100%;
                                                                                                            padding: 5px;
                                                                                                            height: 40px;
                                                                                                            position: relative;
                                                                                                            .dkg-salary-header-title{
                                                                                                                text-align: center;
                                                                                                                color: #3e3e3e;
                                                                                                                font-size: 20px;
                                                                                                                font-weight: normal;
                                                                                                            }
                                                                                                        }
                                                                                                        .dkg-salary-tabbody{
                                                                                                            height: 286px;
                                                                                                            background-color: #eaeaea;
                                                                                                            border: 1px solid #cecece;
                                                                                                            border-radius:  0 0 5px 5px;
                                                                                                            padding: 15px;
                                                                                                            overflow-y: auto;
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .dkg-editcompany-headerCon {
                                                                                                    display: flex;
                                                                                                    align-items: center;
                                                                                                    justify-content: center;
                                                                                                    border-width: 1px 1px 0px;
                                                                                                    border-top-style: solid;
                                                                                                    border-right-style: solid;
                                                                                                    border-bottom-style: initial;
                                                                                                    border-left-style: solid;
                                                                                                    border-top-color: rgb(204, 204, 204);
                                                                                                    border-right-color: rgb(204, 204, 204);
                                                                                                    border-bottom-color: initial;
                                                                                                    border-left-color: rgb(204, 204, 204);
                                                                                                    border-image: initial;
                                                                                                    padding: 11px 8px;
                                                                                                    position: relative;
                                                                                                    background-color: #eaeaea;

                                                                                                    .dkg-editcompany-leftCon {
                                                                                                        position: absolute;
                                                                                                        left: 10px;
                                                                                                        top: 6px;

                                                                                                        .dk-simpleDrpDwnCon {
                                                                                                            width: 100%;

                                                                                                            .dk-simpleDrpDwn {
                                                                                                                .dk-simpleDrpDwnToggle {
                                                                                                                    color: #333;
                                                                                                                    box-shadow: none;
                                                                                                                    white-space: nowrap;
                                                                                                                    margin: 0;
                                                                                                                    background-color: #eaeaea;
                                                                                                                    border: 1px solid #ccc;
                                                                                                                    padding: 0.40rem 0.75rem;
                                                                                                                    text-transform: capitalize;
                                                                                                                    display: flex;
                                                                                                                    width: 100%;
                                                                                                                    justify-content: flex-start;
                                                                                                                    font-size: 0.90rem;
                                                                                                                    position: relative;
                                                                                                                    min-width: 180px;

                                                                                                                    &::after {
                                                                                                                        position: absolute;
                                                                                                                        right: 5px;
                                                                                                                        top: 13px;
                                                                                                                    }
                                                                                                                }

                                                                                                                .dk-simpleDrpDwnMenu {
                                                                                                                    border-radius: 0;
                                                                                                                    max-height: 180px;
                                                                                                                    width: 100%;
                                                                                                                    overflow-y: auto;
                                                                                                                    padding: 0;
                                                                                                                    border-color: #96c8da;
                                                                                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                                                    .dropdown-item {
                                                                                                                        padding: 0.35rem 0.75rem;
                                                                                                                        font-size: 0.9rem;
                                                                                                                    }

                                                                                                                    input {
                                                                                                                        border-bottom: 1px solid #ccc;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                    }
                                                                                                }

                                                                                                .dkg-edit-jobfiles-mainCon {
                                                                                                    .dkg-add-new-btnCon {
                                                                                                        margin-bottom: 5px;

                                                                                                        .dkg-addnew-btn {
                                                                                                            box-shadow: none;
                                                                                                            border: 1px solid #ccc;
                                                                                                            background-color: #fff;
                                                                                                            color: #333;
                                                                                                            font-size: 13px;
                                                                                                            text-transform: capitalize;
                                                                                                            padding: 7px 9px;
                                                                                                            // min-width: 110px;
                                                                                                            margin: 0;
                                                                                                        }
                                                                                                    }

                                                                                                    .dkg-edit-jobfiles-tableCon {
                                                                                                        .dkg-edit-jobfiles-table {
                                                                                                            thead {
                                                                                                                tr {
                                                                                                                    th {
                                                                                                                        background: #485569;
                                                                                                                        border: 1px solid gray;
                                                                                                                        border-bottom: none;
                                                                                                                        color: #fff;
                                                                                                                        font-size: 13px;
                                                                                                                        padding: 9px 7px;
                                                                                                                        position: -webkit-sticky;
                                                                                                                        position: sticky;
                                                                                                                        text-align: center;
                                                                                                                        top: 0;
                                                                                                                        vertical-align: middle;
                                                                                                                        white-space: nowrap;
                                                                                                                        z-index: 9;
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            tbody {

                                                                                                                tr {
                                                                                                                    td {
                                                                                                                        background: #fff;
                                                                                                                        border: 1px solid #ddd;
                                                                                                                        color: #4d4a4a;
                                                                                                                        font-size: 13px;
                                                                                                                        font-weight: 400;
                                                                                                                        padding: 9px 7px;
                                                                                                                        position: relative;
                                                                                                                        text-align: center;
                                                                                                                        text-transform: capitalize;
                                                                                                                        vertical-align: middle;
                                                                                                                        white-space: nowrap;

                                                                                                                        .dkg-viewfile-iconCon {
                                                                                                                            display: flex;
                                                                                                                            justify-content: center;
                                                                                                                            align-items: center;

                                                                                                                            .dkg-viewfile-icon {
                                                                                                                                border-radius: 3px;
                                                                                                                                font-size: 14px;
                                                                                                                                height: 30px !important;
                                                                                                                                line-height: 30px;
                                                                                                                                width: 30px !important;
                                                                                                                                background-color: #2196f3;
                                                                                                                                color: #fff;
                                                                                                                            }
                                                                                                                        }

                                                                                                                        .dkg-file-actions {
                                                                                                                            display: flex;
                                                                                                                            justify-content: center;
                                                                                                                            align-items: center;

                                                                                                                            .dkg-viewfile-icon {
                                                                                                                                border-radius: 3px;
                                                                                                                                font-size: 14px;
                                                                                                                                height: 30px !important;
                                                                                                                                line-height: 30px;
                                                                                                                                width: 30px !important;
                                                                                                                                background-color: #2196f3;
                                                                                                                                color: #fff;
                                                                                                                            }

                                                                                                                            .dkg-download-icon {
                                                                                                                                border-radius: 3px;
                                                                                                                                font-size: 14px;
                                                                                                                                height: 30px !important;
                                                                                                                                line-height: 30px;
                                                                                                                                width: 30px !important;
                                                                                                                                background-color: #31a886;
                                                                                                                                color: #fff;
                                                                                                                            }

                                                                                                                            .dkg-editfile-icon {
                                                                                                                                border-radius: 3px;
                                                                                                                                font-size: 14px;
                                                                                                                                height: 30px;
                                                                                                                                line-height: 30px;
                                                                                                                                width: 30px;
                                                                                                                                background-color: #fee69c;
                                                                                                                                color: #333;
                                                                                                                            }

                                                                                                                            .dkg-deletefile-icon {
                                                                                                                                border-radius: 3px;
                                                                                                                                font-size: 14px;
                                                                                                                                height: 30px;
                                                                                                                                line-height: 30px;
                                                                                                                                width: 30px;
                                                                                                                                background-color: #dc3545;
                                                                                                                                color: #fff;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        .dkg-salary-tab-box{
                                                                                            margin-top: 14px;
                                                                                            .dkg-salary-tab-header{
                                                                                                background: #dddddd;
                                                                                                border: 1px solid #cecece;
                                                                                                border-bottom: none;
                                                                                                width: 100%;
                                                                                                padding: 5px;
                                                                                                height: 40px;
                                                                                                border-radius: 5px 5px 0 0;
                                                                                                position: relative;
                                                                                                .dkg-salary-header-title{
                                                                                                    text-align: center;
                                                                                                    color: #3e3e3e;
                                                                                                    font-size: 20px;
                                                                                                    font-weight: normal;
                                                                                                }
                                                                                            }
                                                                                            .dkg-salary-tabbody{
                                                                                                background-color: #ddd;
                                                                                                border: 1px solid #cecece;
                                                                                                border-radius: 0 0 5px 5px;
                                                                                                display: flex;
                                                                                                align-items: center;
                                                                                                justify-content: center;
                                                                                                height: 200px;
                                                                                                margin-top: 100px;
                                                                                                .dkg-income-inputgrp{
                                                                                                    width: 100%;
                                                                                                    max-width: 95%;
                                                                                                    margin: 0 auto;
                                                                                                    display: flex;
                                                                                                    align-items: center;
                                                                                                    .dkg-input-url{
                                                                                                        margin: 0;
                                                                                                        border: 1px solid #ddd;
                                                                                                        padding: 8px 15px;
                                                                                                        height: 38px;
                                                                                                        text-align: left;
                                                                                                    }
                                                                                                    .dkg-open-url-btn{
                                                                                                        background: #26597d;
                                                                                                        border: none;
                                                                                                        color: #fff;
                                                                                                        border-radius: 0;
                                                                                                        cursor: pointer;
                                                                                                        display: inline-block;
                                                                                                        font-size: 14px;
                                                                                                        font-weight: 500;
                                                                                                        min-width: 80px;
                                                                                                        outline: none;
                                                                                                        padding: 8px 15px;
                                                                                                        text-transform: uppercase;
                                                                                                        white-space: nowrap;
                                                                                                        height: 38px;
                                                                                                        margin: 0;
                                                                                                        &:hover{
                                                                                                            box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                            }
                                                                                        }
                                                                                        .dk-ClentViewTabPane.summaryTabPane{
                                                                                            .dkg-interviewnotes-editor12 {
                                                                                                margin-top: 1rem;
                                                                                                background-color: #eaeaea;
                                                                                                padding: 15px;
                                                                                                .dkg-jobtitleInput {

                                                                                                    &:focus,
                                                                                                    &:focus-visible {
                                                                                                        box-shadow: none;
                                                                                                        outline: 0;
                                                                                                    }
                                                                                                }

                                                                                                .wrapper-class {
                                                                                                    padding: 0 !important;
                                                                                                    border: none !important;

                                                                                                    .rdw-editor-toolbar.toolbar-class {
                                                                                                        margin-bottom: 0;

                                                                                                        .rdw-inline-wrapper {
                                                                                                            .rdw-option-wrapper {

                                                                                                                &:nth-child(05),
                                                                                                                &:nth-child(06),
                                                                                                                &:nth-child(07) {
                                                                                                                    display: none;
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        .rdw-option-wrapper.rdw-option-disabled {
                                                                                                            display: none;
                                                                                                        }
                                                                                                    }

                                                                                                    .editor-class.rdw-editor-main {
                                                                                                        min-height: inherit !important;
                                                                                                        max-height: inherit !important;
                                                                                                        background-color: #fff;
                                                                                                        height: calc(100vh - 291px);
                                                                                                        padding: 15px;
                                                                                                        .DraftEditor-root{
                                                                                                            .DraftEditor-editorContainer{
                                                                                                                .public-DraftEditor-content{
                                                                                                                    div{
                                                                                                                        div{
                                                                                                                            .public-DraftStyleDefault-block{
                                                                                                                                span{
                                                                                                                                    background-color: transparent !important;
                                                                                                                                    color: inherit !important;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            .dkg-cost-explain-Con{
                                                                                                padding-top: 15px;
                                                                                                .dkg-cost-explain-header{
                                                                                                    background: #ddd;
                                                                                                    border: 1px solid #cecece;
                                                                                                    border-radius: 5px 5px 0 0;
                                                                                                    height: 40px;
                                                                                                    padding: 5px;
                                                                                                    position: relative;
                                                                                                    width: 100%;
                                                                                                    .dkg-cost-explain-title{
                                                                                                        color: #3e3e3e;
                                                                                                        font-size: 20px;
                                                                                                        font-weight: 400;
                                                                                                        text-align: center;
                                                                                                    }
                                                                                                }
                                                                                                .dkg-cost-explain-body{
                                                                                                    .dkg-cost-explain-textArea{
                                                                                                        height: calc(100vh - 290px);
                                                                                                        border-top: none;
                                                                                                        border: 1px solid #cecece;
                                                                                                        border-radius: 0 0 5px 5px;
                                                                                                        padding: 5px 10px;
                                                                                                        &:focus{
                                                                                                            outline: 0;
                                                                                                            box-shadow: none;
                                                                                                        }
                                                                                                    }
                                                                                                    .dkg-open-url-btnCon{
                                                                                                        display: flex;
                                                                                                        justify-content: center;
                                                                                                        align-items: center;
                                                                                                        width: 100%;
                                                                                                        .dkg-open-url-btn{
                                                                                                           background-color: #234158;
                                                                                                            border: 1px solid #234158;
                                                                                                            border-radius: 0.1875rem;
                                                                                                            color: #fff;
                                                                                                            display: inline-block;
                                                                                                            font-size: 13px;
                                                                                                            line-height: 1.5385;
                                                                                                            padding: 5px 12px;
                                                                                                            text-align: center;
                                                                                                            text-transform: uppercase;
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                            }
                                                                                            .dkg-savebtnCon {
                                                                                                display: flex;
                                                                                                justify-content: center;
                                                                                                align-items: center;
                                                                                                width: 100%;
                                                                                                margin-top: 20px;

                                                                                                div {

                                                                                                    div {
                                                                                                        .dkg-create-payment-taskbtn {
                                                                                                            background: #234158;
                                                                                                            border: 1px solid #234158;
                                                                                                            border-radius: 0.1875rem;
                                                                                                            color: #fff;
                                                                                                            display: inline-block;
                                                                                                            font-size: 13px;
                                                                                                            line-height: 1.5385;
                                                                                                            margin-left: 120px;
                                                                                                            padding: 5px 12px;
                                                                                                            text-align: center;
                                                                                                            text-transform: uppercase;

                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                    .jobSummaryDetails {
                                        // background: #eaeaea;
                                        // border: 1px solid #ddd;
                                        // border-radius: 5px;
                                        // outline: 0;
                                        padding: 15px !important;
                                        height: calc(100vh - 40px);
                                        .dk-right-boxjobDetails {

                                            // overflow-y: auto;
                                            background-color: #243b4c;
                                            border: 1px solid #d3d3d3;
                                            padding: 15px;
                                            border-radius: 5px;
                                           height: calc(100vh - 75px);
                                            overflow-y: auto;
                                            &::-webkit-scrollbar-track {
                                                background: #F1F1F1;
                                            }
                                            &::-webkit-scrollbar-thumb {
                                                background-color: #ddd;
                                                border-radius: 4px;
                                                cursor: pointer;
                                              }
                                            .left-title-blocks {
                                                color: #fff;
                                                padding: 10px 0px 10px 0px;
                                                font-size: 12px !important;
                                                border-bottom: 1px solid #456e8c;
                                                white-space: nowrap;

                                                &:last-child {
                                                    border-bottom: none;
                                                }

                                                &.col-md-7 {
                                                    .p-3 {
                                                        font-size: 13px !important;
                                                    }
                                                }

                                                .fist-col-div {
                                                    background: #28485f;
                                                    border-right: 1px solid #456e8c;
                                                    text-transform: uppercase;
                                                    font-size: 13px;
                                                }
                                                .dkg-answer-block{
                                                    background-color: #eaeaea;
                                                    color: #333;
                                                    height: 43px;
                                                }
                                            }

                                            .p-3 {
                                                padding: 0.835rem 0.8rem !important;
                                            }
                                        }

                                        .dk-boxjobDetails {
                                            border: 1px solid #d3d3d3;
                                            padding: 15px;
                                            background-color: #28485f;
                                            border-radius: 5px;
                                            height: calc(100vh - 75px);
                                            overflow-y: auto;
                                            &::-webkit-scrollbar-track {
                                                background: #F1F1F1;
                                            }
                                            &::-webkit-scrollbar-thumb {
                                                background-color: #ddd;
                                                border-radius: 4px;
                                                cursor: pointer;
                                              }
                                            h1 {
                                                margin-bottom: 7px;
                                                color: #f4d03e;
                                                text-align: left;
                                                font-size: 18px;
                                                font-weight: 700;

                                                &.dkg-jobdetail-maintitle {
                                                    font-size: 20px;
                                                }
                                            }

                                            h3 {
                                                margin: 0 0 10px;
                                                padding: 0;
                                                font-size: 14px;
                                                font-weight: 700;
                                                color: #f4d03e;
                                            }

                                            div {
                                                margin-bottom: 10px;
                                                background-color: transparent !important;
                                                height: auto !important;
                                                min-height: inherit !important;
                                                p {
                                                    span {
                                                        color: inherit !important;
                                                        background-color: inherit !important;
                                                        font-family: inherit !important;
                                                        margin-bottom: 0 !important;
                                                    }
                                                }
                                            }

                                            p {
                                                color: #fff !important;
                                                opacity: .8;
                                            }

                                            li {
                                                list-style-type: disc;
                                                margin: 0 35px;
                                                color: #fff;
                                                opacity: .8;
                                                span{
                                                    background-color: transparent !important;
                                                    color: #fff !important;
                                                }
                                            }


                                        }

                                        .row.m-0 {
                                            // height: calc(100vh - 150px);
                                            // overflow-y: auto;

                                            .col-md-8.p-3.dk-suMMRightCon.dk-jobsuMMBottomSec {
                                                flex: 0 0 64.666667%;
                                                max-width: 64.666667%;
                                            }

                                            .col-md-4.p-3.dk-suMMRightCon.dk-jobsuMMBottomSec {
                                                flex: 0 0 35.333333%;
                                                max-width: 35.333333%;
                                                // height: calc(100vh - 115px);
                                                // overflow-y: auto;
                                                // height: calc(100vh - 133px);
                                                // overflow-y: auto;
                                            }
                                        }
                                    }

                                    &.dkg-edit-full-jobdetail-tabpane{
                                        height: calc(100vh - 40px);
                                        background-color: #eaeaea;
                                        .dkg-edit-livejobs-adsboxRow-234{
                                            margin: 0 !important;
                                            .dkg-edit-livejobs-adsboxleftSide{
                                                background-color: #244158;
                                                flex: 0 0 14.666667%;
                                                height: calc(100vh - 54px);
                                                margin-top: 14px;
                                                .dkg-edit-livejobs-adsbox-navpills{
                                                    background-color: #fff;
                                                    display: block !important;
                                                    width: 100%;
                                                    .dkg-edit-livejobs-navitem{
                                                        width: 100%;
                                                       .dkg-edit-livejobs-navlink{
                                                        background-color: #244158;
                                                        border-bottom: 1px solid #3e5b73 !important;
                                                        border-radius: 0;
                                                        color: #fff;
                                                        padding: 8px;
                                                        text-align: left;
                                                        width: 100%;
                                                        &.active{
                                                            background-color: #eaeaea;
                                                            color: #333;

                                                        }

                                                       }
                                                       &:first-child{
                                                        .dkg-edit-livejobs-navlink{
                                                            border-top: none;
                                                        }
                                                       }
                                                    }
                                                }
                                            }
                                            .dkg-editjob-adsboxRightSide{
                                                .dkg-editjob-adsbox-jobcontent{
                                                    .dkg-editjob-adsbox-tabpane{
                                                        padding: 15px;
                                                        background-color: #eaeaea;
                                                        div{
                                                            .dk-Adsbox {
                                                                margin-top: 0;
                                                                .dkg-job-staus-col {
                                                                    &.dkg-edit-jobstus-leftcol {
                                                                        flex: 0 0 38.666667%;
                                                                        max-width: 38.666667%;
                                                                    }

                                                                    &.dkg-edit-jobstus-rightcol {
                                                                        flex: 0 0 55%;
                                                                        max-width: 55%;
                                                                    }

                                                                    .dk-suMMformGroupCon {
                                                                        display: flex;
                                                                        align-items: center;

                                                                        .dk-statusDrpDwnCon {
                                                                            width: 100%;

                                                                            .dropdown {
                                                                                width: 100%;
                                                                                display: flex;

                                                                                .dropdown-toggle,
                                                                                .dk-statusDrpDwn.dropdown-toggle {
                                                                                    width: 100%;
                                                                                    box-shadow: none;
                                                                                    margin: 0;
                                                                                    padding: 9px 10px;
                                                                                    text-transform: capitalize;
                                                                                    font-size: 12px;
                                                                                    position: relative;
                                                                                    background-color: #fff;
                                                                                    border: 1px solid #ddd;
                                                                                    border-top-color: rgb(204, 204, 204);
                                                                                    border-right-color: rgb(204, 204, 204);
                                                                                    border-bottom-color: rgb(204, 204, 204);
                                                                                    border-left-color: rgb(204, 204, 204);
                                                                                    height: calc(1.5em + 0.75rem + 2px);
                                                                                    line-height: 12px;

                                                                                    &::after {
                                                                                        position: absolute;
                                                                                        right: 10px;
                                                                                        top: 12px;
                                                                                        font-size: 14px;
                                                                                    }
                                                                                }

                                                                                .dk-statusDrpDwnMenu,
                                                                                .dk-simpleDrpDwnMenu {
                                                                                    border-radius: 0;
                                                                                    padding: 0;
                                                                                    width: 100%;

                                                                                    input {
                                                                                        border-bottom: 1px solid #ddd;
                                                                                        // width: 100%;
                                                                                        // max-width: 95%;
                                                                                        // margin: 12px auto;
                                                                                    }

                                                                                    .list-unstyled {
                                                                                        .dropdown-item {
                                                                                            font-size: 13px;
                                                                                            padding: 7px 10px;
                                                                                            border-bottom: 1px solid #ddd;

                                                                                            &:last-child {
                                                                                                border-bottom: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        .dk-AmDropDwnCon {
                                                                            .dk-AmDropDwn {
                                                                                .dk-AmDropDwnToggle {
                                                                                    background-color: transparent;
                                                                                    box-shadow: none;
                                                                                    margin: 0;
                                                                                    padding: 0 !important;

                                                                                    &::after {
                                                                                        display: none;
                                                                                    }

                                                                                    .tooltipMain {
                                                                                        .filterImg {
                                                                                            width: 37px;
                                                                                            height: 37px;
                                                                                            border: 2px dotted rgb(153, 153, 153);
                                                                                            border-radius: 50%;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                .dk-AmDropDwnMenu {
                                                                                    background: #234158;
                                                                                    border: 1px solid #234158;
                                                                                    left: 22px !important;
                                                                                    padding: 5px;
                                                                                    top: -10px !important;
                                                                                    transform: translate(22px) !important;
                                                                                    -webkit-transform: translate(22px) !important;

                                                                                    &:after {
                                                                                        border-color: transparent #234158 transparent transparent;
                                                                                        _border-color: #000 #ccc #000 #000;
                                                                                        border-style: solid;
                                                                                        border-width: 8.5px 12px 8.5px 0;
                                                                                        content: "";
                                                                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                                                                        height: 0;
                                                                                        left: -12px;
                                                                                        line-height: 0;
                                                                                        position: absolute;
                                                                                        top: 15px;
                                                                                        width: 0;
                                                                                    }

                                                                                    .d-flex {
                                                                                        a.dropdown-item {
                                                                                            display: inline-block;
                                                                                            height: 35px;
                                                                                            margin: 0 3px;
                                                                                            padding: 0;
                                                                                            width: 35px;

                                                                                            .tooltipMain {
                                                                                                .filterImg {
                                                                                                    border-radius: 50%;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        .thridBox {
                                                                            .AmImgConDrop {
                                                                                .dk-suMMSrchDropDwnCon {
                                                                                    .dk-suMMSrchDropDwn {
                                                                                        a {
                                                                                            img {
                                                                                                border: 2px dotted #999;
                                                                                                border-radius: 50%;
                                                                                                height: 37px;
                                                                                                width: 37px;
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    .dk-suMMSrchDropDwnMenu {
                                                                                        border-radius: 0;
                                                                                        padding: 0;

                                                                                        input {
                                                                                            border-bottom: 1px solid #ccc;
                                                                                        }

                                                                                        ul {
                                                                                            padding: 0;
                                                                                            max-height: 220px;
                                                                                            overflow-y: auto;

                                                                                            .dk-assignRecruitersCon {
                                                                                                p {
                                                                                                    padding: 0.25rem 1rem;
                                                                                                    margin-bottom: 0;
                                                                                                    font-size: 0.95rem;
                                                                                                }

                                                                                                .dk-reclistItem {
                                                                                                    padding: 0.25rem 1rem;

                                                                                                    &.active {
                                                                                                        .dk-assignRecCon {
                                                                                                            span {
                                                                                                                color: #fff;
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    .dk-assignRecCon {
                                                                                                        display: flex;
                                                                                                        position: relative;
                                                                                                        align-items: center;

                                                                                                        img.recruiterimg {
                                                                                                            width: 35px;
                                                                                                            height: 35px;
                                                                                                            border-radius: 50%;
                                                                                                            border: 2px dotted #ddd;
                                                                                                            margin-right: 10px;
                                                                                                        }

                                                                                                        span {
                                                                                                            font-size: 0.85rem;
                                                                                                            font-weight: bold;
                                                                                                            color: #333;
                                                                                                        }

                                                                                                        .crossIcon {
                                                                                                            position: absolute;
                                                                                                            right: 10px;

                                                                                                            i {
                                                                                                                font-size: 16px;
                                                                                                                color: #333;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            .dk-remaingRecruiters {
                                                                                                p {
                                                                                                    padding: 0.25rem 1rem;
                                                                                                    margin-bottom: 0;
                                                                                                    font-size: 0.95rem;
                                                                                                }

                                                                                                .dk-reclistItem {
                                                                                                    padding: 0.25rem 1rem;

                                                                                                    &.active {
                                                                                                        .dk-unassignRecCon {
                                                                                                            span {
                                                                                                                color: #fff;
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    .dk-unassignRecCon {
                                                                                                        img.recruiterimg {
                                                                                                            width: 35px;
                                                                                                            height: 35px;
                                                                                                            border-radius: 50%;
                                                                                                            border: 2px dotted #ddd;
                                                                                                            margin-right: 10px;
                                                                                                        }

                                                                                                        span {
                                                                                                            font-size: 0.85rem;
                                                                                                            opacity: .8;
                                                                                                            font-weight: bold;
                                                                                                            color: #212529;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                }
                                                                .dkg-editjob-summ-tabCon{
                                                                    color: #000;
                                                                    div{
                                                                        .dk-suMMformGroupCon{
                                                                            display: flex;
                                                                            align-items: center;
                                                                            label{
                                                                                font-size: 0.95rem;
                                                                            }
                                                                            .dk-statusDrpDwnCon{
                                                                                width: 100%;
                                                                                .dk-simpleDrpDwn {
                                                                                    width: 100%;
                                                                                    .dk-simpleDrpDwnToggle {
                                                                                        color: #333;
                                                                                        box-shadow: none;
                                                                                        white-space: nowrap;
                                                                                        margin: 0;
                                                                                        background-color: #fff;
                                                                                        border: 1px solid #ccc;
                                                                                        padding: 0.40rem 0.75rem;
                                                                                        text-transform: capitalize;
                                                                                        display: flex;
                                                                                        width: 100%;
                                                                                        justify-content: center;
                                                                                        font-size: 0.90rem;
                                                                                        position: relative;

                                                                                        &::after {
                                                                                            position: absolute;
                                                                                            right: 5px;
                                                                                            top: 13px;
                                                                                        }
                                                                                    }

                                                                                    .dk-simpleDrpDwnMenu {
                                                                                        border-radius: 0;
                                                                                        max-height: 180px;
                                                                                        width: 100%;
                                                                                        overflow-y: auto;
                                                                                        padding: 0;
                                                                                        border-color: #96c8da;
                                                                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                        .dropdown-item {
                                                                                            padding: 0.35rem 0.75rem;
                                                                                            font-size: 0.9rem;
                                                                                        }

                                                                                        input {
                                                                                            border-bottom: 1px solid #ccc;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            .dk-simpleDrpDwnCon{
                                                                                width: 100%;
                                                                                .dk-simpleDrpDwn {
                                                                                    .dk-simpleDrpDwnToggle {
                                                                                        color: #333;
                                                                                        box-shadow: none;
                                                                                        white-space: nowrap;
                                                                                        margin: 0;
                                                                                        background-color: #fff;
                                                                                        border: 1px solid #ccc;
                                                                                        padding: 0.40rem 0.75rem;
                                                                                        text-transform: capitalize;
                                                                                        display: flex;
                                                                                        width: 100%;
                                                                                        justify-content: center;
                                                                                        font-size: 0.90rem;
                                                                                        position: relative;

                                                                                        &::after {
                                                                                            position: absolute;
                                                                                            right: 5px;
                                                                                            top: 13px;
                                                                                        }
                                                                                    }

                                                                                    .dk-simpleDrpDwnMenu {
                                                                                        border-radius: 0;
                                                                                        max-height: 180px;
                                                                                        width: 100%;
                                                                                        overflow-y: auto;
                                                                                        padding: 0;
                                                                                        border-color: #96c8da;
                                                                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                                                        .dropdown-item {
                                                                                            padding: 0.35rem 0.75rem;
                                                                                            font-size: 0.9rem;
                                                                                        }

                                                                                        input {
                                                                                            border-bottom: 1px solid #ccc;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .dkg-editjob-title-tabCon{
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            flex-direction: column;
                                                            height: 300px;
                                                            padding: 0 5rem;
                                                            .dkg-jobtitleInput-flex{
                                                                display: flex;
                                                                align-items: center;
                                                                flex-direction: column;
                                                                .dkg-interviewnotes-editor12{
                                                                    margin-top: 1rem;
                                                                    .dkg-jobtitle-label{
                                                                        min-width: 180px;
                                                                        margin-bottom: 0px;
                                                                    }
                                                                    .dkg-jobtitleInput{
                                                                        margin-top: 0px;
                                                                        height: 35px;
                                                                        border: 1px solid rgb(221, 221, 221);
                                                                        padding: 5px;
                                                                        border-radius: 0px;
                                                                    }
                                                                    .dkg-jobinfo-date-timeCon {
                                                                        display: flex;
                                                                        align-items: center;
                                                                        width: 100%;
                                                    
                                                                        .MuiFormControl-root {
                                                                            &.MuiFormControl-marginNormal {
                                                                                margin: 0;
                                                                                width: 100%;
                                                                            }
                                                    
                                                                            .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                                                                &::before{
                                                                                    border-bottom: none;
                                                                                }
                                                                                input {
                                                                                    text-align: center;
                                                                                    background-color: #fff;
                                                                                }
                                                    
                                                                                .MuiInputAdornment-positionEnd {
                                                                                    margin-left: 0;
                                                    
                                                                                    .MuiIconButton-root {
                                                                                        padding: 6px 7px;
                                                                                        background-color: #26597d;
                                                                                        border-radius: 0;
                                                                                        border: 1px solid #ccc;
                                                                                        border-left: 0;
                                                    
                                                                                        .MuiIconButton-label {
                                                                                            svg {
                                                                                                color: #fff;
                                                                                                font-size: 1.25rem;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                    
                                                                        .dkg-readonly-datetime-picker {
                                                                            color: #234158;
                                                    
                                                                            .MuiFormControl-root {
                                                                                &.MuiFormControl-marginNormal {
                                                                                    margin: 0;
                                                                                    width: 100%;
                                                                                }
                                                    
                                                                                .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                                                                    input {
                                                                                        text-align: center;
                                                                                        background-color: #f2f2f2;
                                                                                    }
                                                    
                                                                                    .MuiInputAdornment-positionEnd .MuiIconButton-root {
                                                                                        cursor: text;
                                                                                    }
                                                                                }
                                                                            }
                                                    
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .dkg-editjob-savebtnCon{
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                width: 100%;
                                                                margin-top: 30px;
                                                                div{
                                                                    div{
                                                                        .dkg-editjob-savebtn{
                                                                            background: #234158;
                                                                            border: 1px solid #234158;
                                                                            border-radius: .1875rem;
                                                                            color: #fff;
                                                                            display: inline-block;
                                                                            font-size: 13px;
                                                                            line-height: 1.5385;
                                                                            padding: 5px 12px;
                                                                            text-align: center;
                                                                            text-transform: uppercase;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .dk-ClentViewTabPane.summaryTabPane{
                                                            .dkg-interviewnotes-editor12 {
                                                                margin-top: 1rem;
                                                                .wrapper-class {
                                                                    padding: 0 !important;
                                                                    border: none !important;

                                                                    .rdw-editor-toolbar.toolbar-class {
                                                                        margin-bottom: 0;

                                                                        .rdw-inline-wrapper {
                                                                            .rdw-option-wrapper {

                                                                                &:nth-child(05),
                                                                                &:nth-child(06),
                                                                                &:nth-child(07) {
                                                                                    display: none;
                                                                                }
                                                                            }
                                                                        }

                                                                        .rdw-option-wrapper.rdw-option-disabled {
                                                                            display: none;
                                                                        }
                                                                    }

                                                                    .editor-class.rdw-editor-main {
                                                                        min-height: inherit !important;
                                                                        max-height: inherit !important;
                                                                        background-color: #fff;
                                                                        height: calc(100vh - 200px);
                                                                        padding: 15px;
                                                                        .DraftEditor-root{
                                                                            .DraftEditor-editorContainer{
                                                                                .public-DraftEditor-content{
                                                                                    div{
                                                                                        div{
                                                                                            .public-DraftStyleDefault-block{
                                                                                                span{
                                                                                                    background-color: transparent !important;
                                                                                                    color: inherit !important;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                .dkg-savebtnCon{
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: center;
                                                                    width: 100%;
                                                                    margin-top: 20px;
                                                                    div{
                                                                        div{
                                                                            .dkg-create-payment-taskbtn{
                                                                                background: #234158;
                                                                                border: 1px solid #234158;
                                                                                border-radius: .1875rem;
                                                                                color: #fff;
                                                                                display: inline-block;
                                                                                font-size: 13px;
                                                                                line-height: 1.5385;
                                                                                padding: 5px 12px;
                                                                                text-align: center;
                                                                                text-transform: uppercase;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
            &.dkg-edit-activefirst-jobbody{
                .dkg-edit-activefirst-jobPopCon{
                    .dkg-edit-activefirst-jobtopSec{
                        .dk-DetViewBottomSecCon.dkg-editDetViewBottomSecCon{
                            &.dkg-activefirst-DetViewBottomSec{
                                .dk-ClieDetViewSideMenu.dkg-edit-activefirst-SideMenu{
                                    // left: 50%;
                                    // position: absolute;
                                    // top: -47px;
                                    // transform: translate(-50%, 9px);
                                    .dkg-edit-activefirst-navpills{
                                        // position: relative;
                                        // top: 0;
                                        // width: 100%;
                                        // justify-content: center;
                                        .dkg-edit-activefirst-navitem{
                                            width: 100%;
                                            max-width: inherit !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.interviewSubTab {
    border: 1px solid #ccc !important;
    border-right: 0 !important;

    &.dkg-edit-interview-leftSide {
        flex: 0 0 14.666666%;
        max-width: 14.666666%;
        background-color: #eaeaea;
    }

    .nav.nav-pills {
        display: block;

        .nav-link {
            background: #eaeaea;
            color: #000;
            border-radius: 0;
            text-align: center;
            padding: 10px;
        }

        .active {
            width: 100%;
            border: 0 !important;
            background-color: #fff;
        }
    }

}

.modal.fade .dk-livejobrightModalDailog.modal-dialog {
    right: -95%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show .dk-livejobrightModalDailog.modal-dialog {
    right: 0;
}

.modal-dialog.dk-livejobrightModalDailog1 {
    min-width: 55% !important;
}

// START MEDIA QUIRES
@media only screen and (min-width: 992px) {
    .col-md-2.col-sm-12.dk-ClieDetViewSideMenu {
        -ms-flex: 0 0 10.66667%;
        flex: 0 0 10.66667%;
        max-width: 10.66667%;

    }

    .col-md-10.col-sm-12.dk-ClieDetViewMainCont {
        -ms-flex: 0 0 89.33333%;
        flex: 0 0 89.33333%;
        max-width: 89.33333%;
    }

}

@media only screen and (min-width: 1200px) {
    .col-md-2.col-sm-12.dk-ClieDetViewSideMenu {
        -ms-flex: 0 0 10.66667%;
        flex: 0 0 10.66667%;
        max-width: 10.66667%;

    }

    .col-md-10.col-sm-12.dk-ClieDetViewMainCont {
        -ms-flex: 0 0 89.33333%;
        flex: 0 0 89.33333%;
        max-width: 89.33333%;
    }

}

.dk-createInterview {
    background: #26597d;
    border: none;
    color: #fff;
    margin-right: 0;
    display: inline-block;
    text-transform: uppercase;
    padding: 8px 6px;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    border-radius: 5px;
    min-width: 96px;
    margin-bottom: 0;
    white-space: nowrap;
    position: relative;
    top: -6px;
    right: -15px;

    &:hover {
        background-color: #fff;
    }
}

.dk-Adsbox {
    .description-height {
        .fr-wrapper {
            height: calc(100vh - 253px) !important;
        }
    }

    .titlebox {
        background-color: #eaeaea;
        border: 1px solid #cecece;
        border-radius: 5px;
        padding: 20px 15px 10px 15px;

        .col-md-2 {
            flex: 0 0 13.666667%;
            max-width: 13.666667%;
        }

        .col-md-10 {
            flex: 0 0 86.333333%;
            max-width: 86.333333%;
        }

        .form-control {
            height: calc(1.7em + 0.75rem + 2px);
        }

        .input-group-text {
            border-radius: 0;
            background-color: #eaeaea;

            .dropdown {
                width: 100%;

                .dropdown-menu.show {
                    width: 100%;
                }
            }

            .btn {
                background: none !important;
                box-shadow: none !important;
                margin: 0 !important;
                padding: 0 !important;
                color: #495057 !important;
                text-transform: capitalize;
                font-size: 1rem;
                font-weight: 400;
                width: 100% !important;
                line-height: 1.5;
            }

            .dropdown-toggle::after {
                display: none;
            }
        }
    }

    .instructionebox {
        background-color: #eaeaea;
        border: 1px solid #cecece;
        border-radius: 5px 5px 0px 0px;

        .scrollDiv {
            overflow: auto;
            height: calc(100vh - 452px);
        }

        h3 {
            margin: 0px;
            padding: 11px;
            color: #3e3e3e;
            font-size: 16px;
            border-bottom: 1px solid #cecece;
            text-transform: uppercase;
            text-align: center;
        }

        padding:0px !important;

        .input-group-text {
            border-radius: 0;
            background-color: #eaeaea;
        }
    }

    .resourcesbox {
        background-color: #eaeaea;
        border: 1px solid #cecece;
        border-radius: 5px 5px 0px 0px;

        .scrollDiv {
            overflow: auto;
            height: calc(100vh - 600px);
        }

        h3 {
            margin: 0px;
            padding: 11px;
            color: #3e3e3e;
            font-size: 16px;
            border-bottom: 1px solid #cecece;
            text-transform: uppercase;
            text-align: center;
        }

        padding:0px !important;

        .input-group-text {
            border-radius: 0;
            background-color: #eaeaea;
        }
    }

    .sendoutbox {
        background-color: #fff;
        border: none;
        border-radius: 5px 5px 0px 0px;

        .scrollDiv {
            overflow: auto;
            height: calc(100vh - 304px);
            border: 1px solid #ddd;
            border-top: none;
        }

        h3 {
            margin: 0px;
            padding: 11px;
            background: #fff;
            border-bottom: 1px solid #cecece;
            text-transform: uppercase;
            text-align: center;
            text-align: center;
            color: #3e3e3e;
            font-size: 18px;
            font-weight: normal;
        }

        padding:0px !important;

        .input-group-text {
            border-radius: 0;
            background-color: #eaeaea;
        }
    }

    .sendoutsecondbox {
        background-color: #fff;
        border: 1px solid #cecece;
        border-radius: 5px 5px 0px 0px;
        margin-bottom: 11px;

        .scrollDiv {
            overflow: auto;
            height: calc(100vh - 257px);
            width: 100%;
            border: 0px;
        }

        h3 {
            margin: 0px;
            padding: 11px;
            background: #fff;
            border-bottom: 1px solid #cecece;
            text-transform: uppercase;
            text-align: center;
            text-align: center;
            color: #3e3e3e;
            font-size: 18px;
            font-weight: normal;
        }

        padding:0px !important;

        .input-group-text {
            border-radius: 0;
            background-color: #eaeaea;
        }
    }

    .resourcesTable {
        background-color: #eaeaea;
        border: 1px solid #cecece;
        border-radius: 5px;
        padding: 20px 15px 10px 15px;
    }

    .resourcesTable h3 {
        font-size: 15px;
        color: #5b9cd4;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        text-align: center;
        padding: 10px;

        i {
            float: right;
            color: #000;
            margin-right: 10px;
        }
    }

    .resourcesTable {

        table.table th,
        table.table td {
            padding-top: 0.2rem;
            text-align: center;
            padding-bottom: 0.8rem;
            font-size: 14px;

            &:first-child {
                text-align: left;
            }
        }

        table.table td {
            border-top: none;

            i {
                color: gray;
            }
        }
    }

    .interviewsTable {
        table.table th {
            background-color: #808080;
            border: 1px solid #ddd !important;
            text-align: center;
            font-weight: bold;
            color: #fff;
        }
        table.table td {
            border: 1px solid #ccc;
            text-align: center;
            padding: 0.45rem !important;
            .dkg-clientinfo-checkbox{
                display: flex;
                align-items: center;
                justify-content: center;
                input[type=checkbox]:checked {
                    background-color: #34976e !important;
                }
            }
        }

        .table-responsive {
            padding: 1px;
            background-color: transparent;
            margin-top: 10px;
        }
    }

    .interviewsBottomTable {
        .table-responsive {
            overflow: unset;
        }

        table.table tbody {
            background-color: #eaeaea;
        }

        table.table td {
            border: 1px solid #ccc;
            text-align: center;
        }

        p {
            color: #5b9cd4;
            font-weight: bold;

            &:first-child {
                border-bottom: 1px solid #ccc;
                padding-bottom: 15px;
            }

            i {
                color: gray;
                font-size: 15px;
                margin-left: 10px;
            }
        }
    }

    .insInput {
        height: 43px;
        border-radius: 0px 0px 0px 5px;
        text-align: left;
        border: 1px solid #ced4da !important;
        border-top: 0px !important;
    }

    .cvInput {
        height: 43px;
        border-radius: 5px 0px 0px 5px;
        text-align: left;
        border: 1px solid #ced4da !important;
    }

    .insSubmit {
        padding: 11px 17px 11px 18px;
        background-color: #d9d9d9 !important;
        border-radius: 0px;
        border: 0px !important;
        margin: 0px !important;
        color: #464646 !important;
        font-size: 14px;
        box-shadow: none;
    }

    .insSubmit:hover {
        background-color: #a1a1a1 !important;
        color: #fff !important;
    }

    .samplead {
        background-color: #eaeaea;
        border-radius: 5px 5px 0px 0px;

        h3 {
            margin: 0px;
            padding: 11px;
            color: #3e3e3e;
            font-size: 16px;
            text-transform: uppercase;
            text-align: center;
            background-color: #eaeaea;
            border: 1px solid #ddd;

            &.dkg-sample-senout-title {
                border: none;
                border-bottom: 1px solid #ddd;
            }
        }

        padding:0px !important;
    }
}

.EditorHideBar {
    .fr-wrapper {
        height: calc(100vh - 891px) !important;
    }

    .fr-toolbar {
        display: none;
    }
}

.fr-toolbar.fr-top {
    border-radius: 0 !important;
}

.fr-second-toolbar {
    border-radius: 0 !important;
}

.fr-box.fr-basic .fr-element {
    overflow-x: hidden !important;
}

.MuiSnackbar-anchorOriginBottomCenter {
    z-index: 1000;
}
.MuiSnackbar-anchorOriginBottomCenter {
    left: 50% !important;
    top: 50% !important;
    right: auto;
    transform: translate(-50%, -50%) !important;
}
.full-spec-editor {
    .fr-wrapper {
        height: calc(100vh - 297px) !important;
    }
}

.ad-description {
    .fr-wrapper {
        height: calc(100vh - 314px) !important;
    }
}

.sendout-file {
    height: calc(100vh - 250px);
    border-radius: 5px;
    align-items: center;
}

.likesdislikesbox {
    background-color: transparent;
    border: none;
    border-radius: 5px;

    h3 {
        margin: 0px;
        padding: 11px;
        color: #3e3e3e;
        font-size: 16px;
        border-bottom: 1px solid #cecece;
        text-transform: uppercase;
        text-align: center;
    }

    padding:0px !important;

    textarea {
        resize: none;
        height: 100px;
        overflow: auto;
        text-align: justify;
        font-size: 13px;
        border: 1px solid #ccc;
        border-left: 0px;

        &:focus {
            box-shadow: none;
            border: 1px solid #ccc;
            border-left: 0px;
        }
    }

    .input-group-text {
        border-radius: 0px;
        padding: 0.375rem 1.15rem !important;
    }

    input {
        border: 1px solid #ccc;
        text-align: left;
        height: calc(2em + 0.69rem + 2px) !important;
        border-left: 0px !important;
    }
}

.dk-project-tab-box {
    background: #eaeaea;
    border: 1px solid #cecece;
    border-radius: 0 0 5px 5px;
    height: 171px;
}

.dk-company-tab-box {
    // background: #eaeaea;
    // border: 1px solid #cecece;
    // border-radius: 0 0 5px 5px;
    // height: 402px;
    // padding: 15px;
    // padding-top: 5px;
    // overflow-y: auto;

    .dk-suMMformGroupCon {
        display: flex;
        width: 100%;
        margin-bottom: 0.3rem;

        .label-hover:hover {
            color: blue;
        }

        input {
            border-bottom: 1px solid #ccc;
        }

        label {
            align-items: center;
            min-width: 100px;
            font-size: 0.90rem;
            white-space: nowrap;
            padding-right: 0.5rem;
        }

        label.dk-benfitslabel {
            min-width: 20px;
            max-width: 20px;
        }

        label.relocationlabel {
            min-width: 125px;
        }

        .dk-simpleDrpDwnCon {
            width: 100%;

            .dk-simpleDrpDwn {
                .dk-simpleDrpDwnToggle {
                    color: #333;
                    box-shadow: none;
                    white-space: nowrap;
                    margin: 0;
                    background-color: #fff;
                    border: 1px solid #ccc;
                    padding: 0.35rem 0.75rem;
                    text-transform: capitalize;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    font-size: 0.90rem;
                    position: relative;

                    &::after {
                        position: absolute;
                        right: 5px;
                        top: 13px;
                    }
                }

                .dk-simpleDrpDwnMenu {
                    border-radius: 0;
                    max-height: 180px;
                    width: 100%;
                    overflow-y: auto;
                    padding: 0;
                    border-color: #96c8da;
                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                    .dropdown-item {
                        padding: 0.35rem 0.75rem;
                        font-size: 0.9rem;
                    }
                }
            }
        }

        input {
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0.35rem 0.75rem;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            text-transform: capitalize;
            font-size: 0.95rem;
            text-align: center;

            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }

        input.dk-benfitsInput {
            max-height: 30px;
        }

        .input-group {
            input {
                border-right: 0;
            }

            .input-group-prepend {
                background-color: #fff;

                .input-group-text {
                    background-color: #fff;
                }
            }
        }
    }
    &.dkg-livejobs-imagebox{
        display: flex;
        align-items: center;
        width: 100%;
        height: inherit;
        background-color: #20394a;
        padding: 8px 0;
        .dk-company-tab-cols{
            background-color: #fff;
            border: none;
            border-radius: 5px;
            display: block;
            font-size: 12px;
            margin: 8px 15px;
            width: 100%;
            overflow: hidden;
            img{
                width: 100%;
                height: 140px;
                object-fit: cover;
                object-position: center;
            }
            .dkg-companyName{
                font-size: 13px;
                font-weight: 400;
                color: #333;
                text-align: left;
                padding: 9px 10px;
            }
        }
    }
}
.dkg-company-img-video-Con{
    background-color: #20394a;
    // padding: 15px;
    // border: 3px solid #5a6168;
    border-radius: 5px;
    .dkg-comany-firstbox{
        // border-bottom:  3px solid #5a6168;
        // padding-bottom: 20px;
        .dkg-company-header{
            border-bottom:  1px solid #5a6168;
            background-color: #20394a;
            h3{
                font-size: 18px;
                font-weight: 700;
                width: 100%;
                text-align: center;
                padding: 7px 10px;
                color: #fff;


            }
        }
    }
    .dkg-white-horzontal{
        height: 18px;
        background-color: #fff;
    }
    .dkg-comany-secondbox{
        .dkg-company-header{
            border-bottom:  1px solid #5a6168;
            // background-color: #eaeaea;
            h3{
                font-size: 18px;
                font-weight: 700;
                width: 100%;
                text-align: center;
                padding: 7px 10px;
                color: #fff;

            }
        }
    }
    .dkg-company-header{
        background-color: #20394a;
        h3{
            color: #fff;
        }
    }
    .dkg-company-videos-header{
        background-color: #20394a;
        h3{
            color: #fff;
        }
    }
    // &:first-child{
    //     border-radius: 5px 5x 0 0;
    // }
    // &:last-child{
    //     border-radius:  0 0 5px 5px;
    // }

}
.dk-suMMformGroupCon {
    label {
        margin-bottom: 0 !important;
        padding: 7px;
        background: #dddddd;
        color: #333;
    }

    span {
        padding: 4px 7px;
        border-left: 1px solid #cecece;
        font-size: 13px;
        width: 100%;
        display: inline-block;
        background-color: #eaeaea;
    }
}

.dk-job-details-createhtmEditor {
    .ck-editor {
        .ck.ck-toolbar {
            border: 1px solid #ddd;
            border-bottom: none;
        }
    }

    .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
        height: calc(100vh - 251px) !important;
        border: 1px solid #ddd;
        background-color: #fff;

        p {
            font-size: 14px;
        }
    }

    // .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    //     border-color: #ddd;
    // }
}

.dk-job-fileUldimgCon {
    width: 250px;
    height: 91px;
    margin: 0 auto;
    border: 3px dashed lightslategrey;
    display: flex;
    justify-content: center;
    align-items: center;

    #edit_image h3 {
        font-size: 18px;
    }
}

.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}

.toolbar-class {
    border: 1px solid #ccc;
}

.rdw-editor-main {
    min-height: 504px;
    max-height: 504px;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.modal-dialog.dk-livejobrightModalDailog .modal-content .dk-detViewheader .dk-detViewTitle .updateTitleColor:hover {
    color: #eded71;
    cursor: pointer;
}

.edit-header {
    .active {
        border: none !important;
    }

    .nav-link {
        border-bottom: 1px solid #eaeaea !important;
        padding: 8px !important;
    }
}

.d-flex.edit-header.nav.nav-pills .nav-item {
    width: 100%;
    max-width: 15.5%;
}

// END MEDIA QUIRES

.dkg-company-list-mainCon{
    width: 100%;
    max-width: 50%;
    padding: 0 15px;
    margin: 0 auto;
    .dkg-company-list-tableCon{
        margin-top: 6px;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh - 280px);
        padding-bottom: 0;
        position: relative;
        top: 30px;
        .dkg-company-list-table{
            thead{
                tr{
                    th{
                        background: #485569;
                        color: #fff;
                        font-size: 13px;
                        text-align: center;
                        white-space: nowrap;
                        padding: 9px 7px;
                        border: 1px solid #808080;
                        border-bottom: none;
                        vertical-align: middle;
                        position: sticky;
                        top: 0;
                        z-index: 9;

                    }
                }
            }
            tbody{
                tr{
                    td{
                        background: #fff;
                        color: #4d4a4a;
                        font-size: 13px;
                        font-weight: 400;
                        text-align: center;
                        white-space: nowrap;
                        padding: 5px 7px;
                        border: 1px solid #ddd;
                        vertical-align: middle;
                        text-transform: capitalize;
                        .dkg-clientinfo-checkbox {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                input[type=checkbox]:checked {
                                    background-color: #34976e !important;
                                }
                            }

                    }

                }
            }
        }
    }
}
.dkg-jobspec-htmleditor td{
    min-width: 2em !important;
    padding: .4em !important;
}