.dk-invoicesAlerts-main {
    margin-top: 130px;

    .dk-invoicesAlerts-Cols {
        font-family: Roboto, sans-serif !important;
        padding: 0;
        width: 100%;

        @media(min-width:768px) {
            .col-md-2 {
                flex: 0 0 14.666667%;
                max-width: 14.666667%;
                padding-right: 20px;
            }

            .col-md-6 {
                flex: 0 0 49%;
                max-width: 49%;
            }

            .col-md-4 {
                flex: 0 0 36.33%;
                max-width: 36.33%;
                padding-left: 5px;
            }
        }

        .dk-invoicesAlertsLeftPanel {
            background: #eaeaea;
            height: calc(100vh - 130px);
            margin-top: 0;
            overflow-y: auto;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;

                    a {
                        color: #333;
                        display: block;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 10px 12px;
                        position: relative;

                        svg {
                            bottom: 1px;
                            color: #939393;
                            display: inline-block;
                            position: relative;
                            text-align: left;
                            width: 24px;
                        }

                        .dk-notiBadge {
                            background: #2e97b6;
                            color: #fff;
                            display: inline-block;
                            font-size: 13px;
                            height: 16px;
                            line-height: 17px;
                            position: absolute;
                            right: 10px;
                            text-align: center;
                            top: 13px;
                            width: 20px;
                            border-radius: 3px;
                        }
                    }

                    a.active {
                        background: #244158;
                        color: #ddd;
                    }
                }
            }
        }

        .dk-invoicesAlertsLeftPanel::-webkit-scrollbar {
            width: 0px;
        }

        .dk-invoicesAlertsLeftPanel::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
        }

        .dk-invoicesAlertsLeftPanel::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0px solid slategrey;
        }

        .dk-alertsMiddleSec {
            height: calc(100vh - 130px);
            overflow-y: auto;

            .dk-moduleNoti-box.active {
                background: #244158;

                p {
                    color: #ddd;
                }

                .d-timeflex {
                    border-left: 1px solid #1d5989 !important;

                    small {
                        color: #ddd;

                        &:last-child {
                            border-top: 1px solid #1d5989 !important;
                        }

                        svg {
                            color: #ddd !important;
                        }
                    }
                }

                .sbtitle {
                    color: #ddd !important;
                }

                .dk-notificationText {
                    border-left: 1px solid #1d5989 !important;
                }

                .dk-notificationImg {
                    span {
                        color: #fff;
                    }
                }
            }

            .dk-moduleNoti-box {
                align-items: center;
                background: #eaeaea;
                border: 1px solid #ddd;
                border-left: 4px solid #2e97b6;
                cursor: pointer;
                display: flex;
                margin-bottom: 12px;
                padding: 0 0 0 12px;
                transition: all .5s;

                .dk-notificationImg {
                    border-radius: 50%;
                    height: 40px;
                    margin-right: 12px;
                    margin-top: 0;
                    max-width: 40px;
                    width: 100%;

                    img {
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                    }
                }

                .dk-notificationText {
                    align-items: center;
                    border-left: 1px solid #ccc;
                    display: flex;
                    font-weight: 400;
                    justify-content: space-between;
                    padding-left: 15px;
                    width: 100%;

                    .dk-notification-w100 {
                        p {
                            align-items: center;
                            display: flex;
                            font-size: 17px;
                            justify-content: space-between;
                            margin: 0;
                            width: 100%;

                            span {
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                            }
                        }

                        .dk-notification-tags {
                            display: flex;

                            .sbtitle {
                                align-items: center;
                                color: #333;
                                display: flex;
                                font-size: 13px;
                                font-weight: 400;
                                margin-right: 30px;

                                svg {
                                    display: inline-block;
                                    margin-right: 3px;
                                }
                            }
                        }
                    }

                    .d-timeflex {
                        border-left: 1px solid #ccc;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        margin-left: 0;
                        max-width: 120px;
                        min-height: 78px;
                        padding: 0;
                        width: 100%;

                        small {
                            display: inline-block;
                            margin-right: 0;
                            max-width: 100%;
                            padding: 8px 0;
                            text-align: center;
                            width: 100%;
                            font-size: 12px;
                            margin-top: 0;

                            &:first-child {
                                position: relative;
                                top: 3px;
                            }

                            &:last-child {
                                border-bottom: none;
                                border-top: 1px solid #ccc;
                            }
                        }
                    }
                }

                &:hover {
                    background: #244158;
                    transition: all 0.5s;

                    .dk-notificationText {
                        color: #ddd;

                        p {
                            color: #ddd;
                        }

                        .dk-notification-tags {
                            .sbtitle {
                                color: #ddd;
                            }
                        }

                        .d-timeflex {
                            small {
                                svg {
                                    color: #ddd;
                                }
                            }
                        }
                    }
                }
            }
        }

        .dk-alertsMiddleSec::-webkit-scrollbar {
            width: 0px;
        }

        .dk-alertsMiddleSec::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
        }

        .dk-alertsMiddleSec::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0px solid slategrey;
        }

        .dk-moduleNoti-rightPanel {
            color: #ddd;
            font-size: 14px;
            font-weight: 400;
            height: calc(100vh - 172px);
            line-height: 24px;
            margin-top: 0;
            padding: 0;

            .moduleNoti-title {
                background: #244158;
                border-bottom: 1px solid #1d5989 !important;
                color: #ddd;
                font-size: 15px;
                font-weight: 500;
                padding: 8px 12px;
                text-align: center;
                text-transform: uppercase;
            }

            .dk-moduleNoti-body {
                background: #244158;
                height: calc(100vh - 171px);
                overflow-y: hidden;
                padding: 0;

                .dk-moduleBody-text {
                    height: calc(100vh - 490px);
                    overflow-y: auto;
                    padding: 20px;
                }

                .dk-moduleBody-text::-webkit-scrollbar {
                    width: 8px;
                }

                .dk-moduleBody-text::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                .dk-moduleBody-text::-webkit-scrollbar-thumb {
                    background-color: #1e5582;
                    outline: 1px solid #1e5582;
                }

                .dk-commentCategoryMain {
                    .title {
                        background: #2e506c;
                        border-bottom: 1px solid #7f7f7f;
                        display: block;
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 0;
                        padding: 10px 0;
                        position: relative;
                        text-align: center;
                        text-transform: uppercase;
                        width: 100%;

                        button {
                            background: #2e97b6;
                            border: none;
                            border-radius: 3px;
                            color: #fff;
                            display: inline-block;
                            font-weight: 500;
                            outline: none;
                            padding: 3px 12px;
                            position: absolute;
                            right: 8px;
                            text-transform: uppercase;
                            top: 7px;

                            &:hover {
                                background: #244158;
                                color: #fff;
                                transition: all .5s;
                            }
                        }
                    }

                    .dk-commentCategory-cols {
                        .dk-alertsForm-main {
                            padding: 0 20px;

                            .form-group {
                                label {
                                    width: 100%;
                                    display: block;

                                    input {
                                        background: #1e5582;
                                        color: #ccc;
                                        outline: none;
                                        border: 1px solid #174972;
                                        border-radius: 0;
                                        text-align: left;

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }

                                    textarea {
                                        background: #1e5582;
                                        color: #ccc;
                                        outline: none;
                                        border: 1px solid #174972;
                                        border-radius: 0;
                                        height: 90px;
                                        resize: vertical;
                                        text-align: left;

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }

                                    button.saveBtn {
                                        display: inline-block;
                                        padding: 5px 20px;
                                        background: #3279b2;
                                        color: #fff;
                                        text-transform: uppercase;
                                        border-radius: 3px;
                                        border: none;
                                        margin-top: 2px;
                                        outline: none;

                                        &:hover {
                                            background: #1e5582;
                                            transition: all 0.5s;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .dk-commentCategory-cols {
                        background: #2e506c;
                        padding: 10px 0 5px;

                        .dk-categoryComment-img {
                            cursor: pointer;
                            display: inline-block;
                            margin: 0 5px 5px;

                            img {
                                border-radius: 50%;
                                display: inline-block;
                                -webkit-filter: brightness(.8);
                                filter: brightness(.8);
                                height: 40px;
                                width: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}