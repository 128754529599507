.dk-dailyTrackingMain {
    margin-top: 170px;

    .dk-dailyTracker-head {
        position: relative;
        margin-top: 43px;
        margin-bottom: 10px;

        .dk-searchBox {
            position: relative;
            display: flex;
            width: 100%;
            max-width: 190px;



            span.dk-dayCols {
                border: none;
                position: absolute;
                left: 0;
                cursor: pointer;
                width: 30px;
                font-size: 13px;
                background: #f0f0f0;
            }

            input {
                background-color: #d9d9d9 !important;
                border: 1px solid #ccc !important;
                text-align: left;
                padding-left: 30px;
                padding-right: 8px;
                border-radius: 3px 0 0 3px;
                height: 33px;
                box-shadow: none !important;
                font-size: 13px;
                width: 100%;
                max-width: 155px;
            }

            input[type="date"]::-webkit-calendar-picker-indicator {
                position: absolute;
                right: 44px;
            }

            span {
                width: 40px;
                height: 33px;
                border-radius: 0 3px 3px 0;
                background-color: #f0f0f0 !important;
                border: 1px solid #ddd;
                border-left: none;
                text-align: center;
                line-height: 33px;
                cursor: pointer;
            }
        }

        .dk-addNew {
            margin-left: 10px;

            a {
                padding: 0;
                display: inline-block;
                width: 32px;
                height: 32px;
                position: relative;
                top: 0px;
                background-color: #d9d9d9;
                border: 1px solid #ccc;
                border-radius: 0;
                text-align: center;
                line-height: 32px;
                color: #333;
            }
        }

        .dk-dailyTitle {
            font-size: 18px;
            font-weight: 500;
            margin: 0;
            position: relative;
            top: 5px;
            font-family: "Segoe UI", Arial, sans-serif;
            text-align: center;
        }

        .dk-checkInOut-btn {
            a {
                display: inline-block;
                min-width: 112px;
                padding: 7px 10px;
                background: #3a9469;
                color: #fff;
                text-transform: uppercase;
                text-align: center;
                font-size: 11px;
                border-radius: 3px;

                // &:last-child {
                //     background: #dc3545;
                // }
            }

            a.dkg-lunchbg {
                background-color: #cf7c49 !important;

                span.dkg-lunch-icon {
                    svg {
                        position: relative;
                        left: -9px;
                        top: -3px;
                        width: 15px;
                        height: 15px;
                    }

                }

                // &:before {
                //     content: "";
                //     background: url('../../../../assets/images/lunch-white.png');
                //     padding: 0px 12px;
                //     background-repeat: no-repeat;
                //     background-size: 17px;
                //     position: relative;
                //     top: 1px;
                // }
            }

            a.checkOut {
                position: relative;
                padding-right: 13px;
                background-color: #dc3545 !important;

                &::before {
                    content: '';
                    background: url('../../../../assets/images/check-out.png');
                    padding: 0px 12px;
                    background-repeat: no-repeat;
                    background-size: 17px;
                    position: relative;
                    top: 1px;
                    display: none;
                }

                .dkg-lunch-icon {
                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            a.dkg-dayoff-btn {
                background-color: #57496d;
            }
        }
    }

    .dk-dailyTrackingTable {
        padding: 0 15px 0;

        .table-responsive {
            overflow-y: auto;
            height: calc(100vh - 210px);

            table {
                margin-bottom: 0;

                tr {
                    th {
                        background-color: #d9d9d9;
                        border: 1px solid #c5c5c5;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;
                        font-weight: bold;
                        padding: 8px 12px;
                        border-top: none;
                        position: sticky;
                        top: 0;
                        z-index: 10;

                        .dk-userDropdowndaily {
                            .dropdown-toggle {
                                background-color: transparent !important;
                                border-radius: 50%;
                                box-shadow: none !important;
                                color: #333;
                                margin: 0;
                                padding: 0;

                                &::after {
                                    display: none;
                                }
                            }

                            .dropdown-menu {
                                background: #f2f2f2;
                                border-radius: 3px;
                                left: 67px !important;
                                min-width: -webkit-fit-content;
                                min-width: -moz-fit-content;
                                min-width: fit-content;
                                padding: 8px 5px;
                                right: 0 !important;
                                top: 0 !important;
                                -webkit-transform: inherit !important;
                                transform: inherit !important;
                                width: -webkit-fit-content;
                                width: -moz-fit-content;
                                width: fit-content;
                                z-index: 99;

                                &::before {
                                    border-color: transparent #ccc transparent transparent;
                                    _border-color: #000 #ccc #000 #000;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    content: "";
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    height: 0;
                                    left: -12px;
                                    line-height: 0;
                                    position: absolute;
                                    top: 8px;
                                    width: 0;
                                }

                                a {
                                    background-color: transparent;
                                    border: 0;
                                    clear: both;
                                    display: inline-block;
                                    font-weight: 400;
                                    margin: -5px 3px;
                                    padding: 0;
                                    text-align: inherit;
                                    white-space: nowrap;
                                    width: 30px;

                                    img {
                                        border-radius: 50%;
                                        height: 30px;
                                        width: 30px;
                                    }
                                }
                            }
                        }

                        .dk-personImg {
                            img {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                            }
                        }
                    }

                    td {
                        text-align: center;
                        height: 100px;
                        overflow: hidden;
                        background: #f5f5f5;
                        border: 1px solid #c5c5c5;
                        border-bottom: 1px solid #ddd;
                        min-width: 150px;
                        max-width: 150px;
                        vertical-align: middle;
                        font-size: 14px;

                        .dkg-checkicon-Con {
                            width: 100%;
                            text-align: center;
                            display: block;

                            i,
                            svg {
                                width: 17px;
                                height: 17px;
                                margin-bottom: 2px;
                            }
                        }

                        .dkg-cross-icon {
                            svg {
                                width: 15px;
                                height: 15px;
                                color: #a84d4d;
                                cursor: pointer;
                                position: relative;
                                top: -8px;
                                right: -2px;
                                display: none;
                            }
                        }

                        // .dkg-total-hours,
                        // .dkg-total-minutes {
                        //     text-align: justify;

                        //     .hours-text {
                        //         min-width: 90px;
                        //     }

                        //     .minutes-text {
                        //         min-width: 80px;
                        //     }
                        // }
                        &:hover {
                            cursor: pointer;
                            position: relative;

                            .dkg-cross-icon {
                                position: absolute !important;
                                top: 42px;
                                right: 27px;

                                svg {
                                    width: 15px;
                                    height: 15px;
                                    color: #a84d4d;
                                    cursor: pointer;
                                    display: block;
                                }
                            }

                            // .dkg-lunch-start-time {

                            //     .dkg-cross-icon {
                            //         position: absolute !important;
                            //         top: 42px;
                            //         right: -5px !important;
                            //     }
                            // }
                        }

                        &:first-child {
                            min-width: 120px;
                            vertical-align: middle;
                            font-weight: bold;
                        }

                        &:last-child {
                            // min-width: 100px;
                            vertical-align: middle;
                        }

                        &:nth-child(02) {
                            // min-width: 100px;
                            vertical-align: middle;
                        }

                        .dk-personImg {
                            img {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                            }
                        }

                        .inner-wrap-activity {
                            overflow-y: auto;
                            height: 100px;

                            span.tracking {
                                width: 100%;
                                font-size: .7125rem;
                                margin-top: 3px;
                                cursor: pointer;
                                z-index: 999999;
                                padding: 2px 5px;
                                border-radius: 2px;
                                display: block;
                                text-align: left;
                            }

                            nav.react-contextmenu {
                                background: #fff;
                                box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
                                text-align: left;
                                padding: 0;
                                width: 100%;
                                max-width: 170px;
                                margin-top: 8px;

                                .react-contextmenu-item {
                                    display: block;
                                    width: 100%;
                                    padding: 6px 12px;
                                    font-size: 14px;
                                    cursor: pointer;

                                    &:hover {
                                        background: #2980b9;
                                        color: #fff;

                                        i {
                                            color: #fff;
                                        }
                                    }

                                    i {
                                        display: inline-block;
                                        width: 18px;
                                        color: #2980b9;
                                    }
                                }
                            }
                        }

                        .inner-wrap-activity::-webkit-scrollbar {
                            width: 0px;
                        }

                        .inner-wrap-activity::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
                        }

                        .inner-wrap-activity::-webkit-scrollbar-thumb {
                            background-color: darkgrey;
                            outline: 0 solid slategrey;
                        }
                    }
                }

                tbody {
                    tr {
                        &:first-child {
                            td {
                                &:first-child {
                                    background-color: #3a9469 !important;
                                    color: #fff;
                                    min-width: 125px;
                                    max-width: 125px;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        &:nth-child(02) {
                            td {
                                &:first-child {
                                    background-color: #cf7c49 !important;
                                    color: #fff;
                                    min-width: 125px;
                                    max-width: 125px;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        &:nth-child(03) {
                            td {
                                &:first-child {
                                    background-color: #dc3545 !important;
                                    color: #fff;
                                    min-width: 125px;
                                    max-width: 125px;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        // &:nth-child(04) {
                        //     td {
                        //         &:first-child {
                        //             background-color: #57496d !important;
                        //             color: #fff;
                        //             min-width: 125px;
                        //             max-width: 125px;
                        //             text-transform: uppercase;
                        //         }
                        //     }
                        // }

                        &:last-child {
                            td {
                                background-color: #d9d9d9;
                                border: 1px solid #c5c5c5;

                                &:first-child {
                                    background-color: #3c97b6;
                                    min-width: 125px;
                                    max-width: 125px;
                                    text-transform: uppercase;
                                    color: #fff;
                                }
                            }
                        }

                        &.dkg-lunch-tbody-tr {
                            td {
                                &:hover {
                                    .dkg-lunch-start-time {
                                        .dkg-cross-icon {
                                            position: absolute !important;
                                            top: 32px;
                                            right: 3px !important;
                                        }
                                    }

                                    .dkg-lunch-end-time {

                                        .dkg-cross-icon {
                                            display: none;
                                        }
                                    }

                                }
                            }
                        }

                        td {
                            &.dayOffClass {
                                background-color: #57496d !important;
                                color: #fff;
                                border-bottom: 1px solid #57496d !important;

                                .dkg-cross-icon {
                                    svg {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-checkInModal {
    .modal-dialog {
        min-width: 50%;

        .modal-content {
            border-radius: 3px;

            .modal-header {
                background: #3a9469;
                color: #fff;
                padding: 8px 15px;
                border-radius: 2px 2px 0 0;

                .modal-title {
                    font-family: "Segoe UI", Arial, sans-serif;
                    margin: 0;
                    font-size: 1rem;
                    letter-spacing: 0.5px;
                    display: block;
                    text-align: center;
                    width: 100%;
                }

                .close {
                    color: #fff;
                    padding: 0;
                    margin: 0;
                    outline: none;
                    opacity: 1;
                    font-weight: normal;
                    line-height: 15px;
                }
            }

            .modal-body {
                padding: 0;

                form {
                    .dk-checkInForm {
                        padding: 20px 25px 0;
                    }

                    .dk-userFilter {
                        margin-top: 8px;

                        .dropdown-toggle {
                            padding: 0;
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            margin: 0;
                            box-shadow: none;
                            outline: none;

                            &::after {
                                display: none;
                            }

                            img {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                            }
                        }

                        .dropdown-menu {
                            padding: 5px 5px;
                            background: #f2f2f2;
                            border-radius: 3px;
                            -webkit-transform: inherit !important;
                            transform: inherit !important;
                            right: 0 !important;
                            left: 48px !important;
                            top: -5px !important;
                            margin: 0 !important;
                            min-width: -webkit-fit-content;
                            min-width: -moz-fit-content;
                            min-width: fit-content;
                            width: -webkit-fit-content;
                            width: -moz-fit-content;
                            width: fit-content;

                            &::before {
                                content: "";
                                width: 0;
                                height: 0;
                                border-color: transparent #ccc transparent transparent;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                line-height: 0;
                                _border-color: #000 #ccc #000 #000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                position: absolute;
                                left: -12px;
                                top: 12px;
                            }

                            a {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                margin: 0 3px;

                                img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }

                    label {
                        width: 100%;

                        input {
                            border-radius: 0;
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                            height: 35px;
                        }

                        input[type='date'] {
                            color: #000;
                        }

                        input[type='date']:after {
                            color: #000;
                            content: attr(placeholder);
                        }

                    }

                    .dk-checkInOutBtn {
                        text-align: center;
                        padding: 20px 10px;
                        border-top: 1px solid #ddd;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        input {
                            display: inline-block;
                            background: #3a9469;
                            color: #fff;
                            text-transform: uppercase;
                            font-size: 13px;
                            border: none;
                            outline: none;
                            min-width: 120px;
                            text-align: center;
                            padding: 9px 0;
                            border-radius: 3px;
                        }

                        div {
                            div {
                                .dkg-checkout-btn {
                                    background-color: #dc3545 !important;
                                    color: #fff;
                                }

                                .dkg-lunch-btn {
                                    background-color: #cf7c49 !important;
                                    color: #fff;
                                }

                                .dkg-checkin-btn {
                                    background-color: #3a9469 !important;
                                    color: #fff;
                                }
                            }
                        }

                    }

                    .dkg-timepicker-Con {

                        .MuiGrid-root {
                            .MuiFormControl-root {
                                &.MuiFormControl-marginNormal {
                                    margin: 0;
                                }

                                .MuiInputBase-root {
                                    &.MuiInput-root {
                                        &.MuiInput-underline {

                                            &::before,
                                            &::after {
                                                border-bottom: none;
                                            }

                                            &.MuiInputBase-formControl {
                                                &.MuiInput-formControl {
                                                    &.MuiInputBase-adornedEnd {
                                                        .MuiInputBase-input.MuiInput-input {
                                                            border-radius: 0;
                                                            border: 1px solid #ddd;
                                                            box-shadow: none;
                                                            height: 24px;
                                                            padding: 5px 7px;
                                                            font-size: 14px;
                                                        }

                                                        .MuiInputAdornment-root {
                                                            &.MuiInputAdornment-positionEnd {
                                                                margin-left: 0;

                                                                .MuiButtonBase-root {
                                                                    &.MuiIconButton-root {
                                                                        height: 36px;
                                                                        padding: 5px 7px;
                                                                        border: 1px solid #ddd;
                                                                        border-left: none;
                                                                        border-radius: 0;

                                                                        &:focus {
                                                                            outline: 0;
                                                                            box-shadow: none;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-checkInModal.dk-activityModal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                .modal-title {
                    display: block;
                    text-align: center;
                }
            }

            .modal-body {
                .dk-checkInBody {
                    padding: 15px 20px;

                    label {
                        font-size: 13px;
                    }

                    label.activity_time {
                        background: #ffffff;
                        border-radius: 5px;
                        border: 1px solid #dddddd;
                        padding: 7px 10px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.dk-checkInModal.dk-activityModal.dk-addNewActivityModal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                .modal-title {
                    display: block;
                    text-align: center;
                    width: 100%;
                    font-size: 15px;
                }

                .close {
                    line-height: 19px;
                }
            }

            .modal-body {
                padding: 0;

                .dk-checkInBody {
                    padding: 0;
                }

                form {
                    label {
                        select {
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                            border-radius: 0;
                        }

                        textarea {
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                            border-radius: 0;
                            height: 100px;
                            resize: vertical;
                        }
                    }

                    .dk-checkCols {
                        margin-top: 0;
                        margin-bottom: 0;
                        height: 35px;
                        padding: 0 10px;
                        background: #fff;
                        border-radius: 5px;
                        border: 1px solid #ccc;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 8px;
                        position: relative;

                        input {
                            margin-right: 5px;
                        }

                        label {
                            margin: 0 !important;
                        }

                        input[type="radio"] {
                            position: absolute;
                            left: 15px;
                            width: 19px;
                            height: 19px;
                            opacity: 0;
                        }

                        input[type="radio"]+label span {
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: transparent;
                            vertical-align: middle;
                            border: 1px solid #ad9e9eee;
                            border-radius: 50%;
                            padding: 2px;
                            margin: 0 10px 0 5px;
                        }

                        input[type="radio"]:checked+label span {
                            background: #00b050;
                            background-clip: content-box;
                            border: 2px solid #00b050;
                        }
                    }

                    .dk-checkCols.to_do_clr {
                        input[type="radio"]:checked+label span {
                            background: #dc3545;
                            background-clip: content-box;
                            border: 2px solid #dc3545;
                        }
                    }
                }

                .dk-checkInOutBtn {
                    input {
                        background: #26a69a;
                    }
                }

                .noti-onOff {
                    border: none;
                    outline: none;
                    background: transparent;
                    font-size: 32px;
                    cursor: pointer;
                }

            }
        }
    }
}