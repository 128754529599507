.dk-addnewResModal {
    .modal-dialog {
        min-width: 600px;
        max-width: 600px;
        .modal-content {
            background: transparent;

            .dk-addnewResModalHder.modal-header {
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #26597d;
                padding: 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: none;

                .modal-title {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                }

                .close {
                    text-shadow: none;
                    color: #fff;
                    opacity: 1;
                }
            }

            .dk-addnewResModalBdy.modal-body {
                background-color: #fff;
                border-radius: 0 0 5px 5px;

                .form-group {
                    display: flex;
                    align-items: center;

                    label {
                        margin-bottom: 0.75rem;
                        min-width: 125px;
                        font-size: 13px;
                    }

                    .useFilterCon {
                        .dk-resuser-dropdown-toggle {
                            box-shadow: none;
                            background-color: #eaeaea;
                            border-radius: 50%;
                            border: 1px solid #ddd;
                            width: 35px;
                            height: 35px;
                            padding: 0;
                            margin: 0;

                            &::after {
                                display: none;
                            }

                            img {
                                width: 100%;
                                border-radius: 50%;
                            }
                        }

                        .dk-resuserfliter-DropMenu {
                            transform: inherit !important;
                            position: absolute !important;
                            left: 46px !important;
                            top: -7px !important;
                            max-width: 745px;

                            &:after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-color: transparent #ccc transparent transparent;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                line-height: 0;
                                _border-color: #000 #ccc #000 #000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                position: absolute;
                                left: -12px;
                                top: 15px;
                            }

                            .d-flex {
                                .dropdown-item {
                                    width: 35px !important;
                                    height: 35px !important;
                                    padding: 0;
                                    margin: 0 4px;

                                    img {
                                        border-radius: 50%;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .dk-resDropDown {
                        width: 100%;

                        .ui.selection.dropdown {
                            border-radius: 0;

                            .menu {
                                border-radius: 0;
                            }
                        }

                        input.form-control {
                            border-radius: 0;
                            height: 31px;
                            text-align: center;
                            border: 1px solid #c1c9dc;
                        }
                    }
                }

                .dk-sbmitbtnCon {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .dk-sbmitbtn {
                        display: inline;
                        background: #26597d !important;
                        border: none;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 8px 25px;
                        font-weight: 500;
                        font-size: 14px;
                        outline: none;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}