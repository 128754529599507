*{
    margin: 0;
    padding: 0;
}
html,
body {
    font-family: Verdana, sans-serif;
    font-size: 15px;
}
.dk-resnewSummaryCon{
    margin-top: 120px;
}
.dk-resnewSummaryCon .dk-reSLeftheaderCont input{
    max-width: 150px;
    border-radius: 0;
    height: 34px;
}
.dk-resnewSummaryCon .dk-reSLeftheaderCont input.form-control {
    background-color: #f2f2f2;
    border-radius: 0;
    font-size: 0.8rem;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ddd;
}
.dk-resnewSummaryCon .dk-reSLeftheaderCont input.form-control:focus{
    box-shadow: none;
}
.dk-resnewSummaryCon .dk-reSLeftheaderCont input.form-control.dk-topSerchInput{
    max-width: 127px;
    border-right: none;
}
.dk-resnewSummaryCon .dk-reSLeftheaderCont .input-group-append.dk-toplefSerchIcon{
    margin-left: 0;
    height: 34px;
}
.dk-resnewSummaryCon .dk-reSLeftheaderCont .input-group-append.dk-toplefSerchIcon button{
    margin-left: 0;
    margin-right: 0;
    border-left: none;
}
.dk-resnewSummaryCon .dk-reSLeftheaderCont .input-group-append.dk-toplefSerchIcon button:focus{
    outline: none;
}
.dk-resnewSummaryCon .dk-reSLeftheaderCont .dk-reSIcon i{
    color: #333;
    font-size: 24px;
    margin-top: 4px;
}
.dk-resnewSummaryCon .dk-reSCenterheaderCont h2.dk-reSPageTitle{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
}
.dk-resnewSummaryCon .dk-reSRightheaderCont{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.dk-resnewSummaryCon .dk-reSRightheaderCont .dk-resTopRightBtnCont button.dk-reSRightHeaderBtn{
    background: #f2f2f2 !important;
    border: 1px solid #ccc;
    text-transform: uppercase;
    font-size: 14px;
    outline: none;
    color: #333;
    max-width: 150px;
    padding: 0.334rem 0.57rem;
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0
}
.dk-resnewSummaryCon .dk-reSRightheaderCont .dk-resTopRightBtnCont
.dk-reSloginCatSrcFilter .ui.selection.dropdown.dk-overdues-task-slect{
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    color: #333;
}
.dk-resnewSummaryCon .dk-reSRightheaderCont .dk-resTopRightBtnCont
.dk-reSloginCatSrcFilter .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text{
    /* padding: 0.4rem 0.3rem; */
    font-size: 12px;
    color: #000 !important;
}
.dk-resnewSummaryCon .dk-reSRightheaderCont .dk-resTopRightBtnCont
.dk-reSloginCatSrcFilter .ui.selection.dropdown.dk-overdues-task-slect>.dropdown.icon{
    display: none;
}
.dk-resnewSummaryCon .dk-reSRightheaderCont .dk-resTopRightBtnCont
.dk-reSloginCatSrcFilter  .ui.selection.active.dropdown .menu {
    border-color: transparent;
    /* box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%); */
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    border-radius: 0;
}
.ui.selection.dropdown .menu>.item {
    border-top: 1px solid #ccc;
    padding: 0.5rem 0.4rem !important;
}
.dk-resnewSummaryCon .dk-reSRightheaderCont .dk-resTopRightBtnCont
.dk-reSloginCatSrcFilter .ui.dropdown .menu .active.item{
    font-weight: 400;
    background-color: transparent;
}
.dk-reSloginsSelectPicker {
    width: 100%;
}
.dk-reSloginsSelectPicker .ui.selection.dropdown.dk-overdues-task-slect {
    border-radius: 0;
}

.dk-mb-reSourceTableCon table.table.dk-resourceLoginsTable thead  tr th{
    /* border-bottom: none;
    padding: 10px 18px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    position: sticky;
    top: 0;
    z-index: 1;
    font-weight: 600; */
    border-bottom: none;
    padding: 10px 18px;
    background: #234158;
    color: #fff;
    text-align: center;
}
.dk-mb-reSourceTableCon table.table.dk-resourceLoginsTable tbody{
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 18%), 0 8px 15px 0 rgb(0 0 0 / 15%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
}
.dk-mb-reSourceTableCon table.table.dk-resourceLoginsTable tbody  tr td{
    padding: 10px 18px!important;
    white-space: nowrap;
    background-color: #eaeaea!important;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
}
.dk-mb-reSourceTableCon table.table.dk-resourceLoginsTable tbody  tr td:nth-child(01){
    text-align: center;
    width: 40px;
}
.dk-mb-reSourceTableCon table.table.dk-resourceLoginsTable tbody  tr td:nth-child(02){
    text-align: center;
}
.dk-mbpagNationCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.75rem;
}
.dk-mbpagNationCont .dk-mbpagNationleftCont .dk-mbShowNoCntr{
    color: #333;
    font-size: 14px;
    font-weight: 500;
}
.dk-mbpagNationCont .dk-mbpagNationRightCont ul{
    display: flex;
    list-style: none;
    margin-bottom: 0;
}
.dk-mbpagNationCont .dk-mbpagNationRightCont ul li{
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none ;
    background-color: #26597d;
    color: #fff;
    font-weight: 500;
}
.dk-mbpagNationCont .dk-mbpagNationRightCont ul li.currentPage{
    background: #2196f3 !important;
    color: #fff !important;
}
/* START CSS  FOR ADD NEW  MODAL */
.modal-dialog.dk-resourcePopup{
    min-width: 40% ;
}
.modal-dialog.dk-resourcePopup .modal-content{
    background-color: transparent;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-header.dk-resourcePopupHedaer {
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #26597d;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-header.dk-resourcePopupHedaer .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-header.dk-resourcePopupHedaer span {
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
    font-size: 1.35rem;
    font-weight: 600;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody{
    padding: 10px 25px 20px;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group{
    margin-bottom: 0.5rem;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group input.form-control{
    background-color: #fff;
    border-radius: 0;
    font-size: 0.87rem;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ddd;
    text-align: center;
}
.modal-body.dk-resourcePopupBody .form-group .dk-mlreSPopDrop .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
.modal-body.dk-resourcePopupBody .form-group .dk-mlreSPopDrop .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text{
    font-size: 0.87rem;
    color: #495057;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group span button{
    display: inline-block;
    border: none;
    background-color: #26597d;
    color: #fff;
    height: 34px;
    padding: 2px 7px;
    border-radius: 0;
    font-size: 0.89rem;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group span button:focus{
    outline: none;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group input.form-control{
    box-shadow: none;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group span button:hover{
    outline: 0;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}
.dk-resourcePopupbtnCont{
    width: 100%;
    text-align: center;
}
.dk-resourcePopupbtnCont button{
    background: #26597d !important; 
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 25px;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.dk-resourcePopupbtnCont button:hover{
    outline: 0;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}
/* END CSS FOR ADD NEW MOBAL */
/* START CSS FOR REORDER MODAL */
ul.dk-uisortableTable {
    border: 1px solid #ccc;
    border-bottom: none;
    flex-wrap: wrap;
}
ul.dk-uisortableTable:last-child {
    border: 1px solid #ccc;
    border-bottom: none;
}
ul.dk-uisortableTable li {
    padding: 0;
    font-size: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    list-style: none;
}
ul.dk-uisortableTable .d-flex {
    width: 100% !important;
    padding: 0 !important;
}
ul.dk-uisortableTable li div {
    display: flex;
    align-items: center;

}
ul.dk-uisortableTable li div:first-child {
    min-width: 35px;
    padding: 7px 5px;
}
 ul.dk-uisortableTable li div:last-child {
    border-right: none;
}
/* END CSS FOR REORDER MODAL */
/* START CSS FOR JOBPORTALLIST MODAL */
.modal-dialog.dk-resourceReportPopup {
    min-width: 95%;
    max-width: 95%;
}
.modal-dialog.dk-resourceReportPopup .modal-content .modal-header.dk-resReportPopupHedaer {
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #26597d;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.modal-dialog.dk-resourceReportPopup .modal-content .modal-header.dk-resReportPopupHedaer .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}
.modal-dialog.dk-resourceReportPopup .modal-content
.dk-resourceReportPopupBody{
    background-color: #f2f2f2;
}
.modal-dialog.dk-resourceReportPopup .modal-content
.dk-resourceReportPopupBody .dk-jobportListCont{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.modal-dialog.dk-resourceReportPopup .modal-content
.dk-resourceReportPopupBody .dk-jobportListCont .dk-jobportcols{
    min-width: 210px;
    margin-right: 15px;
    background-color: #fff;
    height: 330px;
}
.modal-dialog.dk-resourceReportPopup .modal-content
.dk-resourceReportPopupBody .dk-jobportListCont .dk-jobportcols
.dk-jobportalHead h5{
    width: 100%;
    border: solid #fff 1px;
    background: #333f50;
    color: #fff;
    text-align: center;
    padding: 3px 5px;
    font-size: 13px;
}
.dk-resourceReportPopupBody .dk-jobportListCont .dk-jobportcols
.dk-jobportalBox.PortalListing li{ 
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 2px 5px;
    font-size: 14px;
}
.dk-resourceReportPopupBody .dk-jobportListCont .dk-jobportcols
.dk-jobportalBox.PortalListing li span{
    background: #333f50;
    text-align: center;
    color: #fff;
    box-shadow: 3px 3px 2px #ccc;
    border-radius: 5px;
    font-size: 12px;
    padding: 2px;
}
/* .modal-dialog.dk-resourceReportPopup .modal-content{
    background-color: transparent;
} */
.modal-dialog.dk-resourceReportPopup .modal-content .modal-header.dk-resReportPopupHedaer span {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.dk-IconCont img{
    width: 28px;
    height: 28px;
}
/* END CSS FOR JOBPORTAL LIST MODAL */
/* START CSS FOR ML RESOURCE TABLE */
.dk-MLreSourceTableCon table.table.dk-mlreSTable{
    margin-bottom: 0;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th {
    border-bottom: none;
    padding: 10px 18px;
    background: #234158;
    color: #fff;
    text-align: center;
    vertical-align: middle;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable tbody tr td {
    padding: 6px 18px !important;
    white-space: nowrap;
    background-color: #f9f9f9;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th:nth-child(02),
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th:nth-child(03){
    min-width: 90px;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th:nth-child(05){
    min-width: 120px;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th:nth-child(07),
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th:nth-child(08),
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th:nth-child(09),
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th:nth-child(10),
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead tr th:nth-child(11){
    min-width: 140px;
}
/* .dk-MLreSourceTableCon table.table.dk-mlreSTable tbody tr td:nth-child(06){
    text-align: left;
    min-width: 180px;
} */
.dk-MLreSourceTableCon table.table.dk-mlreSTable tbody tr td:nth-child(03) i{
    font-size: 18px;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable tbody tr td .dk-resAction {
    display: flex;
    justify-content: center;
}
.mlResCheckBox{
    position: relative;
    margin-left: 5px;
    margin-right: 20px;
    display: none;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable tbody tr td img.person-imageFlex {
    min-height: 22px ;
    border-radius: 50% ;
    height: 30px ;
    width: 30px ;
}
span.mlResCheckBox input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background: #e4e8ec;
    border-radius: 1px;
    box-sizing: border-box;
    position: relative;
    box-sizing: content-box;
    width: 12px;
    height: 12px;
    border-width: 0;
    transition: all 0.3s linear;
    position: absolute;
    border: solid #ccc 1px;
    margin-top: 4px;
}
/* END CSS FOR ML RESOURCE TABLE */
.dk-mb-reSourceTableCon table.table.dk-resourceLoginsTable tbody tr td .dk-resAction {
    display: flex;
    justify-content: center;
}
.dk-resAction  a.dk-view-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    border-radius: 3px;
    background-color: #2196f3;
    color: #fff;

}
.dk-resAction  a.dk-edit-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ccc;
    line-height: 30px;
    font-size: 16px;
    border-radius: 3px;  
    background-color: #fee69c; 
    color: #333;
}
.dk-resAction  a.dk-delete-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ccc;
    line-height: 30px;
    font-size: 16px;
    border-radius: 3px;
    background-color: #dc3545;
    color: #fff;
}
/* START CSS FOR RESOURCE SUMMARY PAGE */
.dk-reSummTwoBox .dk-reSummTwoCols{
    width: 100%;
    max-width: 100%;
    max-height: 140px;
    margin: 0 8px;
}
.dk-reSummTwoBox .dk-reSummTwoCols .dk-reSummTwoHeader h5{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background: #eaeaea;
    border: 1px solid #dadada;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
}
.dk-reSMMainContent{
    margin-top: 1.15rem;
    height: calc(100vh - 165px);
    overflow: auto;
    overflow-y: hidden; 
    overflow-x: hidden;
}
.dk-reSummTwoBox .dk-reSummTwoCols .dk-reSummTwoContent li{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #eaeaea;
    padding: 5px 10px;
    border-bottom: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    font-size: 14px;
}
.dk-reSummTwoBox .dk-reSummTwoCols .dk-reSummTwoContent li:last-child{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.dk-reSummTwoBox .dk-reSummTwoCols .dk-reSummTwoContent li div
{
    font-size: 13px;
}
.dk-reSummTwoBox .dk-reSummTwoCols .dk-reSummTwoContent li span {
    display: inline-block;
    width: 35px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    color: #2c71b2;
    border-radius: 3px;
    font-size: 13px;
}
.dk-reSummThreBox .dk-reSummThreCols{
    width: 100%;
    max-width: 100%;
    margin: 0 8px;
}
.dk-reSummThreHeader h5{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background: #eaeaea;
    border: 1px solid #dadada;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
}
.dk-reSummTreContent{
    min-height: 356px;
    max-height: 356px;
    overflow-y: auto;
    background: #eaeaea;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.dk-reSuMMTwoLeftScrollCont {
    overflow-y: auto;
    max-height: 400px;
    background: #eaeaea;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.dk-reSummTreContent li{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #eaeaea;
    padding: 5px 10px;
    border-bottom: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    font-size: 13px;
}
.dk-reSummTreContent li:last-child{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.dk-reSummTreContent li div{
    font-size: 13px;
}
.dk-reSummTreContent li span {
    display: inline-block;
    width: inherit !important;
    height: 25px;
    text-align: center;
    line-height: 25px;
    color: #2c71b2;
    border-radius: 3px;
    font-size: 13px;
}
.dk-reSHeaderCont78{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eaeaea;
    padding: 5px 10px;
    border: 1px solid #dadada;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #6c757d;
    text-transform: uppercase;
}
.dk-reSSearchIoFilter .SeachIoCon .form-group{
    margin-bottom: 0;
}
.dk-reSSearchIoFilter .SeachIoCon .form-group input {
    background: #fff;
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;
    display: block;
    height: 30px;
    padding: 0 15px;
    color: #333;
    border-radius: 0;
}
.dk-reSSearchIoFilter .SeachIoCon .form-group input.form-control:focus{
    border-color: #009688;
    box-shadow: none;
    border-top: transparent ;
}
.dk-reSummTwoBox .dk-reSummTwoCols {
    position: relative;
    width: 100%;
}
.dk-reSSearchIoFilter {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 999;
    width: 100%;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    border: 1px solid #dadada;
}
.dk-reSummThreBox .dk-reSummThreCols {
    position: relative;
    width: 100%;
}
.dk-reSSearchIoFilter {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 999;
    width: 100%;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    border: 1px solid #dadada;
}
.dk-reSSearchIoFilter .dk-FilterItems{
    list-style: none;
    margin-bottom: 0;
}
.dk-reSSearchIoFilter .dk-FilterItems li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    background: #fff ;
    min-height: 28px;
}
.dk-reSSearchIoFilter .dk-FilterItems li .leftFilterText{
    font-size: 13px;
    color: #333;
    font-weight: 400;
}
.dk-reSSearchIoFilter .dk-FilterItems li span{
    padding-left: 0.5em;
    background: #3a506b;
    color: #fff !important;
    padding: 2px 5px;
    min-width: 30px;
    text-align: center;
    font-size: 11px;
    position: absolute;
    right: 15px;
}
.dk-levelDropDwn div .dk-mySaleheader .saleskpi-yearDropdown .ui.selection.dropdown.dk-overdues-task-slect{
    border-radius: 5px !important;
    height: 30px;
}
.dk-filterIcon{
    cursor: pointer;
}
/* END CSS FOR  RESOURCE SUMMARY PAGE */
.dk-reSLeftheaderCont button,
.dk-reSLeftheaderCont button:hover{
    padding: 0.49rem 0.65rem !important;
    margin-top: 0;
    background-color: #f2f2f2 !important;
    color: #333;
    border: 1px solid #ccc;
    box-shadow: none;
    margin-bottom: 0;
}
.dk-resDelPopup.modal-dialog{
    min-width: 30% ;
}
.dk-resDelPopup.modal-dialog .modal-content {
    border-radius: 5px;
}
.dk-resDelPopup .modal-body.dk-resDelModalBody {
    padding: 35px 15px 22px;
}
.dk-resDelPopup .modal-body.dk-resDelModalBody p{
    color: #e2445b;
}
.dk-resDelPopup.modal-dialog .modal-content button.dk-reSyesBtn{
    background-color: #e2445b !important;
    border: 1px solid #e2445b;
    color: #fff;
    display: inline-block;
    width: 100%;
    max-width: 90px;
    border-radius: 5px;
    padding: 7px 0;
}
.dk-resDelPopup.modal-dialog .modal-content .modal-footer{
    border-top: transparent !important;

}
.dk-resDelPopup.modal-dialog .modal-content .modal-footer button.dk-reSnoBtn{
    border: 1px solid #333;
    color: #333 !important;
    background-color: transparent !important;
    display: inline-block;
    width: 100%;
    max-width: 90px;
    border-radius: 5px;
    padding: 7px 0;
}
.dk-reSPopUpIcon{
    align-items: center;
    background-color: #f5f2f2;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 38px;
    justify-content: center;
    width: 38px;

}
.dk-reSPopUpIcon i{
    color: #333;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group{
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group.dk-imgRowDiv .dk-flagUploadFilter{
    padding-top: 2rem;

}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group label{
    min-width: 160px;
    font-size: 14px;
    margin-bottom: 0;
    text-align: left !important;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group label.inFlagelabel{
    min-width: 60px;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group .dk-mlreSPopDrop{
    width: 100%;
}
.modal-body.dk-resourcePopupBody .form-group .dk-mlreSPopDrop .ui.selection.dropdown.dk-overdues-task-slect{
    border-radius: 0;
    background-color: #fff;
    font-size: 0.85rem;
    height: 31px;
}

.modal-dialog.dk-resourcePopup .modal-content .dk-resourcePopupHedaer.reSheader{
    background-color: #fee69c;
}
.modal-dialog.dk-resourcePopup .modal-content .dk-resourcePopupHedaer.reSViewHeader{
    background-color: #2196f3;
}
.modal-dialog.dk-resourcePopup .modal-content .dk-resourcePopupHedaer.reSheader h4.modal-title{
    color: #333;
}
.dk-mlreSPopDrop .ui.selection.dropdown .menu>.item {
    border-top: 1px solid #ddd;
}
/* START CSS FOR ICOME TAX  PAGE AND TABLE */
.dk-incomeTaxTableCon{
    /* box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%); */
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable thead tr th {
    border-bottom: none;
    padding: 10px 18px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable tbody {
    box-shadow: 0px 0px 6px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
    box-shadow: 0px 0px 3px 0 rgb(0 0 0 / 18%), 0 8px 15px 0 rgb(0 0 0 / 15%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable tbody tr:first-child td{
    border-top: transparent;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable tbody tr:first-child{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable tbody tr td {
    padding: 10px 18px !important;
    white-space: nowrap;
    background-color: #EAEAEA !important;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable tbody tr:last-child{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable tbody tr td .dk-resAction {
    display: flex;
    justify-content: center;
}
.dk-incomeTableImg {
    min-height: 22px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable thead tr th:nth-child(07) {
    /* text-align: left; */
    min-width: 135px;
}
/* .dk-incomeTaxTableCon table.table.dkreSLoginTable thead tr th:nth-child(05){

} */
.dk-incomeTaxTableCon.table-responsive.mt-2 {
    box-shadow: none;
}

.dk-inComeTableIcn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.dk-inComeTableIcn img{
    margin-right: 1rem;
    height: 40px;
    width: 40px;
}
a.dk-calCulateBtn, a.dk-calCulateBtn:hover {
    display: inline-block;
    padding: 0.35rem 0.75rem;
    background-color: #1b6867;
    color: #fff !important;
    border-radius: 5px;
}
.dk-reScricleIcon{
    align-items: center;
    background-color: #eaeaea;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    display: flex;
    flex-shrink: 0;
    height: 35px;
    justify-content: center;
    width: 35px;
}
.dk-reScricleIcon i{
    color: #333;
}
.dk-resumeDownload {
    text-align: center;
}
.dk-resumeDownload {
    width: 100%;
    max-width: 120px;
    margin-left: 40px;
    position: relative;
    overflow: hidden;
}
.dk-resumeDownload input {
    width: 100%;
    height: 110px;
    max-width: 150px;
    position: relative;
    z-index: 9;
    opacity: 0;
    cursor: pointer;
}
.dk-resumeDownload span {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
}
.dk-resumeDownload img {
    width: 100%;
    max-width: 80px;
    height: auto;
}
.dk-reSPopUpIcon{
    position: relative;
}
.dk-flagPopCon {
    position: absolute;
    left: 215px !important;
    right: inherit !important;
    top: -47px !important;
    z-index: 1000;
    float: left;
    /* min-width: 11.25rem; */
    will-change: transform;
    transform: translate3d(0px, 40px, 0px);
    font-size: .8125rem;
    color: #333;
    text-align: left;
    list-style: none;
    border-radius: .1875rem;
    padding: 6px 8px;
    margin: 0 !important;
    box-shadow: none;
    border: 1px solid #ddd;
    background: #f2f2f2;
    min-height: 49px;
    min-width: 50px;
}
.dk-flagPopCon::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8.5px 12px 8.5px 0;
    border-color: transparent #cccccc transparent transparent;
    line-height: 0px;
    _border-color: #000000 #cccccc #000000 #000000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    position: absolute;
    left: -12px;
    top: 15px;
}
.dk-inflagList{
    margin: 0 3px;
}
.dk-inflagList img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.modal-dialog.dk-resourcePopup .modal-content .dk-resourcePopupHedaer.inComHeader{
    background-color: #2196f3;
    color: #fff;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-header.dk-resourcePopupHedaer.inComHeader .modal-title{
    color: #fff;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group.dk-incomeCountryGroup{
    align-items: center;
    display: block;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group.dk-incomeCountryGroup label{
    margin-bottom: 1rem;
}
.modal-dialog.dk-resourcePopup .modal-content .modal-body.dk-resourcePopupBody .form-group label.dk-mlmangerLabel{
    min-width: 155px;
}
.dk-flagEditImge img{
    width: 100px;
    height: 100px;
    padding-left: 1.5rem;
}
.dk-flagEditImge button,
.dk-flagEditImge button:hover {
    border: navajowhite;
    display: inline-block;
    padding: 0.35rem 0.5rem;
    background-color: #1b6867;
    color: #fff !important;
    border-radius: 5px;
    width: 130px;
}
.dk-flagEditImge button:focus{
    outline: 0;
  }
/* END CSS FOR  INCOME TAX  PAGE AND TABLE */
.dk-resSummaryCon .dk-reSCenterheaderCont h2.dk-reSPageTitle {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
}
.dk-levelDropDwn .dropdown .dropdown-menu{
    padding: 0;
}
.dk-levelDropDwn .dropdown .dropdown-menu div .dropdown button.dropdown-item {
    margin: 0;
}
.dk-levelDropDwn .dropdown .dropdown-menu button {
    margin: 0;
}
.dk-MLreSourceTableCon  { 
    overflow-y: auto;
    height: calc(100vh - 180px);
    padding-bottom: 60px;
    /* margin-bottom: 60px; */
}
.dk-mb-reSourceTableCon { 
    overflow-y: auto;
    height: 100vh;
    max-height: 75vh;
    padding-bottom: 60px;
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
/* .dk-MLreSourceTableCon table.table.dk-mlreSTable{
    position: fixed;
} */
.dk-MLreSourceTableCon table.table.dk-mlreSTable thead th {
    position: sticky; 
    top: 0;
    z-index: 1;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable tbody tr {
    /* position: sticky; */
    left: 0;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable tbody{
    margin-bottom: 50px;
}
.dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect{
    border-radius: 0;
    background-color: #eaeaea;
    color: #333;
}
.dk-reSLoginFilter .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
.dk-reSLoginFilter .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text{
    color: #333;
}
.dk-reSLoginFilter .ui.selection.dropdown .menu>.item {
    border-top: 1px solid #ccc;
    padding: 0.5rem 0.4rem !important;
    /* background-color: #eaeaea !important; */
}
.dk-incomeTaxTableCon{
    overflow-y: auto;
    height: 100vh;
    max-height: 75vh;
    padding-bottom: 60px;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable thead th {
    position: sticky; 
    top: 0; z-index: 1;
}
.dk-incomeTaxTableCon table.table.dk-inComTaxreSTable tbody th {
    position: sticky;
    left: 0;
}
/* Just common table stuff. Really. */
.dk-MLreSourceTableCon table.table.dk-mlreSTable {
    border-collapse: collapse;
    width: 100%; 
    }
.dk-MLreSourceTableCon table.table.dk-mlreSTable th,.dk-MLreSourceTableCon table.table.dk-mlreSTable td { 
    padding: 8px 16px;
}
.dk-MLreSourceTableCon table.table.dk-mlreSTable th{
     background:#eee;
}
.form-group.dk-imgRowDiv {
    padding-bottom: 1.5rem;
}
.form-group.dk-imgRowDiv .dk-flagUploadFilter .dk-imgincFlagCon button{
    margin-top: 1.5rem;
}
.dk-reSRightheaderCont .dk-resTopRightBtnCont .dk-reSLoginFilter .ui.dropdown>.clear.icon{
    position: absolute;
    top: 15px;
    right: 13px;
    background-color: #000;
    width: 10px;
    height: 10px;
    font-size: 8px;
    border-radius: 50%;
}
.dk-reSRightheaderCont .dk-resTopRightBtnCont .dk-reSLoginFilter .ui.dropdown>.clear.icon:before {
    content: "\f00d";
    color: #fff;
    margin-top: 14px;
    position: relative;
    top: -4px;
    left: -3px;
}
.webLinkBtn a.dk-resGoToUrlbtn, .webLinkBtn a.dk-resGoToUrlbtn:hover {
    display: inline-block;
    padding: 0.35rem 0.75rem;
    background-color: #1b6867;
    color: #fff !important;
    border-radius: 5px;
}
/* START CSS FOR RESOURCE LOGIN TABLE */
.dk-ResloginTableCon{
    overflow-y: auto;
    height: 100vh;
    max-height: 75vh;
    padding-bottom: 60px;
}
.dk-ResloginTableCon {
    /* box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%); */
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.dk-ResloginTableCon table.table.dk-ResloginTable thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th {
    border-bottom: none;
    padding: 10px 18px;
    background: #234158;
    color: #fff;
    text-align: center;
    vertical-align: middle;
}
.dk-ResloginTableCon table.table.dk-ResloginTable tbody{
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}
.dk-ResloginTableCon table.table.dk-ResloginTable tbody tr:first-child td {
    border-top: transparent;
}
.dk-ResloginTableCon table.table.dk-ResloginTable tbody tr td {
    padding: 10px 18px !important;
    white-space: nowrap;
    background-color: #EAEAEA !important;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
}
.dk-ResloginTableCon table.table.dk-ResloginTable tbody tr td .dk-resAction {
    display: flex;
    justify-content: center;
}
/* }
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(01){
    width: 60px;
}
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(02){
    width: 120px;
}
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(2) {
    width: 6%;
} */
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(2) {
    min-width: 120px;
}
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(3),
.dk-ResloginTableCon table.table.dk-ResloginTable tbody tr td:nth-child(3),
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(4),
.dk-ResloginTableCon table.table.dk-ResloginTable tbody tr td:nth-child(4),
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(5),
.dk-ResloginTableCon table.table.dk-ResloginTable tbody tr td:nth-child(5),
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(7),
.dk-ResloginTableCon table.table.dk-ResloginTable tbody tr td:nth-child(7) {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} 
.dk-ResloginTableCon table.table.dk-ResloginTable thead tr th:nth-child(6),
.dk-ResloginTableCon table.table.dk-ResloginTable tbody tr td:nth-child(6) {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} 
.btn-secondary.btn.Ripple-parent.makeStyles-tblDdlActive-11{
    min-width: 90px;
    background-color: #31a886 !important;
}
/* END CSS FOR RESOUCE LOGIN TABLE */

.dk-faqCollapse-main .MuiCollapse-wrapper .MuiCollapse-wrapperInner{
    background:#38607c;
    color: #fff;
}

.dk-faqCollapse-main .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiAccordionDetails-root{
    padding: 12px 15px;
}