.dk-sendouts-containerMain {
    margin-top: 170px;
    display: flex;

    .dk-sendoutsLeftpanel {
        background: #eaeaea;
        height: calc(100vh - 171px);
        margin-right: 0;
        margin-top: 0;
        max-width: 170px;
        overflow-y: auto;
        padding: 0 !important;
        width: 100%;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                width: 100%;

                &:last-child {
                    a {
                        border-bottom: none;
                    }
                }

                a {
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    color: #000;
                    font-size: 12px;
                    line-height: 15px;
                    margin: 0;
                    border-bottom: 1px solid #ccc;
                    padding: 10px 12px !important;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 400;

                    .dk-badgeNo {
                        display: inline-block;
                        background: #316a67;
                        width: 20px;
                        font-size: 11px;
                        text-align: center;
                        border-radius: 3px;
                        padding: 2px 0 1px;
                        color: #fff;
                    }
                }

                a.active {
                    background: #374252;
                    color: #fff;
                }
            }
        }
    }

    .dk-sendouts-rightPanel {
        background: #374252;
        margin-top: 0;
        padding: 20px;
        width: 100%;

        .dk-sendoutsHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 18px;

            .dk-sendoutCaseSt-rightPanel {
                display: flex;
                align-items: center;

                button.dk-filterBtn {
                    border: 1px solid #7d7d87;
                    background: transparent;
                    margin-right: 10px;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;
                    min-width: 35px;
                    outline: none;

                    &:hover {
                        background: #7d7d87;
                        transition: all 0.5s;
                        color: #374252;
                    }
                }

                .dropdown {
                    .dropdown-toggle {
                        margin: 0;
                        border-radius: 3px;
                        background: #2e75b6 !important;
                        color: #fff !important;
                        font-size: 13px;
                        box-shadow: none;
                        font-family: "Roboto", sans-serif !important;
                        padding: 7px 10px;
                        min-width: 110px;

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        transform: inherit !important;
                        min-width: 110px;
                        border-radius: 0;
                        background: #fff;
                        border: none;
                        padding: 0;
                        margin: 35px 0 0 !important;
                        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
                        z-index: 99999;

                        a {
                            padding: 6px 10px;
                            font-size: 13px;
                        }

                        a.active {
                            font-size: 14px;
                            background: #2e75b6;
                        }
                    }
                }
            }

            .dk-sendouts-yearsDropdown {
                display: flex;
                align-items: center;

                .dk-searchBox {
                    max-width: 190px;
                }

                .addMonthBtn {
                    border: 1px solid #7d7d87;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;
                    min-width: 35px;

                    &:hover {
                        background: #7d7d87;
                        transition: all 0.5s;
                        color: #374252;
                    }
                }

                .dropdown-toggle {
                    background: #3c97b6 !important;
                    border-radius: 3px;
                    box-shadow: none;
                    color: #fff !important;
                    font-size: 13px;
                    margin: 0;
                    min-width: 110px;
                    padding: 7px 10px;
                    z-index: 99999;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    background: #fff;
                    border: none;
                    border-radius: 0;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    margin: 35px 0 0 !important;
                    min-width: 110px;
                    padding: 0;
                    -webkit-transform: inherit !important;
                    transform: inherit !important;
                    z-index: 99999;

                    a {
                        padding: 6px 10px;
                        font-size: 13px;

                        &:hover {
                            background: #f8f9fa;
                        }
                    }

                    a.active {
                        background: #3c97b6;
                    }
                }
            }

            .dk-sendouts-months {
                display: flex;
                justify-content: flex-end;
                margin-left: 0;
                width: 100%;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;

                    li {
                        display: inline-block;
                        list-style-type: none;

                        &:first-child {
                            a {
                                border-left: 1px solid #616167;
                            }
                        }

                        a {
                            background: #4a5057;
                            border-bottom: 1px solid #616167;
                            border-right: 1px solid #616167;
                            border-top: 1px solid #616167;
                            color: #fff;
                            cursor: pointer;
                            float: left;
                            height: 35px;
                            padding: 7px 10px;
                            text-align: center;
                            width: 75px;
                        }

                        a.active {
                            background: #3c97b6;
                        }

                    }
                }

                .addMonthBtn {
                    border: 1px solid #7d7d87;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;

                    &:hover {
                        background: #7d7d87;
                        color: #fff;
                        transition: all .5s;
                    }
                }
            }
        }


    }

    .dk-sendouts-rightPanel.dk-sendoutsCaseStatus-main {
        .dk-sendoutsHead {
            .dk-sendouts-yearsDropdown {
                .dk-searchBox {
                    max-width: 170px;
                }
            }

            .dk-sendoutCaseSt-rightPanel {
                .dropdown {
                    .dropdown-toggle {
                        background: #316a67 !important;
                    }
                }
            }
        }

        .dk-salesSendouts-table {
            table {
                tr {
                    th {
                        background: #316a67 !important;
                    }

                    td {
                        .dk-status {
                            background: #316a67;
                        }

                        &:nth-child(03) {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .dk-salesKpiContainer {
        .dk-yearBox.dropdown {
            .dropdown-toggle {
                margin: 0;
                border-radius: 3px;
                background: #316a67 !important;
            }

            .dropdown-menu {
                z-index: 99999;

                a.active {
                    background: #316a67;
                }
            }
        }

        a.addMonthBtn {
            border: 1px solid #7d7d87;
            border-radius: 50%;
            color: #7d7d87;
            display: inline-block;
            font-size: 15px;
            height: 35px;
            line-height: 33px;
            margin-left: 12px;
            text-align: center;
            width: 35px;

            &:hover {
                background: #7d7d87;
                color: #fff;
                transition: all .5s;
            }
        }

        .monthBoxes {
            ul {
                li {
                    a {
                        &.active {
                            background: #316a67 !important;
                        }
                    }
                }
            }
        }
    }

    .dk-salesKpiContainer {
        .dk-salesSendouts-table {
            margin-top: 8px;
            margin-bottom: 0;
            height: calc(100vh - 258px) !important;
            overflow-y: auto;

            table {
                border: none;
                margin-bottom: 0;

                tr {
                    th {
                        background: #316a67 !important;
                        border: 1px solid #a0a0a0 !important;
                        border-bottom: none;
                        color: #fff;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 10px 12px;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;
                        position: sticky;
                        top: 0;
                        z-index: 9999;
                    }

                    td.cursor-p {
                        cursor: pointer;
                    }

                    td {
                        background: #fff;
                        border: 1px solid #ddd;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 3px 8px;
                        position: relative;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        .dk-smsTableCheckbox {
                            width: 35px;
                            margin: auto;
                        }

                        &:nth-child(01) {
                            width: 5%;
                        }

                        &:nth-child(02) {
                            width: 7% !important;
                        }

                        &:nth-child(02),
                        &:nth-child(03),
                        &:nth-child(04),
                        &:nth-child(05),
                        &:nth-child(06),
                        &:nth-child(07),
                        &:nth-child(07) {
                            width: 12.5%;
                        }

                        .dk-smsTableCheckbox {
                            .dk-chkSendouts {
                                margin-left: 10px;
                            }
                        }

                        .dk-text-red {
                            color: #ff3547;
                        }

                        .dk-text-green {
                            color: #00c851;
                        }


                        input.dk-chkSendouts {
                            -webkit-appearance: none;
                            appearance: none;
                            background: #e4e8ec;
                            border: 1px solid #ccc;
                            border-radius: 0;
                            box-sizing: border-box;
                            box-sizing: content-box;
                            cursor: pointer;
                            height: 12px;
                            position: relative;
                            transition: all .3s linear;
                            width: 12px;
                        }

                        .dk-sales-updatedUser {
                            position: relative;

                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                            }

                            &:hover {
                                .tooltipText {
                                    display: inline-block;
                                }
                            }
                        }

                        .dk-salesSendouts-users {
                            .dropdown-toggle {
                                background-color: transparent !important;
                                border-radius: 50%;
                                box-shadow: none !important;
                                color: #333;
                                margin: 0;
                                padding: 0;

                                &::after {
                                    display: none;
                                }

                                img {
                                    border-radius: 50%;
                                    left: 0;
                                    position: relative;
                                    top: 0;
                                    width: 35px;
                                }
                            }

                            .dropdown-menu {
                                background: #f2f2f2;
                                border-radius: 3px;
                                left: 48px !important;
                                min-width: -webkit-fit-content;
                                min-width: -moz-fit-content;
                                min-width: fit-content;
                                padding: 8px 5px;
                                right: 0 !important;
                                top: 0 !important;
                                -webkit-transform: inherit !important;
                                transform: inherit !important;
                                width: -webkit-fit-content;
                                width: -moz-fit-content;
                                width: fit-content;

                                &::before {
                                    border-color: transparent #ccc transparent transparent;
                                    _border-color: #000 #ccc #000 #000;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    content: "";
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    height: 0;
                                    left: -12px;
                                    line-height: 0;
                                    position: absolute;
                                    top: 8px;
                                    width: 0;
                                }

                                a {
                                    display: inline-block;
                                    margin: -5px 3px;
                                    padding: 0;
                                    width: 30px;
                                    background-color: transparent;
                                    border: 0;
                                    clear: both;
                                    font-weight: 400;
                                    text-align: inherit;
                                    white-space: nowrap;

                                    img {
                                        border-radius: 50%;
                                        height: 30px;
                                        width: 30px;
                                    }
                                }

                            }
                        }
                    }
                }

                tfoot {
                    .sumtr {
                        td.transparent {
                            border: none;
                        }

                        td {
                            background: transparent;
                            color: #fff;
                            padding: 10px 8px;
                            border-color: #a9a9a9;
                        }
                    }
                }
            }
        }
    }
    &.dkg-jobs-demand-mainCon{
        margin-top: 160px;
        .dk-sendouts-rightPanel{
            background-color: transparent !important;
            padding: 0 15px !important;
            .dk-sendoutsHead{
                margin-top: 0;
            }
        }
    }
}

.tooltipText {
    display: none;
    background: #333;
    border-radius: 3px;
    color: #fff;
    display: none;
    font-size: 11px;
    padding: 2px 8px;
    text-transform: capitalize;
    top: 42px;
    white-space: nowrap;
    z-index: 99;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);

    &::after {
        border-color: transparent transparent #333;
        _border-color: #000 #000 #333 #000;
        border-style: solid;
        border-width: 0 7.5px 12px;
        content: "";
        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
        height: 0;
        line-height: 0px;
        top: -11px;
        width: 0;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
}

.dk-sendoutFilterModal {
    .modal-dialog {
        margin: 0 !important;
        max-width: 430px;
        min-width: 430px;
        position: fixed;
        right: 0;
        top: 0;
        -webkit-transform: inherit !important;
        transform: inherit !important;
        transition: opacity .3s linear, right .3s ease-out;
        z-index: 9999;

        .modal-content {
            border: none;
            border-radius: 0;
            height: 100vh;

            .modal-header {
                background: #2e97b6;
                border-bottom: none;
                color: #fff;
                padding: 8px 0;
                position: relative;
                text-align: center;
                width: 100%;

                .modal-title {
                    font-size: 16px;
                    font-weight: 500;
                    margin: 0;
                    text-align: center;
                    width: 100%;
                }

                .close {
                    color: #fff;
                    height: 30px;
                    margin: 0;
                    opacity: 1;
                    opacity: .9;
                    padding: 0;
                    position: absolute;
                    right: 3px;
                    text-shadow: none;
                    top: 5px;
                    width: 30px;
                }
            }

            .modal-body {
                background-color: #234158;
                height: calc(100vh - 235px);
                overflow-y: auto;
                padding: 0;

                .dk-sendoutFilterTabs-main {
                    display: flex;

                    .dk-sendoutTabs-nav {
                        background-color: #eaeaea;
                        height: inherit;
                        max-width: 170px;
                        min-width: 170px;
                        height: calc(100vh - 40px);

                        .nav-item {
                            background-color: #eaeaea;
                            border-bottom: 1px solid #ddd;
                            max-width: 170px;
                            width: 100%;

                            a {
                                border-radius: 0;
                                color: #333;
                                font-size: .95rem;
                                padding: 10px 12px;
                                text-transform: uppercase;
                            }

                            a.active {
                                background-color: #234158;
                                color: #ddd;
                            }
                        }
                    }

                    .dk-sendoutTabs-filterPanel {
                        width: 100%;

                        .dk-panelMainContent {
                            padding: 1.25rem;

                            .dk-Recuriter {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                ul {
                                    list-style: none;
                                    margin-bottom: 0;

                                    li {
                                        margin-bottom: 10px;
                                        margin-top: 15px;

                                        a {
                                            img {
                                                border-radius: 50%;
                                                height: 40px;
                                                width: 40px;
                                            }

                                            .recuriterNn {
                                                color: #ff9800;
                                                margin-left: 8px;
                                                display: inline-block;
                                                margin-left: 8px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}