.dk-completionMain{
    margin-top: 125px;
    padding:0 20px;
    .dk-completionHead{
        position: relative;
        .dk-searchBox{
            width: 100%;
            max-width:240px;
            min-width:240px;
            position: relative; 
            display: flex;  
            .dk-userImg{
                width: 100%;
                max-width: 35px;
                min-width: 35px;
                height: 35px;
                display: inline-block;
                position: relative;
                span{
                    position: absolute;
                    right: -4px;
                    top: -3px;
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: #000;
                    color: #fff;
                    font-size:9px;
                    text-align: center;
                    line-height: 15px;
                    cursor: pointer;
                }
                img{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                }
            }     
            input{
                border-radius: 0;
                border:1px solid #ddd !important;
                box-shadow: none !important;
                background: #f2f2f2;
                margin-right: 10px;
            }
        }
        h3.title{
            font-size:20px;
            margin: 0;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            font-weight: 600;
        }
        .dk-rightPanel{
            display: flex;
            button{
                display: inline-block;
                background: #f2f2f2;
                border:1px solid #ccc;
                border-radius: 3px;
                outline: none;
                padding:9px 0 8px;
                width: 100%;
                min-width: 100px;
                text-transform: uppercase;
                font-weight: 500;
                margin-right: 8px;
                font-family: "Roboto",sans-serif;
                color: #333;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }

    .dk-completionTableMain{
        margin-top:5px;
        .table-responsive{
            height: calc(100vh - 208px);
        }
        table{
            tr{
                th{
                    background: #26597d;
                    border: 1px solid #4483af !important;
                    font-size: 14px;
                    padding:9px 15px;
                    font-weight: 500;
                    color: #fff;
                    vertical-align: middle;
                    text-align: center;
                    white-space: nowrap;
                }
                td{
                    background: #fff;
                    border: 1px solid #ccc;
                    color: #333;
                    border-color: #ccc;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                    padding: 5px 5px;
                    vertical-align: middle;
                    white-space: nowrap;
                    &:nth-child(02){
                        width: 5% !important;
                    }
                    .react-datepicker-wrapper{
                        input{
                            border:none;
                            outline: none;
                            text-align: center;
                        }
                    }
                    .numberText{
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        background: transparent;
                        transition: all 0.5s;
                        &:hover{
                            background: #4483af;
                            color: #fff;
                            transition: all 0.5s;
                        }
                    }
                    .dk-action{
                        display: flex;
                        justify-content: center;
                        a{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            font-size: 15px;
                            border-radius: 3px;
                            color: #fff !important;
                            outline: none;
                            margin: 0 3px !important;
                            &:hover{
                                opacity: 0.7;
                                transition: all 0.5s;
                            }
                        }
                        a.dk-viewBtn{
                            background: #fee69c;
                            color: #333 !important;
                        }
                        a.dk-editBtn{
                            background: #26597d !important;
                            color: #fff;
                        }
                    }

                    .dk-completionDropdown{
                        .dropdown{
                            display: inline-block;
                            .dropdown-toggle{
                                box-shadow: none;
                                border-radius:3px;
                                text-transform: capitalize;
                                font-size: 14px;
                                padding: 5px 15px;
                                margin: 0;
                                &::after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                padding: 0;
                                border: none;
                                border-radius:0;
                                transform: inherit !important;
                                top: 32px !important;
                                left: inherit !important;
                                right: 0 !important;
                                margin: 0 !important;
                                min-width: 115px;
                                a{
                                    padding:5px 12px;
                                }
                            }
                        }
                    }

                    .dk-userImg{
                        img{
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                        }
                    }
                    .react-datepicker-wrapper{
                        width: 100%;
                        max-width: 110px;
                        input{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.dk-addNewCompletionModal{
    .modal-dialog{
        max-width:500px;
        min-width:500px;
        .modal-content{
            border-radius:5px;
            border:none;
            .modal-header{
                border-radius: 4px 4px 0 0;
                background: #26597d;
                padding:8px 12px;
                .modal-title{
                    margin: 0;
                    display: block;
                    width: 100%;
                    color: #fff;
                    font-size: 16px;
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: 600;
                }
                .close{
                    color: #fff;
                    text-shadow: none;
                    opacity: 1;
                    font-weight: normal;
                    outline: none;
                    padding: 0;
                    margin: 0;
                    font-size:26px;
                }
            }
            .modal-body{
                padding: 20px;
                form{
                    label{
                        width: 100%;
                        input{
                            width: 100%;
                            border-radius: 0;
                            height: 35px;
                            box-shadow: none !important;
                            border:1px solid #ddd !important;
                        }
                        .dropdown{
                            .dropdown-toggle{
                                margin: 0;
                                padding: 0;
                                display: inline-block;
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                box-shadow:none;
                                img{
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                }
                                &::after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                transform: inherit !important;
                                padding: 5px 0;
                                right: inherit !important;
                                left: 52px !important;
                                top: 0 !important;
                                height: auto;
                                min-height: auto;
                                margin: -4px 0 !important;
                                &::before{
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    border-color: transparent #cccccc transparent transparent;
                                    line-height: 0px;
                                    _border-color: #000000 #cccccc #000000 #000000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                                    position: absolute;
                                    left: -12px;
                                    top: 12px;
                                }
                                a{
                                    display: inline-block;
                                    width: 32px;
                                    height: 32px;
                                    padding: 0 5px;
                                    img{
                                        width: 100%;
                                        min-width: 32px;
                                        max-width: 32px;
                                        height: 32px;
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }
                    .dk-adBtn{
                        display: inline-block;
                        background: #26597d;
                        color: #fff;
                        text-transform: uppercase;
                        padding:8px 22px;
                        border-radius:3px;
                        border:none;
                        outline: none;
                        &:hover{
                            background: #4483af;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
}

.dk-addNewCompletionModal.dk-deleteModalMain{
    .modal-dialog{
        min-width: 380px;
        max-width: 380px;
        .modal-body{
            h4{
                padding: 15px 0 35px;
                text-align: center;
                margin: 0;
                font-size: 16px;
                color: #e2445b;
                font-weight: normal;
            }
            button{
                border: 1px solid grey !important;
                color: #333 !important;
                background: #fff !important;
                width: 100%;
                max-width: 80px;
                font-size: 14px;
                font-weight: 500;
                padding:7px 0;
                text-transform: capitalize;
                transition: all 0.5s;
                border-radius: 3px;
                outline: none;
                &:hover{
                    background: #333 !important;
                    border-color: #333 !important;
                    color: #fff !important;
                    transition: all 0.5s;
                }
            }
            button.btnRed{
                background: #e2445b !important;
                color: #fff !important;
                border-color: #e2445b !important;
                margin-right: 8px;
            }
        }
    }
}

.dk-trainingDetailMain.dk-ShedulecompletionMain{
    .dk-completionMain{
        margin-top: 15px;
        padding: 0;
    }
    .dk-searchBox{
        min-width:240px;
        max-width:240px;
    }
    h3.title{
        font-weight: 600 !important;
        font-size: 18px !important;
    }
    .dk-detailRightPanel{
        overflow-x: auto;
    }
    .dk-completionTableMain{        
        .table-responsive{
            height: calc(100vh - 259px);
        }
    }
}

.dk-addNumberModal{
    .modal-dialog{
        min-width: 300px;
        max-width: 300px;
        .modal-content{
            border-radius: 5px;
        }
    }
}