.dropdown.dk-workplanrecDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .dk-toogleFilter {
        position: absolute;
        white-space: nowrap;
        background: #333;
        color: #fff;
        top: -32px;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        padding: 0 10px;
        font-size: 10px;
        height: 22px;
        line-height: 22px;
        border-radius: 3px;
        display: none;

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 7.5px 0 7.5px;
            border-color: #333333 transparent transparent transparent;
            line-height: 0px;
            _border-color: #333333 #000000 #000000 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            position: absolute;
            bottom: -9px;
            left: 50%;
            transform: translate(-50%);
        }
    }

    .dk-workplanrecDropdown-btn {
        box-shadow: none;
        padding: 0 !important;
        margin: 0;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        line-height: 30px;
        // background-color: #eaeaea;
        // border: 1px solid #ddd;
        font-size: 0.81rem;
        cursor: pointer;
        position: relative;
        background-color: #fff;
        border: 1px solid #ddd;

        &:hover {
            outline: none;
            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);

            .dk-toogleFilter {
                display: block;
            }
        }

        &:hover {
            .dk-popHover {
                display: block;
            }
        }

        .dk-popHover {
            position: absolute;
            background: #ccc;
            font-size: 10px;
            white-space: nowrap;
            top: 44px;
            z-index: 99;
            padding: 0 10px;
            text-transform: initial;
            border-radius: 3px;
            height: 23px;
            line-height: 23px;
            left: 50%;
            transform: translate(-50%);
            display: none;

            &::before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 7.5px 12px 7.5px;
                border-color: transparent transparent #cccccc transparent;
                line-height: 0px;
                _border-color: #000000 #000000 #cccccc #000000;
                _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translate(-50%);
            }
        }

        &::after {
            display: none;
        }

        img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
        }
    }
}

.dk-workplanrecDropdownMenu {
    padding: 0 5px;
    left: 45px !important;
    top: -5px !important;
    -webkit-transform: inherit !important;
    background-color: #234158;
    transform: inherit !important;
    display: flex !important;
    align-items: center;
    width: fit-content;
    min-width: fit-content;

    &:after {
        content: "";
        width: 0;
        height: 0;
        border-color: transparent #ccc transparent transparent;
        border-style: solid;
        border-width: 8.5px 12px 8.5px 0;
        line-height: 0;
        _border-color: #000 #ccc #000 #000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
        position: absolute;
        left: -12px;
        top: 13px;
    }

    .dropdown-item {
        padding: 5px;

        &:hover {
            background: #234158;
        }

        img.workplan-recimg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        .dk-taskCount {
            display: block;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 13px;
            margin-top: 3px;
        }
    }

    .taskForCrossBtn {
        position: relative;
    }
}