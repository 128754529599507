.dk-editResModal{
    .modal-dialog{
        min-width: 600px;
        max-width: 600px;
        .modal-content{
            background: transparent;
            .dk-editResModalHder.modal-header{
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fee69c;
                padding: 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: none;
                .modal-title {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #333;
                }
                .close{
                    text-shadow: none;
                    color: #333;
                    opacity: 1;
                }
            }
            .dk-addnewResModalBdy.modal-body{
                background-color: #fff;
                border-radius: 0 0 5px 5px;
                .form-group{
                    display: flex;
                    align-items: center;
                    label{
                        margin-bottom: 0.75rem;
                        min-width: 125px;
                        font-size: 13px;
                    }
                    .dk-resDropDown{
                        width: 100%;
                        .ui.selection.dropdown{
                            border-radius: 0;
                            .menu{
                                border-radius: 0;
                            }
                        }
                    }
                    .dk-inputBoxCon{
                        display: flex;
                        width: 100%;
                        input.form-control{
                            border-radius: 0;
                            font-size: 13px;
                            text-align: center;
                            border: 1px solid #c1c9dc;
                            height: 31px;
                            &:focus{
                                outline: 0;
                                box-shadow: none;
                            }
                        }
                        span{
                            button {
                                display: inline-block;
                                border: none;
                                background-color: #26597d;
                                color: #fff;
                                height: 31px;
                                padding: 2px 7px;
                                border-radius: 0;
                                font-size: .89rem;
                            }
                        }
                    }
                    
                }
                .dk-sbmitbtnCon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .dk-sbmitbtn{
                        display: inline;
                        background: #26597d!important;
                        border: none;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 8px 25px;
                        font-weight: 500;
                        font-size: 14px;
                        outline: none;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}