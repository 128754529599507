.dk-leftMenuNoDropDwonCon{
    .dropdown{
        button.dk-leftfliterNobtn.dropdown-toggle.btn {
            box-shadow: none;
            padding: 0 !important;
            margin: 0;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            line-height: 30px;
            background-color: #eaeaea;
            border: 1px solid #ddd;
            img{
                border-radius: 50%;
                height: 35px;
                width: 35px;
            }
            i{
                font-size: 14px;
            }
        }
        button.dk-leftfliterNobtn.dropdown-toggle::after{
            display: none;
        }
        .dropdown-menu.dk-leftUsrFilterMenuNo{
            position: absolute !important;
            right: 50px !important;
            left: inherit !important;
            transform: translate(0px, -20px) !important;
            background-color: #234158;
            border: 1px solid #234158;
            a.dropdown-item {
                padding: 0;
                display: inline-block;
                margin: 0 5px;
                img{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    display: block;

                }
                .recNoCount{
                    color: #fff;
                    font-size: 12px;
                    padding: 4px 0 0;
                    display: block;
                    width: 100%;
                    text-align: center;
                }
            }
            a.dropdown-item:hover{
                .recNoCount{
                    color: #333;
                }
            }
        }
        .dropdown-menu::before {
             content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8.5px 0 8.5px 15px;
            border-color: transparent transparent transparent #ccc;
            position: absolute;
            right: -16px;
            top: 26px;
        }
    }
}