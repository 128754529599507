.dk-adsTrackPageCon.dk-liveAdsMainContainer {
  .dk-adsTrackerPageBg {
    .dk-todayMainPageCon {
      margin-top: 0;
    }
  }
}

.dk-adsTrackPageCon {
  margin-top: 60px !important;
}

.container-fluid.dk-todayMainPageCon {
  margin-top: 0 !important;

  .dk-todayAdsTableCon {
    .dk-todayadsPageHead {
      margin: 8px 0;
    }
  }
}

.dk-liveadsTabsCon {
  margin-top: 16px;

  .slick-slider {
    margin: 0;

    .slick-arrow {
      text-indent: -9999%;
      outline: none;
      position: relative;
    }

    .slick-prev {
      z-index: 100;
      left: -37px !important;
      position: absolute;
      top: 13px;
      background-color: #eaeaea;
      border: 1px solid #ddd;
      border-radius: 50%;
      width: 35px;
      height: 35px;

      &:hover {
        &::before {
          background: url('../../../../assets/images/double-leftwhitearrows.png');
          background-size: 12px;
          position: absolute;
          left: 10px;
          background-repeat: no-repeat;
          top: 10px;
          padding: 7px;
          opacity: 0.8;
        }
      }
    }

    .slick-prev:before,
    .slick-next:before {
      font-size: 20px;
      line-height: 1.25;
      opacity: 1;
      color: #333;
    }

    .slick-prev::before {
      content: "";
      background: url('../../../../assets/images/double-arrows-angles.png');
      background-size: 12px;
      position: absolute;
      left: 26px;
      background-repeat: no-repeat;
      top: 11px;
      padding: 7px;
      opacity: 0.8;
    }

    .slick-prev::before {
      content: "";
      background: url('../../../../assets/images/double-arrows-angles.png');
      background-size: 12px;
      position: absolute;
      left: 10px;
      background-repeat: no-repeat;
      top: 10px;
      padding: 7px;
      opacity: 0.8;
    }

    .slick-prev:hover {
      background-color: #374252;
      border: 1px solid #374252;
      color: #e2dada;
    }

    .slick-next {
      z-index: 100;
      right: -37px !important;
      position: absolute;
      top: 13px;
      background-color: #eaeaea;
      border: 1px solid #ddd;
      border-radius: 50%;
      width: 35px;
      height: 35px;

      &:hover {
        &::before {
          background: url('../../../../assets/images/dublearrowhite.png');
          background-size: 12px;
          position: absolute;
          left: 11px;
          background-repeat: no-repeat;
          top: 11px;
          padding: 7px;
          opacity: 0.8;
        }
      }
    }

    .slick-next:hover {
      background-color: #374252;
      border: 1px solid #374252;
      color: #e2dada;
    }

    .slick-next:before {
      content: "";
      background: url('../../../../assets/images/double-right-arrows.png');
      background-size: 12px;
      position: absolute;
      left: 11px;
      background-repeat: no-repeat;
      top: 11px;
      padding: 7px;
      opacity: 0.8;
    }
  }

  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;

    .slick-track {
      .slick-slide {
        display: inline-block;
        float: left;
      }

      .slick-slide {
        div {
          .liveadsTabCon {
            background-color: #eaeaea;
            color: #333;
            padding: 4px 0;
            border: 1px solid #ddd;
            cursor: pointer;
            text-align: center;
            border-radius: 35px;
            white-space: nowrap;
            font-size: 0.9rem;
            max-width: 95%;

            &:focus {
              outline: none;
            }

            &:active {
              outline: none;
            }

            span {
              color: #333;
              padding: 6px 29px;
              text-align: center;
            }
          }

          .liveadsTabCon.active,
          .liveadsTabCon:hover {
            background-color: #374252;
            border: 1px solid #374252;
            color: #e2dada;

            span {
              color: #e2dada;
            }
          }
        }

        div:focus-visible,
        div:focus-visible {
          outline: 0;
          box-shadow: 0;
        }
      }
    }
  }
}