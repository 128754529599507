.dk-suMMCaseStatusDropDwn {
    width: 100%;

    .dkg-sendouts-kpi-toggle {
        background-color: #fff;

        .react-contextmenu-wrapper {
            a {
                color: #333;
                height: 32px;
                border: 1px solid #ddd;
                line-height: 16px;
                text-transform: capitalize;
                padding: 7px 10px;
            }

        }

        .dkg-sendouts-kpi-dropdown-menu {
            max-height: 280px;
            overflow-y: scroll;

            input {
                border-bottom: 1px solid #ddd;
            }

            ul {
                a {
                    background-color: #234158 !important;
                    color: #fff !important;
                    border-bottom: 1px solid #848488;
                    text-align: left;

                    &:last-child {
                        border-bottom: none;
                    }

                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
        }

        .dkg-sendouts-kpi-dropdown-menu1 {
            max-height: 280px;
            overflow-y: scroll;

            input {
                border-bottom: 1px solid #ddd;
            }

            ul {
                .status-drop0 {
                    background-color: #30887e !important;
                    color: #fff !important;
                    border-bottom: 1px solid #848488;
                    text-align: left;

                    &:last-child {
                        border-bottom: none;
                    }

                    &:hover {
                        opacity: 0.9;
                    }
                }
                .status-drop1 {
                    background-color: #e2445c !important;
                    color: #fff !important;
                    border-bottom: 1px solid #848488;
                    text-align: left;

                    &:last-child {
                        border-bottom: none;
                    }

                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
        }
    }
}