.dropdownFilter {
    .btn-primary.dropdown-toggle {
        background: #fff !important;
        box-shadow: none;
        border: 1px solid #ddd;
        color: #333;
        margin: 0;
        padding: 8px 20px;
        min-width: 150px;
        max-width: 150px;

        &::after {
            display: none;
        }
    }

    .dropdown-menu.show {
        padding: 0;

        .dropdown-item {
            padding: 0.5rem 0.5rem;
            display: flex;
            justify-content: space-between;

            .dropdown-filter-count {
                background-color: #3c4c62;
                border-radius: 2px;
                color: #fff;
                padding: 0px 5px;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}