.dkg-knowleadedash-Main {
    margin-top: 53px;
    height: calc(100vh - 52px) !important;
    background-color: #eaeaea;

    .dkg-dashboard-main {
        // width: 100%;
        // display: block;
        // position: relative;

        h1 {
            padding: 0 0 10px;
            padding-top: 35px;
            text-align: center;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 600;
            color: #000;
            -webkit-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;

            a {
                color: #000;
                transition: all 0.25s ease-in-out;
                position: relative;
                top: -4px;

            }

        }

        .dkg-knowleadedash-boxsRow {
            .dkg-knowleadedash-box {
                .card.faq-card {
                    min-height: 125px;
                    margin-bottom: 15px;
                    transition: all .5s;
                    cursor: pointer;

                    &:hover {
                        transform: translateY(-3px);
                        box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
                    }

                    .card-body {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .dkg-know-dash-imgCon {
                            text-align: center;

                            .dkg-know-dash-img {
                                width: 48px;
                            }

                            span {
                                display: block;
                                margin-top: 9px;
                                text-transform: uppercase;
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
}