.dk-qualyeduConMain{
    .dk-qualyTopHead{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin-bottom: 5px !important;
        .title{
            font-size: 18px;
            font-weight: 600;
            color: #333;
        }
        .btn-Con{
            a {
                display: inline-block;
                background: #f2f2f2;
                padding: 6px 15px;
                font-size: 14px;
                border-radius: 5px;
                color: #333;
                font-weight: 500;
                border: 1px solid #ccc;
            }
        }
    }
    .description{
        p{
            color: #333;
            margin-top: 15px;
            margin-bottom: 25px;
            font-size: 14px;
            text-align: justify;
        }
    }
    .dk-qualyeduBoxCon{
       
        .dk-educationBox {
            width: 100%;
            position: relative;
            .d-flex{
                .dk-educationDate{
                    width: 100%;
                    max-width: -webkit-fit-content;
                    max-width: -moz-fit-content;
                    max-width: fit-content;
                    margin-left: 0;
                    margin-right: 0 !important;
                    font-weight: 600;
                    color: #193b50;
                    background: transparent !important;
                    padding-right: 20px !important;
                    i{
                        font-size: 1.25rem;
                    }
                }
                .dk-educationDetails {
                    width: 100%;
                    max-width: 100%;
                    position: relative;
                    padding-left: 10px;
                    background: #d9dfe7 !important;
                    border: 1px solid #ddd;
                    border-radius:5px;
                    padding: 15px 20px;
                    margin-bottom: 1.25rem;
                    .sbtitle {
                        margin: 0;
                        font-size: 17px;
                        font-weight: 700;
                        color: #193b50;
                        text-transform: uppercase;
                        margin-bottom: 0.5rem;
                        i {
                            margin-right: 3px;
                            color: #4b7b98;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        margin-left: 0;
                        font-size: 14px;
                        color: #333;
                        font-weight: 600;
                        height: 24px;
                        i{
                            display: inline-block;
                            margin-right: 3px;
                            color: #333;
                        }
                    }
                    .dkg-qualification-table{
                        margin-bottom: 0;
                        tbody{
                            tr{
                                td{
                                    padding: 0;
                                    font-size: 13px;
                                    p{
                                        font-size: 13px;
                                    }
                                    &:first-child {
                                        width: 210px;
                                        i {
                                            margin-right: 7px;
                                        }
                                    }
                                    &:last-child {
                                        padding-left: 20px;
                                        font-weight: normal;
                                        p{
                                            font-weight: normal;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dk-colsTexttag {
                        font-size: 14px;
                        margin-left: 0;
                        border: none;
                        padding: 7px 15px;
                        border-radius: 3px;
                        margin-top: 10px;
                        margin-bottom: 0;
                        width: 100%;
                        // max-width: 550px;
                        max-width: 95%;
                        margin-right: auto;
                        background: #d9dfe7;
                    }
                }
            }
        }
        .dk-educationBox:before {
            content: "";
            width: 1px;
            max-width: 100px;
            height: calc(100% - 20px);
            background: #4b7b98;
            position: absolute;
            left: 17px;
            top: 20px;
        }
        &:last-child:before{
            height: 90%;
        }
    }
}