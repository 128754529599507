.dk-structureMainFluid {
    margin-top: 170px;
    padding: 0 15px;
    font-family: "Segoe UI", Arial, sans-serif !important;
    font-weight: 500;

    .structurFlex {
        display: flex;
        font-family: "Segoe UI", Arial, sans-serif !important;
        font-weight: 500;

        .dk-structure-box {
            width: 100%;

            .title {
                background: #ddd;
                border: 1px solid #ccc;
                text-align: center;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 15px;
                display: block;
                width: 100%;
                padding: 8px 0;
            }

            .dk-structure-panel {
                text-transform: uppercase;
                height: calc(100vh - 210px);
                overflow-y: auto;
                border: 1px solid #ccc;

                h3 {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px;
                    font-weight: 600;
                }

                .structure-list {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    height: 100%;
                    width: 100%;

                    li {
                        width: 100%;
                        text-align: center;
                        text-transform: uppercase;
                        border-bottom: 1px solid #a2a2a2;
                        list-style-type: none;
                        padding: 50px 0;
                        font-size: 15px;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }

                .structure-list.lg-list {
                    li {
                        padding: 12px 0;
                    }
                }
            }

            .dk-structure-panel::-webkit-scrollbar {
                width: 0px;
            }

            .dk-structure-panel::-webkit-scrollbar-track {
                box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
            }

            .dk-structure-panel::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                outline: 0px solid slategrey;
            }
        }
    }

    .dk-structureTable-main {
        .dk-structureTable {
            table {
                margin: 0;
                border: 1px solid #9b9b9b;
                border-top: none;

                tr {
                    td {
                        text-align: center;
                        vertical-align: middle;
                        font-size: 14px;
                        border: 0.5px solid #9b9b9b;
                        height: 145px;
                        font-weight: 500;
                        font-size: 16px;
                        text-transform: uppercase;

                        &:nth-child(01) {
                            background: #ebebeb !important;
                            width: 15%;
                        }

                        .dk-tags {
                            text-transform: capitalize;
                            background: #f2f2f2;
                            border-radius: 5px;
                            text-align: center;
                            display: inline-block;
                            float: left;
                            margin: 5px;
                            padding: 0px 10px;
                            border: 1px solid #666;
                            font-size: 14px;
                            position: relative;

                            span {
                                cursor: pointer;
                                display: inline-block;
                                width: 30px;
                                background: #ccc;
                                height: 28px;
                                line-height: 25px;
                                border-radius: 0 5px 5px 0;
                                margin-left: 5px;
                            }
                        }
                    }

                    &:nth-child(01) {
                        td {
                            background: #ffc4ab;
                        }
                    }

                    &:nth-child(02) {
                        td {
                            background: #d8caff;
                        }
                    }

                    &:nth-child(03) {
                        td {
                            background: #396a95;
                        }
                    }
                }
            }

            table.dk-tablehead {
                border-bottom: none;

                tr {
                    td {
                        background: #d6d6d6 !important;
                        border-bottom: none;
                        height: 90px;
                    }
                }
            }
        }
    }
}