.dk-holidaysMain {
    margin-top: 125px;
    padding: 0 20px;

    .dk-holidaysHead {
        position: relative;

        h3.title {
            position: absolute;
            display: inline-block;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            top: 9px;
            margin: 0;
        }

        .dk-holidayLeft {
            display: flex;
            align-items: center;

            .dk-dateDropdown {
                .dropdown-toggle {
                    margin: 0;
                    border-radius: 0;
                    background: #eaeaea !important;
                    border-radius: 5px;
                    color: #333 !important;
                    font-size: 13px;
                    box-shadow: none !important;
                    padding: 7px 28px;
                    margin-right: 8px;
                    outline: none;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    min-width: 89px;
                    border: none;
                    background: #eaeaea;
                }
            }

            .dk-holidayBtn {
                display: flex;
                align-items: center;

                .dk-calenderBtn {
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    background-color: #eaeaea !important;
                    text-align: center;
                    border: 1px solid #ddd;
                    margin-left: 8px;
                    line-height: 33px;
                    font-size: 15px;
                    color: #333;
                    border-radius: 50%;
                    padding: 0;
                    margin: 0;
                    box-shadow: none !important;
                    margin-left: 8px;
                    outline: none;
                }
            }

        }

        .dk-holidayRight {
            width: 100%;
            max-width: 210px;

            .dk-holidayRightBtn {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                button {
                    display: inline-block;
                    background: #f2f2f2;
                    border: 1px solid #ccc;
                    padding: 6px 15px;
                    border-radius: 3px;
                    outline: 0;
                    width: 100%;
                    max-width: 110px;
                    text-align: center;
                    white-space: nowrap;

                    &:first-child {
                        max-width: 50px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

.dk-holidayCalMain {
    display: block;
    width: 100%;
    margin-top: 18px;

    .col-md-2 {
        padding-right: .625rem;
        padding-left: .625rem;
    }

    .dk-holidayBox {
        background-color: #f2f2f2;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 1px solid #ddd;
        min-height: 240px;
        margin-bottom: 15px;

        .dk-dateHeading {
            background-color: transparent;
            width: 100%;
            color: #333 !important;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 5px 0;
            padding-bottom: 5px;
            margin: 0;
        }

        .dk-dateHeading.janBg {
            background-color: #faa2c4;
        }

        .dk-dateHeading.febBg {
            background-color: #b5bdc4;
        }

        .dk-dateHeading.marBg {
            background-color: #b7dcf4;
        }

        .dk-dateHeading.aprBg {
            background-color: #faaca2;
        }

        .dk-dateHeading.mayBg {
            background-color: #a2f0fa;
        }

        .dk-dateHeading.junBg {
            background-color: #added4;
        }

        .dk-dateHeading.julBg {
            background-color: #f0faa2;
        }

        .dk-dateHeading.augBg {
            background-color: #c9cbac;
        }

        .dk-dateHeading.sepBg {
            background-color: #dbbde5;
        }

        .dk-dateHeading.octBg {
            background-color: #c4faa2;
        }

        .dk-dateHeading.novBg {
            background-color: #fbeba5;
        }

        .dk-dateHeading.decBg {
            background-color: #f6d3af;
        }

        .dk-monthDateContainer {
            ul {
                list-style: none;

                li {
                    list-style-type: none;
                }
            }

            ul.dk-weekend {
                list-style: none;
                display: flex;
                padding-inline-start: 0;
                background-color: #ddd;
                padding: 5px 8px;
                margin-bottom: 0;

                li {
                    font-size: 13px;
                    font-weight: 400;
                    width: 14.28%;
                    text-align: center;
                }

                li.dk-sunday {
                    color: red;
                }
            }

            ul.dk-holidaysDays {
                list-style: none;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding-inline-start: 0;
                background-color: #f2f2f2;
                padding: 5px 8px;
                margin-bottom: 0;

                li {
                    min-width: 14.28%;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 400;

                    &:last-child {
                        color: red;
                    }
                }

                .markHolidayActive {
                    span {
                        background: #d0938c;
                        padding: 0 !important;
                        color: #fff;
                        border-radius: 5px;
                        min-width: 27px;
                        display: inline-block;
                    }
                }
            }

            ul.dk-holidaysEndDays {
                justify-content: flex-end;
            }

            ul.dk-holidaysStartDays {
                justify-content: flex-start;
                list-style: none;
                display: flex;
                justify-content: flex-start;
                padding-inline-start: 0;
                background-color: #f2f2f2;
                padding: 5px 8px;
                margin-bottom: 0;

                li {
                    min-width: 14.28%;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }
    }
}

.dk-holidayModalMain {
    .modal-dialog {
        min-width: 84%;

        .modal-content {
            border-radius: 5px;

            .modal-header {
                padding: 5px 27px;
                padding-top: 7px;
                background: #d9d9d9;
                border-bottom: none;
                border-radius: 0;
                border-top-right-radius: 3px;
                border-top-left-radius: 3px;
                position: relative;

                button.close {
                    display: none;
                }

                .dk-holidayModal-left {
                    display: flex;
                    align-items: center;

                    .dk-dateDropdown {
                        .dropdown-toggle {
                            margin: 0;
                            border-radius: 0;
                            background: #fff !important;
                            border-radius: 5px;
                            color: #333 !important;
                            font-size: 13px;
                            box-shadow: none !important;
                            padding: 7px 28px;
                            outline: none;
                            width: 100%;
                            min-width: 90px;

                            &::after {
                                content: "" !important;
                                display: inline-block !important;
                                background: url('../../../../assets/images/arrow-down.png') no-repeat;
                                padding: 5px 7px;
                                position: absolute;
                                right: 6px;
                                z-index: 9;
                                background-size: 11px;
                                border: none;
                                top: 11px;
                            }
                        }

                        .dropdown-menu {
                            padding: 0;
                            min-width: 100px;
                            border: none;
                            background: #eaeaea;
                        }
                    }

                    .dk-globalUserDropdown {
                        margin-left: 10px;

                        button {
                            background: #fff !important;
                        }
                    }
                }

                .dk-holidayModal-right {
                    display: flex;

                    .selection.dropdown {
                        width: 100%;
                        min-width: 130px;
                        text-align: center;
                        color: #333;
                        border: none;
                        margin-right: 10px;
                        min-height: auto;

                        &:last-child {
                            margin-right: 0;
                        }

                        .dropdown.icon {
                            display: none;
                        }

                        .dropdown.icon.clear {
                            display: block !important;
                            width: 14px;
                            height: 14px;
                            background: #333;
                            border-radius: 50%;
                            color: #fff;
                            text-align: center;
                            line-height: 15px;
                            padding: 0;
                            font-size: 7px;
                            margin: 7px 2px;
                        }

                        .default.text {
                            color: #333;
                            font-size: 14px;
                        }

                        .visible.menu {
                            border: none;
                            margin: 0;
                            margin-top: 1px;
                            border-radius: 3px;

                            .item {
                                display: flex;
                                align-items: center;
                                flex-direction: row-reverse;
                                justify-content: space-between;
                                padding: 10px !important;
                                background: #fff;

                                .ui.label {
                                    padding: 0;
                                    background: transparent;
                                    margin: 0;
                                    color: #1f88e2;
                                    font-weight: normal;
                                }

                                span.text {
                                    font-weight: normal;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }

                h3.title {
                    position: absolute;
                    left: 50%;
                    top: 15px;
                    margin: 0;
                    transform: translate(-50%);
                    font-size: 1.3rem;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                .dk-globalUserDropdown {
                    .dropdown-toggle {
                        &:hover {
                            color: #333;
                            border-color: #ddd;
                        }
                    }
                }
            }

            .dk-holidaysModal {
                padding: 0;
                overflow-y: auto;
                max-height: 550px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;

                .dk-mdHolidayTableCont {
                    padding: 15px 25px 25px;
                    background: #d9d9d9;

                    .dk-holdayPopupTable {
                        border: 1px solid #d9d9d9;
                        border-top: none;

                        tr {
                            th {
                                border-bottom: none;
                                padding: 10px 18px 5px;
                                background: #d9d9d9;
                                color: #333;
                                text-align: center;
                                font-size: 14px;
                                border: none !important;
                                border-top: none !important;
                            }
                        }

                        tbody+tbody {
                            border: none !important;
                        }

                        tbody {
                            tr {
                                td {
                                    padding: 5px 10px;
                                    white-space: nowrap;
                                    background-color: #f9f9f9;
                                    font-weight: 400;
                                    text-align: center;
                                    font-size: 13px;
                                    vertical-align: middle;
                                    width: 10%;

                                    .dk-statusDropdown {
                                        .dropdown-toggle {
                                            background: #4c9c23 !important;
                                            color: #fff !important;
                                            border-radius: 5px;
                                            padding: 7px 18px;
                                            min-width: 80px;
                                            margin: 0;
                                            outline: none;
                                            box-shadow: none !important;

                                            &::after {
                                                display: none;
                                            }
                                        }

                                        .InReview {
                                            background: #f3ae36 !important;
                                        }

                                        .dropdown-menu {
                                            padding: 0;
                                            border-radius: 0;
                                            border: none;
                                            min-width: 120px;

                                            a {
                                                padding: 8px 12px;
                                                color: #fff;
                                            }
                                        }
                                    }

                                    .dk-action {
                                        button {
                                            color: #333 !important;
                                            background-color: #f9f9f9;
                                            border: 1px solid #ddd;
                                            outline: 0;
                                            box-shadow: none;
                                            font-size: 14px;
                                            margin-top: 0;
                                            margin: 0 3px;
                                            width: 35px;
                                            height: 35px;
                                            border-radius: 3px;

                                            &:hover {
                                                background: #f2f2f2;
                                                transition: all 0.5s;
                                            }
                                        }
                                    }
                                }

                                .janHoldyPopBg {
                                    background-color: #faa2c4;
                                }

                                .febHoldyPopBg {
                                    background-color: #b5bdc4;
                                }

                                .marHoldyPopBg {
                                    background-color: #b7dcf4;
                                }

                                .aprHoldyPopBg {
                                    background-color: #faaca2;
                                }

                                .mayHoldyPopBg {
                                    background-color: #a2f0fa;
                                }

                                .junHoldyPopBg {
                                    background-color: #added4;
                                }

                                .julHoldyPopBg {
                                    background-color: #f0faa2;
                                }

                                .augHoldyPopBg {
                                    background-color: #c9cbac;
                                }

                                .sepHoldyPopBg {
                                    background-color: #dbbde5;
                                }

                                .octHoldyPopBg {
                                    background-color: #c4faa2;
                                }

                                .novHoldyPopBg {
                                    background-color: #fbeba5;
                                }

                                .decHoldyPopBg {
                                    background-color: #f6d3af;
                                }

                                td.dk-userImg {
                                    img {
                                        width: 30px;
                                        height: 30px;
                                        object-fit: cover;
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-holidayDetailMain.modal {
    background: rgba(0, 0, 0, 0.47);

    .modal-dialog {
        min-width: 35%;

        .modal-content {
            border-radius: 5px;

            .modal-header {
                padding: 8px 10px;
                background: #234158;
                border-radius: 5px 5px 0 0;
                text-align: center;
                color: #fff;

                h4.modal-title {
                    margin: 0;
                    display: block;
                    text-align: center;
                    width: 100%;
                    font-size: 16px;
                    color: #fff;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                button.close {
                    opacity: 1;
                    color: #fff;
                    text-shadow: none;
                    font-size: 17px;
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    right: 11px;
                    top: 11px;
                    outline: none;
                }
            }
        }
    }
}

.dk-addNewLeaveMain {
    .modal-dialog {
        min-width: 47%;

        .modal-content {
            border-radius: 5px;
            box-shadow: none;
            border: none;

            .modal-header {
                padding: 10px 15px;
                background: #2e97b6;
                border-radius: 4px 4px 0 0;

                h4.modal-title {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                }

                button.close {
                    padding: 0;
                    color: #fff;
                    opacity: 1;
                    text-shadow: none;
                    font-size: 20px;
                    position: absolute;
                    right: 28px;
                    top: 26px;
                    outline: 0;
                }
            }

            .dk-addNewleaveBody {
                form {
                    .dk-projectrecruiter {
                        .dk-globalUserDropdown.dropdown {
                            margin-top: 9px;

                            .dropdown-menu {
                                left: 68px !important;
                            }
                        }
                    }

                    label {
                        width: 100%;

                        input {
                            width: 100%;
                            border-radius: 0;
                            border: 1px solid #ddd !important;
                            height: 35px;
                            padding: 0 10px;
                            font-size: 14px;
                        }

                        textarea {
                            width: 100%;
                            height: 100px;
                            resize: vertical;
                            border-radius: 0;
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                        }

                        .dk-saveBtn {
                            display: inline-block;
                            background: #2e97b6;
                            padding: 8px 15px;
                            border: none;
                            color: #fff;
                            text-transform: uppercase;
                            font-weight: 500;
                            font-size: 14px;
                            border-radius: 3px;
                            outline: 0;

                            &:hover {
                                background-color: #234158;
                                transition: all 0.5s;
                            }
                        }

                        .selection.dropdown {
                            width: 100%;
                            border-radius: 0;
                            margin-top: 0;
                            height: 35px;
                            outline: none !important;
                            position: relative;
                            color: #5f5f5f !important;
                            border: 1px solid #ddd !important;

                            &::after {
                                content: '' !important;
                                display: inline-block !important;
                                background: url('../../../../assets/images/arrow-down.png') no-repeat;
                                padding: 5px 10px;
                                position: absolute;
                                right: 0;
                                z-index: 9;
                                background-size: 11px;
                            }

                            .default.text {
                                color: #333;
                                font-size: 12px;
                            }

                            &:hover {
                                border: 1px solid #ddd !important;
                            }

                            .dropdown.icon {
                                display: none;
                            }

                            .visible.menu {
                                border-color: #ddd;
                                box-shadow: none;

                                .item {
                                    font-size: 12px;
                                    border: none;

                                    &:nth-child(01) {
                                        background: #efee9d;
                                        color: #333;
                                    }

                                    &:nth-child(02) {
                                        background: #bc658d;
                                        color: #fff;
                                    }

                                    &:nth-child(03) {
                                        background: #efbbcf;
                                        color: #333;
                                    }

                                    &:nth-child(04) {
                                        background: #abc2e8;
                                        color: #333;
                                    }
                                }
                            }
                        }

                        .dk-reasonTypeDropdown {
                            .visible.menu {
                                .item {
                                    &:nth-child(01) {
                                        background: #f0c9c9;
                                        color: #333;
                                    }

                                    &:nth-child(02) {
                                        background: #cca8e9;
                                        color: #333;
                                    }

                                    &:nth-child(03) {
                                        background: #d291bc;
                                        color: #333;
                                    }

                                    &:nth-child(04) {
                                        background: #78b7bb;
                                        color: #333;
                                    }

                                    &:nth-child(05) {
                                        background: #fdffab;
                                        color: #333;
                                    }

                                    &:nth-child(06) {
                                        background: #d8345f;
                                        color: #fff;
                                    }

                                    &:nth-child(07) {
                                        background: #a8e6cf;
                                        color: #333;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.dropdown.dk-globalUserDropdown {
    .dropdown-toggle.btn {
        // display: inline-block;
        // width: 35px;
        // height: 35px;
        // background-color: #eaeaea !important;
        // text-align: center;
        // border: 1px solid #ddd;
        // margin-left: 8px;
        // line-height: 33px;
        // font-size: 15px;
        // color: #333;
        // border-radius: 50%;
        // padding: 0;
        // margin: 0;
        // box-shadow: none !important;

        background-color: transparent !important;
        // margin-left: 8px;
        color: #333;
        border-radius: 50%;
        padding: 0;
        margin: 0;
        box-shadow: none !important;

        &:active {
            background-color: #eaeaea !important;
        }

        &:focus {
            background-color: #eaeaea !important;
        }

        &::after {
            display: none;
        }

        &:hover {
            background: transparent !important;
            border-color: transparent;
            transition: all 0.5s;
            color: #fff;
        }
    }

    .dropdown-menu {
        padding: 8px 5px;
        background: #f2f2f2;
        border-radius: 3px;
        transform: inherit !important;
        right: 0 !important;
        left: 48px !important;
        top: 0px !important;
        min-width: fit-content;
        width: fit-content;

        &::before {
            content: "";
            width: 0;
            height: 0;
            border-color: transparent #ccc transparent transparent;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            line-height: 0;
            _border-color: #000 #ccc #000 #000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            position: absolute;
            left: -12px;
            top: 8px;
        }

        &::after {
            display: none;
        }

        a {
            padding: 0;
            width: 30px;
            margin: -5px 3px;
            display: inline-block;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }
    }
}