.dkg-emailcontent-Modal.modal{
    .modal-dialog{
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 85% !important;
        max-width: 85%;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        .modal-content{
            border: none;
            box-shadow: none;
            font-family: "Roboto",sans-serif !important;
            border-radius: 0;
            .modal-header{
                background: #cd5360;
                color: #fff;
                padding: 8px 12px;
                border-radius:0;
                .dk-saveBtn{
                    display: inline-block;
                    background: transparent;
                    outline: none;
                    border: none;
                    svg{
                        font-size: 24px;
                        color: #fff;
                    }
                }
                .modal-title{
                    color: #fff;
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    margin: 0;
                }
                .close{
                    outline: none;
                    color: #fff;
                    text-shadow: none;
                    padding: 0;
                    opacity: 1;
                    margin: 3px 0;
                    position: absolute;
                    right: 15px;
                    top: 3px;
                    font-size: 30px;
                    font-weight: 500;
                    &:hover{
                        color: #1b364b;
                    }
                }
                .action-button{
                    svg{
                        cursor: pointer;
                    }
                }
                &.dkg-reply-mail-newHeader{
                    background-color: #3c97b6;
                    .modal-title{
                        position: relative;
                        top: 5px;
                    }
                    .close{
                        &:hover{
                            opacity: 0.78;
                            color: #fff;
                        }
                    }
                    .action-button{
                        &:hover{
                            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                            transition: all ease 0.5s;
                            cursor: pointer;
                        }
                        svg{
                            &:hover{
                                opacity: 0.75;
                            }
                        }
                    }
                }
            }
            .modal-body{
                background: #eaeaea;
                border-radius:0;
                height: calc(100vh - 44px);
                padding: 0;
                .action-button{
                    cursor: pointer;
                    &:hover{
                        color: #2e97b6;
                    }
                    .svg-inline--fa{
                        display: var(--fa-display, inline-block);
                        height: 1em;
                        overflow: visible;
                        vertical-align: -0.125em;
                    }
                }
                .dk-msgHeader{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #ddd;
                    background-color: #eaeaea;
                    padding: 5px 10px;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    z-index: 9;
                    .dk-userTitle{
                        font-size: 17px;
                        font-weight:500;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        position: relative;
                        span.dkg-email-showdatetimer{
                            font-size: 14px;
                            font-weight: 400;
                            position: absolute;
                            right: 0;
                            svg{
                                color: #afafaf;
                            }
                        }
                        .dkg-sendemail-imgCon{
                            .dkg-sendemail-img{
                                border-radius: 50%;
                                height: 32px;
                                width: 32px;
                                border: 1px solid #ddd;
                                display: none;
                            }
                        }
                        .dk-detailText{
                            padding-left: 10px;
                            width: 100%;
                            .title{
                                font-weight: 600;
                                font-size: 15px;
                                position: relative;
                                width: 100%;
                                span{
                                    font-size: 10px;
                                    font-weight: 500;
                                    position:absolute;
                                    right: 0;
                                    top: 0;
                                    opacity: 0.7;
                                }
                                &::before{
                                    content: '';
                                    width: 10px;
                                    height: 10px;
                                    display: inline-block;
                                    border: 1px solid #333;
                                    border-radius: 50%;
                                    margin-right:5px;
                                    display: none;
                                }
                            }
                            .sbtitle{
                                font-weight:500;
                                font-size: 13px;
                                position: relative;
                                span{
                                    font-size: 12px;
                                    display: block;
                                    font-weight: 400;
                                    svg{
                                        opacity: 0.5;
                                    }
                                }
                                b{
                                    font-size: 15px;
                                }
                            }  
                            .dkg-email-showdatepickerCon{
                                .dkg-email-showdatepicker{
                                    font-size: 14px;
                                    font-weight: 400;
                                    position: relative;
                                    top: -25px;
                                    font-weight: normal;
                                    svg{
                                        color: #afafaf;
                                    }
                                }
                            }
                            .dkg-bc-datepickerCon{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                // .dkg-email-showdatepickerCon{
                                //     .dkg-email-showdatepicker{
                                //         position: relative;
                                //         top: -15px;
                                //     }
                                // }
                                .dkg-email-showdatepicker{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .dk-msgformat{
                        a{
                            display: inline-block;
                            margin: 0 3px;
                            color: #8b8b8b;
                        }
                    }
                }
                .dk-msgSender-detail{
                    background-color: #f1f6fc;
                    .d-flex{
                        .dk-detail-leftPanel{
                            width: 100%;
                            position: relative;
                            padding: 10px 20px;
                            .dkg-email-subject-Con{
                                display: block;
                                width: 100%;
                                padding-bottom: 0;
                                .dkg-email-subject{
                                    font-size: 15px;
                                    font-weight: normal; 
                                    .dkg-subtile{
                                        font-weight: 700;
                                    }
                                }
                            }
                            .dk-mailViewAttachfile-Con{
                                .dk-mailViewAttach{
                                    align-items: center;
                                    background: #eaeaea;
                                    border: 1px solid #ccc;
                                    border-radius: 5px;
                                    cursor: pointer;
                                    display: inline-flex;
                                    font-size: 13px;
                                    font-weight: 400;
                                    margin-right: 7px;
                                    margin-top: 0;
                                    padding: 3px 8px;
                                }
                            }
                        }
                    }
                }
                .dkg-readmsg-mainCon{
                    background-color: #fff;
                    // height: calc(100vh - 340px);
                    border-top: 1px solid #ddd;
                    font-weight: 400;
                    margin-top: 0;
                    padding: 10px 12px;
                    height: calc(100vh - 160px);
                    overflow-y: auto;
                    .dkg-readmsg-title{
                        font: small/1.5 Arial,Helvetica,sans-serif;
                        overflow: hidden;
                        font-weight: 500;
                        padding-left: 7px;
                        padding-right: 7px;
                        div{
                            p{
                                margin-bottom: 0;
                                color: #000;
                                font-family: "Roboto";
                                font-weight: 400;
                                font-size: 14px;
                            }
                        }
                    
                    }
                }
            }
        }

    }
}

.modal.dkg-emailcontent-Modal.fade .modal-dialog {
    right: -85%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dkg-emailcontent-Modal.show .modal-dialog {
    right: 0;
}

.modal.dkg-emailcontent-Modal {
    z-index: 9999 !important;
}
