.dk-pipeFlagFilterCon {
    .dropdown {
        button.dk-pipeflagfliterbtn.dropdown-toggle.btn {
            box-shadow: none;
            background-color: transparent;
            padding: 0 !important;
            margin: 0;

            i {
                font-size: 14px;
            }
        }

        button.dk-pipeflagfliterbtn.dropdown-toggle::after {
            display: none;
        }

        .dk-pipleFlagFilterMenu.dropdown-menu {
            padding: 5px;
            left: 7px !important;
            top: -18px !important;
            transform: translate(22px, 7px) !important;
            -webkit-transform: translate(22px, 7px) !important;
            background-color: #fff;

            a.dropdown-item {
                padding: 0;
                display: inline-block;
                margin: 0 3px;
                text-align: center;
            }
        }

        .dk-pipleFlagFilterMenu.dropdown-menu:after {
            content: "";
            width: 0;
            height: 0;
            border-color: transparent #234158 transparent transparent;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            line-height: 0;
            _border-color: #000 #ccc #000 #000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            position: absolute;
            left: -12px;
            top: 10px;
        }
    }
}