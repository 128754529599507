.dk-adsSummary-main {
    margin-top: 115px;

    h3.title {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .dk-adsSummary {
        padding: 0 100px;

        .dk-adsSummaryCols {
            align-items: center;
            background: #eaeaea;
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 12px;
            margin: 12px 15px 18px;
            max-width: 100%;
            padding: 0;
            text-align: center;
            width: 100%;

            .title {
                align-items: center;
                background-color: #eaeaea;
                border-bottom: 1px solid #ddd;
                border-radius: 5px 5px 0 0;
                display: flex;
                justify-content: center;
                width: 100%;
                color: #646060;
                font-size: 15px;
                font-weight: 500;
                min-height: 35px;
                padding: 8px 10px;
                font-family: Segoe UI, Arial, sans-serif !important;
                text-transform: uppercase;
            }

            .dk-adsSummaryPanel {
                align-items: center;
                background-color: #eaeaea;
                border-radius: 0 0 5px 5px;
                display: flex;
                justify-content: center;
                min-height: 125px;
                font-size: 26px;
                color: #3c97b6;
                font-weight: 600;
            }
        }
    }
}