.dk-addnewCategoryMain{
    .modal-dialog{
        .modal-content{
            border-radius: 5px;
            background-color: transparent;
            .dk-addnewCategoryHeader{
                padding: 8px 10px;
                background-color: #37404d;
                color: #fff;
                border-radius: 5px 5px 0 0;
                .modal-title {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                }
                .close {
                    padding: 0;
                    position: absolute;
                    right: 6px;
                    top: 10px;
                    margin: 0;
                    opacity: 1;
                    text-shadow: none;
                    color: #fff;
                    font-size: 25px;
                    outline: none;
                    font-weight: 500;
                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
            .dk-addnewCategoryBody{
                background-color: #fff;
                border-radius: 0 0 5px 5px;
                form{
                    .form-group{
                        padding: 0 25px;
                        label {
                            width: 100%;
                            text-align: left;
                            font-size: 13px;
                            input {
                                border-radius: 0;
                                background: #f2f2f2;
                                border: 1px solid #ccc;
                            }
                        }
                    }
                    .form-footer {
                        border-top: 1px solid #ccc;
                        padding: 10px 25px 0;
                        display: flex;
                        justify-content: flex-end;
                        button {
                            display: inline-block;
                            padding: 8px 12px;
                            border: none;
                            background: #eaeaea;
                            color: #fff;
                            border-radius: 3px;
                            text-transform: uppercase;
                            font-weight: 500;
                            font-size: 13px;
                            &.closeBtn {
                                background: #dc3545;
                            }
                            &.dk-submit-btn{
                                background: #444;
                            }
                        }
                    }
                }
            }
        }
    }
}