.dk-pipelinesMain.dk-commonPipelinesMain.dk-dkGlobalPipelinesMain {
    margin-top: 115px;
    padding: 0 15px;

    .dk-pageheader {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        margin-bottom: 10px;

        h3.title {
            font-weight: 700 !important;
            margin: 0;
            position: inherit;
            left: inherit;
            top: inherit;
            transform: inherit;
            font-size: 19px;
            display: block;
            width: 100%;
            text-align: center;

            @media(max-width:767px) {
                font-size: 16px;
            }
        }

        .dk-teamrolerightBtnCon {
            .dk-teamroleseditBtn {
                a {
                    display: inline-block;
                    background: #f2f2f2;
                    color: #333;
                    border: 1px solid #ccc;
                    padding: 6px 12px;
                    white-space: nowrap;
                    border-radius: 3px;
                    outline: 0;
                    width: 100%;
                    text-align: center;
                    position: relative;
                }
            }
        }
    }

    .dk-pipelinesTableMain::-webkit-scrollbar {
        width: 0;
    }

    .dk-pipelinesTableMain::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
    }

    .dk-pipelinesTableMain::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 0 solid slategrey;
    }

    .dk-pipelinesTableMain {
        overflow-y: auto;
        height: calc(100vh - 196px);

        table {
            overflow-y: auto;
            border-top: none;

            tr {
                th {
                    color: #403c3c;
                    background: #E7E6E6;
                    border: 1px solid #d5d4d4;
                    font-size: 12px;
                    font-weight: 600 !important;
                    text-align: center;
                    text-transform: uppercase;
                    border-bottom: none;
                    padding: 10px 12px;
                    position: sticky;
                    top: 0;
                    z-index: 99;
                    border-top: none;

                    @media(max-width:991px) {
                        white-space: nowrap;
                    }
                }

                td {
                    background-color: #F2F2F2;
                    color: #333;
                    border: 1px solid #ddd;
                    font-size: 12px;
                    font-weight: 400;
                    padding: 5px 15px !important;
                    text-align: center;
                    border-top: none !important;
                    vertical-align: middle;

                    &:nth-child(04) {
                        max-width: 310px;
                    }

                    &:nth-child(05) {
                        width: 32%;
                    }

                    &:nth-child(06) {
                        width: 32%;
                    }

                    &:nth-child(01) {
                        white-space: nowrap;
                    }

                    &:nth-child(02) {
                        white-space: nowrap;
                    }

                    .dk-teamImgCols {
                        display: flex;
                        padding: 0.35rem 0.5rem;
                        justify-content: center;
                        cursor: pointer;

                        .dk-team1 {
                            img {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                                border: 1px solid #dddd;
                            }
                        }
                    }

                    .dk-procssSuMMCols {
                        color: #333;
                        text-align: left;
                    }

                    .dk-processactCols {
                        ul {
                            margin-bottom: 0;
                            list-style: auto;
                            margin: 0;
                            padding: 0;
                            list-style-position: inside;

                            li {
                                text-align: left;
                            }
                        }
                    }
                }

                td.stage1Bg {
                    background-color: #5C737D;
                    color: #fff;
                    text-transform: uppercase;
                }

                td.stage2Bg {
                    background-color: #796698;
                    color: #fff;
                    text-transform: uppercase;
                }

                td.stage3Bg {
                    background-color: #57496D;
                    color: #fff;
                    text-transform: uppercase;
                }

                td.stage4Bg {
                    background-color: #01ACB2;
                    color: #fff;
                    text-transform: uppercase;
                }

                td.stage5Bg {
                    background-color: #007D7F;
                    color: #fff;
                    text-transform: uppercase;
                }

                td.stage6Bg {
                    background-color: #599ad5;
                    color: #fff;
                    text-transform: uppercase;
                }

                td.stage7Bg {
                    background-color: #670004;
                    color: #fff;
                    text-transform: uppercase;
                }

                td.onholdBg {
                    background-color: #7f5347;
                    color: #fff;
                    text-transform: uppercase;
                }

                td.stage8Bg {
                    background-color: #e2445c;
                    color: #fff;
                    text-transform: uppercase;
                }


            }
        }
    }
}

.dk-teamrolesjobsummaryModal {
    .modal-dialog {
        min-width: 60%;

        .modal-content {
            border-radius: 5px;

            .modal-header {
                padding: 7px 15px;
                background: #d9d9d9;
                border-radius: 5px 5px 0 0;

                .modal-title {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                    color: #333;
                    font-family: 'Poppins', sans-serif;
                }

                .close {
                    padding: 0;
                    color: #333;
                    opacity: 1;
                    text-shadow: none;
                    font-size: 24px;
                    position: absolute;
                    right: 28px;
                    top: 24px;
                    outline: 0;
                }
            }

            .modal-body {
                .dk-editRoles-modalBoy {
                    .dk-teamRole-editor {
                        .ck-content {
                            min-height: 120px;
                        }
                    }

                    button.dk-saveBtn {
                        display: inline-block;
                        background: #234158;
                        border: 1px solid #234158;
                        padding: 10px 18px;
                        border: none;
                        color: #fff;
                        font-weight: 500;
                        font-size: 14px;
                        border-radius: 3px;
                        outline: 0;
                        margin: 15px 0 10px;
                        text-transform: uppercase;

                        &:hover {
                            background: #007D7F;
                            transition: all 0.5s;
                        }
                    }

                    .dk-addRecuirters {
                        .dk-addMoreUser {
                            font-size: 11px;
                            background: #d5cccc;
                            border-radius: 50%;
                            line-height: 30px;
                            width: 30px;
                            height: 30px;
                            color: #6c6c6c;
                            cursor: pointer;
                            text-align: center;
                            font-weight: 500;
                            display: inline-block;
                            margin: 0 8px;
                        }

                        .dk-addUserDropdown {
                            display: inline-block;

                            .dropdown-toggle {
                                margin: 0;
                                padding: 0;
                                box-shadow: none;
                                border: none;
                                width: 30px;
                                height: 30px;
                                display: inline-block;

                                &::after {
                                    display: none;
                                }

                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }

                            .dropdown-menu {
                                padding: 5px 7px;
                                transform: inherit !important;
                                left: 45px !important;
                                right: inherit !important;
                                box-shadow: none;
                                border: 1px solid #234158;
                                top: -8px !important;
                                background: #234158;

                                &::before {
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 8px 12px 8px 0;
                                    border-color: transparent #234158 transparent transparent;
                                    line-height: 0px;
                                    _border-color: #000000 #cccccc #000000 #000000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                                    position: absolute;
                                    left: -12px;
                                    top: 15px;
                                }

                                a {
                                    img {
                                        width: 35px;
                                        height: 35px;
                                        border-radius: 50%;
                                    }

                                    margin-right: 8px;

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-pipelinesMain.dk-commonPipelinesMain {
    margin-top: 130px;

    .dk-pageheader {
        .dk-backBtn-cols {
            top: 8px;
        }

        h3.title {
            i {
                cursor: pointer;
                width: 20px;
                height: 20px;
                background: #eaeaea;
                font-size: 12px;
                border-radius: 50%;
                line-height: 20px;
                position: relative;
                bottom: 2px;
            }
        }
    }
}

.dk-pipelineInfoModal {
    .modal-dialog {
        .modal-content {
            border-radius: 5px;

            .modal-header {
                border-radius: 4px 4px 0 0;
                background: #234158;
                padding: 10px 12px;

                .modal-title {
                    color: #fff;
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    text-transform: uppercase;
                }

                .close {
                    color: #fff;
                    text-shadow: none;
                    opacity: 1;
                    padding: 0;
                    position: absolute;
                    right: 10px;
                    top: 8px;
                    margin: 0;
                    font-weight: normal;
                    font-size: 28px;
                    outline: none;
                }
            }
        }
    }
}

.dk-pipelinesMain.dk-commonPipelinesMain.dk-dkGlobalPipelinesMain {
    margin-top: 154px;

    @media(max-width:991px) {
        margin-top: 175px;
    }

    .dk-pipelinesTableMain {
        height: calc(100vh - 204px);

        @media(max-width:991px) {
            overflow-x: auto;
        }

        table {
            margin-bottom: 0;

            @media(max-width:991px) {
                width: 1100px;
            }
        }
    }
}


.dk-pipeineNew-mainContainer {
    margin-top: 115px;
    padding: 0 15px 15px;
    height: calc(100vh - 175px);
    overflow-y: auto;

    .title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        display: block;
        text-align: center;
        color: #6e6e6e;
        margin-bottom: 10px;
    }

    .dk-pageheader {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        margin-bottom: 10px;

        h3.title {
            font-weight: 700 !important;
            margin: 0;
            position: inherit;
            left: inherit;
            top: inherit;
            transform: inherit;
            font-size: 19px;
            display: block;
            width: 100%;
            text-align: center;

            i {
                background: #eaeaea;
                border-radius: 50%;
                bottom: 2px;
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                position: relative;
                width: 20px;
                cursor: pointer;
            }

            @media(max-width:767px) {
                font-size: 16px;
            }
        }

        .dk-teamrolerightBtnCon {
            .dk-teamroleseditBtn {
                a {
                    display: inline-block;
                    background: #f2f2f2;
                    color: #333;
                    border: 1px solid #ccc;
                    padding: 6px 12px;
                    white-space: nowrap;
                    border-radius: 3px;
                    outline: 0;
                    width: 100%;
                    text-align: center;
                    position: relative;
                }
            }
        }
    }

    .dk-pipelines-box-6.dk-pipeline-specCols {
        tr {
            td {
                padding: 10.7px 10px;
            }
        }
    }

    .dk-pipelines-box-6 {
        table {
            margin: 0;
            border: none;

            tr {
                td {
                    background: #eaeaea;
                    text-align: center;
                    vertical-align: middle;
                    border-color: #ccc;
                    padding: 8px 10px;

                    &:first-child {
                        white-space: nowrap;
                    }

                    &:last-child {
                        text-align: left;
                    }

                    .dk-pipeline-imgCols {
                        width: 100%;
                        max-width: 21px;
                        min-width: 21px;
                        font-size: 18px;

                        img {
                            width: 100%;
                            max-width: 21px;
                        }
                    }

                    b {
                        font-weight: 600;
                        white-space: nowrap;
                    }
                }

                td.dk-newCvBg {
                    background: #5c737d;
                    color: #fff;
                }

                td.dk-emailBg {
                    background: #796698;
                    color: #fff;
                }

                td.dk-callingBg {
                    background: #57496d;
                    color: #fff;
                }

                td.dk-completedBg {
                    background: #57496d;
                    color: #fff;
                }

                td.dk-intrestedBg {
                    background: #57496d;
                    color: #fff;
                }

                td.dk-sendoutBg {
                    background: #286967;
                    color: #fff;
                }

                td.dk-joboffersBg {
                    background: #629bd3;
                    color: #fff;
                }

                td.dk-recAdminBg {
                    background: #670004;
                    color: #fff;
                }

                td.dk-onHoldBg {
                    background: #4b4b4b;
                    color: #fff;
                }

                td.dk-closedBg {
                    background: #e2445c;
                    color: #fff;
                }

                td.dk-dbreadyBg {
                    background: #e2445c;
                    color: #fff;
                }
            }
        }
    }
}