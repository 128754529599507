.dk-rcWalletSummary-main {
    width: 100%;
    margin-top: 0;
    padding: 0 !important;
    padding-top: 0 !important;

    .dk-rcSummary-tabs {
        background: #244158;
        height: calc(100vh - 171px);
        overflow-y: auto;
        width: 100%;
        max-width: 256px;
        min-width: 256px;

        .nav {
            .nav-item {
                width: 100%;
                display: block;

                .nav-link {
                    border-bottom: 1px solid #3e5b73;
                    color: #ddd;
                    display: block;
                    font-family: Roboto, sans-serif !important;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 10px 15px;
                    text-align: left;
                    text-transform: uppercase;
                    width: 100%;
                }

                &:nth-child(01) {
                    .nav-link.active {
                        background: #3c96b5;
                        color: #fff;
                    }
                }

                &:nth-child(02) {
                    .nav-link.active {
                        background: #2e75b6;
                        color: #fff;
                    }
                }

                &:nth-child(03) {
                    .nav-link.active {
                        background: #26596d;
                        color: #fff;
                    }
                }
            }
        }
    }

    .dk-rcSummary-tabPanel {
        width: 100%;
        padding-right: 0;
        color: #326a80;

        .dk-workmodel-tabCon {
            color: #333;

            .dk-workmodel-tabpane {
                color: #000;

                .dk-rcSummary-main.blue {
                    color: #20588c;

                    .dkg-workModel-mainCon {
                        background-color: #eaeaea;
                        height: calc(100vh - 195px);
                        padding: 25px;
                        margin-top: 0;
                        overflow-y: auto;

                        .dkg-workModel-tableCon {
                            display: flex;
                            width: 100%;

                            .dkg-workModel-table-1 {
                                thead {
                                    tr {
                                        th {
                                            border: 1px solid #bbb;
                                            border-bottom: none;
                                            border-top: none;
                                            padding: 11px 10px;
                                            text-align: center;
                                            text-transform: uppercase;
                                            vertical-align: middle;
                                            white-space: nowrap;
                                            background: #2e75b6;
                                            color: #fff;
                                            font-size: 14px;
                                        }
                                    }
                                }

                                tbody {
                                    tr {
                                        td {
                                            background: #fff;
                                            border: 1px solid #ddd;
                                            padding: 10px 15px;
                                            text-align: center;
                                            vertical-align: middle;
                                            white-space: nowrap;
                                            font-size: 13px;

                                            &:nth-child(02) {
                                                text-align: left;
                                            }

                                            &:nth-child(03) {
                                                text-align: center;
                                            }

                                            .checkCross-icon.text-red {
                                                color: red;
                                            }

                                            .checkCross-icon.text-green {
                                                color: green;
                                            }
                                        }
                                    }
                                }

                                &.model-1 {
                                    thead {
                                        tr {
                                            th {
                                                background-color: #3c96b5;
                                            }
                                        }
                                    }
                                }

                                &.model-3 {
                                    thead {
                                        tr {
                                            th {
                                                background-color: #26596d;
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-workModel-overviewMain {
                                width: 100%;
                                /* max-width: 1000px; */
                                // margin: 30px auto;
                                // margin-top: 30px;
                                padding-top: 15px;

                                .dkg-workmodel-cols {
                                    width: 100%;
                                    // max-width: 30%;
                                    padding: 0 15px;

                                    .title {
                                        display: inline-flex;
                                        justify-content: center;
                                        align-items: center;
                                        color: #fff;
                                        text-transform: uppercase;
                                        width: 100%;
                                        // max-width: 180px;
                                        min-height: 55px;
                                        text-align: center;
                                        position: relative;
                                        font-weight: bold;

                                        .dkg-workmodel-subtitle {
                                            position: absolute;
                                            display: block;
                                            bottom: 17px;
                                        }
                                    }

                                    .workModel-panel {
                                        width: 100%;
                                        cursor: pointer;
                                        height: calc(100vh - 330px);
                                        background-color: #fff;

                                    }
                                }


                            }
                        }
                    }
                }
            }
        }
    }

    .dk-recwalletHead-12 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .dk-recwallet-leftCon {
            display: flex;
            align-items: center;

            .dkg-rec-collect-srchinput {
                display: flex;
                align-items: center;
                position: relative;
                width: 160px;

                .input-group {
                    input {
                        background-color: #eaeaea;
                        border: 1px solid #ddd;
                        text-align: left;

                        &:focus::placeholder {
                            opacity: 0;
                        }
                    }

                    .input-group-append {
                        .btn.btn-secondary {
                            background-color: #eaeaea !important;
                            border: 1px solid #ddd;
                            border-left: none;
                            color: #787878;
                            font-size: 14px;
                            height: 32px;
                            outline: none;
                            width: 30px;
                            margin: 0;
                            padding: 0;
                            border-left: 1px solid #ddd;
                            box-shadow: none;
                        }
                    }
                }

                input {
                    background-color: #eaeaea;
                    border: 1px solid #ddd;
                    text-align: left;

                    &:focus::placeholder {
                        opacity: 0;
                    }
                }

                .dk-searchBtn {
                    background: #eaeaea;
                    outline: none;
                    width: 30px;
                    border: 1px solid #ddd;
                    border-left: none;
                    height: 32px;
                    font-size: 14px;
                    color: #787878;
                }
            }

            .addMonthBtn {
                border: 1px solid #ccc;
                border-radius: 50%;
                color: #7d7d87;
                display: inline-block;
                font-size: 15px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                width: 100%;
                min-width: 35px;
                margin-left: 8px;
                max-width: 35px;
                background: #eaeaea;

                &:hover {
                    background: #7d7d87;
                    color: #fff;
                    transition: all 0.5s;
                }
            }

            .dkg-recwall-recFilterCon {
                .dk-workplanrecDropdown {
                    .dk-workplanrecDropdown-btn {
                        img.dk-target-img {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            border: 1px solid #ddd;
                        }
                    }

                    .dk-workplanrecDropdownMenu {
                        .d-flex {
                            .dropdown-item {
                                .dk-target-img {
                                    width: 32px;
                                    height: 32px;
                                    border-radius: 50%;

                                }
                            }
                        }
                    }
                }
            }

        }

        .dk-recwallet-centerCon {

            // left: 42%;
            // position: absolute;
            // top: 7px;
            // -webkit-transform: translate(-42%);
            // transform: translate(-42%);
            .dk-filterTagsCon {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .dkg-pagetitle {
                    display: inline-block;
                    position: absolute;
                    text-transform: uppercase;
                    white-space: nowrap;
                    font-weight: 600;
                    font-size: 18px;
                    color: rgb(38, 89, 125);
                }

                .dk-filterbtnGrp {
                    margin-top: 2px;

                    .dk-filterbtntext {
                        background: #366995;
                        font-size: 12px !important;
                        color: #fff;
                        padding: 5px 7px;
                        border-top-left-radius: 30px;
                        border-bottom-left-radius: 30px;
                        line-height: 10px;
                        text-transform: inherit;

                        &.btn-primary {
                            background: #366995 !important;
                        }
                    }

                    .dk-filterbtnicon {
                        background: #234158;
                        font-size: 11px;
                        padding: 5px 7px;
                        border-top-right-radius: 30px;
                        border-bottom-right-radius: 30px;

                        &.btn-primary {
                            background-color: #234158 !important;
                        }
                    }
                }
            }
        }

        .dk-recwallet-rightCon {

            .dk-rcWallet-filter {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                align-items: center;

                .dkg-bonus-rightFilter-btnCon {
                    .dkg-bonus-rightFilterIcon {
                        align-items: center;
                        background: #eaeaea;
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        color: #848484;
                        display: inline-flex;
                        height: 35px;
                        justify-content: center;
                        outline: none;
                        width: 35px;
                        box-shadow: none;

                        i {
                            font-size: 14px;
                            margin-top: 3px;
                        }
                    }
                }

                .dk-reWallet-filterCols {
                    min-width: 130px;
                    margin-right: 8px;
                    position: relative;

                    &.dkg-rcper-timeframeFilter {
                        min-width: 120px;

                        div {
                            .dkg-bonus-righticon-Con {
                                display: none;
                            }

                            .dk-cli-status-dropdown {
                                .dropdown-toggle {
                                    min-width: 118px !important;

                                    &::after {
                                        right: 6px;
                                    }
                                }

                                .dropdown-menu {
                                    padding: 0;
                                    position: absolute !important;
                                    right: inherit !important;
                                    left: 0px !important;
                                    min-width: 120px !important;
                                    max-width: 120px !important;

                                    .dropdown-item {
                                        .dropdown-filter-count {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-right: 0;

                        div {
                            .dk-cli-status-dropdown.dropdown {
                                .dropdown-toggle {
                                    justify-content: center;
                                }
                            }
                        }
                    }

                    .dropdown {
                        .dropdown-toggle {
                            padding: 0;
                            color: #333;
                            text-transform: uppercase;
                            box-shadow: none;
                            border: 1px solid #ccc;
                            background: #eaeaea;
                            border-radius: 3px;
                            width: 100%;
                            font-size: 14px;
                            font-weight: 400;
                            margin: 0;
                            margin-bottom: 0;
                            text-align: left;
                            padding-left: 0;
                            display: flex;
                            align-items: center;
                            padding-left: 5px;

                            svg {
                                display: inline-block;
                                width: 30px;
                                font-size: 20px;
                                border-right: 1px solid #ccc;
                                color: #918f8f;
                                padding: 7px;
                                height: 32px;
                                margin-right: 12px;
                            }

                            // &::after {
                            //     display: none;
                            //     color: #000;
                            // }
                        }

                        .dropdown-menu::-webkit-scrollbar {
                            width: 5px;
                        }

                        .dropdown-menu::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        }

                        .dropdown-menu::-webkit-scrollbar-thumb {
                            background-color: #f2f2f2 !important;
                            outline: 0px solid slategrey;
                        }

                        .dropdown-menu {
                            border: 1px solid #ddd;
                            border-radius: 0;
                            width: 100%;
                            min-width: 125px;
                            max-width: 125px;
                            padding: 0;
                            transform: inherit !important;
                            top: 37px !important;
                            margin: 0 !important;
                            right: inherit !important;
                            height: auto;
                            overflow-y: auto;
                            z-index: 99999;
                            background: #f2f2f2;

                            a {
                                display: block;
                                padding: 6px 12px;
                                font-size: 14px;
                                background: #f2f2f2;
                                border-bottom: 1px solid #ddd;

                                .dropdown-filter-count {
                                    font-weight: 600;
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .dk-kpiCase-months {
            display: flex;
            justify-content: space-between;
            margin-left: 0;
            width: 100%;
            // flex-wrap: wrap;
            margin-top: 2px;
            margin-bottom: 1px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                width: 100%;
                flex-wrap: wrap;

                li {
                    display: inline-block;
                    list-style-type: none;
                    width: 100%;
                    max-width: 7.33%;

                    &:first-child {
                        a {
                            border-left: 1px solid #ddd;
                        }
                    }

                    a {
                        background: #eaeaea;
                        border-bottom: 1px solid #ddd;
                        border-right: 1px solid #ddd;
                        border-top: 1px solid #ddd;
                        color: #333;
                        cursor: pointer;
                        float: left;
                        padding: 5px 15px;
                        text-align: center;
                        width: 100%;
                    }

                    a.active {
                        background: #2e75b6;
                        border: 1px solid transparent;
                        color: #fff;

                    }

                }
            }

            &.dkg-placements-months {
                ul {
                    li {
                        a {
                            &.active {
                                background-color: #396a95;
                            }
                        }
                    }
                }
            }

            &.dkg-performance-months {
                ul {
                    li {
                        a {
                            &.active {
                                background-color: #3c97b6;
                            }
                        }
                    }
                }
            }

            .dk-reWallet-filterCols {
                min-width: 130px;
                margin-right: 8px;
                position: relative;

                &.dkg-rcper-timeframeFilter {
                    min-width: 120px;

                    div {
                        .dkg-bonus-righticon-Con {
                            display: none;
                        }

                        .dk-cli-status-dropdown {
                            .dropdown-toggle {
                                min-width: 118px !important;

                                &::after {
                                    right: 6px;
                                }
                            }

                            .dropdown-menu {
                                padding: 0;
                                position: absolute !important;
                                right: inherit !important;
                                left: 0px !important;
                                min-width: 120px !important;
                                max-width: 120px !important;

                                .dropdown-item {
                                    .dropdown-filter-count {
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    margin-right: 0;

                    div {
                        .dk-cli-status-dropdown.dropdown {
                            .dropdown-toggle {
                                justify-content: center;
                            }
                        }
                    }
                }

                .dropdown {
                    .dropdown-toggle {
                        padding: 0;
                        color: #333;
                        text-transform: uppercase;
                        box-shadow: none;
                        border: 1px solid #ccc;
                        background: #eaeaea;
                        border-radius: 3px;
                        width: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0;
                        margin-bottom: 0;
                        text-align: left;
                        padding-left: 0;
                        display: flex;
                        align-items: center;
                        padding-left: 5px;

                        svg {
                            display: inline-block;
                            width: 30px;
                            font-size: 20px;
                            border-right: 1px solid #ccc;
                            color: #918f8f;
                            padding: 7px;
                            height: 32px;
                            margin-right: 12px;
                        }

                        // &::after {
                        //     display: none;
                        //     color: #000;
                        // }
                    }

                    .dropdown-menu::-webkit-scrollbar {
                        width: 5px;
                    }

                    .dropdown-menu::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    }

                    .dropdown-menu::-webkit-scrollbar-thumb {
                        background-color: #f2f2f2 !important;
                        outline: 0px solid slategrey;
                    }

                    .dropdown-menu {
                        border: 1px solid #ddd;
                        border-radius: 0;
                        width: 100%;
                        min-width: 125px;
                        max-width: 125px;
                        padding: 0;
                        transform: inherit !important;
                        top: 37px !important;
                        margin: 0 !important;
                        right: inherit !important;
                        height: auto;
                        overflow-y: auto;
                        z-index: 99999;
                        background: #f2f2f2;

                        a {
                            display: block;
                            padding: 6px 12px;
                            font-size: 14px;
                            background: #f2f2f2;
                            border-bottom: 1px solid #ddd;

                            .dropdown-filter-count {
                                font-weight: 600;
                            }

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            .addMonthBtn {
                border: 1px solid #7d7d87;
                border-radius: 50%;
                color: #7d7d87;
                display: inline-block;
                font-size: 15px;
                height: 35px;
                line-height: 35px;
                margin-left: 12px;
                text-align: center;
                width: 35px;

                &:hover {
                    background: #7d7d87;
                    color: #fff;
                    transition: all .5s;
                }
            }
        }

        .dk-rcWallet-newfilterCon {
            width: 100%;
            display: flex;
            text-align: center;
            margin-bottom: 1px;
            margin-left: 0;
            align-items: center;
            padding: 0;
            justify-content: flex-end;

            .dk-reWallet-newfilterCols {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }

                .dkg-bonus-dropdown-123 {
                    .dkg-bonus-dropdown-toggle {
                        background-color: #eaeaea !important;
                        border: 1px solid #ddd;
                        box-shadow: none !important;
                        color: #333;
                        font-size: 14px;
                        margin: 0;
                        min-width: 130px;
                        padding: 5px;
                        text-align: center;
                        text-transform: uppercase;
                        width: 130px;

                        &::after {
                            margin: 0 0 0 10px;
                        }

                    }

                    .dkg-bonus-dropdown-menu {
                        background: #fff;
                        border: none;
                        border-radius: 0;
                        min-width: 130px;
                        padding: 0;
                        top: 37px !important;
                        -webkit-transform: inherit !important;
                        transform: inherit !important;

                        .dkg-bonus-dropdown-item {
                            background-color: #fff;
                            border: none;
                            border-bottom: 1px solid #ddd;
                            border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 14px;
                            padding: 6px 10px;
                            position: relative;
                            text-transform: capitalize;
                            width: 100%;

                        }
                    }
                }
            }
        }

        .dk-sendouts-months {
            display: flex;
            justify-content: flex-end;
            margin-left: 0;
            width: 100%;
            position: relative;

            .addMonthBtn {
                position: absolute;
                left: 35px;
                border: 1px solid #7d7d87;
                border-radius: 50%;
                color: #7d7d87;
                display: inline-block;
                font-size: 15px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                width: 35px;

                &:hover {
                    background: #7d7d87;
                    color: #fff;
                    transition: all .5s;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                width: 100%;
                justify-content: flex-end;

                li {
                    display: inline-block;
                    list-style-type: none;
                    width: 100%;
                    max-width: 7.66%;

                    &:first-child {
                        a {
                            border-left: 1px solid #616167;
                        }
                    }

                    a {
                        background: #4a5057;
                        border-bottom: 1px solid #616167;
                        border-right: 1px solid #616167;
                        border-top: 1px solid #616167;
                        color: #fff;
                        cursor: pointer;
                        float: left;
                        height: 35px;
                        padding: 7px 10px;
                        text-align: center;
                        width: 100%;
                    }

                    a.active {
                        background: #3c97b6;
                    }

                }
            }
        }
    }



    .dk-reWallet-summaryUser {
        display: inline-block;
        padding: 0;

        img {
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #ddd;
        }
    }

    .dk-reWallet-summaryBox {
        border: 1px solid #ddd;
        border-radius: 5px;
        border: none;

        .title {
            background: transparent;
            border-bottom: none;
            text-transform: uppercase;
            font-weight: 600;
            color: #333;
            font-size: 16px;
            display: block;
            width: 100%;
            text-align: center;
            padding: 8px 10px;
            border-radius: 5px 5px 0 0;
            position: relative;

            span.dk-infoHover {
                color: #20588c;
                line-height: 14px;
                font-size: 16px;
                position: relative;
                bottom: 1px;
                cursor: pointer;
                margin-left: 5px;

                .dk-tooltip {
                    background: rgb(0 0 0 / 88%);
                    padding: 10px;
                    font-size: 12px;
                    border-radius: 3px;
                    text-align: center;
                    font-weight: normal;
                    text-transform: initial;
                    color: #fff;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    width: 100%;
                    min-width: 260px;
                    z-index: 999;
                    top: 30px;
                    display: none;

                    &::before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 7.5px 10px 7.5px;
                        border-color: transparent transparent #000000 transparent;
                        line-height: 0px;
                        _border-color: #ffffff #ffffff #000000 #ffffff;
                        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
                        position: absolute;
                        top: -8px;
                        left: 50%;
                        transform: translate(-50%);
                        z-index: 999;
                    }
                }

                &:hover {
                    color: #3c97b6;

                    .dk-tooltip {
                        display: block;
                    }
                }
            }
        }

        .dk-rcWallet-summaryCols {
            width: 100%;
            border: 1px solid #ddd;

            .dk-rcWallet-colsItem {
                font-size: 36px;
                height: 133px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                border-right: 1px solid #4b90cf;
                color: #fff;

                &:last-child {
                    border-right: none;
                }
            }

            table {
                margin-bottom: 0;
                border: none;

                tr {
                    th {
                        background: #20588c;
                        color: #fff;
                        border-bottom: 1px solid #ccc;
                        vertical-align: middle;
                        text-align: center;
                        text-transform: uppercase;
                        padding: 10px 10px;
                        border-color: #ccc;
                        position: sticky;
                        top: 0;
                        left: 0;
                        z-index: 99;
                        font-weight: 600;

                        &:first-child {
                            border-left: none;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }

                    td {
                        padding: 12px 12px;
                        background: #eaeaea;
                        border: none;
                        border-bottom: 1px solid #ddd;
                        border-right: 1px solid #ddd;
                        text-align: center;
                        vertical-align: middle;
                        width: 25% !important;

                        &:nth-child(01) {
                            width: 170px;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                }

                tfoot {
                    position: sticky;
                    bottom: 0;
                    left: 0;
                    z-index: 99;

                    tr {
                        td {
                            position: sticky;
                            bottom: 0;
                            left: 0;
                            z-index: 99;
                            background-color: #e4e8ec;

                            &:first-child {
                                border: none !important;
                            }

                            &.dkg-bonus-trans-footer {
                                background-color: transparent !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .dk-reWallet-summaryBox {
        width: 100%;
        max-width: 400px;
        margin: 0 15px;
        height: auto;

        p {
            padding: 20px 15px;
            color: #fff;
        }

        &:nth-child(03) {
            margin-right: 6px;

            .title {
                border-bottom: 1px solid #51b0d0;
            }

            .dk-rcWallet-summaryCols {
                .dk-rcWallet-colsItem {
                    border-right: 1px solid #51b0d0;
                }

                table {
                    tr {
                        th {
                            background: #3c97b6;
                        }
                    }
                }
            }
        }
    }


    .dk-rcWallet-summaryTable-main {
        margin-top: 8px;

        .table-responsive {
            height: calc(100vh - 200px);
            overflow-y: auto;
        }

        table {
            tr {
                th {
                    background: #3c97b6;
                    border: 1px solid #a0a0a0 !important;
                    border-bottom: none;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 10px 12px;
                    position: -webkit-sticky;
                    position: sticky;
                    text-align: center;
                    top: 0;
                    vertical-align: middle;
                    white-space: nowrap;
                    z-index: 999;

                    &:nth-child(02),
                    &:nth-child(03),
                    &:nth-child(04),
                    &:nth-child(05),
                    &:nth-child(06),
                    &:nth-child(07),
                    &:nth-child(08),
                    &:nth-child(09),
                    &:nth-child(10) {
                        width: 120px;
                    }

                }

                td {
                    background: #eaeaea;
                    border: 1px solid #ccc;
                    font-size: 13px;
                    font-weight: 400;
                    padding: 5px 8px;
                    position: relative;
                    text-align: center;
                    vertical-align: middle;
                    white-space: nowrap;

                    &:nth-child(01) {
                        width: 80px;
                    }

                    &:nth-child(02),
                    &:nth-child(03),
                    &:nth-child(04),
                    &:nth-child(05),
                    &:nth-child(06),
                    &:nth-child(07),
                    &:nth-child(08),
                    &:nth-child(09),
                    &:nth-child(10) {
                        width: 120px;
                    }

                    &:nth-child(08) {
                        padding: 0 !important;
                    }

                    .dkg-bonus-modelDropDown {
                        .dkg-model-dropdown-toggle {
                            padding: 0;
                            margin: 0;
                            width: 100%;
                            height: 44px;
                            box-shadow: none;
                            text-transform: capitalize;
                            font-size: 13px;

                            &::after {
                                display: none;
                            }
                        }

                        .dkg-model-dropdown-menu {
                            width: 100%;
                            padding: 0;
                            border-radius: 0;
                            border: 1px solid #ddd;
                            min-height: 180px;
                            max-height: 245px;
                            overflow-y: auto;
                            top: -5px !important;

                            &.dkg-perfor-jobtitledropdown-menu {
                                min-width: 200px !important;
                            }

                            .dkg-model-dropdown-item,
                            .dropdown-item {
                                display: block;
                                padding: 6px 12px;
                                font-size: 13px;
                                background: #fff;
                                border-bottom: 1px solid #ddd;

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }

                    .dkg-per-ratingCon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .dk-salesSendouts-users {
                        img {
                            border-radius: 50%;
                            left: 0;
                            position: relative;
                            top: 0;
                            width: 35px;
                            height: 35px;
                        }

                        .dk-recDropdown {
                            .dropdown {
                                .dropdown-toggle {
                                    background-color: transparent !important;
                                    border-radius: 50%;
                                    box-shadow: none !important;
                                    color: #333;
                                    margin: 0;
                                    padding: 0;

                                    &::after {
                                        display: none;
                                    }

                                    img {
                                        border-radius: 50%;
                                        left: 0;
                                        position: relative;
                                        top: 0;
                                        width: 35px;
                                    }
                                }

                                .dropdown-menu {
                                    background: #f2f2f2;
                                    border-radius: 3px;
                                    left: 72px !important;
                                    min-width: -webkit-fit-content;
                                    min-width: -moz-fit-content;
                                    min-width: fit-content;
                                    padding: 0 5px;
                                    right: 0 !important;
                                    top: -4px !important;
                                    -webkit-transform: inherit !important;
                                    transform: inherit !important;
                                    width: -webkit-fit-content;
                                    width: -moz-fit-content;
                                    width: fit-content;

                                    &::after {
                                        border-color: transparent #ccc transparent transparent;
                                        _border-color: #000 #ccc #000 #000;
                                        border-style: solid;
                                        border-width: 8.5px 12px 8.5px 0;
                                        content: "";
                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        height: 0;
                                        left: -12px;
                                        line-height: 0;
                                        position: absolute;
                                        top: 9px;
                                        width: 0;
                                    }

                                    a {
                                        display: inline-block;
                                        margin: 4px 4px;
                                        padding: 0;
                                        width: 30px;

                                        img {
                                            border-radius: 50%;
                                            height: 30px;
                                            width: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .dk-smsTableCheckbox {
                        align-items: center;
                        display: flex;
                        justify-content: center;

                        [type="radio"]:checked+label:after {
                            background-color: #eaeaea;
                            border: 1px solid #ddd;

                        }

                        .chkCandidate {
                            font-size: 15px;
                            width: 15px;
                            height: 15px;
                            border: 1px solid #ddd;

                            &:checked:after {
                                width: 14px;
                                height: 14px;
                                border-radius: 50%;
                                position: relative;
                                top: -3px;
                                left: 0;
                                background-color: #eaeaea;
                                content: '';
                                display: inline-block;
                                visibility: visible;
                                border: 1px solid #ccc;
                            }
                        }

                        [type="radio"]:checked+label:after {
                            background-color: #eaeaea;
                            border: 1px solid #ddd;

                        }

                        .dk-chkSendouts {
                            margin: 0 7px;
                            -webkit-appearance: none;
                            appearance: none;
                            background: #e4e8ec;
                            border: 1px solid #ccc;
                            border-radius: 0;
                            box-sizing: border-box;
                            box-sizing: content-box;
                            cursor: pointer;
                            height: 12px;
                            position: relative;
                            transition: all .3s linear;
                            width: 12px;
                        }
                    }
                }
            }

            tfoot {
                td.transparent {
                    border: none;
                    background: transparent !important;
                }

                td {
                    padding: 11px 8px;
                    font-size: 13px;

                    &.dkg-bonus-footer-weight {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .dk-rcWallet-summaryTable-main.dk-rcWalletSendout-table {
        table {
            thead {
                tr {
                    th {
                        background: #2e75b6;
                        font-size: 15px;

                        &:nth-child(02),
                        &:nth-child(03),
                        &:nth-child(04),
                        &:nth-child(05),
                        &:nth-child(06),
                        &:nth-child(07),
                        &:nth-child(08),
                        &:nth-child(09),
                        &:nth-child(10),
                        &:nth-child(11) {
                            width: 140px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 0;
                        font-size: 13px;

                        .dkg-bonus-input {
                            background-color: #f2f2f2;
                            border: 1px solid #ddd;
                            width: 100%;
                            height: 32px;
                        }

                        &:nth-child(02),
                        &:nth-child(03),
                        &:nth-child(04),
                        &:nth-child(05),
                        &:nth-child(06),
                        &:nth-child(07),
                        &:nth-child(08),
                        &:nth-child(09),
                        &:nth-child(10),
                        &:nth-child(11) {
                            width: 140px;
                        }
                    }
                }
            }

            tfoot {

                tr {
                    td {

                        &:nth-child(01),
                        &:nth-child(02),
                        &:nth-child(03),
                        &:nth-child(04),
                        &:nth-child(05),
                        &:nth-child(06),
                        &:nth-child(07),
                        &:nth-child(08),
                        &:nth-child(09) {
                            background-color: transparent;
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .dk-rcWallet-summaryTable-main.dk-rcWalletPerformance-table {
        table {
            tr {
                th {
                    background: #3c96b5;
                }
            }
        }
    }

    .dk-rcWallet-summaryTable-main.dk-rcWalletPlacement-table {
        .table-responsive {
            table {
                thead {
                    tr {
                        th {
                            background-color: 3C97B6 !important;
                            font-size: 15px;

                            &:nth-child(02),
                            &:nth-child(03),
                            &:nth-child(04),
                            &:nth-child(05),
                            &:nth-child(06),
                            &:nth-child(07),
                            &:nth-child(08),
                            &:nth-child(09),
                            &:nth-child(10),
                            &:nth-child(11) {
                                width: 140px;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding: 0;
                            font-size: 13px;

                            .dkg-bonus-input {
                                background-color: #f2f2f2;
                                border: 1px solid #ddd;
                                width: 100%;
                                height: 32px;
                            }

                            .dkg-per-rartingCon {
                                .dkg-per-rarting-dropdown {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    .dkg-per-rarting-dropdown-toggle {
                                        background-color: transparent !important;
                                        padding: 0;
                                        margin: 0;
                                        box-shadow: none;
                                        color: #333;
                                        font-size: 13px;

                                        &::after {
                                            display: none;
                                        }

                                        .dkg-empty-rating-group {
                                            i {
                                                margin-right: 4px;

                                                &:last-child {
                                                    margin-right: 0;
                                                }
                                            }
                                        }
                                    }

                                    .dkg-per-rarting-dropdown-menu {
                                        // padding: 3px 5px;
                                        // background: #f2f2f2;
                                        // border-radius: 3px;
                                        // transform: inherit !important;
                                        // right: -110px !important;
                                        // left: inherit !important;
                                        // top: -11px !important;
                                        // min-width: 115px;
                                        // max-width: 115px;

                                        // &::before {
                                        //     content: "";
                                        //     width: 0;
                                        //     height: 0;
                                        //     border-color: transparent #ccc transparent transparent;
                                        //     border-style: solid;
                                        //     border-width: 8.5px 12px 8.5px 0;
                                        //     line-height: 0;
                                        //     _border-color: #000 #ccc #000 #000;
                                        //     _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        //     position: absolute;
                                        //     left: -13px;
                                        //     top: 12px;
                                        // }
                                        padding: 3px 5px;
                                        border-radius: 3px;
                                        transform: inherit !important;
                                        right: -110px !important;
                                        left: inherit !important;
                                        top: -11px !important;
                                        min-width: inherit;
                                        max-width: inherit;

                                        span {
                                            font-size: 22px !important;
                                        }

                                    }
                                }
                            }

                            &:nth-child(02),
                            &:nth-child(03),
                            &:nth-child(04),
                            &:nth-child(05),
                            &:nth-child(06),
                            &:nth-child(07),
                            &:nth-child(08),
                            &:nth-child(09),
                            &:nth-child(10),
                            &:nth-child(11) {
                                width: 140px;
                            }
                        }
                    }
                }

                tfoot {

                    tr {
                        td {

                            &:nth-child(01),
                            &:nth-child(02),
                            &:nth-child(03),
                            &:nth-child(04),
                            &:nth-child(05),
                            &:nth-child(06),
                            &:nth-child(07),
                            &:nth-child(08),
                            &:nth-child(09) {
                                background-color: transparent;
                                border: none;
                            }
                        }
                    }
                }
            }
        }

        &.dkg-placementsbonustableCon {
            .table-responsive {
                table {
                    thead {
                        tr {
                            th {
                                background-color: #396a95 !important;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }

        // &.dkg-placementbonus-table234 {
        //     .table-responsive {
        //         table {
        //             tfoot {

        //                 tr {
        //                     td {
        //                         &:nth-child(10) {
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}


.dk-rcWalletSummary-main::-webkit-scrollbar {
    width: 0px;
}

.dk-rcWalletSummary-main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

.dk-rcWalletSummary-main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0px solid slategrey;
}

.dk-rcWalet-informationMain {
    margin-top: 30px;
    padding: 0 5px;

    .dk-reWallet-summaryBox {
        margin: 0;
        max-width: 100%;
    }

    .col-md-6 {
        &:nth-child(02) {
            .dk-rcWallet-summaryCols {
                table {
                    tr {
                        th {
                            background: #3c97b6;
                        }
                    }
                }
            }
        }
    }
}

.dk-rcWallet-bonusTable {
    display: flex;
    margin-top: 2px;
    margin-left: 0;

    .table-responsive {
        overflow-x: hidden;
        margin: 0 18px;

        &:nth-child(01) {
            .dk-rc-wallet-bonusTitle {
                background: #3c97b6;
            }

            table {
                tr {
                    th {
                        background: #287e9c;
                    }

                    td {
                        background: #3c96b5;
                        border-color: #4da3c1;
                    }
                }
            }
        }

        &:nth-child(03) {
            .dk-rc-wallet-bonusTitle {
                background: #26596d;
            }

            table {
                tr {
                    th {
                        background: #1f495a;
                        border-color: #37728a;
                    }

                    td {
                        background: #26596d;
                        border-color: #326a80;
                    }
                }
            }
        }
    }

    .dk-rc-wallet-bonusTitle {
        display: block;
        text-align: center;
        background: transparent !important;
        color: #333;
        text-transform: uppercase;
        font-weight: 500;
        padding: 8px 10px;
        border: none !important;
        border-radius: 3px 3px 0 0;
        font-size: 15px;
    }

    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 0;
        border-top: none !important;

        &:last-child {
            margin-right: 0;
        }

        tr {
            th {
                white-space: nowrap;
                background: #20588c;
                color: #fff;
                text-align: center;
                border-color: #4d8ac2;
                border-bottom: none;
                padding: 12px 10px;
                vertical-align: middle;

                &:nth-child(01) {
                    width: 120px;
                }
            }

            td {
                white-space: nowrap;
                text-align: center;
                padding: 10px 10px;
                vertical-align: middle;
                background: #2e75b6;
                color: #fff;
                border-color: #4d8ac2;
                font-size: 13px;

                .dk-bonusRec-img {
                    display: inline-block;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
                }
            }
        }

        tfoot {
            td.transparent {
                background: transparent !important;
                border: none;
            }

            td {
                background: #eaeaea !important;
                color: #333 !important;
                font-weight: 600;
                border-color: #bebebe !important;
            }
        }
    }
}

.dk-rcWalletSummary-main.dk-rcInformationMain {
    margin-top: 165px;

    .dk-rcWalet-informationMain {
        .col-md-6 {
            &:nth-child(02) {
                .dk-reWallet-summaryBox {
                    .title {
                        span.dk-infoHover {
                            color: #3c97b6;

                            &:hover {
                                color: #20588c;
                            }
                        }
                    }
                }
            }
        }
    }

}

.dk-cursor-p {
    cursor: pointer;
}

.dk-rcWalletInfo-modalMain {
    width: 100%;
    max-width: 400px;

    .modal-content {
        box-shadow: none;

        .modal-header {
            border-bottom: none;
            padding: 0;
            margin: 0;

            button.close {
                padding: 0;
                position: absolute;
                top: 0;
                right: 0;
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #102c46;
                color: #fff;
                text-shadow: none;
                box-shadow: none;
                opacity: 1;
                cursor: pointer !important;
                font-size: 16px;
                line-height: 28px;
                outline: none;
                z-index: 99;

                &:hover {
                    background: #2e75b6;
                    transition: all 0.5s;
                }
            }
        }

        .modal-body {
            background: #20588c;
            color: #fff;
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }
}

.dk-rcWalletSummary-main {
    padding-top: 15px;

    .dkg-workmodel-pageCon-234 {
        .dk-rcSummary-tabCon {
            width: 100%;

            .dk-workmodel-navpills {
                display: flex;
                // justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 0;

                .dk-workmodel-navitem {
                    width: 100%;
                    max-width: fit-content;

                    .dk-workmodel-navlink {
                        background-color: #fff;
                        color: #333;
                        display: inline-block;
                        font-family: Verdana, sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        height: 44px;
                        letter-spacing: .3px;
                        line-height: 44px;
                        text-align: center;
                        transition: all .5s;
                        white-space: nowrap;
                        width: 100%;

                        &.active {
                            color: #3c97b6;
                            position: relative;
                            font-weight: 600;

                            &:after {
                                background: #3c97b6;
                                bottom: -2px;
                                content: "";
                                height: 2px;
                                left: 0;
                                position: absolute;
                                width: 95%;
                            }
                        }
                    }
                }
            }
        }

    }

    .dk-rcSummary-main.blue {
        .dk-rcSummary-flex {
            .title {
                background: #3c96b5;
                color: #fff;
                padding: 38px 0;
            }

            table {
                tr {
                    th {
                        background: #3c96b5;
                        color: #fff;
                    }
                }
            }
        }
    }

    .dk-rcSummary-main.semiblue {
        .dk-rcSummary-flex {
            .title {
                background: #2e75b6;
                color: #fff;
            }

            table {
                tr {
                    th {
                        background: #2e75b6;
                        color: #fff;
                    }
                }
            }
        }
    }

    .dk-rcSummary-main.darkblue {
        .dk-rcSummary-flex {
            .title {
                background: #26596d;
                color: #fff;
            }

            table {
                tr {
                    th {
                        background: #26596d;
                        color: #fff;
                    }
                }
            }
        }
    }

    .dk-rcSummary-main {
        background: #eaeaea !important;
        padding: 15px;
        margin-top: 10px;
        height: calc(100vh - 206px);

        h2.title {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
            text-align: center;
            width: 100%;
            padding: 0 0 12px;
            margin: 0;
        }

        .dk-rcSummary-flex::-webkit-scrollbar {
            width: 0px;
        }

        .dk-rcSummary-flex::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
        }

        .dk-rcSummary-flex::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0px solid slategrey;
        }

        .dk-rcSummary-flex {
            display: flex;
            align-items: flex-start;
            border: none;
            border-right: none;
            margin-bottom: 0;
            background: #fff;
            height: calc(100vh - 260px);
            overflow-y: auto;

            .title {
                text-align: center;
                text-transform: uppercase;
                width: 100%;
                max-width: 230px;
                font-weight: 600;
                padding: 20px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100vh - 171px);

                span {
                    display: block;
                    width: 100%;
                }
            }

            table {
                margin: 0;
                border: none;

                tr {
                    th {
                        border: 1px solid #bbb;
                        border-top: none;
                        border-bottom: none;
                        padding: 11px 10px;
                        text-align: center;
                        vertical-align: middle;
                        background: #eaeaea;
                        text-transform: uppercase;
                        white-space: nowrap;
                        position: sticky;
                        top: 0;
                        left: 0;
                        z-index: 99;
                    }

                    td {
                        border-bottom: none;
                        white-space: nowrap;
                        padding: 10px 15px;
                        text-align: center;
                        vertical-align: middle;
                        border: 0.5px solid #ddd;
                        max-width: 170px;
                        min-width: 170px;
                        background: #fff !important;

                        &:nth-child(02) {
                            text-align: left;
                        }

                        .checkCross-icon {
                            svg {
                                font-size: 15px;
                            }
                        }

                        .checkCross-icon.text-green {
                            color: green;
                        }

                        .checkCross-icon.text-red {
                            color: red;
                        }
                    }
                }
            }
        }
    }
}

.dk-kpiCase-containerMain.dkg-traing-workmodel-pageCon-789 {
    margin-top: 150px !important;
}