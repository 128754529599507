.dk-expMainCon{
    padding-top: 15px;
    .topHead{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .leftCon{
            .title {
                font-size: 16px;
                font-weight: 600;
                color: #333;
                .sbtitle{
                    font-weight: 600;
                    font-size: 14px;
                    margin: 5px 0;
                }
            }
        }
        .rightCon{
            .btnCon{
                a{
                    display: inline-block;
                    background: #f2f2f2;
                    padding: 6px 15px;
                    font-size: 12px;
                    border-radius: 5px;
                    color: #333;
                    font-weight: 600;
                    border: 1px solid #ccc;
                }
            }
        }
    }
    ul.description{
        margin: 15px 0;
        padding: 0;
        list-style: none;
        li {
            list-style-type: none;
            position: relative;
            display: flex;
            margin-bottom: 5px;
            font-size: 14px;
        }
    }
    .dk-expCardCon{
        position: relative;
        margin-bottom: 35px;
        .dk-expCard{
            padding-left: 50px;
            position: relative;
            span.jobSn {
                font-size: 12px;
                position: absolute;
                left: 10px;
                top: 13px;
                z-index: 99;
                color: #fff;
            }
            .dk-viewtable {
                padding: 0 20px;
                background: #f2f2f2;
                margin-bottom: 25px;
                border-radius: 5px;
                border: 1px solid #ddd;
                .dk-viewExpbox { 
                    padding: 8px 0 20px;
                    border-bottom: 1px solid #ddd;
                    .Exptitle {
                        font-weight: 600;
                        font-size: 18px;
                        color: #333;
                        padding: 8px 0;
                        margin-bottom: 6px;
                    }
                    .Exptitle.right{
                        color: #333;
                        font-size: 16px;
                        width: 100%;
                        text-align: left;
                    }
                    table {
                        margin-bottom: 0;
                        width: 95%;
                        border-right: 0 !important;
                        tbody{
                            tr{
                                border: none;
                                td{
                                    background: transparent;
                                    border: none;
                                    text-align: left;
                                    font-size: 13px;
                                    white-space: nowrap;
                                    padding: 0.1rem 0;
                                }
                                td:first-child {
                                    font-weight: 600;
                                    width: 95px;
                                    display: inline-flex;
                                    align-items: center;
                                    color: #333;
                                    i {
                                        margin-right: 7px;
                                    }
                                }
                                td:nth-child(02) {
                                    width: 15px;
                                    text-align: center;
                                }
                                td:last-child {
                                    padding-left: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .dk-expCard:before {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #e9ebef;
            left: -1px;
            top: .4em;
        }
        .dk-expCard:after {
            content: "";
            position: absolute;
            left: 2px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #3c97b6;
            top: 9px;
        }
    }
    .dk-expCardCon:before {
        content: "";
        width: 4px;
        height: 100%;
        background: #3c97b640;
        position: absolute;
        left: 12px;
        top: 0;
        z-index: 99;
    }
    &.dkg-train-achivment-con{
        .dkg-train-achivment-card-con{
            &::before{
                display: none !important;
            }
            .dkg-train-achivment-card{
                &::before{
                    display: none !important;
                }
                &::after{
                    display: none;
                }
                .dk-viewtable{
                    margin-bottom: 0;
                    .dk-viewExpbox{
                        border-bottom: none;
                    }
                }
            }
        }
    }
}