.dkg-active-saved-mainPage {
    margin-top: 120px;

    .dk-addSpec {
        background: #26597d;
        border: none;
        color: #fff;
        margin-right: 0;
        display: inline-block;
        text-transform: uppercase;
        padding: 7px 6px;
        font-weight: 500;
        font-size: 12px;
        outline: none;
        border-radius: 5px;
        min-width: 120px;
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
    }

    h3 {
        color: #000;
        font-weight: 500;
        font-size: 22px;
    }

    input {
        text-align: left;
        border: 1px solid #ced4da !important;
    }

    .dkg-active-saved-mainRow {
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 3px;
        flex-wrap: wrap;
        background-color: #eaeaea;
        padding-bottom: 1rem !important;

        .active {
            background: #fff;
            color: #000;
        }

        .box-active {
            background: #30887E;
            width: 48px;
        }

        .box-inactive {
            background: #E2445B;
            width: 48px;
        }

        .box {
            text-align: center;
            color: #fff;
            border-radius: 3px;
        }

        .padding-8 {
            padding: 0.8rem !important;
            font-weight: bold;
            font-size: 13.2px;
        }

        .list-group-item {
            border-radius: 3px;

            button {
                background: none !important;
                color: #000;
                box-shadow: none;
                padding: 0px;
                margin: 0px;

            }

            .dropdown-toggle::after {
                content: none !important;
            }

            .btn-primary:not([disabled]):not(.disabled):active,
            .btn-primary:not([disabled]):not(.disabled).active,
            .show>.btn-primary.dropdown-toggle {
                background: none !important;
                box-shadow: none !important;
                color: #000;
            }
        }
    }
}