.dk-candprofile-DatePicker-grid{
    .dk-nativedatepicker{
        margin: 0;
        width: 100%;
        .MuiInputBase-root.MuiInput-root{
            border: 1px solid #ccc;
            background-color: #fff;
            padding: 0 7px;
            padding: 0;
            &::before{
                display: none;
            }
            &::after{
                display: none;
            }
            input{
                text-align:left;
                padding: 2px 5px 0;
                font-size: 12px;
                height: 25px;
                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #333;
                    opacity: 1; /* Firefox */
                }
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #333;
                }  
                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #333;
                  }
            }
            .MuiInputAdornment-root.MuiInputAdornment-positionEnd{
                margin: 0;
                .MuiIconButton-root{
                    padding: 5px;
                    padding-right: 3px;
                }
            }
        }
    }
}