.dk-clientsinocustom-containerMain {
    margin-top: 100px;
    display: flex;

    .dk-clientsinocustom-rightPanel {
        margin-top: 0;
        padding: 0 20px;
        width: 100%;

        .dk-sendoutsHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 18px;

            h3.title {
                margin: 0;
                font-size: 18px;
                font-weight: 700;
                position: relative;
                top: 4px;
            }

            .dk-sendoutsAmRc-dropdown {
                margin-left: 8px;
                display: flex;

                .dropdown {
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .dropdown-toggle {
                        background-color: #fff !important;
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        box-shadow: none;
                        font-size: 0.81rem;
                        height: 35px;
                        line-height: 30px;
                        margin: 0;
                        padding: 0 !important;
                        width: 35px;
                        color: #333 !important;
                        min-width: 35px;

                        &:hover {
                            box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
                            outline: 0;
                            transition: 0.5s;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        background-color: #234158;
                        left: 50px !important;
                        padding: 5px;
                        top: -15px !important;
                        transform: inherit !important;
                        margin: 0 !important;

                        &::before {
                            border-color: transparent #234158 transparent transparent;
                            _border-color: #000 #234158 #000 #000;
                            border-style: solid;
                            border-width: 8.5px 12px 8.5px 0;
                            content: "";
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            height: 0;
                            left: -12px;
                            line-height: 0;
                            position: absolute;
                            top: 24px;
                            width: 0;
                        }

                        .dropdown-item {
                            position: relative;
                            display: inline-block;
                            margin: 0 5px;
                            padding: 0;

                            &:hover {
                                background: transparent;
                            }

                            img {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                display: block;
                            }

                            span.recNoCount {
                                color: #fff;
                                display: block;
                                font-size: 12px;
                                padding: 4px 0 0;
                                text-align: center;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .dk-sendoutyears-rightPanel-btn {
                display: flex;
                align-items: center;

                .dk-sensdouts-searchCols {
                    button {
                        display: inline-flex;
                        width: 35px;
                        height: 35px;
                        color: #848484;
                        border: 1px solid #ddd;
                        margin-right: 10px;
                        border-radius: 50%;
                        background: #f3f3f3;
                        align-items: center;
                        justify-content: center;
                        outline: none;

                        &:hover {
                            background: #ccc;
                            color: #333;
                            transition: all 0.5s;
                        }
                    }
                }

                .dropdown {
                    .dropdown-toggle {
                        box-shadow: none;
                        color: #6c757d;
                        font-size: 13px;
                        margin: 0;
                        min-width: 145px;
                        padding: 0.5rem 0.45rem;
                        text-transform: uppercase;
                        background: #f3f3f3;
                        border: 1px solid #ddd;

                        &::after {
                            color: #6c757d;
                            display: block;
                            font-size: 1.2rem;
                            position: absolute !important;
                            position: absolute;
                            right: 10px;
                            top: 16px;
                        }
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        z-index: 99999;
                        margin: 0 !important;
                        transform: inherit !important;
                        top: 35px !important;
                        width: 100%;

                        a {
                            align-items: center;
                            display: flex;
                            font-size: 13px;
                            justify-content: space-between;
                            padding: 8px 10px;
                            color: #fff;

                            .dropdown-filter-count {
                                align-items: center;
                                background-color: #e3e3e3;
                                border-radius: 2px;
                                color: #000;
                                display: flex;
                                font-size: 12px;
                                justify-content: center;
                                padding: 0 5px;
                            }
                        }
                    }

                    .dropdown-menu.dk-yearsDropdown-items {
                        a {
                            background: #fff;
                            border-bottom: 1px solid #ddd;
                            color: #333;

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            .dk-sendoutCaseSt-rightPanel {
                display: flex;
                align-items: center;

                button.dk-filterBtn {
                    border: 1px solid #ddd;
                    background: transparent;
                    margin-right: 10px;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;
                    min-width: 35px;
                    outline: none;

                    &:hover {
                        background: #7d7d87;
                        transition: all 0.5s;
                        color: #374252;
                    }
                }

                .dropdown {
                    .dropdown-toggle {
                        margin: 0;
                        border-radius: 3px;
                        background: #2e75b6;
                        color: #fff;
                        font-size: 13px;
                        box-shadow: none;
                        font-family: "Roboto", sans-serif;
                        padding: 7px 10px;
                        min-width: 110px;

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        transform: inherit !important;
                        min-width: 110px;
                        border-radius: 0;
                        background: #fff;
                        border: none;
                        padding: 0;
                        margin: 35px 0 0 !important;
                        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
                        z-index: 99999;

                        a {
                            padding: 6px 10px;
                            font-size: 13px;
                        }

                        a.active {
                            font-size: 14px;
                            background: #2e75b6;
                        }
                    }
                }
            }

            .dk-clientsinocustom-yearsDropdown {
                display: flex;
                align-items: center;

                .dk-reloadPage {
                    button {
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        color: #7d7d87;
                        display: inline-block;
                        font-size: 13px;
                        height: 35px;
                        line-height: 11px;
                        margin-left: 8px;
                        text-align: center;
                        width: 35px;
                        min-width: 35px;
                        background: #f3f3f3;

                        &:hover {
                            background: #7d7d87;
                            transition: all 0.5s;
                            color: #374252;
                        }
                    }
                }

                .dk-searchBox {
                    max-width: 150px;
                    position: relative;
                    width: 100%;

                    input {
                        background: #f3f3f3;
                        border: 1px solid #ddd;
                        box-shadow: none;
                        color: #a4a4a4;
                        outline: none;
                        text-align: left;

                        &:focus::placeholder {
                            opacity: 0;
                        }
                    }

                    button {
                        background: transparent;
                        border: none;
                        border-left: 1px solid #ddd;
                        box-shadow: none;
                        color: #7d7d87;
                        height: 32px;
                        line-height: 28px;
                        outline: none;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 33px;
                    }
                }

                .addMonthBtn {
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 8px;
                    text-align: center;
                    width: 35px;
                    min-width: 35px;
                    background: #f3f3f3;

                    &:hover {
                        background: #7d7d87;
                        transition: all 0.5s;
                        color: #374252;
                    }

                }

                .dk-reloadPage {
                    .addMonthBtn {
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        color: #7d7d87;
                        display: inline-block;
                        font-size: 15px;
                        height: 35px;
                        line-height: 35px;
                        margin-left: 8px;
                        text-align: center;
                        width: 35px;
                        min-width: 35px;
                        background: #f3f3f3;

                        &:hover {
                            background: #7d7d87;
                            transition: all 0.5s;
                            color: #374252;
                        }

                    }
                }

                .dropdown-toggle {
                    background: #3c97b6 !important;
                    border-radius: 3px;
                    box-shadow: none;
                    color: #fff !important;
                    font-size: 13px;
                    margin: 0;
                    min-width: 110px;
                    padding: 7px 10px;
                    z-index: 99999;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    background: #fff;
                    border: none;
                    border-radius: 0;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    margin: 35px 0 0 !important;
                    min-width: 110px;
                    padding: 0;
                    -webkit-transform: inherit !important;
                    transform: inherit !important;
                    z-index: 99999;

                    a {
                        padding: 6px 10px;
                        font-size: 13px;

                        &:hover {
                            background: #f8f9fa;
                        }
                    }

                    a.active {
                        background: #3c97b6;
                    }
                }
            }

            .dk-sendouts-months {
                display: flex;
                justify-content: flex-end;
                margin-left: 0;
                width: 100%;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;

                    li {
                        display: inline-block;
                        list-style-type: none;

                        &:first-child {
                            a {
                                border-left: 1px solid #616167;
                            }
                        }

                        a {
                            background: #4a5057;
                            border-bottom: 1px solid #616167;
                            border-right: 1px solid #616167;
                            border-top: 1px solid #616167;
                            color: #fff;
                            cursor: pointer;
                            float: left;
                            height: 35px;
                            padding: 7px 10px;
                            text-align: center;
                            width: 75px;
                        }

                        a.active {
                            background: #3c97b6;
                        }

                    }
                }

                .addMonthBtn {
                    border: 1px solid #7d7d87;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;

                    &:hover {
                        background: #7d7d87;
                        color: #fff;
                        transition: all .5s;
                    }
                }
            }
        }


    }

    .dk-clientsinocustom-rightPanel {
        .dk-clientsinocustom-table {
            height: calc(100vh - 180px);
            margin-top: 6px;
            overflow-x: auto;
            overflow-y: auto;
            padding-bottom: 0;
            position: relative;

            table {
                border: none;

                thead {
                    tr {
                        th {
                            background: #5c737d;
                            border: 1px solid #a0a0a0;
                            border-bottom: none;
                            color: #fff;
                            font-size: 13px;
                            text-transform: uppercase;
                            font-weight: 400;
                            padding: 10px 7px;
                            position: -webkit-sticky;
                            position: sticky;
                            text-align: center;
                            top: 0;
                            vertical-align: middle;
                            white-space: nowrap;
                            z-index: 99;
                        }
                    }

                }

                tbody {
                    td {
                        background: #fff;
                        border: 1px solid #ddd;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 5px 8px;
                        position: relative;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        .dk-smsTableCheckbox {
                            .dk-chkSendouts {
                                margin: 0 7px;
                            }
                        }

                        span.dk-clickable-link {
                            cursor: pointer;

                            &:hover {
                                color: #629bd3;
                            }
                        }

                        .dk-sendoutsComment {
                            display: inline-flex;
                            justify-content: center;
                            background-color: #eaeaea;
                            align-items: center;
                            border-radius: 50%;
                            cursor: pointer;
                            height: 25px;
                            line-height: 25px;
                            width: 25px;
                            color: #333;

                            &:hover {
                                background-color: #234158;
                                color: #fff;
                            }
                        }

                        .commentBoxCon {
                            align-items: center;
                            display: flex;
                            justify-content: center;

                            .numCon {
                                background-color: #eaeaea;
                                border-radius: 50%;
                                cursor: pointer;
                                height: 25px;
                                line-height: 25px;
                                width: 25px;

                                &:hover {
                                    background-color: #234158;
                                    color: #fff;
                                }
                            }
                        }

                        &:nth-child(02) {
                            padding: 0;
                            border: 1px solid #989898;
                        }

                        &:nth-child(08) {
                            padding: 0;
                            border: 1px solid #989898;
                        }

                        &:nth-child(09) {
                            padding: 0;
                            border: 1px solid #989898;
                        }

                        &:nth-child(12) {
                            text-align: left;
                        }

                        &:nth-child(14),
                        &:nth-child(15) {
                            font-weight: 600;
                        }

                        .dk-status {

                            background: #316a67;
                            padding: 12px 8px;
                            text-align: center;
                            color: #fff;
                            min-width: 115px;
                        }

                        .dk-stageDropdown {
                            .dropdown {
                                .dropdown-toggle {
                                    color: #fff;
                                    box-shadow: none;
                                    padding: 12px 15px;
                                    width: 100%;
                                    text-align: center;
                                    background: #5c737d;
                                    text-transform: inherit;
                                    font-size: 13px;
                                    margin: 0;
                                    border-radius: 0;
                                    white-space: nowrap;

                                    &::after {
                                        display: none;
                                    }
                                }

                                .dropdown-menu {
                                    padding: 0;
                                    border-radius: 0;
                                    border: none;
                                    margin: 0 !important;
                                    transform: inherit !important;
                                    top: 45px !important;
                                    width: 100%;
                                    min-width: 100%;

                                    a {
                                        padding: 6px 10px;
                                        font-size: 13px;
                                        display: block;
                                        border-bottom: 1px solid #ddd;

                                        &:last-child {
                                            border-bottom: none;
                                        }
                                    }

                                    &.dkg-clientsino-stusdropdowm-menu {
                                        border: none;

                                        a {
                                            border: none;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-tdImg {
                            img {
                                border-radius: 50%;
                                display: inline-block;
                                height: 32px;
                                width: 32px;
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {
                            border: none;
                            background: #fff;
                            font-size: 13px;
                            font-weight: 400;
                            padding: 5px 8px;
                            text-align: center;
                            vertical-align: middle;
                            white-space: nowrap;

                            &:nth-child(02) {
                                background: #f3f3f3;
                                border: 1px solid #ddd;
                            }

                            &:nth-child(03) {
                                background: #f3f3f3;
                                border: 1px solid #ddd;
                            }

                            .totalAmtPaymentTask {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.tooltipText {
    display: none;
    background: #333;
    border-radius: 3px;
    color: #fff;
    display: none;
    font-size: 11px;
    padding: 2px 8px;
    text-transform: capitalize;
    top: 42px;
    white-space: nowrap;
    z-index: 99;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);

    &::after {
        border-color: transparent transparent #333;
        _border-color: #000 #000 #333 #000;
        border-style: solid;
        border-width: 0 7.5px 12px;
        content: "";
        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
        height: 0;
        line-height: 0px;
        top: -11px;
        width: 0;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
}


.dk-sendoutsSearch-modal {
    .modal-dialog {
        max-width: 430px;
        min-width: 430px;
        margin: 0 !important;
        position: fixed;
        width: 100%;

        .modal-content {
            box-shadow: none;
            border: none;

            .modal-header {
                background: #5c737d;
                border-radius: 0;
                color: #fff;
                padding: 10px 0;
                border-bottom: none;

                .modal-title {
                    display: block;
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                    font-family: Segoe UI, Arial, sans-serif;
                }

                .close {
                    color: #fff;
                    font-size: 25px;
                    font-weight: 400;
                    margin: 0 10px;
                    opacity: 1;
                    outline: none;
                    padding: 0;
                    text-shadow: none;
                }
            }

            .modal-body {
                background-color: #234158;
                height: calc(100vh - 45px);
                overflow-y: auto;
                padding: 0;

                .dk-sendoutFilterTabs-main {
                    display: flex;

                    .dk-sendoutTabs-nav {
                        background-color: #eaeaea;
                        max-width: 170px;
                        min-width: 170px;
                        height: calc(100vh - 40px);

                        .nav-item {
                            background-color: #eaeaea;
                            border-bottom: 1px solid #ddd;
                            max-width: 170px;
                            width: 100%;

                            a {
                                border-radius: 0;
                                color: #333;
                                font-size: .95rem;
                                padding: 10px 12px;
                                text-transform: uppercase;
                            }

                            a.active {
                                background-color: #234158;
                                color: #ddd;
                            }
                        }
                    }

                    .dk-sendoutTabs-filterPanel {
                        width: 100%;

                        .dk-panelMainContent {
                            padding: 1.25rem;

                            .dk-Recuriter {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                ul {
                                    list-style: none;
                                    margin-bottom: 0;

                                    li {
                                        margin-bottom: 10px;
                                        margin-top: 15px;

                                        a {
                                            img {
                                                border-radius: 50%;
                                                height: 40px;
                                                width: 40px;
                                            }

                                            .recuriterNn {
                                                color: #ff9800;
                                                margin-left: 8px;
                                                display: inline-block;
                                                margin-left: 8px;
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-categoryList {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                ul {
                                    list-style: none;
                                    margin-bottom: 0;

                                    li {
                                        margin-bottom: 1rem;
                                        margin-top: 1rem;

                                        a {
                                            color: #ccc;

                                            span {
                                                color: #ff9800;
                                                margin-left: 0.5rem;
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-satusListFilter {
                                display: block;

                                ul {
                                    list-style: none;
                                    margin: 0 auto;
                                    max-width: 80%;
                                    width: 100%;

                                    li {
                                        align-items: center;
                                        display: flex;
                                        margin: 15px 0 0;

                                        a {
                                            border-radius: 30px;
                                            color: #fff;
                                            font-size: 13px;
                                            padding: 5px 10px;
                                            text-align: center;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

.dk-sendoutsSearch-modal.modal.right.fade {
    right: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.dk-jobIdModal {
    .modal-dialog {
        max-width: 300px;
        min-width: 300px;
        width: 100%;

        .modal-content {
            box-shadow: none;
            border: none;
            border-radius: 5px;

            .modal-body {
                padding: 25px;

                .form-group {
                    margin: 0 10px 10px;

                    label {
                        display: block;
                        text-align: center;

                        input {
                            border: 1px solid #ddd;
                            min-width: 110px;
                        }
                    }
                }

                .dk-saveBtn {
                    background-color: #26597d;
                    border: none;
                    border-radius: 5px;
                    color: #fff;
                    display: inline-block;
                    outline: none;
                    padding: 8px 22px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.dk-addInvoiceModal-main.dkg-addnew-paymenttask-modal-21 {
    .modal-dialog {
        .modal-content {
            .modal-header {
                padding: 10px 15px !important;
            }
        }
    }

    .dkg-addnew-payment-modalbody-21 {
        .form-group {
            label {
                text-transform: uppercase;
            }

            .dkg-payment-task-drop12 {
                .dropdown-menu {
                    .dropdown-item {
                        text-align: center;
                    }
                }
            }
        }

        button.dkaddBtn {
            display: inline-block;
            // background: #3c97b6;
            background-color: #5c737d;
            border: none;
            color: #fff;
            padding: 8px 25px;
            border-radius: 3px;
            text-transform: uppercase;
            outline: none;

            &:hover {
                background-color: #26597d;
                transition: all 0.5s;
            }
        }
    }
}