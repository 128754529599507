.dk-skillsMain{
    .title{
        text-align: center;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
        font-family: "Poppins", sans-serif !important;
        display: block;
        margin-bottom: 10px;
        color: #7f7f7f;
    }
    .dk-addSkillsMain{
        position: relative;
        &::after{
            content: '';
            width: 100%;
            height: 1px;
            background: #ddd;
            position: absolute;
            top:45px;
            left: 0;
        }
        .dk-addSkills-tabNav{
            width: 100%;
            max-width: 160px;
            .title{
                font-weight: 500;
                display: block;
                text-align: center;
                margin-bottom:0;
                text-transform: uppercase;
                font-family: 'Poppins', sans-serif !important;
                font-size: 15px;
                min-height: 45px;
                padding-top: 16px;
                color: #7f7f7f;
                background: #f2f2f2;
                border-right: 1px solid #ddd;
            }
            .nav{                            
                background: #f2f2f2;
                border: 1px solid #ddd;
                border-left: none;
                border-radius:0;
                display: inherit;
                height: calc(100vh - 360px);
                .nav-item{
                    display: block;
                    width: 100%;
                    a{
                        display: block;
                        width: 100%;
                        padding: 8px 12px;
                        font-size: 13px;
                        border-bottom: 1px solid #ddd;
                        color: #333;
                        position: relative;
                    }
                    a.active{
                        background: #7A8BA2;
                        color: #fff;
                        &::after{
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 7.5px 0 7.5px 12px;
                            border-color: transparent transparent transparent #7A8BA2;
                            line-height: 0px;
                            _border-color: #000000 #000000 #000000 #7A8BA2;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            right: -11px;
                            top: 11px;
                            z-index: 99;
                        }
                    }
                    &:last-child{
                        a{
                            border-bottom: none;
                        }                                
                    }
                }
            }
        }
        .dk-addSkills-tabContainer{
            width: 100%;
            .tab-content{
                height: auto !important;
                overflow-y: inherit !important;
                overflow-x: inherit !important;
            }
            .dk-tabsPanel{
                .title{
                    font-weight: 500;
                    display: block;
                    text-align: center;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-family: "Poppins", sans-serif !important;
                    font-size: 15px;
                    color: #7f7f7f;
                    background: #f2f2f2;
                    min-height: 45px;
                    padding-top: 16px;
                }
                .dk-tabsPanel-flex{
                    display: flex;
                    .dk-tabsPanle-tagsCols{
                        width: 100%;
                        padding: 0 10px;
                        padding-left: 0;
                        position: relative;
                        &::after{
                            content: "";
                            width: 0.5px;
                            height: calc(100vh - 281px);
                            background: #ddd;
                            position: absolute;
                            top: 0;
                            right: 0px;
                            z-index: 99;
                        }
                        &:last-child{
                            border-right: none;
                            padding-right: 0;
                            padding-left: 10px;
                            background: #fff;
                            .title{
                                background: #fff;
                            }
                            &::after{
                                display: none;
                            }
                        }
                        .dk-tabsPanel-tags.dk-currentBg{
                            background: #f2f2f2;
                            padding-left: 24px;
                            height: calc(100vh - 360px);
                        }
                        .dk-tabsPanel-tags{
                            padding-top: 15px;
                            padding-left: 15px;
                            padding-right: 15px;
                            li.dk-currentTags{
                                list-style: none;
                                display: inline-flex;
                                background: #7A8BA2;
                                font-size: 12px;
                                padding: 3px 19px;
                                border-radius: 30px;
                                border: 1px solid #7A8BA2;
                                margin: 3px 3px;
                                position: relative;
                                color: #fff;
                                span{
                                    padding: 0;
                                    margin-left: 5px;
                                    cursor: pointer;
                                    display: none;
                                    position: absolute;
                                    right: 6px;
                                    top: 3px;
                                    color: #fff;
                                }
                                &:hover{
                                    span{
                                        display: inline-block;
                                    }
                                }
                            }

                            li.dk-currentUsedTags{
                                list-style: none;
                                display: inline-flex;
                                background: #d9dcdf;
                                color: #333;
                                font-size: 12px;
                                padding: 3px 19px;
                                border-radius: 30px;
                                border: 1px solid #ddd;
                                margin: 3px 3px;
                                position: relative;
                                span{
                                    padding: 0;
                                    margin-left: 5px;
                                    cursor: pointer;
                                    display: none;
                                    position: absolute;
                                    right: 6px;
                                    top: 3px;
                                    color: #333;
                                }
                                &:hover{
                                    span{
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
}