.dk-smsMessage-selectMsg{
    background: #fff;
    border-radius: 10px;
    box-shadow:0 22px 104px -6px #00000057;
    width: 100%;
    max-width:800px;
    margin: auto;
    position: fixed;
    bottom:40px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    height: 63px;
    .dk-selectNo{
        width: 100%;
        max-width:63px;
        background:#34976e;
        color: #fff;
        font-weight: 500;
        font-size:30px;
        border-radius: 5px 0 0 5px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dk-rowSelected{
        width: 100%;
        font-weight:300;
        font-size: 22px;
        padding-left: 15px;
        border-bottom: 1px solid #ddd;
        padding-bottom:5px;
        .dk-selectedDot{
            line-height:8px;
            span.dk-circle{
                display: inline-block;
                width: 8px;
                height: 8px;
                background:#016e42;
                border-radius: 50%;
                margin-left: 1px;
            }
        }
    }
    .dk-checkAll{
        width: 200px;
        height: 63px;
        border-left: 1px solid #ddd;
        text-align: center;
        font-weight: 400;
        padding: 12px 0;
        cursor: pointer;
        white-space: nowrap;
        i{
            font-size: 17px;
        }
        span{
            display: block;
        }
    }
    &.dkg-mailbox-slectMesage{
        max-width:1030px;
        .dk-selectNo{
            background:#3c97b6;
        }
        .dk-rowSelected{
            min-width: 180px;
            span{
                font-size: 20px;
            }
        }
        .dk-checkAll{
            &:last-child{
                color: red;
            }
        }
    }
}
.dk-smsMessage-selectMsg.activePopup {
    display: block;
}