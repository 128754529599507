.dk-costBenfitMainPageCon{
    margin-top: 125px;
}
.dk-costbenefitBG .dk-costbenSlectYear .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
.dk-costbenefitBG .dk-costbenSlectYear .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
    color: #fff;
    font-size: 12px;
}
.dk-costbenhrTabsCon .dk-costbenTabsCon .nav-pills.dk-costbenTabs .dk-costbenTab.nav-item {
    width: 100%;
    max-width: 8.3335%;
}
.dk-costbenhrTabsCon .dk-costbenTabsCon .nav-pills.dk-costbenTabs .dk-costbenTab.nav-item .nav-link{
    border-radius: 0;
    text-align: center;
    background: #eaeaea;
    color: #333;
    padding: 5px 15px;
    border: 1px solid #ccc;
    border-right: none;
    cursor: pointer;
    border-radius: 0;
    outline: 0;
    height: 35px;
    line-height: 23px;
}
.dk-costbenhrTabsCon .dk-costbenTabsCon .nav-pills.dk-costbenTabs .dk-costbenTab.nav-item .nav-link#left-tabs-example-tab-dec{
    border-right: 1px solid #ccc;
}
.dk-costbenhrTabsCon .dk-costbenTabsCon .nav-pills.dk-costbenTabs .dk-costbenTab.nav-item .nav-link.active,
.dk-costbenhrTabsCon .dk-costbenTabsCon .nav-pills.dk-costbenTabs .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #252c37;
    border: 1px solid #252c37;
    border-right: none;
    border-radius: 0;
}
.dk-costbenefitPageHead{
    margin-bottom: .5rem;
}
.dk-costbenefitPageHead .dk-costbenefitLeft {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.dk-mailshotsSearch .input-group input {
    border: 1px solid #ddd!important;
    background-color: #eaeaea;
    padding: 0 30px 0 10px;
    box-shadow: none!important;
    border-radius: 0;
    height: 35px;
}
.dk-costbenefitPageHead .dk-costbenefitLeft button.dk-cosfilterbtn{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #eaeaea!important;
    color: #333;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
}
.dk-costbenefitSrch{
    display: flex;
    align-items: center;
}
.dk-costbenefitSrch .dk-mailshotsSearch .input-group {
    width: 100%;
    max-width: 140px;
    margin-right: 3px;
}
.dk-costbenefitSrch .dk-mailshotsSearch .input-group {
    width: 100%;
    max-width: 140px;
    margin-right: 3px;
}
.dk-costbenefitSrch .dk-mailshotsSearch .input-group input {
    border: 1px solid #ddd!important;
    background-color: #eaeaea;
    padding: 0 30px 0 10px;
    box-shadow: none!important;
    border-radius: 0;
    height: 35px;
}
.dk-costbenefitSrch button.dk-cosfilterbtn{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #eaeaea!important;
    color: #333;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
}
.dk-costBenfitMainPageCon h2.dk-adsCreditPTitle {
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
}
.dk-costbenefitBG .dk-castbenfrightCon{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.dk-costbenefitBG .dk-costbenefitTopRight button{
    display: inline-block;
    background: #eaeaea;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 0;
    outline: 0;
    font-size: 15px;
    font-weight: 400;
}
.dk-costbenefitBG .dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect {
    min-width: 120px;
    height: 34px;
    background-color: #eaeaea !important;
}
.dk-costbenefitBG .dk-reSLoginFilter .ui.selection.active.dropdown .menu{
    border-radius: 0;
}
.dk-costbenefitBG .dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect:hover{
    background-color: #eaeaea;
}
.dk-costbenefitBG .dk-reSLoginFilter .dk-resloginfilterDrop .dk-resloginfilter-toggle{
    border-radius: 0;
    background-color: #eaeaea;
    border: 1px solid #ddd;
    color: #797474;
    font-size: 12px;
    box-shadow: none;
    margin: 0;
    padding: 0.5rem 0.35rem;
    min-width: 100px;
    text-transform: capitalize;
}
.dk-costbenefitBG .dk-reSLoginFilter  .dk-resloginfilterDrop .dk-resloginfilter-toggle::after{
        position: absolute;
        right: 6px;
        top: 17px;
        color: #797474;
}
.dk-reSLoginFilter .dk-resloginfilterDrop .dk-resloginfilterDropMenu{
    border-radius: 0;
    padding: 0;
}
.dk-reSLoginFilter .dk-resloginfilterDrop .dropdown-item{
        background-color: #eaeaea;
        border-top: 1px solid #ddd;
        padding: 0.5rem 0.4rem;
        font-size: 13px;
}
.dk-reSLoginFilter .dk-resloginfilterDrop .dropdown-item:first-child{
            border-top: none;
}
.dk-reSLoginFilter .dk-resloginfilterDrop .dropdown-item:hover{
            background-color: #f1f1f1;
}
.dk-costbenefitTableCont{
    cursor: pointer;
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100vh - 260px);
    position: relative;
}
.dk-costbenefitTableCont .dk-costbenefitTable{
    margin-bottom: 0;
}
.dk-costbenefitTableCont .dk-costbenefitTable thead tr th {
    background: #252c37;
    color: #e2dada;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 15px;
    border: 1px solid #616767;
    vertical-align: middle;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr td {
    background: #2c394b;
    color: #bdb4b4;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    padding: 5px 15px;
    border: 1px solid #5a6169;
    vertical-align: middle;
}
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr td .dk-costbenAction{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr td .dk-costbenAction a.dk-edit-btn,
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr td .dk-costbenAction a.dk-view-btn,
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr td .dk-costbenAction a.dk-delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    border-radius: 3px;
}
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr td .dk-costbenAction a.dk-edit-btn {
    /* background-color: #fee69c; */
    color: #fee69c;
}
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr td .dk-costbenAction a.dk-delete-btn {
    /* background-color: #dc3545; */
    color: #dc3545;
}
/* .dk-costbenefitTableCont .dk-costbenefitTable tbody tr:last-child td:nth-child(01),
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr:last-child td:nth-child(02),
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr:last-child td:nth-child(03),
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr:last-child td:nth-child(08){
   background-color: transparent;
   border: none;
} */
/* .dk-costbenefitTableCont .dk-costbenefitTable thead tr:last-child th:nth-child(04),
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr:last-child td:nth-child(04),
.dk-costbenefitTableCont .dk-costbenefitTable thead tr:last-child th:nth-child(05),
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr:last-child td:nth-child(05),
.dk-costbenefitTableCont .dk-costbenefitTable thead tr:last-child th:nth-child(06),
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr:last-child td:nth-child(06),
.dk-costbenefitTableCont .dk-costbenefitTable thead tr:last-child th:nth-child(07),
.dk-costbenefitTableCont .dk-costbenefitTable tbody tr:last-child td:nth-child(07){
    color: #e2dada;
    min-width: 80px;
    max-width: 80px;
} */
.dk-costbenefitTableCont{
    position: relative;
}
.dk-costbenefitTableCont tfoot td {
    position:-webkit-sticky;
    position:sticky;
    bottom:0;
    background:#252c37;
    color: #e2dada;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 15px !important;
    border: 1px solid #616767;
    vertical-align: middle;
}
.dk-costbenefitTableCont .dk-costbenefitTable  tfoot tr td:nth-child(01),
.dk-costbenefitTableCont .dk-costbenefitTable  tfoot tr td:nth-child(02),
.dk-costbenefitTableCont .dk-costbenefitTable  tfoot tr td:nth-child(03),
.dk-costbenefitTableCont .dk-costbenefitTable  tfoot tr td:nth-child(08){
   background-color: #fff;
   border: none;
}