.dk-useFilterCon {
    .dropdown {
        button.dk-fliterbtn.dropdown-toggle.btn {
            box-shadow: none;
            background-color: transparent;
            padding: 0 !important;
            margin: 0;

            img {
                border-radius: 50%;
                height: 30px;
                width: 30px;
            }
        }

        button.dk-fliterbtn.dropdown-toggle::after {
            display: none;
        }

        .dk-pipleuserFilterMenu.dropdown-menu {
            padding: 5px;
            left: 22px !important;
            top: -10px !important;
            transform: translate(22px, 0px) !important;
            -webkit-transform: translate(22px, 0px) !important;
            background-color: #fff;

            a.dropdown-item {
                padding: 0;
                display: inline-block;
                width: 35px;
                height: 35px;
                margin: 0 3px;

                .tooltipMain {
                    position: absolute !important;
                }
            }
        }

        .dk-pipleuserFilterMenu.dropdown-menu:after {
            content: "";
            width: 0;
            height: 0;
            border-color: transparent #234158 transparent transparent;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            line-height: 0;
            _border-color: #000 #ccc #000 #000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            position: absolute;
            left: -12px;
            top: 15px;
        }
    }
}