.dk-tasksuMMCon-21 {
    margin-top: 155px;

    .dkg-tasksummboxModule {
        // margin: auto;
        // max-width: 1600px;
        width: 100%;

        .dkg-tasksummboxcols {
            display: flex;
            width: 100%;

            // .dkg-tasksummbox-header-21 {
            //     display: block;

            //     .dkg-tasksummbox-title-21 {
            //         font-size: 20px;
            //         font-weight: 600;
            //         padding: 7px 9px;
            //         width: 100%;
            //         text-align: center;
            //         text-transform: uppercase;
            //         color: #787171;
            //     }
            // }

            .dkg-tasksummbox {
                // align-items: center;
                background: #fff;
                border-radius: 6px;
                // display: flex;
                // justify-content: center;
                margin: 15px;
                // min-width: 25%;
                max-width: 25%;
                min-height: 125px;
                width: 100%;

                // &.dkg-todo-stauts-box-21 {
                //     max-width: 31%;
                // }

                .dkg-tasksummbox-title-21,
                .dkg-casecat-title-21 {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 7px 9px;
                    padding: 0;
                    width: 100%;
                    text-align: center;
                    text-transform: uppercase;
                    color: #787171;
                    background-color: #fff;
                    border-radius: 5px 5px 0 0;
                    margin-bottom: 0;
                    // border: 1px solid #ddd;
                    border-bottom: none;

                    // background-color: #eaeaea;
                    &.dk-without-filter-header {
                        color: #333;
                        height: 35px;
                        line-height: 35px;
                        background-color: #eaeaea;
                        border: 1px solid #ddd;
                        border-bottom: none;

                        .dk-dbsuMM-filterHeader {
                            &.dk-with-filter-header-24 {
                                background-color: #234158;
                                color: #fff !important;
                                border-radius: 5px 5px 0 0;
                                margin-bottom: 0;
                                // border: 1px solid #ddd;
                                border-bottom: none;
                            }
                        }
                    }
                }

                .dkg-tasksummbox-header {
                    width: 100%;
                    height: calc(100vh - 235px);
                    overflow-y: auto;
                    padding-bottom: 0;
                    position: relative;

                    .dkg-tasksummbox-title {
                        font-size: 20px;
                        font-weight: 600;
                        padding: 7px 9px;
                        width: 100%;
                        text-align: center;
                        text-transform: uppercase;
                        color: #787171;
                    }

                    .dkg-tasks-table {
                        margin-bottom: 0;

                        thead {
                            tr {
                                th {
                                    font-size: 11px;
                                    font-weight: 600;
                                    padding: 8px 9px;
                                    width: 100%;
                                    text-align: center;
                                    text-transform: uppercase;
                                    color: #787171;
                                    border: 1px solid #ddd;
                                    white-space: nowrap;
                                    position: sticky;
                                    text-align: center;
                                    top: 0;
                                    vertical-align: middle;
                                    white-space: nowrap;
                                    z-index: 99;
                                    width: 100%;
                                    max-width: 25%;

                                    &.dk-users-leftblockBg {
                                        background-color: #3c97b6;
                                        color: #fff;
                                    }

                                    &.dk-todo-leftblockBg {
                                        background-color: #26597d;
                                        color: #fff;
                                    }

                                    &.dk-inprogress-leftblockBg {
                                        background-color: #d58b5d;
                                        color: #fff;
                                    }

                                    &.dk-completed-leftblockBg {
                                        background-color: #3a9469;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                &:hover {
                                    background-color: #234158;
                                    color: #fff;
                                    cursor: pointer;

                                    td {


                                        .dkg-task-counter {
                                            color: #fff;
                                        }

                                        &:nth-child(02) {
                                            .dkg-task-counter {
                                                color: #fff
                                            }
                                        }

                                        &:nth-child(03) {
                                            .dkg-task-counter {
                                                color: #fff
                                            }
                                        }

                                        &:nth-child(04) {
                                            .dkg-task-counter {
                                                color: #fff
                                            }
                                        }
                                    }

                                    td {
                                        background-color: #234158 !important;
                                        color: #333 !important;
                                        cursor: pointer;
                                    }

                                }

                                td {
                                    padding: 4px 9px;
                                    background-color: #fff;

                                    .dkg-task-counter {
                                        font-size: 14px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        line-height: 32px;
                                        color: #629bd3;
                                    }

                                    .dk-summ-recimgCon {
                                        display: flex;
                                        justify-content: center;
                                        width: 100%;

                                        .dk-summ-recimg {
                                            width: 32px;
                                            height: 32px;
                                            border-radius: 50%;
                                            cursor: pointer;
                                            display: block;
                                        }
                                    }

                                    &:nth-child(02) {
                                        .dkg-task-counter {
                                            color: #234158
                                        }
                                    }

                                    &:nth-child(03) {
                                        .dkg-task-counter {
                                            color: #d58b5d
                                        }
                                    }

                                    &:nth-child(04) {
                                        .dkg-task-counter {
                                            color: #3a9469
                                        }
                                    }
                                }
                            }

                        }
                    }

                    &.dkg-taskstatus-body {
                        background-color: #fff;
                        border: 1px solid #ddd;

                        ul {
                            margin-bottom: 0;

                            li {
                                background-color: #eaeaea;
                                border-radius: 35px;
                                width: 90%;
                                line-height: 30px;
                                font-size: 13px;
                                margin: 14px auto;
                                display: flex;
                                justify-content: space-between;
                                padding: 0 10px;
                                // font-weight: 600;
                                cursor: pointer;

                                &:first-child {
                                    margin-top: 15px;
                                }

                                &:hover {
                                    background-color: #234158 !important;
                                    color: #fff !important;
                                }
                            }
                        }

                        &.dkg-tasktype-qwe24 {
                            ul {
                                li {
                                    margin-top: 9px;
                                    margin-bottom: 9px;
                                }
                            }
                        }
                    }
                }

                .dkg-casecat-header {
                    width: 100%;
                    border-radius: 0 0 6px 6px;

                    .dkg-casecat-title {
                        font-size: 20px;
                        font-weight: 600;
                        padding: 7px 9px;
                        width: 100%;
                        text-align: center;
                        text-transform: uppercase;
                        color: #787171;
                        border: 1px solid #ddd;
                        border-radius: 6px 6px 0 0;
                    }
                }

                .dkg-casecat-body {
                    // .dk-wp-progressBarCon::-webkit-scrollbar {
                    //     width: 0;
                    // }

                    // .dk-wp-progressBarCon::-webkit-scrollbar-track {
                    //     box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
                    // }

                    // .dk-wp-progressBarCon::-webkit-scrollbar-thumb {
                    //     background-color: darkgrey;
                    //     outline: 0 solid slategrey;
                    // }

                    .dk-wp-progressBarCon {
                        padding: 15px;
                        border-radius: 0 0 5px 5px;
                        background-color: #fff;
                        height: calc(100vh - 235px);
                        overflow-y: auto;
                        border: 1px solid #ddd;

                        .dk-wp-hrprogressListCon {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 14px !important;
                            // font-weight: 600;

                            // background-color: #eaeaea !important;
                            &:hover {
                                background-color: #234158 !important;
                                cursor: pointer;

                                .dk-wp-hrprogressList {
                                    background-color: #234158 !important;
                                    color: #fff;

                                    .dk-wp-hrprogressList-Label {
                                        color: #fff !important;
                                    }
                                }

                                .dk-wp-bar-numberTag {
                                    background-color: #234158 !important;
                                    color: #fff;
                                }
                            }

                            &:first-child {
                                margin-top: 0 !important;
                            }

                            .dk-wp-hrprogressList {
                                background-color: #eaeaea !important;

                                .dk-wp-hrprogressList-Label {
                                    padding: 5px 15px;
                                    color: #234158 !important;
                                    white-space: nowrap;
                                }
                            }

                            .dk-wp-bar-numberTag {
                                background-color: #eaeaea !important;
                                border-radius: 30px;
                                width: 40px;
                                text-align: center;
                                border-top-right-radius: 50px;
                                border-bottom-right-radius: 50px;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                color: #234158;
                            }
                        }

                        &.dkg-taskprogress-color {
                            .dk-wp-hrprogressListCon {
                                &:nth-child(01) {
                                    background-color: #3a9469 !important;
                                    color: #fff;

                                    &:hover {
                                        background-color: #234158 !important;
                                        cursor: pointer;

                                        .dk-wp-hrprogressList {
                                            background-color: #234158 !important;
                                            color: #fff;

                                            .dk-wp-hrprogressList-Label {
                                                color: #fff !important;
                                            }
                                        }

                                        .dk-wp-bar-numberTag {
                                            background-color: #234158 !important;
                                            color: #fff;
                                        }
                                    }

                                    .dk-wp-hrprogressList {
                                        background-color: #3a9469 !important;

                                        .dk-wp-hrprogressList-Label {
                                            padding: 5px 15px;
                                            color: #fff !important;
                                        }
                                    }

                                    .dk-wp-bar-numberTag {
                                        background-color: #3a9469 !important;
                                        color: #fff;
                                    }
                                }

                                &:nth-child(02) {
                                    background-color: #d58b5d !important;
                                    color: #fff;

                                    &:hover {
                                        background-color: #234158 !important;
                                        cursor: pointer;

                                        .dk-wp-hrprogressList {
                                            background-color: #234158 !important;
                                            color: #fff;

                                            .dk-wp-hrprogressList-Label {
                                                color: #fff !important;
                                            }
                                        }

                                        .dk-wp-bar-numberTag {
                                            background-color: #234158 !important;
                                            color: #fff;
                                        }
                                    }

                                    .dk-wp-hrprogressList {
                                        background-color: #d58b5d !important;

                                        .dk-wp-hrprogressList-Label {
                                            padding: 5px 15px;
                                            color: #fff !important;
                                        }
                                    }

                                    .dk-wp-bar-numberTag {
                                        background-color: #d58b5d !important;
                                        color: #fff;
                                    }
                                }

                                &:nth-child(03) {
                                    background-color: #625c60 !important;
                                    color: #fff;

                                    &:hover {
                                        background-color: #234158 !important;
                                        cursor: pointer;

                                        .dk-wp-hrprogressList {
                                            background-color: #234158 !important;
                                            color: #fff;

                                            .dk-wp-hrprogressList-Label {
                                                color: #fff !important;
                                            }
                                        }

                                        .dk-wp-bar-numberTag {
                                            background-color: #234158 !important;
                                            color: #fff;
                                        }
                                    }

                                    .dk-wp-hrprogressList {
                                        background-color: #625c60 !important;

                                        .dk-wp-hrprogressList-Label {
                                            padding: 5px 15px;
                                            color: #fff !important;
                                        }
                                    }

                                    .dk-wp-bar-numberTag {
                                        background-color: #625c60 !important;
                                        color: #fff;
                                    }
                                }

                                &:nth-child(04) {
                                    background-color: #26597d !important;
                                    color: #fff;

                                    &:hover {
                                        background-color: #234158 !important;
                                        cursor: pointer;

                                        .dk-wp-hrprogressList {
                                            background-color: #234158 !important;
                                            color: #fff;

                                            .dk-wp-hrprogressList-Label {
                                                color: #fff !important;
                                            }
                                        }

                                        .dk-wp-bar-numberTag {
                                            background-color: #234158 !important;
                                            color: #fff;
                                        }
                                    }

                                    .dk-wp-hrprogressList {
                                        background-color: #26597d !important;

                                        .dk-wp-hrprogressList-Label {
                                            padding: 5px 15px;
                                            color: #fff !important;
                                        }
                                    }

                                    .dk-wp-bar-numberTag {
                                        background-color: #26597d !important;
                                        color: #fff;
                                    }
                                }


                            }
                        }

                        &.dkg-taskcategory-color {
                            .dk-wp-hrprogressListCon {
                                background-color: #3c97b6 !important;
                                color: #fff;
                                margin-top: 10px;

                                &:hover {
                                    background-color: #234158 !important;
                                    cursor: pointer;

                                    .dk-wp-hrprogressList {
                                        background-color: #234158 !important;
                                        color: #fff;

                                        .dk-wp-hrprogressList-Label {
                                            color: #fff !important;
                                        }
                                    }

                                    .dk-wp-bar-numberTag {
                                        background-color: #234158 !important;
                                        color: #fff;
                                    }
                                }

                                .dk-wp-hrprogressList {
                                    background-color: #3c97b6 !important;

                                    .dk-wp-hrprogressList-Label {
                                        padding: 5px 15px;
                                        color: #fff !important;
                                    }
                                }

                                .dk-wp-bar-numberTag {
                                    background-color: #3c97b6 !important;
                                    color: #fff;
                                }
                            }
                        }

                        &.dkg-task-type-color {
                            .dk-wp-hrprogressListCon {
                                background-color: #C56A5D !important;
                                color: #fff;
                                margin-top: 10px;

                                &:hover {
                                    background-color: #234158 !important;
                                    cursor: pointer;

                                    .dk-wp-hrprogressList {
                                        background-color: #234158 !important;
                                        color: #fff;

                                        .dk-wp-hrprogressList-Label {
                                            color: #fff !important;
                                        }
                                    }

                                    .dk-wp-bar-numberTag {
                                        background-color: #234158 !important;
                                        color: #fff;
                                    }
                                }

                                .dk-wp-hrprogressList {
                                    background-color: #C56A5D !important;

                                    .dk-wp-hrprogressList-Label {
                                        padding: 5px 15px;
                                        color: #fff !important;
                                    }
                                }

                                .dk-wp-bar-numberTag {
                                    background-color: #C56A5D !important;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}