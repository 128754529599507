.dkg-myacc-dashboard-main-Con {
    margin-top: 152px;
    background-color: #eaeaea;
    padding: 75px 15px;
    padding-bottom: 35px;
    height: 100vh;
    .dkg-dashboard-mainCon {
        .dkg-dashboard-boxCon {
            .dkg-dashboard-leftCon {
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 5px;
                padding: 0;

                .rtitle {
                    padding: 0 10px;
                    font-size: 18px;
                    text-align: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    height: 45px;
                    line-height: 45px;
                    text-transform: uppercase;
                    background: #fff;
                    color: #333;
                    border-bottom: 1px solid #ddd;
                }

                .dkg-dashboard-leftformCon {
                    padding: 15px;

                    .form-group {
                        align-items: center;
                        display: flex;

                        .dkg-mdiumsize-label {
                            min-width: 140px;
                            white-space: nowrap;
                            font-size: 13px;
                            text-transform: uppercase;
                        }

                        .dkg-cand-prescreen-dropdown {
                            width: 100%;

                            .dkg-cand-prescreen-dropdown-toggle {
                                margin: 0;
                                margin: 0;
                                padding: 9px 10px;
                                background-color: #fff;
                                box-shadow: none;
                                border-radius: 0;
                                border: 1px solid #ddd;
                                width: 100%;
                                text-transform: capitalize;
                                display: flex;
                                justify-content: center;

                                &::after {
                                    position: absolute;
                                    top: 18px;
                                    right: 15px;
                                }
                            }

                            .dkg-cand-prescreen-dropdown-menu {
                                width: 100%;
                                padding: 0;
                                border-radius: 0;

                                .dkg-prescreen-dropdown-item {
                                    border-bottom: 1px solid #ddd;
                                    padding: 7px 10px;
                                    font-size: 13px;

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }

                        input {
                            background-color: #eaeaea;
                            border: 1px solid #ddd;
                            height: calc(1.5em + 0.75rem + 6px);
                            text-align: left;
                            width: 100%;
                            font-size: 14px;
                            text-align: center;
                        }

                        .dkg-datejoinedCon {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            width: 100%;

                            .MuiFormControl-root {
                                &.MuiFormControl-marginNormal {
                                    margin: 0;
                                    width: 100%;
                                }

                                .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                    border: 1px solid #ddd;

                                    &::before {
                                        border-bottom: none;
                                    }

                                    input {
                                        text-align: center;
                                        background-color: #eaeaea;
                                        height: calc(0.5em + 0.75rem + 6px) !important;
                                        border: none !important;
                                    }

                                    .MuiInputAdornment-positionEnd {
                                        margin-left: 0;

                                        .MuiIconButton-root {
                                            padding: 11px 7px;
                                            background-color: #918f8f;
                                            border-radius: 0;
                                            border: 1px solid #ccc;
                                            border-left: 0;

                                            .MuiIconButton-label {
                                                svg {
                                                    color: #fff;
                                                    font-size: 1.25rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .dkg-readonly-datetime-picker {
                                color: #234158;

                                .MuiFormControl-root {
                                    &.MuiFormControl-marginNormal {
                                        margin: 0;
                                        width: 100%;
                                    }

                                    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                        input {
                                            text-align: center;
                                            background-color: #f2f2f2;
                                        }

                                        .MuiInputAdornment-positionEnd .MuiIconButton-root {
                                            cursor: text;
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-myAccountDashboard {
    position: fixed;
    width: 100%;
    margin: auto;
    padding: 15px;
    margin-top: 110px;
    height: calc(100vh - 110px);
    background-color: #eaeaea;
    .dkg-page-header{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        .dkg-page-title{
            text-align: center;
            font-size: 1.1rem;
            width: 100%;
            margin-bottom: 10px;
            color: #333;
            display: none;
            
        }

    }
    .dk-dashboard-box {
        background: #fff;
        border-radius: 5px;
        margin: 10px;
        width: 100%;
        border: 1px solid #ddd;
        height: inherit;
        .title {
            padding: 8px 15px;
            border-bottom: 1px solid #ccc;
            border-radius: 5px 5px 0 0;
            display: block;
            text-align: center;
            background: #ddd;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: normal;
            color: #6c6b6b;

            .dkg-iconCon {
                position: relative;
                top: -2px;
                left: 7px;

                svg {
                    width: 15px;
                    height: 15px;
                    display: none;
                }
            }
        }

        .dk-dashboard-panel {
            // min-height: 228px;
            .dkg-acc-status-ul{
                list-style: none;
                margin-bottom: 0;
                width: 100%;
                height: calc(100vh - 455px);
                overflow-y: auto;   
                padding-bottom: 5px;     
                background-color: #fff;
                .dkg-acc-status-il{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 8px 10px;
                    .dkg-acc-status-lefttitle{
                        font-size: 13px;
                        color: #445;
                    }
                    .dkg-acc-status-righttitle{
                        color: #2e97b6;
                        font-size: 13px;
                        .dkg-acc-free-btn{
                            display: inline-block;
                            background-color: #9d265c;
                            color: #fff;
                            padding: 5px 7px;
                            border-radius: 5px;
                            font-size: 12px;
                            text-transform: uppercase;
                            margin-left: 0.5rem;
                        }
                    }
                    &:first-child{
                        padding-top: 12px;
                    }
                    &.dkg-cnacc-paidservice-li{
                        padding: 0;
                        // justify-content: flex-start;
                        .dkg-acc-status-lefttitle{
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .dkg-paid-amoun-col{
                                min-width: 60px;
                                max-width: 60px;
                                text-align: center;
                                // background-color: #f2f2f2;
                                padding: 8px 10px;
    
                            }
                        }
                        .dkg-acc-status-righttitle{
                            padding: 8px 10px;
                        }
                    }
                }
                &.dkg-cnacc-paidservice-ul{
                    height: calc(100vh - 243px);
                    margin-bottom: 0;
                    padding-bottom: 0;
                    .dkg-cnacc-paidservice-li{
                        border-bottom: 1px solid #ddd;
                        padding: 0 !important;
                        justify-content: inherit;
                        height: 100%;
                        max-height: 8.333%;
                        .dkg-acc-status-righttitle{
                            width: 120px;
                            background-color: #f2f2f2;
                            padding: 10px;
                            height: 100%;
                        
                        }
                        .dkg-acc-status-lefttitle{
                            padding: 10px;
                            height: 100%;
                        
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
                &.dkg-account-info-ul{
                    max-height: 170px;
                    overflow-y: auto;
                }
            }
            .dkg-unread-tableCon{
                .dkg-unread-table{
                    thead{
                        tr{
                            th{
                                background-color: #fff;
                                border: none;
                                color: #333;
                                font-size: 13px;
                                padding: 9px 7px;
                                position: -webkit-sticky;
                                position: sticky;
                                text-align: center;
                                top: 0;
                                vertical-align: middle;
                                white-space: nowrap;
                                z-index: 9;
                                text-transform: uppercase;
                            
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                               background-color: #fff;
                                border: 1px solid #ddd;
                                color: #4d4a4a;
                                font-size: 13px;
                                font-weight: 400;
                                padding: 0 7px!important;
                                position: relative;
                                text-align: center;
                                text-transform: capitalize;
                                vertical-align: middle;
                                white-space: nowrap;
                                .dkg-unread-imgCon{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    .dkg-unread-img{
                                        width: 32px;
                                        height: 32px;
                                        border-radius: 50%;
                                        object-fit: cover;
                                        border: 1px solid #ddd;
                                    }
                                }
                                .dkg-unread-statusCol{
                                    height: 37px;
                                    line-height: 37px;
                                }
                                &:first-child{
                                    border-left: none;
                                }
                                &:last-child{
                                    padding: 0 !important;
                                    border-right: none !important;

                                }
                            }
                        }
                    }
                }
            }
            .dkg-unread-body-cols{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 140px;
                .dkg-unread-blockNo{
                    font-size: 48px;
                    font-weight: bold;
                    color: #2e97b6;
                    cursor: pointer;
                    &:hover {
                        color: #2e97b6;
                        text-shadow: 0 0 5px #03e9f4,
                        0 0 25px #03e9f4,
                        0 0 50px #03e9f4,
                        0 0 100px #03e9f4;
                    }
                    &.dkg-navyblue-color{
                        color: #396a95;
                        &:hover {
                            color: #1f394c;
                            text-shadow: 0 0 5px #03e9f4,
                            0 0 25px #03e9f4,
                            0 0 50px #03e9f4,
                            0 0 100px #03e9f4;
                        }
                    }
                    &.dkg-navyblue-color{
                        color: #228b57;
                        &:hover {
                            color: #228b57;
                            text-shadow: 0 0 5px #228b57,
                            0 0 25px #228b57,
                            0 0 50px #228b57,
                            0 0 100px #228b57;
                        }
                    }
                }
            }
            .dkg-cn-dashboar-ul{
                margin-bottom: 0;
                .dkg-cn-dashboar-li{
                    list-style: none;
                    border-bottom: 1px solid #ddd;
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    justify-content: space-between;
                    list-style-type: none;
                    padding: 8px 10px;
                    text-align: left;
                    width: 100%;
                    .dkg-leftItem{
                        color: #445;
                    }
                    .dkg-rightItem{
                        color: #2e97b6;
                        display: inline-block;
                        font-size: 13px;
                        text-align: center;
                        a{
                            color: #2e97b6;
                        }
                    }
                    &:last-child{
                        border-bottom: none;
                    }
                }
            }
        }
        &.dkg-first-rowbox{
            height: inherit;
        }
        &.dkg-account-benfits-box{
            // height: calc(100vh - 205px) !important;
            .dk-dashboard-panel {
                &.dkg-account-benfits-panel{
                    .dkg-account-benfits-table-con{
                        height: calc(100vh - 237px);
                        overflow-y: auto;
                        .dkg-account-benfits-table{
                            border: none !important;
                            height: 100%;
                            margin-bottom: 0;
                            tbody{
                                height: 100%;
                                tr{
                                    td{
                                        border-bottom: 1px solid #ddd;
                                        padding: 10px;
                                        text-align: left;
                                        vertical-align: middle;
                                        &:nth-child(1){
                                            border-left: none;
                                            background-color: #f2f2f2;
                                            .dkg-benifts-iconCon{
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                .dkg-benifts-icon{
                                                    width: 22px;
                                                    height: 22px;
                                                }
                                            }
                                        }
                                        &:nth-child(2) {
                                            width: 25%;
                                            background-color: #f2f2f2;
                                            color: #445;
                                            font-size: 14px !important;
                                            text-transform: capitalize;
                                            border: 1px solid #ddd;
                                            border-left: none;
                                        }
                                        &:nth-child(03){
                                            border-left: none;
                                            border-right: none;
                                            background-color: #f2f2f2;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-bottom: none;
                                            height: 100%;
                                            .dkg-benifts-tf-iconCon{
                                                width: 22px;
                                                height: 22px;
                                                .dkg-benifts-tf-icon{
                                                    svg{
                                                        width: 16px;
                                                        height: 16px;
                                                    }
                                                    img{
                                                        width: 22px !important;
                                                        height: 22px !important;
                                                    }
                                                }
                                            }
                                        }
                                        &:nth-child(04){
                                            border-right: none;
                                        }
                                    }
                                    &:last-child{
                                        td{
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .d-flex{
        .w-100{
            max-width: 1600px;
            margin: 0 auto;
            .d-flex{
                .dk-dashboard-box{
                    &:nth-child(01),
                    &:nth-child(05){
                        margin-left: 0;
                    }
                    &:nth-child(04),
                    &:nth-child(08){
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .dkg-cnacc-dash-mainCon{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        .dkg-cnacc-dash-firstCols{
            width: 23.5%;
            height: calc(100vh - 196px);
            overflow-y: auto;
            border: none;
            background-color: transparent;
            .dkg-cnacc-firstbox-Col{
                height: 100%;
                display: flex;
                flex-direction: column;
                .dkg-first-rowbox{
                    margin-left: 0;
                    border: 1px solid #ddd;
                    background-color: #fff;
                    // overflow-y: auto;
                    border-radius: 5px;
                    flex-grow: 1;
                    .dk-dashboard-panel{
                        .dkg-acc-status-ul.dkg-account-info-ul{
                            height: inherit !important;
                        }
                    }
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .dkg-cnacc-dash-firstCols{
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            &:first-child{
                margin-left: 0 !important;
            }
          }
        // .dkg-cnacc-dash-secondCols{
        //     margin-left: 5px;
        // }
        .dkg-cnacc-dash-secondCols{
            width: 35%;
            .dk-dashboard-panel {
                &.dkg-account-benfits-panel{
                    .dkg-account-benfits-table-con{
                        height: calc(100vh - 237px);
                        overflow-y: auto;
                        .dkg-account-benfits-table{
                            border: none !important;
                            height: 100%;
                            margin-bottom: 0;
                            tbody{
                                height: 100%;
                                tr{
                                    td{
                                        border-bottom: 1px solid #ddd;
                                        padding: 10px;
                                        text-align: left;
                                        vertical-align: middle;
                                        &:nth-child(1) {
                                            width: 25%;
                                            background-color: #f2f2f2;
                                            color: #445;
                                            font-size: 14px !important;
                                            text-transform: capitalize;
                                            border: 1px solid #ddd;
                                            border-left: none;
                                        }
                                        &:nth-child(03){
                                            border-right: none;
                                            text-align: center;
                                        }
                                    }
                                    &:first-child{
                                        border-top: none;
                                    }
                                    &:last-child{
                                        td{
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .dkg-cnacc-dash-threeCols{
            width: 53%;
        }
        .dkg-cnacc-dash-threeCols{
            margin-right: 0;
            margin-left: 10px;
        }
    }

}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-myAccountDashboard{
        height: 100%;
        margin-top: 87px;
        padding-top: 15px;
        .d-flex{
            &.dkg-cn-acc-dashboard-col{
                flex-direction: column !important;
                .dkg-cn-acc-dashboard-leftcol{
                    .d-flex.w-100{
                        display: block !important;
                        .dk-dashboard-box{
                            margin: 0;
                            margin-bottom: 15px;
                            &:last-child{
                               margin-bottom: 0; 
                            }
                        }
                        &:last-child{
                            .dk-dashboard-box{
                                &:first-child{
                                    margin-top: 15px;
                                }
                            }
                        }
                    }
                }
                
            }
            
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}