.dkg-pipe-cvparsingModal-11 {
    color: #333;

    .modal-dialog {
        min-width: 75%;

        .modal-content {
            border-radius: 5px;
            border: none;
            background-color: transparent;

            .dkg-pipe-cvparsingModalHeader-11 {
                color: #fff;
                background: #26597d;
                padding: 8px 10px;
                border-radius: 5px 5px 0 0;

                .modal-title {
                    margin-top: 0;
                    font-size: 17px;
                    font-weight: 500;
                    font-family: Roboto, sans-serif;
                    text-align: center;
                    width: 100%;
                    text-transform: uppercase;
                    color: #fff;
                }

                .close {
                    font-size: 1.58em;
                    padding: 0px;
                    right: 10px;
                    position: absolute;
                    top: 4px;
                    opacity: 1;
                    color: #fff !important;
                    text-shadow: none;
                    outline: none;
                    font-weight: normal;
                    padding: 0.35rem;
                    margin: 0;

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }

            .dkg-pipe-cvparsingModalBody-11 {
                color: #333;
                background-color: #fff;
                padding: 0;
                border-radius: 0 0 5px 5px;

                .dk-candinateParseMain {
                    margin: 0;
                    padding: 25px 15px;

                    .col-md-11.m-auto.dk-addnewTabsMain {
                        height: calc(100vh - 230px);

                        .tab-content {
                            .tab-pane {
                                .dkcabparseFormCon {
                                    height: calc(100vh - 300px);
                                    overflow-y: auto;

                                    &.dkg-manualpasring-tab {
                                        // display: flex;
                                        // justify-content: center;
                                        // align-items: center;

                                        .dkcabparseFormTitle {
                                            h3 {
                                                text-transform: uppercase;
                                                font-size: 20px;
                                                font-weight: 600;
                                                margin-bottom: 0;
                                                text-align: center;
                                                padding-bottom: 0 !important;
                                            }
                                        }

                                        form {
                                            width: 100%;

                                            .dkg-manual-vertical-Con {
                                                color: #333;
                                            }
                                            .manual-frmcontrol{
                                                border: 1px solid #ddd;
                                                text-align: left;
                                                height: 40px;
                                            }
                                        }
                                    }

                                    form {
                                        .dk-parseImgBrowserCon.hv-auto {
                                            height: calc(100vh - 500px);
                                        }
                                    }

                                }

                                .dkg-cvparsing-boxCon {
                                    padding-top: 35px;
                                    background-color: #eaeaea;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                    height: calc(100vh - 300px);
                                    border: 1px solid #ddd;
                                    border-radius: 5px;

                                    .dk-duplicateFormCon {
                                        color: #000;

                                        .dk-duplicateFormGruop {
                                            margin-bottom: 1.25rem;
                                            position: relative;

                                            .dkg-duplicate-title {
                                                text-align: center;
                                                width: 100%;
                                                color: #848484;
                                            }

                                            input.form-control {
                                                border: 1px solid #ddd;
                                                font-size: 15px;
                                                padding: 20px 15px;
                                                border-radius: 0;
                                                position: relative;

                                                &:focus {
                                                    box-shadow: none;
                                                    outline: 0;
                                                }
                                            }

                                            .dkg-crosssbtnCon14 {
                                                position: absolute;
                                                right: -8px;
                                                top: 9px;
                                                cursor: pointer;
                                            }
                                        }

                                        .duplicateBtnCon {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            margin-bottom: 0.5rem;
                                            flex-direction: row;

                                            button.dk-srchBtn {
                                                color: #fff;
                                                background-color: #485569;
                                                padding: 7px 15px;
                                                border-radius: 5px;
                                                font-size: 14px;

                                                &:hover {
                                                    outline: 0;
                                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                                }
                                            }

                                            button.dk-srchBtn.clear {
                                                color: #333;
                                                background-color: #FFF;
                                                // border: 1px solid #485569;
                                                box-shadow: none;
                                                margin-right: 20px;
                                                padding: 8px 15px;

                                                &:hover {
                                                    outline: 0;
                                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                                }
                                            }
                                        }

                                        .no-duplicate {
                                            padding: 0;
                                            margin: 0;
                                            font-weight: bolder;
                                            color: green;
                                            text-align: center;
                                            font-size: 14px;
                                        }
                                    }

                                    .dk-check-dupplicate-tableCon {
                                        height: calc(100vh - 465px);
                                        overflow-y: auto;
                                        overflow-x: hidden;
                                        padding-bottom: 0;
                                        position: relative;

                                        .dk-check-dupplicate-table {
                                            margin-bottom: 0;

                                            thead {
                                                tr th {
                                                    text-align: center;
                                                    vertical-align: middle;
                                                    color: #fff;
                                                    background: #3CA8BD;
                                                    border: 1px solid #ccc;
                                                    padding: 9px 7px;
                                                    font-size: 13px;
                                                    vertical-align: middle;
                                                    position: sticky;
                                                    top: 0;
                                                    white-space: nowrap;
                                                    z-index: 9;
                                                }
                                            }

                                            tbody {
                                                tr td {
                                                    background: #f1f1f1;
                                                    color: #4d4a4a;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    text-align: center;
                                                    white-space: nowrap;
                                                    padding: 0px 7px;
                                                    border-left: 1px solid #ccc;
                                                    vertical-align: middle;
                                                    text-transform: capitalize;
                                                    &:nth-child(04),
                                                    &:nth-child(05),
                                                    &:nth-child(06),
                                                    &:nth-child(07) {
                                                        padding: 0;
                                                        border: 1px solid gray;
                                                    }
                                                    .dk-dup-recimgCon{
                                                        .tooltipMain{
                                                            .filterImg{
                                                                width: 32px;
                                                                height: 32px;
                                                                border-radius: 50%;
                                                                object-fit: cover;
                                                            }
                                                        }
                                                    }
                                                    .dkg-dup-id-prviewCon {

                                                        .dkg-dup-sn-counter {
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;

                                                            .dk-checkinputIcon {
                                                                input[type="checkbox"] {
                                                                    position: relative;
                                                                    top: 3px;
                                                                    right: -4px;
                                                                    cursor: pointer;
                                                                    appearance: none;
                                                                    background: #fff;
                                                                    border-radius: 1px;
                                                                    box-sizing: border-box;
                                                                    width: 14px;
                                                                    height: 14px;
                                                                    border-width: 0;
                                                                    transition: all 0.3s linear;
                                                                    border: solid #ddd 1px;
                                                                    z-index: 0;
                                                                }

                                                                input[type="checkbox"]:checked {
                                                                    background-color: #34976e !important;
                                                                }
                                                            }
                                                        }


                                                    }

                                                    // .dkg-dup-id-prviewCon {
                                                    //     display: flex;
                                                    //     align-items: center;
                                                    //     justify-content: center;
                                                    // }

                                                    .dk-dup-recimgCon {
                                                        position: relative;

                                                        img.recruiter-img {
                                                            width: 30px;
                                                            height: 30px;
                                                            border-radius: 50%;
                                                        }
                                                    }

                                                    .profile-location-text {
                                                        color: #000;
                                                        font-weight: 600;

                                                    }

                                                    .caseDiv {
                                                        height: 40px;
                                                        line-height: 40px;
                                                        color: #FFF
                                                    }
                                                    .dkg-caseopen-dropdown{
                                                        position: relative;
                                                        .dkg-caseopen-dropdown-toggle{
                                                            box-shadow: none;
                                                            color: #fff;
                                                            font-size: 13px;
                                                            margin: 0;
                                                            padding: 0!important;
                                                            text-transform: capitalize;
                                                            white-space: nowrap;
                                                            width: 100%;
                                                            height: 40px;
                                                            border-radius: 0;
                                                            &::after{
                                                                display: none;
                                                            }
                                                        }
                                                        .dkg-caseopen-dropdown-menu{
                                                            border-radius: 0;
                                                            padding: 0;
                                                            -webkit-transform: translate(-1px,35px)!important;
                                                            transform: translate(-1px,35px)!important;
                                                            .dkg-caseopen-dropdown-item{
                                                                border-bottom: 1px solid #aaa;
                                                                font-size: 13px;
                                                                padding: 6px 10px;
                                                                text-align: left;
                                                                text-overflow: ellipsis;
                                                                white-space: nowrap;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .manual-frmcontrol{
                                    border: 1px solid #ddd;
                                    text-align: left;
                                    height: 40px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}