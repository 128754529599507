.dk-workplansuMMCon {
    margin-top: 170px;

    .dkg-taskssuMMCon {
        .dkg-taskssuMM-Box {
            .dkg-taskssuMM-Header {
                border-radius: 5px 5px 0 0;
                padding: 8px 0;
                background-color: #26597d;
                border-bottom: 1px solid darkgrey;
                display: none;

                &.dkg-tasks-todoheader {
                    background-color: #2685a5;
                }

                &.dkg-tasks-inprogress-header {
                    background-color: #D58B5D;
                }

                &.dkg-tasks-completed-header {
                    background-color: #3A9469;
                }

                h2 {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .dkg-tasksuMM-Body {
                padding: 0;
                background-color: #eaeaea;
                color: #333;
                border-radius: 5px 5px;

                .dkg-summlistCon {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 0;

                    .dkg-summlist {
                        text-align: center;
                        width: 100%;
                        padding: 19px 0;

                        &.dk-work-suMMLeftBlock {
                            background-color: #2685a5;
                            height: 130px;
                            white-space: nowrap;
                            padding: 0 10px;
                            min-width: 120px;
                            max-width: 120px;
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;

                            &.dkg-todo-newtaskbg {
                                background-color: #d58b5d;
                                min-width: inherit;
                                max-width: inherit;
                                border-radius: 0;
                                border-right: 1px solid #ddd;
                                border-left: 1px solid #dddd;
                            }

                            &.dkg-todo-accepetedtask-bg {
                                background-color: #26597d;
                                min-width: inherit;
                                max-width: inherit;
                                border-radius: 0;
                                border-right: 1px solid #ddd;
                            }

                            &.dkg-todo-overdue-task-bg {
                                background-color: #d91010;
                                min-width: inherit;
                                max-width: inherit;
                                border-radius: 0;
                                border-right: 1px solid #ddd;
                            }

                            &.dkg-todo-quires-task-bg {
                                background-color: #625c60;
                                min-width: inherit;
                                max-width: inherit;
                                border-radius: 0;
                                border-right: 1px solid #ddd;
                            }

                            &.dkg-todo-sucess-task-bg {
                                background-color: #3a9469;
                                min-width: inherit;
                                max-width: inherit;
                                border-radius: 0;
                                border-right: 1px solid #ddd;
                            }

                            &.dk-todo-leftblockBg {
                                background-color: #26597d;
                            }

                            &.dk-inprogress-leftblockBg {
                                background-color: #D58B5D;
                            }

                            &.dk-completed-leftblockBg {
                                background-color: #3A9469;
                            }

                            .suMM-blocktitle {
                                // line-height: 95px;
                                color: #fff;
                                font-weight: 600;
                                padding-bottom: 1rem;
                            }

                            .suMM-blockCounter {
                                color: #fff;
                                font-weight: 600;
                            }
                        }

                        .suMM-tasktitle {
                            font-weight: 600;
                            padding-bottom: 1rem;

                            &.total-tasktitle {
                                color: #2685a5;
                            }

                            &.todo-tasktitle {
                                color: #26597d;
                            }

                            &.inprogress-tasktitle {
                                color: #D58B5D;
                            }

                            &.completed-tasktitle {
                                color: #3A9469;
                            }

                            &.newtask-tasktitle,
                            &.dk-newtask-summ-taskcounter {
                                color: #d58b5d;
                            }

                            &.acceted-tasktitle,
                            &.dk-accepted-summ-taskcounter {
                                color: #26597d;
                            }

                            &.overdue-tasktitle,
                            &.dk-overdue-summ-taskcounter {
                                color: #d91010;
                            }

                            &.queries-tasktitle,
                            &.dk-quires-summ-taskcounter {
                                color: #ff8b00;
                            }

                            &.sucess-tasktitle,
                            &.dk-sucess-summ-taskcounter {
                                color: #3a9469;
                            }
                        }

                        .dk-position-counter {
                            font-size: 13px;
                            font-weight: 600;
                            color: #554e4e;
                        }

                        .dk-summ-taskcounter {
                            font-size: 14px;
                            color: #554e4e;
                        }

                        .dk-summ-recimgCon {
                            padding: 1.18rem 0;

                            .dk-summ-recimg {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .dk-workplansimileCon {
        background-color: #eaeaea;
        border-radius: 5px;
        width: 100%;
        height: 493px;

        .dk-wokplan-emoHeader {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #eaeaea;
            border-radius: 5px 5px 0 0;
            padding: 8px 10px;

            .dk-wokplan-emoTitle {
                font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
                color: #333;
            }
        }

        .dkg-workplanemo-Body {
            .dkg-summemojelistCon {
                list-style: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0;
                padding: 20px 0;

                .dkg-summemojelist {
                    text-align: center;
                    width: 100%;

                    .dk-summ-recimgCon {
                        padding-bottom: 0.5rem;

                        .dk-summ-recimg {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            cursor: pointer;

                            &.active {
                                border: 3px solid #FFA500;
                            }
                        }
                    }
                }
            }

            .monthBoxes {
                display: flex;
                width: 100%;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    width: 100%;

                    li {
                        list-style-type: none;
                        display: inline-block;
                        width: 100%;
                        max-width: 8.33%;

                        &:first-child a {
                            border-left: 1px solid #616167;
                        }

                        a {
                            width: 100%;
                            height: 35px;
                            background: #4a5057;
                            color: #fff;
                            padding: 7px;
                            float: left;
                            border-top: 1px solid #616167;
                            border-bottom: 1px solid #616167;
                            border-right: 1px solid #616167;
                            cursor: pointer;
                            text-align: center;
                        }
                    }
                }
            }

            .dk-days-blocksContainer {
                width: 100%;
                padding: 17px 0;
                padding-top: 0;

                .dk-days-portalCols {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    &:nth-child(02) .dk-days-portalBox,
                    &:nth-child(03) .dk-days-portalBox,
                    &:nth-child(04) .dk-days-portalBox,
                    &:nth-child(05) .dk-days-portalBox {
                        border-top: none;
                    }

                    .dk-days-portalBox {
                        width: 100%;
                        max-width: 14.2857%;
                        min-height: 65px;
                        background: #f1f1f1;
                        border: 1px solid #ddd;
                        border-left: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:first-child {
                            border-left: 1px solid #ddd;
                        }
                    }
                }
            }
        }
    }

    .dk-wp-progressBarCon {
        padding: 15px;
        border-radius: 5px;
        background-color: #eaeaea;

        .dk-wp-hrprogressListCon {
            display: flex;
            justify-content: space-between;

            &:first-child {
                margin-top: 0;
            }

            .dk-wp-hrprogressList {
                .dk-wp-hrprogressList-Label {
                    padding: 5px 15px;
                }
            }

            .dk-wp-bar-numberTag {
                background-color: #C8695F;
                border-radius: 30px;
                width: 40px;
                text-align: center;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                color: #fff;
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-workplansuMMCon {
        margin-top: 121px;

        .dk-db-mo-pagetitleCon {
            background-color: #359ab8;
            display: block !important;
            margin-bottom: 15px;
            padding: 9px 10px;
            text-align: center;
            width: 100%;

            .dk-dbsumm-mo-title {
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
            }
        }

        .dkg-taskssuMMCon {
            .dkg-taskssuMM-Box {
                .dkg-tasksuMM-Body {
                    .dkg-summlistCon {
                        overflow-x: auto;

                        .dkg-summlist {
                            padding: 0;

                            &.dk-work-suMMLeftBlock {
                                height: 89px;
                                min-width: 110px;
                                max-width: 110px;

                                .suMM-blocktitle {
                                    line-height: 88px;
                                }
                            }

                            .suMM-tasktitle {
                                font-size: 13px;
                                white-space: nowrap;
                                min-width: 95px;
                                padding-bottom: 0.5rem;
                            }

                            .dk-summ-recimgCon {
                                padding: 1.018rem 0;

                                .dk-summ-recimg {
                                    margin: 0 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}