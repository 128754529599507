.dk-compyteam-pagecontainerMain {
    margin-top: 120px;

    .dk-compyteam-Leftpanel {
        background-color: #fff;
        width: 100%;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            width: 100%;
            list-style: none;
            padding: 0;
            margin: auto;
            max-width: 1600px;
            background-color: #fff;

            li {
                list-style-type: none;
                display: inline-block;
                width: 100%;
                min-width: 14% !important;
                max-width: 14% !important;
                height: 44px;
                line-height: 44px;
                text-align: center;

                a {

                    display: inline-block;
                    width: 100%;
                    color: #333;
                    text-align: center;
                    font-weight: 400;
                    letter-spacing: 0.3px;
                    font-size: 14px;
                    font-family: Verdana, sans-serif;
                    transition: all 0.5s;
                    height: 44px;
                    line-height: 44px;
                    white-space: nowrap;
                    background-color: #fff;

                    &.active {
                        color: #3c97b6;
                        position: relative;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -2px;
                            height: 2px;
                            width: 85%;
                            background: #3c97b6;
                            left: 16px;

                        }
                    }
                }
            }

        }
    }
}