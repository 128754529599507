.dk-mailShotSrchDialog{
    .modal-dialog{
        max-width: 700px;
        min-width: 700px;
        .modal-content{
            background-color: transparent;
            .modal-header{
                background: #2e97b6;
                color: #fff;
                padding: 7px 0;
                border-radius: 5px 5px 0 0;
                .modal-title {
                    margin: 0;
                    display: block;
                    text-align: center;
                    width: 100%;
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            .modal-body.dk-srchModalBody{
                background: #ededed;
                padding: 20px;
                .dk-searchTags{
                    position: relative;
                    background: #fff;
                    padding: 5px 12px;
                    min-height: 50px;
                    float: left;
                    width: 100%;
                    display: block;
                    clear: both;
                    margin-bottom: 15px;
                    a {
                        display: inline-block;
                        background: #ddd;
                        padding: 4px 15px;
                        font-size: 13px;
                        color: #333;
                        border-radius: 30px;
                    }
                }
                .dk-searchBox {
                    position: relative;
                    margin: 25px 0;
                    clear: both;
                    input {
                        background: #fff;
                        padding: 15px;
                        padding-right: 50px;
                        text-align: center;
                        box-shadow: none;
                        border: 1px solid #ccc !important;
                    }
                    span {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 38px;
                        height: 35px;
                        background: #ccc;
                        text-align: center;
                        line-height: 35px;
                    }
                }
            }
        }
    }
}