.dk-matchJobsCon{
    .dk-machTopHead{
        .title{
            font-size: 18px;
            font-weight: 600;
            color: #333;
            margin-bottom: 12px;
            width: 100%;
            text-align: center;

        }
    }
    .dk-matchTableCon{
        .dk-matchTable{
            thead{
                tr th{
                    text-align: center;
                    vertical-align: middle;
                    padding: 8px 10px;
                    background: #254763;
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    border: 1px solid #467092;
                    white-space: nowrap;
                }
            }
            tbody{
                tr td{
                    background: #e9ecef;
                    border: 1px solid #ccc;
                    font-weight: 500;
                    text-align: center;
                    vertical-align: middle;
                    padding: 7px 10px;
                    font-size: 14px;
                    .matchIdlink{
                        color:#467092;
                    }
                    .statusColumn{
                        a{
                            background: #30887E;
                            color: #fff;
                            padding: 5px 18px;
                        }
                    }
                }
                tr td:first-child {
                    width: 70px;
                }
            }
        }
    }
}