.modal-dialog.dk-adsCreAddNewPop {
    /* min-width: 30%; */
    width: 280px;

}

.modal-dialog.dk-adsCreAddNewPop .modal-content {
    box-shadow: none;
    background-color: transparent;
}

.modal-dialog.dk-adsCreAddNewPop .modal-content .modal-body {
    padding: 20px 30px;
    background-color: #F2F2F2;
    /* width: 350px; */
    border-radius: 6px;
    position: relative;
}

.modal-dialog.dk-adsCreAddNewPop .modal-content .modal-body .dkg-crosssicon-con {
    position: absolute;
    right: -9px;
    top: -11px;
    cursor: pointer;
    background: #102c46;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}

.modal-dialog.dk-adsCreAddNewPop .modal-content .modal-body .dkg-crosssicon-con:hover {
    background: #2b8faf;
    transition: all 0.5s;
}

.modal-dialog.dk-adsCreAddNewPop .modal-content .modal-body .dkg-crosssicon-con svg {
    width: 14px;
    height: 14px;
}

.modal-dialog.dk-adsCreAddNewPop .modal-content .modal-body .dkg-crosssicon-con:hover svg {
    opacity: 0.9;
}

.dk-adsCreditInCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
}

.dk-adsCreditInCon .firstInput {
    max-width: 150px;
}

.dk-adsCreditInCon .firstInput label {
    width: 100%;
    text-align: center;
}

.dk-adsCreditInCon .firstInput input.form-control {
    border-radius: 0;
    text-align: center;
    background-color: #E2E7F6;
    outline: 0;
}

.dk-adsCreditInCon .firstInput input.form-control:focus {
    box-shadow: none;
}

.dk-adsCreditInCon .SecondInput {
    max-width: 150px;
}

.dk-adsCreditInCon .SecondInput label {
    width: 100%;
    text-align: center;
}

.dk-adsCreditInCon .SecondInput input.form-control {
    border-radius: 0;
    text-align: center;
}

.dk-adsCreditBtnCon {
    width: 100%;
    text-align: center;
}

.dk-adsCreditBtnCon .dk-calcsaveBtn {
    border-radius: 35px;
    color: #fff !important;
    padding: 8px 20px;
    letter-spacing: 1px;
    /* margin-top: 1.5rem; */
    min-width: 90px;
    box-shadow: none;
    background: #5e737d !important;

}

.dk-adsCreditBtnCon .dk-calcsaveBtn:hover {
    background-color: #374252;
    transition: all .5s;
    text-decoration: none;
    box-shadow: none;
}