.dk-candidatesuMMPageCon {
    position: relative;
    padding: 15px;
    width: 100%;
    margin-top: 140px;

    .dk-suMMTopHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;

        .candSummpageTitle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .dk-canSummHeadLeftCon {
            display: flex;

            .dk-circlIconCon {
                position: relative;

                .dk-flagfilterNumberCon {
                    .dropdown {
                        .dk-userNumfliterbtn.dropdown-toggle {
                            width: 40px;
                            height: 40px;
                            background-color: #fff;
                            color: #5a5a5a;
                        }

                        .dk-userNumfliterMenu.dropdown-menu.dk-cand-summary-Flagmenu {
                            padding: 0;
                            transform: translate(22px, 0) !important;
                            -webkit-transform: translate(22px, 0px) !important;

                            .d-flex {
                                width: inherit !important;

                                a.dropdown-item.dk-candsuMM-DropItem {
                                    display: inline-block;
                                    margin: 0 6px;
                                    width: 45px;
                                    padding: 8px 5px !important;

                                    i {
                                        font-size: 20px;
                                        width: 100%;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }

                .dk-useFilterNumCon {
                    .dropdown {
                        .dk-userNumfliterbtn.dropdown-toggle.btn {
                            width: 40px;
                            height: 40px;
                            background-color: #fff;
                            color: #5a5a5a;
                        }

                        .dk-userNumfliterMenu {
                            &::after {
                                border-color: transparent #234158 transparent transparent !important;
                            }
                        }
                    }
                }

                .crossUserFilter.dk-lrftCross {
                    position: absolute;
                    top: -5px;
                    right: -7px;
                }
            }
        }

        .candSummpageTitle {
            h2.title {
                margin: 0;
                display: block;
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 0;
            }
        }

        .dk-rightTopHead {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .dksuMM-RightFilter {

                .ui.selection.dropdown.dk-overdues-task-slect,
                .ui.selection.dropdown.dk-overdues-task-slect:hover {
                    border-radius: 0;
                    min-width: 135px;
                    background-color: #eaeaea !important;
                    border: 1px solid #ddd;

                    .dropdown.icon {
                        top: 0.8581em;
                        color: #605a5a;
                    }
                }

                .ui.selection.dropdown .menu {
                    border-radius: 0;
                    min-width: 200px;
                    min-height: 16rem;
                    position: absolute;
                    right: 0;
                    left: inherit;
                    overflow: inherit;

                    .item {
                        font-size: 0.9125rem;
                        min-height: 33px;
                        line-height: 19px;

                        span.description {
                            border-radius: 0;
                            font-size: 0.8125rem;
                            background: #3a506b;
                        }
                    }

                    .active.item {
                        font-weight: normal;
                    }

                    .item:hover {
                        background-color: #eaeaea !important;
                        color: #333 !important;
                    }
                }

                .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
                .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
                    font-size: 14px;
                    color: #605a5a;
                }
            }

            .dk-filterTagsCon {
                .searchTagStatus {
                    font-size: 12px;
                    background: #366995;
                    padding: 7px 0;
                    border-radius: 30px;
                    color: #fff;
                    margin: 0 3px;
                    font-weight: normal;
                    padding-left: 15px;

                    span {
                        background: #234158;
                        padding: 7px 7px;
                        border-radius: 0 30px 30px 0;
                        font-size: 11px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .dk-candSummBoxContainer {
        display: flex;
        position: relative;
        width: 100%;
        height: calc(100vh - 175px);
        overflow-y: auto;
        overflow-x: hidden;
        .row{
            width: 100% !important;
            margin: 0 !important;
            .col-md-12{
                width: 100% !important;
                padding: 0 !important;
            }
        }
        .dk-candtopLeftBoxCont {
            display: flex;
            padding: 0 15px;
            width: 100%;
            .dk-topLeftboxesCols {
                width: 100%;
                // min-width: 230px;
                // max-width: 230px;
                background: #eaeaea;
                border: 1px solid #dadada;
                margin: 0 8px;
                margin-bottom: 18px;
                align-items: center;
                font-size: 12px;
                text-align: center;
                border-radius: 5px;
                padding: 0;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                .dk-toptitle {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: 1px solid #dadada;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #6c757d;
                }

                .dk-toptitle-filterBg {
                    background: #234158;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: 1px solid #dadada;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #fff;
                }

                ul::-webkit-scrollbar {
                    width: 10px;
                    cursor: pointer;
                }

                ul::-webkit-scrollbar-track {
                    background: #F1F1F1;
                }

                ul::-webkit-scrollbar-thumb {
                    background-color: #ddd;
                    border-radius: 4px;
                    cursor: pointer;
                }

                ul {
                    margin-bottom: 0;
                    padding-left: 0;

                    // height: 145px;
                    // overflow-y: auto;
                    li {
                        list-style-type: none;
                        width: 100%;
                        text-align: left;
                        background: #eaeaea;
                        border-bottom: 1px solid #dadada;
                        padding: 8px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 13px;

                        span {
                            display: inline-block;
                            margin-right: 0px;
                        }

                        &:hover {
                            background-color: #234158;
                            color: #fff;
                            cursor: pointer;

                            span.rightItem {
                                color: #fff;
                            }
                        }

                        .leftItem {
                            img {
                                width: 12px;
                                margin-right: 4px;
                            }

                            i {
                                font-size: 15px;
                                background: 0 0;
                            }
                        }

                        span.rightItem {
                            display: inline-block;
                            text-align: center;
                            color: #2c71b2;
                            font-size: 13px;

                        }

                        .recuriterItem {
                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                margin-right: 10px;
                            }

                            i {
                                font-size: 15px;
                                background: 0 0;
                            }
                        }

                    }

                    li:last-child {
                        border-bottom: none;
                    }

                    &.dkg-recruiter-admin-ul {
                        max-height: 146px;
                        overflow-y: auto;

                        li {
                            padding: 4px 5px;

                            .recuriterItem {
                                .recruitersImg {
                                    width: 27px;
                                    height: 27px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .dk-candbottomLeftBoxCont {
            display: flex;
            .dk-bottomLeftboxesCols.blank {
                opacity: 0;
            }

            .dk-bottomLeftboxesCols {
                width: 100%;
                // min-width: 230px;
                // max-width: 230px;
                background: #eaeaea;
                border: 1px solid #dadada;
                margin: 0 8px;
                margin-bottom: 18px;
                align-items: center;
                font-size: 12px;
                text-align: center;
                border-radius: 5px;
                padding: 0;

                .dk-toptitle {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: 1px solid #dadada;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #6c757d;
                    white-space: nowrap;
                }

                .dk-toptitle-filterBg {
                    background: #234158;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: 1px solid #dadada;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #fff;
                }

                .dk-boxHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #dddd;
                    padding: 8px;
                    border-radius: 5px 5px 0 0;
                    cursor: pointer;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #6c757d;

                    .title {
                        color: #6c757d;
                    }
                }

                ul.dk-bottomLeftLists {
                    margin-bottom: 0;
                    min-height: 355px;
                    max-height: 355px;
                    overflow-y: auto;
                    padding-left: 0;

                    &.dk-case-status-bgColor23 {
                        li {
                            &:hover {
                                background-color: #234158 !important;
                                cursor: pointer;
                            }
                        }
                    }

                    li {
                        list-style-type: none;
                        width: 100%;
                        text-align: left;
                        background: #eaeaea;
                        padding: 5px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 13px;
                        cursor: pointer;

                        span {
                            display: inline-block !important;
                            margin-right: 2px;
                        }

                        &:hover {
                            background-color: #234158;
                            cursor: pointer;

                            .recuriterItem,
                            .leftItem {
                                color: #fff;
                            }

                            span.rightItem {
                                color: #fff;
                            }
                        }

                        .recuriterItem {
                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                margin-right: 10px;
                            }

                            i {
                                font-size: 15px;
                                background: 0 0;
                            }
                        }

                        span.rightItem {
                            display: inline-block;
                            text-align: center;
                            color: #2c71b2;
                            font-size: 13px;
                            cursor: pointer;
                        }

                        span.caseStatusCounter {
                            color: #fff;
                        }
                    }

                    li:last-child {
                        border-bottom: none;
                    }
                }

                ul.dk-bottomLeftLists::-webkit-scrollbar {
                    width: 10px;
                    cursor: pointer;
                }

                ul.dk-bottomLeftLists::-webkit-scrollbar-track {
                    background: #F1F1F1;
                }

                ul.dk-bottomLeftLists::-webkit-scrollbar-thumb {
                    background-color: #ddd;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }

        .dk-candRightBoxCont {
            display: flex;
            padding: 0 15px;
            .dk-bottomLeftboxesCols {
                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .dk-RightboxesCols.blank {
                background: transparent;
                border: none;
            }

            .dk-RightboxesCols {
                width: 100%;
                max-width: 100%;
                background: #eaeaea;
                border: 1px solid #dadada;
                margin: 0 8px;
                align-items: center;
                font-size: 12px;
                text-align: center;
                border-radius: 5px;
                padding: 0;

                .dk-toptitle-filterBg {
                    background: #234158;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: 1px solid #dadada;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #fff;
                }

                .dk-toptitle {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: 1px solid #dadada;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #6c757d;
                }

                .dk-boxHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #dddd;
                    padding: 8px;
                    border-radius: 5px 5px 0 0;
                    cursor: pointer;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #6c757d;

                    .title {
                        color: #6c757d;
                    }
                }

                ul.dk-bottomLeftLists {
                    margin-bottom: 0;
                    min-height: 370px;
                    max-height: 370px;
                    overflow-y: auto;
                    padding-left: 0;

                    li {
                        list-style-type: none;
                        width: 100%;
                        text-align: left;
                        background: #eaeaea;
                        padding: 5px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 13px;
                        cursor: pointer;

                        &:hover {
                            background-color: #234158;
                            cursor: pointer;

                            .recuriterItem {
                                color: #fff;
                            }

                            span.rightItem {
                                color: #fff;
                            }
                        }

                        .recuriterItem {
                            img {
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                margin-right: 4px;
                            }

                            i {
                                font-size: 15px;
                                background: 0 0;
                            }
                        }

                        span.rightItem {
                            display: inline-block;
                            text-align: center;
                            color: #2c71b2;
                            font-size: 13px;
                        }

                        span.caseStatusCounter {
                            color: #fff;
                        }
                    }

                    li:last-child {
                        border-bottom: none;
                    }
                }

                ul.dk-bottomLeftLists::-webkit-scrollbar {
                    width: 10px;
                    cursor: pointer !important;
                }

                ul.dk-bottomLeftLists::-webkit-scrollbar-track {
                    background: #F1F1F1;
                    cursor: pointer !important;
                }

                ul.dk-bottomLeftLists::-webkit-scrollbar-thumb {
                    background-color: #ddd;
                    border-radius: 4px;
                    cursor: pointer !important;
                }
            }
        }
    }

    // .dk-candSummBoxContainer::-webkit-scrollbar {
    //     display: none;
    // }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-candidatesuMMPageCon {
        margin-top: 120px;
        padding: 0;

        .dk-mo-suMM-pageTitle {
            display: block !important;
            width: 100%;
            text-align: center;
            padding: 9px 10px;
            background-color: #359ab8;
            margin-bottom: 15px;

            h2.dk-mobile-title {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
                color: #fff;
            }
        }

        .dk-suMMTopHead {
            display: flex;
            justify-content: center;
            padding-bottom: 10px;

            .dk-canSummHeadLeftCon {
                .dk-circlIconCon {

                    .dk-useFilterNumCon,
                    .dk-flagfilterNumberCon {
                        .dropdown {
                            .dk-userNumfliterMenu.dropdown-menu {
                                position: absolute !important;
                                top: 85px !important;
                                left: -50px !important;
                                transform: rotate(90deg) !important;

                                .d-flex {
                                    overflow-x: inherit;

                                    a.dropdown-item {
                                        padding: 0;
                                        display: inline-block;
                                        margin: 0 5px;
                                        width: 40px;
                                        transform: rotate(270deg) !important;
                                    }
                                }

                                &.dk-cand-summary-Flagmenu {
                                    top: 135px !important;
                                    left: -88px !important;
                                    transform: rotate(90deg) !important;
                                }

                            }
                        }
                    }
                }
            }

            .candSummpageTitle {
                // position: relative;
                // top: -35px;
                // left: -25%;
                display: none;

                .title {
                    font-size: 13px !important;
                }
            }
        }

        .dk-candSummBoxContainer {
            height: calc(100vh - 60px);

            div {
                padding: 0 10px !important;

                .dk-candtopLeftBoxCont {
                    display: block;

                    .dk-topLeftboxesCols {
                        margin: 10px 0 !important;
                        padding: 0 !important;

                        &:first-child {
                            margin-top: 0 !important;
                        }
                    }
                }

                .dk-candbottomLeftBoxCont {
                    display: block !important;

                    .dk-bottomLeftboxesCols {
                        margin: 10px 0 !important;
                        padding: 0 !important;

                        .dk-boxHeader {
                            padding: 5px 8px !important;

                            .title {
                                padding: 0 !important;
                            }

                            .numberCounter {
                                padding: 0 !important;
                            }
                        }
                    }
                }

                .dk-candRightBoxCont {
                    display: block;

                    .dk-RightboxesCols {
                        margin: 10px 0 !important;
                        padding: 0 !important;
                    }
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}