.dkg-mypipelines-pageCon12 {
    margin-top: 160px;

    .dkg-pipelineLeftCols12 {
        .dkg-mypipelineleftCon {
            display: flex;
            align-items: center;

            .dk-mailshotsSearch {
                .input-group {
                    width: 100%;
                    max-width: 145px;
                    min-width: 145px;

                    input {
                        padding: 0.5rem 0.55rem;
                        background-color: #fff !important;
                        margin: 0;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        line-height: 1.3rem;
                        height: 35px;
                        border-radius: 0;
                        text-align: left;

                        &:focus::placeholder {
                            color: transparent;
                        }
                    }

                    .input-group-append {
                        margin-left: -1px;

                        button {
                            padding: 0.5rem 0.55rem;
                            background-color: #fff !important;
                            margin: 0;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            line-height: 1.3rem;
                            height: 35px;
                            border-radius: 0;

                            &:hover {
                                background-color: #234158 !important;

                                i {
                                    color: #fff;
                                }
                            }

                            i {
                                color: #6c757d;
                            }
                        }
                    }
                }
            }

            .dkg-rec-tableFilterCon12 {
                .dkg-mypipe-rec-dropdown {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .dkg-mypipe-rec-dropdown-toggle {
                        padding: 0;
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 35px;
                        height: 35px;
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        box-shadow: none;

                        &:hover {
                            outline: 0;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }

                        &::after {
                            display: none;
                        }

                        i {

                            font-size: 14px;
                        }

                        img.table-select-user-img {
                            width: 35px;
                            border-radius: 50%;
                            position: relative;
                            top: -1px;
                            right: 1px;
                        }
                    }

                    &::after {
                        display: none;
                    }

                    .dkg-mypipe-rec-dropdown-menu {
                        padding: 5px;
                        left: 25px !important;
                        top: -15px !important;
                        transform: translate(22px, -5px) !important;
                        -webkit-transform: translate(22px, -5px) !important;
                        background-color: #234158;
                        min-width: inherit;

                        &:after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-color: transparent #234158 transparent transparent;
                            border-style: solid;
                            border-width: 8.5px 12px 8.5px 0;
                            line-height: 0;
                            _border-color: #000 #234158 #000 #000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            left: -12px;
                            top: 28px;
                        }

                        .d-flex {
                            .dkg-mypipe-rec-dropdown-item {
                                padding: 0;
                                display: inline-block;
                                margin: 0 5px;
                                text-align: center;

                                img {
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    display: block;

                                }

                                .recNoCount {
                                    color: #fff;
                                    font-size: 12px;
                                    padding: 4px 0 0;
                                    display: block;
                                    width: 100%;
                                    text-align: center;
                                }

                                &:hover {
                                    .recNoCount {
                                        color: #333;
                                    }
                                }
                            }
                        }
                    }

                    .crossUserFilter {
                        position: absolute;
                        top: -3px;
                        right: -9px;
                        font-size: 15px !important;
                        cursor: pointer;
                    }
                }
            }

            .dkg-relodbtnCon {
                button.dk-reoderBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: #fff !important;
                    color: #6c757d;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    outline: none;
                    box-shadow: none;

                    &:hover {
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                    }
                }
            }
        }
    }

    .dkg-mypipelineCenterCols12 {
        .dk-filterTagsCon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .dk-filterbtnGrp {
                margin-top: 2px;

                .dk-filterbtntext.btn-primary {
                    background: #366995 !important;
                    font-size: 12px !important;
                    color: #fff;
                    padding: 5px 7px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                    line-height: 10px;
                    text-transform: inherit
                }

                .dk-filterbtnicon {
                    background-color: #234158 !important;
                    font-size: 11px;
                    padding: 5px 7px;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }
            }

            .dk-pipeline-pagetitle {
                margin-top: 7px;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }

    .dkg-pipelineRightCols12 {
        .dkg-pipelineright12 {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .dk-newCVRightFilter,
            .dk-duplicatefilterCon {

                .dk-reoderBtn,
                .dkg-topright-cricleBtn {
                    padding: 0;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    box-shadow: none;
                    color: #6c757d;
                    background-color: #fff !important;

                    &:hover {
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                    }

                    i {
                        font-size: 13px;
                    }
                }
            }

            .dkg-mypipeline-casefileterCon {
                .dkg-casestaus-dropdown12 {
                    .btn-primary.dropdown-toggle {
                        background: #fff !important;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        margin: 0;
                        padding: 0.5rem 0.45rem;
                        box-shadow: none;
                        text-transform: capitalize;
                        min-width: 145px;
                        font-size: 13px;
                        color: #6c757d;

                        &::after {
                            position: absolute;
                            right: 10px;
                            top: 16px;
                            font-size: 1.2rem;
                        }

                        // &::after {
                        //     display: none;
                        // }
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        width: 100%;

                        .dkg-casestuts-item12 {
                            padding: 0.5rem 0.5rem;
                            display: flex;
                            justify-content: space-between;

                            .dropdown-filter-count {
                                background-color: #3c4c62;
                                border-radius: 2px;
                                color: #fff;
                                padding: 0px 5px;
                                font-size: 12px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }

            .dk-trickfilterIcon {
                .dkg-pipe-trickFilterCon {
                    .dkg-trickfilter-dropDown14 {
                        .dkg-trickfilter-dropDown-toggle14 {
                            margin: 0;
                            padding: 0;
                            box-shadow: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 35px;
                            height: 35px;
                            line-height: 35px;
                            background: #fff !important;
                            color: #6c757d;
                            border: 1px solid #ddd;
                            border-radius: 50%;

                            &:hover {
                                outline: 0;
                                box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                            }

                            svg {
                                width: 16px;
                                height: 16px;
                            }

                            &::after {
                                display: none;
                            }

                        }

                        .dkg-trickfilter-dropDown-menu14 {
                            position: absolute !important;
                            background-color: #234158;
                            transform: inherit !important;
                            right: 50px !important;
                            top: -18px !important;
                            left: inherit !important;
                            margin: 0 !important;
                            padding: 0 !important;
                            display: flex;
                            align-items: inherit;
                            border: 1px solid #808080;

                            &::after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-color: transparent #234158 transparent transparent;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                line-height: 0;
                                _border-color: #000 #234158 #000 #000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                position: absolute;
                                right: -10px;
                                top: 26px;
                                transform: rotate(59deg);
                            }

                            .dkg-trickfilter-dropDown-item14 {
                                padding: 0.25rem 0.5rem;
                                width: 100%;
                                text-align: center;
                                color: #fff;
                                border-right: 1px solid #808080;

                                &:last-child {
                                    border-right: none;
                                }

                                .dk-idDropdown-cols {
                                    span {
                                        svg {
                                            width: 13px;
                                            height: 13px;
                                        }

                                        &.dkg-tricker {
                                            display: block;
                                            font-size: 12px;
                                        }
                                    }
                                }

                                &:hover {
                                    .dk-idDropdown-cols {
                                        span {

                                            &.dkg-tricker {
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dk-pipe-prfindicon-Con {
                .dk-pipe-profileind-dropdown14 {
                    .dk-pipe-profileind-dropdown-toggle-14 {
                        margin: 0;
                        padding: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        background: #fff !important;
                        color: #6c757d;
                        border: 1px solid #ddd;
                        border-radius: 50%;

                        &:hover {
                            outline: 0;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }

                        svg {
                            width: 16px;
                            height: 16px;
                        }

                        i {
                            font-size: 13px;
                        }

                        &::after {
                            display: none;
                        }

                    }

                    .dk-pipe-profileind-dropdown-menu14 {
                        position: absolute !important;
                        background-color: #234158;
                        transform: inherit !important;
                        right: 50px !important;
                        top: -18px !important;
                        left: inherit !important;
                        margin: 0 !important;
                        padding: 0 !important;
                        display: flex;
                        align-items: inherit;
                        border: 1px solid #808080;

                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-color: transparent #234158 transparent transparent;
                            border-style: solid;
                            border-width: 8.5px 12px 8.5px 0;
                            line-height: 0;
                            _border-color: #000 #234158 #000 #000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            right: -10px;
                            top: 26px;
                            transform: rotate(59deg);
                        }

                        .d-flex {
                            .dk-profileind-rtDropdownItem {
                                padding: 0.25rem 0.5rem;
                                width: 100%;
                                text-align: center;
                                color: #fff;
                                border-right: 1px solid #808080;

                                &:last-child {
                                    border-right: none;
                                }

                                span {
                                    display: block;
                                    font-size: 12px;

                                    svg {
                                        width: 13px;
                                        height: 13px;
                                    }
                                }

                                &:hover {
                                    span {
                                        color: #333;
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .dkg-candfilterIconCon {
                .dkg-candfilter-dropdown14 {
                    .dkg-candfilter-dropdown-toggle14 {
                        margin: 0;
                        padding: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        background: #fff !important;
                        color: #6c757d;
                        border: 1px solid #ddd;
                        border-radius: 50%;

                        &:hover {
                            outline: 0;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }

                        svg {
                            width: 16px;
                            height: 16px;
                        }

                        i {
                            font-size: 13px;
                        }

                        &::after {
                            display: none;
                        }

                    }

                    .dkg-candfilter-dropdown-menu14 {
                        position: absolute !important;
                        background-color: #234158;
                        transform: inherit !important;
                        right: 50px !important;
                        top: -18px !important;
                        left: inherit !important;
                        margin: 0 !important;
                        padding: 0 !important;
                        display: flex;
                        align-items: inherit;
                        border: 1px solid #808080;

                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-color: transparent #234158 transparent transparent;
                            border-style: solid;
                            border-width: 8.5px 12px 8.5px 0;
                            line-height: 0;
                            _border-color: #000 #234158 #000 #000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            right: -10px;
                            top: 26px;
                            transform: rotate(59deg);
                        }

                        .dkg-candfilter-dropdown-item14 {
                            padding: 0.25rem 0.5rem;
                            width: 100%;
                            text-align: center;
                            color: #fff;
                            border-right: 1px solid #808080;

                            &:last-child {
                                border-right: none;
                            }

                            span {
                                display: block;
                                font-size: 12px;

                                svg {
                                    width: 13px;
                                    height: 13px;
                                }
                            }

                            &:hover {
                                span {
                                    color: #333;
                                }
                            }
                        }
                    }

                }
            }

            .dk-flagfilterIcon {
                .dkg-mypipeflag-dropdown14 {
                    .dkg-mypipeflag-dropdown-toggle14 {
                        margin: 0;
                        padding: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        background: #fff !important;
                        color: #6c757d;
                        border: 1px solid #ddd;
                        border-radius: 50%;

                        &:hover {
                            outline: 0;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }

                        svg {
                            width: 16px;
                            height: 16px;
                        }

                        i {
                            font-size: 13px;
                        }

                        &::after {
                            display: none;
                        }

                    }

                    .dkg-mypipeflag-dropdown-menu14 {
                        position: absolute !important;
                        background-color: #234158;
                        transform: inherit !important;
                        right: 50px !important;
                        top: -18px !important;
                        left: inherit !important;
                        margin: 0 !important;
                        padding: 0 !important;
                        display: flex;
                        align-items: inherit;
                        border: 1px solid #808080;

                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-color: transparent #234158 transparent transparent;
                            border-style: solid;
                            border-width: 8.5px 12px 8.5px 0;
                            line-height: 0;
                            _border-color: #000 #234158 #000 #000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            right: -10px;
                            top: 26px;
                            transform: rotate(59deg);
                        }

                        .d-flex {
                            .dkg-mypipeflag-dropdown-item {
                                padding: 0.25rem 0.5rem;
                                width: 100%;
                                text-align: center;
                                color: #fff;
                                border-right: 1px solid #808080;

                                &:last-child {
                                    border-right: none;
                                }

                                span {
                                    display: block;
                                    font-size: 12px;

                                    svg {
                                        width: 13px;
                                        height: 13px;
                                    }
                                }

                                &:hover {
                                    span {
                                        color: #333;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    .dkg-mypipeline-TableCon12 {
        margin-top: 6px;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh - 237px);
        padding-bottom: 0;
        position: relative;

        .dkg-mypipeline-Table {
            thead {
                tr th {
                    background: #485569;
                    color: #fff;
                    font-size: 13px;
                    text-align: center;
                    white-space: nowrap;
                    padding: 9px 15px;
                    border: 1px solid #808080;
                    border-bottom: none;
                    vertical-align: middle;
                    position: sticky;
                    top: 0;
                    border-top: none;
                    z-index: 9;

                }
            }

            tbody {
                tr td {
                    background: #fff;
                    color: #4d4a4a;
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;
                    white-space: nowrap;
                    padding: 0 7px;
                    border: 1px solid #ddd;
                    vertical-align: middle;
                    text-transform: capitalize;
                    position: relative;

                    &:nth-child(6) {
                        text-align: left;
                        min-width: 180px;
                        max-width: 180px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:nth-child(7) {
                        min-width: 180px;
                        max-width: 180px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: left;
                    }

                    &:nth-child(8) {
                        min-width: 210px;
                        max-width: 210px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: left;
                    }

                    &:nth-child(9),
                    &:nth-child(10),
                    &:nth-child(11) {
                        border: 1px solid #808080;
                        padding: 0;
                    }

                    .dkg-mypipleFilterCols {
                        display: flex;
                        align-items: center;

                        .dk-chevron-rightIcon {
                            width: 100%;
                            text-align: left;
                            display: flex;
                            align-items: center;

                            i {
                                background: #297483;
                                padding: 5px;
                                text-align: center;
                                border-radius: 2px;
                                color: #fff;
                                margin-right: 8px;
                                cursor: pointer;
                            }
                        }

                        .dk-checkinputIcon {
                            margin-left: 0.8rem;
                            margin-right: 0.35rem;
                            position: relative;

                            .dk-checkinputIcon input[type=checkbox] {
                                position: absolute;
                                top: 6px;
                                right: -4px;
                                cursor: pointer;
                                appearance: none;
                                background: #eaeaea;
                                border-radius: 1px;
                                box-sizing: border-box;
                                width: 14px;
                                height: 14px;
                                border-width: 0;
                                transition: all 0.3s linear;
                                border: solid #ddd 1px;
                                z-index: 0;
                            }
                        }
                    }

                    .dkg-mypipe-cs-dropdown {
                        position: relative;

                        .dkg-mypipe-cs-dropdown-toggle,
                        .dkg-mypipe-cs-dropdown-toggle:hover {
                            box-shadow: none;
                            background-color: transparent;
                            margin: 0;
                            white-space: nowrap;
                            font-size: 13px;
                            text-transform: capitalize;
                            color: #fff;
                            border-radius: 0 !important;
                            padding: 0;
                            width: 100%;

                            .caseDiv {
                                height: 40px;
                                line-height: 40px;
                                cursor: pointer;
                                padding: 0 7px;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .dkg-mypipe-cs-dropdown-menu {
                            border-radius: 0;
                            padding: 0;
                            transform: translate(-1px, 35px) !important;
                            padding: 0;

                            // border: none;
                            .dkg-mypipe-cs-dropdown-item {
                                text-align: left;
                                font-size: 13px;
                                padding: 6px 10px;
                                // border-bottom: 1px solid #aaa;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }

                    .dkg-recuriters-dropdown-qw12 {
                        .dkg-recuriters-dropdown-toggle-qw12 {
                            box-shadow: none;
                            background-color: transparent !important;
                            padding: 0 !important;
                            margin: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;

                            img {
                                border-radius: 50%;
                                height: 30px;
                                width: 30px;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .dkg-recuriters-dropdown-menu-qw12 {
                            border: 1px solid #234158;
                            background: #234158;
                            padding: 0;
                            left: 22px !important;
                            top: -10px !important;
                            transform: translate(22px, 0px) !important;
                            -webkit-transform: translate(22px, 0px) !important;

                            &::after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-color: transparent #234158 transparent transparent;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                line-height: 0;
                                _border-color: #000 #ccc #000 #000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                position: absolute;
                                left: -12px;
                                top: 15px;
                            }

                            .d-flex {
                                a.dkg-recuriters-dropdown-item-qw12 {
                                    padding: 5px 0;
                                    display: inline-block;
                                    margin: 0 4px;

                                    img {
                                        width: 35px;
                                        height: 35px;
                                        border-radius: 50%;
                                        border: 1px solid #ddd;
                                    }
                                }
                            }
                        }
                    }

                    .dkg-mypipeline-blankcols {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .dkg-trickfilter-dropDown15 {
                            .dropdown-toggle {
                                margin: 0 !important;
                                padding: 0;
                                background: transparent;
                                border: none;
                                box-shadow: none;
                                text-transform: inherit;
                                font-size: 13px;
                                width: 100%;
                                min-width: 15px;
                                max-width: 15px;

                                &::after {
                                    display: none;
                                }

                            }

                            .dropdown-menu {
                                padding: 0;
                                margin: 0 !important;
                                transform: inherit !important;
                                display: flex;
                                min-width: 100px;
                                left: 26px !important;
                                top: -27px !important;
                                border: 1px solid #808080;
                                background-color: #234158 !important;

                                &::before {
                                    border-color: transparent #234158 transparent transparent;
                                    _border-color: #000 #234158 #000 #000;
                                    border-style: solid;
                                    border-width: 6.5px 12px 6.5px 0;
                                    content: "";
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    height: 0;
                                    left: -12px;
                                    line-height: 0px;
                                    position: absolute;
                                    top: 27px;
                                    width: 0;

                                }

                                .dkg-trickfilter-dropDown-item15 {
                                    border: none;
                                    font-size: 16px;
                                    padding: 4px 9px;
                                    text-align: center;
                                    width: 100%;
                                    border-right: 1px solid #808080;
                                    background-color: #234158 !important;
                                    color: #fff;

                                    .dk-idDropdown-cols {
                                        span.dkg-tricker {
                                            display: block;
                                            font-size: 12px;
                                        }
                                    }

                                    &:hover {
                                        background-color: #fff !important;
                                        color: #333 !important;

                                        .dk-idDropdown-cols {
                                            span.dkg-tricker {
                                                color: #333;
                                            }
                                        }
                                    }

                                    &:last-child {
                                        border-right: none;
                                    }
                                }
                            }
                        }

                        .dk-pipe-profileind-dropdown15,
                        .dkg-candfilter-dropdown15,
                        .dkg-mypipeflag-dropdown15 {

                            .dk-pipe-profileind-dropdown-toggle-15,
                            .dkg-candfilter-dropdown-toggle15,
                            .dkg-mypipeflag-dropdown-toggle15 {
                                margin: 0 !important;
                                padding: 0;
                                background: transparent !important;
                                border: none;
                                box-shadow: none;
                                text-transform: inherit;
                                font-size: 13px;
                                width: 100%;
                                min-width: 15px;
                                max-width: 15px;

                                &::after {
                                    display: none;
                                }

                            }

                            .dkg-candfilter-dropdown-menu15,
                            .dk-pipe-profileind-dropdown-menu15,
                            .dkg-mypipeflag-dropdown-menu15 {
                                padding: 0;
                                margin: 0 !important;
                                transform: inherit !important;
                                display: flex;
                                min-width: 100px;
                                left: 26px !important;
                                top: -15px !important;
                                border: 1px solid #808080;
                                background-color: #234158 !important;

                                &::before {
                                    border-color: transparent #234158 transparent transparent;
                                    _border-color: #000 #234158 #000 #000;
                                    border-style: solid;
                                    border-width: 6.5px 12px 6.5px 0;
                                    content: "";
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    height: 0;
                                    left: -12px;
                                    line-height: 0px;
                                    position: absolute;
                                    top: 18px;
                                    width: 0;

                                }

                                .dkg-candfilter-dropdown-item15,
                                .dk-profileind-rtDropdownItem-15,
                                .dkg-mypipeflag-dropdown-item15 {
                                    border: none;
                                    font-size: 16px;
                                    padding: 4px 9px;
                                    text-align: center;
                                    width: 100%;
                                    border-right: 1px solid #808080;
                                    background-color: #234158 !important;
                                    color: #fff;

                                    span.proflieCount,
                                    .recNoCount {
                                        display: block;
                                        font-size: 12px;
                                    }

                                    &:hover {
                                        background-color: #fff !important;
                                        color: #333 !important;

                                        span.proflieCount,
                                        .recNoCount {
                                            color: #333;
                                        }
                                    }

                                    &:last-child {
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }

                    .commentBoxCon {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .numCon {
                            width: 25px;
                            height: 25px;
                            background-color: #eaeaea;
                            border-radius: 50%;
                            line-height: 25px;
                            cursor: pointer;

                            &:hover {
                                background-color: #234158;
                                color: #fff;
                                outline: 0;
                                box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                            }
                        }
                    }
                }
            }

            tfoot {
                tr td {
                    background: #fff;
                    color: #4d4a4a;
                    font-size: 14px;
                    text-align: center;
                    white-space: nowrap;
                    padding: 9px 15px !important;
                    vertical-align: middle;
                    border: none;
                    z-index: 1;

                    &:nth-child(16),
                    &:nth-child(17),
                    &:nth-child(18),
                    &:nth-child(19) {
                        border: 1px solid #ddd;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .col-md-3.dkg-pipelineLeftCols12 {
        flex: 0 0 28%;
        max-width: 28%;
    }

    .col-md-3.dkg-pipelineRightCols12 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dkg-mypipelineCenterCols12 {
        flex: 0 0 42%;
        max-width: 42%;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .col-md-3.dkg-pipelineLeftCols12 {
        flex: 0 0 28%;
        max-width: 28%;
    }

    .col-md-3.dkg-pipelineRightCols12 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dkg-mypipelineCenterCols12 {
        flex: 0 0 42%;
        max-width: 42%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .col-md-3.dkg-pipelineLeftCols12,
    .col-md-3.dkg-pipelineRightCols12 {
        flex: 0 0 28%;
        max-width: 28%;
    }

    .col-md-6.dkg-mypipelineCenterCols12 {
        flex: 0 0 44%;
        max-width: 44%;
    }

    .get {
        color: #333;
    }
}