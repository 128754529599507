.dkg-report-dashboard-main{
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    margin-top: 120px;

    .dk-portalCols {
        width: 100%;
        max-width: 1340px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        height: 100%;
        .dk-portalBox {
            width: 100%;
            max-width: 100%;
            height: 345px;
            background: #eaeaea;
            border: 1px solid #dadada;
            margin: 15px;
            border-radius: 6px;
            &.dkg-account-grey-boxs{
                display: block;
                justify-content: inherit;
                align-items: inherit;
                min-height: inherit;
            }
            .title {
                display: block;
                width: 100%;
                background: #ddd;
                text-align: center;
                text-transform: uppercase;
                padding: 6px 10px;
                border-radius: 5px 5px 0 0;
                font-size: 14px;
                font-weight: 500;
            }

            .dk-textPanel {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 28px;
                font-weight: 600;
                padding: 10px 0;
                .dkg-accounting-boxtable {
                    border-top: none;
                    margin-bottom: 0;

                    thead {
                        tr {
                            th {
                                text-align: center;
                                font-weight: 500;
                                vertical-align: middle;
                                border: none;
                                border-top: none;
                                padding: 7px 0;
                                color: #6c757d;
                                font-size: 14px;
                                text-transform: capitalize;
                                padding-top: 20px;
                                border-right: 1px solid #ddd;

                                &:last-child {
                                    border-right: none;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                border: none;
                                vertical-align: middle;
                                text-align: center;
                                padding: 5px 10px;
                                // border-bottom: 1px solid #ddd;

                                &:first-child {
                                    text-align: left;
                                }

                                &:last-child {
                                    text-align: right;
                                }

                                // &:last-child {
                                //     border-bottom: none;
                                // }

                                &.dkg-orange-color {
                                    color: #d58b5d;
                                }

                                &.dkg-red-color {
                                    color: #d91010;
                                }

                                &.dkg-green-color {
                                    color: #3a9469;
                                }

                                &.dkg-sky-blue {
                                    color: #2c71b2;
                                }

                                &.dkg-blue-color {
                                    color: #234158;
                                }

                                &.dkg-grey-color {
                                    color: #808080 !important;
                                }

                                &.dkg-targets-black-color {
                                    color: #333 !important;
                                }

                                &.dkg-target-summ-text {
                                    &.dkg-targets-black-color {
                                        color: #333 !important;
                                    }
                                }

                            }

                            &:last-child {
                                td {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .accounting-boxbg {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-finance-boxbg {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-payroll-boxbg {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-legal-boxbg {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-banking-boxbg {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-cashflow-boxbg {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-income-boxbg {
            background-color: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-expense-boxbg {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-profit-loss-boxbg {
            background-color: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }

        }

        .dk-knowleadge-boxbg {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-business-overviewbox {
            background: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-business-boxbg {
            background-color: #d58b5d;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        .dk-contracts-boxbg {
            background-color: #5A4C72;

            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}