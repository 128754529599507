.dk-structureMain.dk-dkGlobalStructureMain {
    margin-top: 120px;

    @media(max-width:991px) {
        margin-top: 165px;
    }

    h3.title {
        font-size: 19px !important;
        color: #333 !important;
    }

    .dk-structureTable {
        .dk-table-responsive {
            @media(max-width:1199px) {
                overflow-x: auto;
            }

            table {
                @media(max-width:1199px) {
                    width: 1300px;
                    margin-top: 0;
                }

                tr {
                    th {
                        @media(max-width:1199px) {
                            white-space: nowrap;
                        }
                    }

                    td {
                        @media(max-width:1199px) {
                            white-space: nowrap;
                        }

                        button {
                            background-color: #3c97b6;
                            border-color: #3c97b6;
                            padding: 7px 0;
                            width: 175px;
                            outline: none;
                            white-space: nowrap;

                            &:hover {
                                background-color: #234158;
                                border-color: #234158;
                            }
                        }
                    }
                }
            }
        }
    }
}