.dk-adsSummPageCon {
    margin-top: 115px;

    h2.dk-adspageTitle {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 0;
    }
}