.dkg-companyclient-detMain {
    margin-top: 120px;
    height: calc(100vh - 130px);
    overflow-y: auto;
    background-color: #fff;

    .dk-clients-details-ViewPagN {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .dk-clientsid-con {
            display: none;

            a {
                color: #000000ab;
                font-size: 14px;
                font-weight: 600;
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 0;
            list-style: none;

            li.dk-detListHover a {
                font-size: 1rem;
                width: 2rem;
                min-width: 2rem;
                max-width: 2rem;
                height: 2rem;
                min-height: 2rem;
                max-height: 2rem;
                padding: 0.312rem 0.5rem;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000000ab;
            }

            li.dk-detListHover a:hover {
                background-color: #245884;
                border-color: #245884;
                color: #fff;
            }

            & li a,
            & ul li a:hover {
                color: #000000ab;
            }

            & li a.dk-NoCounttext,
            & li a.dk-NoCounttext:hover {
                font-size: 1rem;
                font-weight: 700;
                padding: 0 5px;
            }
        }
    }

    .dkg-ClientDetailSecCon {

        // padding: 15px 0;
        .dk-ClieDetViewSideMenu-12 {
            .nav.nav-pills {
                margin-bottom: 0;
                max-width: 100%;
                width: 100%;

                .dkg-clidetails12 {
                    max-width: 100%;

                    &:first-child {
                        .nav-link {
                            border-top: 1px solid #dadada;
                        }
                    }

                    .nav-link {
                        background-color: #037db2;
                        border-bottom: 1px solid #dadada;
                        border-radius: 0;
                        color: #fff;
                        font-size: .95rem;
                        padding: 0.5rem 0.45rem;


                        &.active,
                        &.show>.nav-link {
                            background-color: #fff;
                            border-color: #fff;
                            color: #333;
                        }
                    }

                    // &:first-child {
                    //     .nav-link {
                    //         border-top-left-radius: 5px;
                    //         border-top-right-radius: 5px;
                    //     }
                    // }

                    // &:last-child {
                    //     .nav-link {
                    //         border-bottom-left-radius: 5px;
                    //         border-bottom-right-radius: 5px;
                    //     }
                    // }
                }
            }
        }

        .dk-ClieDetViewMainCont-12 {
            .dkg-clientdet-tabcontent-12 {
                height: calc(100vh - 100px);
                overflow-y: auto;
                overflow-x: hidden;
                border-radius: 5px;

                .dk-ClentViewTabPane-12 {
                    .dk-cliDetilFormCon {
                        background: #d9dfe7;
                        border-radius: 0;
                        padding: 0;
                        min-height: 190px;

                        .form-group.dk-Candinate-fromGroup {
                            margin-bottom: 1rem;

                            label {
                                display: flex;
                                align-items: center;
                                justify-content: left;
                                margin-bottom: 0;
                                font-size: 12px;
                                padding-right: 20px;
                                color: #000;
                                white-space: nowrap;
                                min-width: 70px;
                                line-height: 25px;
                            }

                            label.dk-smalllabel {
                                min-width: 80px;

                                &.dkg-dv-rightlabel {
                                    min-width: 65px;
                                }
                            }

                            .dk-nativedatepicker-grid {
                                .dk-nativedatepicker {
                                    margin-top: 0;
                                    width: 100%;
                                    margin-bottom: 0;
                                    background: #fff;
                                    border: 1px solid #ddd !important;
                                    padding: 0;

                                    .MuiInput-underline {
                                        &::before {
                                            display: none;
                                        }

                                        &::after {
                                            display: none;
                                        }
                                    }

                                    .MuiInputBase-root.MuiInput-root {
                                        input {
                                            text-align: center;
                                            font-size: 13px;
                                        }

                                        .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                            .MuiButtonBase-root.MuiIconButton-root {
                                                padding: 4px 2px;
                                                background-color: #139bd6;
                                                border-radius: 0;
                                                color: #fff;
                                            }
                                        }
                                    }

                                }
                            }

                            input.form-control {
                                width: 100%;
                                height: 28px;
                                padding: 4px 6px;
                                font-size: 12px;
                                line-height: 1.42857143;
                                color: #000 !important;
                                border: 1px solid #ced4da;
                                background: #fff;
                                outline: 0;
                                border-radius: 0;
                                text-align: left;

                                &::placeholder {
                                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                                    color: #333;
                                    opacity: 1;
                                    /* Firefox */
                                }

                                &:-ms-input-placeholder {
                                    /* Internet Explorer 10-11 */
                                    color: #333;
                                }

                                &::-ms-input-placeholder {
                                    /* Microsoft Edge */
                                    color: #333;
                                }
                            }

                            .dk-clViewAddonIcon {
                                border: 1px solid #139BD6;
                                padding: 0 0.293rem;
                                background-color: #139BD6;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 25px;
                                min-width: 25px;
                                max-width: 25px;

                                & i {
                                    color: #fff;
                                    cursor: pointer;
                                }
                            }

                            select.form-control {
                                appearance: none;
                            }

                            select.form-control {
                                width: 100%;
                                height: 26px;
                                padding: 4px 6px;
                                font-size: 12px;
                                line-height: 1.42857143;
                                color: #333 !important;
                                border: 1px solid #ced4da;
                                background: #fff;
                                border-top-color: #ced4da;
                                outline: 0;
                                border-radius: 0;
                                text-align: left;
                            }
                        }

                        .dkg-clidet-comsuMMCon-12 {
                            background: #d9dfe7;
                            border-radius: 5px;
                            padding: 0;
                            min-height: 235px;

                            .dkg-clidet-comsuMMConHeader-12 {
                                align-items: center;
                                background-color: #d9dfe7;
                                border-bottom: 1px solid #ccc;
                                border-radius: 5px 5px 0 0;
                                display: flex;
                                justify-content: center;
                                width: 100%;

                                .dkg-com-title {
                                    color: #504d4d;
                                    font-size: 22px;
                                    font-weight: 600;
                                    padding: 8px 10px;
                                    margin-bottom: 0;
                                }
                            }

                            .dkg-clidet-comsuMMConBody-12 {
                                border-radius: 0 0 5px 5px;
                                padding: 15px;

                                .form-group.dk-Candinate-fromGroup {
                                    margin-bottom: 0.5rem;

                                    label {
                                        display: flex;
                                        align-items: center;
                                        justify-content: left;
                                        margin-bottom: 0;
                                        font-size: 12px;
                                        padding-right: 20px;
                                        color: #000;
                                        white-space: nowrap;
                                        min-width: 100px;
                                        line-height: 25px;
                                    }

                                    label.dk-smalllabel {
                                        min-width: 80px;

                                        &.dkg-dv-rightlabel {
                                            min-width: 65px;
                                        }
                                    }

                                    .dk-nativedatepicker-grid {
                                        .dk-nativedatepicker {
                                            margin-top: 0;
                                            width: 100%;
                                            margin-bottom: 0;
                                            background: #fff;
                                            border: 1px solid #ddd !important;
                                            padding: 0;

                                            .MuiInput-underline {
                                                &::before {
                                                    display: none;
                                                }

                                                &::after {
                                                    display: none;
                                                }
                                            }

                                            .MuiInputBase-root.MuiInput-root {
                                                input {
                                                    text-align: center;
                                                    font-size: 13px;
                                                }

                                                .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                                    .MuiButtonBase-root.MuiIconButton-root {
                                                        padding: 4px 2px;
                                                        background-color: #139bd6;
                                                        border-radius: 0;
                                                        color: #fff;
                                                    }
                                                }
                                            }

                                        }
                                    }

                                    input.form-control {
                                        width: 100%;
                                        height: 28px;
                                        padding: 4px 6px;
                                        font-size: 12px;
                                        line-height: 1.42857143;
                                        color: #000 !important;
                                        border: 1px solid #ced4da;
                                        background: #fff;
                                        outline: 0;
                                        border-radius: 0;
                                        text-align: left;

                                        &::placeholder {
                                            /* Chrome, Firefox, Opera, Safari 10.1+ */
                                            color: #333;
                                            opacity: 1;
                                            /* Firefox */
                                        }

                                        &:-ms-input-placeholder {
                                            /* Internet Explorer 10-11 */
                                            color: #333;
                                        }

                                        &::-ms-input-placeholder {
                                            /* Microsoft Edge */
                                            color: #333;
                                        }
                                    }

                                    .dk-clViewAddonIcon {
                                        border: 1px solid #139BD6;
                                        padding: 0 0.293rem;
                                        background-color: #139BD6;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 25px;
                                        min-width: 25px;
                                        max-width: 25px;

                                        & i {
                                            color: #fff;
                                            cursor: pointer;
                                        }
                                    }

                                    select.form-control {
                                        appearance: none;
                                    }

                                    select.form-control {
                                        width: 100%;
                                        height: 26px;
                                        padding: 4px 6px;
                                        font-size: 12px;
                                        line-height: 1.42857143;
                                        color: #333 !important;
                                        border: 1px solid #ced4da;
                                        background: #fff;
                                        border-top-color: #ced4da;
                                        outline: 0;
                                        border-radius: 0;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }

                    .dkg-clients-dettop-rightCon {
                        background: #d9dfe7;
                        border-radius: 5px;
                        padding: 15px;
                        min-height: 190px;
                        height: calc(100vh - 175px);

                        .dkg-com-img-titleCon {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .dkg-com-img-Con {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                background-color: #f2f2f2;

                                img {
                                    width: 230px;
                                    height: 80px;
                                }
                            }

                            .dkg-comdetails-title-Con {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                h2 {
                                    font-size: 22px;
                                    margin-bottom: 0;
                                    font-weight: bold;
                                }
                            }

                            .dkg-client-name-idCon {
                                .form-group.dk-Candinate-fromGroup {
                                    margin-bottom: 0.5rem;

                                    label {
                                        display: flex;
                                        align-items: center;
                                        justify-content: left;
                                        margin-bottom: 0;
                                        font-size: 12px;
                                        padding-right: 20px;
                                        color: #000;
                                        white-space: nowrap;
                                        min-width: 100px;
                                        line-height: 25px;
                                    }

                                    label.dk-smalllabel {
                                        min-width: 80px;

                                        &.dkg-dv-rightlabel {
                                            min-width: 65px;
                                        }
                                    }

                                    .dk-nativedatepicker-grid {
                                        .dk-nativedatepicker {
                                            margin-top: 0;
                                            width: 100%;
                                            margin-bottom: 0;
                                            background: #fff;
                                            border: 1px solid #ddd !important;
                                            padding: 0;

                                            .MuiInput-underline {
                                                &::before {
                                                    display: none;
                                                }

                                                &::after {
                                                    display: none;
                                                }
                                            }

                                            .MuiInputBase-root.MuiInput-root {
                                                input {
                                                    text-align: center;
                                                    font-size: 13px;
                                                }

                                                .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                                    .MuiButtonBase-root.MuiIconButton-root {
                                                        padding: 4px 2px;
                                                        background-color: #139bd6;
                                                        border-radius: 0;
                                                        color: #fff;
                                                    }
                                                }
                                            }

                                        }
                                    }

                                    input.form-control {
                                        width: 100%;
                                        height: 28px;
                                        padding: 4px 6px;
                                        font-size: 12px;
                                        line-height: 1.42857143;
                                        color: #000 !important;
                                        border: 1px solid #ced4da;
                                        background: #fff;
                                        outline: 0;
                                        border-radius: 0;
                                        text-align: left;

                                        &::placeholder {
                                            /* Chrome, Firefox, Opera, Safari 10.1+ */
                                            color: #333;
                                            opacity: 1;
                                            /* Firefox */
                                        }

                                        &:-ms-input-placeholder {
                                            /* Internet Explorer 10-11 */
                                            color: #333;
                                        }

                                        &::-ms-input-placeholder {
                                            /* Microsoft Edge */
                                            color: #333;
                                        }
                                    }

                                    .dk-clViewAddonIcon {
                                        border: 1px solid #139BD6;
                                        padding: 0 0.293rem;
                                        background-color: #139BD6;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 25px;
                                        min-width: 25px;
                                        max-width: 25px;

                                        & i {
                                            color: #fff;
                                            cursor: pointer;
                                        }
                                    }

                                    select.form-control {
                                        appearance: none;
                                    }

                                    select.form-control {
                                        width: 100%;
                                        height: 26px;
                                        padding: 4px 6px;
                                        font-size: 12px;
                                        line-height: 1.42857143;
                                        color: #333 !important;
                                        border: 1px solid #ced4da;
                                        background: #fff;
                                        border-top-color: #ced4da;
                                        outline: 0;
                                        border-radius: 0;
                                        text-align: left;
                                    }
                                }
                            }
                        }

                        .dkg-clidet-social-iconCon {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-top: 1rem;

                            .dkg-clidet-social-icon {
                                font-size: 32px;
                                margin-right: 0.5rem;
                                color: #696969;
                            }
                        }

                        .dk-socailinputGrp-Con {
                            .dk-socailinputGrp {
                                .fb-ip-grp-prepend {
                                    .input-group-text {
                                        background-color: #037db2;
                                        color: #fff;

                                        svg {
                                            font-size: 13px;
                                        }
                                    }

                                }

                                .dk-socail-formControl {
                                    border-radius: 0;
                                    height: 29px;
                                    margin-top: 1px;
                                }

                                .dkg-edit-ip-prepend,
                                .dkg-open-ip-prepend {
                                    .input-group-text {
                                        background-color: #037db2;
                                        color: #fff;
                                        font-size: 13px;

                                        svg {
                                            font-size: 13px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .dkg-clidet-comsuMMCon-12 {
                        background: #d9dfe7;
                        border-radius: 5px;
                        padding: 0;
                        min-height: 259px;

                        .dkg-clidet-comsuMMConHeader-12 {
                            align-items: center;
                            background-color: #d9dfe7;
                            border-bottom: 1px solid #ccc;
                            border-radius: 5px 5px 0 0;
                            display: flex;
                            justify-content: center;
                            width: 100%;

                            .dkg-com-title {
                                color: #646060;
                                font-size: 22px;
                                font-weight: 600;
                                padding: 8px 10px;
                                margin-bottom: 0;
                            }
                        }

                        .dkg-clidet-comsuMMConBody-12 {
                            border-radius: 0 0 5px 5px;
                            padding: 15px;
                            min-height: 247px;

                            .form-group.dk-Candinate-fromGroup {
                                margin-bottom: 0.5rem;

                                label {
                                    display: flex;
                                    align-items: center;
                                    justify-content: left;
                                    margin-bottom: 0;
                                    font-size: 12px;
                                    padding-right: 20px;
                                    color: #000;
                                    white-space: nowrap;
                                    min-width: 100px;
                                    line-height: 25px;
                                }

                                label.dk-smalllabel {
                                    min-width: 80px;

                                    &.dkg-dv-rightlabel {
                                        min-width: 65px;
                                    }
                                }

                                .dk-nativedatepicker-grid {
                                    .dk-nativedatepicker {
                                        margin-top: 0;
                                        width: 100%;
                                        margin-bottom: 0;
                                        background: #fff;
                                        border: 1px solid #ddd !important;
                                        padding: 0;

                                        .MuiInput-underline {
                                            &::before {
                                                display: none;
                                            }

                                            &::after {
                                                display: none;
                                            }
                                        }

                                        .MuiInputBase-root.MuiInput-root {
                                            input {
                                                text-align: center;
                                                font-size: 13px;
                                            }

                                            .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                                .MuiButtonBase-root.MuiIconButton-root {
                                                    padding: 4px 2px;
                                                    background-color: #139bd6;
                                                    border-radius: 0;
                                                    color: #fff;
                                                }
                                            }
                                        }

                                    }
                                }

                                input.form-control {
                                    width: 100%;
                                    height: 28px;
                                    padding: 4px 6px;
                                    font-size: 12px;
                                    line-height: 1.42857143;
                                    color: #000 !important;
                                    border: 1px solid #ced4da;
                                    background: #fff;
                                    outline: 0;
                                    border-radius: 0;
                                    text-align: left;

                                    &::placeholder {
                                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                                        color: #333;
                                        opacity: 1;
                                        /* Firefox */
                                    }

                                    &:-ms-input-placeholder {
                                        /* Internet Explorer 10-11 */
                                        color: #333;
                                    }

                                    &::-ms-input-placeholder {
                                        /* Microsoft Edge */
                                        color: #333;
                                    }
                                }

                                .dk-clViewAddonIcon {
                                    border: 1px solid #139BD6;
                                    padding: 0 0.293rem;
                                    background-color: #139BD6;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 25px;
                                    min-width: 25px;
                                    max-width: 25px;

                                    & i {
                                        color: #fff;
                                        cursor: pointer;
                                    }
                                }

                                select.form-control {
                                    appearance: none;
                                }

                                select.form-control {
                                    width: 100%;
                                    height: 26px;
                                    padding: 4px 6px;
                                    font-size: 12px;
                                    line-height: 1.42857143;
                                    color: #333 !important;
                                    border: 1px solid #ced4da;
                                    background: #fff;
                                    border-top-color: #ced4da;
                                    outline: 0;
                                    border-radius: 0;
                                    text-align: left;
                                }
                            }

                            .dk-socailinputGrp-Con {
                                .dk-socailinputGrp {
                                    .fb-ip-grp-prepend {
                                        .input-group-text {
                                            background-color: #037db2;
                                            color: #fff;

                                            svg {
                                                font-size: 13px;
                                            }
                                        }

                                    }

                                    .dk-socail-formControl {
                                        border-radius: 0;
                                        height: 29px;
                                        margin-top: 1px;
                                    }

                                    .dkg-edit-ip-prepend,
                                    .dkg-open-ip-prepend {
                                        .input-group-text {
                                            background-color: #037db2;
                                            color: #fff;
                                            font-size: 13px;

                                            svg {
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .col-md-2.dk-ClieDetViewSideMenu-12 {
        flex: 0 0 11.666667%;
        max-width: 11.666667%;
    }

    .col-md-10.dk-ClieDetViewMainCont-12 {
        flex: 0 0 88.333333%;
        max-width: 88.333333%;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}