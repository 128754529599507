.cursor-pointer {
    cursor: pointer;
}

.dk-pipedocs-previewModal {
    min-width: 70%;

    .modal-content {
        .dk-pipedocs-preview-modalbody {
            padding: 0;

            iframe {
                display: block !important;
                width: 100%;
                height: calc(100vh - 46px);
            }
        }

        .modal-header {
            margin: 0 !important;
            padding: 7px 10px;
            border-bottom: none;
            background-color: #26597d;
            color: #fff;
            font-size: 15px;
            font-weight: 700;
            .close {
                position: absolute;
                top: 0;
                right: 0;
                width: 35px;
                height: 35px;
                z-index: 99;
                opacity: 1;
                text-shadow: none;
                color: #fff;
                outline: none;
                box-shadow: none;
                border-radius: 0 !important;
                &:hover {
                   background-color: #eaeaea;
                   color: #333;
                    transition: all 0.5s;
                }
            }
        }

    }
}

.modal.fade.dk-modalCvRight .modal-dialog {
    right: -70%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show.dk-modalCvRight .modal-dialog {
    right: 0;
}

.modal.dk-modalCvRight {
    .modal-dialog {
        margin: 0 !important;
        float: right;
        min-width: 60%;

        .modal-content {
            .modal-header {
                background: #26597d;
                border-radius: 0;
                padding: 10px 12px;
                border-bottom: none;

                .modal-title {
                    color: #fff;
                    display: block;
                    font-size: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        color: #fff;
                        display: inline-block;
                        margin: 0 7px;

                        &:hover {
                            color: #fff;
                        }
                    }
                }

                .close {
                    color: #fff;
                    font-size: 22px;
                    margin: 0;
                    opacity: 1;
                    outline: none;
                    padding: 0;
                    position: absolute;
                    right: 8px;
                    text-shadow: none;
                    top: 10px;
                }
                &.dkg-candprof-cvdownload-header{
                    background-color: #d58b5d;
                    padding: 8px 15px !important;
                    height: 40px;
                    .modal-title{
                        .dkg-reviewapp-dwnld-icon {
                            left: 5px;
                            position: absolute;
                            top: 6px;
                            color: #fff;
                            display: inline-block;
                            margin: 0 7px;
                        }
                    }
                    .close{
                        border-radius: 0;
                        top: 1px !important;
                        right: 0 !important;
                        background-color: #d58b5d;
                        &:hover{
                            background-color: #eaeaea;
                            color: #333;
                        }
                    }
                }
            }
        }
    }
}