.dkg-rec-dbedit-modal-24 {
    .modal-dialog {
        min-width: 40%;

        .modal-content {
            background-color: transparent;
            border-radius: 5px;

            .dkg-rec-dbedit-modalheader-24 {
                border-bottom: none;
                background-color: #2d7ee2;
                color: #333;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 15px;
                border-radius: 5px 5px 0 0;
                position: relative;

                .modal-title {
                    color: #fff;
                    font-size: 17px;
                    font-weight: 500;
                    margin: 0;
                    text-align: center;
                    width: 100%;
                }

                button {
                    font-size: 20px;
                    opacity: 1;
                    outline: none;
                    padding: 0;
                    position: absolute;
                    right: 30px;
                    text-shadow: none;
                    top: 24px;
                    color: #fff;
                }
            }

            .dkg-rec-dbedit-modalbody-24 {
                background-color: #fff;
                border-radius: 0 0 5px 5px;

                form {
                    .form-group {
                        margin-bottom: 0.5rem;

                        &.dkg-rec-db-native-frmgrp {
                            .MuiGrid-justify-content-xs-center {
                                justify-content: flex-start;

                                .MuiFormControl-marginNormal {
                                    margin-top: 0;
                                    margin-bottom: 0;

                                    .MuiInputBase-root {
                                        border: 1px solid #ddd;

                                        &::before {
                                            display: none;
                                        }

                                        .MuiInputBase-input {
                                            padding-left: 10px;
                                            border: none;
                                            border-right: 1px solid #ddd;
                                        }

                                        .MuiInputAdornment-positionEnd {
                                            .MuiIconButton-root {
                                                padding: 5px;
                                            }
                                        }
                                    }
                                }
                            }

                        }

                        label {
                            color: #333 !important;
                            font-family: Verdana, sans-serif !important;
                            font-size: 14px !important;
                        }

                        input {
                            border: 1px solid #ddd;
                        }

                        .dkg-rec-db-dropdown-14 {
                            .btn-primary.dropdown-toggle {
                                margin: 0;
                                padding: 6px 10px;
                                background-color: transparent !important;
                                color: #333;
                                width: 100%;
                                box-shadow: none;
                                border: 1px solid #ddd;
                                text-transform: capitalize;
                                font-size: 13px;
                                height: 33px;

                                &::after {
                                    position: absolute;
                                    top: 15px;
                                    right: 10px;
                                }
                            }

                            .dropdown-menu {
                                border-radius: 0;
                                padding: 0;
                                width: 100%;

                                .dkg-rec-db-dropdown-item-14 {
                                    padding: 6px 10px;
                                    text-align: left;
                                    border-bottom: 1px solid #ddd;
                                    font-size: 13px;

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }

                        }

                        &.dkg-frmgrp-userfilterCon {
                            display: flex;
                            align-items: center;
                            margin-top: 25px;

                            .dkg-user-db-dropdown-24 {
                                .dkg-user-db-toggle-24 {
                                    background: #eaeaea !important;
                                    color: #333;
                                    border: 1px solid #ddd !important;
                                    border-radius: 50%;
                                    box-shadow: none !important;
                                    font-size: 15px;
                                    height: 35px;
                                    margin: 0;
                                    outline: none;
                                    padding: 0;
                                    width: 35px;

                                    i {
                                        font-size: 15px;
                                    }

                                    &::after {
                                        display: none;
                                    }
                                }

                                .dkg-user-db-menu-24 {
                                    background: #f2f2f2;
                                    left: 45px !important;
                                    min-width: auto;
                                    padding: 5px 8px;
                                    right: inherit !important;
                                    top: -5px !important;
                                    -webkit-transform: inherit !important;
                                    transform: inherit !important;
                                    display: flex;

                                    &:before {
                                        border-color: transparent #ccc transparent transparent;
                                        border-style: solid;
                                        border-width: 7.5px 10px 7.5px 0;
                                        content: "";
                                        height: 0;
                                        left: -10px;
                                        line-height: 0;
                                        position: absolute;
                                        top: 16px;
                                        width: 0;
                                    }

                                    .dropdown-item.dkg-user-db-item {
                                        display: inline-block;
                                        margin: 0 3px;
                                        padding: 0;

                                        img.recruiter_img {
                                            border-radius: 50%;
                                            height: 35px;
                                            max-width: 35px;
                                            width: 35px;

                                            &:hover {
                                                border-radius: 50%;
                                                height: 35px;
                                                max-width: 35px;
                                                width: 35px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }

                    .dk-submitBtn-Con {
                        .dk-submitBtn {
                            background: #2d7ee2;
                            color: #fff;
                            border: none;
                            border-radius: 3px;
                            display: inline-block;
                            font-size: 14px;
                            font-weight: 500;
                            outline: 0;
                            outline: none;
                            padding: 8px 15px;
                        }
                    }
                }
            }
        }
    }
}