.dk-bOverviewyears-containerMain {
    margin-top: 80px;
    display: flex;

    .dk-bOverviewyears-rightPanel {
        margin-top: 0;
        padding: 20px;
        width: 100%;

        .dk-sendoutsHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 18px;

            h3.title {
                margin: 0;
                font-size: 18px;
                font-weight: 700;
                position: relative;
                top: 4px;
            }

            .dk-recwallet-centerCon {
                .dk-filterTagsCon {
                    .dkg-pagetitle {
                        font-size: 16px;
                        font-weight: 600;
                        position: relative;
                        top: 2px;
                    }

                    .dk-filterbtnGrp {
                        .dk-filterbtntext {
                            margin: 0;
                            padding: 5px 15px !important;
                            box-shadow: none !important;
                            border-radius: 30px 0 0 30px;
                            background: #366995 !important;
                            outline: none;
                        }

                        .dk-filterbtnicon {
                            padding: 5px 10px !important;
                            margin: 0;
                            margin-left: 0;
                            box-shadow: none !important;
                            border-radius: 0 30px 30px 0;
                            background: #234158 !important;
                            outline: none;
                        }
                    }
                }
            }

            .dk-sendoutsAmRc-dropdown {
                margin-left: 8px;
                display: flex;

                .dropdown {
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .dropdown-toggle {
                        background-color: #fff !important;
                        border: 1px solid #ddd;
                        border-radius: 50%;
                        box-shadow: none;
                        font-size: 0.81rem;
                        height: 35px;
                        line-height: 30px;
                        margin: 0;
                        padding: 0 !important;
                        width: 35px;
                        color: #333 !important;
                        min-width: 35px;
                        z-index: 99;

                        &:hover {
                            box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
                            outline: 0;
                            transition: 0.5s;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        background-color: #234158;
                        left: 50px !important;
                        padding: 5px;
                        top: -15px !important;
                        transform: inherit !important;
                        margin: 0 !important;

                        &::before {
                            border-color: transparent #234158 transparent transparent;
                            _border-color: #000 #234158 #000 #000;
                            border-style: solid;
                            border-width: 8.5px 12px 8.5px 0;
                            content: "";
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            height: 0;
                            left: -12px;
                            line-height: 0;
                            position: absolute;
                            top: 24px;
                            width: 0;
                        }

                        .dropdown-item {
                            position: relative;
                            display: inline-block;
                            margin: 0 5px;
                            padding: 0;

                            &:hover {
                                background: transparent;
                            }

                            img {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                display: block;
                            }

                            span.recNoCount {
                                color: #fff;
                                display: block;
                                font-size: 12px;
                                padding: 4px 0 0;
                                text-align: center;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .dk-sendoutyears-rightPanel-btn {
                display: flex;
                align-items: center;

                .pipeline-cafileterCon {
                    margin-right: 12px;
                    position: relative;

                    .dk-lrftCross {
                        position: absolute;
                        top: -4px;
                        right: -3px;
                        cursor: pointer;
                    }

                    &.dkg-business-rightCon {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-right: 0;

                        .dkg-monthfilterCon {
                            position: relative;
                        }
                    }
                }

                .dkg-bonus-righticon-Con {
                    background: #fff;
                }

                .dk-cli-status-dropdown {
                    .dropdown-toggle {
                        background: #fff !important;
                    }

                    &.dkg-monthfilter-dropdown {
                        &.dkg-monthselector {
                            .dropdown-toggle {
                                background: transparent !important;
                                color: #fff !important;

                                &::after {
                                    color: #fff !important;
                                }
                            }
                        }

                    }
                }

                .dk-sensdouts-searchCols {
                    button {
                        display: inline-flex;
                        width: 35px;
                        height: 35px;
                        color: #848484;
                        border: 1px solid #ddd;
                        margin-right: 10px;
                        border-radius: 50%;
                        background: #fff;
                        align-items: center;
                        justify-content: center;
                        outline: none;

                        &:hover {
                            background: #ccc;
                            color: #333;
                            transition: all 0.5s;
                        }
                    }
                }

                .dropdown {
                    .dropdown-toggle {
                        box-shadow: none;
                        color: #6c757d;
                        font-size: 13px;
                        margin: 0;
                        min-width: 145px;
                        padding: 0.5rem 0.45rem;
                        text-transform: capitalize;
                        background: #fff !important;
                        border: 1px solid #ddd;

                        &::after {
                            color: #6c757d;
                            display: block;
                            font-size: 1.2rem;
                            position: absolute !important;
                            position: absolute;
                            right: 10px;
                            top: 16px;
                        }
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        z-index: 99999;
                        transform: inherit !important;
                        border: none;
                        width: 100%;
                        top: 36px !important;

                        a {
                            align-items: center;
                            display: flex;
                            font-size: 13px;
                            justify-content: space-between;
                            padding: 5px 10px;
                            color: #333;
                            border-bottom: 0.5px solid #acacac;

                            .dropdown-filter-count {
                                align-items: center;
                                background-color: #3c4c62;
                                border-radius: 2px;
                                color: #fff;
                                display: flex;
                                font-size: 12px;
                                justify-content: center;
                                padding: 0 5px;
                                top: 5px;
                            }
                        }
                    }

                    .dropdown-menu.dk-yearsDropdown-items {
                        a {
                            background: #fff;
                            border-bottom: 1px solid #ddd;
                            color: #333;

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            .dk-sendoutCaseSt-rightPanel {
                display: flex;
                align-items: center;

                button.dk-filterBtn {
                    border: 1px solid #ddd;
                    background: transparent;
                    margin-right: 10px;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;
                    min-width: 35px;
                    outline: none;

                    &:hover {
                        background: #7d7d87;
                        transition: all 0.5s;
                        color: #374252;
                    }
                }

                .dropdown {
                    .dropdown-toggle {
                        margin: 0;
                        border-radius: 3px;
                        background: #2e75b6;
                        color: #fff;
                        font-size: 13px;
                        box-shadow: none;
                        font-family: "Roboto", sans-serif;
                        padding: 7px 10px;
                        min-width: 110px;

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        transform: inherit !important;
                        min-width: 110px;
                        border-radius: 0;
                        background: #fff;
                        border: none;
                        padding: 0;
                        margin: 35px 0 0 !important;
                        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
                        z-index: 99999;

                        a {
                            padding: 6px 10px;
                            font-size: 13px;
                        }

                        a.active {
                            font-size: 14px;
                            background: #2e75b6;
                        }
                    }
                }
            }

            .dk-sendoutsyears-yearsDropdown {
                display: flex;
                align-items: center;

                .dk-searchBox {
                    max-width: 150px;
                    position: relative;
                    width: 100%;

                    input {
                        background: #fff;
                        border: 1px solid #ddd;
                        box-shadow: none;
                        color: #a4a4a4;
                        outline: none;
                        text-align: left;

                        &:focus::placeholder {
                            opacity: 0;
                        }
                    }

                    button {
                        // border: none;
                        background-color: #fff;
                        border: 1px solid #ddd;
                        box-shadow: none;
                        color: #7d7d87;
                        height: 32px;
                        line-height: 28px;
                        outline: none;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 33px;
                    }
                }

                .addMonthBtn {
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 8px;
                    text-align: center;
                    width: 35px;
                    min-width: 35px;
                    background-color: #fff;

                    &:hover {
                        background: #7d7d87;
                        transition: all 0.5s;
                        color: #374252;
                    }
                }

                .dropdown-toggle {
                    background: #3c97b6 !important;
                    border-radius: 3px;
                    box-shadow: none;
                    color: #fff !important;
                    font-size: 13px;
                    margin: 0;
                    min-width: 110px;
                    padding: 7px 10px;
                    z-index: 99999;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    background: #fff;
                    border: none;
                    border-radius: 0;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    margin: 35px 0 0 !important;
                    min-width: 110px;
                    padding: 0;
                    -webkit-transform: inherit !important;
                    transform: inherit !important;
                    z-index: 99999;

                    a {
                        padding: 6px 10px;
                        font-size: 13px;

                        &:hover {
                            background: #f8f9fa;
                        }
                    }

                    a.active {
                        background: #3c97b6;
                    }
                }
            }

            .dk-sendouts-months {
                display: flex;
                justify-content: flex-end;
                margin-left: 0;
                width: 100%;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;

                    li {
                        display: inline-block;
                        list-style-type: none;

                        &:first-child {
                            a {
                                border-left: 1px solid #616167;
                            }
                        }

                        a {
                            background: #4a5057;
                            border-bottom: 1px solid #616167;
                            border-right: 1px solid #616167;
                            border-top: 1px solid #616167;
                            color: #fff;
                            cursor: pointer;
                            float: left;
                            height: 35px;
                            padding: 7px 10px;
                            text-align: center;
                            width: 75px;
                        }

                        a.active {
                            background: #3c97b6;
                        }

                    }
                }

                .addMonthBtn {
                    border: 1px solid #7d7d87;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;

                    &:hover {
                        background: #7d7d87;
                        color: #fff;
                        transition: all .5s;
                    }
                }
            }
        }


    }

    .dk-bOverviewyears-rightPanel {
        .dk-bOverviewyears-table {
            height: calc(100vh - 180px);
            margin-top: 6px;
            overflow-x: auto;
            overflow-y: auto;
            padding-bottom: 0;
            position: relative;

            table {
                border: none;

                tr {
                    th {
                        background: #485569;
                        border: 1px solid gray;
                        border-bottom: none;
                        color: #fff;
                        font-size: 13px;
                        text-transform: uppercase;
                        font-weight: 400;
                        padding: 10px 12px;
                        position: -webkit-sticky;
                        position: sticky;
                        text-align: center;
                        top: 0;
                        vertical-align: middle;
                        white-space: nowrap;
                        z-index: 9999;
                        &:nth-child(13),
                        &:nth-child(14){
                            min-width: 145px;
                            max-width: 145px;
                        }
                    }

                    td {
                        background: #fff;
                        border: 1px solid #f1ecec;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 1px 12px;
                        position: relative;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        &:nth-child(02) {
                            padding: 0;
                            border-bottom: 1px solid gray !important;

                            .caseDiv {
                                min-height: 37px;
                                line-height: 37px;
                            }
                        }

                        // &:nth-child(05) {
                        //     text-align: left;
                        // }

                        .dk-checkinputIconCon {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .dk-checkinputIcon {
                                position: relative;
                                top: 3px;
                                margin-left: 5px;

                                input {
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }

                        .dk-caseStatusDropCon {
                            .dropdown {
                                .dk-caseStatusfliterbtn {
                                    .caseDiv {
                                        min-height: 37px;
                                        line-height: 37px;
                                    }
                                }
                            }
                        }

                        // &:nth-child(6) {
                        //     padding: 0 !important;
                        //     border: 1px solid gray;
                        // }

                        // &:nth-child(7) {
                        //     padding: 0;
                        //     border: 1px solid gray;
                        // }

                        &:nth-child(8),
                        &:nth-child(9) {
                            padding: 0;
                            border: 1px solid gray;
                        }

                        &:nth-child(12) {
                            text-align: left;
                        }

                        .dk-smsTableCheckbox {
                            .dk-chkSendouts {
                                margin: 0 7px;
                            }
                        }

                        span.dk-clickable-link {
                            cursor: pointer;

                            &:hover {
                                color: #629bd3;
                            }
                        }

                        .dk-sendoutsComment {
                            display: inline-flex;
                            justify-content: center;
                            background-color: #eaeaea;
                            align-items: center;
                            border-radius: 50%;
                            cursor: pointer;
                            height: 25px;
                            line-height: 25px;
                            width: 25px;
                            color: #333;

                            &:hover {
                                background-color: #234158;
                                color: #fff;
                            }
                        }

                        .commentBoxCon {
                            align-items: center;
                            display: flex;
                            justify-content: center;

                            .numCon {
                                background-color: #eaeaea;
                                border-radius: 50%;
                                cursor: pointer;
                                height: 25px;
                                line-height: 25px;
                                width: 25px;

                                &:hover {
                                    background-color: #234158;
                                    color: #fff;
                                }
                            }

                            .numCon.zeroCommentCount {
                                color: #eaeaea;
                            }
                        }

                        .dk-status {
                            background: #316a67;
                            padding: 12px 0;
                            text-align: center;
                            color: #fff;
                        }

                        .dk-paidDropdown.dk-progressDropdown {
                            .dropdown-menu {
                                a {
                                    color: #333 !important;
                                    border: 1px solid #ddd !important;
                                }
                            }
                        }

                        .dk-paidDropdown {
                            .dropdown {
                                .dropdown-toggle {
                                    color: #fff;
                                    box-shadow: none;
                                    padding: 8px 15px;
                                    width: 100%;
                                    text-align: center;
                                    background: #5c737d;
                                    text-transform: inherit;
                                    font-size: 13px;
                                    margin: 0;
                                    border-radius: 0;
                                    white-space: nowrap;

                                    &::after {
                                        display: none;
                                    }
                                }

                                .dropdown-menu {
                                    padding: 0;
                                    border-radius: 0;
                                    border: none;
                                    margin: 0 !important;
                                    transform: inherit !important;
                                    top: 37px !important;
                                    width: 100%;
                                    min-width: 100%;

                                    a {
                                        padding: 6px 10px;
                                        font-size: 13px;
                                        display: block;
                                        border-bottom: 1px solid #ddd;
                                        color: #fff;
                                        background: #fff;

                                        &:last-child {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-tdImg {
                            img {
                                border-radius: 50%;
                                display: inline-block;
                                height: 32px;
                                width: 32px;
                            }

                            .dropdown {
                                .dropdown-toggle {
                                    background-color: transparent !important;
                                    border-radius: 50%;
                                    box-shadow: none !important;
                                    color: #333;
                                    margin: 0;
                                    padding: 0;

                                    &::after {
                                        display: none;
                                    }

                                    img {
                                        border-radius: 50%;
                                        left: 0;
                                        position: relative;
                                        top: 0;
                                        width: 32px;
                                        height: 32px;
                                    }
                                }

                                .dropdown-menu {
                                    background: #f2f2f2;
                                    border-radius: 3px;
                                    left: 48px !important;
                                    min-width: -webkit-fit-content;
                                    min-width: -moz-fit-content;
                                    min-width: fit-content;
                                    padding: 5px 5px;
                                    right: 0 !important;
                                    top: -6px !important;
                                    -webkit-transform: inherit !important;
                                    transform: inherit !important;
                                    width: -webkit-fit-content;
                                    width: -moz-fit-content;
                                    width: fit-content;
                                    z-index: 9999;

                                    &::before {
                                        border-color: transparent #ccc transparent transparent;
                                        _border-color: #000 #ccc #000 #000;
                                        border-style: solid;
                                        border-width: 8.5px 12px 8.5px 0;
                                        content: "";
                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        height: 0;
                                        left: -12px;
                                        line-height: 0;
                                        position: absolute;
                                        top: 10px;
                                        width: 0;
                                    }

                                    a {
                                        display: inline-block;
                                        margin: 0 3px;
                                        padding: 0;
                                        width: 30px;

                                        img {
                                            border-radius: 50%;
                                            height: 30px;
                                            width: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:first-child {
                        td {
                            border-top: 1px solid gray !important;
                            // &:nth-child(11){
                            //     border-bottom: 1px solid gray;
                            // }
                        }
                    }
                }

                tfoot.sum {
                    tr {
                        td {
                            padding: 8px 12px;
                            border: 0.5px solid #ddd;
                        }
                    }
                }

                tfoot {
                    tr {
                        td {
                            border: none !important;
                            background: #fff;
                            padding: 8px 10px;

                            &:nth-child(12) {
                                border: 1px solid #ccc !important;
                                font-weight: 600;
                                background: #f3f3f3;
                            }

                            &:nth-child(12),
                            &:nth-child(13),
                            &:nth-child(14),
                            &:nth-child(16) {
                                border: 1px solid #ccc !important;
                                font-weight: 600;
                                background: #f3f3f3;
                            }

                            // &:nth-child(12) {
                            //     border: 1px solid #ccc !important;
                            //     font-weight: 600;
                            //     background: #f3f3f3;
                            // }

                            // &:nth-child(13){
                            //     border: 1px solid #ccc !important;
                            //     font-weight: 600;
                            //     background: #f3f3f3;
                            // }
                        }
                    }
                }
            }

            .dkg-acc-invoice-table {
                thead {
                    tr {
                        th {
                            background-color: #5c737d !important;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            // padding: 1px 12px;

                            &:nth-child(02) {
                                padding: inherit;
                                // border-bottom: inherit !important;
                            }

                            &:nth-child(03),
                            &:nth-child(04),
                            &:nth-child(05),
                            &:nth-child(06) {
                                min-width: 105px;
                                padding: 0;
                            }

                            &:nth-child(08) {
                                padding: 0 !important;
                                border: 1px solid gray;
                            }

                            &:nth-child(09) {
                                padding: 0 !important;
                                border: 1px solid gray;
                            }

                            &:nth-child(10) {
                                padding: 0;
                                border: 1px solid gray;
                            }

                            // &:nth-child(07) {
                            //     padding: 0 !important;
                            // }
                        }

                        &:first-child {
                            td {
                                border-top: none !important;
                            }
                        }

                    }
                }

                tfoot {
                    tr {
                        td {
                            &:nth-child(02) {
                                border: none !important;
                                border-top: 1px solid #ddd !important;
                            }



                            &:nth-child(12),
                            &:nth-child(13),
                            &:nth-child(14) {
                                background-color: transparent !important;
                                border: none !important;
                            }

                            &:nth-child(16) {
                                border: 1px solid #ccc !important;
                                font-weight: 600;
                                background: #f3f3f3;
                            }
                        }
                    }
                }

                &.dkg-clientinvo-banking-ui123 {
                    thead {
                        tr {
                            th {
                                background-color: #485569 !important;
                                border: 1px solid gray !important;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                &:nth-child(05) {
                                    text-align: center;
                                }

                                &:nth-child(08) {
                                    text-align: left;
                                    border: 1px solid #ddd;
                                    padding: 1px 12px !important;
                                }

                                &:nth-child(09) {
                                    padding: 0;
                                    border: 1px solid gray;
                                }

                                &:nth-child(11) {
                                    padding: 0;
                                    border: 1px solid gray;
                                }

                                // &:nth-child(15) {
                                //     text-align: left;
                                // }
                            }
                        }
                    }

                    tfoot {
                        tr {
                            td {

                                &:nth-child(11),
                                &:nth-child(16) {
                                    background-color: transparent;
                                    border: none !important;
                                }

                                &:nth-child(13),
                                &:nth-child(14),
                                &:nth-child(15) {
                                    background-color: #eaeaea !important;
                                    border: 1px solid #ddd !important;
                                    font-weight: 600 !important;
                                }

                            }
                        }
                    }
                }
            }

            .dkg-business-finalfee-table {
                tfoot {
                    tr {
                        td {

                            &:nth-child(13),
                            &:nth-child(14),
                            &:nth-child(15) {
                                border: none !important;
                                background-color: transparent !important;
                            }
                        }
                    }
                }
            }

            .dkg-business-joboffers-table,
            .dkg-business-joined-table {
                tbody {
                    tr {
                        td {
                            padding: 0 !important;

                            .dk-reason-dropdownCon {
                                .dk-reason-dropdown {
                                    .dk-reason-dropdown-toggle {
                                        box-shadow: none;
                                        background-color: transparent;
                                        margin: 0;
                                        white-space: nowrap;
                                        font-size: 13px;
                                        text-transform: capitalize;
                                        color: #333;
                                        width: 100%;
                                        // height: 40px;
                                        // line-height: 40px;
                                        cursor: pointer;
                                        padding: 0 7px !important;

                                        &::after {
                                            display: none;
                                        }
                                    }

                                }

                                .dk-reason-dropdownMenu {
                                    border-radius: 0;
                                    padding: 0;
                                    transform: translate(-1px, 35px) !important;

                                    .dropdown-item {
                                        text-align: left;
                                        font-size: 13px;
                                        padding: 6px 10px;
                                        border: none;
                                        border-bottom: 1px solid #ddd;

                                        &:last-child {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                            .dkg-case-stus-dropdown{
                                .dkg-case-stus-dropdown-toggle{
                                    margin: 0 !important;
                                    padding: 0;
                                    color: #fff;
                                    width: 100%;
                                    min-width: 85px;
                                    max-width: 85px;
                                    font-size: 0.85rem;
                                    border-radius: 0;
                                    text-transform: capitalize;
                                    .statusDiv{
                                        padding: 8px 5px;
                                        height: 37px;
                                    }
                                    &::after{
                                        display: none;
                                    }
                                }
                                .dkg-case-stus-dropdownMenu{
                                    padding: 0;
                                    border-radius: 0;
                                    margin: 0 !important;
                                    top: 34px !important;
                                    transform: inherit !important;
                                    left: 0 !important;
                                    right: inherit !important;
                                    border: none;
                                    min-width: 90px;
                                    max-width: 90px;
                                    .dkg-case-stus-dropdown-item{
                                        display: block;
                                        width: 100%;
                                        font-size: 14px;
                                        padding: 6px 12px;
                                        text-transform: capitalize;
                                    }
                                }
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {

                            &:nth-child(12),
                            &:nth-child(15) {
                                border: none !important;
                                background-color: transparent !important;
                            }

                        }
                    }
                }
            }

            .dkg-business-finalfee-table {
                tbody {
                    tr {
                        td {
                            padding: 0 !important;

                            .dk-reason-dropdownCon {
                                .dk-reason-dropdown {
                                    .dk-reason-dropdown-toggle {
                                        box-shadow: none;
                                        background-color: transparent;
                                        margin: 0;
                                        white-space: nowrap;
                                        font-size: 13px;
                                        text-transform: capitalize;
                                        color: #333;
                                        width: 100%;
                                        // height: 40px;
                                        // line-height: 40px;
                                        cursor: pointer;
                                        padding: 0 7px !important;

                                        &::after {
                                            display: none;
                                        }
                                    }

                                }

                                .dk-reason-dropdownMenu {
                                    border-radius: 0;
                                    padding: 0;
                                    transform: translate(-1px, 35px) !important;

                                    .dropdown-item {
                                        text-align: left;
                                        font-size: 13px;
                                        padding: 6px 10px;
                                        border: none;
                                        border-bottom: 1px solid #ddd;

                                        &:last-child {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                            .dkg-case-stus-dropdown{
                                .dkg-case-stus-dropdown-toggle{
                                    margin: 0 !important;
                                    padding: 0;
                                    color: #fff;
                                    width: 100%;
                                    min-width: 85px;
                                    max-width: 85px;
                                    font-size: 0.85rem;
                                    border-radius: 0;
                                    text-transform: capitalize;
                                    .statusDiv{
                                        padding: 8px 5px;
                                        height: 37px;
                                    }
                                    &::after{
                                        display: none;
                                    }
                                }
                                .dkg-case-stus-dropdownMenu{
                                    padding: 0;
                                    border-radius: 0;
                                    margin: 0 !important;
                                    top: 34px !important;
                                    transform: inherit !important;
                                    left: 0 !important;
                                    right: inherit !important;
                                    border: none;
                                    min-width: 90px;
                                    max-width: 90px;
                                    .dkg-case-stus-dropdown-item{
                                        display: block;
                                        width: 100%;
                                        font-size: 14px;
                                        padding: 6px 12px;
                                        text-transform: capitalize;
                                    }
                                }
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {

                            &:nth-child(12),
                            &:nth-child(15) {
                                border: none !important;
                                background-color: transparent !important;
                            }

                            &:nth-child(13) {
                                background-color: #eaeaea !important;
                                border: 1px solid #ddd !important;
                            }

                        }
                    }
                }
            }

            .dkg-business-interviews-table {
                tbody {
                    tr {
                        td {
                            padding: 0;

                            .dk-reason-dropdownCon {
                                .dk-reason-dropdown {
                                    .dk-reason-dropdown-toggle {
                                        box-shadow: none;
                                        background-color: transparent;
                                        margin: 0;
                                        white-space: nowrap;
                                        font-size: 13px;
                                        text-transform: capitalize;
                                        color: #333;
                                        width: 100%;
                                        // height: 40px;
                                        // line-height: 40px;
                                        cursor: pointer;
                                        padding: 0 7px !important;

                                        &::after {
                                            display: none;
                                        }
                                    }

                                }

                                .dk-reason-dropdownMenu {
                                    border-radius: 0;
                                    padding: 0;
                                    transform: translate(-1px, 35px) !important;

                                    .dropdown-item {
                                        text-align: left;
                                        font-size: 13px;
                                        padding: 6px 10px;
                                        border: none;
                                        border-bottom: 1px solid #ddd;

                                        &:last-child {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                            .dkg-case-stus-dropdown{
                                .dkg-case-stus-dropdown-toggle{
                                    margin: 0 !important;
                                    padding: 0;
                                    color: #fff;
                                    width: 100%;
                                    min-width: 85px;
                                    max-width: 85px;
                                    font-size: 0.85rem;
                                    border-radius: 0;
                                    text-transform: capitalize;
                                    .statusDiv{
                                        padding: 8px 5px;
                                        height: 37px;
                                    }
                                    &::after{
                                        display: none;
                                    }
                                }
                                .dkg-case-stus-dropdownMenu{
                                    padding: 0;
                                    border-radius: 0;
                                    margin: 0 !important;
                                    top: 34px !important;
                                    transform: inherit !important;
                                    left: 0 !important;
                                    right: inherit !important;
                                    border: none;
                                    min-width: 90px;
                                    max-width: 90px;
                                    .dkg-case-stus-dropdown-item{
                                        display: block;
                                        width: 100%;
                                        font-size: 14px;
                                        padding: 6px 12px;
                                        text-transform: capitalize;
                                    }
                                }
                            }
                            .dk-useFilterCon{
                                .dropdown{
                                    .dk-pipleuserFilterMenu{
                                        border: 1px solid #234158;
                                        background: #234158;
                                        .d-flex{
                                            .dropdown-item{
                                                padding: 0;
                                                display: inline-block;
                                                width: 35px;
                                                height: 35px;
                                                margin: 0 3px;
                                                .tooltipMain{
                                                    .filterImg{
                                                        border-radius: 50%;
                                                    }
                                                    .tooltipText{
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:nth-child(09){
                                padding: 0 !important;
                                border-bottom: 1px solid grey;
                            }
                            &:nth-child(13),
                            &:nth-child(14){
                                 min-width: 145px;
                                 max-width: 145px;
                               }

                        }
                    }
                }

                tfoot {
                    tr {
                        td {

                            &:nth-child(12),
                            &:nth-child(14),
                            &:nth-child(15) {
                                border: none !important;
                                background-color: transparent !important;
                            }

                        }
                    }
                }
            }
        }
    }
}

.tooltipText {
    display: none;
    background: #333;
    border-radius: 3px;
    color: #fff;
    display: none;
    font-size: 11px;
    padding: 2px 8px;
    text-transform: capitalize;
    top: 42px;
    white-space: nowrap;
    z-index: 99;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);

    &::after {
        border-color: transparent transparent #333;
        _border-color: #000 #000 #333 #000;
        border-style: solid;
        border-width: 0 7.5px 12px;
        content: "";
        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
        height: 0;
        line-height: 0px;
        top: -11px;
        width: 0;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
}


.dk-sendoutsSearch-modal {
    .modal-dialog {
        max-width: 430px;
        min-width: 430px;
        margin: 0 !important;
        position: fixed;
        width: 100%;

        .modal-content {
            box-shadow: none;
            border: none;

            .modal-header {
                background: #2e97b6;
                border-radius: 0;
                color: #fff;
                padding: 10px 0;
                border-bottom: none;

                .modal-title {
                    display: block;
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                    font-family: Segoe UI, Arial, sans-serif;
                }

                .close {
                    color: #fff;
                    font-size: 25px;
                    font-weight: 400;
                    margin: 0 10px;
                    opacity: 1;
                    outline: none;
                    padding: 0;
                    text-shadow: none;
                }
            }

            .modal-body {
                background-color: #234158;
                height: calc(100vh - 45px);
                overflow-y: auto;
                padding: 0;

                .dk-sendoutFilterTabs-main {
                    display: flex;

                    .dk-sendoutTabs-nav {
                        background-color: #eaeaea;
                        max-width: 170px;
                        min-width: 170px;
                        height: calc(100vh - 40px);

                        .nav-item {
                            background-color: #eaeaea;
                            border-bottom: 1px solid #ddd;
                            max-width: 170px;
                            width: 100%;

                            a {
                                border-radius: 0;
                                color: #333;
                                font-size: .95rem;
                                padding: 10px 12px;
                                text-transform: uppercase;
                            }

                            a.active {
                                background-color: #234158;
                                color: #ddd;
                            }
                        }
                    }

                    .dk-sendoutTabs-filterPanel {
                        width: 100%;

                        .dk-panelMainContent {
                            padding: 1.25rem;

                            .dk-Recuriter {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                ul {
                                    list-style: none;
                                    margin-bottom: 0;

                                    li {
                                        margin-bottom: 10px;
                                        margin-top: 15px;

                                        a {
                                            img {
                                                border-radius: 50%;
                                                height: 40px;
                                                width: 40px;
                                            }

                                            .recuriterNn {
                                                color: #ff9800;
                                                margin-left: 8px;
                                                display: inline-block;
                                                margin-left: 8px;
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-CategoryList {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                ul {
                                    list-style: none;
                                    margin-bottom: 0;

                                    li {
                                        margin-bottom: 1rem;
                                        margin-top: 1rem;

                                        a {
                                            color: #ccc;

                                            span {
                                                color: #ff9800;
                                                margin-left: 0.5rem;
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-satusListFilter {
                                display: block;

                                ul {
                                    list-style: none;
                                    margin: 0 auto;
                                    max-width: 80%;
                                    width: 100%;

                                    li {
                                        align-items: center;
                                        display: flex;
                                        margin: 15px 0 0;

                                        a {
                                            border-radius: 30px;
                                            color: #fff;
                                            font-size: 13px;
                                            padding: 5px 10px;
                                            text-align: center;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

.dk-sendoutsSearch-modal.modal.right.fade {
    right: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.dk-yearsAddNewTask-modal {
    .modal-dialog {
        max-width: 600px;
        min-width: 600px;
        position: fixed;
        margin: 0 !important;

        .modal-content {
            border-radius: 0;
            border: none;
            box-shadow: none;

            .modal-header {
                align-items: center;
                background-color: #234158;
                border-bottom: none;
                border-radius: 0;
                display: flex;
                justify-content: space-between;
                padding: 10px 15px;
                width: 100%;

                .modal-title {
                    color: #fff;
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    margin: 0;
                    opacity: .9;
                    padding-top: 1px;
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                }

                .close {
                    align-items: center;
                    color: #fff;
                    display: flex;
                    height: 53px;
                    justify-content: center;
                    margin: 0;
                    opacity: .9;
                    padding: 0;
                    position: absolute;
                    right: 2px;
                    text-shadow: none;
                    top: 0;
                    width: 30px;
                    outline: none;
                }
            }

            .modal-body {
                background-color: #eaeaea;
                height: calc(100vh - 46px);
                overflow-y: auto;
                padding: 15px;

                .form-group {
                    display: flex;
                    align-items: center;

                    label {
                        font-size: 13px;
                        margin-bottom: 0;
                        width: 100%;

                        &:first-child {
                            min-width: 155px;
                            max-width: 155px;
                            text-transform: uppercase;
                        }

                        input {
                            outline: none;
                            text-align: center;
                            height: 32px;
                        }
                    }

                    .dkg-contracts-dropdown {
                        width: 100%;
                    }

                    button.dkaddBtn {
                        display: inline-block;
                        background: #234158;
                        border: none;
                        color: #fff;
                        padding: 5px 25px;
                        border-radius: 3px;
                        text-transform: uppercase;
                        outline: none;
                        font-size: 16px;

                        &:hover {
                            background: #26597d;
                            transition: all 0.5s;
                        }
                    }

                    .dropdown {
                        .dropdown-toggle {
                            align-items: center;
                            background-color: #fff;
                            border: 1px solid #ddd;
                            box-shadow: none;
                            color: gray;
                            display: flex;
                            font-size: 13px;
                            justify-content: space-around;
                            margin: 0;
                            padding: 7px 10px;
                            text-transform: capitalize;
                            width: 100%;

                            &::after {
                                color: gray;
                                display: inline-block;
                                font-size: 16px;
                                position: absolute;
                                right: 11px;
                                top: 16px;
                            }
                        }

                        .dropdown-menu {
                            border-radius: 0;
                            max-height: 220px;
                            overflow-y: auto;
                            padding: 0;
                            width: 100%;

                            a {
                                border-bottom: 1px solid #ddd;
                                padding: 5px 7px;
                            }
                        }
                    }

                    &.dkg-save-btnCon {
                        display: flex;
                        justify-content: center;

                        div {
                            div {
                                button.dk-create-new-taskbtn {
                                    background: #234158;
                                    border: 1px solid #234158;
                                    border-radius: 0.1875rem;
                                    color: #fff;
                                    display: inline-block;
                                    font-size: 13px;
                                    line-height: 1.5385;
                                    margin-left: 120px;
                                    padding: 5px 12px;
                                    text-align: center;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-yearsAddNewTask-modal.modal.right {
    right: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.dk-jobIdModal {
    .modal-dialog {
        max-width: 300px;
        min-width: 300px;
        width: 100%;

        .modal-content {
            box-shadow: none;
            border: none;
            border-radius: 5px;

            .modal-body {
                padding: 25px;

                .form-group {
                    margin: 0 10px 10px;

                    label {
                        display: block;
                        text-align: center;

                        input {
                            border: 1px solid #ddd;
                            min-width: 110px;
                        }
                    }
                }

                .dk-saveBtn {
                    background-color: #26597d;
                    border: none;
                    border-radius: 5px;
                    color: #fff;
                    display: inline-block;
                    outline: none;
                    padding: 8px 22px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.cursor-p {
    cursor: pointer;
}

.dk-adsCreAddNewPop-234 {
    .modal-dialog {
        .modal-content {
            .modal-body {
                background-color: #f2f2f2;
                border-radius: 6px;
                padding: 20px 30px;
                position: relative;

                .dkg-crosssicon-con {
                    background: #102c46;
                    border-radius: 50%;
                    color: #fff;
                    cursor: pointer;
                    display: inline-block;
                    height: 30px;
                    line-height: 28px;
                    position: absolute;
                    right: -9px;
                    text-align: center;
                    top: -11px;
                    width: 30px;

                    svg {
                        height: 14px;
                        width: 14px;
                    }
                }

                .dk-adsCreditInCon {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 5px;

                    .firstInput {
                        max-width: 150px;

                        label {
                            text-align: center;
                            width: 100%;

                        }

                        input {
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                            background-color: #e2e7f6;
                            border-radius: 0;
                            outline: 0;
                            text-align: center;
                        }
                    }

                    .SecondInput {
                        max-width: 150px;
                        margin-left: 10px;

                        label {
                            text-align: center;
                            width: 100%;
                        }

                        input {
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                            border-radius: 0;
                            text-align: center;
                        }
                    }

                    .dk-adsCreditBtnCon {
                        margin-left: 8px;
                        margin-top: 28px;
                        width: auto;

                        div {
                            div {
                                button {
                                    background: #2b8faf !important;
                                    font-size: 14px;
                                    padding: 6px 30px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}