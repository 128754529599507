.dk-dailyappsPageCon {
    margin-top: 75px;
    position: fixed;
}

.dk-dailyappsPageCon .dk-appsleftbtnCon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dk-dailyappsPageCon .dk-appsrightbtnCon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dk-dailyappsPageCon .dk-appsrightbtnCon a {
    background-color: #fff;
    color: #333;
    display: block;
    padding: 8px;
    text-decoration: none;
    border: 1px solid #ccc;
    min-width: 80px;
    text-align: center;
    font-size: 14px;
    border-right: none;
}

.dk-dailyappsPageCon .dk-appsrightbtnCon a:last-child {
    border-right: 1px solid #ccc;
}

.dk-dailyappsPageCon .dk-appsrightbtnCon a:hover,
.dk-dailyappsPageCon .dk-appsrightbtnCon a.active {
    background-color: #2b4a5f;
    color: #fff !important;
    border: 1px solid #2b4a5f;
}

.dk-dailyappsPageCon .dk-dailyappsTitleCon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

.dk-dailyappsPageCon .dk-dailyappsTitleCon h2.dk-dailyappsTitle {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.dk-dailyappsPageCon .dk-appTrackerTableBg {
    /* background-color: #374252; */
    height: 100vh;
}

.dk-dailyappsTableCon {
    background-color: #374252;
    padding: 25px 15px;
    width: 100%;
    height: 100vh;
    margin-top: 0.725rem;
    height: calc(100vh - 180px);
    /* padding-bottom: 10px; */
    overflow: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.dk-dailyappsTableCon .dk-dailyappsTab.nav-item {
    width: 100%;
    display: flex;
    align-items: center;
    /* max-width: 60px; */
}

.nav-pills.dk-dailyappsTabs .nav-link {
    border-right: none;
    cursor: pointer;
    text-align: center;
    border-radius: 0;
    outline: 0;
    text-transform: uppercase;
    width: 40px;
    height: 40px;
    margin-right: 4px;
    border: none;
    padding-left: 0;
}

.nav-pills.dk-dailyappsTabs .nav-link.active,
.nav-pills.dk-dailyappsTabs .show>.nav-link {
    background-color: transparent;
    border: 3px solid transparent;
    position: relative;
    border: none;
}

/* .nav-pills.dk-dailyappsTabs .nav-link.active::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 10px 0 10px;
    border-color: #3291f7 transparent transparent transparent;
    line-height: 0;
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translate(-50%);
} */
img.dk-dalilrecIMG {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #ddd;
}

.nav-pills.dk-dailyappsTabs .nav-link.active img {
    border: 3px solid #FFA500 !important;
}

.dk-dailyAppstableCont {
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden !important;
    height: calc(100vh - 165px);
    padding-bottom: 0;
    margin-top: 10px;
    position: relative;
}

.dk-dailyAppstableCont .dk-dailyAppstable thead tr th {
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 1px 9px;
    vertical-align: middle;
    /* background: #2C394B; */
    background-color: #eaeaea;
    color: #333;
    /* border: 1px solid #616167; */
    border: 1px solid #999;
    min-width: 110px;
    max-width: 110px;
    position: sticky;
    top: 0;
    z-index: 99;
}

.dk-dailyAppstableCont .dk-dailyAppstable thead tr th:last-child {
    color: #333;
}

.dk-dailyAppstableCont .dk-dailyAppstable thead tr th:last-child {
    /* border: 1px solid #616767; */
    background: #eaeaea;
    /* color: #e2dada; */
    color: #333;
}

.dk-appstrackTableSearch .input-group {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
}

.dk-appstrackTableSearch .input-group input {
    border: 1px solid #eaeaea;
    background-color: #eaeaea;
    padding: 0 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    height: 35px;
}

.dk-appstrackTableSearch .input-group .input-group-append button {
    position: absolute;
    right: 0;
    top: 9px;
    padding: 0;
    margin: 0;
    background-color: #eaeaea !important;
    color: #808080;
    box-shadow: none;
    border: 1px solid transparent;
    border-left: none;
}

.dk-appstrackTableSearch .input-group .input-group-append button i {
    color: #908c8c;
}

/* .dk-dailyAppstableCont .dk-dailyAppstable tbody tr:last-child td:first-child{
    border: none;
    background-color: transparent;
} */
.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td {
    background: #252c37;
    color: #bdb4b4;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    padding: 7px 15px;
    border: .5px solid #726e6e;
    vertical-align: middle;
    min-width: 110px;
    max-width: 110px;
}

.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td:first-child {
    margin-left: 10px;
}

.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td.monBg {
    background-color: #26596d;
}

.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td.tusBg {
    background-color: #57496d;
}

.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td.wedBg {
    background-color: #535255;
}

.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td.thusBg {
    background-color: #a84d4d;
}

.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td.friBg {
    background-color: #4c5f59;
}

.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td.satBg {
    background-color: #28a745;
}

.dk-dailyAppstableCont .dk-dailyAppstable tbody tr td.sunBg {
    background-color: #dc3545;
}

.dk-dailyAppstableCont .dk-dailyAppstable tfoot tr td {
    position: sticky;
    bottom: 0;
    background: #3a3a52;
    color: #e2dada;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 15px !important;
    border: 1px solid #616767;
    vertical-align: middle;
    border: none;
}