.modal-dialog.dk-qualifyrorderPopup{
    min-width: 40%;
    .modal-content {
        background-color: transparent;
        .modal-header.dk-qualifyRorderheader {
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #d9d9d9;
            padding: 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-align: center;
            .modal-title.dk-qualifyRorderTitle{
                width: 100%;
                text-align: center;
                color: #333;
                font-size: 18px;
                font-weight: 600;
            }
            & button.close{
                opacity: .8;
            }
        }
        .dk-qualifyRorderBody{
            background-color: #fff !important;
            padding: 10px 25px 20px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .dk-ReorderTableCon{
                .dk-ReorderTable{
                    margin-bottom: 0;
                    border-top: 0;
                    tbody{
                        tr td{
                            padding: 4px 10px;
                            text-align: center;
                            border: 1px solid #ddd;
                            vertical-align: middle;
                            font-size: 14px;
                            margin-bottom: 1rem;
                        }
                        tr td:nth-child(1){
                            background: #234158;
                            border: 1px solid #234158;
                            border-bottom: 1px solid #ddd;
                            color: #fff;
                            width: 40px;

                        }
                    }
                }
                .makeStyles-root-7{
                    .makeStyles-wrapper-8{
                        .dk-addCliBtn.dk-camupdateBtn,
                        .dk-addCliBtn.dk-camupdateBtn:hover {
                            display: inline-block;
                            background: #26597d;
                            border: none;
                            color: #fff;
                            text-transform: uppercase;
                            padding: 8px 25px;
                            font-weight: 500;
                            font-size: 14px;
                            outline: none;
                            cursor: pointer;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
}