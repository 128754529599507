.dkg-finance-cashflowtwentytwoMain {
    margin-top: 110px;

    .dkg-cashflowtwentytwo-pageCon {
        .dkg-cashflowtwentytwo-TableCon {
            height: calc(100vh - 140px);
            margin-top: 6px;
            overflow-x: auto;
            overflow-y: auto;
            padding-bottom: 0;
            position: relative;

            .dkg-cashflowtwentytwo-Table {
                &.dkg-cashfloe-cashin-table78 {
                    border: none;
                }

                thead {
                    tr {
                        th {
                            // background: #5A4C72;
                            background-color: #fff !important;
                            border: 1px solid #ddd !important;
                            color: #495057;
                            font-size: 13px;
                            padding: 8px 12px;
                            white-space: nowrap;
                            position: -webkit-sticky;
                            position: sticky;
                            text-align: center;
                            text-transform: capitalize;
                            top: 0;
                            vertical-align: middle;
                            z-index: 9;
                            font-weight: bold;

                            &:nth-child(03),
                            &:nth-child(04),
                            &:nth-child(05),
                            &:nth-child(06),
                            &:nth-child(07),
                            &:nth-child(08),
                            &:nth-child(09),
                            &:nth-child(10),
                            &:nth-child(11),
                            &:nth-child(12),
                            &:nth-child(13),
                            &:nth-child(14),
                            &:nth-child(15) {
                                min-width: 70px;
                            }

                            .dkg-exchangeIcon {
                                svg {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }

                // &.dkg-cashfloe-cashin-table78 {
                //     border: none;

                //     tr {
                //         &:first-child {
                //             th {

                //                 &:nth-child(01),
                //                 &:nth-child(02) {
                //                     border: none;
                //                 }
                //             }
                //         }
                //     }
                // }

                tbody {
                    &.dkg-table-workflow-tbody1 {
                        tr {
                            td {
                                .negativeValues {
                                    color: #d91010;
                                }

                                padding: 8px 12px;
                                white-space: nowrap;
                                text-align: center;
                                font-size: 13px;
                                font-weight: bold;
                                background-color: #F3F6F8;
                                color: #333;
                                white-space: nowrap;

                                .add-new-payment-section {
                                    color: green;
                                    font-size: 12px;

                                    &:hover {
                                        opacity: 0.85;
                                    }

                                }
                            }
                        }

                        &.dkg-cashflow-cashin-tbody-78 {
                            tr {
                                td {
                                    .negativeValues {
                                        color: #d91010;
                                    }

                                    background-color: #F3F6F8 !important;
                                    border: 1px solid #ddd;
                                }
                            }
                        }
                    }

                    &.dkg-table-workflow-tbody2,
                    &.dkg-table-workflow-tbody3 {
                        tr {
                            td {
                                .negativeValues {
                                    color: #d91010;
                                }

                                padding: 0;
                                white-space: nowrap;
                                text-align: center;
                                background-color: #fff;
                                border: 1px solid #ddd;
                                font-size: 13px;
                                vertical-align: middle;

                                .dkg-subheadingg {
                                    .react-contextmenu.todorightDropmenu {
                                        padding: 8px 10px;
                                        border-radius: 0;
                                        background-color: #F3F6F8;
                                        position: relative;
                                        // top: 185px !important;
                                        // left: 148px !important;
                                        width: 95px;
                                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

                                        // &:after {
                                        //     border-color: transparent #234158 transparent transparent;
                                        //     _border-color: #000 #234158 #000 #000;
                                        //     border-style: solid;
                                        //     border-width: 8.5px 12px 8.5px 0;
                                        //     content: "";
                                        //     _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        //     height: 0;
                                        //     left: -12px;
                                        //     line-height: 0;
                                        //     position: absolute;
                                        //     top: 13px;
                                        //     width: 0;
                                        // }

                                        .react-contextmenu-item {
                                            font-size: 14px;
                                            font-weight: 600;
                                        }
                                    }
                                }

                                .dkg-cashflow-arrpw-altIcon {
                                    svg {
                                        color: #565d63;
                                    }
                                }

                                .dkg-cashflow-srNo {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .dk-checkinputIcon {
                                        position: relative;
                                        top: 3px;
                                        margin-left: 5px;

                                        input {
                                            width: 14px;
                                            height: 14px;
                                        }
                                    }
                                }

                                .form-control {
                                    padding: 0;
                                    height: 24px;
                                    background-color: #fff;
                                }

                                &:nth-child(01) {
                                    padding: 5px 8px;
                                }

                                &:nth-child(01) {
                                    text-align: left;
                                    min-width: 160px;

                                    input {
                                        text-align: left;
                                        background-color: #fff;
                                    }
                                }

                                &:nth-child(03),
                                &:nth-child(04),
                                &:nth-child(05),
                                &:nth-child(06),
                                &:nth-child(07),
                                &:nth-child(08),
                                &:nth-child(09),
                                &:nth-child(10),
                                &:nth-child(11),
                                &:nth-child(12),
                                &:nth-child(13),
                                &:nth-child(14),
                                &:nth-child(15) {
                                    min-width: 70px;
                                }

                            }

                            th {
                                padding: 8px 12px;
                                white-space: nowrap;
                                text-align: center;
                                background-color: #F3F6F8;
                                border: 1px solid #ddd;
                                font-size: 12px;
                                vertical-align: middle;
                                font-weight: bold;
                                color: #3a9469;

                                &:first-child {
                                    color: #212529;
                                    text-align: left;
                                }
                            }

                            // &.total-expenditure-row {
                            //     th {
                            //         border-bottom: 1px solid #333;
                            //     }
                            // }
                        }

                        &.dkg-total-expense-tbody-78 {
                            tr.total-expenditure-row {
                                th {
                                    background-color: #fff;

                                    .negativeValues {
                                        color: #d91010;
                                    }

                                    .positiveValues {
                                        color: #3a9469;
                                    }

                                    span.dkg-negative-eurosign {
                                        color: #d91010;
                                    }

                                    span.dkg-positive-eurosign {
                                        color: #3a9469;
                                    }

                                    // &:nth-child(01),
                                    // &:nth-child(02) {
                                    //     border-bottom: 1px solid #000;
                                    // }
                                }

                                &.dkg-total-expense-row-78.dkg-totalexpense-row-89 {
                                    th.dkg-totalexpense-col-89 {
                                        background-color: #fff !important;
                                        border: 1px solid #ddd;
                                    }
                                }
                            }

                            tr.dkg-total-expense-last-row-78 {
                                th {
                                    border-bottom: 1px solid #ddd;

                                    &:nth-child(01),
                                    &:nth-child(02) {
                                        border-bottom: 1px solid #ddd;
                                    }

                                    &:nth-child(14) {
                                        display: none;

                                        span {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            tr {
                                td {
                                    .negativeValues {
                                        color: #d91010;
                                    }

                                    .positiveValues {
                                        color: #3a9469;
                                    }

                                    background-color: #F3F6F8;

                                    &:last-child {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &.dkg-table-workflow-tbody2 {
                        tr {
                            td {
                                .negativeValues {
                                    color: #d91010;
                                }

                                font-weight: normal !important;
                                background-color: #f3f6f8 !important;
                                border: 1px solid #ddd;
                                color: #3a9469;

                                &:first-child {
                                    color: #212529;
                                }

                                &:last-child {
                                    font-weight: bold !important;
                                }
                            }
                        }
                    }

                    &.dkg-expenditure-workflow-tbody4 {
                        tr {
                            td {
                                .negativeValues {
                                    color: #d91010;
                                }

                                padding: 8px 12px;
                                white-space: nowrap;
                                text-align: center;
                                background-color: #fff;
                                font-size: 13px;
                                font-weight: bold;

                                .add-new-payment-section {
                                    color: green;
                                    font-size: 12px;

                                    &:hover {
                                        opacity: 0.85;
                                    }

                                }
                            }
                        }
                    }

                    &.dkg-expenditure-workflow-tbody4 {
                        tr {
                            td {
                                .negativeValues {
                                    color: #d91010;
                                }

                                padding: 8px 12px;
                                white-space: nowrap;
                                text-align: center;
                                background-color: #F3F6F8;
                                font-size: 13px;
                                font-weight: bold;

                                .add-new-payment-section {
                                    color: green;
                                    font-size: 12px;

                                    &:hover {
                                        opacity: 0.85;
                                    }

                                }
                            }
                        }
                    }

                    &.dkg-expenditure-cashout-addrow-tbody {
                        tr {

                            &.dkg-expandature-tbody-row-ui-header-678 {
                                td {
                                    .negativeValues {
                                        color: #d91010;
                                    }

                                    padding: 7px 8px;
                                    white-space: nowrap;
                                    text-align: center;
                                    background-color: #fff;
                                    font-size: 13px;
                                    font-weight: bold;
                                    vertical-align: middle;
                                    color: #495057;
                                    text-transform: capitalize !important;
                                    border: 1px solid #ddd;

                                    .dkg-subheadingg {
                                        .react-contextmenu.todorightDropmenu {
                                            padding: 8px 10px;
                                            border-radius: 0;
                                            background-color: #F3F6F8;
                                            width: 95px;
                                            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

                                            .react-contextmenu-item {
                                                font-size: 14px;
                                                font-weight: 600;
                                            }
                                        }
                                    }


                                    input {
                                        text-align: left;
                                        font-weight: bold;
                                        font-size: 14px;
                                        background-color: #fff;
                                        color: #495057;

                                        // input[type="checkbox"] {
                                        //     background-color: #fff;
                                        // }

                                    }

                                    input[type="checkbox"] {
                                        background-color: #f2f2f2;
                                        width: 13px;
                                        height: 13px;
                                    }

                                    .add-new-payment-section {
                                        color: green;
                                        font-size: 12px;

                                        &:hover {
                                            opacity: 0.85;
                                        }

                                    }
                                }
                            }

                            &.dkg-expandature-tbody-row-ui-678 {
                                td {
                                    .negativeValues {
                                        color: #d91010;
                                    }

                                    padding: 0;
                                    white-space: nowrap;
                                    text-align: center;
                                    background-color: #fff;
                                    border: 1px solid #ddd;
                                    font-size: 12px;
                                    vertical-align: middle;
                                    color: #495057;

                                    .dkg-subheadingg {
                                        .react-contextmenu.todorightDropmenu {
                                            padding: 8px 10px;
                                            border-radius: 0;
                                            background-color: #F3F6F8;
                                            width: 95px;
                                            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

                                            .react-contextmenu-item {
                                                font-size: 14px;
                                                font-weight: 600;
                                            }
                                        }
                                    }

                                    .dkg-cashflow-arrpw-altIcon {
                                        svg {
                                            color: #565d63;
                                        }
                                    }

                                    .dkg-cashflow-srNo {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        .dk-checkinputIcon {
                                            position: relative;
                                            top: 3px;
                                            margin-left: 5px;

                                            input {
                                                width: 14px;
                                                height: 14px;
                                            }
                                        }
                                    }

                                    .form-control {
                                        padding: 0;
                                        height: 24px;
                                        background-color: #fff;
                                    }

                                    &:nth-child(01) {
                                        padding: 5px 8px;
                                    }

                                    &:nth-child(01) {
                                        text-align: left;
                                        min-width: 160px;

                                        input {
                                            text-align: left;
                                            background-color: #fff;
                                        }
                                    }

                                    &:nth-child(03),
                                    &:nth-child(04),
                                    &:nth-child(05),
                                    &:nth-child(06),
                                    &:nth-child(07),
                                    &:nth-child(08),
                                    &:nth-child(09),
                                    &:nth-child(10),
                                    &:nth-child(11),
                                    &:nth-child(12),
                                    &:nth-child(13),
                                    &:nth-child(14),
                                    &:nth-child(15) {
                                        min-width: 70px;
                                    }

                                }
                            }
                        }
                    }
                }
            }

            &.table {
                tbody+tbody {
                    border-top: none !important;
                }

            }
        }
    }
}

.table tbody+tbody {
    border: none !important;
}