.dkg-mainprinciple-mainCon {
    margin-top: 165px;

    .dkg-mainprinciple-Row {
        .dkg-mainprinciple-Col {

            .dkg-principale-header {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                // margin: 20px 0;
                margin-bottom: 14px;

                .dkg-principale-title {
                    font-size: 24px;
                    color: #333;
                    font-weight: 700;
                }
            }

            .dkg-principale-tableCon {
                height: calc(100vh - 255px);
                overflow-y: auto;

                .dkg-principale-table {
                    tbody {
                        tr {
                            td {
                                font-size: 16px;
                                text-transform: uppercase;
                                font-weight: normal;
                                text-align: center;
                                padding: 8px 10px;
                                height: 85px;
                                line-height: 75px;
                                background-color: #f2f2f2;
                                border: 1px solid #ddd;

                                .dkg-principleImgCon {
                                    .dkg-principleImg {
                                        width: 55px;
                                        height: 55px;
                                        position: relative;
                                        top: 0;
                                    }
                                }

                                &:nth-child(01),
                                &:nth-child(02) {
                                    width: 12%;
                                }

                                &:nth-child(03) {
                                    text-align: left;
                                }

                                &:nth-child(04) {
                                    width: 45%;

                                }

                                .dkg-pricipale-desc {
                                    display: flex;
                                    justify-content: flex-start;
                                    flex-wrap: wrap;
                                    font-size: 14px;
                                    margin-bottom: 0;
                                    font-weight: normal;
                                    text-transform: none;
                                    text-align: justify;

                                }
                            }
                        }
                    }
                }

            }

            .dkg-principale-tableCon::-webkit-scrollbar {
                display: none;
            }
        }
    }
}