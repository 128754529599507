.dk-pipe-profileind-rtDropdown {
    .dk-pipe-profileind-Dropdown-toggle {
        box-shadow: none;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #ddd;
        margin: 0;
        padding: 0;
        position: relative;
        color: #6c757d;

        &:hover {
            outline: 0;
            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        }

        &::after {
            display: none;
        }

        i {
            font-size: 16px;
        }

        .crossUserFilter.dk-lrftCross {
            position: absolute;
            right: -7px;
            top: -8px;
        }
    }

    .dk-pipe-profileind-rtDropdownMenu {
        position: absolute !important;
        background-color: #234158;
        transform: inherit !important;
        right: 50px !important;
        top: -18px !important;
        left: inherit !important;
        margin: 0 !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        min-width: inherit;
        border: 1px solid #808080;

        &::before {
            border-color: transparent #234158 transparent transparent;
            _border-color: #000 #234158 #000 #000;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            content: "";
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            height: 0;
            left: inherit;
            right: -9px;
            line-height: 0;
            position: absolute;
            top: 26px;
            width: 0;
            transform: rotate(60deg);
        }

        .d-flex {
            .dk-profileind-rtDropdownItem {
                display: inline-block;
                padding: 0.25rem 0.5rem !important;
                border-right: 1px solid #808080;

                &:last-child {
                    border-right: none;
                }

                i {
                    width: 100%;
                    text-align: center;
                    font-size: 13px;
                }

                .proflieCount {
                    color: #fff;
                    display: block;
                    font-size: 12px;
                    text-align: center;
                    width: 100%;
                }

                &:hover {
                    .proflieCount {
                        color: #333;
                    }
                }
            }
        }
    }
}