.page-margin-top {
    margin-top: 150px;
}

.pageSummaryDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.dk-candinatePipeMain {
    .dk-pipelinePageHead {
        .dk-pipelineLeft {
            display: flex;
            align-items: center;

            .dk-mailshotsSearch {
                .input-group {
                    width: 100%;
                    max-width: 140px;
                    min-width: 130px;

                    input {
                        padding: 0.5rem 0.55rem;
                        background-color: #fff !important;
                        margin: 0;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        line-height: 1.3rem;
                        height: 35px;
                        border-radius: 0;
                        text-align: left;

                        &:focus::placeholder {
                            color: transparent;
                        }
                    }

                    .input-group-append {
                        margin-left: -1px;

                        button {
                            padding: 0.5rem 0.55rem;
                            background-color: #fff !important;
                            margin: 0;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            line-height: 1.3rem;
                            height: 35px;
                            border-radius: 0;

                            &:hover {
                                background-color: #234158 !important;

                                i {
                                    color: #fff;
                                }
                            }

                            i {
                                color: #6c757d;
                            }
                        }
                    }
                }
            }

            button.dk-cricleBtn,
            .dk-checkduppopup,
            .dk-newCVRightFilter {
                button.dk-reoderBtn {
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: #fff !important;
                    color: #333;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    outline: none;
                    box-shadow: none;
                    cursor: pointer;

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                    }
                }

                i.fa.fa-filter {
                    font-size: 12px;
                    margin-top: 3px;
                }

                i {
                    margin-top: 3px;
                }
            }

            button.dk-cricleBtn,
            .dk-checkduppopup {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #eaeaea !important;
                color: #333;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;

                button.dk-reoderBtn {
                    background-color: transparent;
                    border: none;

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                    }
                }

                i.fa.fa-filter {
                    font-size: 12px;
                    margin-top: 3px;
                }

                i {
                    margin-top: 3px;
                }
            }
        }

        .dk-filterTagsCon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .dk-pipeline-pagetitle {
                margin-top: 7px;
                font-size: 16px;
                font-weight: 600;
            }

            .dk-filterbtnGrp {
                margin-top: 2px;

                .dk-filterbtntext {
                    background: #366995;
                    font-size: 12px !important;
                    color: #fff;
                    padding: 5px 7px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                    line-height: 10px;
                    text-transform: inherit;

                    &.btn-primary {
                        background: #366995 !important;
                    }
                }

                .dk-filterbtnicon {
                    background: #234158;
                    font-size: 11px;
                    padding: 5px 7px;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;

                    &.btn-primary {
                        background-color: #234158 !important;
                    }
                }
            }
        }

        .dk-pipelineCetre {
            .dk-pipelineTabsCon {
                width: 100%;

                ul {

                    list-style: none;
                    display: flex;
                    width: 100%;
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-bottom: 0;
                    margin-top: 4px;

                    li {
                        min-width: 15.66%;
                        // max-width: 15.80%;
                        margin: 0 5px auto;
                        background: #eaeaea;
                        border: 1px solid #ddd;
                        text-align: center;
                        border-radius: 35px;

                        a {
                            font-size: 13px;
                            padding: 10px;
                            white-space: nowrap;
                            line-height: 30px;
                            color: #4d4a4a;
                            font-size: 0.82rem;

                            span {
                                padding-left: 5px;
                            }
                        }
                    }

                    li.newCVtiveTab.active,
                    li.newCVtiveTab:hover {
                        background-color: #5c737d;
                        border: 1px solid #5c737d;

                        a {
                            color: #fff;
                        }
                    }

                    li.toprocessTab.active,
                    li.toprocessTab:hover {
                        background-color: #56496C;
                        border: 1px solid #56496C;

                        a {
                            color: #fff;
                        }
                    }

                    li.recruiterTab.active,
                    li.recruiterTab:hover {
                        background-color: #2d9b99;
                        border: 1px solid #2d9b99;

                        a {
                            color: #fff;
                        }
                    }

                    li.account-managerTab.active,
                    li.account-managerTab:hover {
                        background-color: #306967;
                        border: 1px solid #306967;

                        a {
                            color: #fff;
                        }
                    }

                    li.offerTab.active,
                    li.offerTab:hover {
                        background-color: #629bd3;
                        border: 1px solid #629bd3;

                        a {
                            color: #fff;
                        }
                    }

                    li.escalatedTab.active,
                    li.escalatedTab:hover {
                        background-color: #670004;
                        border: 1px solid #670004;

                        a {
                            color: #fff;
                        }
                    }
                }
            }

        }

        .dk-pipelineright {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .dk-btnCicleCon {
                display: flex;

                button.dk-cricleBtn,
                button.dk-reoderBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: #fff !important;
                    color: #6c757d;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    outline: none;
                    box-shadow: none;

                    &:hover {
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                    }

                    i.fa.fa-filter {
                        font-size: 12px;
                        margin-top: 3px;
                    }

                    &.dk-pipeline-manualparse {
                        cursor: pointer;
                        position: relative;

                        input[type=file] {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            z-index: 9;
                            cursor: pointer;
                            width: 35px;
                            height: 35px;
                        }
                    }
                }

                .dk-pipe-prfind-Con {
                    position: relative;
                }

                .dkg-candfilter-dropdownCon {
                    .dkg-candfilter-dropdown14 {
                        .dkg-candfilter-dropdown-toggle14 {
                            margin: 0;
                            padding: 0;
                            box-shadow: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 35px;
                            height: 35px;
                            line-height: 35px;
                            background: #fff !important;
                            color: #6c757d;
                            border: 1px solid #ddd;
                            border-radius: 50%;

                            &:hover {
                                outline: 0;
                                box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                            }

                            svg {
                                width: 16px;
                                height: 16px;
                            }

                            &::after {
                                display: none;
                            }

                        }

                        .dkg-candfilter-dropdown-menu14 {
                            position: absolute !important;
                            background-color: #234158 !important;
                            transform: inherit !important;
                            right: 50px !important;
                            top: -30px !important;
                            left: inherit !important;
                            margin: 0 !important;
                            padding: 0 !important;
                            display: flex;
                            align-items: center;

                            &:after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-color: transparent #234158 transparent transparent;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                line-height: 0;
                                _border-color: #000 #234158 #000 #000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                position: absolute;
                                right: -10px;
                                top: 29px;
                                transform: rotate(59deg);
                            }

                            .dkg-candfilter-dropdown-item14 {
                                border: none;
                                font-size: 16px;
                                padding: 4px 9px;
                                text-align: center;
                                width: 100%;
                                border-right: 1px solid #808080;
                                background-color: #234158 !important;
                                color: #fff;

                                span.proflieCount,
                                .recNoCount {
                                    display: block;
                                    font-size: 12px;
                                }

                                &:hover {
                                    background-color: #fff !important;
                                    color: #333 !important;

                                    span.proflieCount,
                                    .recNoCount {
                                        color: #333;

                                    }
                                }

                                &:last-child {
                                    border-right: none;
                                }
                            }
                        }
                    }
                }

                .dk-flagfilterIcon {
                    .dk-lhs-FlagFilter {
                        .dropdown {
                            button {
                                background-color: #fff;
                                margin: 0;
                                padding: 0;
                                width: 35px;
                                height: 35px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: none;
                                border-radius: 50%;
                                color: #6c757d;

                                &:hover {
                                    outline: 0;
                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                }

                                &::after {
                                    display: none;
                                }

                                i {
                                    font-size: 14px;
                                }
                            }

                            .dk-lhsflagnoDropMenu.dropdown-menu {
                                position: absolute !important;
                                background-color: #234158;
                                transform: inherit !important;
                                right: 45px !important;
                                top: -5px !important;
                                left: inherit !important;
                                margin: 0 !important;
                                padding: 0 !important;

                                &:after {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-color: transparent #234158 transparent transparent;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    line-height: 0;
                                    _border-color: #000 #ccc #000 #000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    position: absolute;
                                    right: -10px;
                                    top: 11px;
                                    transform: rotate(59deg);
                                }

                                .d-flex {
                                    padding: 0;

                                    .dropdown-item {
                                        padding: 7px 10px;
                                        // margin: 0 3px;

                                        i {
                                            display: block;
                                        }

                                        .recNoCount {
                                            color: #fff;
                                        }

                                        &:hover {
                                            .recNoCount {
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .pipeline-cafileterCon {
                    position: relative;

                    .dropdownFilter {
                        .dropdown-toggle {
                            margin: 0;
                            padding: 0.5rem 0.45rem;
                            box-shadow: none;
                            background-color: #eaeaea;
                            text-transform: capitalize;
                            min-width: 120px;
                            font-size: 13px;
                            color: #514f4f;

                            &::after {
                                position: absolute;
                                right: 5px;
                                top: 15px;
                                color: #514f4f;
                            }
                        }

                        .dropdown-menu {
                            padding: 0;
                            border-radius: 0;
                            max-height: 480px;
                            overflow-y: auto;
                            position: absolute !important;
                            right: 5px !important;

                            .dropdown-item {
                                padding: 8px 10px;
                                font-size: 13px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .dk-casecounter {
                                    display: inline-block;
                                    background: #3a506b;
                                    color: #fff;
                                    padding: 2px 5px;
                                    min-width: 30px;
                                    text-align: center;
                                    font-size: 11px;
                                }

                                &:hover {
                                    background-color: #eaeaea !important;
                                    color: #333 !important;

                                }
                            }
                        }

                        // .crossUserFilter {
                        //     position: absolute;
                        //     color: #000;
                        //     top: -5px;
                        //     right: -1px;
                        //     font-size: 15px !important;
                        //     cursor: pointer;
                        // }
                    }
                }

                .dk-trickfilterIcon {
                    color: #333;
                    position: relative;

                    .dkg-top-right-trickFilter {
                        .dk-idDropdownMain {
                            .dropdown-toggle {
                                margin: 0;
                                padding: 0;
                                box-shadow: none;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 35px;
                                height: 35px;
                                line-height: 35px;
                                background: #fff !important;
                                color: #6c757d;
                                border: 1px solid #ddd;
                                border-radius: 50%;

                                &:hover {
                                    outline: 0;
                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                }

                                svg {
                                    width: 16px;
                                    height: 16px;
                                }

                                &::after {
                                    display: none;
                                }
                            }

                            .dk-lhstricknoDropMenu.dropdown-menu {
                                position: absolute !important;
                                background-color: #234158 !important;
                                transform: inherit !important;
                                right: 50px !important;
                                top: -10px !important;
                                left: inherit !important;
                                margin: 0 !important;
                                padding: 0 !important;
                                display: flex;
                                align-items: center;

                                .dropdown-item {
                                    padding: 0.25rem 0.5rem;
                                    text-align: center;
                                    width: 100%;
                                    color: #fff;

                                    &:hover {
                                        color: #333;
                                    }

                                    .dk-idDropdown-cols {
                                        span {
                                            svg {
                                                width: 16px;
                                                height: 16px;
                                            }
                                        }

                                        span.dkg-tricker {
                                            display: block;
                                        }
                                    }

                                    &:after {
                                        content: "";
                                        width: 0;
                                        height: 0;
                                        border-color: transparent #234158 transparent transparent;
                                        border-style: solid;
                                        border-width: 8.5px 12px 8.5px 0;
                                        line-height: 0;
                                        _border-color: #000 #234158 #000 #000;
                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        position: absolute;
                                        right: -10px;
                                        top: 14px;
                                        transform: rotate(59deg);
                                    }
                                }
                            }
                        }
                    }

                    .crossUserFilter {
                        position: absolute;
                        color: #000;
                        top: -5px;
                        right: -8px;
                        font-size: 15px !important;
                        cursor: pointer;
                    }
                }

                .dk-flagfilterIcon {
                    .dk-useFilterNumCon {
                        .dk-pipe-prfind-Con {
                            .dk-pipe-profileind-rtDropdown {

                                .dk-pipe-profileind-rtDropdownMenu {
                                    top: -10px !important;

                                    &::before {
                                        top: -9px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dk-qulaifyRightFilter {

                .ui.selection.dropdown.dk-overdues-task-slect,
                .ui.selection.dropdown.dk-overdues-task-slect:hover {
                    border-radius: 0;
                    min-width: 135px;
                    background-color: #eaeaea !important;
                    border: 1px solid #ddd;

                    .dropdown.icon {
                        top: 0.8581em;
                        color: #605a5a;
                    }
                }

                .ui.selection.dropdown .menu {
                    border-radius: 0;
                    min-width: 200px;
                    min-height: 16rem;
                    position: absolute;
                    right: 0;
                    left: inherit;
                    overflow: inherit;

                    .item {
                        font-size: 0.9125rem;
                        min-height: 33px;
                        line-height: 19px;

                        span.description {
                            border-radius: 0;
                            font-size: 0.8125rem;
                            background: #3a506b;
                        }
                    }

                    .active.item {
                        font-weight: normal;
                    }

                    .item:hover {
                        background-color: #eaeaea !important;
                        color: #333 !important;
                    }
                }

                .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
                .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
                    font-size: 14px;
                    color: #605a5a;
                }
            }
        }
    }

    .pipe-newCVTableCon {
        margin-top: 6px;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh - 237px);
        padding-bottom: 0;
        position: relative;

        .dkpipeline-newCVTable {
            margin-bottom: 0;

            thead tr th {
                background: #485569;
                color: #fff;
                font-size: 13px;
                text-align: center;
                white-space: nowrap;
                padding: 9px 7px;
                border: 1px solid gray;
                border-bottom: none;
                vertical-align: middle;
                position: sticky;
                top: 0;
                border-top: none;
                z-index: 9;

                &:nth-child(07) {
                    max-width: 210px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:nth-child(16),
                &:nth-child(17),
                &:nth-child(18) {
                    min-width: 90px;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                &.dk-cursor-pointer {
                    cursor: pointer;
                }
            }

            tbody tr td {
                background: #fff;
                color: #4d4a4a;
                font-size: 13px;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
                padding: 0 7px;
                border-left: 1px solid #ddd;
                vertical-align: middle;
                text-transform: capitalize;
                position: relative;

                input {
                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }

                .dk-useFilterCon {
                    cursor: pointer;

                    .dropdown {
                        .dk-pipleuserFilterMenu.dropdown-menu {
                            border: 1px solid #234158;
                            background: #234158;

                            &::after {
                                border-color: transparent #234158 transparent transparent;
                            }

                            .d-flex {
                                .dropdown-item {
                                    .tooltipMain {
                                        .filterImg {
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .pipleFilterCols {
                    display: flex;

                    .dk-chevron-rightIcon {
                        margin-right: 8px;

                        i {
                            background: #297483;
                            padding: 5px;
                            text-align: center;
                            border-radius: 2px;
                            color: #fff;
                            margin-right: 8px;
                            cursor: pointer;
                        }
                    }

                    .dk-checkinputIcon {
                        margin-left: 0.5rem;
                        margin-right: 0.35rem;
                        position: relative;

                        input[type="checkbox"] {
                            -webkit-appearance: none;
                            appearance: none;
                            background: #eaeaea !important;
                            border: 1px solid #ddd;
                            border-radius: 1px;
                            box-sizing: border-box;
                            cursor: pointer;
                            height: 14px;
                            position: absolute;
                            right: -4px;
                            top: 6px;
                            transition: all .3s linear;
                            width: 14px;
                            z-index: 0;
                        }

                        input[type="checkbox"]:checked {
                            background-color: #34976e !important;
                        }
                    }

                    .opencaledericon {
                        cursor: pointer;
                        margin-left: 0.5rem;
                        margin-right: 0;
                        margin-top: 3px;
                        display: none;

                        i {
                            color: #a9a8a8;
                        }
                    }

                    .openFlagOptions {
                        .dk-pipeFlagFilterCon.dk-circleDropdown {
                            .dropdown {
                                .dropdown-toggle {
                                    display: inline-block;
                                    width: 16px;
                                    height: 16px;
                                    background: #d3d3d3;
                                    border-radius: 50%;
                                    position: relative;
                                    bottom: 2px;
                                }

                                .dropdown-menu {
                                    padding: 6px 3px;
                                    min-width: fit-content;
                                    width: 100%;
                                    transform: inherit !important;
                                    top: -7px !important;
                                    left: 30px !important;
                                    right: inherit !important;

                                    a {
                                        display: inline-block;
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 50%;
                                        margin: 0 3px;
                                    }

                                    a.gray {
                                        background: #d3d3d3;
                                    }

                                    a.yellow {
                                        background: #d9d933;
                                    }

                                    a.red {
                                        background: #ff0000;
                                    }

                                    a.green {
                                        background: #008000;
                                    }
                                }
                            }
                        }
                    }

                    .openFlagOptions,
                    .openCricleOptions {
                        cursor: pointer;
                        margin-left: 0.65rem;
                        margin-top: 4px;
                        margin-right: 4px;

                        i {
                            color: #297483;
                            font-size: 15px !important;
                        }

                        .dropdown {
                            .btn {
                                margin: 0;
                                padding: 0;
                                box-shadow: none;

                                &::after {
                                    display: none;
                                }
                            }

                            .dropdown-menu.show {
                                padding: 0;
                                left: 26px !important;
                                top: -40px !important;

                                &::after {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-color: transparent #234158 transparent transparent;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    line-height: 0;
                                    _border-color: #000 #ccc #000 #000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    position: absolute;
                                    left: -12px;
                                    top: 15px !important;
                                }

                                &.dk-pipleuserFilterMenu.dkg-db-profile-comDrop12 {
                                    padding: 0;
                                    left: 26px !important;
                                    top: -50px !important;

                                    &::after {
                                        content: "";
                                        width: 0;
                                        height: 0;
                                        border-color: transparent #234158 transparent transparent;
                                        border-style: solid;
                                        border-width: 8.5px 12px 8.5px 0;
                                        line-height: 0;
                                        _border-color: #000 #ccc #000 #000;
                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        position: absolute;
                                        left: -12px;
                                        top: 25px !important;
                                    }
                                }
                            }
                        }
                    }

                    .dkg-dbcandfilter-Con {

                        .dkg-candfilter-dropdown15,
                        .dkg-mypipeflag-dropdown15 {

                            .dk-pipe-profileind-dropdown-toggle-15,
                            .dkg-candfilter-dropdown-toggle15,
                            .dkg-mypipeflag-dropdown-toggle15 {
                                margin: 0 !important;
                                padding: 0;
                                background: transparent !important;
                                border: none;
                                box-shadow: none;
                                text-transform: inherit;
                                font-size: 13px;
                                width: 100%;
                                min-width: 15px;
                                max-width: 15px;

                                &::after {
                                    display: none;
                                }

                            }

                            .dkg-candfilter-dropdown-menu15,
                            .dk-pipe-profileind-dropdown-menu15,
                            .dkg-mypipeflag-dropdown-menu15 {
                                padding: 0;
                                margin: 0 !important;
                                transform: inherit !important;
                                display: flex;
                                min-width: 100px;
                                left: 26px !important;
                                top: -15px !important;
                                border: 1px solid #808080;
                                background-color: #234158 !important;

                                &::before {
                                    border-color: transparent #234158 transparent transparent;
                                    _border-color: #000 #234158 #000 #000;
                                    border-style: solid;
                                    border-width: 6.5px 12px 6.5px 0;
                                    content: "";
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    height: 0;
                                    left: -12px;
                                    line-height: 0px;
                                    position: absolute;
                                    top: 18px;
                                    width: 0;

                                }

                                .dkg-candfilter-dropdown-item15,
                                .dk-profileind-rtDropdownItem-15,
                                .dkg-mypipeflag-dropdown-item15 {
                                    border: none;
                                    font-size: 16px;
                                    padding: 4px 9px;
                                    text-align: center;
                                    width: 100%;
                                    border-right: 1px solid #808080;
                                    background-color: #234158 !important;
                                    color: #fff;

                                    span.proflieCount,
                                    .recNoCount {
                                        display: block;
                                        font-size: 12px;
                                    }

                                    &:hover {
                                        background-color: #fff !important;
                                        color: #333 !important;

                                        span.proflieCount,
                                        .recNoCount {
                                            color: #333;
                                        }
                                    }

                                    &:last-child {
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .dk-tableImgCricle {
                    img {
                        min-height: 22px;
                        border-radius: 50%;
                        height: 30px;
                        width: 30px;
                    }
                }

                .commentBoxCon {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .numCon {
                        width: 25px;
                        height: 25px;
                        background-color: #eaeaea;
                        border-radius: 50%;
                        line-height: 25px;
                        cursor: pointer;
                    }
                }

                input[type="text"] {
                    border: none;
                    background-color: #fff;
                    color: #000;
                    font-size: 12px;
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 0;

                    &:focus {
                        border: 1px solid #333;
                    }
                }

                .caseDiv {
                    height: 40px;
                    line-height: 40px;
                    cursor: pointer;
                }

                &.bt-0 {
                    border-top: none;
                    color: #fff;
                    border-bottom: 1px solid #ddd;
                }

                &:nth-child(09),
                &:nth-child(10),
                &:nth-child(11) {
                    padding: 0 !important;
                    border: 1px solid #808080;
                }
            }

            tbody tr td:nth-child(08) {
                a {
                    text-align: left;
                    color: #4d4a4a;

                    &:hover {
                        color: #2c71b2;
                        cursor: pointer;
                    }
                }
            }

            // tbody tr td:nth-child(10), tbody tr td:nth-child(11), tbody tr td:nth-child(12){
            //     background-color:#5c737d;
            //     color: #fff;
            //     cursor: pointer;
            //     padding: 0;
            // }
            tbody tr:last-child td {
                border-bottom: 1px solid #808080;
            }

            tfoot {
                tr td {
                    background: #fff;
                    color: #4d4a4a;
                    font-size: 14px;
                    text-align: center;
                    white-space: nowrap;
                    padding: 9px 15px !important;
                    vertical-align: middle;
                    border: none;
                    z-index: 1;

                    &:nth-child(14) {
                        border-left: 1px solid #ddd;
                    }

                    &:nth-child(14),
                    &:nth-child(15),
                    &:nth-child(16),
                    &:nth-child(17) {
                        border-right: 1px solid #ddd;
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-track {
            background: #F1F1F1;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 40%;
        max-width: 40%;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 40%;
        max-width: 40%;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 40%;
        max-width: 40%;
    }
}

.dk-pipepaginationCon {
    flex: 0 0 100%;
    max-width: 100%;
    background-color: #40546a;
    position: fixed;
    width: 100%;
    bottom: 0;

    ul {
        list-style: none;
        padding: 6px 15px;
        margin-bottom: 0;

        li {
            color: #fff;
            z-index: 999;
            font-size: 13px;
        }
    }
}

.tableFilterRoundButton {
    box-shadow: none;
    padding: 0 !important;
    margin: 0;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    line-height: 30px;
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: .81rem;

    i {
        font-size: 1rem;
    }

    img {
        width: 100%;
        border-radius: 50%;
    }
}

.dk-pipeFlagFilterCon {
    .dropdown {
        button.dk-pipeflagfliterbtn.dropdown-toggle.btn {
            box-shadow: none;
            background-color: transparent;
            padding: 0 !important;
            margin: 0;

            i {
                font-size: 14px;
            }
        }

        button.dk-pipeflagfliterbtn.dropdown-toggle::after {
            display: none;
        }

        .dk-pipleFlagFilterMenu.dropdown-menu {
            padding: 5px;
            left: 7px !important;
            top: -18px !important;
            transform: translate(22px, 7px) !important;
            -webkit-transform: translate(22px, 7px) !important;
            background-color: #fff;

            a.dropdown-item {
                padding: 0;
                display: inline-block;
                margin: 0 3px;
                text-align: center;
            }
        }

        .dk-pipleFlagFilterMenu.dropdown-menu:after {
            content: "";
            width: 0;
            height: 0;
            border-color: transparent #ccc transparent transparent;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            line-height: 0;
            _border-color: #000 #ccc #000 #000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            position: absolute;
            left: -12px;
            top: 8px;
        }
    }
}

.dk-pipe-profileind-Dropdown {
    .dk-pipe-profileind-Dropdown-toggle {
        box-shadow: none;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #ddd;
        margin: 0;
        padding: 0;

        &::after {
            display: none;
        }

        i {
            font-size: 13px;
        }
    }

    .dk-pipe-profileind-DropdownMenu {
        padding: 0;
        transition: inherit !important;
        position: absolute !important;
        left: 45px !important;
        top: -55px !important;
        background-color: #234158 !important;

        &:after {
            border-color: transparent #234158 transparent transparent;
            _border-color: #000 #234158 #000 #000;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            content: "";
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            height: 0;
            left: -12px;
            line-height: 0;
            position: absolute;
            top: 24px;
            width: 0;
        }

        .d-flex {
            .dk-profileind-DropdownItem {
                display: inline-block;
                margin: 0 3px;
                padding: 8px 5px !important;
                width: 35px;

                i {
                    width: 100%;
                    text-align: center;
                    font-size: 1.23rem;
                }

                .proflieCount {
                    color: #333;
                    display: block;
                    font-size: 12px;
                    padding: 4px 0 0;
                    text-align: center;
                    width: 100%;
                    font-size: 1rem;
                }
            }
        }
    }
}

.pagination {
    // background-color: #485569 !important;
    background-color: transparent !important;
    border-radius: 0 !important;

    .dk-resPagination {
        width: 100%;

        .dk-resLeftPag {
            .dk-texCounter {
                color: #fff !important;
            }
        }

        .pagination-btns {
            nav {
                ul {
                    margin-top: 0 !important;

                    li {
                        min-width: auto !important;
                        width: auto !important;
                        margin: 0 !important;
                        border: 0px !important;
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }
}

.dk-resPaginationCon {
    .dk-resPagination {
        &.dkg-db-datatablepagination {
            display: flex;
            justify-content: center !important;
        }

        .dk-resLeftPag {
            .dk-texCounter {
                color: #fff !important;
            }
        }
    }
}