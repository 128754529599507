.dk-cliListViewPageCont {
    position: relative;
    width: 100%;
    // max-width: 1600px;
    // margin: auto;
    margin-top: 160px;

    .dkg-clientslistHeader {

        .dkg-listviewleftCon {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .dk-clientslistSearch {
                max-width: 170px;

                .input-group {
                    input {
                        box-shadow: none;
                        outline: none;
                        border: 1px solid #d1cccc;
                        border-radius: 0;
                        font-size: 13px;
                        text-align: left;
                        background-color: #fff;
                        color: #495057;

                        &:focus::placeholder {
                            color: transparent;
                        }

                    }

                    .input-group-append {
                        button.btn.btn-secondary {
                            background-color: #eaeaea !important;
                            margin: 0;
                            padding: 0 10px;
                            color: #333;
                            box-shadow: none;
                            border: 1px solid #d1cccc;
                            // border-left: none;
                        }
                    }
                }
            }

            .dk-reloadBtn {
                display: inline-block;
                width: 100%;
                min-width: 32px;
                max-width: 32px;
                height: 32px;
                background: #eaeaea;
                border-radius: 3px;
                margin-right: 8px;
                border: 1px solid #ccc;
                outline: none;
            }
        }

        .dk-clients-right-statusFilter {
            color: #000;
            position: relative;
            display: flex;

            .dk-resCrcleIconCon {
                .dk-reloadBtn {
                    width: 100%;
                    min-width: 32px;
                    height: 32px;
                    background: #eaeaea;
                    border-radius: 3px;
                    margin-right: 8px;
                    border: 1px solid #ccc;
                    outline: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #333;
                }
            }

            .dk-reloadBtn {
                display: inline-block;
                width: 100%;
                min-width: 32px;
                height: 32px;
                background: #eaeaea;
                border-radius: 3px;
                margin-right: 8px;
                border: 1px solid #ccc;
                outline: none;
            }

            .crossUserFilter.dk-lrftCross {
                right: -6px;
            }

            .dk-cli-status-dropdown {
                color: #000;

                .dropdown-toggle {
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid #d1cccc;
                    padding: 6px 10px;
                    text-transform: capitalize;
                    font-size: 13px;
                    background-color: #eaeaea !important;
                    color: #333;
                    border-radius: 3px;
                    min-width: 120px;

                    &::after {
                        position: relative;
                        top: 1px;
                    }
                }

                .dropdown-menu {
                    border-radius: 0;
                    padding: 0;
                    z-index: 99999;

                    .dropdown-item {
                        padding: 8px 10px;
                        border-bottom: 1px solid transparent;
                        font-size: 13px;
                        position: relative;
                        display: flex;
                        justify-content: space-between;

                        .dkg-lv-statuscounter {
                            position: absolute;
                            right: 8px;
                            color: #fff;
                            background-color: #234158;
                            padding: 1px 5px;
                            font-size: 12px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .dk-clntsPageTitle div {
        margin-top: 7px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .dk-statusFilter {

        // position: absolute;
        // right: 11px;
        // top: 4px;
        .dropdown {
            .dropdown-toggle {
                margin: 0;
                box-shadow: none;
                outline: none;
                text-transform: inherit;
                font-size: 14px;
                border: 1px solid #ddd;
                border-radius: 3px;
                padding: 5px 15px;
            }

            .dropdown-menu {
                padding: 0;
                border-radius: 0;
                margin: 0 !important;
                transform: inherit !important;
                top: 35px !important;
                left: inherit !important;
                right: 0 !important;

                a {
                    display: block;
                    width: 100%;
                    padding: 6px 12px;
                    border-bottom: 1px solid #ddd;
                    font-size: 14px;
                    color: #333;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .listViewTableCon {
        padding-left: 0;
        padding-right: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: calc(100vh - 250px);
        overflow-y: auto;
        margin-top: 8px;

        .dk-clListViewTable {
            thead tr th {
                background-color: #485569;
                border: 1px solid gray;
                border-bottom: none;
                color: #fff;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 400;
                padding: 10px 7px;
                position: sticky;
                text-align: center;
                top: 0;
                vertical-align: middle;
                white-space: nowrap;
                z-index: 9999;

                &:nth-child(01) {
                    width: 100px;
                }

                &:nth-child(02) {
                    width: 130px;
                    text-align: center;
                }

                // &:last-child {
                //     width: 180px;
                // }

                input.form-control.searchTxtBox {
                    border: dotted 1px #ccc;
                    font-size: 13px;
                    padding: 0.375rem 0.5rem;
                    height: 30px;
                    border-radius: 0;
                }

                button.btn.dk-mbclientsrchInput {
                    border: 1px dotted #ccc;
                    border-left: 0;
                    margin-top: 4px;
                    border-radius: 0;
                    padding: 0.35rem 0.5rem;
                    height: 30px;
                    margin: 0;
                    box-shadow: none;
                }
            }

            tbody {
                // box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
                *box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                padding-left: 15px;
                padding-right: 15px;
                padding: 8px 18px !important;
                white-space: nowrap;
                background-color: #eaeaea !important;
                font-weight: 400;
                text-align: center;
                vertical-align: middle;
                font-size: 14px;
                border-bottom: 1px solid #e1dbdb !important;

                tr:first-child td {
                    border-top: transparent;
                }

                tr td {
                    padding: 8px 7px !important;
                    white-space: nowrap;
                    background-color: #eaeaea !important;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 14px;
                    border-bottom: 1px solid #ddd;

                    .d-flex {
                        .dk-smsTableCheckbox {
                            input {
                                margin-left: 0.5rem;
                                margin-right: inherit !important;
                            }

                            input[type="radio"] {
                                cursor: pointer;
                                appearance: none;
                                background: #fff;
                                box-sizing: border-box;
                                position: relative;
                                box-sizing: content-box;
                                width: 12px;
                                height: 12px;
                                border-width: 0;
                                transition: all 0.3s linear;
                                border: solid #ddd 1px;
                                border-radius: 50%;
                            }
                        }
                    }

                    &:nth-child(01) {
                        width: 100px;

                        .d-flex {
                            .dkg-id-number {
                                position: relative;
                                top: 2px;
                            }

                            input[type=radio] {
                                width: 15px;
                                height: 15px;
                                position: relative;
                                top: 4px;
                                cursor: pointer;
                            }

                            input[type='radio']:after {
                                width: 15px;
                                height: 15px;
                                border-radius: 15px;
                                top: 0;
                                left: 0;
                                position: relative;
                                background-color: #f2f2f2;
                                content: "";
                                display: inline-block;
                                visibility: visible;
                                border: 2px solid #ddd;
                            }

                            input[type='radio']:checked:after {
                                width: 15px;
                                height: 15px;
                                border-radius: 15px;
                                top: 0;
                                left: 0;
                                position: relative;
                                background-color: #34976e;
                                content: '';
                                display: inline-block;
                                visibility: visible;
                                border: 2px solid #ddd;
                            }

                        }
                    }

                    &:nth-child(02) {
                        width: 130px;
                    }

                    &:nth-child(05) {
                        text-align: left;
                    }

                    &:nth-child(04),
                    &:nth-child(06),
                    &:nth-child(07),
                    &:nth-child(08),
                    &:nth-child(09) {
                        text-align: center;
                    }

                    // &:last-child {
                    //     width: 180px;
                    // }

                    .dk-statusDropdown {
                        .dropdown {
                            .dropdown-toggle {
                                margin: 0 !important;
                                padding: 0;
                                background: #368836;
                                color: #fff;
                                width: 100%;
                                min-width: 85px;
                                max-width: 85px;
                                font-size: 0.85rem;

                                &::after {
                                    display: none;
                                }

                                .statusDiv {
                                    border-radius: 3px;
                                    padding: 8px 5px;
                                }
                            }

                            .dropdown-menu {
                                padding: 0;
                                border-radius: 0;
                                margin: 0 !important;
                                top: 34px !important;
                                transform: inherit !important;
                                left: 0 !important;
                                right: inherit !important;
                                border: none;

                                a {
                                    display: block;
                                    width: 100%;
                                    font-size: 14px;
                                    padding: 6px 12px;
                                }

                                a.dk-activeBtn {
                                    background: #3a9469;
                                    color: #fff;
                                }

                                a.dk-inActiveBtn {
                                    background: #dc3545;
                                    color: #fff;
                                }
                            }
                        }
                    }

                    .dk-userIcon {
                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                        }

                        .dropdown {
                            .dropdown-toggle {
                                margin: 0;
                                padding: 0;
                                box-shadow: none;

                                &::after {
                                    display: none;
                                }
                            }

                            .dropdown-menu {
                                transform: inherit !important;
                                top: 0px !important;
                                left: 53px !important;
                                right: inherit !important;
                                margin: 0 !important;
                                padding: 4px 0;
                                background: #234158;
                                border: none;

                                &::before {
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 9px 18px 9px 0;
                                    border-color: transparent #234158 transparent transparent;
                                    line-height: 0px;
                                    _border-color: #000000 #234158 #000000 #000000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                                    position: absolute;
                                    left: -15px;
                                    top: 10px;
                                }

                                a {
                                    display: inline-block;
                                    width: 32px;
                                    height: 32px;
                                    margin: 0 4px;

                                    img {
                                        display: inline-block;
                                        width: 32px;
                                        height: 32px;
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }

                    .dk-clupdeProfile i {
                        background: #297483;
                        padding: 5px;
                        text-align: center;
                        border-radius: 2px;
                        color: #fff;
                        margin-right: 8px;
                        cursor: pointer;
                    }

                    a.dk-cllistStatusbtnt {
                        margin: 0;
                        border-radius: 3px;
                        border: none;
                        width: 100%;
                        min-width: 85px;
                        padding: 8px 0;
                        color: #fff !important;
                        outline: 0;
                        cursor: pointer;
                        font-size: 11px;
                        text-transform: uppercase;
                        white-space: nowrap;
                        display: inline-block;
                        font-weight: 500;
                        text-align: center;
                        vertical-align: middle;
                    }

                    a.dk-clientsActBtn {
                        background-color: #1b6867;
                    }

                    .dk-clistViewTableIcn .dk-cllistViewTabImg {
                        min-height: 22px;
                        height: 30px;
                        width: 45px;
                    }
                }

            }
        }

        .company-list {
            width: 100%;
            max-width: 170px;
            position: absolute;
            top: -39px;

            input {
                box-shadow: none;
                outline: none;
            }
        }
    }
}

.company-list {
    .form-control {
        border: 1px solid #d1cccc;
        border-radius: 0;
    }

    .btn {
        margin: 0;
        border: 1px solid #d1cccc;
        padding: 0 10px;
        background: #ddd !important;
        box-shadow: none;
        color: #333;
    }
}

.dk-editCompanyList-modal {
    .modal-dialog {
        min-width: 95% !important;
        max-width: 95% !important;
        margin: 0 !important;
        float: right;

        .modal-content {
            box-shadow: none;
            border: none;
            font-family: "Segoe UI", Arial, sans-serif !important;
            border-radius: 0;

            .modal-header {
                padding: 6px 12px;
                border-bottom: none;
                background: #26597d;

                span.dk-idType {
                    white-space: nowrap;
                    color: #fff;
                    font-weight: 500;
                    position: absolute;
                    left: 15px;
                    top: 9px;
                    font-size: 14px;
                }

                .modal-title {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 700;
                    color: #fff;
                }

                .close {
                    outline: none;
                    position: absolute;
                    right: 14px;
                    top: 6px;
                    opacity: 1;
                    margin: 0;
                    padding: 0;
                    font-size: 22px;
                    text-shadow: none;
                    color: #fff;

                    span {
                        font-size: 24px !important;
                    }

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .modal-body {
                padding: 15px;
                height: calc(100vh - 40px);

                .dk-addNClientFormCont {
                    background: #D9DFE7;
                    border-radius: 5px;
                    padding: 15px;
                    height: calc(100vh - 68px);

                    button {
                        text-transform: unset;
                    }

                    .ddl-main.dropdown {
                        .dropdown-toggle {
                            margin: 0;
                            padding: 0;
                            box-shadow: none;
                            width: 100%;
                            background: #fff;
                            width: 100%;
                            height: 32px;
                            font-size: 13px;

                            &::after {
                                display: block;
                                position: absolute;
                                right: 10px;
                                font-size: 20px;
                                top: 13px;
                                color: #919191;
                            }
                        }

                        .dropdown-menu {
                            input {
                                text-align: center;
                            }

                            border-radius: 0;
                            width: 100%;
                            margin: 0 !important;
                            top: 33px !important;
                            transform: inherit !important;
                            padding: 2px;

                            a {
                                display: block;
                                padding: 6px 12px;
                                font-size: 14px;
                                color: #333;
                                border-bottom: 1px solid #ddd;
                                background: #f2f2f2;

                                &:hover {
                                    background: #fff;
                                    transition: all 0.5s;
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }

                    .form-group {
                        margin-bottom: 0.5rem;

                        .ui.selection.dropdown.dk-overdues-task-slect {
                            text-transform: inherit;
                            color: #333;

                            .default.text {
                                color: #333;
                            }
                        }

                        .dk-nativedatepicker-grid {
                            .dk-nativedatepicker {
                                margin-top: 0;
                                width: 100%;
                                margin-bottom: 0;
                                background: #fff;
                                border: 1px solid #ddd !important;
                                padding: 0;

                                .MuiInput-underline {
                                    &::before {
                                        display: none;
                                    }

                                    &::after {
                                        display: none;
                                    }
                                }

                                .MuiInputBase-root.MuiInput-root {
                                    input {
                                        text-align: center;
                                        font-size: 13px;
                                    }

                                    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                        .MuiButtonBase-root.MuiIconButton-root {
                                            padding: 4px 2px;
                                            background-color: #139bd6;
                                            border-radius: 0;
                                            color: #fff;
                                        }
                                    }
                                }

                            }
                        }

                        label {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            margin-bottom: 0;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000;
                            white-space: nowrap;
                            min-width: 100px;
                            line-height: 25px;
                        }

                        label.dk-longllabel {
                            min-width: 125px;
                            position: relative;
                            bottom: 2px;
                        }

                        input.form-control {
                            width: 100%;
                            height: 32px;
                            padding: 4px 6px;
                            font-size: 13px;
                            line-height: 1.42857143;
                            color: #000 !important;
                            border: 1px solid #ced4da;
                            background: #fff;
                            border-top-color: #ced4da;
                            outline: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            border-radius: 0;
                            box-shadow: none !important;

                            &.dkg-calenderinput-type {
                                // -webkit-appearance: none;
                                // -moz-appearance: none;
                                background: transparent;
                                background-image: url("../../../../assets//images/calendar.png");
                                background-repeat: no-repeat;
                                background-position-x: 100%;
                                background-position-y: 5px;
                                border: 1px solid #dfdfdf;
                                border-radius: 2px;
                                margin-right: 2rem;
                                padding: 1rem;
                                padding-right: 2rem;
                            }
                        }

                        input.form-control.dkg-calenderinput-type {
                            -moz-appearance: none;
                            -webkit-appearance: none;
                            appearance: none;
                            background: transparent;
                            background-image: url("../../../../assets//images/calendar.png");
                            background-repeat: no-repeat;
                            background-position-x: 100%;
                            background-position-y: 5px;
                            border: 1px solid #dfdfdf;
                            border-radius: 2px;
                            margin-right: 2rem;
                            padding: 1rem;
                            padding-right: 2rem;
                        }

                        // input[type=date]::-webkit-calendar-picker-indicator{
                        //     -moz-appearance:none; 
                        //     -webkit-appearance:none;
                        //     appearance:none;
                        // }
                        // input[type="date"]::-webkit-calendar-picker-indicator {
                        //     display: none;
                        //     -webkit-appearance: none;
                        // }
                        input[type="date"]::-webkit-calendar-picker-indicator {
                            -webkit-appearance: none !important;
                            -moz-appearance: none;
                            appearance: none;
                        }

                        span.dk-clViewAddonIcon.arrowDown {
                            font-size: 20px;

                            svg {
                                position: relative;
                                font-size: 15px;
                                top: 1px;
                            }
                        }

                        span.dk-clViewAddonIcon {
                            border: 1px solid #139BD6;
                            padding: 0;
                            background-color: #139BD6;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 25px;
                            min-width: 25px;
                            max-width: 25px;
                            height: 32px;
                            color: #fff;

                            i {
                                color: #fff;
                            }
                        }

                        .dk-createBtn {
                            display: inline-block;
                            background: #165a77;
                            box-shadow: none;
                            outline: none;
                            color: #fff;
                            padding: 8px 25px 8px;
                            font-size: 14px;
                            border: none;
                            text-transform: uppercase;
                            font-weight: 500;
                            border-radius: 5px;
                            outline: none;
                        }
                    }
                }

                .dkaddNewRightCon {
                    height: calc(100vh - 68px);
                    background: #D9DFE7;
                    border-radius: 5px;
                    padding: 15px;
                    margin-top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .addNewBox {
                        .dk-FileUploadCon {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .dk-fileUldimgCon {
                                width: 300px;
                                max-width: 250px;
                                height: 180px;
                                margin: 0 auto;
                                border: 3px dashed lightslategrey;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                h3 {
                                    text-transform: uppercase;
                                    color: #324148;
                                    padding: 40px 0;
                                    font-size: 1.4rem;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }

                &.dkg-listview-AddNewBody {
                    overflow-y: auto;

                    .dk-cliAdNewPageCont {
                        padding: 0 !important;
                        margin-top: 0 !important;

                        .row {
                            div {
                                .dk-clntsPageTitle {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-editCompanyList-modal.fade .modal-dialog {
    right: -95%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-editCompanyList-modal.show .modal-dialog {
    right: 0;
}