.dk-editCategoryMain{
    .modal-dialog{
        .modal-content{
            border-radius: 5px;
            .dk-editCategoryHeader{
                padding: 8px 10px;
                background-color: #37404d;
                color: #fff;
                border-radius: 5px 5px 0 0;
                .modal-title {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                }
                .close {
                    padding: 0;
                    position: absolute;
                    right: 6px;
                    top: 10px;
                    margin: 0;
                    opacity: 1;
                    text-shadow: none;
                    color: #fff;
                    font-size: 25px;
                    outline: none;
                    font-weight: 500;
                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
            .dk-editCategoryBody{
                padding: 15px;
                .dk-editCategory-pills{
                    .dk-editCategory-navitem{
                        width: 100%;
                        max-width: 33.33%;
                        text-align: center;
                        .nav-link{
                            border-radius: 0;
                            &:nth-child(01) {
                                background: #28a745;
                                color: #fff;
                            }
                        }
                        &:nth-child(02){
                            .nav-link{
                                background: #363f4c;
                                color: #fff;
                            }
                        }
                        &:nth-child(03){
                            .nav-link{
                                background: #69594a;
                                color: #fff;
                            }
                        }
                    }
                }
                .dk-editCategory-tabcontent{
                    .dk-editCategory-tabpane{
                        .dk-toolsValuesPanel {
                            margin-top: 15px;
                            .dk-edit-row-list{
                                span.dk-sno {
                                    display: inline-block;
                                    width: 40px;
                                    height: 37px;
                                    border: 1px solid #ddd;
                                    border-bottom: none;
                                    border-right: none;
                                    text-align: center;
                                    line-height: 35px;
                                }
                                input {
                                    border-radius: 0;
                                    height: 37px;
                                    border: 1px solid #ddd;
                                    border-bottom: none;
                                    background: #f2f2f2;
                                    box-shadow: none;
                                    text-align: left;
                                }
                                .dk-toolsPanel-updateIcon {
                                    display: inline-block;
                                    width: 45px;
                                    border: 1px solid #ddd;
                                    border-bottom: none;
                                    border-left: none;
                                    text-align: center;
                                    line-height: 35px;
                                    cursor: pointer;
                                    i {
                                        padding: 7px;
                                        text-align: center;
                                        color: #fff;
                                        background-color: #28a745;
                                        border-color: #28a745;
                                    }
                                }
                                &:last-child{
                                    span.dk-sno{
                                        border-bottom: 1px solid #ddd;
                                    }
                                    input{
                                        border-bottom: 1px solid #ddd;
                                    }
                                    .dk-toolsPanel-updateIcon{
                                        border-bottom: 1px solid #ddd;
                                    }
                                }
                            }
                        }
                        .dk-toolsReorderPanel{
                            margin-top: 15px;
                            ul{
                                .Mukul{
                                    // background-color: #eaeaea;
                                    .dk-valueFields{
                                        .dk-handle{
                                            background-color: rgb(54, 63, 76);
                                            width: 50px;
                                            text-align: center;
                                            line-height: 35px;
                                            color: white;
                                            cursor: all-scroll;
                                        }
                                        input {
                                            height: 35px;
                                            border-radius: 0px;
                                            border: 1px solid #ddd;
                                            border-bottom: none;
                                            background: #f2f2f2;
                                            box-shadow: none;
                                            text-align: left;
                                        }

                                    }
                                    &:last-child{
                                        .dk-valueFields{
                                            input {
                                                border-bottom: 1px solid #ddd;
                                            }
                                        }
                                    }
                                }

                            }
                            .d-flex{
                                .dk-toolsPanelSaveBtn {
                                    background: #165a77;
                                    box-shadow: none;
                                    color: #fff;
                                    font-size: 14px;
                                    outline: none;
                                    padding: 8px 12px 7px;
                                    border-radius: 4px;
                                    border: none;
                                }
                            }
                        }
                        .dk-toolsDeletePanel{
                            margin-top: 15px;
                            .d-flex{
                                input {
                                    border-radius: 0;
                                    height: 37px;
                                    border: 1px solid #ddd;
                                    border-bottom: none;
                                    background: #f2f2f2;
                                    box-shadow: none;
                                    text-align: left;
                                    cursor: not-allowed;
                                }
                                .dk-toolsPanel-updateIcon {
                                    display: inline-block;
                                    width: 45px;
                                    border: 1px solid #ddd;
                                    border-left: none;
                                    text-align: center;
                                    line-height: 35px;
                                    cursor: pointer;
                                    i.fa-trash-alt {
                                        background-color: #dc3545;
                                        border-color: #dc3545;
                                        padding: 7px;
                                        text-align: center;
                                        color: #fff;
                                    }
                                }
                                &:last-child{
                                    input{
                                        border-bottom: 1px solid #ddd;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}