.dk-moduleTraining-main{
    margin-top: 120px;
    @media(max-width:992px){
        margin-top: 120px;
    }
    .dk-moduleTraining-cols{
        background: #eaeaea;
        display: flex;
        @media(max-width:992px){
            flex-wrap: wrap;
        }
        .dk-moduleTraining-leftPanel{
            margin-top: 0;
            max-width: 170px;
            min-width: 170px;
            width: 100%;
            @media(max-width:992px){
                max-width:100%;
                min-width:100%;
            }
            ul::-webkit-scrollbar {
                width: 0px;
            }
               
            ul::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
               
            ul::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                outline: 1px solid slategrey;
            }
            ul{
                background: #244158;
                height: calc(100vh - 120px);
                list-style: none;
                margin: 0;
                min-height: auto;
                overflow-y: auto;
                padding: 0;
                @media(max-width:992px){
                    height: auto;
                    display: flex;
                }
                li{
                    display: block;
                    list-style-type: none;
                    width: 100%;
                    a{
                        border-bottom: 1px solid #3e5b73;
                        color: #ddd;
                        display: block;
                        font-family: Roboto,sans-serif!important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 15px;
                        text-transform: inherit;
                        width: 100%;
                        @media(max-width:992px){
                            white-space: nowrap;
                            border-bottom: none;
                        }
                    }
                    a.active{
                        background: #eaeaea;
                        color: #333;
                    }
                }
            }
        }

        .dk-moduleTraining-rightPanel{
            width: 100%;
            margin-left: 30px;
            padding-right: 15px;
            position: relative;
            @media(max-width:992px){
                margin-left: 0;
                height: calc(100vh - 185px);
                padding: 0 12px;
                margin-top: 10px;
            }
            .title{
                text-align: center;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 500;
                padding: 11px 0;
                @media(max-width:992px){
                    text-align: left;
                    padding: 10px 15px;
                }
            }
            .dk-getStartedRight-detail{
                .nav{
                    position: absolute;
                    right: 18px;
                    top:8px;
                    .nav-item{
                        margin-right:18px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            border-bottom: 3px solid transparent;
                            box-shadow: none;
                            color: #737373;
                            font-family: Roboto,sans-serif!important;
                            font-size: 16px;
                            font-weight: 400;
                            padding: 0 0 2px;
                            text-transform: capitalize;
                        }
                        a.active{
                            border-bottom:3px solid #3c97b6;
                            color: #3c97b6;
                        }
                    }                    
                }
            }
            .dk-moduleTraining-rightCols{
                width: 100%;
                background: #fff;
                height: calc(100vh - 165px);
                border: 1px solid #ddd;
                @media(max-width:992px){
                    height:auto;
                    min-height: 300px;
                    border-top: none;
                }
                .dk-getStartedRight-detail{
                    .dk-rightDetail-panel{
                        .d-flex{
                            @media(max-width:992px){
                                flex-wrap: wrap;
                            }
                        }
                        .dk-detailLefttabsMain{
                            background: #eaeaea;
                            display: inline-block;
                            height: calc(100vh - 168px);
                            margin-right: 0;
                            max-width: 195px;
                            width: 100%;
                            @media(max-width:992px){
                                height: auto;
                                max-width: 100%;
                            }
                            .MuiBox-root::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                            }
                               
                            .MuiBox-root::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            }
                            
                            .MuiBox-root::-webkit-scrollbar-thumb {
                                background-color: darkgrey;
                                outline: 0px solid slategrey;
                            }
                            .MuiBox-root{
                                width: 100%;
                                @media(max-width:992px){
                                    display: flex;
                                    overflow-x: auto;
                                }
                                .MuiTabs-root{
                                    flex-wrap: wrap;
                                    min-height: auto;
                                    overflow: visible;
                                    width: 100%;
                                    .MuiTabs-scroller{
                                        overflow: visible!important;
                                        white-space: normal;
                                        .MuiTabs-indicator{
                                            display: none;
                                        }
                                        .MuiTabs-flexContainer{
                                            flex-wrap: wrap;
                                            justify-content: space-between;
                                            overflow: visible;
                                            .MuiButtonBase-root{
                                                align-items: flex-start;
                                                align-items: left;
                                                border-bottom: 1px solid #ddd;
                                                color: #757575;
                                                display: block;
                                                flex-shrink: inherit;
                                                font-size: 15px;
                                                font-weight: 400;
                                                max-width: 100%;
                                                min-height: auto;
                                                min-width: inherit!important;
                                                opacity: 1;
                                                outline: none;
                                                overflow: visible;
                                                padding: 12px 15px;
                                                position: relative;
                                                text-align: center;
                                                text-transform: capitalize;
                                                width: 100%;   
                                                @media(max-width:992px){
                                                    white-space: nowrap;
                                                }                                       
                                            }
                                            .MuiButtonBase-root.Mui-selected{
                                                background: #fff;
                                                color: #606060;
                                            }                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}