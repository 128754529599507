.dk-historyMain {
    margin-top: 185px;

    h3.title {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        display: block;
        text-align: center;
        width: 100%;
        margin-bottom: 25px;
    }

    .dk-history-container {
        position: relative;
        margin-top: 30px;

        .title {
            position: relative;
            text-align: center;

            &::after {
                position: absolute;
                content: "";
                width: 70%;
                border-top: 3px solid #003f51;
                border-left: 3px solid #003f51;
                border-right: 3px solid #003f51;
                height: 56px;
                top: 22px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            span {
                display: inline-block;
                padding: 8px 30px 10px;
                background: #003f51;
                position: relative;
                z-index: 4;
                font-size: 16px;
                color: #fff;

                svg {
                    margin: 0 5px;
                }
            }
        }

        .dk-historyBoxes {
            display: flex;
            margin-top: 30px;

            .dk-history-cols {
                float: left;
                width: 16%;
                margin-right: 8px;
                position: relative;

                &:nth-child(01) {
                    &::after {
                        display: none;
                    }
                }

                &:nth-child(02) {
                    &::before {
                        border-top: 22px solid transparent;
                        border-left: 25px solid #00cc99;
                        border-bottom: 22px solid transparent;
                    }

                    .dk-history-img {
                        background: #00cc99;
                    }
                }

                &:nth-child(03) {
                    &::before {
                        border-top: 22px solid transparent;
                        border-left: 25px solid #1d5c85;
                        border-bottom: 22px solid transparent;
                    }

                    .dk-history-img {
                        background: #1d5c85;
                    }
                }

                &:nth-child(04) {
                    &::before {
                        border-top: 22px solid transparent;
                        border-left: 25px solid #ffc751;
                        border-bottom: 22px solid transparent;
                    }

                    .dk-history-img {
                        background: #ffc751;
                    }
                }

                &:nth-child(05) {
                    &::before {
                        border-top: 22px solid transparent;
                        border-left: 25px solid #ff9640;
                        border-bottom: 22px solid transparent;
                    }

                    .dk-history-img {
                        background: #ff9640;
                    }
                }

                &:nth-child(06) {
                    .dk-history-img {
                        background: #ff706f;
                    }
                }

                &::before {
                    position: absolute;
                    content: "";
                    border-top: 22px solid transparent;
                    border-left: 25px solid #fff;
                    border-bottom: 22px solid transparent;
                    top: 50%;
                    right: -25px;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    z-index: 4;
                }

                &::after {
                    position: absolute;
                    content: "";
                    border-top: 25px solid transparent;
                    border-left: 29px solid #2f98b7;
                    border-bottom: 25px solid transparent;
                    top: 50%;
                    left: 0;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    z-index: 3;
                }

                .dk-history-img {
                    height: 170px;
                    background: #d0d0d0;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .sbtitle {
                    font-size: 16px;
                    color: #2e7282;
                    background: #e7e7e7;
                    padding: 2px 0;
                    text-align: center;

                    small {
                        font-size: 10px;
                        bottom: 0;
                    }
                }
            }
        }
    }

    .dk-historyBinifits {
        padding: 30px 0;

        h4.title {
            text-align: left;
            color: #333;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                position: relative;
                display: block;
                margin-bottom: 10px;
                position: relative;
                color: #5f5f5f;

                &::before {
                    content: "";
                    background: url('../../../../../../assets//images/checked.png') no-repeat;
                    padding: 0px 12px;
                    background-size: 15px;
                    position: relative;
                    top: 1px;
                }
            }
        }

        .dk-historyBinifits-img {
            img {
                width: 100%;
                max-width: 350px;
                margin: auto;
            }
        }
    }
}