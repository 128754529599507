.dk-visitWebsiteMain {
    padding: 15px;
    // background: #26596d;
    background: #fff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    h3.title {
        text-align: center;
        display: block;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        margin-bottom: 30px;
    }

    .dk-visitWebsite-cols {
        width: 100%;
        max-width: 1050px;
        margin: auto;
        display: flex;
        justify-content: center;
        .dk-visitWebsite-box {
            display: inline-block;
            margin: 0 15px 25px;
            width: 100%;

            a {
                padding: 30px 20px;
                background: #fefefe;
                border-radius: 8px;
                min-height: 125px;
                display: flex;
                align-items: center;
                justify-content: center;

                .dk-visitLogo {
                    text-align: center;
                    display: block;
                    text-transform: uppercase;
                    font-size: 16px;
                    text-align: center;
                    font-weight: 500;

                    img {
                        width: 100%;
                        max-width: 150px;
                        margin: auto;
                        transform: scale(1);
                        transition: all 0.5s;
                    }
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: all 0.5s;
                    }
                }

                .title {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #26596d;
                    font-weight: 600;
                    text-align: center;
                    display: block;
                    margin-top: 10px;
                }
            }
        }

        .dk-visitWebsite-box.itteamtabs {
            a {
                background: #B55355;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.trainingtabs {
            a {
                background: #396a95;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.dkglobaltabs {
            a {
                background: #258fb4;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.dkgcrm-dashboardbox {
            a {
                background: #144773;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.dkgcrm-myaccountsbox {
            a {
                // background: #2e75b6;
                color: #fff;
                background: #884C5E;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.dkgcrm-lastbox {
            a {
                background: #cf7c49;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.dkgcrm-recquirementdbox {
            a {
                background: #3c4c62;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.dkgcrm-marketingdbox {
            a {
                background: #4c5f59;
                // background: #396A95;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.dkgcrm-acc-finanancebox {
            a {
                background-color: #5A4C72;
                // background: #884C5E;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }

        .dk-visitWebsite-box.dkgcrm-supplierbox {
            a {
                background-color: #01796f;
                // background: #884C5E;
                color: #fff;

                .dk-visitLogo {
                    span {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            max-width: 40px;
                        }
                    }

                    color: #fff;
                }
            }
        }
    }
}

// START MEDIA QUERY
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .dk-visitWebsiteMain {
        height: 100% !important;
        .dk-visitWebsite-cols{
            display: block;
            .dk-visitWebsite-box{
                margin: 8px auto  !important;
                &:nth-child(03){
                    padding-right: 0 !important;
                }
                &:nth-child(04){
                    padding-left: 0 !important;
                }
                &.dkg-busines-obox{
                    margin-top: 35px !important;
                }
                a{
                    padding: 10px 20px;
                    min-height: inherit;
                    .dk-visitLogo{
                        span{
                            img{
                                max-width: 26px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//     .dk-visitWebsiteMain {
//         height: 100% !important;
//         .dk-visitWebsite-cols{
//             display: block;
//             .dk-visitWebsite-box{
//                 margin: 8px auto  !important;
//                 &:nth-child(03){
//                     padding-right: 0 !important;
//                 }
//                 &:nth-child(04){
//                     padding-left: 0 !important;
//                 }
//                 &.dkg-busines-obox{
//                     margin-top: 35px !important;
//                 }
//             }
//         }
//     }
// }
