.dk-tabs-right-tabpane-12.addnewClass.dkg-hidewithoutselct {
    display: none;
}

.dk-tabs-right-tabpane-12.addnewClass.dkg-hidewithoutselct.activesSubTab {
    display: inherit;

    .dk-rightDetail-panel-13 {
        .dk-getStartedRight-detail-13 {
            display: flex;

            .dk-tabs-right-nav-13 {
                background: #eaeaea;
                display: inline-block;
                height: calc(100vh - 267px) !important;
                overflow-y: auto;
                margin-right: 0;
                max-width: 220px;
                min-width: 220px;
                width: 100%;

                .dk-tabs-right-navitem-13 {
                    .dk-tabs-right-navlink-13 {
                        align-items: flex-start;
                        border-bottom: 1px solid #ddd;
                        border-left: 1px solid #ddd;
                        color: #757575;
                        display: block;
                        flex-shrink: inherit;
                        font-size: 15px;
                        font-weight: 400;
                        max-width: 100%;
                        min-height: auto;
                        min-width: inherit !important;
                        opacity: 1;
                        outline: none;
                        overflow: visible;
                        padding: 11px 7px;
                        position: relative;
                        text-align: left;
                        text-transform: inherit !important;
                        width: 100%;
                        font-family: Roboto, sans-serif !important;

                        &.addActiveClass {
                            background: #fff;
                            color: #606060;
                        }
                    }

                    // &:first-child {
                    //     .dk-tabs-right-navlink-13 {
                    //         border-top: 1px solid #ddd;
                    //     }
                    // }
                }
            }

            .dk-tabs-right-tabcontent-13.tab-content {
                width: 100%;
                background-color: #fff;
                height: calc(100vh - 185px);

                .dkg-scriptview-text {
                    padding: 15px;
                }
            }
        }
    }
}

.dkg-payment-qt-pageCon {
    margin-top: 8px;

    &.dkg-comman-training-pageCon {
        margin-top: 130px;

        &.dkg-comman-training-234 {
            .dkg-payment-qt-tab-mainCon {
                .col-md-2.dkg-payment-qt-tabCon {
                    flex: 0 0 13.666667%;
                    max-width: 13.666667%;
                }

                .col-md-10.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 85.333333%;
                    max-width: 85.333333%;
                }
            }
        }

        &.dkg-solutions-mainpageCon {
            margin-top: 170px;
            height: calc(100vh - 170px);

            &.dkg-profie-taskCon {
                margin-top: 142px;

                .dkg-profies-linksCon {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    padding-bottom: 0;
                    max-width: 1600px;
                    margin: 0 auto;
                    background-color: #fff;

                    .dkg-profile-link {
                        width: 100%;
                        max-width: 9.999% !important;
                        text-align: center;
                        color: #333;
                        height: 44px;
                        line-height: 44px;
                        font-weight: 400;
                        letter-spacing: 0.3px;
                        font-size: 14px;
                        font-family: Verdana, sans-serif;
                        border-right: 1px solid #ddd;
                        background-color: #fff;

                        &:hover,
                        &.active {
                            background-color: #d9dfe7;
                        }
                    }
                }

                .dkg-payment-qt-tab-mainCon {
                    border-top: none !important;
                    height: calc(100vh - 187px) !important;
                    background-color: #d9dfe7;

                    @media only screen and (min-width: 768px) {
                        .col-md-2 {
                            .dkg-payment-qt-tab-navpills {
                                height: calc(100vh - 223px) !important;

                                .dkg-payment-qt-tab-nav-item {
                                    &.dkg-solutions-navitem {
                                        &:first-child {

                                            .dkg-payment-qt-tab-navlink,
                                            .dkg-payment-qt-tab-navlink.active {
                                                color: #d9dfe7;
                                            }

                                            .dkg-payment-qt-tab-navlink {
                                                background-color: #d9dfe7;
                                                color: #d9dfe7;
                                                border-bottom: none;
                                            }
                                        }

                                        .dkg-payment-qt-tab-navlink {
                                            &.active {
                                                background-color: #d9dfe7;

                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .col-md-10.dkg-payment-rt-tabCon-10 {
                            .dkg-payment-qt-tabcontent {
                                .dkg-payment-qt-tabpane {
                                    height: calc(100vh - 223px) !important;

                                    .dkg-payment-qt-rightpanel {
                                        .dkg-tabs-rightPanel-12 {
                                            .title {
                                                background-color: #d9dfe7;
                                            }

                                            .dk-tabs-rightCols-12 {
                                                .dk-tabs-right-detail-12 {
                                                    .dk-tabs-right-tabpane-12.addnewClass.dkg-hidewithoutselct.activesSubTab {
                                                        .dk-rightDetail-panel-13 {
                                                            .dk-getStartedRight-detail-13 {
                                                                .dk-tabs-right-nav-13 {
                                                                    background-color: #d9dfe7;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .dkg-payment-qt-tab-mainCon {
                height: calc(100vh - 170px) !important;

                .dkg-payment-qt-tabCon.dkg-solutions-side-menuCon {
                    .dkg-payment-qt-tab-navpills {
                        max-height: calc(100vh - 221px) !important;

                        &::-webkit-scrollbar {
                            background-color: transparent;
                            width: 0px;
                        }
                    }

                    .dkg-payment-qt-tab-navpills:hover {
                        &::-webkit-scrollbar {
                            width: 8px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: rgba(0, 0, 0, .2)
                        }
                    }
                }

                .dkg-payment-rt-tabCon-10.dkg-solutions-right-sideCon {
                    .dkg-payment-qt-tabcontent {
                        .dkg-payment-qt-tabpane {
                            height: calc(100vh - 174px) !important;

                            .dkg-payment-qt-rightpanel {
                                .dkg-tabs-rightPanel-12 {
                                    .dk-tabs-rightCols-12 {
                                        color: inherit;

                                        .dk-tabs-right-detail-12 {
                                            .dk-tabs-right-tabpane-12 {
                                                .dk-rightDetail-panel-13 {
                                                    .dk-getStartedRight-detail-13 {
                                                        .dk-tabs-right-nav-13 {
                                                            &::-webkit-scrollbar {
                                                                background-color: transparent;
                                                                width: 0px;
                                                            }
                                                        }

                                                        .dk-tabs-right-nav-13:hover {
                                                            &::-webkit-scrollbar {
                                                                width: 8px;
                                                            }

                                                            &::-webkit-scrollbar-thumb {
                                                                background-color: rgba(0, 0, 0, .2)
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .dk-tabs-right-tabcontent-13.tab-content {
                    .dkg-scriptview-text {
                        .wrapper-class.rdw-editor-wrapper {
                            .editor-class.rdw-editor-main {
                                padding: 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.dkg-clientinfo-training-demands-pageCon {
        margin-top: 160px;
        .dkg-jobspec-company-header{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 10px;
            .dkg-jobpsec-DropdownCon{
                .dkg-jobpsec-Dropdown{
                    margin: 0;
                    .dkg-jobpsec-Dropdown-toggle{
                        background-color: #fff;
                        border: none;
                        box-shadow: none;
                        font-size: 13px;
                        margin: 0;
                        min-width: 110px;
                        padding: 7px 9px;
                        text-transform: capitalize;
                        min-width: 240px;
                        position: relative;
                        color: #636363;
                        border: none !important;
                        &:after{
                            position: absolute;
                            top: 17px;
                            right: 30px;
                            color: #636363;
    
                        }
                    }
                    .dkg-jobpsec-Dropdown-Menu{
                        border-radius: 0;
                        min-width: 180px;
                        padding: 0;
                        height: calc(100vh - 440px);
                        overflow-y: auto;
                        width: 100%;
                        .dkg-jobpsec-Dropdown-Item{
                            border-bottom: 1px solid #ddd;
                            font-size: 13px;
                            padding: 7px 10px;
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
                .dkg-jobpsec-page-title{
                    font-weight: 600;
                    padding: 0;
                    margin: 0;
                    color: #244158;
                    font-size: 18px;
                }
            } 
            .dkg-jobspec-company-title {
                border-bottom: 1px solid #ddd;
                font-size: 18px;
                font-weight: 500;
                padding: 11px 0;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
                background-color: #fff;
                font-weight: 600;
            }
        }
    }

    .dkg-payment-qt-tab-mainCon {
        background-color: #eaeaea;
        // margin-top: 15px !important;
        border-top: 1px solid #ddd;

        .col-md-2 {
            @media only screen and (max-width: 600px) {
                &.dkg-payment-qt-tabCon {
                    flex: 0 0 13.666667%;
                    max-width: 13.666667%;

                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 17.666667%;
                        max-width: 17.666667%;
                    }
                }
            }

            @media only screen and (min-width: 600px) {
                &.dkg-payment-qt-tabCon {
                    flex: 0 0 13.666667%;
                    max-width: 13.666667%;

                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 17.666667%;
                        max-width: 17.666667%;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                &.dkg-payment-qt-tabCon {
                    flex: 0 0 17.666667%;
                    max-width: 17.666667%;

                    &.dkg-prescreen-question-sidemenu-234 {
                        flex: 0 0 17.666667%;
                        max-width: 17.666667%;
                    }

                    &.dkg-solutions-side-menuCon {
                        flex: 0 0 13.666667%;
                        max-width: 13.666667%;
                    }

                    &.dkg-clientinfo-sedmenu {
                        flex: 0 0 14.666667%;
                        max-width: 14.666667%;
                    }
                }
            }

            .dkg-payment-qt-tab-navpills {
                margin-bottom: 0;
                width: 100%;
                background-color: #244158;
                height: calc(100vh - 125px);
                overflow-y: auto;
                flex-wrap: nowrap;

                .dkg-payment-qt-tab-nav-item {
                    .dkg-payment-qt-tab-navlink {
                        // background-color: #eaeaea;
                        // border-radius: 0;
                        // color: #333;
                        // font-size: .95rem;
                        // padding: 0.65rem 1rem;
                        // text-align: center;
                        background: #244158;
                        border-bottom: 1px solid #3e5b73;
                        color: #ddd;
                        display: block;
                        font-family: Roboto, sans-serif !important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 7px;
                        text-transform: inherit;
                        width: 100%;
                        border-radius: 0;

                        &.active,
                        &.show>.nav-link {
                            background-color: #eaeaea;
                            color: #333;
                        }
                    }
                }
            }
        }

        .col-md-10 {
            @media only screen and (max-width: 600px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 82.333333%;
                    max-width: 82.333333%;

                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 81.333333%;
                        max-width: 81.333333%;
                    }

                    &.dkg-clientinfo-rightCon {
                        flex: 0 0 84.333333%;
                        max-width: 84.333333%;
                    }
                }
            }

            @media only screen and (min-width: 600px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 82.333333%;
                    max-width: 82.333333%;

                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 81.333333%;
                        max-width: 81.333333%;
                    }

                    &.dkg-clientinfo-rightCon {
                        flex: 0 0 84.333333%;
                        max-width: 84.333333%;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                &.dkg-payment-rt-tabCon-10 {
                    flex: 0 0 82.333333%;
                    max-width: 82.333333%;

                    &.dkg-solutions-right-sideCon {
                        flex: 0 0 86.333333%;
                        max-width: 86.333333%;

                        .dkg-payment-qt-tabcontent {
                            .dkg-payment-qt-tabpane {
                                .dkg-payment-qt-rightpanel {
                                    .dkg-tabs-rightPanel-12 {
                                        .dk-tabs-rightCols-12 {
                                            .dk-tabs-right-detail-12 {
                                                .dk-tabs-right-tabpane-12 {
                                                    .dk-rightDetail-panel-13 {
                                                        .dk-getStartedRight-detail-13 {
                                                            .dk-tabs-right-nav-13 {
                                                                height: calc(100vh - 235px);
                                                            }

                                                            .dk-tabs-right-tabcontent-13 {
                                                                height: calc(100vh - 268px);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .dkg-payment-qt-tabcontent {
                        .dkg-payment-qt-tabpane {
                            height: calc(100vh - 160px);
                            background-color: #eaeaea;
                        }
                    }


                    &.dkg-question-rightsidecon-234 {
                        flex: 0 0 81.333333%;
                        max-width: 81.333333%;
                    }

                    &.dkg-clientinfo-rightCon {
                        flex: 0 0 84.333333%;
                        max-width: 84.333333%;
                    }
                }
            }
        }

        .dk-tabs-right-tabcontent-13.tab-content {
            .dkg-scriptview-text {
                .wrapper-class.rdw-editor-wrapper {
                    border: none;
                    position: relative;
                    top: -12px;

                    .rdw-editor-toolbar.toolbar-class {
                        display: none !important
                    }

                    .editor-class.rdw-editor-main {
                        cursor: text;
                        caret-color: transparent;
                        min-height: inherit;
                        max-height: inherit;

                        .DraftEditor-root {
                            .DraftEditor-editorContainer {
                                .public-DraftEditor-content {
                                    color: #000;

                                    div {
                                        div {
                                            .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
                                                margin: 1em 0 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

        .dkg-payment-qt-tabcontent {
            .dkg-payment-qt-tabpane {
                .dkg-payment-qt-rightpanel {
                    position: relative;

                    .title {
                        font-size: 15px;
                        font-weight: 500;
                        padding: 11px 0;
                        text-align: center;
                        text-transform: uppercase;
                        width: 100%;
                        border-bottom: 1px solid #ddd;
                    }

                    .dk-tabs-rightCols-12 {
                        .dk-tabs-right-detail-12 {
                            .dk-tabs-right-tabcontent-13.tab-content {
                                .dkg-scriptview-text {
                                    background-color: #fff;
                                    height: calc(100vh - 268px);
                                    overflow-y: auto;
                                    padding: 0 10px;
                                }
                            }
                        }
                    }

                    .dk-moduleTraining-rightCols {
                        background: #fff;
                        // border: 1px solid #ddd;
                        height: calc(100vh - 170px);
                        width: 100%;
                    }

                    .dk-tabs-rightCols-12 {
                        .dk-tabs-right-detail-12 {
                            .dk-tabs-right-nav-12 {
                                position: absolute;
                                right: 0;
                                top: 8px;

                                .dk-tabs-right-navitem-12 {
                                    margin-right: 18px;

                                    .dk-tabs-right-navlink-12 {
                                        border-bottom: 3px solid transparent;
                                        box-shadow: none;
                                        color: #737373;
                                        font-family: Roboto, sans-serif !important;
                                        font-size: 16px;
                                        font-weight: 400;
                                        padding: 0 0 2px;
                                        text-transform: capitalize;

                                        &.active,
                                        &.activeSubTab {
                                            border-bottom: 3px solid #3c97b6;
                                            color: #3c97b6;
                                        }
                                    }
                                }
                            }

                            .dk-tabs-right-tabcontent-12 {
                                .dk-tabs-right-tabpane-12 {
                                    background: #fff;
                                    border: 1px solid #ddd;
                                    height: calc(100vh - 165px);
                                    width: 100%;

                                    .dk-rightDetail-panel-13 {
                                        color: #244158;

                                        .dk-getStartedRight-detail-13 {
                                            display: flex;

                                            .dk-tabs-right-nav-13 {
                                                width: 100%;
                                                max-width: 195px;
                                                min-width: 195px;
                                                background: #eaeaea;
                                                display: inline-block;
                                                height: calc(100vh - 168px);
                                                margin-right: 0;

                                                .dk-tabs-right-navitem-13 {
                                                    .dk-tabs-right-navlink-13 {
                                                        align-items: flex-start;
                                                        border-bottom: 1px solid #ddd;
                                                        color: #757575;
                                                        display: block;
                                                        flex-shrink: inherit;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        max-width: 100%;
                                                        min-height: auto;
                                                        min-width: inherit !important;
                                                        opacity: 1;
                                                        outline: none;
                                                        overflow: visible;
                                                        padding: 12px 15px;
                                                        position: relative;
                                                        text-align: center;
                                                        text-transform: capitalize;
                                                        width: 100%;

                                                        &.active,
                                                        &.addActiveClass {
                                                            background: #fff;
                                                            color: #606060;
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-tabs-right-tabcontent-13 {
                                                .dk-tabs-right-tabpane-13 {
                                                    padding: 24px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } 
                    &.dkg-pt-qt-about-rightpanel{
                        .dk-moduleTraining-rightCols{
                            .dk-jobsuMMBottomSec{
                                display: none;
                            }
                        }
                    }
                    &.dkg-pt-qt-images-rightpanel{
                        .dk-suMMLeftCon{
                            display: none;
                        }
                        .dk-moduleTraining-rightCols{
                            .dk-jobsuMMBottomSec{
                                .dk-jobsuMMBottomBoxBg{
                                .dkg-company-img-video-Con{
                                    .dkg-white-horzontal,
                                    .dkg-comany-secondbox
                                        {
                                            display: none;
                                        }
                                    }
                                
                                }
                               
                            }
                        }
                        
                    }
                    &.dkg-pt-qt-videos-rightpanel{
                        .dk-suMMLeftCon{
                            display: none;
                        }
                        .dk-moduleTraining-rightCols{
                            .dk-jobsuMMBottomSec{
                                .dk-jobsuMMBottomBoxBg{
                                .dkg-company-img-video-Con{
                                    .dkg-white-horzontal,
                                    .dkg-comany-firstbox
                                        {
                                            display: none;
                                        }
                                    }
                                
                                }
                               
                            }
                        }
                        
                    }

                }
            }
        }
    }
}