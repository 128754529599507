.dkg-view-paymenttask-modal-21 {
    .modal-dialog {
        min-width: 600px !important;
        max-width: 600px;

        .modal-content {
            border-radius: 5px;

            .dkg-view-payment-modalbody-21 {
                padding: 15px;
                position: relative;
                background-color: #eaeaea;
                border-radius: 5px;

                .closeicon {
                    background: #102c46;
                    border-radius: 50%;
                    color: #fff;
                    cursor: pointer;
                    display: inline-block;
                    height: 30px;
                    line-height: 28px;
                    position: absolute;
                    right: -9px;
                    text-align: center;
                    top: -15px;
                    width: 30px;

                    &:hover {
                        background: #2b8faf;
                        transition: all .5s;
                        cursor: pointer;
                    }
                }

                .form-group {
                    margin-bottom: 0 !important;

                    textarea {
                        &.dkg-readonly-paymentnotes {
                            background-color: #fff !important;
                            text-align: left;
                            border-radius: 5px;
                            border: 1px solid #ddd;
                        }
                    }
                }
            }
        }
    }
}