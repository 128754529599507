.dk-workplantasktitleModal {
    .modal-dialog {
        min-width: 45%;
        max-width: 45%;

        .modal-content {
            border-radius: 5px;
            background-color: #ddd;

            .modal-header.dk-tasktitleModalHeader {
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #26597d;
                padding: 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: none;
                position: relative;

                .dkg-tasktitle-editicon-Con {
                    position: absolute;
                    left: 45px;
                    top: 12px;

                    svg {
                        color: #fff;
                        cursor: pointer;
                        width: 18px;
                        height: 18px;

                        &:hover {
                            opacity: 0.79;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }
                    }
                }

                .modal-title {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                }

                .close {
                    text-shadow: none;
                    color: #fff;
                    opacity: 1;
                }
            }

            .dk-tasktitleModalBody {
                padding: 20px 30px;
                background-color: #fff;
                border-radius: 0 0 5px 5px;

                .from-group {
                    margin-bottom: 1.5rem;

                    label {
                        font-size: 13px;
                        display: block;
                        margin-bottom: 2px;
                        color: #575050;
                        text-align: left;

                        &.dk-cre-newtask-label-center {
                            text-align: left;
                            min-width: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .dk-task-for-dropdown.dk-task-for-dropdown-24 {
                        .dk-task-for-dropdown-toggle.dropdown-toggle {
                            display: inherit !important;
                        }
                    }

                    .dk-taskText {
                        color: #fff;
                        display: block;
                        font-size: 13px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        text-transform: uppercase;
                        width: 100%;
                    }

                    input.dk-tasktype-input {
                        border: 1px solid #c1c9dc;
                    }

                    input {
                        border: 1px solid #ddd;
                        text-transform: uppercase;

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }

                        &.dk-tasktype-input[readonly] {
                            // background-color: #234158;
                            // border: 1px solid #234158;
                            text-align: center;

                            &::placeholder {
                                color: #333;
                                opacity: 1;
                                /* Firefox */
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10-11 */
                                color: #fff;
                            }

                            &::-ms-input-placeholder {
                                /* Microsoft Edge */
                                color: #fff;
                            }
                        }
                    }

                    .dk-tasktype-dropdown {
                        .dk-tasktype-dropdown-toggle {
                            box-shadow: none;
                            border: 1px solid #ddd;
                            padding: 5px 14px;
                            height: 34px;
                            border-radius: 3px;
                            background: #fff;
                            margin-top: 0px;
                            font-family: Verdana, sans-serif;
                            font-size: 14px;
                            text-transform: capitalize;
                            line-height: 22px;
                            color: #797474;
                            width: 100%;
                            position: relative;
                            margin: 0;

                            &::after {
                                position: absolute;
                                right: 10px;
                                top: 15px;
                            }
                        }

                        .dk-tasktype-dropdown-menu {
                            padding: 0;
                            border-radius: 0;
                            width: 100%;
                            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                            border: none;

                            .dropdown-item {
                                padding: 7px 10px;
                                font-size: 13px;
                                color: #333;
                            }
                        }
                    }

                    .dk-taskByCon {
                        border-radius: 3px;
                        height: 35px;
                        padding: 0 10px !important;
                        min-height: 35px;
                        line-height: 35px;
                        box-shadow: none;
                        border: 1px solid rgba(34, 36, 38, .15);
                        display: flex;
                        align-items: center;
                        background-color: #e9ecef;

                        &.dkg-task-123 {
                            // border-right: none;
                            // border-top-right-radius: 0;
                            // border-bottom-right-radius: 0;
                            background: #e9ecef;
                        }

                        &.dkg-taskforLeft124 {
                            // border-top-left-radius: 0;
                            // border-bottom-left-radius: 0;
                            background: #e9ecef;
                        }

                        .dk-selectator-textlength {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;

                            &:first-child {
                                margin-right: 0.5rem;
                            }

                            &:last-child {
                                margin-right: 0;
                            }

                            .dk-selected-items {
                                display: flex;
                                background-color: #fafafa;
                                border: 1px solid #ddd;
                                width: 44px;
                                justify-content: center;
                                height: 33px;
                                align-items: center;
                                padding: 3px 5px;
                                border-radius: 2px;
                                position: relative;

                                .userImgCont {
                                    img {
                                        width: 25px;
                                        height: 23px;
                                    }
                                }

                                .dk-selecteditem-remove {
                                    display: inline-block;
                                    font-weight: 700;
                                    color: rgba(0, 0, 0, .75);
                                    margin: 0 0 0 5px;
                                    cursor: pointer;
                                    font-size: 60%;
                                    line-height: 10px;
                                    vertical-align: top;
                                    border-radius: 0 2px 2px 0;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    padding: 7px 5px 4px;
                                }
                            }
                        }
                    }

                    .dk-taskSwitch {
                        color: #333;

                        li.tg-list-item {
                            list-style-type: none;
                        }
                    }
                }

                .dk-caseText {
                    background: #d58b5d;
                    border: 1px solid #d58b5d;
                    color: #fff;
                    display: block;
                    font-size: 13px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                }

                .form-group.dk-create-date-timeFrmGrp {
                    .input-group-prepend {
                        .input-group-text {
                            background: #e9ecef;
                        }
                    }

                    &.dkg-dropdownicon-Frmgrp {
                        .dkg-task-achtmentDropDown {
                            .dropdown-toggle {
                                margin: 0;
                                background-color: #e9ecef !important;
                                color: #333;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                box-shadow: none;
                                text-transform: capitalize;
                                padding: 8px 10px;
                                border: 1px solid #e9ecef !important;
                                font-size: 12px;
                                color: #495057;

                                &:hover {
                                    background-color: #fff;
                                }

                                &::after {
                                    position: absolute;
                                    top: 15px;
                                    right: 10px;
                                }
                            }

                            .dropdown-menu {
                                padding: 0;
                                border-radius: 0;
                                width: 100%;
                            }
                        }
                    }

                    label {
                        font-size: 13px;
                        display: block;
                        color: #575050;
                        text-align: left;
                        margin-bottom: 1px;

                        &.dk-cre-newtask-label-center {
                            display: flex;
                            min-width: 100px;
                            align-items: center;
                            justify-content: center;
                        }

                        &.dk-TaskDate {
                            font-size: 13px;
                            display: block;
                            margin-bottom: 2px;
                            color: #575050;
                            text-align: left;

                            input {
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                                border-right: none;
                                height: 35px;
                            }
                        }

                        &.dk-TaskTime {
                            font-size: 13px;
                            display: block;
                            margin-bottom: 2px;
                            color: #575050;
                            text-align: left;

                            input {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                height: 35px;
                            }
                        }

                        &.dk-taskChoosefile {
                            position: relative;
                            border: 1px solid #ddd;
                            border-radius: 3px;

                            .file-select-name {
                                font-size: 12px;
                                display: inline-block;
                                position: absolute;
                                top: 8px;
                                left: 5px;
                            }

                            input {
                                width: 100%;
                                height: 35px;
                                position: relative;
                                z-index: 99;
                                opacity: 0;
                                border-radius: 3px;
                                border: 1px solid #ddd !important;
                                box-shadow: none !important;
                                cursor: pointer;
                            }

                            span {
                                background: #e9ecef;
                                padding: 0 25px;
                                display: inline-block;
                                height: 35px;
                                line-height: 35px;
                                position: absolute;
                                top: 0;
                                right: 0;
                                z-index: 9;
                                width: 100%;
                                cursor: pointer;
                                color: #333;

                                &:hover {
                                    background-color: #fff;
                                }
                            }

                            &.dk-taskdownloadfile {
                                .dk-taskdownload-icon {
                                    width: 100%;
                                    text-align: center;
                                }
                            }
                        }

                        .dk-subjectFrmControl {
                            border-radius: 3px;
                            height: 35px;
                            padding: 0 10px !important;
                            min-height: 35px;
                            background-color: #e9ecef;
                            line-height: 35px;
                            border: 1px solid #ddd;
                            text-align: left;

                            &:focus {
                                outline: 0;
                                box-shadow: none;
                            }
                        }

                        &.dk-meeting-date-timelabel {
                            text-align: center;
                        }

                        .MuiInputAdornment-positionEnd {
                            margin-left: 0;
                            justify-content: center;
                            width: 100%;
                            max-width: 38px;
                        }
                    }

                    input.dk-tasktype-input {
                        border: 1px solid #c1c9dc;
                        text-transform: uppercase;
                        font-size: 13px;
                    }

                    .dk-nativedatepicker-grid {
                        .dk-nativedatepicker {
                            margin: 0;
                            padding: 0;
                            border: 1px solid #c1c9dc;
                            border-top-left-radius: 3px;
                            border-bottom-left-radius: 3px;

                            .MuiInputAdornment-root {
                                margin-left: 0;
                                width: 100%;
                                max-width: 35px;
                                background: #e9ecef;
                                min-height: 33px;
                                display: flex;
                                justify-content: center;
                            }

                            .MuiInputBase-root {
                                .MuiInputAdornment-root {
                                    .MuiIconButton-root {
                                        padding: 0;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }

                    .dk-nativetimeepicker-grid {
                        .dk-nativetimeepicker {
                            margin: 0;
                            padding: 0;
                            border: 1px solid #c1c9dc;
                            border-top-right-radius: 3px;
                            border-bottom-right-radius: 3px;

                            .MuiInputAdornment-root {
                                margin-left: 0;
                                width: 100%;
                                max-width: 35px;
                                background: #e9ecef;
                                min-height: 33px;
                                display: flex;
                                justify-content: center;
                            }

                            .MuiInputBase-root {
                                .MuiInputAdornment-root {
                                    .MuiIconButton-root {
                                        padding: 0;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }

                    .MuiFormControl-root {
                        padding: 0 6px;
                        width: 100%;

                        .MuiInputBase-root {
                            &.MuiInput-underline:before {
                                border: none !important;
                                height: 33px;
                                line-height: 0.81rem;
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                                border-right: none !important;
                            }

                            &.MuiInput-underline:after {
                                border: none !important;
                                height: 33px;
                                line-height: 0.81rem;
                            }

                            input {
                                color: #333;
                                height: 25px;
                                text-align: center;
                                padding: 4px 0;
                                font-size: 13px;
                                background-color: #e9ecef;

                                &::-webkit-calendar-picker-indicator {
                                    margin-left: 0px;
                                }

                                &[type="date"]::-webkit-inner-spin-button,
                                &[type="date"]::-webkit-calendar-picker-indicator,
                                &[type="time"]::-webkit-inner-spin-button,
                                &[type="time"]::-webkit-calendar-picker-indicator {
                                    // display: none;
                                    // -webkit-appearance: none;
                                    color: #333;
                                }

                                .MuiInputAdornment-positionEnd {
                                    margin-left: 0;
                                    justify-content: center;
                                    width: 100%;
                                    max-width: 38px;
                                }
                            }

                            .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                .MuiButtonBase-root.MuiIconButton-root {
                                    padding: 0.23rem;
                                }
                            }
                        }
                    }

                    & textarea.form-control {
                        background: #e9ecef;
                        border: 1px solid #c1c9dc !important;
                        border-radius: 3px;
                        color: #616161;
                        font-family: Roboto, sans-serif;
                        font-size: 14px;
                        min-height: 125px;
                        padding: 5px 10px;
                        text-align: left;

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }

                        &::-webkit-input-placeholder {
                            color: #333;
                        }

                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: #333;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: #333;
                        }

                        &:-ms-input-placeholder {
                            color: #333;
                        }

                        &::placeholder {
                            color: #333;
                        }
                    }
                }

                .form-group.dk-createNewTaskFooter {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .dk-create-new-taskbtn {
                        background-color: #26597d;
                        border: none;
                        border-radius: 2px;
                        display: inline-block;
                        height: 36px;
                        line-height: 36px;
                        padding: 0 2rem;
                        text-transform: uppercase;
                        vertical-align: middle;
                        -webkit-tap-highlight-color: #ddd;
                        font-size: 1rem;
                        outline: 0;
                        text-decoration: none;
                        color: #fff;
                        text-align: center;
                        letter-spacing: .5px;
                        transition: .2s ease-out;
                        margin-top: 0;
                        font-weight: 600;
                    }
                }

                .form-group.dkg-attachment-fileFrmGrp {
                    margin-top: 1px;
                    border: 1px solid #ddd;
                    padding: 6px 10px;
                    border-radius: 3px;
                    background-color: #f2f2f2;
                    white-space: nowrap;

                    .dkgfileName {
                        color: #333;
                    }
                }

                .form-group.dkg-tsk-cat-pri-frmgrp {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.25rem;

                    label {
                        white-space: nowrap;
                        font-size: 13px;
                        margin-bottom: 0;
                        color: #575050;
                        text-align: left;
                        width: 120px;
                    }

                    input {
                        &::-webkit-input-placeholder {
                            /* Edge */
                            color: #fff;
                        }

                        &:-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: #fff;
                        }

                        &::placeholder {
                            color: #fff;
                        }
                    }
                }

                // .form-group.dk-create-date-timeFrmGrp {

                //     .MuiGrid-root.dk-nativedatepicker-grid.MuiGrid-container,
                //     .MuiGrid-root.dk-nativetimeepicker-grid.MuiGrid-container {
                //         background-color: #fff;
                //     }
                // }

                .form-group.dk-wrp-sub-titleGrmGrp {
                    margin-bottom: 0.5rem;

                    .input-group-prepend {
                        .input-group-text {
                            border: 1px solid #ddd;
                            background-color: #eaeaea;
                            color: #333;
                        }
                    }

                    .form-control.dk-subjectFrmControl {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                        border: 1px solid #ddd;
                        text-align: left;
                        height: 34px;
                        background-color: #fff;

                        &::placeholder {
                            color: #333;
                        }

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }
                    }
                }

                .form-group.dk-wrp-readtextAreaFrmGrp {
                    textarea {
                        text-align: left;
                        border: 1px solid #ddd;
                        border-radius: 3px;
                        background-color: #fff;

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }

                        &::placeholder {
                            color: #333;
                        }
                    }
                }

                .form-group.dkg-tsk-cat-frmgrp-12 {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .dk-cre-newtask-label-center {
                        text-align: center;
                        max-width: 100px;
                        min-width: 100px;
                        margin-bottom: 2px;
                        color: #737373;
                        font-size: 13px;
                    }

                    .dk-task-category-dropdown.dropdown {
                        width: 100%;
                    }

                    .dkg-attachment-fileCon {
                        background-color: #fff;
                        border: 1px solid #ddd;
                        border-radius: 3px;
                        margin-top: 1px;
                        padding: 6px 10px;
                        white-space: nowrap;
                        width: 100%;
                        position: relative;

                        .dkg-attahment-fileicon {
                            position: absolute;
                            right: 10px;

                            i {
                                color: #0000008a;
                            }
                        }
                    }
                }

            }
        }
    }
}