.dk-cliDetVPageCon {
    margin-top: 120px;

    .dk-clientDetViewTopSec::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for  */
    }

    .dk-clientDetViewTopSec {
        height: calc(100vh - 175px);
        overflow: auto;
        overflow-y: auto;
        overflow-x: hidden;

        .dk-cliDetViewPagN {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            top: -35px;

            .dk-clientsid-con {
                display: none;

                a {
                    color: #000000ab;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-bottom: 0;
                list-style: none;

                li.dk-detListHover a {
                    font-size: 1rem;
                    width: 2rem;
                    min-width: 2rem;
                    max-width: 2rem;
                    height: 2rem;
                    min-height: 2rem;
                    max-height: 2rem;
                    padding: 0.312rem 0.5rem;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #000000ab;
                }

                li.dk-detListHover a:hover {
                    background-color: #245884;
                    border-color: #245884;
                    color: #fff;
                }

                & li a,
                & ul li a:hover {
                    color: #000000ab;
                }

                & li a.dk-NoCounttext,
                & li a.dk-NoCounttext:hover {
                    font-size: 1rem;
                    font-weight: 700;
                    padding: 0 5px;
                }
            }
        }

        .dk-cliDetilFormCon {
            background: #fff;
            border-radius: 5px;
            padding: 15px;
            min-height: 190px;

            .form-group.dk-Candinate-fromGroup {
                margin-bottom: 0.5rem;

                label {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    margin-bottom: 0;
                    font-size: 12px;
                    padding-right: 20px;
                    color: #000;
                    white-space: nowrap;
                    min-width: 100px;
                    line-height: 25px;
                }

                label.dk-smalllabel {
                    min-width: 80px;

                    &.dkg-dv-rightlabel {
                        min-width: 65px;
                    }
                }
                .dk-cliDorpDwon {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    width: 100%;
                    .dropdown{
                        width: 100%;
                    }
                    .dropdown button {
                        padding: 0;
                        font-size: 11px;
                        color: #fff !important;
                        background-color: #31a886 !important;
                        margin: 0;
                        box-shadow: none;
                        width: 100%;
                        text-transform: capitalize;
                        .statusDiv {
                            display: inline-block;
                            width: 100%;
                            min-width: 120px;
                            width: 100%;
                            height: 27px;
                            padding: 4px 6px;
                            font-size: 12px;
                            text-transform: capitalize;
                        }
                    }
    
                    .dropdown .dropdown-menu {
                        border-radius: 0;
                        padding: 0;
    
                        .dropdown-item.dkActiveItem {
                            background-color: #31a886 !important;
                            text-transform: uppercase;
                            color: #fff;
                            width: 100%;
                            text-align: center;
                        }
    
                        .dropdown-item.dkInactiveItem {
                            background-color: #e2445c;
                            text-transform: uppercase;
                            color: #fff;
                            width: 100%;
                            text-align: center;
                        }
                    }
    
                    .dropdown button.dropdown-toggle::after {
                        display: none;
    
                    }
                }
                .dk-nativedatepicker-grid {
                    .dk-nativedatepicker {
                        margin-top: 0;
                        width: 100%;
                        margin-bottom: 0;
                        background: #fff;
                        border: 1px solid #ddd !important;
                        padding: 0;

                        .MuiInput-underline {
                            &::before {
                                display: none;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .MuiInputBase-root.MuiInput-root {
                            input {
                                text-align: center;
                                font-size: 13px;
                            }

                            .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                .MuiButtonBase-root.MuiIconButton-root {
                                    padding: 4px 2px;
                                    background-color: #139bd6;
                                    border-radius: 0;
                                    color: #fff;
                                }
                            }
                        }

                    }
                }

                input.form-control {
                    width: 100%;
                    height: 27px;
                    padding: 4px 6px;
                    font-size: 12px;
                    line-height: 1.42857143;
                    color: #333 !important;
                    border: 1px solid #ddd;
                    background: #eaeaea;
                    outline: 0;
                    border-radius: 0;
                    text-align: center;

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #333;
                        opacity: 1;
                        /* Firefox */
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: #333;
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: #333;
                    }

                    &.dkg-input-left {
                        text-align: left;
                    }
                }

                .ddl-main.dropdown {

                    .ddl-btn.dropdown-toggle {
                        min-height: 27px !important;
                        max-height: 27px !important;
                        height: 27px !important;
                        line-height: 12px !important;
                        text-transform: capitalize;
                        background-color: #eaeaea;
                    }
                }

                .dk-clViewAddonIcon {
                    border: 1px solid #139BD6;
                    padding: 0 0.293rem;
                    background-color: #139BD6;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 25px;
                    min-width: 25px;
                    max-width: 25px;

                    & i {
                        color: #fff;
                        cursor: pointer;
                    }
                }

                select.form-control {
                    appearance: none;
                }

                select.form-control {
                    width: 100%;
                    height: 26px;
                    padding: 4px 6px;
                    font-size: 12px;
                    line-height: 1.42857143;
                    color: #333 !important;
                    border: 1px solid #ced4da;
                    background: #fff;
                    border-top-color: #ced4da;
                    outline: 0;
                    border-radius: 0;
                    text-align: left;
                }
            }
            
        }
        .dk-cliDetViewCntreCon {
            min-height: 227px;
            max-height: 227px;
            background-color: #fff;
            border-radius: 5px;
            padding: 10px 15px 5px 15px;

            .dk-cliRecImgPopMainCon {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    min-height: 32px;
                    border-radius: 50%;
                    height: 55px;
                    width: 55px;
                }

                .dk-selectRecDropDwn {
                    .dropdown {
                        a {
                            .tooltipMain {
                                .dkreCImg {
                                    width: 54px;
                                    height: 54px;
                                }
                            }
                        }

                        .dk-candDropDwnMenu {
                            border: 1px solid #ddd;
                            border-radius: 0;
                            left: 22px !important;
                            max-height: 220px;
                            min-width: 180px;
                            overflow-y: auto;
                            padding: 0;
                            position: absolute !important;
                            right: inherit !important;
                            top: 54px !important;
                            -webkit-transform: inherit !important;
                            transform: inherit !important;

                            ul {
                                a {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding: 4px 9px;
                                    border-bottom: 1px solid #ddd;
                                    font-size: 13px;

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    .ImgCricleCon {
                                        .dkreCItemImg {
                                            width: 32px;
                                            height: 32px;
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }

            .dk-cliDorpDwon {
                display: flex;
                justify-content: center;
                margin-top: 15px;

                .dropdown button {
                    padding: 0;
                    font-size: 11px;
                    color: #fff !important;
                    background-color: #31a886 !important;
                    margin-top: 6px;

                    .statusDiv {
                        display: inline-block;
                        width: 100%;
                        padding: 7px 9px;
                        min-width: 120px;
                        padding: 0.54rem 0.3rem;
                    }
                }

                .dropdown .dropdown-menu {
                    border-radius: 0;
                    padding: 0;

                    .dropdown-item.dkActiveItem {
                        background-color: #31a886 !important;
                        text-transform: uppercase;
                        color: #fff;
                        width: 100%;
                        text-align: center;
                    }

                    .dropdown-item.dkInactiveItem {
                        background-color: #e2445c;
                        text-transform: uppercase;
                        color: #fff;
                        width: 100%;
                        text-align: center;
                    }
                }

                .dropdown button.dropdown-toggle::after {
                    display: none;

                }
            }
            .dk-ClientimgUpload{
                margin-top: 10px;
            }
            .dk-ClientimgUpload .dk-recuploadImg {
                width: 100%;
                position: relative;
                overflow: hidden;
                border-radius: 5px;

                input {
                    width: 100%;
                    height: 80px;
                    position: relative;
                    z-index: 9;
                    opacity: 0;
                    cursor: pointer;
                }

                span {
                    position: absolute;
                    top: 15px;
                    left: 0;
                    cursor: pointer;
                    width: 100%;
                    text-align: center;

                    img {
                        width: 100%;
                        height: 55px;
                        padding: 0 7px;
                    }
                }
            }
            .dkg-clientsocail-media{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;   
                padding-top: 30px;
                .dkg-socail-media-item{
                    width: 100%;
                    text-align: center;
                    // border: 1px solid #ddd;
                    cursor: pointer;
                    .dkg-socail-media-img{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        .dk-DetViewBottomSecCon {
            background: #D9DFE7;
            border-radius: 5px;
            padding-bottom: 2.1rem;

            .dk-ClieDetViewSideMenu .nav.nav-pills {
                width: 100%;
                max-width: 95%;
                margin-bottom: 0;
                padding-left: .5rem;
                height: 360px;

                .nav-item .nav-link {
                    background-color: #037DB2;
                    color: #fff;
                    padding: 0.5rem 1rem;
                    border-radius: 0;
                    border-bottom: none;
                    font-size: 0.95rem;
                    height: 38px;
                    line-height: 24px;

                }

                .nav-item .nav-link.active,
                & .nav-item .show>.nav-link {
                    color: #333;
                    background-color: #D9DFE7;
                    border-color: #D9DFE7 #D9DFE7;
                }

                & .nav-item:first-child .nav-link {
                    border-top-left-radius: 5px;
                }
            }

            .dk-ClieDetViewMainCont .dk-ClentViewTabPane {
                padding: 15px;
                padding-bottom: 0;
                // padding-right: 5px;

                .dkg-edit-jobfiles-mainCon {

                    .dkg-jobfiles-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 5px;

                        .dkg-leftStaffnHead {
                            align-items: center;
                            display: flex;

                            .dk-searchCols {
                                margin-right: 8px;
                                max-width: 160px;
                                position: relative;
                                width: 100%;

                                input {
                                    background-color: #fff;
                                    border: 1px solid #ddd;
                                    color: #818181;
                                    height: 32px;
                                    padding: 0 12px;
                                    text-align: left;
                                }

                                button {
                                    background-color: #037DB2;
                                    border: 1px solid #818181;
                                    color: #818181;
                                    display: inline-block;
                                    font-size: 13px;
                                    height: 32px;
                                    outline: none;
                                    position: absolute;
                                    right: 0;
                                    text-align: center;
                                    top: 0;
                                    width: 32px;

                                    svg {
                                        color: #fff;
                                    }
                                }
                            }

                        }
                    }
                }

                .dkclietopLeft {
                    a.dk-topTbleIcon {
                        background-color: #fff;
                        color: #333;
                        padding: 0.1rem 0.5rem;
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        border: 1px solid #ccc;
                    }
                }

                &.dkg-clientlist-faqtabpane {
                    .dkg-payment-faq-pageCon {
                        margin-top: 0;
                        padding: 8px;
                        .dkg-candprescreen-mainRow {
                            background-color: transparent;
                            border-top: none;
                            margin: 0;

                            .col-md-10.dkg-cand-prescreen-centre-tabCon {
                                flex: 0 0 100% !important;
                                max-width: 100% !important;
                            }
                            .dkg-clientlist-faq-mainCon{
                                height: calc(100vh - 115px);
                                overflow-y: auto;
                                overflow-x: hidden;
                                .dkg-payment-faq-tab-mainCon{
                                    .dkg-payment-faq-tabCon{
                                        &.dkg-faq-sideMenuCon-234{
                                            .dkg-payment-faq-tab-navpills{
                                                color: inherit !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .dkclietopLeft .input-group {
                    max-width: 140px;

                    input.form-control {
                        background-color: #fff;
                        border: 1px solid #ccc !important;
                        border-right: 0 !important;
                        height: 30px;
                        padding: 0.24rem;
                        font-size: 11px;
                        max-width: 140px;
                        border-radius: 0;
                    }

                    input.form-control:focus {
                        outline: 0;
                        box-shadow: none;
                    }

                    .input-group-append {
                        margin-left: 0;

                        .btn.btn-secondary {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            margin: 0;
                            padding: 0 0.45rem;
                            box-shadow: none;
                            height: 30px;
                            border: 1px solid #ccc;
                            border-left: 00;
                            background-color: #fff !important;

                            i {
                                color: grey;
                            }
                        }
                    }
                }

                .dk-cliDetTabPaneBlkCon .dk-cliDetTitle h2 {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 600;
                    margin-top: 0;
                    margin-bottom: 2px;
                    position: relative;

                    .dv-detail-plusicon-con {

                        // position: absolute;
                        // right: 5px;
                        // top: 0;
                        a {
                            color: #333;

                            &:hover {
                                color: #037db2;
                            }
                        }
                    }
                }

                .dk-cliDetTabPaneBlkCon .dk-cliDeteContent {
                    padding: 15px;
                    border: 1px solid #ddd;
                    background-color: #fff;
                    border-radius: 5px;
                    // min-height: 270px;
                    // max-height: 270px;
                    height: 312px;
                    overflow-y: auto;

                    div {
                        padding: 0 15px;
                    }
                }

                .dk-CliContactRight {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    a.dkHringAddBtn {
                        display: inline-block;
                        border: 1px solid #ccc;
                        background-color: #fff;
                        color: #333;
                        outline: 0;
                        width: 100%;
                        max-width: 110px;
                        text-align: center;
                        padding: 3px 0;
                        height: 30px;
                    }

                    a.dkHringAddBtn:hover {
                        display: inline-block;
                        border: 1px solid #139BD6;
                        background-color: #139BD6;
                        color: #fff;
                        outline: 0;
                        width: 100%;
                        max-width: 110px;
                        text-align: center;
                        padding: 3px 0;
                        height: 30px;
                    }

                    .dkclietopRight {
                        a.dk-topTbleIcon {
                            background-color: #fff;
                            color: #333;
                            padding: 0.1rem 0.5rem;
                            font-size: 13px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 30px;
                            border: 1px solid #ccc;
                        }
                    }

                    .dk-CliDetSlectFilter .ui.selection.dropdown {
                        line-height: 1.128rem;
                        border-radius: 0;
                        min-height: 0.85rem;
                        padding: 0.485rem 0.75rem;
                        max-width: 130px;
                        min-width: 130px;
                        border: 1px solid #ccc;
                    }

                    .dk-CliDetSlectFilter .ui.selection.dropdown>.delete.icon,
                    .dk-CliDetSlectFilter .ui.selection.dropdown>.dropdown.icon,
                    .dk-CliDetSlectFilter .ui.selection.dropdown>.search.icon {
                        top: .528571429em;
                    }
                }

                .dk-cliDetailTableCon {
                    table.dk-cliDetialTable {
                        thead tr th {
                            border-bottom: none;
                            padding: 8px 18px;
                            background: #037DB2;
                            color: #fff;
                            text-align: center;
                            vertical-align: middle;
                            font-size: 0.9rem;
                        }

                        tbody tr td {
                            padding: 3px 18px !important;
                            white-space: nowrap;
                            background-color: #f9f9f9;
                            font-weight: 400;
                            text-align: center;
                            vertical-align: middle;
                            font-size: 0.85rem;

                            .dk-cliAgreDocType i {
                                font-size: 26px;
                            }

                            .dk-CliDetailAction {
                                display: -webkit-flex;
                                display: flex;
                                -webkit-justify-content: center;
                                justify-content: center;

                                a.dk-view-btn,
                                a.dk-view-btn :hover {
                                    display: -webkit-flex;
                                    display: flex;
                                    -webkit-justify-content: center;
                                    justify-content: center;
                                    -webkit-align-items: center;
                                    align-items: center;
                                    width: 30px;
                                    height: 30px;
                                    line-height: 30px;
                                    font-size: 16px;
                                    border-radius: 3px;
                                    background-color: #2196f3;
                                    color: #fff;
                                }

                                a.dk-edit-btn,
                                a.dk-edit-btn:hover {
                                    background: #ccc;
                                    background-color: #fee69c;
                                    display: -webkit-flex;
                                    display: flex;
                                    -webkit-justify-content: center;
                                    justify-content: center;
                                    -webkit-align-items: center;
                                    align-items: center;
                                    width: 30px;
                                    height: 30px;
                                    line-height: 30px;
                                    font-size: 16px;
                                    border-radius: 3px;
                                }

                                a.dk-delete-btn,
                                a.dk-delete-btn:hover {
                                    display: -webkit-flex;
                                    display: flex;
                                    -webkit-justify-content: center;
                                    justify-content: center;
                                    -webkit-align-items: center;
                                    align-items: center;
                                    width: 30px;
                                    height: 30px;
                                    background: #ccc;
                                    line-height: 30px;
                                    font-size: 16px;
                                    border-radius: 3px;
                                    background-color: #dc3545;
                                    color: #fff;
                                }
                            }

                            .dk-cliDorpDwon .dropdown {
                                .dropdown-toggle::after {
                                    display: none;
                                }

                                button {
                                    padding: 0.42rem 0.3rem;
                                    font-size: 11px;
                                    color: #fff !important;
                                    background-color: #31a886 !important;
                                    margin-top: 2px;
                                    min-width: 75px;
                                }

                                .dropdown-menu {
                                    border-radius: 0;
                                    padding: 0;

                                    .dropdown-item.dkActiveItem {
                                        background-color: #009a9a;
                                        text-transform: uppercase;
                                        color: #fff;
                                        width: 100%;
                                        text-align: center;
                                    }

                                    .dropdown-item.dkInactiveItem {
                                        background-color: #e2445c;
                                        text-transform: uppercase;
                                        color: #fff;
                                        width: 100%;
                                        text-align: center;
                                    }
                                }
                            }

                            .dk-inputHlder input[type="text"] {
                                background-color: #f9f9f9 !important;
                                width: 80px;
                                border: none;
                                text-align: center;
                            }
                        }
                    }
                }

                .dk-cliDetailTableCon.dk-perHireFreeTableCon {
                    overflow-y: auto;
                    height: 320px;
                    max-height: 285px;
                    padding-bottom: 0px;
                }

                .dk-cliDetViwpagination {
                    display: flex;
                    justify-content: space-between;

                    .dktotalNumberCounter h5 {
                        margin: 2px 0;
                        color: #7d6e6e;
                        font-size: 1.12rem;
                    }

                    .dk-cliInvociePag nav {
                        margin: 5px 0;

                        ul {
                            margin-bottom: 0;

                            li {
                                background-color: #eeeeee;

                                a {
                                    padding: 0.35rem 0.95rem;
                                    font-size: 0.95rem;

                                }
                            }
                        }
                    }
                }

                .dkcli-socialMCon {
                    min-height: 311px;
                    max-height: 311px;

                    .dk-cliSocailMAddonIcon {
                        border: 1px solid #ddd;
                        padding: 0 0.75rem;
                        background-color: #fff;
                        display: flex;
                        align-items: center;
                        text-align: center;

                        i {
                            color: #fff;
                            cursor: pointer;
                        }

                        .dkg-social-img {
                            width: 22px;
                            height: 22px;
                            cursor: pointer;
                        }
                    }

                    input.form-control {
                        width: 100%;
                        height: 36px;
                        padding: 4px 6px;
                        font-size: 12px;
                        line-height: 1.42857143;
                        color: #737577 !important;
                        border: 1px solid #ced4da;
                        background: #fff;
                        outline: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0;
                    }

                    .dk-cliSocailMAddonBtn button {
                        border: 1px solid #139BD6;
                        background-color: #139BD6;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        height: 36px;
                        color: #fff;
                        padding: 3px 7px;
                    }
                }

                .dk-socailMrightCon {
                    background-color: #fff;
                    border-radius: 5px;
                    border: 1px solid #ddd;
                    max-height: 287px;
                    min-height: 287px;

                    .dk-socailHeader h2 {
                        font-size: 18px;
                        text-transform: uppercase;
                        border-bottom: 1px solid #ddd;
                        padding: 7px 15px;
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 9px;
                        position: relative;
                    }

                    & a.dk-topTbleIcon {
                        position: absolute;
                        right: 25px;
                        top: 7px;
                        background-color: #139BD6;
                        color: #fff;
                        padding: 0.1rem 0.5rem;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 25px;
                    }

                    .dk-socailConten p {
                        font-size: 0.95rem;
                        font-weight: normal;
                        text-align: justify;
                        padding: 5px 15px;
                    }
                }

                .dkCliInterLeftCon {
                    .form-group.dk-CliInterViewSelect.d-flex.w-100 {
                        width: 95% !important;
                        margin-bottom: 1.35rem;

                        select.form-control {
                            width: 100%;
                            height: 36.7px;
                            padding: 4px 6px;
                            font-size: 14px;
                            line-height: 1.42857143;
                            color: #737577 !important;
                            border: 1px solid #ced4da;
                            background: #fff;
                            outline: 0;
                            border-radius: 0;
                        }
                    }

                    .dkInterviewH h2 {
                        font-size: 18px;
                        font-weight: 600;
                        margin: 0;
                        padding: 0;
                        text-transform: uppercase;
                        text-align: center;
                    }
                }

                .dk-InterviewRightCon {
                    .dk-RightTophder h2.topTitle {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 18px;
                        margin: 0;
                        padding: 0;
                        font-weight: 600;
                        position: relative;
                        text-transform: uppercase;
                    }

                    .dk-RightTophder a,
                    a.dkHringAddBtn {
                        display: inline-block;
                        border: 1px solid #139BD6;
                        background-color: #139BD6;
                        color: #fff;
                        padding: 4px 0;
                        border-radius: 3px;
                        outline: 0;
                        width: 100%;
                        max-width: 110px;
                        text-align: center;
                    }

                    & .dk-RightTophder {
                        position: relative;
                    }

                    & .dk-RightTophder a {
                        position: absolute;
                        right: 0;
                        top: -9px;
                    }

                    .dk-RightTophder a:hover {
                        background: #f2f2f2;
                        border: 1px solid #ccc;
                        color: #333;
                    }

                    .dk-InterviewRightContent {
                        padding: 15px;
                        background-color: #fff;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        height: 260px;
                    }
                }

                .dkg-projecttab-container {
                    background-color: #eaeaea;

                    .dkg-project-tab {
                        .dkg-project-cols {
                            &.cols_2 {
                                // margin-right: 12px;
                                max-width: 33.33%;
                                width: 100%;

                                &.first_cols,
                                &.second_cols {
                                    border-right: 1px solid #ddd;
                                    max-width: 28%;
                                }

                                &.thrid_cols {
                                    max-width: 44%;
                                }

                                .dkg-project-header {
                                    // background: #eaeaea;
                                    background-color: transparent;
                                    font-weight: 400;
                                    padding: 7px 15px;

                                    i {
                                        cursor: pointer;
                                    }

                                    .title {
                                        color: #000;
                                        font-size: 14px;
                                        font-weight: 400;
                                        text-align: center;
                                        width: 100%;
                                    }

                                    .dk-smsTableCheckbox {
                                        input[type="checkbox"] {
                                            cursor: pointer;
                                            appearance: none;
                                            background: #f2f2f2;
                                            border-radius: 0;
                                            box-sizing: border-box;
                                            position: relative;
                                            box-sizing: content-box;
                                            width: 12px;
                                            height: 12px;
                                            border-width: 0;
                                            transition: all 0.3s linear;
                                            border: solid #ccc 1px;
                                        }
                                    }
                                }


                                .dkg-projecttab-Sec {
                                    ul {
                                        background-color: #f2f2f2;
                                        list-style: none;
                                        margin: 0;
                                        height: calc(100vh - 413px);
                                        padding: 0;

                                        p {
                                            margin-bottom: 0;
                                            font-size: 13px;
                                            text-align: left;
                                            padding: 15px;
                                        }

                                        textarea,
                                        input.dkg-input-project-desc {
                                            background-color: #fff;
                                            // height: calc(100vh - 398px);
                                            height: calc(5.5em + 1.75rem + 2px);
                                            width: 100%;
                                            border: none;
                                            padding: 5px 15px;
                                            font-size: 13px;
                                            overflow-y: auto;
                                            text-align: left;

                                            &:focus {
                                                outline: 0;
                                                box-shadow: none;
                                                border: none;
                                            }
                                        }

                                        li {
                                            font-size: 13px;
                                        }

                                        &.dkg-saveproject-cols {
                                            background-color: #fff;

                                            .dkg-savedprojectCon {
                                                background-color: #fff;
                                                padding: 40px 0;

                                                input {
                                                    background-color: #eaeaea;
                                                    width: 100%;
                                                    max-width: 60%;
                                                    margin: 0 auto;
                                                    border: 1px solid #dddd;


                                                }

                                                .dkg-saveproject-btnCon {
                                                    margin-top: 25px;

                                                    div {
                                                        div {
                                                            .dkg-saved-project-btn {
                                                                background-color: #037DB2;
                                                                border: 1px solid #037DB2;
                                                                border-radius: 0.1875rem;
                                                                color: #fff;
                                                                display: inline-block;
                                                                font-size: 13px;
                                                                line-height: 1.5385;
                                                                margin-left: 120px;
                                                                padding: 5px 12px;
                                                                text-align: center;
                                                                text-transform: uppercase;

                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                        }

                                        &.activeTab {
                                            background-color: #38607c;
                                            color: #333;
                                        }

                                        &.responbilityBullets {
                                            li {
                                                background: #eaeaea;
                                                border-bottom: 1px solid #ddd;
                                                cursor: pointer;
                                                font-weight: 400;
                                                list-style-type: none;
                                                min-height: 35px;
                                                padding: 6px 10px;
                                                position: relative;
                                                font-size: 13px;

                                                // &:before {
                                                //     content: "";
                                                //     display: inline-block;
                                                //     font-family: Font Awesome\ 5 Free;
                                                //     font-weight: 700;
                                                //     width: 22px;
                                                // }
                                                p {
                                                    margin-bottom: 0;
                                                    font-size: 13px;
                                                    text-align: left;
                                                    padding: 0 15px;
                                                }
                                            }
                                        }

                                        li {
                                            background-color: #fff;
                                            border-bottom: 1px solid #ddd;
                                            cursor: pointer;
                                            font-weight: 400;
                                            list-style-type: none;
                                            min-height: 35px;
                                            padding: 6px 10px;
                                            color: #333;

                                            &.activeTab {
                                                background-color: #38607c;
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .dkg-edit-jobfiles-mainCon {
                    .dkg-add-new-btnCon {
                        margin-bottom: 5px;

                        .dkg-jobfiles-header {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .dkg-leftStaffnHead {
                                align-items: center;
                                display: flex;

                                .dk-searchCols {
                                    margin-right: 8px;
                                    max-width: 160px;
                                    position: relative;
                                    width: 100%;

                                    input {
                                        background-color: #374252;
                                        border: 1px solid #818181;
                                        color: #818181;
                                        height: 32px;
                                        padding: 0 12px;
                                        text-align: left;
                                    }

                                    button {
                                        background-color: #374252;
                                        border: 1px solid #818181;
                                        color: #818181;
                                        display: inline-block;
                                        font-size: 13px;
                                        height: 32px;
                                        outline: none;
                                        position: absolute;
                                        right: 0;
                                        text-align: center;
                                        top: 0;
                                        width: 32px;
                                    }
                                }

                            }
                        }

                        .dkg-addnew-btn {
                            box-shadow: none;
                            border: 1px solid #ccc;
                            background-color: #fff;
                            color: #333;
                            font-size: 13px;
                            text-transform: capitalize;
                            padding: 7px 9px;
                            // min-width: 110px;
                            margin: 0;
                        }
                    }

                    .dkg-edit-jobfiles-tableCon {
                        height: calc(100vh - 150px);
                        overflow-y: auto;
                        padding-bottom: 0;
                        position: relative;

                        .dkg-edit-jobfiles-table {
                            thead {
                                tr {
                                    th {
                                        background: #037DB2;
                                        border: 1px solid gray;
                                        border-bottom: none;
                                        color: #fff;
                                        font-size: 13px;
                                        padding: 9px 7px;
                                        position: -webkit-sticky;
                                        position: sticky;
                                        text-align: center;
                                        top: 0;
                                        vertical-align: middle;
                                        white-space: nowrap;
                                        z-index: 9;
                                    }
                                }
                            }

                            tbody {

                                tr {
                                    td {
                                        background: #fff;
                                        border: 1px solid #ddd;
                                        color: #4d4a4a;
                                        font-size: 13px;
                                        font-weight: 400;
                                        padding: 9px 7px;
                                        position: relative;
                                        text-align: center;
                                        text-transform: capitalize;
                                        vertical-align: middle;
                                        white-space: nowrap;

                                        .dkg-viewfile-iconCon {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            .dkg-viewfile-icon {
                                                border-radius: 3px;
                                                font-size: 14px;
                                                height: 30px !important;
                                                line-height: 30px;
                                                width: 30px !important;
                                                background-color: #2196f3;
                                                color: #fff;
                                            }
                                        }

                                        .dkg-file-actions {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            .dkg-viewfile-icon {
                                                border-radius: 3px;
                                                font-size: 14px;
                                                height: 30px !important;
                                                line-height: 30px;
                                                width: 30px !important;
                                                background-color: #2196f3;
                                                color: #fff;
                                            }

                                            .dkg-download-icon {
                                                border-radius: 3px;
                                                font-size: 14px;
                                                height: 30px !important;
                                                line-height: 30px;
                                                width: 30px !important;
                                                background-color: #31a886;
                                                color: #fff;
                                            }

                                            .dkg-editfile-icon {
                                                border-radius: 3px;
                                                font-size: 14px;
                                                height: 30px;
                                                line-height: 30px;
                                                width: 30px;
                                                background-color: #fee69c;
                                                color: #333;
                                            }

                                            .dkg-deletefile-icon {
                                                border-radius: 3px;
                                                font-size: 14px;
                                                height: 30px;
                                                line-height: 30px;
                                                width: 30px;
                                                background-color: #dc3545;
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            cursor: pointer;
                        }

                    }

                    .dkg-edit-jobfiles-tableCon::-webkit-scrollbar {
                        cursor: pointer;
                    }
                    .dkg-file-type-filterCon{
                        display: flex;
                        justify-content: flex-end;
                        .dkg-file-typedropdown{
                            .dkg-typedropdown-toggle{
                                margin: 0;
                                padding: 0.5rem 0.45rem;
                                box-shadow: none;
                                background-color: #fff;
                                color: #333;
                                text-transform: capitalize;
                                min-width: 145px;
                                font-size: 13px;
                                &:after {
                                    display: block;
                                    position: absolute !important;
                                    color: #6c757d;
                                    position: absolute;
                                    right: 10px;
                                    top: 16px;
                                    font-size: 1.2rem
                                }
                            }
                            .dkg-typedropdown-Menu{
                                padding: 0;
                                border-radius: 0;
                                 width: 100%;
                                position: absolute !important;
                                right: 5px !important;
                                .dkg-typedropdown-item{
                                    padding: 8px 10px;
                                    font-size: 13px;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    background-color: #fff;
                                    border-bottom: 1px solid #ddd;
                                    &:hover{
                                        background-color: #f2f2f2;
                                    }
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                }

                            }
                        }

                    }
                }

                .dk-Adsbox.dkg-livejobs-adsboxRow {
                    .dkg-livejobs-adsboxCol {
                        .samplead.ad-description.dkg-livejobs-adsboxRow-234 {
                            .specsLeftTab.dkg-livejobs-adsboxleftSide {
                                background-color: #244158;
                                flex: 0 0 13.666667%;
                                max-width: 13.666667%;
                                padding-left: 0;
                                padding-right: 0;
                                margin-top: 14px;
                                height: calc(100vh - 250px);

                                .dkg-livejobs-adsbox-navpills {
                                    display: block !important;
                                    width: 100%;

                                    .nav-item {
                                        max-width: inherit;

                                        &:first-child {
                                            border-top: none;
                                        }

                                        .nav-link {
                                            background-color: #244158 !important;
                                            border-bottom: 1px solid #3e5b73 !important;
                                            color: #fff;
                                            text-align: left !important;
                                            border-radius: 0;

                                            &.active {
                                                background-color: #D9DFE7 !important;
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }

                            .col-md-10.col-sm-10.dkg-livejobs-adsboxRightSide.specsLeftTab {
                                flex: 0 0 85.333333%;
                                max-width: 85.333333%;
                            }
                        }
                    }
                }

                &.dkg-desc-tabpane-234 {
                    padding: 0;
                    margin-top: 30px;

                    .dkg-desc-sidmenu-tabCon {
                        .dkg-desc-sidmenu-tabRow {
                            .dkg-desc-sidmenu-Col-2 {
                                @media (min-width: 768px) {
                                    &.col-md-2 {
                                        flex: 0 0 13.666667%;
                                        max-width: 13.666667%;
                                    }
                                }

                                .dkg-desc-sidmenu-navpills {
                                    background-color: #244158;
                                    flex-wrap: nowrap;
                                    height: calc(100vh - 95px);
                                    margin-bottom: 0;
                                    overflow-y: auto;
                                    width: 100%;
                                    // margin-top: 15px;

                                    .dkg-desc-sidmenu-navitem {
                                        .dkg-desc-sidmenu-navlink {
                                            background-color: #244158;
                                            border-bottom: 1px solid #3e5b73;
                                            border-radius: 0;
                                            color: #ddd;
                                            display: block;
                                            font-family: Roboto, sans-serif !important;
                                            font-size: 14px;
                                            font-weight: 400;
                                            padding: 9px 7px;
                                            text-transform: inherit;
                                            width: 100%;

                                            &.active {
                                                background-color: #D9DFE7;
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }

                            .dkg-desc-sidmenu-Col-9 {
                                @media (min-width: 768px) {
                                    &.col-md-10 {
                                        flex: 0 0 86.333333%;
                                        max-width: 86.333333%;

                                    }
                                }

                                .dkg-desc-sidmenu-tabContent {
                                    .dkg-desc-sidmenu-tabpane {
                                        color: #333;
                                    }
                                }
                            }
                        }
                    }
                }

                &.dkg-sendouts-tabpane {
                    padding-top: 0;
                    background-color: #D9DFE7;
                    margin-top: 30px;

                    .dkg-livejobs-adsboxRow {
                        .dkg-livejobs-adsboxCol {
                            .samplead {
                                &.ad-description {
                                    &.dkg-livejobs-adsboxRow-234 {
                                        background-color: #D9DFE7;

                                        .specsLeftTab.dkg-livejobs-adsboxleftSide {
                                            margin-top: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.dkg-interview-tabpane {
                    background-color: #D9DFE7;
                    margin-top: 15px;

                    .dkg-livejobs-adsboxRow {
                        .dkg-livejobs-adsboxCol {
                            .samplead {
                                &.ad-description {
                                    &.dkg-livejobs-adsboxRow-234 {
                                        background-color: #D9DFE7;

                                        .specsLeftTab.dkg-livejobs-adsboxleftSide {
                                            margin-top: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// START MEDIA QUIRES
@media only screen and (min-width: 992px) {
    .col-md-2.col-sm-12.dk-ClieDetViewSideMenu {
        -ms-flex: 0 0 10.66667%;
        flex: 0 0 10.66667%;
        max-width: 10.66667%;

    }

    .col-md-10.col-sm-12.dk-ClieDetViewMainCont {
        -ms-flex: 0 0 89.33333%;
        flex: 0 0 89.33333%;
        max-width: 89.33333%;
    }

}

@media only screen and (min-width: 1200px) {
    .col-md-2.col-sm-12.dk-ClieDetViewSideMenu {
        -ms-flex: 0 0 10.66667%;
        flex: 0 0 10.66667%;
        max-width: 10.66667%;

    }

    .col-md-10.col-sm-12.dk-ClieDetViewMainCont {
        -ms-flex: 0 0 89.33333%;
        flex: 0 0 89.33333%;
        max-width: 89.33333%;
    }

}

// END MEDIA QUIRES