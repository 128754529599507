.dk-payTaskMain {
    margin-top: 120px;
    padding: 0 10px;
    height: calc(100vh - 175px);

    .dk-payBoxes-flex {
        display: flex;

        .dk-payboxCols {
            width: 100%;
            max-width: 16.66%;
            margin: 0 10px;

            .dk-payBox {
                width: 100%;
                background: #eaeaea;
                border: 1px solid #dadada;
                border-radius: 5px;

                &.h-lg {
                    height: calc(100vh - 175px);
                    overflow-y: hidden;

                    &.h-md-height {
                        height: calc(100vh - 400px);
                        overflow-y: auto;
                    }
                }

                .title {
                    background-color: #eaeaea;
                    border-bottom: 1px solid #dadada;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 12px;
                    color: #6c757d;
                    padding: 7px 10px;
                    border-radius: 5px 5px 0 0;
                }

                .dkg-paymentsuMM-ul {
                    overflow-y: auto;
                    margin-bottom: 0;
                    min-height: 155px;
                    max-height: 155px;

                    &.dkg-payment-bottom-ullist {
                        min-height: calc(100vh - 423px);
                        max-height: calc(100vh - 423px);
                    }

                    &.dkg-paymentsuMM-rightlistCon {
                        min-height: calc(100vh - 220px);
                        max-height: calc(100vh - 220px);
                    }

                    &::-webkit-scrollbar {
                        width: 10px;
                        cursor: pointer;
                    }

                    &::-webkit-scrollbar-track {
                        background: #F1F1F1;
                        cursor: pointer;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #ddd;
                        border-radius: 4px;
                        cursor: pointer;
                    }

                    li {
                        list-style-type: none;
                        width: 100%;
                        text-align: left;
                        // background: #F1F1F1;
                        border-bottom: none;
                        padding: 5px 9px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 13px;
                        cursor: pointer;

                        span.rightItem {
                            display: inline-block;
                            text-align: center;
                            color: #2c71b2;
                            font-size: 13px;
                        }

                        &:hover {
                            background-color: #234158;
                            color: #fff;
                            cursor: pointer;

                            span.rightItem {
                                color: #fff;
                            }
                        }

                        .recuriterItem {
                            img {
                                width: 26px;
                                height: 26px;
                                border-radius: 50%;
                                margin-right: 8px;
                            }
                        }

                        &.dkg-business-select-li {
                            background-color: #234158;
                            color: #fff;

                            span.rightItem {
                                color: #fff;
                            }
                        }
                    }

                    &.dkg-payment-priority-ullist {
                        li {
                            &:first-child {
                                color: #3a9469;

                                span.rightItem {
                                    color: #3a9469;
                                }
                            }

                            &:last-child {
                                color: #d91010;

                                span.rightItem {
                                    color: #d91010;
                                }
                            }
                        }
                    }
                    &.dkg-payment-stage-ullist{
                        li {
                            &:nth-child(01) {
                                color: #d58b5d;

                                span.rightItem {
                                    color: #d58b5d;
                                }
                            }
                            &:nth-child(02) {
                                color: #3a9469;

                                span.rightItem {
                                    color: #3a9469;
                                }
                            }
                            &:nth-child(03) {
                                color: #5c737d;

                                span.rightItem {
                                    color: #5c737d;
                                }
                            }
                            &:hover{
                                a{
                                    color: #fff !important;
                                    span{
                                        color: #fff !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dk-payBox.h-lg {
                .dk-payBox-panel {
                    height: 545px;
                }
            }

            .dk-payBox.h-sm {
                margin-bottom: 20px;

                // &.h-vsm {
                //     height: 30%;
                // }

                // &.h-sm-firstRow {
                //     height: 30.59%;
                //     overflow-y: auto;
                // }

                &:last-child {
                    margin-bottom: 0;
                }

                // .dk-payBox-panel {
                //     height: 245px;
                // }
            }
        }
    }
}