.dkg-commonTrainingMainPage {
    margin-top: 80px;

    .page-header {
        position: relative;
        padding: 42px 10px 16px;

        a.dk-goBackBtn {
            padding: 0;
            border: none;
            position: absolute;
            left: 17px;
            top: 13px;

            &:hover {
                background: transparent;
                color: #3c97b6;
            }
        }

        h3.title {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 500;
            margin: 0;
            text-align: center;
            color: #505050;

            img {
                width: 100%;
                max-width: 25px;
                margin-right: 10px;
            }
        }

        a {
            display: inline-block;
            border: 1px solid #ccc;
            padding: 7px 15px;
            border-radius: 5px;
            color: #333;

            &:hover {
                background: #244158;
                border-color: #244158;
                color: #fff;
                transition: all 0.5s;
            }
        }
    }

    .dkg-comman-trainingBoxMain {
        padding: 0 22px;
        overflow-y: auto;
        height: calc(100vh - 110px);

        .col-md-3 {
            flex: 20%;
            max-width: 20%;
            padding-left: 8px;
            padding-right: 8px;

            .dkg-os-training-box {

                &:hover {
                    figure {
                        img {
                            transform: scale(.7);
                            transition: all .4s ease-in-out;
                        }
                    }
                }

                a {
                    background: #eaeaea;
                    border: 1px solid #d8d8d8;
                    min-height: 105px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 16px;
                    border-radius: 5px;
                    color: #1a3445;
                    transform: scale(.9);
                    transition: all .4s ease-in-out;

                    figure {
                        margin: 0 !important;
                        min-height: auto;
                        display: none;

                        img {
                            transform: scale(.9);
                            transition: all .4s ease-in-out;
                            max-width: 48px;
                            max-height: 48px;
                        }
                    }

                    h3 {
                        font-size: 18px;
                        line-height: 20px;
                        margin: 0 8px;
                        font-weight: 500;
                        top: 0;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .dkg-comman-trainingBoxMain::-webkit-scrollbar {
        width: 0;
    }

    .dkg-comman-trainingBoxMain::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    }

    .dkg-comman-trainingBoxMain::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 0px solid slategrey;
    }



    // .dk-detailsMain {
    //     padding: 0 22px;
    //     padding-left: 0;
    //     margin-top: 12px;

    //     @media(max-width:992px) {
    //         padding: 0;
    //     }

    //     .d-md-flex {
    //         @media(max-width:992px) {
    //             flex-wrap: wrap;
    //         }
    //     }

    //     h3.title {
    //         font-size: 18px;
    //         text-transform: uppercase;
    //         font-weight: 600;
    //         margin: 0;
    //         text-align: center;
    //         position: relative;
    //         margin-bottom: 10px;
    //         color: #505050;
    //     }

    //     .dk-detailsLeftPanel {
    //         width: 100%;
    //         max-width: 200px;

    //         a.dk-backBtn {
    //             display: inline-block;
    //             border: 1px solid #ccc;
    //             padding: 7px 15px;
    //             border-radius: 0;
    //             color: #333;

    //             &:hover {
    //                 background: #244158;
    //                 border-color: #244158;
    //                 color: #fff;
    //                 transition: all 0.5s;
    //             }
    //         }

    //         ul {
    //             background: #244158;
    //             list-style: none;
    //             margin: 0;
    //             padding: 0;
    //             border-radius: 0 8px 5px 0;
    //             min-height: 430px;
    //             margin-top: 0;

    //             li {
    //                 list-style-type: none;
    //                 display: block;
    //                 width: 100%;

    //                 a {
    //                     display: block;
    //                     width: 100%;
    //                     color: #ddd;
    //                     font-size: 16px;
    //                     padding: 8px 15px;
    //                     font-weight: 400;
    //                     border-bottom: 1px solid #3e5b73;
    //                     font-family: "Roboto", sans-serif !important;
    //                     text-transform: capitalize;
    //                 }

    //                 a.active {
    //                     background: #eaeaea;
    //                     color: #333;
    //                 }

    //                 a.active:first-child {
    //                     border-radius: 0 5px 0 0;
    //                 }

    //                 &:last-child {
    //                     a {
    //                         border-bottom: none;
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     .dk-detailRightPanel {
    //         width: 100%;
    //         margin-left: 25px;

    //         .dk-detailTabsMain {
    //             background: #eaeaea;
    //             border: 1px solid #d8d8d8;
    //             border-radius: 5px;
    //             margin-top: 0;
    //             padding: 0;
    //             min-height: 428px;
    //             position: relative;

    //             h3.title {
    //                 position: absolute;
    //                 margin: 0;
    //                 left: 50%;
    //                 top: 17px;
    //                 transform: translate(-50%);
    //                 text-transform: capitalize;
    //             }

    //             .nav-tabs {
    //                 position: relative;
    //                 border: none !important;
    //                 justify-content: flex-end;
    //                 border-bottom: 1px solid #ddd !important;
    //                 padding: 12px 15px;

    //                 .nav-item {
    //                     box-shadow: none;
    //                     border: none;
    //                     color: #737373;
    //                     margin: 0 15px;
    //                     padding: 0;
    //                     border-bottom: 3px solid transparent;
    //                     font-family: "Roboto", sans-serif !important;
    //                     font-weight: 600;
    //                     font-size: 16px;
    //                     text-transform: capitalize;
    //                     cursor: pointer;
    //                 }

    //                 .nav-item.active {
    //                     background: transparent;
    //                     box-shadow: none;
    //                     border: none;
    //                     border-bottom: 3px solid #244158;
    //                     color: #244158;
    //                 }
    //             }

    //             .tab-content {
    //                 width: 100%;
    //                 padding: 15px;
    //             }
    //         }

    //     }
    // }
}