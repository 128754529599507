.dkg-livespec-mainCon{
    margin-top: 120px;
    height: calc(100vh - 120px);
    background-color: #eaeaea;
    width: 100%;
    padding: 0 15px;
    .dkg-livespec-main{
        width: 100%;
        display: block;
        position: relative;
        max-width: 1600px;
        margin: 0 auto;
        .dkg-livespec-mainRow{
            padding-top: 20px;
            .dkg-livespec-main-sideCol{
                .dkg-aboutus-navpills{
                    background-color: #244158;
                    display: block;
                    width: 100%;
                    max-width: 210px;
                    .dkg-aboutus-navitem{
                        .dkg-aboutus-navlink{
                            border-radius: 0;
                            background-color: #244158;
                            color: #fff;
                            border-bottom: 1px solid #aaa;
                            padding: 7px 10px;
                            &.active{
                                background-color: #ddd;
                                color: #333;
                                position: relative;
                                &:before {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-bottom: 10px solid transparent;
                                    border-top: 10px solid transparent;
                                    border-left: 12px solid #ddd;
                                    position: absolute;
                                    top: 7px;
                                    right: -11px;
                                }
                            }
                        }
                        &:last-child{
                            .dkg-aboutus-navlink{
                                &.active{
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
            .dkg-livespec-mainCol{
                .dkg-aboutus-tabcontent{
                    .dkg-aboutus-tabpane{
                        .dkg-aboutus-header{
                            border: 1px solid #ccc;
                            border-bottom: none;
                            padding: 9px 10px;
                            background-color: #ddd;
                            .dkg-livespec-title{
                                width: 100%;
                                text-align: center;
                                font-weight: 600;
                                font-size: 22px;
                                text-transform: uppercase;   
                                margin-bottom: 0;    
                                color: #333; 
                            }
                        }
                        .dkg-aboutus-mainCon{
                            background-color: #fff;
                            border: 1px solid #ccc;
                            height: calc(100vh - 270px);
                            .dkg-livespec-card{
                                background-color: #243b4c;
                                background-clip: border-box;
                                border-radius: 1px;
                                border: 1px solid #243b4c;
                                width: 100%;
                                max-width: 75%;
                                margin: 0 auto;
                                margin-top: 40px;
                                .dkg-livespec-cardRow{
                                    .dkg-livespec-card-leftCol{
                                        .dkg-livespec-card-detail{
                                            background: #fff;
                                            margin: 0 15% 0 -30%;
                                            border: 1px solid #ccc;
                                            padding: 60px 30px 70px;                            
                                            .dkg-card-detail-title{
                                                margin: 0 0 25px;
                                                font-size: 18px;
                                                color: #25455a;
                                                font-weight: 600;
                                            }
                                            .dkg-livespec-card-ul{
                                                margin: 0;
                                                padding: 0 0 0 0px;
                                                list-style: none;
                                                .dkg-livespec-card-li{
                                                    margin: 0;
                                                    padding: 0 0 20px 20px;
                                                    color: #25455a;
                                                    position: relative;
                                                    i{
                                                        position: absolute;
                                                        left: 0;
                                                        top: 5px
                                                    }
                                                    a{
                                                        color: #333;
                                                    }
                                                }
                                            }
                                            .dkg-livespec_social{
                                                padding-left: 22px;
                                                a{
                                                    padding-right: 8px;
                                                    img{
                                                        width: 35px;
                                                        height: 35px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .dkg-livespec-card-rightCol{
                                        color: #25455a;
                                        form{
                                            padding: 30px;
                                            .row{
                                                div{
                                                    h2{
                                                        margin: 0 0 15px;
                                                        font-size: 18px;
                                                        color: #fff;
                                                    }
                                                }
                                                .form-group{
                                                    margin-bottom: 1rem;
                                                    .form-control{
                                                        height: 34px;
                                                        padding: 0.1rem 0.5rem;
                                                        font-size: 14px;
                                                        background-color: #fff;
                                                        line-height: 1.3;
                                                        border: #fff;
                                                        text-align: left;
                                                    }
                                                    textarea.form-control {
                                                        height: 80px;
                                                        text-align: left;
                                                    }
                                                }
                                                
                                            }
                                            p{
                                                margin-bottom: 1rem;
                                                color: #ddd;
                                                font-size: 14px;
                                                input[type="checkbox"] {
                                                    cursor: pointer;
                                                    appearance: none;
                                                    background: #fff;
                                                    border-radius: 0.25rem;
                                                    box-sizing: border-box;
                                                    position: relative;
                                                    box-sizing: content-box;
                                                    width: 12px;
                                                    height: 12px;
                                                    border-width: 0;
                                                    transition: all 0.3s linear;
                                                    border: solid #ccc 1px;
                                                    margin-right: 0.5rem;
                                                }
                                                a{
                                                    color: rgb(93, 157, 226);
                                                    margin: 0 0.5rem;
                                                }
                                            }
                                            .btn-light {
                                                color: #212529;
                                                background-color: #f8f9fa;
                                                border-color: #f8f9fa;
                                                border: 1px solid transparent;
                                                padding: 0.375rem 0.75rem;
                                                font-size: 1rem;
                                                line-height: 1.5;
                                                border-radius: 0.25rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}