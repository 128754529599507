.dk-suMMCaseStatusDropDwn {
    width: 100%;

    .dropdown {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        color: #26597d;

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            right: 10px;
            top: 14px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: #adadad transparent transparent transparent;
            line-height: 0px;
            _border-color: #adadad #000000 #000000 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
        }

        .react-contextmenu-wrapper {
            display: inline-block;
            width: 100%;

            a {
                display: block;
            }
        }

        nav.react-contextmenu {
            display: inline-block;
            background: #fff;

            .react-contextmenu-item {
                display: block;
                padding: 5px 10px;
                font-size: 12px;
                border-bottom: 1px solid #ddd;
                background: #f2f2f2;
                cursor: pointer;

                svg {
                    color: red;
                    font-size: 14px;
                }

                &:hover {
                    color: red;
                    transition: all 0.5s;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        a {
            color: #fff;
            font-size: 12px;
            width: 100%;
            text-align: center;
            width: 100%;
            padding: 3px 11px;
            height: 35px;
            line-height: 27px;
            border-radius: 0;

        }

        .dk-progress-stageMenu {
            display: block;
            width: 100%;
            border-radius: 0;
            padding: 0;
            border-color: currentcolor #96c8da #96c8da;
            border-top: #96c8da;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);
            background: #26597d;
            margin-top: 0 !important;
            top: -2px !important;

            input.form-control {
                border-radius: 0;
                border: 1px dotted #ccc;
                font-size: 12px;
                max-width: 87%;
                margin: 0 auto;
                margin-top: 12px;
                margin-bottom: 4px;
                background: #efefef;
            }

            input.form-control:focus {
                outline: 0;
                box-shadow: none;
            }

            ul {
                margin-bottom: 0;

                .dk-progress-stageItem {
                    border-radius: 0;
                    border: none;
                    background-color: #fff;
                    color: #fff !important;
                    padding: 3px 15px;
                    text-align: left;
                    font-weight: 500;
                    font-size: 13px;
                    background: #26597d !important;
                    // &::after{
                    //     display: none !important;
                    // }
                }

                .dk-progress-stageItem:active,
                .dk-progress-stageItem:hover {
                    color: #007bff;
                    background-color: #e0e2e4;
                }
            }
        }

        .dk-Drop14Menu.dk-dropdown-menuCols.dkg-sendouts-kpi-dropdown-menu {
            width: 100%;
            border-radius: 0;
            padding: 0;

            input.form-control {
                border: none;
                border-bottom: 1px solid #ddd;
            }
        }
    }

}