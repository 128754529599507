.dk-stepBystepMain {
    margin-top: 0;
    padding: 0;
    padding: 0 15px;

    h3.title {
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
        display: block;
        color: #333;
        padding-top: 0;
    }

    .dk-stepColsMain {
        width: 100%;
        position: relative;
        margin-top: -99px;
    }

    .dk-stepCols {
        width: 100%;
        min-width: 225px;
        background: #5f7b86;

        &:last-child {
            .dk-stepGrayBox {
                &::after {
                    height: 100px;
                }
            }
        }

        .dk-stepText {
            text-align: center;
            padding: 22px 15px;
            position: relative;
            background: #fff;

            .dk-StepNumber.dk-nullText {
                top: 64px;
            }

            .dk-StepNumber {
                position: absolute;
                top: 73px;
                left: 0;
                width: 100%;
                text-align: center;
                color: #fff;
                z-index: 9;
                font-family: Segoe UI, Arial, sans-serif !important;
                font-weight: 600;
                font-size: 14px;
                text-transform: uppercase;
            }

            p {
                margin: 0;
            }

            p.title {
                font-family: Segoe UI, Arial, sans-serif !important;
                font-size: 16px;
                font-weight: 700;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:hover {
                    color: #2e97b6;
                    transition: all 0.5s;
                }

                span {
                    display: inline-block;
                    margin-right: 5px;

                    img {
                        width: 100%;
                        max-width: 25px;
                    }
                }
            }

            .dk-stepIcon {
                position: absolute;
                bottom: -40px;
                z-index: 99;
                color: #fff;
                left: 0;
                width: 100%;
            }

            .title {
                text-transform: uppercase;
                font-weight: 600;
                color: #234158;
            }

            p {
                font-size: 12px;
                line-height: 1.1rem;
            }
        }

        .dk-stepGrayBox {
            background: #5f7b86;
            min-height: 100px;
            position: relative;
            border-top: 30px solid #333;

            &::after {
                content: "";
                width: 3px;
                height: 100px;
                background: #333;
                position: absolute;
                top: -30px;
                right: -3px;
                z-index: 1;
            }

            .dk-StepNumber {
                text-align: center;
                padding: 10px 0;
                text-transform: uppercase;
                color: #fff;
                font-family: Segoe UI, Arial, sans-serif !important;
                font-weight: 600;
                font-size: 18px;

                small {
                    display: block;
                    width: 100%;
                }

                span {
                    width: 50px;
                    height: 50px;
                    border: 4px solid #fff;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    color: #fff;
                }

                .sbTitle {
                    display: block;
                    padding: 3px 0;
                }
            }
        }
    }

    .dk-stepCols.mt-80 {
        margin-top: 100px;
    }

    .dk-stepCols.mt-150 {
        margin-top: 200px;
    }

    .dk-stepCols.mt-220 {
        margin-top: 300px;
    }

    .dk-stepCols.mt-290 {
        margin-top: 400px;
    }

    .dk-stepDetailMain {
        position: fixed;
        right: 30px;
        top: 260px;
        width: 100%;
        max-width: 400px;
        z-index: 1;
        border: 1px solid #ccc;
        display: block;
        border-radius: 5px;

        .title {
            font-family: Segoe UI, Arial, sans-serif !important;
            font-size: 16px;
            font-weight: 700;
            background: #ccc;
            padding: 5px 12px;
            color: #333;
            text-align: center;
            border-radius: 5px 5px 0 0;
            position: relative;
        }

        .closePanel {
            display: inline-block;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 23px;
            background: transparent;
            color: #fff;
            font-size: 14px;
            outline: none;
            position: absolute;
            right: 6px;
            top: 4px;
            border: none;

            &:hover {
                color: #333;
            }
        }

        .dk-stepDetailCols {
            padding: 15px;
            text-align: left;
            font-family: Segoe UI, Arial, sans-serif !important;
            font-weight: 400;
            color: #333;
            background: #eaeaea;

            p {
                margin-bottom: 10px;
                font-size: 15px;
                line-height: 1.4rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

}

.dk-dkglobalTeam-summaryMain {
    margin-top: 0;
    width: 100%;

    .dk-rolesresponPageMain.dk-trainingTeamRolesMain.dk-trainingTeamRolesMainRecruitment {
        margin-top: 0;
    }

    .dk-rolesresponPageMain {
        .dk-pageheader {
            .title {
                position: relative;
                transform: inherit;
                left: inherit;
                top: inherit;
                display: block;
                width: 100%;
            }
        }
    }
}