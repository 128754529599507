.dkg-delelte-office365Modal {
    .modal-dialog {
        min-width: 30%;
        max-width: 30%;

        .modal-content {
            border: none;
            border-radius: 5px;
            background-color: transparent;

            .dkg-delelte-office365ModalBody {
                background-color: #fff;
                border-radius: 5px;
                padding: 20px 25px;

                p {
                    font-size: 15px;
                    color: #e2445b;
                    font-weight: 400;
                    padding: 15px 0;
                    display: block;
                }

                div {
                    button.dkg-office-no-del-btn {
                        border: 1px solid #333;
                        color: #333;
                        background: transparent;
                        display: inline-block;
                        width: 100%;
                        max-width: 90px;
                        border-radius: 5px;
                        padding: 7px 0;
                        transition: all 0.5s;
                        outline: none;
                        box-shadow: none;

                        &:hover {
                            background: #535353;
                            border-color: #535353;
                            color: #fff;
                        }

                    }

                    button.dkg-office-yes-del-btn {
                        background: #e2445b;
                        border: 1px solid #e2445b;
                        color: #fff;
                        display: inline-block;
                        width: 100%;
                        max-width: 90px;
                        min-width: 90px;
                        border-radius: 5px;
                        padding: 7px 0;
                        margin-left: 10px;


                        &:hover {
                            background: #535353;
                            border-color: #535353;
                        }
                    }
                }
            }
        }
    }
}