.ui.selection.dropdown.dk-overdues-task-slect {
    color: #797474;
    padding: .435rem 1.25rem !important;
    margin: 0;
    font-family: Verdana,sans-serif;
    font-size: 12px;
    background: #fff;
    border: 1px solid #c1c9dc;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5385;
    min-height: 2.304em;
    white-space: nowrap;
    border-radius: .1875rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.ui.selection.dropdown.dk-overdues-task-slect:hover {
  background: #fff !important;
  border: 1px solid #c1c9dc !important;
}
.ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
.ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
  color: #797474;
  font-size: 12px;
}
.ui.selection.dropdown.dk-overdues-task-slect>.dropdown.icon{
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.5381em;
  font-size: 12px;
}


.dk-overdue-task-select .ui.selection.active.dropdown .menu,
.dk-overdue-task-select .ui.selection.active.dropdown .menu:hover {
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0,0,0,.15);
  border-radius: .1875rem;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}
.dk-overdue-task-select .ui.selection.active.dropdown:hover .menu{
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0,0,0,.15);
  border-radius: .1875rem;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}
.dk-overdue-task-select .ui.dropdown .menu .active.item{
  font-weight: normal !important;
}
.dk-overdue-task-select .ui.dropdown .menu .selected.item,
.dk-overdue-task-select .ui.dropdown.selected{
  background: #26a69a !important;
  width: 100%;
  padding: .5rem 1.25rem !important;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 12px;
}
.dk-overdue-task-select .ui.dropdown .menu .selected.item .text{
  font-size: 12px;
}
.dk-overdue-task-select .ui.selection.dropdown .menu>.item{  
  padding: .65rem 1.25rem !important;
  font-size: 12px;
  border: 0;

}
.ui.selection.dropdown .text:focus {
  font-size: 12px;
  box-shadow: none;
  background-color: #eee !important;
  border: transparent;
  color: #797474 !important;

}