.dk-adminsTrainingMain{
    margin-top: 100px;
    .col-md-3{
        flex: 20%;
        max-width: 20%;
    }
    .dk-trainingMainCols{
        width: 100%;
        max-width: 1220px;
        margin: auto;
        h3.title{
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
            margin:0 0 18px;
            padding-top:18px;
            text-align: center;
            color: #505050;
        }
        .os-box{
            border-radius: 5px;
            padding:10px 10px;
            margin: 0 0 30px;
            a{
                color: #fff;
                display: block;
                text-align: center;
                padding: 15px 0;
                h3{
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    font-weight: 700;
                    color: #fff;
                }
                figure{
                    text-align: center;
                    margin-bottom: 10px;
                    img{
                        transform: scale(.9);
                        -webkit-transition: all .4s ease-in-out;
                        max-width: 90px;
                        max-width: 45px;
                        max-height: 45px;
                    }
                }
                &:hover{
                    figure{
                        img{
                            transform: scale(.7);
                            transition: all .4s ease-in-out;
                        }
                    }
                }
            }
            
        }
    }
    
}

.dk-adminsTrainingMain.dk-commonTrainingAdminMain{
    .dk-trainingMainCols{
        max-width: 768px;
    }
}

.dk-adminsTrainingMain{
    .dk-trainingMainCols.dk-trainingAdminCols{
        max-width: 1180px;
    }   
}