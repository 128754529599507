.dk-cliBoardViewPageCont {
    margin-top: 160px;
    &.dkg-cand-jobmatching-mainCon{
        margin-top: 0 !important;
    }
    .commentBoxCon {
        display: flex;
        justify-content: center;
        align-items: center;

        .numCon {
            width: 25px;
            height: 25px;
            background-color: #eaeaea;
            border-radius: 50%;
            line-height: 25px;
            text-align: center;
            border: 1px solid #b9b9b9;
            cursor: pointer;

            &:hover {
                background-color: #234158;
                color: #fff;
            }
        }

        .activenumCon {
            width: 25px;
            height: 25px;
            background-color: #234158;
            border-radius: 50%;
            color: #fff;
            line-height: 25px;
            text-align: center;
            cursor: pointer;
        }
    }

    .dk-qulaifyTopHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;

        .dk-qulaifyLeftTopHead {
            display: flex;
            align-items: center;

            .dk-SrchInputBox {
                .input-group {
                    max-width: 160px;

                    input {
                        padding: 0.5rem 0.55rem;
                        background-color: #fff;
                        margin: 0;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        line-height: 1.3rem;
                        height: 35px;
                        text-align: left;
                        border-radius: 0;
                    }

                    input.form-control:focus {
                        box-shadow: none;
                        background: #fff;
                        outline: 0;
                    }

                    .input-group-append {
                        button {
                            padding: 0.5rem 0.55rem;
                            background-color: #eaeaea !important;
                            color: #6b6b6b;
                            margin: 0;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            line-height: 1.3rem;
                            height: 35px;
                            border-radius: 0;

                            i {
                                color: #6c757d;
                            }
                        }

                    }
                }
            }

            .dk-reclivejobsBox {
                .dk-AccwithNnoDropDwnCon {



                    .dk-AmDropDwn {
                        .dk-AmDropDwnToggle {
                            width: 35px;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #6c757d;
                            background: #fff;
                            border-radius: 50%;
                            border: 1px solid #ddd;
                        }
                    }

                    .dk-lrftCross {
                        position: absolute;
                        color: #000;
                        top: -6px;
                        margin-left: 23px;
                        font-size: 15px !important;
                        cursor: pointer;
                    }
                }
            }

            .dk-cricleBtn {
                display: inline-block;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #fff !important;
                color: #6c757d;
                text-align: center;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;
            }

            i.fa.fa-filter {
                font-size: 12px;
            }
        }

        .dk-qulaifyTopHeadTitle {
            h2.title {
                margin: 0;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                color: #333;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                top: 8px;
                text-transform: uppercase;
                display: none;
            }

            h3.title {
                font-weight: bold;
                .dkg-jobs-faqicon{
                    color: #000;
                    .dkg-jobsfaq-tab{
                        width: 1.5rem;
                        height: 1.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        border: 1px solid #ddd;
                        background-color: #eaeeea;
                    }
                }
            }
        }

        .dk-qulaifyRightTopHead {
            display: flex;
            align-items: center;
            .dk-refreral-filter-btn {
                display: inline-block;
                height: 34px;
                line-height: 20px;
                padding: 7px 10px;
                background: #f2f2f2 !important;
                color: #6c757d;
                border: 1px solid #ddd;
                outline: none;
                box-shadow: none;
            }
            button.dk-reoderBtn {
                display: inline-block;
                background: #eaeaea;
                border: 1px solid #ddd;
                padding: 5px 10px;
                border-radius: 0;
                outline: 0;
                font-size: 15px;
                font-weight: 400;
            }
            .dkg-jobsfaq-tab{
                display: inline-block;
                background-color: #eaeaea;
                color: #333;
                border: 1px solid #ddd;
                padding: 5px 10px;
                border-radius: 0;
                outline: 0;
                font-size: 15px;
                font-weight: 400;
                &:hover{
                    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                    transition: all ease 0.5s;
                }
            }
            .dk-cricleBtn {
                display: inline-block;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #fff !important;
                color: #6c757d;
                text-align: center;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;
            }

            i.fa.fa-filter {
                font-size: 12px;
            }

            .dk-qulaifyRightFilter {

                .ui.selection.dropdown.dk-overdues-task-slect,
                .ui.selection.dropdown.dk-overdues-task-slect:hover {
                    border-radius: 0;
                    min-width: 135px;
                    background-color: #eaeaea !important;
                }

                .ui.selection.dropdown .menu {
                    border-radius: 0;
                }

                .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
                .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
                    font-size: 13px;

                    .dropdown.icon {
                        top: 0.7381em;
                    }
                }
            }
        }
    }

    .dk-jobboardTableCon {
        padding-left: 0;
        overflow-y: auto;
        overflow-x: auto;
        background-color: #234158;
        height: calc(100vh - 150px);
        position: relative;
        padding-bottom: 0;
        margin-top: 10px;

        .table.table.dk-clBoardViewTable {
            margin-bottom: 0;

            thead {
                tr th {
                    padding: 0px;
                    border: 0px;
                    &:nth-child(08),
                    &:nth-child(09),
                    &:nth-child(10),
                    &:nth-child(11),
                    &:nth-child(12),
                    &:nth-child(13){
                        width: 60px;
                    }
                }
            }

            tbody {
                tr td {
                    padding: 17px 21px 0px 21px;
                    background-color: #234158;
                    font-weight: 400;
                    text-align: left;
                    vertical-align: middle;
                    font-size: 12px;
                    border: 0px !important;
                    color: #4d4b4b;

                    &:nth-child(09) {
                        text-align: left;

                        &:hover {
                            color: blue;
                            cursor: pointer;
                        }
                    }

                    &:nth-child(11) {
                        text-align: left;
                    }

                    .dk-recImgCon {
                        width: 32px;
                        height: 32px;

                        img {
                            width: 100%;
                            height: auto;
                            border-radius: 50%;
                            border: 1px solid #ddd;
                        }
                    }

                    .dk-SpecRes {
                        img.pdf-img {
                            width: 30px;
                            height: auto;
                        }
                    }

                    .dk-clupdeProfile i {
                        background: #297483;
                        padding: 3px 5px;
                        text-align: center;
                        border-radius: 2px;
                        color: #fff;
                        cursor: pointer;
                    }

                    a.dk-cllistStatusbtnt {
                        margin: 0;
                        border-radius: 3px;
                        border: none;
                        width: 100%;
                        min-width: 85px;
                        padding: 8px 0;
                        color: #fff !important;
                        outline: 0;
                        cursor: pointer;
                        font-size: 11px;
                        text-transform: uppercase;
                        display: inline-block;
                        font-weight: 500;
                        text-align: center;
                        vertical-align: middle;
                    }

                    a.dk-clientsActBtn {
                        background-color: #1b6867;
                    }

                    .dk-livejobsActionCols {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        a {
                            color: #333;
                            margin-right: 0.5rem;
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                        }

                        a:last-child {
                            margin-right: 0;
                        }
                    }

                    .dk-freqDrpdwnCon {
                        .dropdown.dk-freqDropDwon {
                            button {
                                margin: 0;
                                padding: .375rem 0rem !important;
                                min-width: 100%;
                                font-size: 12px !important;
                                text-transform: capitalize;
                                box-shadow: none;

                            }

                            button.dropdown-toggle::after {
                                display: none;
                            }

                            .dk-freqDrpMenu.dropdown-menu.show {
                                padding: 0;
                                border-radius: 0;
                                min-width: 90px;
                            }

                            .dk-freqDrpMenu.dropdown-menu {
                                .dropdown-item:first-child {
                                    background-color: #31a886;
                                    color: #fff;
                                }

                                .dropdown-item:last-child {
                                    background-color: #dc3545;
                                    color: #fff;
                                }
                            }
                        }
                    }
                    &:nth-child(08),
                    &:nth-child(09),
                    &:nth-child(10),
                    &:nth-child(11),
                    &:nth-child(12),
                    &:nth-child(13){
                        width: 60px;
                    }
                }
            }

            tfoot {
                tr td {
                    background-color: #234158;
                    padding-bottom: 21px;
                    border: 0px;

                    &:nth-child(10) {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.air-datepicker-global-container {
    z-index: 1000000;
}

.priority-click {
    margin-right: 0.4rem;
    cursor: pointer;
    margin-top: 0px;
    float: left;
    border-radius: 100%;
    height: 17px;
    width: 17px;
}

.priority-click-filter {
    margin-right: 0.1rem;
    cursor: pointer;
    margin-left: 6px;
    margin-top: 0px;
    border-radius: 100%;
    height: 17px;
    width: 17px;
}

#gray_circle {
    background: #d0cece;
}

#green_circle {
    background: #30887e;
}

#yellow_circle {
    background: #ffd966;
}

.priority-box .dk-AmDropDwn .dk-AmDropDwnMenu.dropdown-menu:after {
    top: 9px !important;
}

.dk-rightCross {
    position: absolute;
    color: #000;
    top: -6px;
    margin-left: 23px;
    font-size: 15px !important;
    cursor: pointer;
}

.dot {
    height: 7px;
    width: 7px;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 3px;
    background-color: #234158;
    border-radius: 50%;
    display: inline-block
}

.spec-1 {
    color: #000;
    font-size: 12px
}

.spec-1 i {
    color: #ada9a9;
    font-size: 13px;
    margin-right: 5px;
}
.spec-1 svg {
    color: #ada9a9;
    font-size: 13px;
    margin-right: 5px;
}


.job-title {
    font-weight: 400;
    color: #333;
    cursor: inherit;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    div{
        span.date-hover{
            padding-left: 10px;
        }
    }
    &:hover{
        color: #629bd3;
        cursor: pointer;
    }

}
.float-right.mr-4{
    white-space: nowrap !important;
}
.para {
    font-size: 16px
}

.status-badge span {
    border-radius: 0.125rem;
    display: inline-block;
    padding: 1em 1.2em 1em 1.2em;
    color: #fff !important;
    background-color: #30887e !important;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.company-image {
    width: 90%;
    padding: 10px;
}

.grid-table-box {
    height: 50%;
    font-size: 13px;
    color: #396a95;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #ccc;
    .dkg-livejobs-View-btn{
        background-color: #d58b5d;
        color: #fff;
        width: 100%;
        text-align: center;
        height: 100%;
        line-height: 52px;
        cursor: pointer;
        font-size: 1.15rem;
        transition: .3s;
        &:hover{
            animation: pulse 1s infinite;
            transition: .3s;
        }
        .dkg-livejobs-icon{
            padding-right: 8px;
            svg{
                width: 18px;
                height: 18px;
                color: #f2f2f2;
            }
        }

    
    }
    @keyframes pulse {
        0% {
          transform: scale(1);
        }
        70% {
          transform: scale(.9);
        }
          100% {
          transform: scale(1);
        }
      }
    .dkg-livejobs-apply-btn{
        background-color: #3a9469;
        color: #fff;
        width: 100%;
        text-align: center;
        height: 100%;
        line-height: 52px;
        cursor: pointer;
        font-size: 1.15rem;
        &:hover{
            animation: pulse 1s infinite;
            transition: .3s;
        }
        .dkg-livejobs-icon{
            padding-right: 8px;
            svg{
                width: 18px;
                height: 18px;
                color: #f2f2f2;
            }
        }
    }


    i {
        color: gray;
    }

    &:hover {
        background: #ddd;
    }
}

.dk-job-status-back {
    .dropdown-menu {
        padding: 0;
    }

    .dk-freqDropDwon {
        .btn {
            padding: 0;
            margin: 0;
            border-radius: unset;
            box-shadow: none;
        }

        .dropdown-toggle::after {
            display: none;
        }
    }
}

.grid-table-box2 {
    width: 100%;
    height: 50%;
    .dk-AmDropDwnCon {
        width: 20%;
        margin-left: 3%;
        float: left;
        display: flex;
    }
    .dkg-livejobs-apply-btn{
        background-color: #3a9469;
        color: #fff;
        width: 100%;
        text-align: center;
        height: 100%;
        line-height: 52px;
        cursor: pointer;
        font-size: 1.15rem;
        &:hover{
            animation: pulse 1s infinite;
            transition: .3s;
        }
        .dkg-livejobs-icon{
            padding-right: 8px;
            svg{
                width: 18px;
                height: 18px;
            }
        }
    }
}

.boxCols {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 35px 35px 0px 0px;
    line-height: 0px;
    position: absolute;
    left: 7px;
    top: -1px;
    z-index: 1;
    background: none;
}

.boxCols-text {
    position: absolute;
    z-index: 2;
    font-size: 12px;
    transform: rotate(316deg);
    color: #fff;
    left: 3px;
    top: 24px;
}

.grid-table-row {
    background-color: #eaeaea;
    // overflow: hidden;
    border-color: transparent;
    &.dkg-livejobs-two-card{
        border: none !important;
    }
    .dk-freqDrpdwnCon {
        width: 100% !important;
        border-right: 1px solid #ccc;
    }

    .dk-freqDropDwon {
        width: 100% !important;
    }

    .col-md-1 {
        flex: 0 0 13%;
        max-width: 13%;
    }

    .col-md-7 {
        flex: 0 0 70.333333%;
        max-width: 70.333333%;
    }

    .logoBox {
        height: 100%;
        border-right: 1px solid #ccc;

        .logoBoxH {
            height: 70%;
        }

        .dropdown-menu {
            a {
                color: #fff;

                &:hover {
                    color: #000;
                }
            }
        }

        .logoBoxText {
            height: 30%;
            border-top: 1px solid #ccc;
            color: #fff;
        }

        .col-md-6 {
            color: #000;
            height: 100%;
            .dk-freqDropDwon{
                width: 100%!important;
                color: #4d4b4b;
                .dropdown-toggle.btn {
                    border-radius: 0;
                    border-radius: initial;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    &::after{
                        display: none;
                    }
                    &.boxCols{
                        left: -1px;
                        top: 0px !important;
                    }
                }
                .dk-freqDrpMenu.dropdown-menu {
                    padding: 0;
                }
            }
        }
        .col-md-12 {
            color: #000;
            height: 100%;
            .dk-freqDropDwon{
                width: 100%!important;
                color: #4d4b4b;
                left: 0 !important;
                height: 100%;
                .dropdown-toggle.btn {
                    border-radius: 0;
                    border-radius: initial;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    &::after{
                        display: none;
                    }
                    &.boxCols{
                        left: -1px;
                        top: -4px !important;
                    }
                }
                .dk-freqDrpMenu.dropdown-menu {
                    padding: 0;
                    width: 100%;
                }
                .dkg-livejob-status-boxCols{
                    height: 100%;
                    text-transform: capitalize;
                }
            }
        }
    }

    .align-content-center {
        border-left: 1px solid #ccc;
    }

    .fa-info-circle {
        color: #646161 !important;
    }
}

.switch-job-btn {
    background: #fff !important;
    box-shadow: none;
    border: 1px solid #ddd;
    color: #333;
    margin: 0;
    padding: 8px 20px;
    min-width: 120px;
    max-width: 120px;
    font-size: 12px;
}