.crossUserFilter {
    position: absolute;
    color: #000;
    top: -5px;
    right: -8px;
    font-size: 15px !important;
    cursor: pointer;
}
.dk-filterUserTabs{
    .dropdown-toggle.btn, .dropdown-toggle.btn.active{
        background: #eaeaea !important;
        width:35px;
        height: 35px;
        border-radius:50%;
        border:1px solid #ddd !important;
        box-shadow: none !important;
        color: #fff;
        font-size: 15px;
        padding: 0;
        margin: 0;
        outline: none;
        &::after{
            display: none;
        }
        i{
            color: #333;
        }
    }
    .dropdown-menu{
        min-width: auto;
        padding:5px 8px;
        transform: inherit !important;
        top: -5px !important;
        left:45px !important;
        right: inherit !important;
        background: #f2f2f2;
        &::before{
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 10px 7.5px 0;
            border-color: transparent #ccc transparent transparent;
            line-height: 0;
            position: absolute;
            left: -10px;
            top:16px;
        }
        .dropdown-item{
            padding: 0;
            display: inline-block;
            margin: 0 3px;
            img{
                width: 35px;
                max-width: 35px;
                height: 35px;
                border-radius: 50%;
            }
        }
    }
}