.dk-addNewRowModal-main {
    .modal-dialog {
        .modal-content {
            box-shadow: none;
            border: none;
            border-radius: 0;

            .modal-header {
                padding: 10px 15px !important;
                position: sticky;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 99;

                .modal-title {
                    color: #fff;
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    margin: 0;
                    opacity: .9;
                    padding-top: 1px;
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                }

                .close {
                    align-items: center;
                    color: #fff;
                    display: flex;
                    height: 53px;
                    justify-content: center;
                    margin: 0;
                    opacity: .9;
                    padding: 0;
                    position: absolute;
                    right: 2px;
                    text-shadow: none;
                    top: -7px;
                    width: 30px;
                }
            }

            .modal-body {
                overflow: visible;
                padding: 0;

                .dk-addNewInvoiceModal-body {
                    background-color: #eaeaea;
                    *height: calc(100vh - 45px);
                    padding: 15px;

                    .form-group {
                        align-items: center;
                        display: flex;

                        .dkg-kpireport-savebtnCon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;

                            div {
                                div {
                                    button.dkaddBtn {
                                        display: inline-block;
                                        background: #3c97b6;
                                        border: none;
                                        color: #fff;
                                        padding: 8px 25px;
                                        border-radius: 3px;
                                        text-transform: uppercase;
                                        outline: none;
                                        margin-left: 100px;
                                    }
                                }
                            }
                        }

                        label {
                            text-transform: uppercase;
                            width: 100%;
                            font-size: 13px;

                            .dkg-sendout-readonly-input {
                                background-color: #f1f1f1;
                                border: 1px solid #ddd;
                                cursor: text;
                            }

                        }

                        label.dk-amrcDropdown {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .d-flex {
                                margin: 0 30px;

                                .dk-globalUserDropdown {
                                    .dropdown-menu {
                                        &::before {
                                            position: absolute;
                                            left: inherit;
                                            top: 8px;
                                            right: -10px;
                                            transform: rotate(59deg);
                                        }
                                    }
                                }
                            }

                            .title {
                                margin-right: 8px;
                            }

                            .dropdown {
                                .dropdown-toggle {
                                    margin: 0;
                                    padding: 0;
                                    display: inline-block;
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    box-shadow: none;

                                    &::after {
                                        display: none;
                                    }

                                    img {
                                        width: 35px;
                                        height: 35px;
                                        border-radius: 50%;
                                    }
                                }

                                .dropdown-menu {
                                    padding: 4px 5px;
                                    margin: 0 !important;
                                    border-radius: 0;
                                    transform: inherit !important;
                                    width: fit-content;
                                    min-width: fit-content;
                                    max-width: fit-content;
                                    right: 47px !important;
                                    left: inherit !important;
                                    top: -3px !important;
                                    background: #f2f2f2;

                                    &::after {
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-width: 8px 0 8px 12px;
                                        border-color: transparent transparent transparent #cccccc;
                                        line-height: 0px;
                                        _border-color: #000000 #000000 #000000 #cccccc;
                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                                        content: '';
                                        height: 0;
                                        right: -12px;
                                        line-height: 0;
                                        position: absolute;
                                        top: 12px;
                                        width: 0;
                                    }

                                    .d-flex {
                                        margin: 0;
                                    }

                                    a {
                                        display: inline-block;
                                        width: 32px;
                                        height: 32px;
                                        margin: 0 3px;
                                        padding: 0;

                                        &:hover {
                                            background: transparent;
                                        }

                                        &:focus {
                                            background: transparent;
                                        }

                                        img {
                                            width: 32px;
                                            height: 32px;
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
                        }

                        label.dk-addInvoice-title {
                            width: 100%;
                            max-width: 165px;
                            min-width: 165px;
                            width: 100%;
                        }

                        .dk-dropdown {
                            .dropdown {
                                .dropdown-toggle {
                                    align-items: center;
                                    background-color: #fff;
                                    border: 1px solid #ddd;
                                    box-shadow: none;
                                    color: gray;
                                    display: flex;
                                    font-size: 13px;
                                    justify-content: space-around;
                                    margin: 0;
                                    padding: 7px 10px;
                                    text-transform: capitalize;
                                    width: 100%;

                                    &::after {
                                        color: gray;
                                        display: inline-block;
                                        font-size: 16px;
                                        position: absolute;
                                        right: 11px;
                                        top: 16px;
                                    }
                                }

                                .dropdown-menu {
                                    border-radius: 0;
                                    max-height: 220px;
                                    overflow-y: auto;
                                    padding: 0;
                                    width: 100%;

                                    a {
                                        border-bottom: 1px solid #ddd;
                                        padding: 5px 7px;
                                        font-size: 13px;
                                        text-align: center;
                                    }
                                }
                            }
                        }

                        .dkg-addnew-date-timeCon {
                            display: flex;
                            align-items: center;
                            width: 100%;

                            .dkg-customdateformatCon {
                                display: flex;
                                align-items: center;

                                .dkg-customdateformat {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid #ddd;
                                    background-color: #f1f1f1;
                                    width: 100%;
                                    border-right: none;
                                    height: 32px;
                                    cursor: text;
                                }

                                .dkg-calendorIcon {
                                    border: 1px solid #ddd;
                                    background-color: #f1f1f1;
                                    cursor: text;
                                    height: 32px;
                                    line-height: 25px;
                                    padding: 0 5px;
                                }
                            }

                            .MuiFormControl-root {
                                &.MuiFormControl-marginNormal {
                                    margin: 0;
                                    width: 100%;
                                }

                                .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                    &::after {
                                        display: none;
                                    }

                                    &::before {
                                        display: none;
                                    }

                                    input {
                                        text-align: center;
                                        background-color: #fff;
                                    }

                                    .MuiInputAdornment-positionEnd {
                                        margin-left: 0;

                                        .MuiIconButton-root {
                                            padding: 6px 7px;
                                            background-color: #26597d;
                                            border-radius: 0;
                                            border: 1px solid #ccc;
                                            border-left: 0;

                                            .MuiIconButton-label {
                                                svg {
                                                    color: #fff;
                                                    font-size: 1.25rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .dkg-readonly-datetime-picker {
                                color: #234158;

                                .MuiFormControl-root {
                                    &.MuiFormControl-marginNormal {
                                        margin: 0;
                                        width: 100%;
                                    }

                                    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                        input {
                                            text-align: center;
                                            background-color: #f2f2f2;
                                        }

                                        .MuiInputAdornment-positionEnd .MuiIconButton-root {
                                            cursor: text;
                                        }
                                    }
                                }

                            }
                        }
                    }

                    button.dkaddBtn {
                        display: inline-block;
                        background: #3c97b6;
                        border: none;
                        color: #fff;
                        padding: 8px 25px;
                        border-radius: 3px;
                        text-transform: uppercase;
                        outline: none;

                        &:hover {
                            background: #26597d;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
}

.dk-addNewRowModal-main.modal {
    overflow-y: auto;
    overflow-x: visible;
    padding-right: 0 !important;

    .modal-dialog {
        margin: 0 !important;
        max-width: 600px;
        min-width: 600px;
        position: fixed;
        width: 100%;
    }
}

.dk-addNewRowModal-main.modal::-webkit-scrollbar {
    width: 8px;
}

.dk-addNewRowModal-main.modal::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #eaeaea;
}

.dk-addNewRowModal-main.modal::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.dk-addNewRowModal-main.modal.right.fade {
    margin: 0;
    right: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.dk-addEditMain-modal {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 70px;
    z-index: 999;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 22px 104px -6px rgba(0, 0, 0, 0.34);

    .dk-addEdit-no {
        background: #34976e;
        border-radius: 5px 0 0 5px;
        color: #fff;
        cursor: default;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 63px;
        font-size: 30px;
    }

    .dk-addEdit-cols {
        width: 100%;
        max-width: 120px;
        min-width: 120px;
        background-color: #fff;
        border-left: 1px solid #ccc;
        color: #333;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        text-align: center;
        padding: 10px;
    }

    .dk-addEdit-close {
        button {
            background: transparent;
            outline: none;
            border: none;
            width: 63px;
            border-left: 1px solid #ccc;
            height: 100%;
        }
    }
}