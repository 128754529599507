.dk-workflowCon {
    margin-top: 170px;

    .dk-workFlowPageHeader {
        position: relative;

        ul {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.2rem;

            li {
                margin: 0;
                border-radius: 0;
                line-height: 15px;
                padding: 10px 18px;
                background-color: #ccc;
                text-align: center;
                min-width: 160px;
                border-right: 1px solid #b9abab;
                cursor: pointer;

                &.active {
                    margin: 0;
                    line-height: 15px;
                    padding: 10px 18px;
                    background-color: #eaeaea;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-child {
                    margin: 0;
                    line-height: 15px;
                    padding: 10px 18px;
                    background-color: #ccc;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    border-right: none;
                }

                a {
                    text-transform: uppercase;
                    font-size: 12px;
                    color: #333;
                }
            }
        }

        .dk-workflow-editbtnCon {
            position: absolute;
            top: 0;
            right: 5px;

            .dk-workflow-editbtn {
                display: inline-block;
                background: #f2f2f2;
                color: #333;
                border: 1px solid #ccc;
                padding: 6px 0;
                border-radius: 3px;
                outline: 0;
                width: 100%;
                min-width: 85px;
                max-width: 85px;
                text-align: center;
                box-shadow: none;
                font-size: 14px;
            }
        }
    }

    .dk-workFlowbottomSec {
        display: flex;
        width: 100%;

        .dk-recAmBox {
            width: 100%;
            max-width: 100%;
            background: #eaeaea;
            border: 1px solid #dadada;
            margin: 0 9px 18px;
            align-items: center;
            font-size: 13px;
            border-radius: 5px;
            padding: 0;

            .dk-recAmImgCon {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px;

                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    border: 1px solid #dadada;
                }

                .dk-wf-rec-dropdownCon {
                    .dk-wf-rec-dropdown {
                        .dk-wf-rec-dropdown-toggle {
                            box-shadow: none;
                            width: 70px;
                            height: 70%;
                            display: flex;
                            justify-content: center;
                            margin: 0;
                            padding: 0;

                            &::after {
                                display: none;
                            }
                        }

                        .dk-wf-rec-dropdownMenu {
                            position: absolute !important;
                            right: -3px !important;
                            left: inherit !important;
                            border-radius: 5px;
                            padding: 5px;
                            background-color: #234158;
                            min-width: 80px;
                            max-width: 80px;
                            max-height: 380px;
                            overflow-y: auto;

                            &:after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-color: transparent #234158 transparent transparent;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                line-height: 0;
                                _border-color: #000 #234158 #000 #000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                position: absolute;
                                left: -12px;
                                top: 2px;
                            }

                            a.dropdown-item {
                                padding: 0;
                                display: block;
                                margin: 5px;

                                img {
                                    width: 55px;
                                    height: 55px;
                                    border-radius: 50%;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            .dk-workflowBodyCon {
                .dk-workflowBodyHeader {
                    background-color: #ddd;

                    h2 {
                        padding: 7px 10px;
                        font-size: 18px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-transform: uppercase;
                        margin: 0;
                        opacity: 0.96;
                    }
                }

                .dk-workflowBodyList {
                    color: #333;

                    .dk-workflowTable {
                        margin-bottom: 0;

                        tbody tr td {
                            padding: 8px 10px;
                            font-size: 13px;
                            vertical-align: middle;
                            background-color: #f2f2f2;

                            &:first-child {
                                border-left: none;
                            }

                            &:last-child {
                                border-right: none;
                                text-align: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}