.dk-salesReportMain {
    width: 100%;
    // max-width: 1600px;
    // margin: auto;
    margin-top: 115px;
    padding: 0 15px;
    // padding-left: 6px;
    // height: calc(100vh - 155px);
    // overflow-y: auto;

    .dk-leftBoxes-main {
        // display: flex;
        width: 100%;
        // flex-wrap: wrap;

        .dk-leftBoxes-panel {
            width: 100%;
            display: flex;
            align-items: start;

            .dk-leftBoxes-cols {
                background: #F1F1F1;
                border: 1px solid #cfcfcf;
                border-radius: 5px;
                width: 100%;
                margin: 6px;

                // .dk-textPanel {
                //     min-height: 373px;
                // }

                // .dk-textPanel.sm-height {
                //     min-height: 200px;
                // }
                // .dk-left-textPanel {
                //     min-height: 220px;
                // }

                .title {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: 1px solid #ddd;
                    text-transform: uppercase;
                    color: #6c757d;
                    font-size: 13px;
                }

                .dk-toptitle-filterBg {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: none;
                    //
                    text-transform: uppercase;
                    color: #6c757d;

                    .dk-dbsuMM-filterHeader {
                        span {
                            i {
                                display: none;
                            }
                        }

                        img {
                            width: 27px;
                            height: 27px;
                            margin-right: 8px;
                            border-radius: 50%;
                        }
                    }
                }

                .dkg-businessbook-ul {
                    min-height: 145px;
                    max-height: 145px;
                    overflow-y: auto;
                    margin-bottom: 0;

                    &.dkg-business-language-ul {
                        // min-height: 315px;
                        // max-height: 315px;
                        min-height: calc(100vh - 405px);
                        max-height: calc(100vh - 405px);
                        overflow-y: auto;
                        margin-bottom: 0;
                    }

                    li {
                        list-style-type: none;
                        width: 100%;
                        text-align: left;
                        background: #F1F1F1;
                        border-bottom: none;
                        padding: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 13px;

                        .recuriterItem {
                            img {
                                width: 28px;
                                height: 28px;
                                border-radius: 50%;
                                margin-right: 8px;
                            }
                        }

                        span.rightItem {
                            display: inline-block;
                            text-align: center;
                            color: #2c71b2;
                            font-size: 13px;
                        }

                        &:hover {
                            background-color: #234158;
                            color: #fff;
                            cursor: pointer;

                            span.rightItem {
                                color: #fff;
                            }
                        }
                    }

                    .dkg-business-select-li {
                        background: #234158;

                        color: #fff;

                        .dk-dbsuMM-filterHeader {
                            span {
                                i {
                                    cursor: pointer;
                                }
                            }

                            img {
                                width: 27px;
                                height: 27px;
                                border-radius: 50%;
                                margin-right: 8px;
                            }
                        }

                        .rightItem {
                            color: #fff;
                        }
                    }

                    &.dkg-businessbook-acc-ul {
                        li {
                            padding: 4px 5px;

                            .recuriterItem {
                                img {
                                    width: 26px;
                                    height: 26px;
                                    border-radius: 50%;
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                }

                .dkg-businessbook-ul::-webkit-scrollbar {
                    width: 10px;
                    cursor: pointer;
                }

                .dkg-businessbook-ul::-webkit-scrollbar-track {
                    background: #F1F1F1;
                }

                .dkg-businessbook-ul::-webkit-scrollbar-thumb {
                    background-color: #ddd;
                    border-radius: 4px;
                    cursor: pointer;
                }

            }
        }
    }

    .dk-rightBoxes-main {
        width: 16%;
        margin-left: 6px;

        // .dk-leftBoxes-panel {
        //     .dk-leftBoxes-cols {
        //         background: #eaeaea;
        //         border: 1px solid #cfcfcf;
        //         border-radius: 5px;
        //         width: 100%;
        //         margin: 6px 0;

        //         .title {
        //             border-bottom: 1px solid #ccc;
        //             background: #ddd;
        //             text-transform: uppercase;
        //             font-size: 14px;
        //             display: block;
        //             text-align: center;
        //             padding: 8px 0;
        //             border-radius: 5px 5px 0 0;
        //         }

        //         .dk-textPanel {
        //             // height: calc(100vh - 208px);
        //             height: 550px;
        //         }
        //     }
        // }
        .dk-leftBoxes-panel {
            width: 100%;
            display: flex;
            align-items: start;

            .dk-leftBoxes-cols {
                background: #F1F1F1;
                border: 1px solid #cfcfcf;
                border-radius: 5px;
                width: 100%;
                margin: 6px;

                .title {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: none;
                    text-transform: uppercase;
                    color: #6c757d;
                }

                .dk-toptitle-filterBg {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border-bottom: none;
                    //
                    text-transform: uppercase;
                    color: #6c757d;

                    .dk-dbsuMM-filterHeader {
                        span {
                            i {
                                display: none;
                            }
                        }

                        img {
                            width: 27px;
                            height: 27px;
                            margin-right: 8px;
                            border-radius: 50%;
                        }
                    }
                }

                .dkg-businessbook-ul {
                    min-height: 145px;
                    max-height: 145px;
                    overflow-y: auto;
                    margin-bottom: 0;

                    &.dkg-business-language-ul {
                        min-height: 510px;
                        max-height: 510px;
                        overflow-y: auto;

                        .dkg-business-select-li {
                            background: #234158;

                            color: #fff;

                            .dk-dbsuMM-filterHeader {
                                span {
                                    i {
                                        cursor: pointer;
                                    }
                                }

                                img {
                                    width: 27px;
                                    height: 27px;
                                    margin-right: 8px;
                                    border-radius: 50%;
                                }
                            }

                            .rightItem {
                                color: #fff;
                            }
                        }

                    }

                    &.dkg-business-language-ul::-webkit-scrollbar {
                        width: 10px;
                        cursor: pointer;
                    }

                    &.dkg-business-language-ul::-webkit-scrollbar-track {
                        background: #F1F1F1;
                    }

                    &.dkg-business-language-ul::-webkit-scrollbar-thumb {
                        background-color: #ddd;
                        border-radius: 4px;
                        cursor: pointer;
                    }

                    li {
                        list-style-type: none;
                        width: 100%;
                        text-align: left;
                        background: #F1F1F1;
                        border-bottom: none;
                        padding: 8px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 13px;

                        span.rightItem {
                            display: inline-block;
                            text-align: center;
                            color: #2c71b2;
                            font-size: 13px;
                        }

                        &:hover {
                            background-color: #234158;
                            color: #fff;
                            cursor: pointer;

                            span.rightItem {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }
}