.dkg-jobspec-company-mainCon{
    margin-top: 145px;
    height: calc(100vh - 145px);
    padding: 0;
    .dkg-jobspec-company-header{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 5px 0;
        .dkg-jobpsec-DropdownCon{
            .dkg-jobpsec-Dropdown{
                margin: 0;
                .dkg-jobpsec-Dropdown-toggle{
                    background-color: #fff;
                    border: none;
                    box-shadow: none;
                    font-size: 13px;
                    margin: 0;
                    min-width: 110px;
                    padding: 7px 9px;
                    text-transform: capitalize;
                    min-width: 240px;
                    position: relative;
                    color: #2e97b6;
                    border: none!important;
                    &:after{
                        position: absolute;
                        top: 16px;
                        right: 40px;
                        color: #2e97b6;

                    }
                    &:hover{
                        background-color: #eaeaea;
                    }
                }
                .dkg-jobpsec-Dropdown-Menu{
                    border-radius: 0;
                    min-width: 180px;
                    padding: 0;
                    height: calc(100vh - 440px);
                    overflow-y: auto;
                    width: 100%;
                    .dkg-jobpsec-Dropdown-Item{
                        border-bottom: 1px solid #ddd;
                        font-size: 13px;
                        padding: 7px 10px;
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
            }
            .dkg-jobpsec-page-title{
                color: #2e97b6;
                font-size: 18px;
                font-weight: 600;
                margin: 0;
                padding: 0;
            }
        } 
        .dkg-jobspec-company-title {
            border-bottom: 1px solid #ddd;
            font-size: 18px;
            font-weight: 500;
            padding: 11px 0;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
            background-color: #fff;
            font-weight: 600;
        }
    }
    .dkg-jobspec-company-mainRow{
        background-color: #eaeaea;
        .dkg-jobspec-company-sideMenu{
            flex: 0 0 16.666667%;
            max-width: 16.666667%;
            .dkg-jobspec-company-navpills{
                background-color: #244158;
                height: calc(100vh - 184px);
                margin-bottom: 0;
                overflow-y: auto;
                width: 100%;
                .dkg-jobspec-company-navitem{
                    width: 100%;
                    .dkg-jobspec-company-navlink{
                        background: #244158;
                        border: none;
                        border-bottom: 1px solid #3e5b73;
                        border-radius: 0;
                        color: #ddd;
                        display: block;
                        font-family: Roboto, sans-serif !important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 7px;
                        text-transform: inherit;
                        width: 100%;
                        &.active{
                            background-color: #eaeaea;
                            color: #333;
                            border: none !important;
                        }
                    }
                }
            }
        }
        .dkg-jobspec-rightCon{
            padding-left: 0;
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
            .dkg-jobspec-tabcontent{
                .dkg-jobspec-tabpane{
                    background-color: #eaeaea;
                    height: calc(100vh - 220px);
                    border: none;
                    border-right: 1px solid #ddd;
                    padding: 0 15px;
                    padding-right: 25px;
                    padding-bottom: 30px;
                    overflow-y: hidden;
                    width: 100%;
                    .dkg-jopspec-pageheader{
                        width: 100%;
                        .dkg-jopspec-page-title{
                            color: #636363;
                            font-size: 20px;
                            font-weight: 700;
                            text-align: center;
                            padding: 7px 10px;
                            text-transform: uppercase;
                            position: relative;
                            right: 10%;                        
                        }
                    }
                    .dkg-companynew-info-mainCon{
                        width: 100%;
                        height: calc(100vh - 240px);
                        overflow-y: auto;
                        padding-bottom: 60px;
                        background-color: #fff;
                        .dkg-companynew-info-con{
                            width: 100%;
                            .dkg-comany-banner-mainCon{
                                background-color: transparent;
                                border-radius: 5px;
                                padding: 0;
                                width: 100%;
                                max-width: 100%;
                                // margin-right: auto;
                                .dkg-comany-banner-cols{
                                    margin: 0 ;
                                    .dkg-comany-banner-box{
                                        padding: 0 0 0px;
                                        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
                                        transition: box-shadow 83ms;
                                        background-color: #eaeaea;
                                        position: relative;
                                        .dkg-comany-banner{
                                            width: 100%;
                                            overflow: hidden;
                                            height: 360px;
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            background-size: cover;
                                        }
                                        .dkg-companynew-banner-edit{
                                            position: absolute;
                                            right: 10px;
                                            top: 0;
                                            z-index: 9;
                                            cursor: pointer;
                                            .dkg-banner-input{
                                                cursor: pointer;
                                                width: 24px;
                                                height: 24px;
                                                position: relative;
                                                left: 24px;
                                                z-index: 99;
                                                opacity: 0;
                                            }
                                            svg{
                                                width: 30px;
                                                height: 30px;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                    .dkg-cp-info-bottomcon{
                                        margin: 0;
                                        padding: 15px;
                                        position: relative;
                                        z-index: 5;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        width: 100%;
                                        border-left: 1px solid #ddd;
                                        border-right: 1px solid #ddd;
                                        background-color: #f2f2f2;
                                        .dkg-cp-info-leftCon{
                                            display: flex;
                                            align-items: center;
                                            .dkg-cp-info-box{
                                                margin: -90px 0 0;
                                                height: 130px;
                                                width: 170px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                background: #fff;
                                                box-shadow: 0 0 3px #000;
                                                border-radius: 5px;
                                                position: relative;
                                                .dkg-cp-info-logo{
                                                    width: 100%;
                                                    height: 100%;
                                                    background-size: cover;
                                                    overflow: hidden;
                                                    border-radius: 5px; 
                                                }
                                                .dkg-cp-info-edit-icon{
                                                    position: absolute;
                                                    right: 10px;
                                                    top: 5px;
                                                    cursor: pointer;
                                                    .dkg-cp-info-logo-input{
                                                        cursor: pointer;
                                                        width: 24px;
                                                        height: 24px;
                                                        position: relative;
                                                        left: 24px;
                                                        z-index: 99;
                                                        opacity: 0;
                                                    }
                                                    svg{
                                                        width: 22px;
                                                        height: 22px;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                            .dkg-cp-info-nameCon{
                                                position: relative;
                                                .dkg-cp-info-name{
                                                    margin-left: 20px;
                                                    border-radius: 0;
                                                    text-align: left;
                                                    color: #000000;
                                                    font-weight: 600;
                                                    padding: 9px 10px;
                                                    font-size: 1.35rem;
                                                    position: relative;
                                                    top: -10px;
                                                    border: none;
                                                    background-color: #f2f2f2;
                                                }
                                                .dkg-cp-info-title-edit{
                                                    position: absolute;
                                                    right: -25px;
                                                    top: -17px;
                                                    z-index: 9;
                                                    svg{
                                                        width: 18px;
                                                        height: 18px;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                        }
                                        .dkg-cp-info-rightCon{
                                            .dkg-socailmedia-box{
                                                .dkg-socailmedia-tab{
                                                    .dkg-socailmedia-img{
                                                        width: 34px;
                                                        height: 34px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-companynew-banner-tableCon{
                                background-color: transparent;
                                border-radius: 5px;
                                padding: 0;
                                width: 100%;
                                max-width: 100%;
                                .dkg-companynew-info-tablecon{
                                    background-color: #fff;
                                    border-radius: 5px;
                                    padding: 0;
                                    // padding-top: 0;
                                    // padding-left: 0;
                                    width: 100%;
                                    max-width: 100%;
                                    .dkg-companynew-info-table{
                                        margin-bottom: 0;
                                        // max-width: 65%;
                                        tbody{
                                            tr{
                                                td{
                                                    color: #333;
                                                    background-color: #fff;
                                                    text-align: left;
                                                    padding: 5px 10px;
                                                    font-size: 1rem;
                                                    vertical-align: middle;
                                                    height: 45px;
                                                    &:nth-child(01){
                                                        width: 226px;
                                                        background-color: #244158;
                                                        color: #fff;
                                                        border-bottom: 1px solid #a7a2a2;
                                                    }
                                                    // &:nth-child(02){
                                                    //     width: 340px;
                                                    // }
                                                    .dkg-sm-input{
                                                        border: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dkg-jobspec-read-editor-Con{
                        background-color: #fff;
                        padding: 7px 15px;
                        height: calc(100vh - 235px);
                        overflow-y: auto;
                        width: 100%;
                    }
                    .dkg-company-video-mainCon{
                        background-color: #fff;
                        height: calc(100vh - 235px);
                        overflow-y: auto;
                        width: 100%;
                        padding: 15px 30px;
                        padding-bottom: 45px;
                        .dkg-company-videoCon{
                            width: 100%;
                            .dkg-company-mainRow{
                              .dkg-company-video-cols{
                                padding: 15px;
                                .dkg-company-video-box{
                                    text-align: center;
                                    background: #f5f5f5;
                                    margin: 0;
                                    border-radius: 5px;
                                    padding-bottom: 1rem;
                                    .dkg-company-album-box-header{
                                        padding: 8px 15px;
                                        border-bottom: 1px solid #ccc;
                                        border-radius: 5px 5px 0 0;
                                        display: block;
                                        text-align: center;
                                        background: #ddd;
                                        .dkg-album-box-header-title{
                                            font-size: 1.10rem;
                                            font-weight: bold;
                                            color: #6c6b6b;
                                            text-transform: uppercase;
                                            text-align: center;
                                            width: 100%;
                                        }
                                    }
                                    .dkg-company-video-figure{
                                        margin: 0 0 1rem ;
                                        .dkg-company-video-imgCon{
                                            width: 100%;
                                            height: 206px;
                                            border-radius: 0;
                                            overflow: hidden;                 
                                            .dkg-company-video-img{
                                                height: 100%;
                                                width: 100%;
                                                object-fit: cover;
                                                border-radius: 0;       
                                            }
                                        }
                                    }
                                    .dkg-company-bottomCon{
                                        .dkg-upload-mediabtn{
                                            box-shadow: none;
                                            color: #fff;
                                            background-color: #6c757d;
                                            border-color: #6c757d;
                                            margin: 0;
                                            padding: 9px 15px;
                                            border-radius: 5px;
                                            margin-right: 0.5rem;
                                            width: 80px;
                                        }
                                        .dkg-edit-mediabtn{
                                            box-shadow: none;
                                            color: #212529;
                                            background-color: #d39e00;
                                            border-color: #c69500;
                                            margin: 0;
                                            padding: 9px 15px;
                                            border-radius: 5px;
                                            width: 80px;
                    
                                        }
                                    }
                                }
                              }  
                            }
                        }
                    }
                }
            }
        }
    }
    &.dkg-jobspec-modal-company-mainCon{
        .dkg-jobspec-company-mainRow{
            .dkg-jobspec-company-sideMenu{
                .dkg-jobspec-company-navpills{
                    height: calc(100vh - 135px) !important;
                    .dkg-jobspec-company-navitem{
                        width: 100%;
                        .dkg-jobspec-company-navlink{
                            &.active{
                                background-color: #eaeaea;
                                color: #333;
                            }
                        }
                    }
                }
            }
            .dkg-jobspec-rightCon{
                .dkg-jobspec-tabcontent{
                    .dkg-jobspec-tabpane{
                        height: calc(100vh - 210px) !important;
                        .dkg-companynew-info-mainCon{
                            height: calc(100vh - 210px);
                        }
                        .dkg-jobspec-read-editor-Con{
                           height: calc(100vh - 210px);
                        }
                        .dkg-company-video-mainCon{
                           height: calc(100vh - 210px);
                        }
                    }
                }
            }
        }
    }
}