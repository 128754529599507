.dk-QuailfyMainPageCon {
    margin-top: 165px;
    .dk-QuailfyMainPTitle {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 0;
    }
    .dk-qulaifyTopHead{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        .dk-qulaifyLeftTopHead{
            display: flex;
            align-items: center;
            .dk-SrchInputBox{
                .input-group{
                    max-width: 160px;
                    input{
                        border-radius: 0;
                        background-color: #eaeaee;
                        border: 1px solid #ddd;
                        height: 35px;
                    }
                    input.form-control:focus{
                        box-shadow: none;
                        outline: 0;
                    }
                    .input-group-append{
                        button{
                            padding: 0.5rem 0.55rem;
                            background-color: #eaeaee !important;
                            margin: 0;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            border-left: none;
                            line-height: 1.3rem;
                            i{
                                color: #6c757d;
                            }
                        }
    
                    }
                }
            }
            button.dk-cricleBtn{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                background: #eaeaea !important;
                color: #333;
                text-align: center;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;
            }
        }
        .dk-qulaifyTopHeadTitle{
            h2.title{
                margin: 0;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                color: #333;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                top: 8px;
                text-transform: uppercase;
            }
        }
        .dk-qulaifyRightTopHead{
            display: flex;
            align-items: center;
            button.dk-reoderBtn{
                display: inline-block;
                background: #eaeaea;
                border: 1px solid #ddd;
                padding: 5px 10px;
                border-radius: 0;
                outline: 0;
                font-size: 15px;
                font-weight: 400;
            }
            .dk-qulaifyRightFilter{
                .ui.selection.dropdown.dk-overdues-task-slect{
                    border-radius: 0;
                    min-width: 135px;
                }
                .ui.selection.dropdown .menu {
                    border-radius: 0;
                }
            }
        }
    }
    .dk-qualifyTableCon{
        overflow-y: auto;
        overflow-x: auto;
        margin-top: 0.5rem;
        height: calc(100vh - 235px);
        padding-bottom: 30px;
        position: relative;
        .dk-qualifyTable{
            margin-bottom: 0;
            border-top: none;
            thead{
                tr th{
                    background: #252c37;
                    color: #e2dada;
                    font-size: 14px;
                    text-align: center;
                    white-space: nowrap;
                    padding: 9px 15px;
                    border: 1px solid #616767;
                    border-bottom: none;
                    vertical-align: middle;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                }
            }
            tbody{
                tr td{
                    background: #2c394b!important;
                    color: #bdb4b4;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    white-space: nowrap;
                    padding: 5px 15px;
                    border: 1px solid #5a6169;
                    vertical-align: middle;
                    &:nth-child(01){
                        width:2%;
                    }
                    &:nth-child(02){
                        width:12%;
                    }
                    &:nth-child(03){
                        width:12%;
                    }
                    &:nth-child(06){
                        width:7%;
                    }
                    &:nth-child(04){
                        text-align: left;
                        span{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 150px;
                            display: inline-block;
                        }
                    }
                    &:nth-child(05){
                        text-align: left;
                        span{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 150px;
                            display: inline-block;
                        }
                    }
                    .dk-freqDrpdwnCon{
                        color: #333;
                        .dropdown.dk-freqDropDwon{
                            width: 100%;
                            max-width: 150px;
                            margin: auto;
                            button {
                                margin: 0;
                                padding: .375rem 1rem!important;
                                min-width: 100%;
                                width: 100%;
                                background-color: inherit;
                                text-transform: capitalize;
                                box-shadow: none;
                                white-space: nowrap;
                            }
                            .btn-success{
                                background-color: inherit;
                            }
                            .btn-success.dropdown-toggle{
                                background-color: inherit;
                            }
                            button.dropdown-toggle::after{
                                display: none;
                            }
                            .dk-freqDrpMenu.dropdown-menu.show{
                                padding: 0;
                                border-radius: 0;
                            }
                            .dk-freqDrpMenu.dropdown-menu{
                                .dropdown-item:first-child{
                                    background-color: #31a886;
                                    color: #fff;
                                }
                                .dropdown-item:last-child{
                                    background-color: #1b6867;
                                    color: #fff;
                                }

                            }
                        }
                    }
                    .dk-qualifyAction {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a.dk-view-btn,
                        a.dk-edit-btn,
                        a.dk-delete-btn{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            font-size: 16px;
                            border-radius: 3px;
                        }
                        a.dk-view-btn{
                            color: #2196f3;
                        }
                        a.dk-edit-btn{
                            color: #fee69c;
                        }
                        a.dk-delete-btn{
                            color: #dc3545;
                        }
                    }
                }
            }
        }
    }
}
