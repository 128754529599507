.dk-hrAdminDetailMain {
    margin-top: 130px;
    background: #eaeaea;
    padding: 0;
    position: relative;

    .dk-detailsMain {
        margin-top: 0;

        .dk-detailsLeftPanel {
            margin-top: 0;
        }
    }

    .page-header {
        position: relative;
        padding: 8px 22px 12px;

        h3.title {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0 0 6px;
            text-align: center;
            color: #505050;
        }

        a {
            display: inline-block;
            border: 1px solid #ccc;
            padding: 7px 15px;
            border-radius: 5px;
            color: #333;

            &:hover {
                background: #244158;
                border-color: #244158;
                color: #fff;
                transition: all 0.5s;
            }
        }
    }

    .os-box {

        &:hover {
            figure {
                img {
                    transform: scale(.7);
                    transition: all .4s ease-in-out;
                }
            }
        }

        a {
            background: #eaeaea;
            border: 1px solid #d8d8d8;
            min-height: 105px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            border-radius: 5px;
            color: #1a3445;

            &:hover {
                text-decoration: none;
            }

            figure {
                margin: 0 !important;
                min-height: auto;

                img {
                    transform: scale(.9);
                    transition: all .4s ease-in-out;
                    max-width: 48px;
                    max-height: 48px;
                }
            }

            h3 {
                font-size: 18px;
                line-height: 20px;
                margin: 0 8px;
                font-weight: 600;
                top: 0;
                white-space: nowrap;
            }
        }
    }

    .dk-trainingBoxMain::-webkit-scrollbar {
        width: 0;
    }

    .dk-trainingBoxMain::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    }

    .dk-trainingBoxMain::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 0px solid slategrey;
    }

    .dk-hrAdminBoxMain {
        padding: 2px 22px;
        overflow-y: auto;
        height: calc(100vh - 228px);

        .col-md-3 {
            flex: 20%;
            max-width: 20%;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    .dk-detailsMain {
        padding: 0 22px;
        padding-left: 0;
        margin-top: 0;

        @media(max-width:992px) {
            padding: 0;
        }

        h3.title {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0;
            text-align: center;
            position: relative;
            margin-bottom: 10px;
            color: #505050;
        }

        .dk-detailsLeftPanel {
            width: 100%;
            max-width: 170px;
            min-width: 170px;
            margin-top: 0;

            @media(max-width:992px) {
                max-width: 100%;
                min-width: 100%;
            }

            a.dk-backBtn {
                display: inline-block;
                border: 1px solid #ccc;
                padding: 7px 15px;
                border-radius: 0;
                color: #333;

                &:hover {
                    background: #244158;
                    border-color: #244158;
                    color: #fff;
                    transition: all 0.5s;
                }
            }

            ul::-webkit-scrollbar {
                width: 0px;
                height: 0;
            }

            ul::-webkit-scrollbar-track {
                box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
            }

            ul::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                outline: 0px solid slategrey;
            }

            ul {
                background: #244158;
                list-style: none;
                margin: 0;
                padding: 0;
                border-radius: 0;
                min-height: auto;
                margin-top: 0;
                height: calc(100vh - 131px);
                overflow-y: auto;

                @media(max-width:992px) {
                    height: auto;
                    display: flex;
                    border-radius: 0;
                }

                li {
                    list-style-type: none;
                    display: block;
                    width: 100%;

                    a {
                        display: block;
                        width: 100%;
                        color: #ddd;
                        font-size: 15px;
                        padding: 11px 15px;
                        font-weight: 400;
                        border-bottom: 1px solid #3e5b73;
                        font-family: "Roboto", sans-serif !important;
                        text-transform: inherit;
                        text-align: left;

                        @media(max-width:992px) {
                            white-space: nowrap;
                            border-bottom: none;
                            border-right: 1px solid #3e5b73;
                        }

                        &:hover {
                            background: #eaeaea;
                            color: #333;
                            transition: all 0.5s;
                            text-decoration: none;
                        }
                    }

                    a.active {
                        background: #eaeaea;
                        color: #333;
                    }

                    a.active:first-child {
                        border-radius: 0;
                    }
                }
            }
        }

        .dk-detailRightPanel {
            width: 100%;
            margin-left: 25px;
            margin-top: 0;
            position: relative;

            @media(max-width:992px) {
                margin-left: 0;
                margin: 0;
                padding: 15px;
            }

            .dk-detailTabsMain {

                // background: #fff;
                // border:1px solid #d8d8d8;
                // border-radius: 5px;
                // margin-top:0;
                // padding:0;
                // height: calc(100vh - 230px);
                // overflow-y: auto;
                // position: relative;
                h3.title {
                    position: absolute;
                    margin: 0;
                    left: 50%;
                    top: 8px;
                    transform: translate(-50%);
                    text-transform: inherit;
                    z-index: 1;
                    color: #333;
                    font-size: 20px;
                    font-weight: 400;

                    @media(max-width:992px) {
                        position: relative;
                        transform: inherit;
                        left: inherit;
                    }
                }

                .nav-tabs {
                    position: relative;
                    border: none !important;
                    justify-content: flex-end;
                    *border-bottom: 1px solid #ddd !important;
                    padding: 10px 15px 5px;
                    background: transparent;
                    padding-right: 80px;

                    .nav-item {
                        margin: 0 !important;

                        .nav-link {
                            box-shadow: none;
                            border: none;
                            color: #737373;
                            margin: 0 15px;
                            padding: 0;
                            padding-bottom: 2px;
                            border-bottom: 3px solid transparent;
                            font-family: "Roboto", sans-serif !important;
                            font-weight: 400;
                            font-size: 16px;
                            text-transform: capitalize;
                            cursor: pointer;
                            outline: none;
                        }

                        .nav-link.active {
                            background: transparent;
                            box-shadow: none;
                            border: none;
                            border-bottom: 3px solid #3c97b6;
                            color: #3c97b6;
                            font-weight: 400;
                        }
                    }
                }

                .tab-content {
                    background: #fff;
                    border: 1px solid #d8d8d8;
                    border-radius: 5px;
                    border-radius: 5px;
                }

                .nav-tabs {
                    position: relative;
                    border: none !important;
                    justify-content: flex-end;
                    *border-bottom: 1px solid #ddd !important;
                    padding: 10px 15px 5px;
                    background: transparent;
                    padding-right: 80px;

                    .nav-item {
                        box-shadow: none;
                        border: none;
                        color: #737373;
                        margin: 0 15px !important;
                        padding: 0;
                        border-bottom: 3px solid transparent;
                        font-family: "Roboto", sans-serif !important;
                        font-weight: 400;
                        font-size: 16px;
                        text-transform: capitalize;
                    }

                    .nav-item.active {
                        background: transparent;
                        box-shadow: none;
                        border: none;
                        border-bottom: 3px solid #3c97b6;
                        color: #3c97b6;
                        font-weight: 400;
                    }
                }

                .tab-content {
                    width: 100%;
                    padding: 0;
                }

                .dk-detailLefttabsMain {
                    width: 100%;
                    max-width: 195px;
                    display: inline-block;
                    background: #eaeaea;
                    height: calc(100vh - 207px);
                    margin-right: 0;

                    .MuiTabs-root {
                        flex-wrap: wrap;
                        min-height: auto;
                        width: 100%;
                        overflow: visible;

                        .MuiTabs-scroller {
                            white-space: normal;
                            overflow: visible !important;

                            .MuiTabs-flexContainer {
                                overflow: visible;
                                justify-content: space-between;
                                flex-wrap: wrap;

                                .MuiButtonBase-root {
                                    padding: 0;
                                    overflow: visible;
                                    display: block;
                                    width: 100%;
                                    min-width: inherit !important;
                                    max-width: 100%;
                                    text-align: center;
                                    min-height: auto;
                                    align-items: flex-start;
                                    text-transform: capitalize;
                                    font-weight: 400;
                                    font-size: 15px;
                                    padding: 12px 15px;
                                    border-bottom: 1px solid #ddd;
                                    outline: none;
                                    position: relative;
                                    color: #757575;
                                    opacity: 1;
                                    flex-shrink: inherit;
                                    align-items: left;

                                    &:first-child {
                                        border-radius: 5px 0 0 0;
                                    }

                                    &:last-child {
                                        border-right: none;
                                    }

                                    .MuiTouchRipple-root {
                                        display: none;
                                    }
                                }

                                .MuiButtonBase-root.Mui-selected {
                                    background: #fff;
                                    color: #606060;

                                    &::after {
                                        content: '';
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-width: 10px 0 10px 15px;
                                        border-color: transparent transparent transparent #fff;
                                        line-height: 0px;
                                        _border-color: #000000 #000000 #000000 #fff;
                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                                        position: absolute;
                                        right: -14px;
                                        top: 9px;
                                        display: none;
                                    }
                                }
                            }

                            .MuiTabs-indicator {
                                display: none;
                            }
                        }
                    }
                }

                .dk-tabDetail-flex {
                    .MuiTabPanel-root {
                        padding-top: 31px;
                        text-align: left;
                    }
                }

                .dk-hrAdminInformation-cols {
                    background-color: #fff;
                    margin-top: 41px;
                    padding: 20px;
                    border: 1px solid #ddd;
                    height: calc(100vh - 202px);
                    overflow-y: auto;

                    @media(max-width:992px) {
                        height: auto;
                        margin-top: 20px;
                    }
                }

                .dk-trainingInformation-cols::-webkit-scrollbar {
                    width: 0;
                }

                .dk-trainingInformation-cols::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
                }

                .dk-trainingInformation-cols::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    outline: 0 solid slategrey;
                }
            }

            .dk-detailTabsMain.dk-detailTabsMainOverviewMain {
                .nav-tabs {
                    padding-right: 0;
                }

                .dk-trainingInformation-cols {
                    background: transparent;
                    border: none;
                    margin-top: 0;
                }
            }
        }
    }

}