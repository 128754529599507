.dk-savingsMain{
    background: #374252;
    padding:25px 20px;
    margin-top: 180px;
    height: calc(100vh - 178px);
    .dk-savingsHead{
        position: relative;
        h3.title{
            font-size:20px;
            text-transform: uppercase;
            color: #fff;
            font-weight: 500;
            display: block;
            text-align: center;
            margin: 0 0 8px;
        }
    }
    .dk-savingsTable{
        margin-top: 5px;
        table{
            border:none;
            tr{
                th{
                    background: #0086c0;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #fff;
                    padding: 10px 18px;
                    vertical-align: middle;
                    border: 1px solid #d4d4d4;
                    border-bottom: none;
                    text-transform: uppercase;
                    white-space: nowrap;
                    cursor: pointer;
                    &:nth-child(01){
                        width: 5%;
                    }
                }
            }
            tr{
                td{
                    background: #fff;
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;
                    color: #333;
                    padding:8px 18px;
                    vertical-align: middle;
                    border: 1px solid #d4d4d4;

                    .dk-recruiterImG{
                        img{
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                        }
                    }
                    span{
                        width: 100%;
                        float: left;
                        cursor: pointer;
                    }
                }
            }
            tbody.sumtr{
                tr{
                    td{
                        background:#374352;
                        color: #fff;
                        &:first-child{
                            border-left: none;
                        }
                        &:last-child{
                            border-right: none;
                        }
                    }
                }
            }
        }
    }
}

.dk-addNumbersModal{
    .modal-dialog{
        width: 100%;
        max-width: 300px;
        min-width: 300px !important;
        .modal-content{
            border-radius:5px;
            border:none;
            box-shadow: none;
            .modal-body{
                padding: 25px;
                .form-group{
                    margin:0 10px 10px !important;
                    label{
                        text-align: center;
                        width: 100%;
                    }
                    input{
                        border:1px solid #ddd !important;
                        box-shadow: none !important;
                        height: 35px;
                        text-align: center;
                        font-size: 15px;
                        border-radius: 0;
                    }
                }  
                .dk-saveBtn{
                    display: inline-block;
                    padding:10px 22px;
                    background-color: #0086c0;
                    text-transform: uppercase;
                    color: #fff;
                    outline: none;
                    border: none;
                    border-radius: 5px;
                    &:hover{
                        background: #3c97b6;
                        transition: all 0.5s;
                    }
                }              
            }
        }
    }
}