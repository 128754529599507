.dk-creteNewProjectModal{
    .modal-dialog{
        min-width: 45%;
        .modal-content{
            box-shadow: none;
            background-color: transparent;
            border: none;
            .modal-header.creteNewHeader{
                padding: 10px 15px;
                background: #2e75b6;
                border-radius: 5px 5px 0 0;
                border-bottom: none;
                .modal-title {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                    color: #fff;
                }
                .close{
                    text-shadow: none;
                    opacity: 1;
                    color: #fff;
                }
            }
            .modal-body.creteNewBody{
                background-color: #fff;
                border-radius: 0 0 5px 5px;
                .form-group{
                    label {
                        width: 100%;
                        font-size: 14px;
                        color: #5d5d5d;
                        text-align: left;
                        margin-bottom: 0.5rem;
                    }
                    label.centerTitle{
                        text-align: center;
                    }
                    .dk-userFilterDrpDwn.dropdown{
                        .dropdown-toggle.btn{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-shadow: none;
                            margin: 0;
                            padding: 0 0.5rem !important;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #eaeaea !important;
                            border: 1px solid #ddd;
                            &::after{
                                display: none;
                            }
                            img{
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                            }
                        }
                        .projectsFilterMenu{
                            position: absolute !important;
                            left: 30px !important;
                            top: 1px !important;
                            transform: translate(30px, -7px) !important;
                            border: 1px solid #ddd;
                            background-color: #eaeaea;
                            &::after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                border-color: transparent #ccc transparent transparent;
                                line-height: 0;
                                position: absolute;
                                left: -12px;
                                top: 15px;
                            }
                            .dropdown-item{
                                margin: 0 4px;
                                padding: 0;
                                img{
                                    width: 35px;
                                    height: 35px;
                                    max-width: inherit !important;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                    input.form-control{
                        width: 100%;
                        border-radius: 0;
                        border: 1px solid #ddd ;
                        height: 35px;
                        padding: 0 10px;
                        font-size: 14px;
                        &:focus{
                            box-shadow: none;
                            outline: 0;
                        }
                    }
                    .dk-projectDropDwn{
                        .ui.selection.dropdown{
                            border-radius: 0;
                            border: 1px solid #ddd;
                            .menu{
                                border-radius: 0;
                            }
                        }
                    }
                }
                .dk-project-btnCon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-top: 1rem;
                    button{
                        display: inline-block;
                        background: #2e75b6;
                        padding: 8px 15px;
                        border: none;
                        color: #fff;
                        font-weight: 500;
                        font-size: 14px;
                        border-radius: 3px;
                        outline: 0;
                        min-width: 80px;
                        text-align: center;
                    }
                }
            }
        }
    }
    
}