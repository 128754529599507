.dkg-mailbox-page-mainCon{
    margin-top: 110px;
    background-color: #eaeaea;
    width: 100%;
    height: calc(100vh - 107px);
    padding: 0 15px;
    border: 5px;
    .dk-mailboxMain{
        padding-top: 25px;
        .dkg-mailbox-cols-3{
            width: 100%;
            max-width: 225px;
            margin-right: 5px;
            .dkg-mailbox-leftPanel{
                .dk-mailbox-composemail{
                    .dkg-compose-email{
                        background-color: #22455f;
                        border-color: #22455f;
                        margin: 0 0 1em;
                        padding: 0.8em 0.6em;
                        max-width: 100%;
                        border-radius: 5px;
                        color: #fff;
                        display: block;
                        text-align: center;
                        text-transform: uppercase;
                        transition: all .5s;
                        &:hover{
                            background-color: #3c97b6;
                            border-color: #3c97b6;
                            transition: all ease-in .5s;
                        }
                        .dkg-emailwrite-logo{
                            width: 20px;
                            height: 20px;
                            margin-right: 0.5rem;
                        }
                    }
                }
                .dkg-mailbox-itemsCols{
                    padding: 0;
                    margin-bottom: 15px;
                   .dkg-empmailbox-leftPanel{
                    .dkg-mailbox-headerCols{
                        .dkg-title{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 15px;
                            background: #22455f;
                            width: 100%;
                            color: #fff;
                            font-weight: 500;
                            font-size: 16px;
                            border-radius: 0;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                        }
                        .dkg-main-mailbox-ul{
                            padding: 0;
                            margin: 0;
                            list-style: none;
                           .dkg-main-mailbox-li{
                            list-style-type: none;
                            .dkg-main-mailbox-link{
                                background-color: #2e536f;
                                display: block;
                                border-bottom: 1px solid #326890;
                                font-size: 14px;
                                color: #fff;
                                padding: 8px 10px;
                                position: relative;
                                &.active{
                                    background-color: #3c97b6;
                                }
                                .dkg-mailbox-list-icon{
                                    svg{
                                        width: 15px;
                                        height: 15px;
                                        margin-right: 0.5rem;
                                        display: none;
                                    }
                                }
                                .dkg-mailbox-no-badge{
                                    position: absolute;
                                    right: 12px;
                                    top: 10px;
                                    color: #fff;
                                    background-color: transparent;
                                    display: inline-block;
                                    padding: 0.25em 0.4em;
                                    font-size: 75%;
                                    font-weight: 700;
                                    line-height: 1;
                                    text-align: center;
                                    white-space: nowrap;
                                    vertical-align: baseline;
                                    border-radius: 0.25rem;
                                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                
                                }
                            }
                            &:last-child{
                                .dkg-main-mailbox-link{
                                    border-bottom-left-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
                            }
                           } 
                        }
                    }
                   } 
                }
            }
        }
        .dkg-mailbox-cols-9{
            width: 100%;
            max-width: calc(100% - 230px);
            flex: inherit;
            .dkg-mailbox-right-cols{
                background-color: transparent;
                padding: 25px;
                padding-top: 0;
                height: calc(100vh - 220px);
                .dkg-mailbox-cols-foot{
                    .dkg-mailbox-header{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        // background-color: #566d76;
                        .dkg-mailbox-leftPanel{
                            .dkg-mailbox-inputCon{
                                position: relative;
                                input{
                                    background: #f2f2f2 !important;
                                    border: 1px solid #ddd;
                                    color: #8a8a8a;
                                    max-width: 150px;
                                    display: block;
                                    width: 100%;
                                    height: calc(1.5em + 1.32rem + 2px);
                                    padding: 0.375rem 0.75rem;
                                    font-size: 1rem;
                                    font-weight: 400;
                                    line-height: 1.5;
                                    border-radius: 0;
                                    &:focus{
                                        outline: 0;
                                        box-shadow: none;
                                    }
                                }
                                .dkg-srch-icon{
                                    position: absolute;
                                    top: 1px;
                                    right: 1px;
                                    font-size: 14px;
                                    color: #8a8a8a;
                                    background-color: #eaeaea;
                                    height: 39px;
                                    width: 35px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-left: 1px solid #ddd;
                                    border-right: 1px solid #ddd;
                                    cursor: pointer;
                                    svg{
                                        color: #8a8a8a;
                                    }
                                }
                            }
                        }
                        .dkg-mailbox-centrePanel{
                            .dkg-mailbox-title{
                                font-size: 18px;
                                color: #333;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }
                        .dkg-mailbox-rightPanel{
                            display: flex;
                            justify-content: flex-end;
                            color: #333;
                            align-items: center;
                            .dkg-header-rightCon{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 120px !important;
                                .dkg-header-rightIcon{
                                    color: #fff !important;
                                    input[type="checkbox"]{
                                        background-color: #fff !important;
                                        position: relative;
                                        top: 3px;
                                        border: 1px soid grey;
                                    
                                    }
                                    .dkg-icon{
                                        color: #333;
                                        svg{
                                            color: #333;
                                        }
                                    }
                                }
                            }
                            .dkg-mailbox-drpdwnCon{
                                display: flex;
                                align-items: center;
                                button.dkg-mailbox-filter-icon {
                                    background-color: #eaeaea !important;
                                    border: 1px solid #ddd;
                                    border-right: none;
                                    border-radius: 0;
                                    box-shadow: none;
                                    color: #6b6b6b;
                                    height: 39px;
                                    width: 35px;
                                    margin: 0;
                                    padding: .5rem .55rem;
                                }
                                .dkg-mailbox-drpdwn{
                                    .dkg-mailbox-drpdwn-toggle{
                                        background-color: #f2f2f2;
                                        border: 1px solid #ddd;
                                        color: #6c757d;
                                        box-shadow: none;
                                        text-transform: capitalize;
                                        min-width: 145px;
                                        font-size: 13px;
                                        margin: 0;
                                        height: calc(1.5em + 1.32rem + 2px);
                                        padding: 0.375rem 0.75rem;
                                        height: 39px;
                                        width: 35px;
                                        &:after {
                                            display: block;
                                            position: absolute;
                                            right: 10px;
                                            top: 18px;
                                            color: #6c757d;
                                            font-size: 1.2rem;
                                        }
                                    }
                                    .dkg-mailbox-drpdwn-Menu{
                                        border-radius: 0;
                                        padding: 0;
                                        width: 100%;
                                        background-color: #f2f2f2;
                                        .dkg-mailbox-drpdwn-item{
                                            border-bottom: 1px solid #ddd;
                                            padding: 7px 10px;
                                            font-size: 13px;
                                            &:last-child{
                                                border-bottom: none;
                                            }
                                            &.selected-item{
                                                background-color: #2e536f;
                                                color: #fff;
                                            }
                                            &:hover{
                                                background-color: #2e536f;
                                                color: #fff; 
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dkg-mailbox-tableCon{
                        margin-top: 6px;
                        height: calc(100vh - 257px);
                        overflow-y: auto;
                        position: relative;
                        .dkg-mailbox-table{
                            margin-bottom: 0;
                            thead{
                                tr{
                                    th{
                                        background-color: #3c97b6;
                                        border: 1px solid #ccc;
                                        color: #fff;
                                        font-size: 14px;
                                        font-weight: 600;
                                        padding: .81rem .75rem;
                                        position: -webkit-sticky;
                                        position: sticky;
                                        text-align: center;
                                        top: 0;
                                        vertical-align: middle;
                                        white-space: nowrap;
                                        z-index: 9;
                                        &:nth-child(01){
                                            width: 60px;
                                        }
                                        &:nth-child(02),
                                        &:nth-child(03){
                                            width: 100px;
                                        }
                                        &:nth-child(04){
                                            width: 350px;
                                        }
                                        &:nth-child(05){
                                            width: 160px;
                                        }
                                        &:nth-child(06),
                                        &:nth-child(07){
                                            width: 100px;
                                        }
                                    }
                                }
                            }
                            tbody{
                                tr{
                                    td{
                                        background-color: #fff;
                                        color: #333;
                                        border: 1px solid #dee2e6;
                                        vertical-align: middle;
                                        font-size: 14px;
                                        padding: 0.45rem 0.75rem;
                                        text-align: center;
                                        .dkg-mailbox-imgCon{
                                            .dkg-mailbox-img{
                                                height: 35px;
                                                width: 35px;
                                                object-fit: cover;
                                                border-radius: 50%;
                                            }
                                        }
                                        .dkg-mailbox-firstcols{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .dkg-flagicon{
                                                color: #8a8a8a;
                                            }
                                            .dk-smsTableCheckbox{
                                                input{
                                                    background-color: #fff;
                                                }
                                                input[type=checkbox]:checked {
                                                    background: #31a886 !important;
                                                }
                                            }
                                        }
                                        .dkg-jobtitle-cols{
                                            cursor: pointer;
                                            &:hover{
                                                color: #629bd3;
                                            }
                                        }
                                        &:nth-child(01){
                                            width: 60px;
                                        }
                                        &:nth-child(02),
                                        &:nth-child(03){
                                            width: 100px;
                                        }
                                        &:nth-child(04){
                                            width: 350px;
                                        }
                                        &:nth-child(05){
                                            width: 160px;
                                        }
                                        &:nth-child(06),
                                        &:nth-child(07){
                                            width: 100px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
     
}

.dk-email-content {
    margin: 0;
    border: 1px solid #b9b9b9;
    border-top: none;
}

.dkg-readmsg-mainCon {
    min-height: 100px !important; 
    height: auto !important;
}

.dkg-emailcontent-ModalBody {
    background: transparent;
}