.dk-summaryTrainingMain {
    margin-top: 140px;

    .col-md-3 {
        flex: 20%;
        max-width: 20%;
    }

    .dk-trainingMainCols {
        width: 100%;
        max-width: 1220px;
        margin: auto;

        h3.title {
            font-size: 22px;
            text-transform: uppercase;
            font-weight: 700;
            margin: 0 0 18px;
            padding-top: 18px;
            text-align: center;
            color: #505050;

            @media(max-width:992px) {
                font-size: 16px;
            }
        }

        .os-box {
            border-radius: 5px;
            padding: 10px 10px;
            margin: 0 0 30px;
            cursor: pointer;
            transition: all 0.5s;

            a {
                color: #fff;
                display: block;
                text-align: center;
                padding: 15px 0;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }

                h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                }

                figure {
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 0;

                    img {
                        transform: scale(1);
                        // -webkit-transition: all .4s ease-in-out;
                        max-width: 90px;
                        max-width: 45px;
                        max-height: 45px;
                    }
                }

                &:hover {
                    figure {
                        img {
                            transform: scale(0.8);
                            transition: all .4s ease-in-out;
                        }
                    }
                }
            }

        }
    }

}

.dk-summaryTrainingMain.dk-summaryTrainingContainer {
    .dk-trainingMainCols {
        max-width: 1000px;

        .row {
            @media(max-width:767px) {
                flex-wrap: wrap;
            }
        }

        .col-md-3 {
            flex: 25%;
            max-width: 25%;

            @media(max-width:767px) {
                flex: 100%;
                max-width: 100%;
            }
        }
    }
}

.dk-jobCheckDetailModal.modal.right {
    .modal-dialog {
        .modal-content {
            .modal-header {
                .close {
                    &:hover {
                        background: transparent !important;
                    }
                }
            }
        }
    }
}

.dk-jobCheckDetailModal.modal.right {
    .modal-dialog {
        right: -500px !important;
        min-width: 500px !important;
        max-width: 500px;
        height: 100vh;
        position: fixed;
        top: 0px;
        margin: 0 !important;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;

        .modal-content {
            height: 100vh;
            border-radius: 0;
            font-family: "Segoe UI", Arial, sans-serif;

            .modal-header {
                background-color: #617781;
                border-radius: 0;
                padding: 9px 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                .modal-title {
                    color: #fff;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    text-transform: capitalize;
                    opacity: 0.85;
                    font-size: 20px;
                    font-weight: 400;
                }

                .btn-close {
                    color: #fff;
                    opacity: 0.85;
                    font-weight: normal;
                    outline: none;
                    font-size: 24px;
                    border: none;

                    &::after {
                        content: '';
                        background: url('../../../../../assets/images/close.png') no-repeat;
                        padding: 13px;
                        background-size: 12px;
                        position: absolute;
                        top: 18px;
                        right: 0;
                        cursor: pointer;
                    }

                    &:hover {
                        background: #859ca7;
                    }
                }
            }
        }
    }
}

.dk-jobCheckDetailModal.modal.right.show {
    .modal-dialog {
        right: 0 !important;
    }
}


.dk-dkGlobalDashboard-Main {
    margin-top: 110px;
    padding: 0 25px;

    @media(max-width:991px) {
        margin-top: 180px;
        padding: 0 15px;
    }

    .col-md-3 {
        flex: 0 0 30%;
        max-width: 30%;

        @media(max-width:767px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .col-md-6 {
        flex: 0 0 40%;
        max-width: 40%;

        @media(max-width:767px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .dk-information-cols {
        border: 1px solid #ddd;
        font-family: "Roboto", sans-serif !important;

        @media(max-width:767px) {
            margin-bottom: 15px;
        }

        .title {
            text-transform: uppercase;
            border-bottom: 1px solid #ddd;
            font-size: 16px;
            font-weight: 600;
            padding: 6px 0;
            text-align: center;
            background: #f2f2f2;
        }

        .dk-userImg {
            padding: 3px 10px;
            border-bottom: 1px solid #ddd;
            text-align: center;
            background: #f2f2f2;

            img {
                width: 65px;
                height: 65px;
                border-radius: 50%;
                padding: 3px;
                border: 1px solid #ddd;
            }
        }

        .dk-infoTable {
            table {
                border: none;
                margin-bottom: 0;

                tr {
                    td {
                        border: none;
                        border-right: 1px solid #ddd;
                        border-bottom: 0.5px solid #ddd;
                        height: 36px;
                        background: #f2f2f2;
                        vertical-align: middle;
                        text-align: center;

                        &:last-child {
                            border-right: none;
                            background: #fff;
                            width: 61%;
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        .dk-infoTable.dk-businessDetailTable {
            table {
                tr {
                    td {
                        &:first-child {
                            width: 30%;
                        }
                    }
                }
            }
        }

        .dk-infoTable.dk-businessOverallTable {
            table {
                tr {
                    td {
                        height: 90px;
                        text-align: center;
                        vertical-align: middle;
                        font-weight: 500;
                        color: #777;
                        font-size: 15px;

                        .dk-logo {
                            img {
                                width: 100%;
                                max-width: 150px;
                            }

                            a {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .dk-summarySh-cols {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        border-right: none;
        border-top: none;

        &:last-child {
            .dk-summarySh-panel {
                li {
                    border-right: 1px solid #ccc;
                }
            }
        }

        .title {
            text-align: center;
            font-weight: 500;
            text-transform: uppercase;
            border-bottom: 1px solid #fff;
            padding: 8px 0;
            font-size: 15px;
            background: #fff;
        }

        .dk-summarySh-panel::-webkit-scrollbar {
            width: 0px;
        }

        .dk-summarySh-panel::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
        }

        .dk-summarySh-panel::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0px solid slategrey;
        }

        .dk-summarySh-panel {
            height: auto;
            flex-wrap: wrap;
            overflow-y: auto;
            display: flex;
            border-left: 1px solid #9d9d9d;

            li {
                list-style-type: none;
                width: 100%;
                height: 40px;
                border-bottom: 1px solid #9d9d9d;
                background: #f2f2f2;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;

                &:nth-child(01) {
                    border-top: 1px solid #9d9d9d;
                }

                &:last-child {
                    border-bottom: 1px solid #9d9d9d;
                }
            }
        }

        &:nth-child(01) {
            .dk-summarySh-panel {
                li {
                    min-height: 40px;
                }
            }
        }

        &:nth-child(02) {
            .dk-summarySh-panel {
                li {
                    min-height: 40px;
                    color: #fff;
                    text-transform: uppercase;
                }
            }
        }
    }

    .dk-summarySh-cols.dk-summarySh-trainer {
        max-width: 7%;

        .dk-summarySh-panel {
            .dk-sheduleUser {
                text-align: center;
                padding: 5px 0;

                img {
                    width: 100%;
                    max-width: 32px;
                    border-radius: 50%;
                }
            }
        }
    }
}