.modal-dialog.dk-pipenwqrightFliterModal {
    position: fixed;
    top: 0;
    transform: inherit !important;
    right: 0;
    margin: 0 !important;
    min-width: 430px;
    max-width: 430px;
    height: 100vh;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    z-index: 9999;

    .modal-content {
        border-radius: 0;
        border: none;
        height: 100vh;

        .modal-header.dkmb-Fliterheader {
            padding: 8px 0;
            text-align: center;
            background: #2e97b6;
            color: #fff;
            border-bottom: none;
            width: 100%;
            position: relative;

            .modal-title.dkmb-FliterTitle {
                margin: 0;
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }

            .close {
                padding: 0;
                margin: 0;
                position: absolute;
                right: 3px;
                top: 5px;
                width: 30px;
                height: 30px;
                text-shadow: none;
                opacity: 1;
                color: #fff;
                opacity: 0.9;

                &:hover {
                    background-color: #eaeaea;
                    color: #333;
                }
            }
        }

        .modal-body.dkmb-Fliterbody.dkg-pipelinesRightFilterBody-13 {
            padding: 0;
            background-color: #234158;
            height: calc(100vh - 235px);
            overflow-y: auto;

            .dk-leftSideMenu.dkg-right-sideFilter-13 {
                background-color: #eaeaea;
                min-width: 170px;
                max-width: 170px;
                height: inherit !important;

                .nav-item {
                    background-color: #eaeaea;
                    border-bottom: 1px solid #ddd;
                    max-width: 170px;

                    .nav-link {
                        border-radius: 0;
                        color: #333;
                        text-transform: uppercase;
                        font-size: 0.95rem;
                        padding: 10px 12px;

                        i {
                            display: inline-block;
                            width: 22px;
                            text-align: left;
                            font-size: 14px;
                        }

                        svg {
                            display: inline-block;
                            font-size: 15px;
                            margin-right: 0.45rem;
                        }
                    }

                    .nav-link.active,
                    & .show>.nav-link {
                        color: #ddd;
                        background-color: #234158 !important;
                    }
                }
            }

            .tab-content {
                .tab-pane {
                    .dk-rightMainContent {
                        padding: 1.25rem;

                        .dk-Recuriter {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            ul {
                                list-style: none;
                                margin-bottom: 0;

                                li {
                                    margin-top: 15px;
                                    margin-bottom: 10px;

                                    a {
                                        img {
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 50%;
                                        }

                                        span.recuriterNo {
                                            color: #ff9800;
                                            margin-left: 8px;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-flagFilter {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            ul {
                                list-style: none;
                                margin-bottom: 0;

                                li {
                                    margin-top: 1rem;
                                    margin-bottom: 1rem;

                                    a {
                                        span.text-orange {
                                            color: #ff9800;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-CategoryList {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            ul {
                                margin-bottom: 0;
                                list-style: none;

                                li {
                                    margin-top: 1rem;
                                    margin-bottom: 1rem;

                                    a {
                                        color: #ccc;

                                        Span.text-orange {
                                            margin-left: 0.5rem;
                                            color: #ff9800;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-satusList {
                            width: 100%;
                            display: block;
                            text-align: center;
                            max-width: 80%;
                            margin: 0 auto;

                            ul {
                                list-style: none;
                                margin-bottom: 0;

                                li {
                                    margin-bottom: 0.5rem;

                                    a.activeBg {
                                        padding: 5px 10px;
                                        display: flex;
                                        color: #fff;
                                        justify-content: center;
                                        align-items: center;
                                        background-color: #31A886;
                                        border-radius: 35px;
                                        line-height: 25px;
                                    }

                                    a.inActiveBg {
                                        padding: 5px 10px;
                                        display: flex;
                                        color: #fff;
                                        justify-content: center;
                                        align-items: center;
                                        background-color: #DC3545;
                                        border-radius: 35px;
                                        line-height: 25px;
                                    }
                                }
                            }
                        }

                        .dk-filterList {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.dkg-roletypeFilterlist12 {
                                ul {
                                    li {
                                        padding-left: 2.8rem;
                                    }
                                }
                            }

                            ul {
                                list-style: none;
                                margin-bottom: 0;

                                li {
                                    margin: 6px 0;

                                    a {
                                        color: #ccc;
                                        font-size: 13px;

                                        span {
                                            color: #ff9800;
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-satusListFilter {
                            display: block;

                            ul {
                                list-style: none;
                                margin-bottom: 0;
                                width: 100%;
                                max-width: 80%;
                                margin: 0 auto;

                                li {
                                    display: flex;
                                    align-items: center;
                                    margin: 15px 0 0;

                                    a {
                                        color: #fff;
                                        text-align: center;
                                        width: 100%;
                                        border-radius: 30px;
                                        padding: 5px 10px;
                                        font-size: 13px;

                                        span {
                                            margin-left: 0.5rem;
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-ByDatefilterCon {
                            width: 100%;
                            max-width: 85%;
                            margin: 0 auto;

                            .title {
                                display: block;
                                width: 100%;
                                text-align: center;
                                font-weight: 500;
                                border: none;
                                color: #fff;
                                margin-bottom: 0.6rem;
                            }

                            .dk-topFormCon {
                                .dkformgroup {
                                    margin: 0 0 10px;
                                    width: 100%;

                                    input {
                                        border: none;
                                        background: #fff !important;
                                        border-radius: 3px;
                                        height: 35px;
                                        padding: 0 10p;
                                    }

                                    button.btn.gobtn {
                                        display: inline-block;
                                        height: 35px;
                                        background: #2e97b6;
                                        border: none;
                                        color: #fff;
                                        border-radius: 3px;
                                        outline: none;
                                        width: 100%;
                                        margin: 0;
                                        text-transform: capitalize;
                                        font-size: 15px;
                                        line-height: 13px;
                                        box-shadow: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal {
    z-index: 9999 !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}