.dkg-ads-reslist-mainCon {
    width: 100%;
    height: calc(100vh - 135px);
    overflow-y: auto;
    margin-top: 0;
    padding: 15px;
    padding-top: 0;
    margin-top: 120px;

    .dkg-ads-reslistBoxes-main {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .dkg-ads-reslist-panel {
            width: 100%;
            display: flex;
            align-items: start;

            .dkg-ads-reslist-cols {
                background: #fff;
                border: 1px solid #ddd;
                border-radius: 5px;
                width: 100%;
                margin: 12px;
                // height: 295px;

                .dkg-ads-reslist-suMM-panel {
                    height: calc(100vh - 200px);
                }

                .title {
                    border-bottom: 1px solid #ddd;
                    background: #f2f2f2;
                    text-transform: uppercase;
                    font-size: 14px;
                    display: block;
                    text-align: center;
                    padding: 8px 0;
                    border-radius: 5px 5px 0 0;
                }
            }
        }
    }


    &::-webkit-scrollbar {
        display: none;
    }
}