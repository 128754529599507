.dk-exp-income-banking-main {
    display: block;
    width: 100%;
    margin-top: 160px;
    padding: 0 20px;

    .dk-bankingYearsHead-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .title {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            top: 7px;
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            color: #333;
            text-transform: uppercase;
        }

        .dk-bankingSearch-cols {

            .dk-reloadBtn {
                button {
                    display: inline-block;
                    width: 33px;
                    height: 33px;
                    line-height: 10px;
                    background: #fff;
                    color: #848484;
                    text-align: center;
                    border-radius: 50%;
                    outline: none;
                    border: 1px solid #ddd;
                    margin-left: 10px;
                    font-size: 12px;

                    &:hover {
                        background: #f3f3f3;
                        transition: all 0.5s;
                    }
                }
            }

            .dk-search-input {
                position: relative;
                width: 100%;
                max-width: 155px;

                input {
                    height: 32px;
                    background: #fff;
                    border: 1px solid #ddd;
                    text-align: left;
                    padding: 0 12px;

                    &:focus::placeholder {
                        opacity: 0;
                    }
                }

                button {
                    display: inline-block;
                    color: #898989;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: transparent;
                    border: none;
                    outline: none;
                    border-left: 1px solid #ddd;
                    width: 30px;
                    height: 32px;
                    line-height: 12px;
                }
            }

            .dk-bankingHead-right {
                .dropdown {
                    .dropdown-toggle {
                        margin: 0;
                        text-transform: uppercase;
                        font-size: 13px;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        background: #fff;
                        padding: 6px 12px;
                        width: 100%;
                        min-width: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &::after {
                            position: absolute;
                            right: 12px;
                            top: 14px;
                            font-size: 1.2rem;
                        }
                    }

                    .dropdown-menu {
                        border-radius: 0;
                        padding: 0;
                        transform: inherit !important;
                        top: 35px !important;
                        margin: 0 !important;

                        a {
                            font-size: 14px;
                            padding: 6px 12px;
                            border-bottom: 1px solid #ddd;

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        .dk-recwallet-centerCon {
            .dk-filterTagsCon {
                .dkg-pagetitle {
                    font-size: 16px;
                    font-weight: 600;
                    position: relative;
                    top: 2px;
                }

                .dk-filterbtnGrp {
                    .dk-filterbtntext {
                        margin: 0;
                        padding: 5px 15px !important;
                        box-shadow: none !important;
                        border-radius: 30px 0 0 30px;
                        background: #366995 !important;
                        outline: none;
                    }

                    .dk-filterbtnicon {
                        padding: 5px 10px !important;
                        margin: 0;
                        margin-left: 0;
                        box-shadow: none !important;
                        border-radius: 0 30px 30px 0;
                        background: #234158 !important;
                        outline: none;
                    }
                }
            }
        }

        .dk-sendoutsAmRc-dropdown {
            margin-left: 8px;
            display: flex;

            .dropdown {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }

                .dropdown-toggle {
                    background-color: #fff !important;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    box-shadow: none;
                    font-size: 0.81rem;
                    height: 35px;
                    line-height: 30px;
                    margin: 0;
                    padding: 0 !important;
                    width: 35px;
                    color: #333 !important;
                    min-width: 35px;
                    z-index: 99;

                    &:hover {
                        box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
                        outline: 0;
                        transition: 0.5s;
                    }

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    background-color: #234158;
                    left: 50px !important;
                    padding: 5px;
                    top: -15px !important;
                    transform: inherit !important;
                    margin: 0 !important;

                    &::before {
                        border-color: transparent #234158 transparent transparent;
                        _border-color: #000 #234158 #000 #000;
                        border-style: solid;
                        border-width: 8.5px 12px 8.5px 0;
                        content: "";
                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                        height: 0;
                        left: -12px;
                        line-height: 0;
                        position: absolute;
                        top: 24px;
                        width: 0;
                    }

                    .dropdown-item {
                        position: relative;
                        display: inline-block;
                        margin: 0 5px;
                        padding: 0;

                        &:hover {
                            background: transparent;
                        }

                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            display: block;
                        }

                        span.recNoCount {
                            color: #fff;
                            display: block;
                            font-size: 12px;
                            padding: 4px 0 0;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .dk-sendoutyears-rightPanel-btn {
            display: flex;
            align-items: center;

            .pipeline-cafileterCon {
                margin-right: 12px;
                position: relative;

                .dk-lrftCross {
                    position: absolute;
                    top: -4px;
                    right: -3px;
                    cursor: pointer;
                }
            }

            .dkg-bonus-righticon-Con {
                background: #fff;
            }

            .dk-cli-status-dropdown {
                .dropdown-toggle {
                    background: #fff !important;
                }
            }

            .dk-sensdouts-searchCols {
                button {
                    display: inline-flex;
                    width: 35px;
                    height: 35px;
                    color: #848484;
                    border: 1px solid #ddd;
                    margin-right: 10px;
                    border-radius: 50%;
                    background: #fff;
                    align-items: center;
                    justify-content: center;
                    outline: none;

                    &:hover {
                        background: #ccc;
                        color: #333;
                        transition: all 0.5s;
                    }
                }
            }

            .dropdown {
                .dropdown-toggle {
                    box-shadow: none;
                    color: #6c757d;
                    font-size: 13px;
                    margin: 0;
                    min-width: 145px;
                    padding: 0.4rem 0.45rem;
                    text-transform: uppercase !important;
                    background: #fff !important;
                    border: 1px solid #ddd;

                    &::after {
                        color: #6c757d;
                        display: block;
                        font-size: 1.2rem;
                        position: absolute !important;
                        position: absolute;
                        right: 10px;
                        top: 14px;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    border-radius: 0;
                    z-index: 99999;
                    transform: inherit !important;
                    border: none;
                    width: 100%;
                    top: 36px !important;

                    a {
                        align-items: center;
                        display: flex;
                        font-size: 13px;
                        justify-content: space-between;
                        padding: 5px 10px;
                        color: #333;
                        border-bottom: 0.5px solid #acacac;

                        .dropdown-filter-count {
                            align-items: center;
                            background-color: #3c4c62;
                            border-radius: 2px;
                            color: #fff;
                            display: flex;
                            font-size: 12px;
                            justify-content: center;
                            padding: 0 5px;
                            top: 5px;
                        }
                    }
                }

                .dropdown-menu.dk-yearsDropdown-items {
                    a {
                        background: #fff;
                        border-bottom: 1px solid #ddd;
                        color: #333;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }

            i {
                position: absolute;
                top: -11px;
                right: -9px;
            }

            .dkg-banking-categry-filter-234 {
                position: relative;

                .dkg-banking-categry-234 {
                    .dropdown-menu {
                        min-width: 180px;
                        border: 1px solid #ddd;

                        .dkg-banking-categry-item {
                            font-size: 12px;
                            border-bottom: 1px solid #ddd;

                            &:last-child {
                                border-bottom: none;
                            }

                            .dropdown-filter-count {
                                background-color: transparent !important;
                                color: #2c71b2;
                            }

                        }
                    }
                }

                i {
                    position: absolute;
                    top: -11px;
                    right: -9px;
                }
            }
        }
    }

    .dk-bankingYearsTable-main {
        .table-responsive {
            margin-top: 6px;
            overflow-y: auto;
            overflow-x: auto;
            height: calc(100vh - 180px);
            padding-bottom: 0;
            position: relative;

            table.dkg-acc-banking-table45 {
                border: none;
                margin: 0 !important;

                thead {
                    tr {
                        th {
                            vertical-align: middle;
                            text-align: center;
                            font-size: 13px;
                            text-transform: uppercase;
                            background: #3a9469;
                            color: #fff;
                            padding: 8px 12px;
                            border: 1px solid #a0a0a0;
                            white-space: nowrap;
                            position: sticky;
                            top: 0;
                            z-index: 9;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            vertical-align: middle;
                            text-align: center;
                            font-size: 13px;
                            background: #f3f3f3;
                            // padding: 8px 12px;
                            padding: 1px 12px;
                            border: 1px solid #ddd;
                            white-space: nowrap;
                            // .dk-checkinputIcon {
                            //     left: 7px;
                            //     position: relative;
                            //     top: 3px;

                            //     input[type=checkbox] {
                            //         background-color: #f1f1f1;

                            //         &:checked {
                            //             background-color: #34976e;
                            //         }
                            //     }
                            // }

                            &:nth-child(02) {
                                padding: 0;
                                border-bottom: 1px solid gray !important;

                                .caseDiv {
                                    min-height: 37px;
                                    line-height: 37px;
                                }
                            }

                            .dk-checkinputIconCon {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .dk-checkinputIcon {
                                    position: relative;
                                    top: 3px;
                                    margin-left: 5px;

                                    input {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }

                            .commentBoxCon {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                .numCon {
                                    background-color: #eaeaea;
                                    border-radius: 50%;
                                    cursor: pointer;
                                    height: 25px;
                                    line-height: 25px;
                                    width: 25px;

                                    &:hover {
                                        background-color: #234158;
                                        color: #fff;
                                    }
                                }
                            }

                            &:nth-child(05),
                            &:nth-child(06),
                            &:nth-child(07) {
                                text-align: left;
                            }

                            &:nth-child(10) {
                                color: #d91010;
                            }

                            &:nth-child(11) {
                                color: #3a9469;
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {

                            &:nth-child(01),
                            &:nth-child(02),
                            &:nth-child(03),
                            &:nth-child(04),
                            &:nth-child(05),
                            &:nth-child(06),
                            &:nth-child(07),
                            &:nth-child(08),
                            &:nth-child(09) {
                                border: none;
                                background-color: transparent;
                                cursor: text;
                            }

                            &:nth-child(10),
                            &:nth-child(11) {
                                background-color: #eaeaea;
                                font-weight: 600;
                                text-align: center;
                            }

                            &:nth-child(12) {
                                display: none;
                            }
                        }
                    }
                }

            }
        }

    }
}

.cursor-p {
    cursor: pointer;
}

.dkg-banking-addnew-btn {
    background-color: #692d77 !important;
}

.dkg-addnew-paymenttask-modal-21 .modal-dialog .modal-content .dkg-addnew-payment-modalbody-21 .form-group input.dkg-addnew-banking-input {
    color: #333;
}

.dkg-addnew-paymenttask-modal-21 .modal-dialog .modal-content .dkg-addnew-payment-modalbody-21 .form-group .dkg-payment-task-drop12.dkg-banking-addnew-dropdown .dk-suMMCaseStatusDropDwn .dropdown::after {
    border-color: #848484 transparent transparent transparent;
}

.dkg-addnew-paymenttask-modal-21 .modal-dialog .modal-content .dkg-addnew-payment-modalbody-21 .form-group .dkg-payment-task-drop12.dkg-banking-addnew-dropdown .dk-suMMCaseStatusDropDwn .dropdown .dropdown-menu {
    max-height: 395px !important;

    ul.list-unstyled {
        margin-bottom: 0;
    }
}