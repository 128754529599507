// Start CSS For JobsAdminSecond Submenu
.dk-jobsAdminSubmenuCon {
    background: #eaeaea;
    position: fixed;
    top: 140px;
    left: 0;
    width: 100%;
    z-index: 9;
    .dk-jobsAdminNavCon{
        ul {
            display: flex;
            list-style: none;
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            text-align: center;
            background: #fff;
            li {
                display: flex;
                width: 100%;
                max-width: 9.09%;
                text-align: center;
                a {
                    color: #333;
                    font-size: 15px;
                    padding: 0.875rem 0.6rem;
                    width: 100%;
                    text-decoration: none;
                    font-size: 14px;
                    height: 44px;
                    &.active, &:hover {
                        background-color: #D6DCE5 ;
                        color: #333;
                    }
                }
            }
        }
    }
}
// Start CSS For JobsAdminSecond Submenu
.dk-jobsadmin-pageCon{
    background-color: #D6DCE5 ;
    height: 100vh;
    margin-top: 184px;
    .dkjobs-admin-categoryPageCon{
        .dkjobs-categoryblockCon{
            border: 1px solid #cbc9c9;
            border-radius: 5px;
            background: #fff;
            margin-top: 1.85rem !important;
            min-height: 500px;
            .dk-jobsadminblock-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem 0;
                .dk-jobsadmin-leftCon{
                    input{
                        min-width: 300px;
                        max-width: 300px;
                        border: 1px solid #ced4da;
                        height: 32px;
                        &:focus{
                            box-shadow: none;
                            outline: 0;
                        }
                    }
                }
                .dk-jobsadmin-RightCon{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        border-radius: 5px;
                        background-color: #eaeaea;
                        border: 1px solid #ddd;
                        color: #333;
                    }
                }
            }
            .dk-backend-categorytableCon{
                .dk-backend-categorytable{
                    thead{
                        tr th{
                            position: sticky;
                            top: -1px;
                            background: #2e5a7c;
                            color: #fff;
                            z-index: 1;
                            cursor: pointer;
                            text-align: center;
                            padding:0.45rem 0.35rem;
                            font-size: 13px;
                            border-bottom: none;
                            vertical-align: middle;
                            &:nth-child(02){
                                text-align: left;
                            }
                        }
                    }
                    tbody{
                        tr td {
                            background: #eaeaea;
                            color: #333;
                            border: 1px solid #d5d5d5;
                            font-size: 13px;
                            padding:0.2rem 0.35rem;
                            text-align: center;
                            vertical-align: middle;
                            &:nth-child(02){
                                text-align: left;
                            }
                            input{
                                background-color: transparent;
                                border-radius: 0px;
                                font-size: 13px;
                                border: none;
                                height: 25px;
                                &::placeholder {
                                    color: #333;
                                    opacity: 1; /* Firefox */
                                }
                                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                                  color: #333;
                                }
                                &::-ms-input-placeholder { /* Microsoft Edge */
                                  color: #333;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}