.dk-sendouts-containerMain.dk-jobSpecsContainer-main {
    margin-top: 170px;
    display: flex;

    .dk-sendoutsLeftpanel {
        background: #eaeaea;
        height: calc(100vh - 171px);
        margin-right: 0;
        margin-top: 0;
        max-width: 170px;
        overflow-y: auto;
        padding: 0 !important;
        width: 100%;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                width: 100%;

                &:last-child {
                    a {
                        border-bottom: none;
                    }
                }

                a {
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    color: #000;
                    font-size: 12px;
                    line-height: 15px;
                    margin: 0;
                    border-bottom: 1px solid #ccc;
                    padding: 12px 12px;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 400;

                    .dk-badgeNo {
                        background: #26596D;
                    }
                }

                a.active {
                    background: #374252;
                    color: #fff;
                }
            }
        }
    }

    .dk-sendouts-rightPanel {
        background: #374252;
        margin-top: 0;
        padding: 20px;
        width: 100%;

        .dk-sendoutsHead {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dk-sendouts-yearsDropdown {
                .dropdown-toggle {
                    background: #26596D !important;
                    border-radius: 3px;
                    box-shadow: none;
                    color: #fff !important;
                    font-size: 13px;
                    margin: 0;
                    min-width: 110px;
                    padding: 7px 10px;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    background: #fff;
                    border: none;
                    border-radius: 0;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    margin: 35px 0 0 !important;
                    min-width: 110px;
                    padding: 0;
                    -webkit-transform: inherit !important;
                    transform: inherit !important;
                    z-index: 99999;

                    a {
                        padding: 6px 10px;
                        font-size: 13px;

                        &:hover {
                            background: #f8f9fa;
                        }
                    }

                    a.active {
                        background: #2e75b6;
                    }
                }
            }

            .dk-sendouts-months {
                display: flex;
                justify-content: flex-end;
                margin-left: 0;
                width: 100%;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;

                    li {
                        display: inline-block;
                        list-style-type: none;

                        &:first-child {
                            a {
                                border-left: 1px solid #616167;
                            }
                        }

                        a {
                            background: #4a5057;
                            border-bottom: 1px solid #616167;
                            border-right: 1px solid #616167;
                            border-top: 1px solid #616167;
                            color: #fff;
                            cursor: pointer;
                            float: left;
                            height: 35px;
                            padding: 7px 10px;
                            text-align: center;
                            width: 75px;
                        }

                        a.active {
                            background: #26596D;
                        }

                    }
                }

                .addMonthBtn {
                    border: 1px solid #7d7d87;
                    border-radius: 50%;
                    color: #7d7d87;
                    display: inline-block;
                    font-size: 15px;
                    height: 35px;
                    line-height: 35px;
                    margin-left: 12px;
                    text-align: center;
                    width: 35px;

                    &:hover {
                        background: #7d7d87;
                        color: #fff;
                        transition: all .5s;
                    }
                }
            }
        }

        .dk-salesSendouts-table.dk-kpijobsSummaryTable {
            margin-top: 8px;
            margin-bottom: 0;
            height: calc(100vh - 272px);
            overflow-y: auto;

            table {
                border: none;
                margin-bottom: 0;

                tr {
                    th {
                        background: #26596D !important;
                        border: 1px solid #a0a0a0 !important;
                        border-bottom: none;
                        color: #fff;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 10px 12px;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;
                        position: sticky;
                        top: 0;
                        z-index: 9999;
                    }

                    td.cursor-p {
                        cursor: pointer;
                    }

                    td {
                        background: #fff;
                        border: 0.5px solid #ddd;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 5px 8px;
                        position: relative;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        .dk-smsTableCheckbox {
                            .dk-chkSendouts {
                                margin: 0 3px;
                            }
                        }

                        .dk-text-red {
                            color: #ff3547;
                        }

                        .dk-text-green {
                            color: #00c851;
                        }


                        input.dk-chkSendouts {
                            -webkit-appearance: none;
                            appearance: none;
                            background: #e4e8ec;
                            border: 1px solid #ccc;
                            border-radius: 0;
                            box-sizing: border-box;
                            box-sizing: content-box;
                            cursor: pointer;
                            height: 12px;
                            position: relative;
                            transition: all .3s linear;
                            width: 12px;
                        }

                        .dk-sales-updatedUser {
                            position: relative;

                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                            }

                            &:hover {
                                .tooltipText {
                                    display: inline-block;
                                }
                            }
                        }

                        .dk-salesSendouts-users {
                            .dropdown-toggle {
                                background-color: transparent !important;
                                border-radius: 50%;
                                box-shadow: none !important;
                                color: #333;
                                margin: 0;
                                padding: 0;

                                &::after {
                                    display: none;
                                }

                                img {
                                    border-radius: 50%;
                                    left: 0;
                                    position: relative;
                                    top: 0;
                                    width: 35px;
                                }
                            }

                            .dropdown-menu {
                                background: #f2f2f2;
                                border-radius: 3px;
                                left: 68px !important;
                                min-width: -webkit-fit-content;
                                min-width: -moz-fit-content;
                                min-width: fit-content;
                                padding: 8px 5px;
                                right: 0 !important;
                                top: 0 !important;
                                -webkit-transform: inherit !important;
                                transform: inherit !important;
                                width: -webkit-fit-content;
                                width: -moz-fit-content;
                                width: fit-content;

                                &::before {
                                    border-color: transparent #ccc transparent transparent;
                                    _border-color: #000 #ccc #000 #000;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    content: "";
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    height: 0;
                                    left: -12px;
                                    line-height: 0;
                                    position: absolute;
                                    top: 8px;
                                    width: 0;
                                }

                                a {
                                    display: inline-block;
                                    margin: -5px 3px;
                                    padding: 0;
                                    width: 30px;
                                    background-color: transparent;
                                    border: 0;
                                    clear: both;
                                    font-weight: 400;
                                    text-align: inherit;
                                    white-space: nowrap;

                                    img {
                                        border-radius: 50%;
                                        height: 30px;
                                        width: 30px;
                                    }
                                }

                            }
                        }
                    }
                }

                tfoot {
                    .sumtr {
                        td.transparent {
                            border: none;
                        }

                        td {
                            background: transparent;
                            color: #fff;
                            padding: 11px 8px;
                            border-color: #a9a9a9;
                        }
                    }
                }
            }
        }
    }

    .dk-sendouts-rightPanel.dk-refusedBg {
        .dk-sendoutsHead {
            .dk-sendouts-yearsDropdown {
                .dropdown {
                    .dropdown-toggle {
                        background: #a84d4d !important;
                    }
                }
            }

            .dk-sendouts-months {
                ul {
                    li {
                        a.active {
                            background: #a84d4d;
                        }
                    }
                }
            }
        }

        .dk-salesSendouts-table {
            table {
                tr {
                    th {
                        background: #a84d4d !important;
                    }
                }
            }
        }
    }

    .dk-sendouts-rightPanel.dk-joinedBg {
        .dk-sendoutsHead {
            .dk-sendouts-yearsDropdown {
                .dropdown {
                    .dropdown-toggle {
                        background: #4768b0 !important;
                    }
                }
            }

            .dk-sendouts-months {
                ul {
                    li {
                        a.active {
                            background: #4768b0;
                        }
                    }
                }
            }
        }

        .dk-salesSendouts-table {
            table {
                tr {
                    th {
                        background: #4768b0 !important;
                    }
                }
            }
        }
    }

    .dk-sendouts-rightPanel.dk-creditBg {
        .dk-sendoutsHead {
            .dk-sendouts-yearsDropdown {
                .dropdown {
                    .dropdown-toggle {
                        background: #da3d5e !important;
                    }
                }
            }

            .dk-sendouts-months {
                ul {
                    li {
                        a.active {
                            background: #da3d5e;
                        }
                    }
                }
            }
        }

        .dk-salesSendouts-table {
            table {
                tr {
                    th {
                        background: #da3d5e !important;
                    }
                }
            }
        }
    }
}

.tooltipText {
    display: none;
    background: #333;
    border-radius: 3px;
    color: #fff;
    display: none;
    font-size: 11px;
    padding: 2px 8px;
    text-transform: capitalize;
    top: 42px;
    white-space: nowrap;
    z-index: 99;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);

    &::after {
        border-color: transparent transparent #333;
        _border-color: #000 #000 #333 #000;
        border-style: solid;
        border-width: 0 7.5px 12px;
        content: "";
        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
        height: 0;
        line-height: 0px;
        top: -11px;
        width: 0;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
}