.dk-qualyeduConMain{
    .dk-qualyTopHead{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        .title{
            position: inherit !important;
            left: inherit !important;
            transform: inherit !important;
            font-size: 18px;
            font-weight: 600;
            color: #333;
        }
        .btn-Con{
            a {
                display: inline-block;
                background: #f2f2f2;
                padding: 6px 15px;
                font-size: 14px;
                border-radius: 5px;
                color: #333;
                font-weight: 500;
                border: 1px solid #ccc;
            }
        }
    }
    .description{
        p{
            color: #333;
            margin-top: 15px;
            margin-bottom: 25px;
            font-size: 14px;
            text-align: justify;
        }
    }
    .dk-qualyeduBoxCon{
        .dk-educationBox {
            width: 100%;
            position: relative;
            .d-flex{
                .dk-educationDate{
                    width: 100%;
                    max-width: -webkit-fit-content;
                    max-width: -moz-fit-content;
                    max-width: fit-content;
                    margin-left: 0;
                    margin-right: 28px;
                    font-weight: 600;
                    color: #193b50;
                    background: #d9dfe7;
                    padding-right: 50px;
                }
                .dk-educationDetails {
                    width: 100%;
                    max-width: 100%;
                    position: relative;
                    padding-left: 10px;
                    background: #d9dfe7;
                    .sbtitle {
                        margin: 0;
                        font-size: 17px;
                        font-weight: 700;
                        color: #193b50;
                        text-transform: uppercase;
                        i {
                            margin-right: 3px;
                            color: #4b7b98;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        margin-left: 0;
                        font-size: 14px;
                        i{
                            display: inline-block;
                            margin-right: 3px;
                        }
                    }
                    .dk-colsTexttag {
                        font-size: 14px;
                        margin-left: 0;
                        border: 1px solid #ccc;
                        padding: 12px 15px;
                        border-radius: 3px;
                        margin-top: 10px;
                        margin-bottom: 32px;
                        width: 100%;
                        max-width: 550px;
                        background: #d9dfe7;
                    }
                }
            }
        }
        .dk-educationBox:before {
            content: "";
            width: 100%;
            max-width: 100px;
            height: 1px;
            background: #4b7b98;
            position: absolute;
            left: 95px;
            top: 11px;
        }
    }
}