.dk-talentpoolMain {
    margin-top: 115px;

    .dk-talentpoolHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .dk-talentpoolLeft {
            display: flex;
            align-items: center;

            .dkg-projet-excBtn {
                color: #333;
                text-decoration: none;
                background-color: #eaeaea;
                border-radius: 5px;
                border: 1px solid #ddd;
                padding: 7px 10px;
                white-space: nowrap;
            }

            .dk-talentpoolImgCon {
                max-width: 70px;

                .dk-talentpoolImg {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    margin-right: 12px;
                }
            }

            .dk-talentpooltextCon {
                .dk-talentpooltextTitle {
                    font-size: 18px;
                    font-weight: 500;
                }

                .small {
                    a {
                        color: #333;
                        text-decoration: none;
                        margin-left: 0.5rem;
                    }
                }

                .dk-plus-minus-btnCon {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

            }
        }

        .dk-talentpoolCentre {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            // top: 3px;

            .dk-TitleCon {
                display: flex;
                align-items: center;

                .dk-TitleTab {
                    a {
                        color: #333;
                        padding: 0 0 10px;
                        font-size: 16px;
                        font-weight: 600;
                        margin-right: 25px;
                        border: none;
                    }

                    &:first-child {
                        position: relative;

                        &::after {
                            content: "/";
                            position: absolute;
                            right: 6px;
                            top: -3px;
                            color: #333;
                            font-size: 19px;
                            font-weight: 600;
                        }
                    }

                    &.active {
                        a {
                            background: 0 0;
                            border: none;
                            color: #007bff;
                            position: relative;

                            &::after {
                                content: "";
                                width: 100%;
                                height: 2px;
                                background: #2e97b6;
                                position: absolute;
                                left: 0;
                                bottom: 4px;
                                display: none;
                            }
                        }
                    }
                }
            }

            .dk-filterTagsCon {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .dk-pipeline-pagetitle {
                    margin-top: 7px;
                    font-size: 16px;
                    font-weight: 600;
                }

                .dk-filterbtnGrp {
                    margin-top: 2px;

                    .dk-filterbtntext {
                        background: #366995;
                        font-size: 12px !important;
                        color: #fff;
                        padding: 5px 7px;
                        border-top-left-radius: 30px;
                        border-bottom-left-radius: 30px;
                        line-height: 10px;
                        text-transform: inherit;

                        &.btn-primary {
                            background: #366995 !important;
                        }
                    }

                    .dk-filterbtnicon {
                        background: #234158;
                        font-size: 11px;
                        padding: 5px 7px;
                        border-top-right-radius: 30px;
                        border-bottom-right-radius: 30px;

                        &.btn-primary {
                            background-color: #234158 !important;
                        }
                    }
                }
            }
        }

        .dk-talentpoolRight {
            .dk-btnCon {
                a {
                    color: #333;
                    text-decoration: none;
                    background-color: #eaeaea;
                    border-radius: 5px;
                    border: 1px solid #ddd;
                    padding: 7px 10px;
                    white-space: nowrap;
                }
            }
        }
    }

    .dkg-project-table-234 .dk-candinatePipeMain.page-margin-top.dkg-pipeline-mainCon.dkg-pipelines-mainpageCon.dkg-dt-pageCon-14 {
        margin-top: 20px !important;
    }

    .dk-candinatePipeMain.page-margin-top.dkg-pipeline-mainCon.dkg-pipelines-main-tableCon {
        .pipe-newCVTableCon.dkg-dt-tableCon234.table-responsive {
            height: calc(100vh - 233px) !important;
        }
    }

    .dk-talentpoolTableCon {
        margin-top: 15px;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh - 175px);
        padding-bottom: 0;
        position: relative;

        .dk-talentpoolTable {
            thead {
                tr th {
                    background: #26597d;
                    border-color: #4483af;
                    font-size: 13px;
                    color: #fff;
                    font-weight: normal;
                    white-space: nowrap;
                    padding: 10px 12px;
                    border-bottom: none;
                    text-align: center;
                    vertical-align: middle;
                    position: sticky;
                    top: 0;
                    z-index: 999;
                    box-sizing: border-box;

                    &:first-child {
                        text-align: left;
                        font-weight: 600;
                        // width: 340px;
                        column-width: 180px;

                        & .goback-Text {
                            color: #fff;
                            opacity: .9;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        background: #fff;
                        border-color: 1px solid #ddd;
                        font-size: 13px;
                        color: #333;
                        font-weight: normal;
                        white-space: nowrap;
                        padding: 5px 12px;
                        text-align: center;
                        vertical-align: middle;
                        cursor: pointer;

                        &:first-child {
                            // width: 340px;
                            column-width: 180px;
                        }

                        &:nth-child(03),
                        &:nth-child(04),
                        &:nth-child(05) {
                            max-width: 90px;
                        }

                        .dk-candpoolcols {
                            // display: flex;
                            // align-items: center;

                            .dk-candProfileimg {
                                img {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                }
                            }

                            .dk-talentpooltextCon {
                                display: block;
                            }

                            .dk-candidateName {
                                margin-left: 0;
                                font-size: 15px;
                                color: #007bff;
                                cursor: pointer;
                                font-size: 13px;
                                font-weight: normal;
                                text-align: left;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.9;
                                }
                            }

                            .small {
                                text-align: left;
                                margin-left: 9px;
                            }

                            .dk-plus-minus-btnCon {
                                display: flex;
                                align-items: center;
                            }
                        }

                        .dk-plus-minus-btnCon {
                            a {
                                display: block;
                                color: #6e6b6b;

                                &:hover {
                                    color: blueviolet;
                                }
                            }
                        }
                    }

                    &.active-candidate.active {
                        td {
                            background-color: #eaeaea !important;
                        }
                    }
                }
            }
        }
    }

    .dkg-cvpreviewCon {
        width: 100%;
        margin-top: 15px;

        .dkg-srch-boxinputCon {
            margin-bottom: 10px;

            .dk-boxCols {
                label {
                    text-align: center;
                    width: 100%;
                }

                input {
                    border: 1px solid #ddd;
                    background-color: #eaeaea;
                    border-radius: 0;
                    height: 38px;
                    cursor: pointer;
                }
            }
        }

        .dkg-cvpreview {
            width: 100%;

            iframe {
                display: inherit !important;
                height: calc(100vh - 175px);
                overflow-y: auto;
            }

            embed {
                height: calc(100vh - 175px) !important;
                overflow-y: auto;
            }

            // iframe {
            //     display: inherit !important;
            //     width: 100%;
            //     height: calc(100vh - 190px);

            //     &.dkg-savedprojects-iframe {
            //         #document {
            //             html {
            //                 body {
            //                     color: #333;
            //                 }
            //             }
            //         }
            //     }
            // }
            .dkg-cvpreview-img {
                width: 100%;
                // height: calc(100vh - 190px);
                // overflow-y: auto;
            }
        }

        // .dkg-cvpreview::-webkit-scrollbar {
        //     display: none;
        // }
    }
}