.dk-jobDetailsMain{
    margin-top: 170px;
    padding: 0 20px;
    .dk-jobdetails-header{
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        position: relative;
        margin-bottom: 3px;
        h3.title{
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            background-color: #fff;
            text-transform: uppercase;
            padding-bottom: 5px;
            i{
                cursor: pointer;
                color: #617781;
            }
        }
    }
    table{
        tr{
            th{
                vertical-align: middle;
                min-width: 100px;
                max-width: 100px;
                text-align: center;
                color: #929292;
                text-transform: uppercase;
            }
            td{
                vertical-align: middle;
                min-width: 100px;
                max-width: 100px;
                text-align: center;
                color: #929292;
                text-transform: uppercase;
                height: 110px;
                border:1px solid #afa7a7;
                font-size: 15px;
                padding: 10px 15px;
                transition: all 0.5s;
                position: relative;
                &:hover{
                    background: #859ca7 !important;
                    transition: all 0.5s;
                }
                .heading{
                    color: #929292;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                .title{
                    color: #ebe9e9;
                    text-transform: uppercase;
                }
                .react-contextmenu{
                    background: #fff;
                    border-radius: 3px;
                    min-width: 150px;
                    *box-shadow:-2px 2px 10px rgba(0,0,0,0.05);
                    padding: 3px 0;
                    margin-top: 10px !important;
                    pointer-events: all !important;
                    .react-contextmenu-item{
                        font-size: 14px;
                        padding:6px 15px;
                        text-align: left;
                        color: #333;
                        &:hover{
                            background: #2685A5;
                            color: #fff;
                            i{
                                color: #fff;
                            }
                        }
                        i{
                            color: #2980b9;
                        }
                    }
                }
                .react-contextmenu-wrapper{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }            
            td.tdBg1{
                background-color: #f2f2f2;
                &:hover{
                    background: #f2f2f2 !important;
                }
            }
            td.rowBg1{
                background-color: #617781;
                cursor: pointer;
            }
            td.rowBg2{
                background-color: #736191;
                cursor: pointer;
            }
            td.rowBg3{
                background-color: #57496d;
                cursor: pointer;
            }
            td.rowBg4{
                background-color: #2a9290;
                cursor: pointer;
            }
            td.rowBg5{
                background-color: #1b6867;
                cursor: pointer;
            }
        }
    }
}

.dk-jobDetailinfoModal{
    .modal-dialog{
        min-width: 40%;
        .modal-content{
            font-family: "Roboto",sans-serif !important;
            border-radius: 5px;
            .modal-header{
                background-color: #617781;
                border-radius: 0;
                padding:8px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                border-radius: 4px 4px 0 0;
                .modal-title{
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    font-size: 18px;
                    font-weight: 400;
                }
                .close{
                    color: #fff;
                    padding: 0;
                    opacity: 1;
                    font-weight: normal;
                    outline: none;
                    position: absolute;
                    right: 26px;
                }
            }
            .modal-body{
                padding: 20px;
                font-weight: 400;
            }
        }
    }
}

.dk-jobCheckDetailModal.modal.right{
    .modal-dialog{
        right: -600px !important;
        min-width: 600px !important;
        max-width: 600px;
        height: 100vh;
        position: fixed;
        top: 0px;
        margin:0 !important;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
           -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
             -o-transition: opacity 0.3s linear, right 0.3s ease-out;
                transition: opacity 0.3s linear, right 0.3s ease-out;
        .modal-content{
            height: 100vh;
            border-radius: 0;
            font-family: "Segoe UI",Arial,sans-serif;
            .modal-header{
                background-color: #617781;
                border-radius: 0;
                padding: 9px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .modal-title{
                    color: #fff;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    text-transform: capitalize;
                    opacity: 0.85;
                    font-size: 20px;
                    font-weight: 400;
                }
                .close{
                    color: #fff;
                    opacity: 0.85;
                    font-weight: normal;
                    outline: none;
                    &:hover{
                        background:#859ca7;
                    }
                }
            }
        }
    }    
}

.dk-jobCheckDetailModal.modal.right.show{
    .modal-dialog{
        right:0 !important;
    }
}

.dk-jobUpdateDetailsModal{
    .modal-dialog{
        min-width: 30%;
        .modal-content{
            border-radius: 5px;
            font-family: "Roboto",sans-serif !important;
            .modal-header{
                background-color: #617781;
                border-radius: 4px 4px 0 0;
                padding: 8px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .modal-title{
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    font-size: 18px;
                }
                .close{
                    color: #fff;
                    text-shadow: none;
                    opacity:1;
                    text-shadow: none;
                    font-weight: normal;
                    padding: 0;
                    position: absolute;
                    right: 10px;
                    top:6px;
                    outline: none;
                    margin: 0;
                    font-size:28px;
                }
            }
            .modal-body{
                padding: 25px;
                form{
                    label{
                        width: 100%;
                        font-weight: 400;
                        font-size: 16px;
                    }
                    .dk-updateBtn{
                        background: #2685A5;
                        color: #fff;
                        border: 1px solid #2685A5;
                        width: 100px;
                        text-align: center;
                        display: inline-block;
                        padding: 7px 12px;
                        text-transform: uppercase;
                        font-size: 13px;
                        line-height: 1.5385;
                        border-radius: 0.1875rem;
                        outline: none;
                        &:hover{
                            background: #2a9290;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
}