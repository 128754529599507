.dk-loginsdetalisMain {
    padding: 0 20px;
    font-family: "Roboto", sans-serif !important;
    width: 100%;
    margin-top: 120px;
    height: calc(100vh - 130px);

    .dk-loginstopHeadTitle {
        padding-bottom: 10px;
        width: 100%;
        text-align: center;

        h3.dk-logins-title {
            margin-bottom: 0;
            margin-top: 0;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .dkg-logins-dashboard-boxsCon {
        width: 100%;
        display: flex;
        padding: 0;
        height: calc(100vh - 130px);
        overflow-y: auto;

        .dkg-logins-LeftCon {
            width: 40%;
            // height: calc(100vh - 180px);
            height: calc(100vh - 175px);

            .dkg-loginsdetalis-boxRow {
                display: flex;
                height: 51%;

                .dk-loginsdetalisCols {
                    background: #f2f2f2;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    width: 100%;
                    margin: 9px;

                    .title {
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        font-weight: 500;
                        padding: 6px 10px;
                        background: #e2e2e2;
                        border-radius: 5px 5px 0 0;
                        font-size: 15px;
                        border-bottom: 1px solid #ccc;

                        span.dk-itTeamImg {
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            margin-right: 10px;

                            img {
                                display: inline-block;
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                            }
                        }
                    }

                    .dk-itTeamBox {
                        // min-height: 220px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        min-height: 231px;

                        .dk-itTeamBox-cols {
                            margin: 0 15px;
                            text-align: center;
                            font-weight: 400;

                            span.dk-itteamSummary-no {
                                display: inline-block;
                                margin-bottom: 3px;
                            }

                            span.text-blue {
                                display: block;
                                font-weight: 500;
                                color: #3c97b6;
                            }

                            span.text-yellow {
                                display: block;
                                font-weight: 500;
                                color: #d58b5d;
                            }

                            span.text-green {
                                display: block;
                                font-weight: 500;
                                color: #3a9469;
                            }
                        }
                    }
                }
            }
        }

        .dkg-logins-RightCon {
            width: 60%;

            .dkg-loginsdetalis-boxRow {
                display: flex;

                .dk-loginsdetalisCols {
                    background: #f2f2f2;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    width: 100%;
                    margin: 9px;

                    .title {
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        font-weight: 500;
                        padding: 6px 10px;
                        background: #e2e2e2;
                        border-radius: 5px 5px 0 0;
                        font-size: 15px;
                        border-bottom: 1px solid #ccc;

                        span.dk-itTeamImg {
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            margin-right: 10px;

                            img {
                                display: inline-block;
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                            }
                        }
                    }

                    .dk-itTeamBox {

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        height: calc(100vh - 220px);

                        .dk-itTeamBox-cols {
                            margin: 0 15px;
                            text-align: center;
                            font-weight: 400;

                            span.dk-itteamSummary-no {
                                display: inline-block;
                                margin-bottom: 3px;
                            }

                            span.text-blue {
                                display: block;
                                font-weight: 500;
                                color: #3c97b6;
                            }

                            span.text-yellow {
                                display: block;
                                font-weight: 500;
                                color: #d58b5d;
                            }

                            span.text-green {
                                display: block;
                                font-weight: 500;
                                color: #3a9469;
                            }
                        }
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}