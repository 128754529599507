.dk-moduleBankingProcess-main {
    background: #eaeaea;
    margin-top: 0;
    padding-top: 120px;

    .dk-moduleBankingProcess-cols {
        background: #eaeaea;
        display: flex;

        @media(max-width:992px) {
            flex-wrap: wrap;
        }

        .dk-moduleBankingProcess-leftPanel {
            margin-top: 0;
            max-width: 170px;
            min-width: 170px;
            width: 100%;

            @media(max-width:992px) {
                max-width: 100%;
                min-width: 100%;
            }

            ul::-webkit-scrollbar {
                width: 0px;
            }

            ul::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            ul::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                outline: 1px solid slategrey;
            }

            ul {
                background: #244158;
                height: calc(100vh - 120px);
                list-style: none;
                margin: 0;
                min-height: auto;
                overflow-y: auto;
                padding: 0;

                @media(max-width:992px) {
                    height: auto;
                    display: flex;
                }

                li {
                    display: block;
                    list-style-type: none;
                    width: 100%;

                    a {
                        border-bottom: 1px solid #3e5b73;
                        color: #ddd;
                        display: block;
                        font-family: Roboto, sans-serif !important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 15px;
                        text-transform: inherit;
                        width: 100%;

                        @media(max-width:992px) {
                            white-space: nowrap;
                            border-bottom: none;
                        }
                    }

                    a.active {
                        background: #eaeaea;
                        color: #333;
                    }
                }
            }
        }

        .dk-moduleBankingProcess-rightPanel.bgTransparent {
            background: #eaeaea;

            .dk-financeClients-tableMain {
                padding: 0;
            }

            .dk-moduleBankingProcess-rightCols {
                background: #eaeaea;
            }
        }

        .dk-moduleBankingProcess-rightPanel {
            width: 100%;
            margin-left: 30px;
            padding-right: 15px;
            position: relative;

            .dk-statmentFlex {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dk-searchCols {
                    width: 100%;
                    max-width: 160px;
                    position: relative;

                    input {
                        text-align: left;
                        padding: 0 12px;
                        border: 1px solid #ccc;
                        height: 32px;

                        &:focus::placeholder {
                            opacity: 0;
                        }
                    }

                    button {
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: inline-block;
                        width: 32px;
                        text-align: center;
                        border: 1px solid #ddd;
                        outline: none;
                        background: transparent;
                        height: 32px;
                        color: #757575;
                        font-size: 13px;
                    }
                }
            }

            .dk-financeYears-main {
                display: inline-flex;
                position: absolute;
                left: 0;
                top: 10px;

                .dk-financeYears-cols {
                    a {
                        margin-right: 18px;
                        font-size: 14px;
                        font-weight: 600;
                        color: #737373;
                        display: inline-block;
                        border-radius: 3px;
                        border-bottom: 3px solid transparent;
                    }

                    a.active {
                        border-bottom: 3px solid #3c97b6;
                        color: #3c97b6;
                    }
                }
            }

            .dk-financeYears-addNew {
                a {
                    display: inline-block;
                    background: #fff;
                    padding: 5px 13px;
                    border-radius: 3px;
                    color: #333;
                    border: 1px solid #ddd;

                    &:hover {
                        background: #2e75b6;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
            }

            @media(max-width:992px) {
                margin-left: 0;
                height: calc(100vh - 185px);
                padding: 0 12px;
                margin-top: 10px;
            }

            .title {
                text-align: center;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 600;
                padding: 11px 0;

                @media(max-width:992px) {
                    text-align: left;
                    padding: 10px 15px;
                }
            }

            .dk-getStartedRight-detail {
                .nav {
                    position: absolute;
                    right: 18px;
                    top: 8px;

                    .nav-item {
                        margin-right: 18px;

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                            border-bottom: 3px solid transparent;
                            box-shadow: none;
                            color: #737373;
                            font-family: Roboto, sans-serif !important;
                            font-size: 16px;
                            font-weight: 400;
                            padding: 0 0 2px;
                            text-transform: capitalize;
                        }

                        a.active {
                            border-bottom: 3px solid #3c97b6;
                            color: #3c97b6;
                        }
                    }
                }
            }

            .dk-moduleBankingProcess-rightCols {
                width: 100%;
                background: #fff;
                height: calc(100vh - 165px);
                border: none;

                @media(max-width:992px) {
                    height: auto;
                    min-height: 300px;
                    border-top: none;
                }

                .dk-getStartedRight-detail {
                    .dk-rightDetail-panel {
                        .d-flex {
                            @media(max-width:992px) {
                                flex-wrap: wrap;
                            }
                        }

                        .dk-detailLefttabsMain {
                            background: #eaeaea;
                            display: inline-block;
                            height: calc(100vh - 168px);
                            margin-right: 0;
                            max-width: 195px;
                            width: 100%;

                            @media(max-width:992px) {
                                height: auto;
                                max-width: 100%;
                            }

                            .MuiBox-root::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                            }

                            .MuiBox-root::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            }

                            .MuiBox-root::-webkit-scrollbar-thumb {
                                background-color: darkgrey;
                                outline: 0px solid slategrey;
                            }

                            .MuiBox-root {
                                width: 100%;

                                @media(max-width:992px) {
                                    display: flex;
                                    overflow-x: auto;
                                }

                                .MuiTabs-root {
                                    flex-wrap: wrap;
                                    min-height: auto;
                                    overflow: visible;
                                    width: 100%;

                                    .MuiTabs-scroller {
                                        overflow: visible !important;
                                        white-space: normal;

                                        .MuiTabs-indicator {
                                            display: none;
                                        }

                                        .MuiTabs-flexContainer {
                                            flex-wrap: wrap;
                                            justify-content: space-between;
                                            overflow: visible;

                                            .MuiButtonBase-root {
                                                align-items: flex-start;
                                                align-items: left;
                                                border-bottom: 1px solid #ddd;
                                                color: #757575;
                                                display: block;
                                                flex-shrink: inherit;
                                                font-size: 15px;
                                                font-weight: 400;
                                                max-width: 100%;
                                                min-height: auto;
                                                min-width: inherit !important;
                                                opacity: 1;
                                                outline: none;
                                                overflow: visible;
                                                padding: 12px 15px;
                                                position: relative;
                                                text-align: center;
                                                text-transform: capitalize;
                                                width: 100%;

                                                @media(max-width:992px) {
                                                    white-space: nowrap;
                                                }
                                            }

                                            .MuiButtonBase-root.Mui-selected {
                                                background: #fff;
                                                color: #606060;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .dk-financeClients-tableMain {
            padding: 0;

            table {
                margin-bottom: 0;
                border: none;

                tr {
                    th {
                        padding: 10px 10px;
                        vertical-align: middle;
                        font-size: 13px;
                        background: #244158;
                        border-bottom: none;
                        color: #fff;
                        text-align: center;
                        border-color: #8e8e8e;
                    }

                    td {
                        background: #fff;
                        color: #333;
                        vertical-align: middle;
                        font-size: 13px;
                        text-align: center;
                        padding: 4px 10px;

                        &:nth-child(01) {
                            width: 70px;
                        }

                        &:nth-child(05) {
                            width: 70px;
                        }

                        &:nth-child(06) {
                            width: 70px;
                        }

                        .dk-invoiceStatus {
                            .dropdown {
                                .dropdown-toggle {
                                    padding: 0;
                                    margin: 0;
                                    box-shadow: none;
                                    border: none;
                                    color: #fff;
                                    text-transform: inherit;
                                    width: 100%;

                                    div {
                                        width: 100%;
                                        height: 38px;
                                        line-height: 38px;
                                        font-size: 14px;
                                    }

                                    &::after {
                                        display: none;
                                    }
                                }

                                .dropdown-menu {
                                    padding: 0;
                                    border-radius: 0;
                                    border: none;
                                    width: 100%;
                                    min-width: 110px;
                                    transform: inherit !important;
                                    top: 40px !important;
                                    margin: 0 !important;

                                    a {
                                        display: block;
                                        font-size: 14px;
                                        padding: 6px 10px;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        .dk-financeAction {
                            a {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                line-height: 30px;
                                border-radius: 3px;
                                color: #fff;
                                text-align: center;
                                margin: 0 3px;
                            }

                            a.viewBtn {
                                background: #ffb11c;
                            }

                            a.downloadBtn {
                                background: #3a9469;
                            }
                        }

                        .dk-checkinputIcon {
                            left: 7px;
                            position: relative;
                            top: 3px;

                            input[type=checkbox] {
                                background-color: #f1f1f1;

                                &:checked {
                                    background-color: #34976e;
                                }
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {
                            padding: 10px 8px;
                        }
                    }
                }
            }
        }
    }
}

.dk-dkBankingYears-main {
    display: block;
    width: 100%;
    margin-top: 120px;
    padding: 0 20px;

    .dk-bankingYearsHead-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .title {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            top: 7px;
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            color: #333;
            text-transform: uppercase;
        }

        .dk-bankingSearch-cols {

            .dk-reloadBtn {
                button {
                    display: inline-block;
                    width: 33px;
                    height: 33px;
                    line-height: 10px;
                    background: #fff;
                    color: #848484;
                    text-align: center;
                    border-radius: 50%;
                    outline: none;
                    border: 1px solid #ddd;
                    margin-left: 10px;
                    font-size: 12px;

                    &:hover {
                        background: #f3f3f3;
                        transition: all 0.5s;
                    }
                }
            }

            .dk-search-input {
                position: relative;
                width: 100%;
                max-width: 155px;

                input {
                    height: 32px;
                    background: #fff;
                    border: 1px solid #ddd;
                    text-align: left;
                    padding: 0 12px;

                    &:focus::placeholder {
                        opacity: 0;
                    }
                }

                button {
                    display: inline-block;
                    color: #898989;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: transparent;
                    border: none;
                    outline: none;
                    border-left: 1px solid #ddd;
                    width: 30px;
                    height: 32px;
                    line-height: 12px;
                }
            }

            .dk-bankingHead-right {
                .dropdown {
                    .dropdown-toggle {
                        margin: 0;
                        text-transform: uppercase;
                        font-size: 13px;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        background: #fff;
                        padding: 6px 12px;
                        width: 100%;
                        min-width: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &::after {
                            position: absolute;
                            right: 12px;
                            top: 14px;
                            font-size: 1.2rem;
                        }
                    }

                    .dropdown-menu {
                        border-radius: 0;
                        padding: 0;
                        transform: inherit !important;
                        top: 35px !important;
                        margin: 0 !important;

                        a {
                            font-size: 14px;
                            padding: 6px 12px;
                            border-bottom: 1px solid #ddd;

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        .dk-recwallet-centerCon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .dk-filterTagsCon {
                .dkg-pagetitle {
                    font-size: 16px;
                    font-weight: 600;
                    // position: relative;
                    // top: 2px;
                }

                .dk-filterbtnGrp {
                    .dk-filterbtntext {
                        margin: 0;
                        padding: 5px 15px !important;
                        box-shadow: none !important;
                        border-radius: 30px 0 0 30px;
                        background: #366995 !important;
                        outline: none;
                    }

                    .dk-filterbtnicon {
                        padding: 5px 10px !important;
                        margin: 0;
                        margin-left: 0;
                        box-shadow: none !important;
                        border-radius: 0 30px 30px 0;
                        background: #234158 !important;
                        outline: none;
                    }
                }
            }
        }

        .dk-sendoutsAmRc-dropdown {
            margin-left: 8px;
            display: flex;

            .dropdown {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }

                .dropdown-toggle {
                    background-color: #fff !important;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    box-shadow: none;
                    font-size: 0.81rem;
                    height: 35px;
                    line-height: 30px;
                    margin: 0;
                    padding: 0 !important;
                    width: 35px;
                    color: #333 !important;
                    min-width: 35px;
                    z-index: 99;

                    &:hover {
                        box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
                        outline: 0;
                        transition: 0.5s;
                    }

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    background-color: #234158;
                    left: 50px !important;
                    padding: 5px;
                    top: -15px !important;
                    transform: inherit !important;
                    margin: 0 !important;

                    &::before {
                        border-color: transparent #234158 transparent transparent;
                        _border-color: #000 #234158 #000 #000;
                        border-style: solid;
                        border-width: 8.5px 12px 8.5px 0;
                        content: "";
                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                        height: 0;
                        left: -12px;
                        line-height: 0;
                        position: absolute;
                        top: 24px;
                        width: 0;
                    }

                    .dropdown-item {
                        position: relative;
                        display: inline-block;
                        margin: 0 5px;
                        padding: 0;

                        &:hover {
                            background: transparent;
                        }

                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            display: block;
                        }

                        span.recNoCount {
                            color: #fff;
                            display: block;
                            font-size: 12px;
                            padding: 4px 0 0;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .dk-sendoutyears-rightPanel-btn {
            display: flex;
            align-items: center;

            .pipeline-cafileterCon {
                margin-right: 12px;
                position: relative;

                .dk-lrftCross {
                    position: absolute;
                    top: -4px;
                    right: -3px;
                    cursor: pointer;
                }
            }

            .dkg-bonus-righticon-Con {
                background: #fff;
            }

            .dk-cli-status-dropdown {
                .dropdown-toggle {
                    background: #fff !important;
                }
            }

            .dk-sensdouts-searchCols {
                button {
                    display: inline-flex;
                    width: 35px;
                    height: 35px;
                    color: #848484;
                    border: 1px solid #ddd;
                    margin-right: 10px;
                    border-radius: 50%;
                    background: #fff;
                    align-items: center;
                    justify-content: center;
                    outline: none;

                    &:hover {
                        background: #ccc;
                        color: #333;
                        transition: all 0.5s;
                    }
                }
            }

            .dropdown {
                .dropdown-toggle {
                    box-shadow: none;
                    color: #6c757d;
                    font-size: 13px;
                    margin: 0;
                    min-width: 145px;
                    padding: 0.4rem 0.45rem;
                    text-transform: capitalize !important;
                    background: #fff !important;
                    border: 1px solid #ddd;

                    &::after {
                        color: #6c757d;
                        display: block;
                        font-size: 1.2rem;
                        position: absolute !important;
                        position: absolute;
                        right: 10px;
                        top: 14px;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    border-radius: 0;
                    z-index: 99999;
                    transform: inherit !important;
                    border: none;
                    width: 100%;
                    top: 36px !important;
                    background-color: #eaeaea;

                    a {
                        align-items: center;
                        display: flex;
                        font-size: 13px;
                        justify-content: space-between;
                        padding: 5px 10px;
                        color: #333;
                        background-color: #eaeaea;
                        border-bottom: 0.5px solid #acacac;

                        .dropdown-filter-count {
                            align-items: center;
                            background-color: #3c4c62;
                            border-radius: 2px;
                            color: #fff;
                            display: flex;
                            font-size: 12px;
                            justify-content: center;
                            padding: 0 5px;
                            top: 5px;
                        }
                    }
                }
                &.dkg-type-rightfilter{
                    .dropdown-menu{
                        max-width: 144px !important;
                        min-width: 144px !important;
                    }
                }
                .dropdown-menu.dk-yearsDropdown-items {
                    a {
                        background-color: #eaeaea;
                        border-bottom: 1px solid #ddd;
                        color: #333;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }

            i {
                position: absolute;
                top: -11px;
                right: -9px;
            }

            .dkg-banking-categry-filter-234 {
                position: relative;

                .dkg-banking-categry-234 {
                    .dropdown-menu {
                        min-width: 180px;
                        border: 1px solid #ddd;
                        background-color: #f2f2f2;

                        .dkg-banking-categry-item {
                            font-size: 12px;

                            border-bottom: 1px solid #ddd;

                            &:last-child {
                                border-bottom: none;
                            }

                            .dropdown-filter-count {
                                background-color: transparent !important;
                                color: #2c71b2;
                                font-weight: bold;
                            }
                            &.dkg-type-counter{
                                .dropdown-filter-count {
                                        align-items: center;
                                        background-color: #3c4c62 !important;
                                        border-radius: 2px;
                                        color: #fff;
                                        display: flex;
                                        font-size: 12px;
                                        justify-content: center;
                                        padding: 0 5px;
                                    }
                            }

                        }
                    }
                }

                i {
                    position: absolute;
                    top: -11px;
                    right: -9px;
                }
                .dkg-typefilter-dropdown{
                    .dkg-typefilter-dropdown-toggle{
                        font-size: 12px;
                        min-width: 130px;
                    }
                    .dkg-typefilter-dropdown-menu{
                        .dkg-typefilter-dropdown-item{
                            align-items: center;
                            display: flex;
                            font-size: 13px;
                            justify-content: space-between;
                            padding: 8px 10px;

                            .dropdown-filter-count {
                                align-items: center;
                                background-color: #3c4c62;
                                border-radius: 2px;
                                color: #fff;
                                display: flex;
                                font-size: 12px;
                                justify-content: center;
                                padding: 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .dk-bankingYearsTable-main {

        .table-responsive {
            margin-top: 6px;
            overflow-y: auto;
            overflow-x: auto;
            height: calc(100vh - 180px);
            padding-bottom: 0;
            position: relative;

            table {
                border: none;
                margin: 0 !important;

                thead {
                    tr {
                        th {
                            vertical-align: middle;
                            text-align: center;
                            font-size: 13px;
                            text-transform: uppercase;
                            background: #692D77;
                            color: #fff;
                            padding: 8px 12px;
                            border: 1px solid #a0a0a0;
                            white-space: nowrap;
                            position: sticky;
                            top: 0;
                            z-index: 9;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            vertical-align: middle;
                            text-align: center;
                            font-size: 13px;
                            background: #fff;
                            // padding: 8px 12px;
                            padding: 1px 12px;
                            border: 1px solid #f1ecec;
                            white-space: nowrap;
                            // .dk-checkinputIcon {
                            //     left: 7px;
                            //     position: relative;
                            //     top: 3px;

                            //     input[type=checkbox] {
                            //         background-color: #f1f1f1;

                            //         &:checked {
                            //             background-color: #34976e;
                            //         }
                            //     }
                            // }

                            &:nth-child(02),
                            &:nth-child(05)  {
                                padding: 0;
                                border-bottom: 1px solid gray !important;

                                .caseDiv {
                                    min-height: 37px;
                                    line-height: 37px;
                                }
                            }

                            &:nth-child(05)  {
                                padding: 0;
                                border-bottom: 1px solid rgb(148, 147, 147) !important;
                                border-left: 1px solid rgb(148, 147, 147) !important;
                                .caseDiv {
                                    min-height: 37px;
                                    line-height: 37px;
                                }
                            }
                            &:nth-child(06),
                            &:nth-child(07) {
                                text-align: left;
                            }

                            .dk-checkinputIconCon {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .dk-checkinputIcon {
                                    position: relative;
                                    top: 3px;
                                    margin-left: 5px;

                                    input {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }

                            .commentBoxCon {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                .numCon {
                                    background-color: #eaeaea;
                                    border-radius: 50%;
                                    cursor: pointer;
                                    height: 25px;
                                    line-height: 25px;
                                    width: 25px;

                                    &.zeroCommentCount {
                                        color: #eaeaea;
                                    }

                                    &:hover {
                                        background-color: #234158;
                                        color: #fff;
                                    }
                                }
                            }
                            .dkg-typecol-dropdown{
                                .dkg-typecol-dropdown-toggle{
                                    background-color: transparent;
                                    box-shadow: none;
                                    color: #fff;
                                    font-size: 13px;
                                    margin: 0;
                                    padding: 0!important;
                                    text-transform: capitalize;
                                    white-space: nowrap;
                                    width: 100%;
                                    &::after{
                                        display: none;
                                    }
                                    .caseDiv{
                                        cursor: pointer;
                                        height: 37px;
                                        line-height: 37px;
                                        padding: 0 7px;
                                        font-size: 12px;

                                    }
                                }
                                .dkg-typecol-dropdown-menu{
                                    border-radius: 0;
                                    padding: 0;
                                    width: 100%;
                                    transform: translate(-1px,35px)!important;
                                    min-width: 90px;
                                    max-width: 90px;
                                    .dropdown-item {
                                        border-bottom: 1px solid #aaa;
                                        font-size: 13px;
                                        padding: 6px 10px;
                                        text-align: left;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        &:last-child{
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                            &:nth-child(06),
                            &:nth-child(07),
                            &:nth-child(08) {
                                text-align: left;
                            }

                            &:nth-child(12) {
                                color: #d91010;
                            }

                            &:nth-child(13) {
                                color: #3a9469;
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {

                            &:nth-child(01),
                            &:nth-child(02),
                            &:nth-child(03),
                            &:nth-child(04),
                            &:nth-child(05),
                            &:nth-child(06),
                            &:nth-child(07),
                            &:nth-child(08),
                            &:nth-child(09),
                            &:nth-child(10) {
                                border: none;
                                background-color: transparent;
                                cursor: text;
                            }

                            &:nth-child(11),
                            &:nth-child(12) {
                                background-color: #eaeaea;
                                font-weight: 600;
                                text-align: center;
                                white-space: nowrap;
                            }

                            &:nth-child(13) {
                                display: block;
                            }
                        }
                    }
                }

            }
        }

    }
}

.cursor-p {
    cursor: pointer;
}

.dkg-banking-addnew-btn {
    background-color: #692d77 !important;
}

.dkg-addnew-paymenttask-modal-21 .modal-dialog .modal-content .dkg-addnew-payment-modalbody-21 .form-group input.dkg-addnew-banking-input {
    color: #333;
}

.dkg-addnew-paymenttask-modal-21 .modal-dialog .modal-content .dkg-addnew-payment-modalbody-21 .form-group .dkg-payment-task-drop12.dkg-banking-addnew-dropdown .dk-suMMCaseStatusDropDwn .dropdown::after {
    border-color: #848484 transparent transparent transparent;
}

.dkg-addnew-paymenttask-modal-21 .modal-dialog .modal-content .dkg-addnew-payment-modalbody-21 .form-group .dkg-payment-task-drop12.dkg-banking-addnew-dropdown .dk-suMMCaseStatusDropDwn .dropdown .dropdown-menu {
    max-height: 395px !important;

    ul.list-unstyled {
        margin-bottom: 0;
    }
}