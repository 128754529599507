.dkg-like-dislike-mainCon {
    height: calc(100vh - 380px);
    overflow-y: auto;
    overflow-x: hidden;
}

// .dkg-like-dislike-mainCon::-webkit-scrollbar {
//     display: none;
// }

.dkg-like-dislike-mainCon::-webkit-scrollbar {
    width: 10px;
    cursor: pointer;
}

.dkg-like-dislike-mainCon::-webkit-scrollbar-track {
    background: #eaeaea;
}

.dkg-like-dislike-mainCon::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 4px;
    cursor: pointer;
}