.modal-dialog.dk-viewResModalDialog {
    min-width: 40%;

    &.dkg-clientinfo-RightModal {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 600px !important;
        max-width: 600px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }

    .modal-content {
        background-color: transparent;

        .modal-header.dk-resourcePopupHedaer {
            border-radius: 0;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            background-color: #2196f3;
            padding: 10px;
            // border-top-left-radius: 5px;
            // border-top-right-radius: 5px;
            border-radius: 0;

            .modal-title {
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
            }

            .close {
                color: #fff;
                opacity: 1;
            }
        }

        .modal-body {
            padding: 10px 25px 20px;
            background-color: #fff;
            // border-bottom-left-radius: 5px;
            // border-bottom-right-radius: 5px;
            height: calc(100vh - 50px);
            border-radius: 0;

            form {
                .form-group {
                    display: -webkit-flex;
                    display: flex;
                    margin-bottom: 1rem;
                    -webkit-align-items: center;
                    align-items: center;

                    label.dk-mlmangerLabel {
                        min-width: 155px;
                        font-size: 14px;
                        margin-bottom: 0;
                        text-align: left !important;
                    }

                    .dk-reSPopUpIcon {
                        -webkit-align-items: center;
                        align-items: center;
                        background-color: #f5f2f2;
                        border-radius: 50%;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-flex-shrink: 0;
                        flex-shrink: 0;
                        height: 38px;
                        -webkit-justify-content: center;
                        justify-content: center;
                        width: 38px;

                        .selectedUserImgPop {
                            width: 100%;
                            border-radius: 50%;
                        }
                    }

                    .dk-addNewSlectDropDwn {
                        width: 100%;

                        .ui.selection.dropdown {
                            width: 100%;
                            border-radius: 0;
                            background-color: #fff;
                            font-size: .85rem;
                            height: 31px;
                            color: #797474;
                            padding: .435rem 1.25rem !important;
                            background: #fff;
                            border: 1px solid #c1c9dc;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 1.5385;
                            min-height: 2.304em;
                            white-space: nowrap;

                            .text {
                                color: #797474 !important;
                                font-size: 0.85rem;
                            }

                            .menu {
                                border-radius: 0;

                                .item {
                                    border-top: 1px solid #ddd;
                                    color: #797474;
                                    background-color: rgb(255, 255, 255);
                                    pointer-events: all;
                                    padding: .5rem .4rem !important;
                                }

                                .selected.item {
                                    color: #797474;
                                    font-weight: normal;

                                }
                            }
                        }
                    }

                    input.form-control {
                        background-color: #fff;
                        border-radius: 0;
                        font-size: .87rem;
                        padding: .5rem;
                        border: 1px solid #c1c9dc !important;
                        text-align: center;
                        box-shadow: none;
                        height: 31px;
                    }

                    span {
                        button {
                            display: inline-block;
                            border: none;
                            background-color: #26597d;
                            color: #fff;
                            height: 31px;
                            padding: 2px 7px;
                            border-radius: 0;
                            font-size: .89rem;
                        }
                    }
                }

                .dk-addresbtnCont {
                    width: 100%;
                    text-align: center;

                    button,
                    button:hover {
                        background: #26597d !important;
                        border: none;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 8px 25px;
                        font-weight: 500;
                        font-size: 14px;
                        outline: none;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                }

            }
        }
    }
}