.dk-serchTextCon{
    .topRow{
        .dk-boxCols{
            label{
                text-align: center;
                width: 100%;
            }
            input.form-control {
                background-color: #fff;
                border-radius: 0;
                font-size: .87rem;
                padding: .5rem;
                border: 1px solid #ccc;
                text-align: center;
                box-shadow: none;
                height: 32px;
            }
            .dk-chooseColorDrpdwn{
                .ui.selection.dropdown{
                    border-radius: 0;
                    height: 32px;
                }
            }
            .dk-btnCon{
                margin-top: 30px;
                display: flex;
                align-items: center;
                a{
                    display: inline-flex;
                    background: #fff;
                    border: 1px solid #ccc;
                    padding: 6px 10px;
                    margin-right: 10px;
                    border-radius: 3px;
                    align-items: center;
                    color: #333;
                    height: 32px;
                    i{
                        color: #333;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
    .dk-textAreaRow{
        .dk-textAreaCon{
            margin: 5px 0px 15px;
            .form-control1{
                border-radius: 0;
            }
        }
    }
}