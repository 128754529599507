.dk-task-category-dropdown {

    a.dk-taskcatDropdwntoggle,
    a.dk-taskcatDropdwntoggle:hover {
        box-shadow: none;
        border: 1px solid #c1c9dc;
        padding: 5px 14px;
        height: 34px;
        border-radius: 3px;
        background: #fff;
        margin-top: 0px;
        font-family: Verdana, sans-serif;
        font-size: 14px;
        text-transform: capitalize;
        line-height: 22px;
        color: #9b9999;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            position: absolute;
            right: 10px;
            top: 15px;
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
        }
    }

    .dk-taskcategory-dropdown-menu {
        padding: 0;
        border-radius: 0;
        width: 100%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        border: none;

        input {
            border-radius: 0;
        }

        ul {
            margin-bottom: 0;
            border-radius: 0;

            .dropdown-item {
                padding: 6px 10px;
                font-size: 12px;
                color: #333;
                border-bottom: 1px solid #dddddd;

                &:last-child {
                    border-bottom: none;
                }

                &:after {
                    display: none !important;
                }
            }
        }
    }
}

.dk-taskTypeDropdown-main {
    .dropdown-toggle {
        box-shadow: none;
        outline: none;
        width: 100%;
        background: #e9ecef;
        font-size: 13px;
        padding: 0;
        height: 32px;
        margin: 0;
        border: 1px solid #c1c9dc;

        &::after {
            margin-left: 10px;
        }
    }

    .dropdown-menu {
        transform: inherit !important;
        top: 33px !important;
        left: inherit !important;
        right: 0 !important;
        margin: 0 !important;
        padding: 0;
        border-radius: 0;
        width: 100%;

        a.dropdown-item {
            font-size: 13px;
            display: block;
            padding: 5px 10px;
        }
    }
}