.dk-sales-jobspecMain{
    margin-top:155px;
    .nav-tabs-navigation{
        display: flex;
        justify-content: center;
        padding: 10px 0;
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                display: inline-block;
                a{
                    color: #000;
                    border: 0;
                    margin:0 10px;
                    border-radius: 3px;
                    line-height: 15px;
                    text-transform: uppercase;
                    font-size: 12px;
                    padding: 10px 18px;
                    background-color: transparent;
                    &:hover{
                        background: #2e75b6;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
                a.active{
                    background: #2e75b6;
                    color: #fff;
                }
            }
        }
    }
    .dk-jobspecContainer{
        background: #374252;
        margin-top: 1rem;
        height: calc( 100vh - 210px );
        .dk-jobspec-table-Header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .dk-jobspec-LeftCon{
                display: flex;
                align-items: center;
                .dk-searchBoxGroup{
                    width: 100%;
                    max-width:210px;
                    .input-group{
                        input{
                            border-radius: 0;
                            border: dotted 1px #ccc !important;
                            font-size: 13px;
                            padding:10px 12px;
                            height: 35px;
                            background-color: transparent;
                            box-shadow: none !important;
                        }
                    }
                }
                .dk-document-iconCon{
                    margin-left: 1rem;
                    i{
                        font-size: 23px;
                        color: #fff;
                    }
                }
                .dk-user-dropdownCon{
                    margin: 0 1rem;
                    .dk-user-dropdown{
                        .dk-user-dropdown-toggle{
                            box-shadow: none;
                            padding: 0;
                            margin: 0;
                            i{
                                font-size: 23px;
                                color: #fff;
                            }
                            &::after{
                                display: none;
                                box-shadow: none;
                            }
                        }
                        .dk-user-dropdownMenu{
                            padding: 6px 8px;
                            left: 30px !important;
                            right: inherit !important;
                            top: -42px !important;
                            margin: 0 !important;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            background: #f2f2f2;
                            &::after {
                                content: '';
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                border-color: transparent #cccccc transparent transparent;
                                line-height: 0px;
                                _border-color: #000000 #cccccc #000000 #000000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                                position: absolute;
                                left: -12px;
                                top: 15px;
                            }
                            a {
                                margin: 0 3px;
                                padding: 0;
                                img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
                .dk-pluseIconCon{
                    background-color: #374252;
                    border: 1px solid #7d7d87;
                    display: inline-block;
                    align-items: center;
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 27px;
                    i{
                        font-size: 13px;
                        color: #7d7d87;
                    }
                }
            }
            .dk-caseopend-RightCon{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 1.25rem 0;
                .dk-month-dropdownCon{
                    margin-right: 0.5rem;
                    .dk-monthBox{
                        .dropdown-toggle{
                            box-shadow: none;
                            margin: 0;
                            background-color: #2e75b6;
                            color: #fff;
                            font-size: 13px;
                            padding: 0.5rem 0.5rem;
                            min-width: 70px;
                            border-radius: 0;
                            &::after{
                                display: none;
                            }
                        }
                        .dropdown-menu{
                            min-width: 70px;
                            border-radius: 0;
                            padding: 0;
                            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                            a{
                                font-weight: 400;
                                font-size: 13px;
                                display: block;
                                padding:5px 12px;
                                &:hover{
                                    background-color: #f2f2f2;
                                }
                            }
                        }
                    }
                }
                .dk-date-formCon,.dk-date-toCon{
                    .dk-nativedatepicker-grid{
                        .dk-nativedatepicker{
                            margin: 0;
                            .MuiInputBase-root.MuiInput-root{
                                background-color: #fff;
                                border: 1px solid #ddd;
                                max-width: 140px;
                                &::before{
                                    border-bottom: none;
                                }
                                input{
                                    padding: 7px 5px;
                                }
                            }
                        }
                    }
                }
                .dk-calendCon{
                    a{
                        display: inline-block;
                        width: 45px;
                        height: 33px;
                        font-size: 13px;
                        background-color: transparent;
                        border: 1px solid #ccc;
                        color: #fff;
                        line-height: 29px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .dk-jobspec-tableCon{
            height: calc( 100vh - 310px );
            overflow-y: auto;
            .dk-jobspec-table{
                margin-bottom: 0;
                thead{
                    tr th{
                        font-size: 13px;
                        font-weight: 400;
                        text-align: center;
                        color: #fff;
                        vertical-align: middle;
                        white-space: nowrap;
                        background-color: #2e75b6;
                        padding: 10px 12px;
                        border-bottom: none;
                        position: sticky;
                        top: 0;
                        z-index: 9;
                    }
                }
                tbody{
                    tr td {
                        font-size: 12px;
                        font-weight: 400;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;
                        padding: 0;
                        background: #fff;
                        .checkNo{
                            input[type="checkbox"]{
                                margin-top: 4px;
                            }
                        }
                        .dk-jobspec-status-cols{
                            background-color: #009a9a;
                            color: #fff;
                            padding: 9px 15px;
                        }
                        .dk-jobspec-rec-cols{
                            img{
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}