.dk-moduleNotification-main {
    margin-top: 170px;
    height: calc(100vh - 190px);
    overflow-y: auto;
    overflow-x: hidden;

    .title {
        font-size: 20px;
        font-weight: 500;
        position: relative;
        margin-bottom: 10px;
        text-align: center;
        display: inline-block;
    }

    .dk-moduleNoti-Cols {
        padding: 0;
        font-family: "Roboto", sans-serif !important;
        width: 100%;

        .col-md-2 {
            @media(min-width:768px) {
                flex: 0 0 16.666667%;
                max-width: 16.666667%;
                padding-right: 20px;
            }
        }

        .col-md-6 {
            @media(min-width:768px) {
                flex: 0 0 49%;
                max-width: 49%;
            }
        }

        .col-md-4 {
            @media(min-width:768px) {
                flex: 0 0 36.33%;
                max-width: 36.33%;
                padding-left: 5px;
            }
        }



        .dk-title {
            font-weight: 500;
            font-size: 20px;
        }

        .dk-moduleNotiLeftPanel::-webkit-scrollbar {
            width: 0px;
        }

        .dk-moduleNotiLeftPanel::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
        }

        .dk-moduleNotiLeftPanel::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0 solid slategrey;
        }

        .dk-moduleNotiLeftPanel {
            background: #eaeaea;
            margin-top: 0;
            height: calc(100vh - 172px);
            overflow-y: auto;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;

                    a {
                        display: block;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333;
                        padding: 10px 12px;
                        position: relative;
                        border-bottom: 1px solid #ccc;

                        svg {
                            display: inline-block;
                            width: 24px;
                            text-align: left;
                            color: #939393;
                            position: relative;
                            bottom: 1px;
                        }

                        span.dk-notiBadge {
                            display: inline-block;
                            width: 20px;
                            height: 16px;
                            background: transparent;
                            border-radius: 3px;
                            color: #2c71b2;
                            font-size: 13px;
                            position: absolute;
                            right: 10px;
                            top: 13px;
                            text-align: center;
                            line-height: 17px;
                        }
                    }

                    a.active {
                        background: #244158;
                        color: #ddd;

                        span.dk-notiBadge {
                            background: #2e97b6;
                            color: #fff;
                        }
                    }
                }
            }
        }



        .dk-notiMiddleSec {
            height: calc(100vh - 173px);
            overflow-y: auto;
            padding: 0 15px;
        }

        .dk-notiMiddleSec::-webkit-scrollbar {
            width: 0px;
        }

        .dk-notiMiddleSec::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        .dk-notiMiddleSec::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }

        .dk-moduleNoti-box.active {
            background: #244158;

            p {
                color: #ddd;
            }

            .d-timeflex {
                border-left: 1px solid #1d5989 !important;

                small {
                    color: #ddd;

                    &:last-child {
                        border-top: 1px solid #1d5989 !important;
                    }

                    svg {
                        color: #ddd !important;
                    }
                }
            }

            .sbtitle {
                color: #ddd !important;
                padding: 0 10px;
            }

            .dk-notificationText {
                border-left: 1px solid #1d5989 !important;
            }

            .dk-notificationImg {
                span {
                    color: #fff;
                }
            }
        }

        .dk-moduleNoti-box {
            background: #eaeaea;
            display: flex;
            align-items: center;
            padding: 0 12px;
            padding-right: 0;
            border-left: 4px solid #2e97b6;
            margin-bottom: 12px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
            transition: all 0.5s;
            cursor: pointer;

            &:hover {
                background: #244158;
                transition: all 0.5s;

                .dk-notificationText {
                    color: #ddd;

                    p {
                        color: #ddd;
                    }

                    .dk-notification-tags {
                        .sbtitle {
                            color: #ddd;
                        }
                    }

                    .d-timeflex {
                        small {
                            svg {
                                color: #ddd;
                            }
                        }
                    }
                }
            }

            .dk-notificationImg {
                width: 100%;
                max-width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 12px;
                margin-top: 0;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                span {
                    text-align: center;
                    display: block;
                    margin-top: 3px;
                    font-size: 16px;
                    color: #8a8a8a;
                }
            }

            .dk-notificationText {
                font-weight: 400;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-left: 1px solid #ccc;
                padding-left: 15px;

                .sbtitle {
                    font-weight: 500;
                    padding: 0 10px;
                    position: relative;
                    bottom: 6px;

                    span {
                        font-weight: 300;
                    }
                }

                .dk-notification-tags {
                    display: flex;

                    .sbtitle {
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        color: #333;
                        margin-right: 30px;
                        font-size: 13px;

                        svg {
                            display: inline-block;
                            margin-right: 3px;
                        }
                    }

                    a {
                        display: inline-block;
                        background: #dadada;
                        padding: 0px 12px;
                        border-radius: 30px;
                        color: #333;
                        text-decoration: none;
                        margin-right: 7px;

                        &:last-child {
                            margin-right: 0;
                        }

                        span.closeTags {
                            display: inline-block;
                            background: #ccc;
                            color: #000;
                            padding: 2px 8px;
                            border-radius: 0 30px 30px 0;
                            margin-left: 5px;

                            &:hover {
                                background: #333;
                                color: #fff;
                                transition: all 0.5s;
                            }
                        }
                    }
                }

                .d-timeflex {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    width: 100%;
                    border-left: 1px solid #ccc;
                    margin-left: 0;
                    min-height: 78px;
                    padding: 0;
                    width: 100%;
                    max-width: 120px;
                    white-space: nowrap;

                    &.dkg-notification-twoblock {

                        // min-width: 180px !important;
                        // max-width: 180px !important;
                        small {
                            &:first-child {
                                text-transform: uppercase;
                            }
                        }
                    }

                    small {
                        display: inline-block;
                        margin-right: 0;
                        width: 100%;
                        max-width: 100%;
                        text-align: center;
                        padding: 8px 10px;

                        svg {
                            font-size: 15px;
                            display: inline-block;
                            position: relative;
                            bottom: 1px;
                            margin-right: 3px;
                            color: #333;
                        }

                        &:first-child {
                            position: relative;
                            top: 3px;
                        }

                        &:last-child {
                            border-bottom: none;
                            border-top: 1px solid #ccc;
                        }
                    }
                }

                p {
                    margin: 0;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    padding-right: 15px;

                    span {
                        width: 100%;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    small {
                        width: 100%;
                        text-align: right;
                        max-width: 80px;
                    }
                }

                small {
                    display: block;
                    width: 100%;
                    max-width: 75px;
                    margin-top: 0;
                    font-size: 12px;
                    text-align: right;
                }
            }
        }
        .dkg-blackbook-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 15px;
            margin-bottom: 5px;
            .dkg-blackbook-LeftCon{
                display: flex;
                align-items: center;
                .dk-mailshotsSearch{
                    .input-group{
                        max-width: 145px;
                        min-width: 145px;
                        width: 100%;
                        input{
                            background-color: #fff!important;
                            border: 1px solid #ddd;
                            border-radius: 0;
                            box-shadow: none;
                            height: 35px;
                            line-height: 1.3rem;
                            margin: 0;
                            padding: 0.5rem 0.55rem;
                            text-align: left;
                        }
                        .input-group-append{
                            margin-left: -1px;
                            .btn-secondary{
                                background-color: #fff!important;
                                border: 1px solid #ddd;
                                border-radius: 0;
                                box-shadow: none;
                                height: 35px;
                                line-height: 1.3rem;
                                margin: 0;
                                padding: 0.5rem 0.55rem;
                                i {
                                    color: #6c757d;
                                }
                            }
                        }
                    }
                }
                .dkg-plusicon{
                    box-shadow: none;
                    font-size: .81rem;
                    margin: 0;
                    padding: 0!important;
                    width: 35px;
                    height: 35px;
                    line-height: 30px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i{
                        color: #6c757d;
                    }
                }
            }
            .dkg-blackbook-titleCon{
               h2{
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin-top: 2px;
                    text-transform: uppercase;
               }
            }
            .dkg-blackbook-rightCon{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .dkg-blackbook-dropdown{
                    .dkg-blackbook-dropdown-toggle{
                        box-shadow: none;
                        font-size: 13px;
                        margin: 0;
                        min-width: 130px;
                        padding: 0.5rem 0.45rem;
                        text-transform: capitalize;
                        border: 1px solid #ddd;
                        background-color: #fff;
                        position: relative;
                        &:after {
                            color: #6c757d;
                            display: block;
                            font-size: 1.2rem;
                            position: absolute!important;
                            position: absolute;
                            right: 10px;
                            top: 16px;
                        }
                    }
                    .dkg-blackbook-dropdown-menu{
                        border-radius: 0;
                        max-height: 480px;
                        overflow-y: auto;
                        padding: 0;
                        position: absolute!important;
                        right: 5px!important;
                        .dropdown-item {
                            align-items: center;
                            display: flex;
                            font-size: 13px;
                            justify-content: space-between;
                            padding: 8px 10px;
                            border-bottom: 1px solid #ddd;
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
        .dkg-compy-noti-boxcon-234 {
            // height: calc(100vh - 173px);
            // overflow-y: auto;
            padding: 0 15px;
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
            display: flex;

            .dkg-compy-noti-left-boxcon {
                flex: 0 0 67%;
                max-width: 67%;

                .dkg-compy-noti-box-cols {
                    .dk-moduleNoti-box.active {
                        background: #244158;

                        p {
                            color: #ddd;
                        }

                        .d-timeflex {
                            border-left: 1px solid #1d5989 !important;

                            small {
                                color: #ddd;

                                &:last-child {
                                    border-top: 1px solid #1d5989 !important;
                                }

                                svg {
                                    color: #ddd !important;
                                }
                            }
                        }

                        .sbtitle {
                            color: #ddd !important;
                            padding: 0 10px;
                        }

                        &:hover {
                            background-color: #fff;
                            transition: all 0.5s;

                            .dk-notificationText {
                                color: #333;

                                .dkg-compy-noti-boxs-Header {
                                    color: #333;

                                    .d-timeflex {
                                        border-left: 1px solid #ddd !important;

                                        small {
                                            color: #333;

                                            &:last-child {
                                                border-bottom: none;
                                                border-top: 1px solid #ccc !important;
                                            }
                                        }
                                    }
                                }

                                p {
                                    color: #333;
                                }

                                .dk-notification-tags {
                                    .sbtitle {
                                        color: #333;
                                    }
                                }

                                .d-timeflex {
                                    small {
                                        svg {
                                            color: #333;
                                        }
                                    }
                                }

                                .dkg-compy-noti-boxs-Header {
                                    .timeflex {
                                        span {
                                            color: #333;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-notificationText {
                            border-left: 1px solid #ccc !important;
                        }

                        .dk-notificationImg {
                            span {
                                color: #333;
                            }
                        }
                    }

                    .dk-moduleNoti-box {
                        background: #eaeaea;
                        display: flex;
                        align-items: center;
                        padding: 0 12px;
                        padding-right: 0;
                        border-left: 4px solid #2e97b6;
                        margin-bottom: 12px;
                        border-top: 1px solid #ddd;
                        border-bottom: 1px solid #ddd;
                        border-right: 1px solid #ddd;
                        transition: all 0.5s;
                        cursor: pointer;
                        min-height: 80px;

                        &:hover {
                            background-color: #fff;
                            transition: all 0.5s;

                            .dk-notificationText {
                                color: #333;

                                p {
                                    color: #333;
                                }

                                .dk-notification-tags {
                                    .sbtitle {
                                        color: #333;
                                    }
                                }

                                .d-timeflex {
                                    small {
                                        svg {
                                            color: #333;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-notificationImg {
                            width: 100%;
                            max-width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            margin-right: 12px;
                            margin-top: 0;

                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                            }

                            span {
                                text-align: center;
                                display: block;
                                margin-top: 3px;
                                font-size: 16px;
                                color: #8a8a8a;
                            }
                        }

                        .dk-notificationText {
                            font-weight: 400;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-left: 1px solid #ccc;
                            padding-left: 15px;

                            .sbtitle {
                                font-weight: 500;
                                padding: 0 10px;
                                position: relative;
                                bottom: 6px;

                                span {
                                    font-weight: 300;
                                }
                            }

                            .dk-notification-tags {
                                display: flex;

                                .sbtitle {
                                    font-weight: 400;
                                    display: flex;
                                    align-items: center;
                                    color: #333;
                                    margin-right: 30px;
                                    font-size: 13px;

                                    svg {
                                        display: inline-block;
                                        margin-right: 3px;
                                    }
                                }

                                a {
                                    display: inline-block;
                                    background: #dadada;
                                    padding: 0px 12px;
                                    border-radius: 30px;
                                    color: #333;
                                    text-decoration: none;
                                    margin-right: 7px;

                                    &:last-child {
                                        margin-right: 0;
                                    }

                                    span.closeTags {
                                        display: inline-block;
                                        background: #ccc;
                                        color: #000;
                                        padding: 2px 8px;
                                        border-radius: 0 30px 30px 0;
                                        margin-left: 5px;

                                        &:hover {
                                            background: #333;
                                            color: #fff;
                                            transition: all 0.5s;
                                        }
                                    }
                                }
                            }

                            .d-timeflex {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-end;
                                width: 100%;
                                border-left: 1px solid #ccc;
                                margin-left: 0;
                                min-height: 78px;
                                padding: 0;
                                width: 100%;
                                max-width: 120px;
                                white-space: nowrap;

                                small {
                                    display: inline-block;
                                    margin-right: 0;
                                    width: 100%;
                                    max-width: 100%;
                                    text-align: center;
                                    padding: 8px 10px;

                                    svg {
                                        font-size: 15px;
                                        display: inline-block;
                                        position: relative;
                                        bottom: 1px;
                                        margin-right: 3px;
                                        color: #333;
                                    }

                                    &:first-child {
                                        position: relative;
                                        top: 3px;
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                        border-top: 1px solid #ccc;
                                    }
                                }
                            }

                            p {
                                margin: 0;
                                font-size: 17px;
                                display: flex;
                                align-items: center;
                                width: 100%;
                                justify-content: space-between;

                                span {
                                    width: 100%;
                                    display: inline-block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                small {
                                    width: 100%;
                                    text-align: right;
                                    max-width: 80px;
                                }
                            }

                            small {
                                display: block;
                                width: 100%;
                                max-width: 75px;
                                margin-top: 0;
                                font-size: 12px;
                                text-align: right;
                            }
                        }
                    }
                }
            }

            .dkg-compy-noti-right-boxcon {
                flex: 0 0 33%;
                max-width: 33%;
                padding-left: 15px;

                .dkg-compy-noti-boxs-cols {
                    background-color: #244158;
                    height: calc(100vh - 235px);
                    padding: 0;
                    position: relative;

                    .dkg-compy-noti-boxs-Header {
                        .d-timeflex {
                            border-left: 1px solid #1d5989 !important;

                            small {
                                color: #ddd;

                                &:last-child {
                                    border-top: 1px solid #1d5989 !important;
                                }

                                svg {
                                    color: #ddd !important;
                                }
                            }
                        }

                        .sbtitle {
                            color: #ddd !important;
                            padding: 0 10px;
                        }

                        .dk-notificationText {
                            border-left: 1px solid #1d5989 !important;
                        }

                        .dk-notificationImg {
                            span {
                                color: #fff;
                            }
                        }
                    }

                    .dkg-compy-noti-boxs-Body {
                        .dk-moduleBody-text {
                            height: calc(100vh - 510px);
                            overflow-y: auto;
                            padding: 20px;

                            p {
                                color: #fff;
                            }
                        }

                        .dk-commentCategoryMain {
                            // margin-top: 15px;
                            // position: absolute;
                            // left: 0;
                            // bottom: 0;

                            .title {
                                display: block;
                                width: 100%;
                                text-align: center;
                                background: #2e506c;
                                border-bottom: 1px solid #7f7f7f;
                                font-size: 13px;
                                text-transform: uppercase;
                                font-weight: 500;
                                padding: 10px 0;
                                margin-bottom: 0;
                                position: relative;
                                color: #fff;

                                button {
                                    display: inline-block;
                                    background: #2e97b6;
                                    padding: 3px 12px;
                                    text-transform: uppercase;
                                    border: none;
                                    outline: none;
                                    color: #fff;
                                    position: absolute;
                                    right: 8px;
                                    top: 7px;
                                    font-weight: 500;
                                    border-radius: 3px;

                                    &:hover {
                                        background: #244158;
                                        color: #fff;
                                        transition: all 0.5s;
                                    }
                                }
                            }

                            .dk-commentCategory-cols {
                                background: #2e506c;
                                padding: 10px 0 5px;
                                height: 243px;
                            }

                            .dk-categoryComment-img {
                                display: inline-block;
                                margin: 0 5px 5px;
                                cursor: pointer;

                                img {
                                    display: inline-block;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    filter: brightness(0.8);
                                }
                            }
                        }

                        .dkg-cumm-log-maintabCon {
                            .dkg-cumm-log-mainRow {
                                margin: 0;

                                .dkg-cumm-log-navmainCol {
                                    padding: 0;

                                    .dkg-cumm-log-navpills {
                                        .dkg-cumm-log-navitem {
                                            width: 100%;
                                            max-width: 33.33%;

                                            .dkg-cumm-log-navlink {
                                                background-color: #3c97b6;
                                                color: #fff;
                                                text-align: center;
                                                width: 100%;
                                                font-size: 14px;
                                                padding: 14px 8px;
                                                border-bottom: none;
                                                border-right: 1px solid #244158;
                                                border-radius: 0;
                                                min-height: 49px;
                                                font-weight: bold;

                                                &.active {
                                                    background-color: #244158;
                                                    color: #fff;
                                                    border-right: #ddd;
                                                }
                                            }

                                            &:last-child {
                                                .dkg-cumm-log-navlink {
                                                    border-right: none;
                                                }
                                            }
                                        }
                                    }
                                }

                                .dkg-cumm-log-tabcontantentCol {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }

            &::-webkit-scrollbar {
                width: 0px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                outline: 1px solid slategrey;
            }
        }
    }
}

.dk-maimenu-todoTaskMain {
    margin-top: 185px;
    width: 100%;
    max-width: 1240px;
    height: calc(100vh - 190px);

    .container {
        max-width: 100% !important;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        position: relative;
        margin-bottom: 25px;
        text-align: center;
        display: block;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
    }

    .dk-maimenu-suMMCon {
        .dk-searchFilter-cols {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 20px;
            padding: 0 15px;

            .dk-trackingDate {
                small {
                    font-size: 14px;
                    display: block;
                    width: 100%;
                    margin-bottom: 5px;
                    font-weight: 400;
                    color: #7c7c7c;
                }

                .dk-nativedatepicker-grid {
                    width: 100%;
                    max-width: 180px;

                    .dk-nativedatepicker {
                        margin: 0 !important;

                        .MuiInput-root {
                            border: 1px solid #ccc;
                            border-radius: 3px;

                            &::before {
                                display: none;
                            }

                            &::after {
                                display: none !important;
                            }

                            input {
                                padding: 0 10px;
                                border: none;
                                background: transparent;
                            }
                        }

                        .MuiIconButton-root {
                            padding: 0 10px;
                            outline: none;

                            &::after {
                                content: '';
                                background: url('../../../../../assets/images/calendar.png') no-repeat;
                                padding: 11px 10px;
                                position: absolute;
                                right: 10px;
                                top: -10px;
                                background-size: 20px;
                            }

                            svg {
                                display: none;
                            }
                        }
                    }
                }
            }

            .dk-selectYear {
                small {
                    font-size: 14px;
                    display: block;
                    width: 100%;
                    margin-bottom: 5px;
                    font-weight: 400;
                    color: #7c7c7c;
                }

                .dropdown {
                    .dropdown-toggle {
                        margin: 0;
                        border: 1px solid #ccc;
                        height: 35px;
                        box-shadow: none;
                        outline: none;
                        padding: 0;
                        width: 100%;
                        min-width: 110px;
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        min-width: 110px;
                        margin: 0 !important;
                        transform: inherit !important;
                        top: 37px !important;

                        a {
                            display: block;
                            padding: 5px 12px;
                            font-size: 12px;
                            border-bottom: 1px solid #ddd;

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            label {
                margin-right: 10px;
                display: inline-block;
                margin-bottom: 0;
            }

            input {
                border: 1px solid #ccc;
                box-shadow: none;
                outline: none;
                height: 35px;
                border-radius: 3px;
                background: #f9f9f9;
            }

            select {
                border: 1px solid #ccc;
                box-shadow: none;
                outline: none;
                height: 35px;
                border-radius: 3px;
                background: #f9f9f9;
            }

            .dk-searchBtn {
                display: inline-block;
                margin-top: 0;

                button {
                    display: inline-block;
                    background: #eaeaea;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    outline: none;
                    box-shadow: none;
                    height: 35px;
                    width: 100%;
                    max-width: 40px;
                    min-width: 40px;

                    &:hover {
                        background: #3c97b6;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
            }
        }

        .dk-mainmenu-pagetitle {
            margin-bottom: 22px;

            h2 {
                margin: 0;
                display: block;
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }

        .dkg-mainsuMM-leftCon {
            .dkg-mainsuMM-leftTopCon {
                display: flex;

                .dkg-main-menu-suMMBox-Con {
                    width: 100%;
                    max-width: 100%;
                    background: #eaeaea;
                    border: 1px solid #dadada;
                    margin: 0 12px;
                    margin-bottom: 0;
                    align-items: center;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 5px;
                    padding: 0;

                    .dkg-main-menusuMM-Header {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        border-radius: 5px 5px 0 0;
                        border-bottom: 1px solid #ddd;
                        text-transform: uppercase;
                        background: #eaeaea;

                        .dkg-main-menusuMM-Title {
                            font-size: 16px;
                            padding: 8px 10px;
                            color: #504d4d;
                            font-weight: 600;
                        }
                    }

                    .dkg-main-menusuMM-Body {
                        background-color: #fff;
                        border-radius: 0 0 5px 5px;
                        min-height: 180px;
                        // height: calc( 100vh - 600px);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        ul {
                            list-style: none;
                            margin: 0;
                            width: 100%;
                            padding: 10px 10px;
                            height: calc(100vh - 310px);

                            li {
                                list-style-type: none;
                                display: flex;
                                width: 100%;
                                align-items: center;
                                justify-content: space-between;
                                color: #333;
                                font-size: 13px;
                                padding: 6px 0;

                                span {
                                    color: #2c71b2;
                                }
                            }
                        }

                        @media(max-width:992px) {
                            min-height: 80px;
                        }
                    }
                }
            }
        }

        .dkg-mainsuMM-RightCon {
            padding: 0 10px;

            .dk-workplansimileCon {
                margin-top: 30px;
                background-color: #eaeaea;
                border-radius: 5px;
                width: 100%;
                height: 450px;

                @media(max-width:992px) {
                    overflow-x: auto;
                }

                .dk-wokplan-emoHeader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #eaeaea;
                    border-radius: 5px 5px 0 0;
                    padding: 8px 10px;
                    border: 1px solid #ccc;
                    border-bottom: none;

                    @media(max-width:992px) {
                        position: sticky;
                        top: 0;
                        left: 0;
                    }

                    .dk-wokplan-emoTitle {
                        font-size: 18px;
                        font-weight: bold;
                        text-transform: uppercase;
                        color: #646060;
                    }
                }

                .dkg-workplanemo-Body {
                    @media(max-width:992px) {
                        width: 800px;
                        overflow-x: auto;
                    }

                    .dkg-summemojelistCon {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 0;
                        padding: 20px 0;
                        border-left: 1px solid #ccc;
                        border-right: 1px solid #ccc;

                        .dkg-summemojelist {
                            text-align: center;
                            width: 100%;

                            .dk-summ-recimgCon {
                                padding-bottom: 0.5rem;

                                .dk-summ-recimg {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    cursor: pointer;

                                    &.active {
                                        border: 3px solid #FFA500;
                                    }
                                }
                            }
                        }
                    }

                    .monthBoxes {
                        display: flex;
                        width: 100%;

                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;
                            width: 100%;
                            margin-bottom: 0;
                            height: 33px;

                            li {
                                list-style-type: none;
                                display: inline-block;
                                width: 100%;
                                max-width: 8.33%;

                                &:first-child a {
                                    border-left: 1px solid #616167;
                                }

                                a {
                                    width: 100%;
                                    height: 35px;
                                    background: #4a5057;
                                    color: #fff;
                                    padding: 7px;
                                    float: left;
                                    border-top: 1px solid #616167;
                                    border-bottom: 1px solid #616167;
                                    border-right: 1px solid #616167;
                                    cursor: pointer;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .dk-days-blocksContainer {
                        width: 100%;
                        padding: 17px 0;
                        padding-top: 0;

                        .dk-days-portalCols {
                            width: 100%;
                            display: flex;
                            justify-content: center;

                            &:nth-child(02) .dk-days-portalBox,
                            &:nth-child(03) .dk-days-portalBox,
                            &:nth-child(04) .dk-days-portalBox,
                            &:nth-child(05) .dk-days-portalBox {
                                border-top: none;
                            }

                            &:last-child {
                                .dk-days-portalBox {
                                    &:first-child {
                                        border-bottom-left-radius: 5px;
                                    }

                                    &:last-child {
                                        border-bottom-right-radius: 5px;
                                    }
                                }
                            }

                            .dk-days-portalBox {
                                width: 100%;
                                max-width: 14.2857%;
                                min-height: 85px;
                                background: #f1f1f1;
                                border: 1px solid #ddd;
                                border-left: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &:first-child {
                                    border-left: 1px solid #ddd;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-notificationSeenModal.modal {
    .modal-dialog {
        width: 100%;
        max-width: 600px;
        position: fixed;
        right: 0;
        top: 0;
        margin: 0 !important;

        .modal-content {
            background: #244158;

            .modal-header {
                padding: 10px 12px;
                border-bottom: 1px solid #1d5989;
                background: #12212c;

                .modal-title {
                    font-size: 16px;
                    display: block;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 600;
                }

                .close {
                    color: #fff;
                    text-shadow: none;
                    opacity: 1;
                }
            }
        }
    }

    .dk-moduleNoti-rightPanel {
        padding: 0;
        font-weight: 400;
        font-size: 14px;
        margin-top: 0;
        line-height: 24px;
        height: calc(100vh - 30px);
        color: #ddd;

        .moduleNoti-title {
            background: #244158;
            padding: 8px 12px;
            text-align: center;
            text-transform: uppercase;
            color: #ddd;
            font-size: 15px;
            font-weight: 500;
            border-bottom: 1px solid #1d5989 !important;
        }

        .dk-moduleNoti-body {
            padding: 20px 0 0;
            padding-top: 0;
            background: #244158;
            height: calc(100vh - 30px);
            overflow-y: auto;

            .dk-moduleBody-text {
                height: calc(100vh - 178px);
                padding: 20px 20px;
                overflow-y: auto;
            }

            .dk-moduleBody-text::-webkit-scrollbar {
                width: 8px;
            }

            .dk-moduleBody-text::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            .dk-moduleBody-text::-webkit-scrollbar-thumb {
                background-color: #1e5582;
                outline: 1px solid #1e5582;
            }

            .dk-commentCategoryMain {
                .title {
                    display: block;
                    width: 100%;
                    text-align: center;
                    background: #2e506c;
                    border-bottom: 1px solid #7f7f7f;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 500;
                    padding: 10px 0;
                    margin-bottom: 0;
                    position: relative;

                    button {
                        display: inline-block;
                        background: #2e97b6;
                        padding: 3px 12px;
                        text-transform: uppercase;
                        border: none;
                        outline: none;
                        color: #fff;
                        position: absolute;
                        right: 8px;
                        top: 7px;
                        font-weight: 500;
                        border-radius: 3px;

                        &:hover {
                            background: #244158;
                            color: #fff;
                            transition: all 0.5s;
                        }
                    }
                }

                .dk-commentCategory-cols {
                    background: #2e506c;
                    padding: 10px 0 5px;
                }

                .dk-categoryComment-img {
                    display: inline-block;
                    margin: 0 5px 5px;
                    cursor: pointer;

                    img {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        filter: brightness(0.8);
                    }
                }
            }
        }

        .dk-moduleNoti-body::-webkit-scrollbar {
            width: 0px;
        }

        .dk-moduleNoti-body::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
        }

        .dk-moduleNoti-body::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0 solid slategrey;
        }
    }
}

.dk-notificationSummary-main {
    .dk-notiSummaryCols {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 30px;

        .title {
            background-color: #eaeaea;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 7px 10px;
            text-transform: uppercase;
            color: #6c757d;
            font-size: 13px;
            margin-bottom: 0;
        }

        .dk-panel {
            min-height: 125px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F1F1F1;
            border: none;
            border-top: 1px solid #cfcfcf;

            .dkg-bigsizenum {
                h2 {
                    font-size: 64px;
                    font-weight: bold;
                    color: #3c97b6;
                }
            }
        }
    }
}