.dk-viewResModal {
    .modal-dialog {
        .modal-content {
            background: transparent;

            .dk-viewResModalHder.modal-header {
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #2196f3;
                padding: 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: none;

                &.dk-res-reorder-Modalheader {
                    background-color: #26597d;
                }

                .modal-title {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                }

                .close {
                    text-shadow: none;
                    color: #fff;
                    opacity: 1;
                }
            }

            .dk-viewResModalBdy.modal-body {
                background-color: #fff;
                border-radius: 0 0 5px 5px;

                &.res-rordermodal-body {
                    ul {
                        max-height: calc(100vh - 340px);
                        overflow-y: auto;

                        .Mukul {
                            .dk-valueFields {
                                .dk-handle {
                                    border-bottom: 1px solid #ddd;
                                }

                                input {
                                    border-bottom: none !important;
                                }
                            }

                            &:last-child {
                                .dk-valueFields {
                                    input {
                                        border-bottom: 1px solid #ddd !important;
                                    }
                                }
                            }
                        }
                    }

                    .dk-res-reorder-save-btnCon {
                        div {
                            div {
                                .dk-toolsPanelSaveBtn {
                                    display: inline;
                                    background: #26597d !important;
                                    border: none;
                                    color: #fff;
                                    text-transform: uppercase;
                                    padding: 6px 10px;
                                    font-weight: 500;
                                    font-size: 14px;
                                    outline: none;
                                    cursor: pointer;
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                }

                .form-group {
                    display: flex;
                    align-items: center;

                    label {
                        margin-bottom: 0.75rem;
                        min-width: 125px;
                        font-size: 13px;
                    }

                    .useFilterCon {
                        .dropdown {
                            .dk-resUsrfliterbtn {
                                width: 35px;
                                height: 35px;
                                margin: 0;
                                padding: 0;
                                box-shadow: none;
                                background-color: transparent;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .dk-resDropDown {
                        width: 100%;

                        .ui.selection.dropdown {
                            border-radius: 0;

                            .menu {
                                border-radius: 0;
                            }
                        }
                    }

                    input.form-control {
                        border-radius: 0;
                        font-size: 13px;
                        text-align: center;
                        border: 1px solid #c1c9dc;
                        height: 31px;

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }
                    }
                }

                .dk-sbmitbtnCon {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .dk-sbmitbtn {
                        display: inline;
                        background: #26597d !important;
                        border: none;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 8px 25px;
                        font-weight: 500;
                        font-size: 14px;
                        outline: none;
                        cursor: pointer;
                        border-radius: 5px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}