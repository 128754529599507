.dkg-client-info-pageMain {
    margin-top: 160px;

    .dkg-ci-summ-mainRow {
        .dkg-ci-summ-mainCol {
            .dkg-client-info-topboxs-Con {
                display: flex;
                width: 100%;

                .dkg-client-info-topbox-Cols {
                    width: 100%;
                    max-width: 100%;
                    background: #eaeaea;
                    border: 1px solid #dadada;
                    margin: 0 9px;
                    margin-bottom: 18px;
                    align-items: center;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 5px;
                    padding: 0;

                    .dkg-clientinfo-tableCon {
                        .dkg-clientinfo-table {
                            margin-bottom: 0;

                            tbody {
                                tr {
                                    td {
                                        height: 80px;
                                        width: 80px;
                                        line-height: 52px;
                                        cursor: pointer;

                                        .dkg-clintinfo-con-234 {
                                            font-size: 14px;

                                            .dkg-title {
                                                display: block;
                                                height: 30px;
                                                color: #333;
                                            }

                                            .dkg-counter {
                                                font-weight: bold;
                                                color: #3c97b6;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .dkg-ci-summ-TitleCon {
                            background-color: #eaeaea;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            padding: 7px 10px;
                            border-bottom: 1px solid #dadada;
                            font-weight: 700;
                            text-transform: uppercase;
                            color: #6c757d;
                            position: relative;

                            .dk-totalCvCount {
                                position: absolute;
                                right: 10px;
                                top: 7px;
                            }
                        }
                    }
                }
            }

            .dkg-client-info-bottomboxs-Con {
                display: flex;
                width: 100%;

                .dkg-client-info-bottombox-Cols {
                    width: 100%;
                    max-width: 100%;
                    background: #eaeaea;
                    border: 1px solid #dadada;
                    margin: 0 9px;
                    margin-bottom: 18px;
                    align-items: center;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 5px;
                    padding: 0;

                    .dkg-client-info-bottombox-Header {
                        background-color: #eaeaea;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        padding: 7px 10px;
                        border-bottom: 1px solid #dadada;
                        font-weight: 700;
                        text-transform: uppercase;
                        color: #6c757d;
                    }

                    .dkg-client-info-bottombox-Body {
                        height: calc(100vh - 415px);
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}