.dk-resSummaryCon {
  padding: 20px 10px;
  margin-top: 130px;
  height: calc(100vh - 160px);
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.dk-resSummaryCon::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dk-resSummaryCon .dk-reSCenterheaderCont h2.dk-reSPageTitle {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
}

.dk-reSummThreeBox .dk-reSummThreeCols {
  width: 100%;
  max-width: 16.666%;
  max-height: 140px;
  margin: 0 8px;
  background-color: #eaeaea;
  border-radius: 0 0 5px 5px;
}

.dk-reSummThreeBox .dk-reSummThreeCols {
  position: relative;
  width: 100%;

  .dk-reSHeaderCont78.dk-dbsuMM-filterHeader {
    background-color: #234158;
    color: #fff;
    cursor: pointer;
  }

  &.dkg-manager-cols {
    .dk-reSummTreContent.dkg-managerboxtrecontent {
      min-height: 110px !important;
      max-height: 110px !important;

      li {
        span {
          width: inherit !important;
          height: inherit !important;
        }

        &:last-child {
          border-radius: 0;
        }

        &:hover {
          background-color: #234158;
          color: #fff;
          cursor: pointer;

          span {
            color: #fff;
          }
        }

        .dk-clisuMMLeft {
          .dk-CliensuMMImgCri {
            img {
              width: 22px;
              height: 22px;
              margin-right: 0.5rem;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.dk-reSHeaderCont78 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #eaeaea;
  padding: 5px 10px;
  border: 1px solid #dadada;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #6c757d;
}

.dk-reSummThreeCols .dk-reSummTwoContent {
  // min-height: 355px;
  // max-height: 355px;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  // background-color: #eaeaea;
}

.dk-reSummThreeCols .dk-reSummTwoContent.dkg-client-info-topContent {
  min-height: 110px;
  max-height: 110px;
  overflow-y: auto;
}

.dk-reSummThreeCols .dk-reSummTwoContent.dkg-client-info-topContent::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

.dk-reSummThreeCols .dk-reSummTwoContent.dkg-client-info-topContent::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.dk-reSummThreeCols .dk-reSummTwoContent.dkg-client-info-topContent::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.dk-reSummThreeCols .dk-reSummTwoContent.dkg-clieninfo-bottom-ullist {
  height: calc(100vh - 400px);
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #eaeaea;
}

.dk-reSummThreeCols .dk-reSummTwoContent.dkg-clieninfo-bottom-ullist::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

.dk-reSummThreeCols .dk-reSummTwoContent.dkg-clieninfo-bottom-ullist::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.dk-reSummThreeCols .dk-reSummTwoContent.dkg-clieninfo-bottom-ullist::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.dk-reSummThreeBox .dk-reSummThreeCols .dk-reSummTwoContent li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #eaeaea;
  padding: 5px 10px;
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: #234158;
    color: #fff;
    cursor: pointer;

    span {
      color: #fff;
    }
  }
}

.dk-reSummThreeBox .dk-reSummThreeCols .dk-reSummTwoContent li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dk-reSummThreeBox .dk-reSummThreeCols .dk-reSummTwoContent li div {
  font-size: 13px;
}

.dk-reSummThreeBox .dk-reSummThreeCols .dk-reSummTwoContent li span {
  display: inline-block;
  width: inherit !important;
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: #2c71b2;
  border-radius: 3px;
  font-size: 13px;
}

.dk-reSummThreeBox .dk-reSummThreeCols .dk-reSummTwoContent li .dk-clisuMMLeft {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dk-reSummThreeBox .dk-reSummThreeCols .dk-reSummTwoContent li .dk-clisuMMLeft .dk-CliensuMMImgCri img.clientSummImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 5px;
}

.dk-reSummThreeBox .dk-reSummThreeCols .dk-reSummTwoContent li span {
  display: inline-block;
  width: inherit !important;
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: #2c71b2;
  border-radius: 3px;
  font-size: 13px;
}

.dk-reSummThreBox .dk-reSummThreCols {
  width: 100%;
  max-width: 320px;
  margin: 0 8px;

  .dk-reSHeaderCont78 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eaeaea;
    padding: 5px 10px;
    border: 1px solid #dadada;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    color: #6c757d;

    &.dk-dbsuMM-filterHeader {
      background-color: #234158;
      color: #fff;
      cursor: pointer;
    }

    .dk-filterIcon {
      cursor: pointer;
    }
  }

  .dk-reSummTreContent {
    min-height: 518px;
    max-height: 518px;
    overflow-y: auto;
    background: #eaeaea;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #eaeaea;
      padding: 5px 10px;
      border-bottom: 1px solid #dadada;
      border-left: 1px solid #dadada;
      border-right: 1px solid #dadada;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 0;

      &:hover {
        background-color: #234158;
        color: #fff;
        cursor: pointer;

        span {
          color: #fff;
        }
      }

      div {
        font-size: 13px;
      }

      span {
        display: inline-block;
        width: inherit !important;
        height: 25px;
        text-align: center;
        line-height: 25px;
        color: #2c71b2;
        border-radius: 3px;
        font-size: 13px;
      }
    }
  }
}

/*# sourceMappingURL=style.css.map */