.dk-compaginsCreateNewPop.modal-dialog {
    min-width: 50%;
}
.dk-compaginsCreateNewPop.modal-dialog .modal-content {
    border-radius: 5px;
    box-shadow: none;
    border: none;
}
.dk-compaginsCreateNewPop.modal-dialog .modal-content .modal-header {
    padding: 10px 15px;
    background: #26597d;
    border-radius: 5px 5px 0 0;
}
.dk-compaginsCreateNewPop.modal-dialog .modal-content .modal-header .modal-title {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}
.dk-compaginsCreateNewPop.modal-dialog .modal-content .modal-header button.close {
    padding: 0;
    color: #fff;
    opacity: 1;
    text-shadow: none;
    font-size: 20px;
    position: absolute;
    right: 28px;
    top: 28px;
    outline: 0;
}
.useFilterCon  a,
.useFilterCon  a:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #eaeaea!important;
    color: #333;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
}
.dk-compaginsCreateNewPop.modal-dialog .modal-content .modal-body.dk-compaginsCreateNew{
    padding: 10px 25px 20px;
}
.modal-body.dk-compaginsCreateNew form .form-group {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
}
.modal-body.dk-compaginsCreateNew form .form-group label {
    min-width: 90px;
    font-size: 14px;
    margin-bottom: 0;
    text-align: left!important;
}
.modal-body.dk-compaginsCreateNew form .form-group input.form-control {
    background-color: #fff;
    border-radius: 0;
    font-size: .87rem;
    padding: .5rem;
    border: 1px solid #ddd;
    text-align: center;
    box-shadow: none;
    height: 32px;
}
.modal-body.dk-compaginsCreateNew .dk-camaadNewFilter {
    width: 100%;
}
.modal-body.dk-compaginsCreateNew  
.dk-camaadNewFilter .ui.selection.dropdown.dk-overdues-task-slect {
    border-radius: 0;
    background-color: #fff;
    font-size: .85rem;
    height: 32px;
    border: 1px solid #ddd;
}
.modal-body.dk-compaginsCreateNew form .dk-adstrackbtnCont {
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-body.dk-compaginsCreateNew form .dk-adstrackbtnCont .dk-adstrackaddbtn {
    display: inline-block;
    background: #26597d;
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 25px;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.modal-body.dk-compaginsCreateNew form .form-group .dropdown.todays12 .dropdown-toggle.btn.todyAddToggle {
    display: flex;
    width: 35px !important;
    height: 35px !important;
    background-color: #eaeaea!important;
    border: 1px solid #ddd;
    font-size: 15px;
    color: #333;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    box-shadow: none!important;
    justify-content: center;
    align-items: center;
}
.modal-body.dk-compaginsCreateNew form .form-group .dropdown.todays12 button i{
    left: 0;
}
.modal-body.dk-compaginsCreateNew form .form-group .dropdown.todays12 .dropdown-toggle.btn.todyAddToggle{
    transform: translate(-2px,0)!important;
}
/* .modal-body.dk-compaginsCreateNew form .form-group .dropdown.todays12 .dropdown-menu::before{
    display: none;
} */
.modal-body.dk-compaginsCreateNew form .form-group .dropdown.todays12 .dropdown-menu.dk-adstracker-DrpMenu12{
    transform: translate(-0,-5px)!important;
}
.modal-body.dk-compaginsCreateNew form .form-group .dropdown.todays12 .dropdown-menu.dk-adstracker-DrpMenu12::before {
    position: absolute;
    left: -12px;
    top: 13px;
}
.modal-body.dk-compaginsCreateNew form .form-group .dropdown.todays12 .dropdown-menu.dk-adstracker-DrpMenu12 a.dropdown-item {
    padding: 0;
    margin: 0 3px;
    display: inline-block;
    width: 35px;
}