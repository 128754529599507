.dk-trackingMain {
    margin-top: 160px;
    padding: 0 15px;

    .dk-trackingHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .dk-headLeftCols {
            display: flex;
            align-items: center;

            .dk-searchBox {
                width: 100%;
                max-width: 160px;
                position: relative;
                margin-right: 10px;

                input {
                    padding: 0.5rem 0.55rem;
                    background-color: #eaeaea !important;
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid #ccc;
                    line-height: 1.3rem;
                    height: 35px;
                    border-radius: 0;
                }

                button {
                    position: absolute;
                    right: 9px;
                    top: 0;
                    border: none;
                    outline: none;
                    background: transparent;
                    color: #828282;
                    font-size: 14px;
                    border-left: 1px solid #ccc;
                    height: 34px;
                    padding-left: 7px;
                }
            }

            .dk-circleBtn {
                display: inline-block;
                margin-right: 8px;

                button {
                    padding: 0 !important;
                    margin: 0;
                    border-radius: 50%;
                    height: 35px;
                    width: 35px;
                    line-height: 30px;
                    background-color: #eaeaea;
                    border: 1px solid #ddd;
                    font-size: 0.81rem;
                    cursor: pointer;
                    outline: none;

                    svg {
                        font-size: 15px;
                    }
                }
            }
        }

        h3.title {
            color: #26597d;
            position: absolute;
            left: 50%;
            display: inline-block;
            transform: translate(-50%);
            top: 7px;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .dk-headRightCols {
            display: flex;
            align-items: center;

            .dk-trackingDropdown {
                .dropdown-toggle {
                    color: #333;
                    width: 100%;
                    border: 1px solid #ddd;
                    background-color: #eaeaea;
                    padding: 7px 5px;
                    font-size: 13px;
                    width: 130px;
                    text-align: center;
                    box-shadow: none;
                    outline: none;
                    text-transform: uppercase;

                    &::after {
                        display: inline-block;
                        margin: 0 5px;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    border-radius: 0;
                    border: 1px solid #ddd;
                    width: 100%;
                    min-width: 155px;
                    transform: inherit !important;
                    top: 45px !important;
                    left: inherit !important;
                    right: 5px !important;
                    margin: 0 !important;

                    .dk-trackingInput {
                        padding: 8px;

                        input {
                            width: 100%;
                            border: 1px solid #ddd;
                            outline: none;
                            height: 35px;
                            text-align: center;
                            font-size: 12px;
                        }
                    }

                    a {
                        border: none;
                        border-bottom: 1px solid #ddd;
                        background-color: #fff;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 12px;
                        padding: 6px 12px;

                        .dropdown-filter-count {
                            align-items: center;
                            background-color: #3c4c62;
                            border-radius: 2px;
                            color: #fff;
                            display: flex;
                            font-size: 12px;
                            justify-content: center;
                            padding: 0 5px;
                        }
                    }
                }
            }
        }
    }

    .dk-trackingTable {
        table {
            tr {
                th {
                    color: #fff;
                    background: #234158;
                    font-size: 12px;
                    text-align: left;
                    padding: 10px 5px;
                    white-space: nowrap;
                    border: 1px solid gray;
                    text-align: center;
                    text-transform: uppercase;
                    vertical-align: middle;
                    position: sticky;
                    top: 0;
                    border-top: none;
                    z-index: 9;
                }

                td {
                    font-size: 12px;
                    font-weight: 400;
                    padding: 8px 5px 8px 7px;
                    text-align: center;
                    vertical-align: middle;
                    border: 1px solid #ddd;
                    white-space: nowrap;
                    background-color: #eee;

                    .dk-commentTypeTd {
                        img {
                            width: 100%;
                            max-width: 18px;
                            margin-right: 5px;
                            position: relative;
                            bottom: 2px;
                        }
                    }

                    &:nth-child(08) {
                        padding: 0;
                        border-right-color: #818181;
                    }

                    &:nth-child(09) {
                        padding: 0;
                        border-right-color: #818181;
                    }

                    &:nth-child(10) {
                        padding: 0;
                    }

                    &:nth-child(12) {
                        padding: 0;
                    }

                    .dk-tdFirstCheck {
                        position: relative;
                        top: 2px;
                        left: 5px;
                    }

                    .dk-tdFirstCheckinput[type=checkbox]:checked {
                        color: #f00;
                        background: #297483;
                    }

                    span.dk-openCandidateID {
                        i {
                            background: #297483;
                            padding: 5px;
                            text-align: center;
                            border-radius: 2px;
                            color: #fff;
                            margin-right: 8px;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }

                    .dk-byImg {
                        img {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                    }

                    .dk-checkSerial {
                        display: inline-block;
                        margin-left: 10px;
                        position: relative;
                        top: 3px;
                    }

                    .dk-tableDropdown {
                        .dropdown-toggle {
                            display: inline-block;
                            color: #fff;
                            border-radius: 0;
                            padding: 0;
                            font-size: 13px;
                            margin: 0 !important;
                            height: 46px;
                            line-height: 46px;
                            box-shadow: none;
                            text-transform: capitalize;
                            white-space: nowrap;
                            width: 100%;

                            span {
                                display: block;
                                width: 100%;
                                height: 100%;
                                padding: 0;
                                border-radius: 0;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            border-radius: 0;
                            padding: 0;
                            transform: inherit !important;
                            position: absolute !important;
                            top: 46px !important;

                            a {
                                border-bottom: 1px solid #dadada;
                                padding: 5px 7px;
                                font-size: 12px;
                            }
                        }
                    }

                    .dk-trackingTblComment {
                        span {
                            width: 25px;
                            height: 25px;
                            background-color: #ccc;
                            border-radius: 50%;
                            line-height: 25px;
                            cursor: pointer;
                            display: inline-block;
                        }
                    }

                    .dk-trackingTblCategory {
                        span {
                            display: inline-block;
                            color: #fff;
                            border-radius: 0;
                            padding: 0;
                            font-size: 13px;
                            background: #7A7B80;
                            width: 100%;
                            height: 46px;
                            line-height: 46px;
                        }
                    }

                    .dk-tableStatus-dropdown {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .dropdown {
                            &:nth-child(02) {
                                .dropdown-menu {
                                    top: -12px !important;

                                    &::before {
                                        top: 17px;
                                    }
                                }
                            }

                            &:nth-child(03) {
                                .dropdown-menu {
                                    top: -12px !important;

                                    &::before {
                                        top: 17px;
                                    }
                                }
                            }

                            &:nth-child(04) {
                                .dropdown-menu {
                                    top: -12px !important;

                                    &::before {
                                        top: 17px;
                                    }
                                }
                            }
                        }

                        .dropdown-toggle {
                            background: transparent;
                            border: none;
                            box-shadow: none;
                            font-size: 13px;
                            margin: 0 !important;
                            max-width: 15px;
                            min-width: 15px;
                            padding: 0;
                            text-transform: inherit;
                            width: 100%;
                            margin: 0px 5px !important;

                            svg {
                                font-size: 15px;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            background-color: #234158 !important;
                            border: 1px solid gray;
                            display: flex;
                            left: 30px !important;
                            margin: 0 !important;
                            min-width: 100px;
                            padding: 0;
                            top: -27px !important;
                            -webkit-transform: inherit !important;
                            transform: inherit !important;

                            &::before {
                                border-color: transparent #234158 transparent transparent;
                                _border-color: #000 #234158 #000 #000;
                                border-style: solid;
                                border-width: 6.5px 12px 6.5px 0;
                                content: "";
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                height: 0;
                                left: -12px;
                                line-height: 0px;
                                position: absolute;
                                top: 27px;
                                width: 0;
                            }

                            a {
                                background-color: #234158 !important;
                                border: none;
                                border-right: 1px solid gray;
                                font-size: 16px;
                                padding: 4px 9px;
                                text-align: center;
                                width: 100%;
                                color: #fff;

                                &:last-child {
                                    border-right: none;
                                }

                                span {
                                    display: block;
                                    width: 100%;
                                    font-size: 12px;
                                }

                                small {
                                    display: block;
                                    width: 100%;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.fade.dk-trackingCommentsModal .modal-dialog {
    right: -40%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show.dk-trackingCommentsModal .modal-dialog {
    right: 0;
}

.dk-trackingCommentsModal {
    .modal-dialog {
        height: 100%;
        margin: auto;
        margin: 0 !important;
        max-width: 45% !important;
        min-width: 45% !important;
        width: 100%;

        .modal-content {
            border-radius: 0;
            box-shadow: none;
            height: 100vh;

            .modal-header {
                background: #26597d;
                padding: 8px 10px;

                .modal-title {
                    color: #fff;
                    font-family: Roboto, sans-serif;
                    font-size: 17px;
                    font-weight: 500;
                    margin-top: 0;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                }

                .close {
                    color: #fff !important;
                    font-size: 1.58em;
                    font-weight: 400;
                    margin: 0;
                    opacity: 1;
                    outline: none;
                    padding: 0.35rem;
                    position: absolute;
                    right: 10px;
                    text-shadow: none;
                    top: 8px;
                }
            }

            .modal-body {
                padding: 15px;
            }
        }
    }
}