.dk-maimenu-suMMPageCon {
    margin-top: 135px;
    padding: 0 100px;

    .dk-maimenu-suMMCon {
        .dk-mainmenu-pagetitle {
            margin-bottom: 22px;

            h2 {
                margin: 0;
                display: block;
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }

        .dkg-mainsuMM-leftCon {
            .dkg-mainsuMM-leftTopCon {
                display: flex;
                justify-content: center;

                .dkg-main-menu-suMMBox-Con {
                    width: 100%;
                    max-width: 22%;
                    background: #eaeaea;
                    border: 1px solid #dadada;
                    margin: 12px 15px;
                    margin-bottom: 18px;
                    align-items: center;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 5px;
                    padding: 0;

                    .dkg-main-menusuMM-Header {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        border-radius: 5px 5px 0 0;
                        background-color: #eaeaea;
                        border-bottom: 1px solid #ddd;

                        .dkg-main-menusuMM-Title {
                            font-size: 16px;
                            padding: 8px 10px;
                            color: #646060;
                            font-weight: 600;
                            min-height: 35px;
                        }
                    }

                    .dkg-main-menusuMM-Body {
                        background-color: #eaeaea;
                        border-radius: 0 0 5px 5px;
                        min-height: 125px;
                        // height: calc( 100vh - 600px);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media(max-width:992px) {
                            min-height: 80px;
                        }
                    }
                }
            }
        }

        .dkg-mainsuMM-RightCon {
            padding: 0 10px;

            .dk-workplansimileCon {
                margin-top: 30px;
                background-color: #eaeaea;
                border-radius: 5px;
                width: 100%;
                height: 450px;

                @media(max-width:992px) {
                    overflow-x: auto;
                }

                .dk-wokplan-emoHeader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #eaeaea;
                    border-radius: 5px 5px 0 0;
                    padding: 8px 10px;
                    border: 1px solid #ccc;
                    border-bottom: none;

                    @media(max-width:992px) {
                        position: sticky;
                        top: 0;
                        left: 0;
                    }

                    .dk-wokplan-emoTitle {
                        font-size: 18px;
                        font-weight: bold;
                        text-transform: uppercase;
                        color: #646060;
                    }
                }

                .dkg-workplanemo-Body {
                    @media(max-width:992px) {
                        width: 800px;
                        overflow-x: auto;
                    }

                    .dkg-summemojelistCon {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 0;
                        padding: 20px 0;
                        border-left: 1px solid #ccc;
                        border-right: 1px solid #ccc;

                        .dkg-summemojelist {
                            text-align: center;
                            width: 100%;

                            .dk-summ-recimgCon {
                                padding-bottom: 0.5rem;

                                .dk-summ-recimg {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    cursor: pointer;

                                    &.active {
                                        border: 3px solid #FFA500;
                                    }
                                }
                            }
                        }
                    }

                    .monthBoxes {
                        display: flex;
                        width: 100%;

                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;
                            width: 100%;
                            margin-bottom: 0;
                            height: 33px;

                            li {
                                list-style-type: none;
                                display: inline-block;
                                width: 100%;
                                max-width: 8.33%;

                                &:first-child a {
                                    border-left: 1px solid #616167;
                                }

                                a {
                                    width: 100%;
                                    height: 35px;
                                    background: #4a5057;
                                    color: #fff;
                                    padding: 7px;
                                    float: left;
                                    border-top: 1px solid #616167;
                                    border-bottom: 1px solid #616167;
                                    border-right: 1px solid #616167;
                                    cursor: pointer;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .dk-days-blocksContainer {
                        width: 100%;
                        padding: 17px 0;
                        padding-top: 0;

                        .dk-days-portalCols {
                            width: 100%;
                            display: flex;
                            justify-content: center;

                            &:nth-child(02) .dk-days-portalBox,
                            &:nth-child(03) .dk-days-portalBox,
                            &:nth-child(04) .dk-days-portalBox,
                            &:nth-child(05) .dk-days-portalBox {
                                border-top: none;
                            }

                            &:last-child {
                                .dk-days-portalBox {
                                    &:first-child {
                                        border-bottom-left-radius: 5px;
                                    }

                                    &:last-child {
                                        border-bottom-right-radius: 5px;
                                    }
                                }
                            }

                            .dk-days-portalBox {
                                width: 100%;
                                max-width: 14.2857%;
                                min-height: 85px;
                                background: #f1f1f1;
                                border: 1px solid #ddd;
                                border-left: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &:first-child {
                                    border-left: 1px solid #ddd;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}