.dk-cliAdNewPageCont {
    margin-top: 120px;

    .dk-clntsPageTitle {
        text-align: center;

        h2 {
            margin: 12px 0;
            display: block;
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            padding: 0;
            margin-top: 10px;
            text-transform: uppercase;
        }
    }

    .dk-addNClientFormCont {
        background: #D9DFE7;
        border-radius: 5px;
        padding: 15px;
        height: calc(100vh - 210px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        & {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }

        .form-group.dk-Candinate-fromGroup {
            margin-bottom: 0.5rem;

            .ddl-main.dropdown {
                .ddl-btn.dropdown-toggle {
                    text-transform: inherit;
                    font-size: 13px;
                    color: #333;
                    height: 32px;
                }
            }

            .ui.selection.dropdown.dk-overdues-task-slect {
                text-transform: inherit;
                font-size: 13px;
                color: #333;
            }

            label {
                display: flex;
                align-items: center;
                justify-content: left;
                margin-bottom: 0;
                font-size: 13px;
                font-weight: 400;
                color: #000;
                white-space: nowrap;
                min-width: 100px;
                line-height: 25px;
            }

            .ddl-main.dropdown {
                .dropdown-toggle {
                    &::after {
                        display: inline-block;
                        font-size: 16px;
                        color: #808080;
                        position: absolute;
                        right: 11px;
                        top: 13px;
                    }
                }
            }

            .selection.dropdown {
                height: 32px;

                &::after {
                    vertical-align: 0.255em;
                    content: "";
                    border-top: 0.3em solid;
                    border-right: 0.3em solid transparent;
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
                    display: inline-block;
                    font-size: 16px;
                    color: #808080;
                    position: absolute;
                    right: 11px;
                    top: 13px;
                }
            }

            label.dk-longllabel {
                min-width: 130px;
            }

            .dk-nativedatepicker-grid {
                .dk-nativedatepicker {
                    margin-top: 0;
                    width: 100%;
                    margin-bottom: 0;
                    background: #fff;
                    border: 1px solid #ddd !important;
                    padding: 0;

                    .MuiInput-underline {
                        &::before {
                            display: none;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .MuiInputBase-root.MuiInput-root {
                        input {
                            text-align: center;
                            font-size: 13px;
                        }

                        .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                            .MuiButtonBase-root.MuiIconButton-root {
                                padding: 4px 2px;
                                background-color: #139BD6;
                                border-radius: 0;
                                color: #fff;
                            }
                        }
                    }

                }
            }

            .MuiButtonBase-root {
                background: #165a77;
                box-shadow: none;
                outline: none;
                color: #fff;
                padding: 8px 20px 7px;
                font-size: 14px;

                &:hover {
                    background: #139BD6;
                    transition: all 0.5s;
                }
            }

            & input.form-control {
                width: 100%;
                height: 32px;
                padding: 4px 6px;
                font-size: 12px;
                line-height: 1.42857143;
                color: #000 !important;
                border: 1px solid #ced4da;
                background: #fff;
                border-top-color: #ced4da;
                outline: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0;
            }

            & span.dk-clViewAddonIcon {
                border: 1px solid #139BD6;
                padding: 0;
                background-color: #139BD6;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                min-width: 25px;
                max-width: 25px;
                height: 32px;

                & i {
                    color: #fff;
                }
            }

            & select.form-control {
                width: 100%;
                height: 26px;
                padding: 4px 6px;
                font-size: 12px;
                line-height: 1.42857143;
                color: #737577 !important;
                border: 1px solid #ced4da;
                background: #fff;
                outline: 0;
                border-radius: 0;
            }

            & select.form-control.addNewSelect {
                height: 32px;
            }

            & select.form-control {
                appearance: none;
            }
        }
    }

    .dkaddNewRightCon {
        height: calc(100vh - 210px);
        background: #D9DFE7;
        border-radius: 5px;
        padding: 15px;
        // margin-top: 43px;
        display: flex;
        justify-content: center;
        align-items: center;

        & .addNewBox .dk-FileUploadCon .dk-fileUldimgCon {
            width: 300px;
            max-width: 250px;
            height: 180px;
            margin: 0 auto;
            border: 3px dashed lightslategrey;
            display: flex;
            justify-content: center;
            align-items: center;

            figure {
                canvas {
                    height: 170px;
                    margin-top: 6px;
                    max-height: 170px;
                    min-height: 170px;
                }
            }

            h3 {
                text-transform: uppercase;
                color: #324148;
                padding: 40px 0;
                font-size: 1.4rem;
                font-weight: 400;
            }

            img {

                width: 250px;
                height: 185px;
            }
        }

    }
}

// Start Css For browing img 
.dk-FileUploadCon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dk-browsedFile {
        position: relative;

        input {
            position: absolute;
            bottom: 0;
            z-index: 9;
            cursor: pointer;
            width: 100%;
            height: 41px;
            opacity: 0;
        }

        button {
            cursor: pointer;
            position: relative;
            z-index: 1;
        }
    }

    .dk-uploadView-img {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 15px 0 0;
        background: #fff;
        border-radius: 5px;
        padding: 10px;
        position: relative;

        img {
            width: 100%;
            max-width: 160px;
            margin: auto;
        }

        span.close-btn {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #000;
            color: #fff;
            line-height: 15px;
            text-align: center;
            font-size: 10px;
            position: absolute;
            top: -3px;
            right: -3px;
            cursor: pointer;
        }
    }

    & img {
        width: 100%;
        height: auto;
    }

    & button {
        margin-top: 20px;
        width: 250px;
        background-color: #ddd;
        color: #fff;
        background: #139BD6;
        border: none;
        padding: 10px;
        border-radius: 4px;
        transition: all .2s ease;
        outline: none;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;

        input {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 35px;
            cursor: pointer;
        }
    }

    & button:hover {
        background: #165a77;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }
}

.dk-uploadModalMain {
    .modal-dialog {
        .modal-body {
            .ReactCrop {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }

        .modal-footer {
            button {
                display: inline-block;
                padding: 7px 18px;
                border: none;
                margin: 0 5px;
                box-shadow: none;
                background: #165a77;
                color: #fff;
                border-radius: 5px;
                text-transform: uppercase;
                font-weight: 500 !important;
                font-size: 14px;

                &:hover {
                    background: #139BD6;
                    transition: all 0.5s;
                }
            }
        }
    }
}

.ui.selection.dropdown.dk-overdues-task-slect {
    width: 100%;
}