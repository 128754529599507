.dk-liveads-stusfilterDrop{
    .dk-liveads-stusfilter-toggle{
        border-radius: 0;
        background-color: #eaeaea;
        border: 1px solid #ddd;
        color: #797474;
        font-size: 12px;
        box-shadow: none;
        margin: 0;
        padding: 0.5rem 0.35rem;
        min-width: 120px;
        text-transform: capitalize;
        &::after{
            position: absolute;
            right: 6px;
            top: 15px;
            color: #797474;
        }
    }
    .dk-liveads-stusfilterDropMenu{
        border-radius: 0;
        padding: 0;
        .dropdown-item{
            background-color: #eaeaea;
            border-top: 1px solid #ddd;
            padding: 0.5rem 0.4rem;
            font-size: 13px;
            &:first-child{
                border-top: none;
            }
            &:hover{
                background-color: #f1f1f1;
            }
        }
    }
}