.dk-jobprofile-colorDropdown{
    .dk-jpcolorDropdown-toggle{
        margin: 0;
        padding: 0;
        background: gray;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        height: 30px;
        width: 30px;
        &::after{
            display: none;
        }
    }
    .dk-jpcolorDropdown-menu{
        border-radius: 0;
        padding: 0;
        .dk-colorDropdown-Item{
            margin: 5px;
            padding: 0;
            border-radius: 50%;
            cursor: pointer;
            display: inline-block;
            height: 24px !important;
            width: 24px !important;
        }
    }
}
// Start SCSS For Job Profile tabs
.dk-editJobProfile-head{
    background-color: inherit !important;
    .dkg-jobprofle-tabsCon{
        width: 100%;
        margin: 0;
        .dkg-jobprofle-pills{
            width: 100%;
            display: flex;
            .dkg-jobprofle-navitem{
                white-space: nowrap;
                background-color: #c09f80 !important;
                &:first-child{
                    width: 10%;
                }
                &:nth-child(02){
                    width: 10%;
                }
                &:nth-child(03){
                    width: 60%;
                    background-color: #c09f80 !important;
                }
                &:nth-child(04){
                    width: 10%;
                }
                &:nth-child(05){
                    width: 10%;
                }
                .nav-link{
                    border-radius: 0;
                    // border-bottom: 1px solid #ddd;
                    max-width: inherit !important;
                }
                
            }
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-jobProfileMain{
        padding: 0 !important;
        margin-top: 173px;
        .dk-jobprofiles-mo-pagetitleCon{
            margin: 0;
            background-color: #359ab8;
            display: block!important;
            margin-bottom: 15px;
            padding: 9px 10px;
            text-align: center;
            width: 100%;
            .dk-jobprofiles-mo-title{
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
            }
        }
        .dk-jobProfile-users{
            width: 100%;
            max-width: 95%;
            margin: 0 auto;
            .d-flex{
                justify-content: inherit !important;
                .dk-jobProfile-users-box{
                    width: 40px;
                    height: 40px;
                    margin: 0 5px;
                    img{
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        .dk-jobProfile-container{
            .d-md-flex{
                .dk-jobProfile-cols{
                    max-width: 95%;
                    margin: 10px auto;
                }
            }
        }
    }
    .dk-editJobProfile{
        .modal-body{
            .dk-editJobProfile-container{
                .dk-editJobProfile-head {
                    .dkg-jobprofle-tabsCon{
                        .dkg-jobprofle-pills{
                            .dkg-jobprofle-navitem{
                                height: 35px;
                                a{
                                    font-size: 13px;
                                }
                                &:first-child {
                                    width: 13%;
                                }
                                &:nth-child(02) {
                                    width: 16%;
                                }
                                &:nth-child(03) {
                                    width: 40%;
                                }
                                &:nth-child(04) {
                                    width: 19%;
                                }
                                &:nth-child(05) {
                                    width: 12%;
                                    a{
                                        .ml-4.modal-header{
                                            margin-left: 0.5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}
