.dk-notiAddnew-main {
    margin-top: 190px;

    .dk-notiAddnew-container {
        width: 100%;
        max-width: 550px;
        margin: auto;
        background: #eaeaea;
        border: 1px solid #ccc;
        border-radius: 5px;

        form {
            padding: 25px 25px 10px;
        }

        .title {
            background: #396a95;
            padding: 8px 15px;
            text-align: center;
            border-radius: 5px 5px 0 0;
            color: #fff;
            text-transform: uppercase;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            position: relative;

            .dk-notiUser {
                position: absolute;
                left: 10px;
                top: 5px;
            }
        }

        .sbtitle {
            white-space: nowrap;
        }

        .dk-notiUser {
            display: inline-block;
            width: 100%;
            max-width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }

        .form-group {
            width: 100%;

            .dk-showUser-dropdown {
                .dropdown {
                    .dropdown-toggle {
                        background-color: transparent !important;
                        outline: none;
                        border: none;
                        box-shadow: none;
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        padding: 0;
                        margin: 0;

                        span {
                            display: inline-block;
                            white-space: nowrap;
                            color: #333;
                            margin-left: 10px;
                            text-transform: initial;
                            font-size: 14px;
                        }

                        img {
                            display: inline-block;
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        padding: 5px 5px;
                        border-radius: 0;
                        margin: 0 !important;
                        border: none;
                        background: #396a95;
                        transform: inherit !important;
                        top: -6px !important;
                        left: inherit !important;
                        right: 17px !important;

                        &::before {
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 8px 15px 8px 0;
                            border-color: transparent #396a95 transparent transparent;
                            line-height: 0px;
                            _border-color: #000000 #396a95 #000000 #000000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                            position: absolute;
                            left: -13px;
                            top: 14px;
                        }

                        a {
                            display: inline-block;
                            height: 35px;
                            height: 35px;
                            margin: 0 3px;
                            padding: 0;

                            &:focus {
                                background: transparent !important;
                            }

                            img {
                                display: inline-block;
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                            }

                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                }
            }

            .dk-nativedatepicker-grid {
                .dk-nativedatepicker {
                    margin: 0 !important;
                    background: #fff;
                    border: 1px solid #ddd;
                    width: 100%;

                    .MuiInputBase-root {
                        &::before {
                            display: none;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    input.MuiInputBase-input {
                        border: none !important;
                        text-align: center;
                    }
                }

                .MuiButtonBase-root {
                    outline: none;

                    svg {
                        display: none;
                    }

                    &::after {
                        content: '';
                        background: url('../../../../../assets/images/calendar.png') no-repeat;
                        position: absolute;
                        right: 10px;
                        top: 1px;
                        background-size: 20px;
                        padding: 11px 10px;
                    }
                }
            }

            .dk-nativetimeepicker-grid {
                .dk-nativetimeepicker {
                    margin: 0 !important;
                    background: #fff;
                    border: 1px solid #ddd;
                    width: 100%;

                    .MuiInputBase-root {
                        &::before {
                            display: none;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    input.MuiInputBase-input {
                        border: none !important;
                        text-align: center;
                    }
                }

                .MuiButtonBase-root {
                    outline: none;
                }
            }

            select {
                border: 1px solid #ddd !important;
                background: #fff;
                box-shadow: none !important;
                height: 40px;
                border-radius: 3px;
                padding: 0 10px;
                text-align: left;
            }

            input {
                border: 1px solid #ddd !important;
                background: #fff;
                box-shadow: none !important;
                height: 40px;
                border-radius: 3px;
                padding: 0 10px;
                text-align: left;

                &:focus::placeholder {
                    color: transparent;
                }
            }

            textarea {
                border: 1px solid #ddd !important;
                background: #fff;
                box-shadow: none !important;
                height: 110px;
                border-radius: 3px;
                resize: vertical;
                text-align: left;

                &:focus::placeholder {
                    color: transparent;
                }
            }

            .dk-notiAddnewBtn {
                display: inline-block;
                padding: 8px 25px;
                background: #396a95;
                border: none;
                outline: none;
                border-radius: 5px;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                text-transform: uppercase;
                font-weight: 500;

                &:hover {
                    background: #3c97b6;
                    transition: all 0.5s;
                }
            }
        }
    }
}

.dkg-noti-addnew-Con {
    margin-top: 160px;

    .dkg-noti-addnew-mainCon {
        // background-color: #eaeaea;
        // padding: 15px 25px;
        // border-radius: 5px;
        // height: calc(100vh - 250px);


        .dk-announcementsModalBody {
            .dk-taskprocess-cardCon {

                // height: calc(100vh - 205px);
                // overflow-y: auto;
                &:first-child {
                    .dk-taskprocess-card {
                        margin-top: 0;
                    }
                }

                .dk-taskprocess-card {
                    background-color: #fff;
                    border-radius: 5px;
                    padding: 0;
                    margin: 12px 15px;

                    .dk-taskprocess-card-body {
                        display: flex;
                        justify-content: flex-start;

                        .dk-user-imgCon {
                            width: 90px;
                            border: 1px solid #ddd;
                            padding: 0.5rem;
                            text-align: center;
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;

                            img {
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;
                                border: 2px solid #ccc;
                            }

                            .dk-announce-clock {
                                // font-weight: 600;
                                font-size: 12px;
                                white-space: nowrap;
                                position: relative;
                                top: 2px;
                            }
                        }

                        .dk-inprogrestextCon {
                            display: flex;
                            align-items: center;
                            border: 1px solid #ddd;
                            border-left: none;
                            padding: 0.5rem;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;

                            .dk-recruiterName {
                                margin-bottom: 0.35rem;
                                font-size: 16px;
                                margin-top: 7px;
                            }

                            .dk-taskdetails {
                                font-size: 14px;
                                text-align: justify;
                            }
                        }

                        .dk-counter-commentCon {
                            display: flex;
                            justify-content: flex-start;
                            margin-top: 7px;

                            .dk-counterCon {
                                padding-right: 10px;
                            }

                            .dk-commentCon {
                                display: flex;

                                i {
                                    margin-top: 3px;
                                }

                                .dk-commentNumber {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 22px;
                                    height: 22px;
                                    border-radius: 50%;
                                    font-size: 13px;
                                    background-color: #ccc;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .dk-notificationsModalBody {
        .dk-warningMain {
            form {
                background-color: #eaeaea;
                padding: 15px 25px;
                border-radius: 5px;

                .row {
                    .col-12 {
                        .form-group {
                            .dkg-sub-form-control {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}