.dk-searchInlineDropDwn {
    color: #333;

    .dropdown {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        position: inherit;

        a {
            font-size: 13px;
            text-transform: capitalize;
            color: #3488de;
            width: 100%;
            padding: 1px 10px;
        }

        .dropdown-menu.show {
            transform: translate(0, 37px) !important;
            display: block;
            border-radius: 0;
            padding: 0;
            border-top: none;
            border-color: #96c8da;
            box-shadow: 0 2px 3px 0 rgba(0.34, 0.36, 38, 0.15);

            input.form-control {
                border-radius: 0;
                border: none;
                border-bottom: 1px solid #808080;
                font-size: 14px;
            }

            input.form-control:focus {
                box-shadow: none;
                outline: 0;
            }

            ul {
                margin-bottom: 0;
                min-height: 220px;
                max-height: 220px;
                overflow-y: auto;

                .dropdown-item {
                    border-top: 1px solid #ccc;
                    padding: 0.5rem 0.4rem;
                    background-color: #f2f2f2;
                    color: #333;
                }

                .dropdown-item:first-child {
                    border-top: none;
                }

                .dropdown-item:hover {
                    background-color: #fff;
                    color: #333;
                }

                .dropdown-item::after {
                    display: none;
                }
            }
        }
    }
}