.dk-workPlan-main {
    margin-top: 165px;
    padding: 0 25px;
    .dk-workPlan-head {
        background: transparent;
        padding: 0;
        border: none;
        position: relative;
        .dk-workPlanHead-leftpanel {
            display: flex;
            align-items: center;
            .dk-workHead-right-btn {
                display: flex;
                align-items: center;
                a {
                    color: #333;
                    margin-right: 5px;
                    display: inline-block;
                    &:hover {
                        color: #3488de;
                    }
                }
                .dk-reportCheckbox {
                    display: flex;
                    .dk-reportCheckbox-cols {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        background: #eaeaea;
                        border: 1px solid #ccc;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 3px;
                        border-radius: 3px;
                        cursor: pointer;
                        i {
                            padding: 0;
                            font-size: 13px;
                        }
                        &:first-child {
                            i {
                                font-size: 16px;
                                color: #6e6e6e;
                            }
                        }
                    }
                    .dk-reportCheckbox-cols.dk-circleBtn {
                        border-radius: 50%;
                    }
                }
            }
        }
        .dk-workPlanTags {
            margin-left: 0;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            a {
                position: relative;
                font-size: 12px;
                background: #366995;
                padding: 3px 0;
                border-radius: 30px;
                color: #fff;
                padding-left: 15px;
                &:hover {
                    background: #349ab8;
                    transition: all 0.5s;
                    span {
                        background: #366995;
                    }
                }
                span {
                    background: #234158;
                    padding: 5px 7px;
                    border-radius: 0 30px 30px 0;
                    font-size: 11px;
                    margin-left: 5px;
                }
            }
        }
        .dk-workPlanHead-right {
            display: flex;
            align-items: center;
            margin-right: 0;
            .dk-workHead-right-btn {
                display: flex;
                a {
                    display: inline-block;
                    padding: 5px 12px;
                    background: #eaeaea;
                    border-radius: 3px;
                    color: #333;
                    border: 1px solid #ccc;
                    text-transform: uppercase;
                    font-size: 13px;
                }
                a.dkgreen {
                    background: #3f9e6f;
                    color: #fff;
                    border-color: #3f9e6f;
                    width: 126px;
                    text-align: center;
                }
            }
        }
    }
    .dk-workPlan-conatiner {
        margin: 5px 0 0;
        .dk-liveProjectSrcoll {
            overflow-y: auto;
            height: calc(100vh - 216px);
            .dk-workPlanTable-main {
                border: 1px solid #ddd;
                margin-bottom: 25px;
                border-radius: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                table.dk-borderBtm {
                    border: none;
                    margin: 0 !important;
                    border-bottom: 1px solid #ddd;
                    tr {
                        td.dk-hotTags {
                            border-left: none;
                            border-radius: 5px 0 0;
                            overflow: hidden;
                            background: #fff;
                            .dk-checkBox {
                                margin: 3px -2px 0;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                position: relative;
                                z-index: 9;
                                a {
                                    font-size: 14px;
                                    margin: 0 4px
                                }
                                input[type="checkbox"] {
                                    cursor: pointer;
                                    -webkit-appearance: none;
                                    appearance: none;
                                    background: #fff;
                                    border-radius: 1px;
                                    box-sizing: border-box;
                                    position: relative;
                                    box-sizing: content-box;
                                    width: 12px;
                                    height: 12px;
                                    border-width: 0;
                                    transition: all 0.3s linear;
                                    position: relative;
                                    border: solid #ccc 1px;
                                    top: 1px;
                                    margin-left: 7px;
                                }
                                input[type="checkbox"]:checked {
                                    background-color: #438bca !important;
                                }
                            }
                        }
                        td {
                            font-size: 14px;
                            position: relative;
                            border-top: none;
                            border-right: none;
                            width: 7%;
                            white-space: nowrap;
                            background: #eaeaea;
                            padding: 5px 15px;
                            text-align: center;
                            vertical-align: middle;
                            border-right: 1px solid #dadada;
                            &:nth-child(01) {
                                text-align: right;
                                padding: 6px 12px;
                                border-right: 1px solid #ddd !important;
                                width: 11%;
                                background: #fff;
                                span {
                                    display: block;
                                    font-size: 13px;
                                }
                            }
                            &:last-child {
                                border-right: none;
                            }
                            .boxCols {
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 72px 72px 0 0;
                                border-color: #e24454 transparent transparent transparent;
                                line-height: 0px;
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 9;
                            }
                            .boxCols.dkgreen {
                                border-color: #3f9e6f transparent transparent transparent;
                                line-height: 0px;
                            }
                            .dk-workTitle {
                                margin-left: 0;
                                font-size: 17px;
                                font-weight: 500;
                                width: 100%;
                                cursor: pointer;
                                color: #000;
                                margin-bottom: 5px;
                                text-align: center;
                            }
                            &:nth-child(02) {
                                background: #fff;
                                .dk-workTableImg {
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 30px;
                                        border-radius: 50%;
                                        margin-right: 5px;
                                    }
                                }
                            }
                            .dk-tabletdTitle {
                                display: block;
                                font-size: 13px;
                                cursor: pointer;
                            }
                            .textblue {
                                color: #3488de;
                                font-size: 13px;
                            }
                            &:last-child {
                                padding-left: 0;
                                padding-right: 0;
                                .dk-commentBox {
                                    font-size: 18px;
                                    .dk-tabletdTitle.tableClick {
                                        border-bottom: 1px solid #ccc;
                                        padding-bottom: 5px;
                                        margin-bottom: 5px;
                                    }
                                    .commentBox-icon {
                                        cursor: pointer;
                                        small {
                                            width: 28px;
                                            height: 28px;
                                            background: #ccc;
                                            display: inline-block;
                                            line-height: 28px;
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                table.dk-tableTd-main.dk-tpHeaTableMain {
                    border: none;
                    margin: 0 !important;
                    tr {
                        td {
                            border-top: none;
                            border-bottom: none;
                            background: #eaeaea;
                            width: 14.28% !important;
                            padding: 8px 0;
                            border-right: 1px solid #dadada !important;
                            border-bottom: 1px solid #dadada !important;
                            text-align: center;
                            vertical-align: middle;
                            span {
                                display: block;
                                color: #3488de;
                                position: relative;
                                top: 4px;
                            }
                            &:nth-child(01) {
                                border-radius: 0;
                                border-left: none;
                            }
                            &:last-child {
                                border-right: none !important;
                            }
                        }
                    }
                }
                table.table.table-bordered.dk-tableTd-main {
                    margin: 0;
                    border: none;
                    tr {
                        td {
                            text-align: center;
                            padding: 8px 0;
                            font-weight: 500;
                            font-size: 13px;
                            color: #333;
                            border: none;
                            background: #eaeaea;
                            vertical-align: middle;
                            &:first-child {
                                border-radius: 0 0 0 5px;
                            }
                            span {
                                display: block;
                                position: relative;
                                top: 4px;
                            }
                            span.textyellow {
                                color: #ff9900;
                            }
                            span.textgreen {
                                color: green;
                            }
                            span.textblue {
                                color: #25cdff;
                            }
                            span.textred {
                                color: #e24454;
                            }
                        }
                    }
                }
                .dk-tableTabs {
                    overflow: hidden;
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        display: flex;
                        flex-wrap: nowrap;
                        margin-top: -45px;
                        transition: all 0.5s;
                        a {
                            padding: 12px 10px;
                            width: 100%;
                            background: #366995;
                            color: #ddd;
                            font-size: 14px;
                            margin: 0 !important;
                            border-right: 1px solid #5da0da;
                            text-align: center;
                            img {
                                width: 100%;
                                max-width: 16px;
                                display: inline-block;
                                position: relative;
                                top: 0;
                                opacity: 0.8;
                                margin-right: 5px;
                            }
                        }
                        a.active {
                            border-color: #234158;
                            background: #234158;
                        }
                    }
                }
                .dk-tableTabs.show {
                    ul {
                        margin-top: 0;
                        transition: all 0.5s;
                    }
                }
                .dk-tableChartShow.show {
                    table {
                        margin-top: -55px !important;
                        transition: all 0.5s;
                    }
                }
                .dk-tableChartShow {
                    overflow: hidden;
                    table {
                        margin-top: 0;
                        transition: all 0.5s;
                    }
                }
            }
        }
        .dk-liveProjectSrcoll::-webkit-scrollbar {
            width: 0px;
        }
        .dk-liveProjectSrcoll::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
        }
        .dk-liveProjectSrcoll::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0px solid slategrey;
        }
    }
}

.dk-projectAddNew-modal {
    .modal-dialog {
        min-width: 600px;
        .modal-content {
            border-radius: 7px;
            border: none;
            box-shadow: none;
            .modal-header {
                background: #366995;
                padding: 5px 0;
                border-radius: 5px 5px 0 0;
                .modal-title {
                    margin: 0;
                    display: block;
                    text-align: center;
                    width: 100%;
                    color: #fff;
                    font-weight: 500;
                    text-transform: uppercase;
                    font-size: 15px;
                }
                button.close {
                    padding: 0;
                    color: #fff;
                    margin: 0;
                    opacity: 1;
                    position: absolute;
                    right: 10px;
                    top: 7px;
                    outline: none;
                    font-weight: normal;
                }
            }
            .modal-body {
                min-height: 300px;
            }
        }
    }
}

.dk-liveProject-editModal {
    .modal-dialog {
        min-width: 1120px;
        height: auto !important;
        .modal-content {
            border-radius: 5px;
            border: none;
            box-shadow: none;
            background: #e9ecef;
            .modal-body {
                padding: 25px;
                .dk-editModal-tabs {
                    display: flex;
                    background: #fff;
                    .dk-editModal-tabsNav {
                        width: 100%;
                        max-width: 150px;
                        background: #366996;
                        .nav-pills {
                            flex-wrap: wrap;
                            border: none;
                            margin: 0;
                            .nav-item {
                                display: block;
                                width: 100%;
                                margin-bottom: 0;
                                a.nav-link {
                                    border: none;
                                    background: #366995;
                                    color: #fff;
                                    text-align: left;
                                    padding: 10px 12px;
                                    border-bottom: 1px solid #235077;
                                    font-size: 13px;
                                    white-space: nowrap;
                                    border-radius: 0;
                                }
                                a.active {
                                    background: #fff;
                                    color: #333;
                                }
                                &:last-child {
                                    a {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                    .dk-editModal-tabsPanel {
                        width: 100%;
                        padding: 20px;
                        .dk-editPanelcols {
                            .dk-editPanel-box {
                                padding: 15px 10px;
                                position: relative;
                                .dk-editModal-textCols {
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    max-width: 807px;
                                    margin-left: auto;
                                    .dk-jobIDTitle {
                                        display: inline-block;
                                        white-space: nowrap;
                                        background: #e9ecef;
                                        padding: 10px 25px;
                                        border-radius: 5px;
                                        margin-right: 9px;
                                        color: #333;
                                        border: 1px solid #ddd;
                                    }
                                    .dk-editModal-text {
                                        background: #e9ecef;
                                        border: 1px solid #ddd;
                                        padding: 10px 15px;
                                        margin-bottom: 0;
                                        border-radius: 5px;
                                        width: 100%;
                                        .title {
                                            font-weight: 500;
                                            font-size: 17px;
                                            display: block;
                                            margin-bottom: 0;
                                            margin-right: 0;
                                            text-align: center;
                                        }
                                    }
                                    .dk-editModal-titleBtn {
                                        width: 100%;
                                        max-width: 100px;
                                        display: flex;
                                        margin-left: 10px;
                                        a {
                                            display: inline-block;
                                            width: 40px;
                                            height: 40px;
                                            background: #e9ecef;
                                            border: 1px solid #ddd;
                                            text-align: center;
                                            line-height: 40px;
                                            color: #333;
                                            border-radius: 50%;
                                            font-size: 15px;
                                            margin: 0 5px;
                                            &:hover{
                                                background: #366995;
                                                color: #fff;
                                                border-color: #366995;
                                                transition: all 0.5s;
                                            }
                                        }
                                    }
                                }
                                .dk-editModalUser-main {
                                    padding: 0 15px;
                                    margin-top: 15px;
                                    position: relative;
                                    .dk-editModalUser-user {
                                        span.dk-dropText {
                                            margin-right: 10px;
                                        }
                                        button {
                                            background: #f2f2f2;
                                            border: 1px solid #ddd;
                                            width: 35px;
                                            height: 35px;
                                            margin: 0;
                                            text-align: center;
                                            line-height: 35px;
                                            border-radius: 50%;
                                            color: #333 !important;
                                            padding: 0;
                                            font-size: 14px;
                                            outline: none;
                                        }
                                    }
                                    .dk-editModalUser-img {
                                        display: flex;
                                        justify-content: center;
                                        margin-left: 0;
                                        position: absolute;
                                        left: 50%;
                                        transform: translate(-50%);
                                        a {
                                            padding: 0;
                                            width: 100%;
                                            height: 35px;
                                            max-width: 35px;
                                            max-height: 35px;
                                            margin: 0 3px;
                                            display: inline-block;
                                            img {
                                                padding: 0;
                                                width: 100%;
                                                height: 35px;
                                                max-width: 35px;
                                                max-height: 35px;
                                                border-radius: 50%;
                                                border: 1px solid #ccc;
                                            }
                                        }
                                        a.dk-addRec {
                                            display: inline-block;
                                            background: #e9ecef;
                                            text-align: center;
                                            line-height: 35px;
                                            border-radius: 50%;
                                            border: 1px solid #ddd;
                                            width: 35px;
                                            color: #333;
                                        }
                                    }
                                }
                            }
                            .dk-editModalListing{
                                .dk-editModalListing-cols{
                                    margin:15px 10px 0;
                                    label{
                                        &:first-child{
                                            width: 100%;
                                            max-width:108px;
                                            font-size: 13px;
                                            margin-left:0;
                                            margin-bottom:10px;
                                        }
                                        input{
                                            background:#e9ecef;
                                            border-radius: 3px;
                                            border:1px solid #ddd !important;
                                            box-shadow: none !important;
                                            height: 33px;
                                            padding: 0 15px;
                                            text-align: center;
                                            font-size: 13px;
                                        }
                                    }
                                    label.dkdropDown{
                                        width: 100%;
                                        margin-bottom: 8px;
                                        .selection.dropdown{
                                            width:100%;
                                            min-width: 100%;
                                            min-height: 35px;
                                            height: 35px;
                                            background: #e9ecef;
                                            border:1px solid #ddd;
                                            line-height:1em;
                                            font-size: 13px;
                                            border-radius: 3px;
                                            text-align: center;
                                            padding:10px 10px;
                                            i{
                                                padding: 6px 4px;
                                                font-size: 18px;
                                            }
                                            .default.text{
                                                color: #333;
                                                opacity: 1;
                                                text-align: center;
                                            }
                                            .visible.menu{
                                                border: 1px solid #ddd !important;
                                                .item{
                                                    padding:10px 10px !important;
                                                    font-size: 13px;
                                                    font-weight: normal !important;
                                                }
                                            }
                                        }
                                    }
                                    label.dk-date{
                                        position: relative;
                                        span{
                                            position: absolute;
                                            right: 0;
                                            top: 0;
                                            width: 30px;
                                            height: 33px;
                                            background: #e9ecef;
                                            line-height: 32px;
                                            text-align: center;
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}