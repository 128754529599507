.dk-kpiColsMain {
    padding: 0 15px;
    height: 100vh;
}

.dk-kpiColsMain.kpiTableDetailMain {
    background: #fff;

    .dk-detailTableMain {
        .dk-detailHead {
            color: #333;

            .dk-detailsLeft {
                .dk-searchCols {
                    position: relative;
                    width: 100%;
                    max-width: 160px;
                    position: relative;

                    button {
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: transparent;
                        border: none;
                        outline: none;
                        height: 35px;
                        width: 30px;
                        border-left: 1px solid #ddd;
                        font-size: 12px;
                        color: #737373;
                    }

                    // &::after{
                    //     content: "\f002";
                    //     display: inline-block;
                    //     font-family: 'Font Awesome 5 Free';
                    //     font-size:16px;
                    //     font-weight:bold;
                    //     text-rendering:auto;
                    //     -webkit-font-smoothing: antialiased;
                    //     -moz-osx-font-smoothing: grayscale;
                    //     position: absolute;
                    //     right: 9px;
                    //     top: 8px;
                    //     font-size: 14px;
                    //     color: #797979;
                    // }
                    input {
                        border: 1px solid #ddd !important;
                        color: #333;
                        background: #fff;
                        text-align: left;

                        &:focus::placeholder {
                            color: transparent;
                        }
                    }

                    ::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: #333;
                    }

                    ::-moz-placeholder {
                        /* Firefox 19+ */
                        color: #333;
                    }

                    :-ms-input-placeholder {
                        /* IE 10+ */
                        color: #333;
                    }

                    :-moz-placeholder {
                        /* Firefox 18- */
                        color: #333;
                    }
                }

                .dk-userDrop {
                    .dropdown {
                        .dropdown-toggle {
                            border-color: #ddd;
                            color: #7b7b7b;
                            background: #fff;
                        }
                    }
                }

                .dk-detailAdd {
                    button {
                        border-color: #ddd;
                        color: #7b7b7b;
                        background: #fff;
                    }
                }

                .dk-addNewBtn {
                    a {
                        display: inline-block;
                        padding: 6px 15px;
                        border: 1px solid #ddd;
                        border-radius: 3px;
                        color: #333;
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 500;
                        margin-left: 5px;
                        background: #fff;
                    }
                }
            }
        }

        .table-responsive {
            height: calc(100vh - 257px) !important;

            table {
                tr {
                    th {
                        background: #2e5a7c !important;

                        &:nth-child(01) {
                            width: 4%;
                        }
                    }

                    td {
                        &:nth-child(02) {
                            padding-right: 8px !important;
                            padding-left: 8px !important;
                        }

                        .dk-kpisTableActioon {
                            a {
                                display: inline-block;
                                font-size: 16px;
                                margin: 0 3px;
                                font-weight: bold;
                            }

                            a.dk-editBtn {
                                color: blue;
                            }

                            a.dk-deleteBtn {
                                color: #E2445B;
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {
                            background: #eaeaea !important;
                            color: #333 !important;
                            border: 1px solid #d4d4d4 !important;
                        }
                    }
                }
            }
        }
    }
}

.dk-kpitableMain {
    .dk-headCols {
        color: #fff;
        position: relative;
        padding: 35px 0 12px;

        h3.title {
            text-align: center;
            width: 100%;
            margin: 0;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .dk-kpiTable {
        .table-responsive {
            overflow-y: auto;
            height: calc(100vh - 232px);
        }

        table {
            position: relative;
            margin-bottom: 0;
            border-top: none;

            tr {
                th {
                    font-size: 14px;
                    text-align: center;
                    white-space: nowrap;
                    padding: 7px 15px;
                    border: none;
                    background-color: #fff;
                    min-width: 100px;
                    max-width: 100px;
                    position: sticky;
                    top: 0;
                    z-index: 99;
                    cursor: text;
                    color: #333;

                    .dk-recrImg {
                        img {
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                        }
                    }

                    .dk-addCols {
                        button {
                            display: inline-block;
                            background: #ccc;
                            border: none;
                            width: 35px;
                            height: 31px;
                            border-radius: 3px;
                            outline: none;
                        }
                    }
                }

                td {
                    background: #244158;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    white-space: nowrap;
                    padding: 5px 15px;
                    border: 1px solid #ccc;
                    vertical-align: middle;

                    &:nth-child(01) {
                        width: 130px;

                        span {
                            display: inline-block;
                            width: 33px;
                            text-align: left;
                            margin-left: 7px;
                        }
                    }

                    .dk-dateDayCols {
                        cursor: pointer;
                    }

                    span.addNumberCols {
                        display: block;
                        cursor: pointer;
                    }
                }

                td.dk-brBgNone {
                    border: none;
                    background: transparent;
                }

                td.dk-brNone {
                    border: none;
                }
            }
        }
    }
}

.dk-dateAndDayModal.modal {
    background: rgba(0, 0, 0, 0.46);
}


.dk-kpiColsMain {
    .dk-detailTableMain {
        padding: 0 5px;

        .dk-detailHead {
            position: relative;

            .dk-detailsLeft {
                display: flex;
                align-items: center;

                .dk-searchCols {
                    width: 100%;
                    max-width: 180px;
                    margin-right: 3px;


                    input {
                        border-radius: 3px;
                        border: 1px solid #787d84 !important;
                        box-shadow: none !important;
                        background: transparent;
                        color: #ccc;
                        height: 35px;
                        font-size: 13px;
                    }

                    input::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: #ccc;
                    }

                    input::-moz-placeholder {
                        /* Firefox 19+ */
                        color: #ccc;
                    }

                    input:-ms-input-placeholder {
                        /* IE 10+ */
                        color: #ccc;
                    }

                    input:-moz-placeholder {
                        /* Firefox 18- */
                        color: #ccc;
                    }
                }

                .dk-userDrop {
                    .dropdown {
                        .dropdown-toggle {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: transparent;
                            box-shadow: none;
                            color: #ccc;
                            font-size: 16px;
                            border: 1px solid #787d84;
                            padding: 0 !important;
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            line-height: 35px;
                            margin: 0 3px;

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            transform: inherit !important;
                            left: 55px !important;
                            right: inherit !important;
                            top: 0 !important;
                            padding: 3px 2px;
                            background: #f2f2f2;

                            &::before {
                                content: '';
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 7.5px 12px 7.5px 0;
                                border-color: transparent #f2f2f2 transparent transparent;
                                line-height: 0px;
                                _border-color: #000000 #f2f2f2 #000000 #000000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                                position: absolute;
                                left: -11px;
                                z-index: 9;
                                top: 11px;
                            }

                            a {
                                display: inline-block;
                                padding: 0;
                                margin: 0 3px;
                                width: 32px;
                                height: 32px;

                                img {
                                    margin: 0 3px;
                                    width: 32px;
                                    height: 32px;
                                    display: inline-block;
                                    border-radius: 50%;
                                }

                                &:active {
                                    background: transparent;
                                }
                            }
                        }
                    }
                }

                .dk-detailAdd {
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: transparent;
                        box-shadow: none;
                        font-size: 16px;
                        border: 1px solid #787d84;
                        padding: 0 !important;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        line-height: 35px;
                        text-align: center;
                        color: #ccc;
                        outline: none;
                    }
                }
            }
        }

        .dk-detailsTable {
            margin-top: 5px;

            .table-responsive {
                overflow-y: auto;
                height: calc(100vh - 295px);

                Table {
                    margin: 0;
                    border-top: none;

                    tr {
                        th {
                            background: #2e75b6;
                            font-size: 13px;
                            vertical-align: middle;
                            padding: 8px 12px;
                            text-align: center;
                            color: #fff;
                            border-bottom: none;
                            position: sticky;
                            top: 0;
                            z-index: 99;
                            white-space: nowrap;
                            border-top: 1px solid #fff;
                        }

                        td {
                            background: #fff;
                            text-align: center;
                            color: #333;
                            vertical-align: middle;
                            padding: 0 8px;
                            font-size: 13px;
                            white-space: nowrap;

                            // &:nth-child(01) {
                            //     display: flex;
                            // }

                            &:nth-child(05) {
                                padding: 0;
                            }

                            &:nth-child(06) {
                                text-align: left;
                            }

                            .dkg-kpispec-sno-idcon {
                                display: flex;
                                justify-content: center;

                                .dk-noTd {
                                    display: flex;
                                    justify-content: center;
                                    padding: 0 8px;

                                    span {
                                        display: inline-block;
                                    }

                                    input[type="checkbox"] {
                                        cursor: pointer;
                                        -webkit-appearance: none;
                                        appearance: none;
                                        background: #e4e8ec;
                                        border-radius: 1px;
                                        box-sizing: border-box;
                                        position: relative;
                                        box-sizing: content-box;
                                        width: 12px;
                                        height: 12px;
                                        border-width: 0;
                                        transition: all 0.3s linear;
                                        border: solid #ccc 1px;
                                        top: 3px;
                                        margin-right: 10px;
                                    }

                                    input[type="checkbox"]:checked {
                                        background-color: #34976e !important;
                                    }
                                }
                            }


                            .dk-recrImg {
                                img {
                                    width: 32px;
                                    height: 32px;
                                    border-radius: 50%;
                                }
                            }

                            .dk-status {
                                background: #009a9a;
                                color: #fff;
                                padding: 8px 0;
                            }
                        }
                    }

                    tfoot {
                        tr {
                            td {
                                padding: 8px 0;
                                background: #374252;
                                color: #fff;
                                border-color: #9d9898;
                                position: sticky;
                                bottom: 0;
                                z-index: 99;
                                min-width: 100px;
                                max-width: 100px;
                                position: sticky;
                                top: 0;
                                z-index: 99;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-dateAndDayModal {
    .modal-dialog {
        max-width: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 10px auto;

        .modal-content {
            border-radius: 5px;
            border: none;
            box-shadow: none;

            .modal-header {
                background: #2e5a7c;
                padding: 10px 15px;
                border-radius: 4px 4px 0 0;

                .modal-title {
                    color: #fff;
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                }

                .close {
                    color: #fff;
                    opacity: 1;
                    text-shadow: none;
                    font-weight: 600;
                    outline: none;
                }
            }

            .modal-body {
                padding: 20px 25px;

                form {
                    label {
                        width: 100%;

                        input {
                            border-radius: 0;
                            height: 35px;
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                            font-size: 14px;
                        }

                        select {
                            border-radius: 0;
                            height: 35px;
                            border: 1px solid #ddd !important;
                            box-shadow: none !important;
                            font-size: 14px;
                        }
                    }

                    .dk-AddBtn {
                        display: inline-block;
                        padding: 8px 18px;
                        background: #2e5a7c;
                        border-radius: 5px;
                        color: #fff;
                        text-transform: uppercase;
                        border: none;
                        outline: none;
                        font-weight: 500;

                        &:hover {
                            background: #374252;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-addNumberModal {
    background: rgba(0, 0, 0, 0.46);

    .modal-dialog {
        max-width: 300px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;

        .modal-content {
            border-radius: 5px;

            .modal-body {
                padding: 25px 35px;

                label {
                    text-align: center;
                    width: 100%;
                }

                input {
                    border-radius: 3px;
                    text-align: center;
                    box-shadow: none !important;
                    border: 1px solid #ddd !important;
                }

                .dk-AddBtn {
                    display: inline-block;
                    padding: 8px 22px;
                    background-color: #0086c0;
                    text-transform: uppercase;
                    color: #fff;
                    outline: none;
                    border: none;
                    border-radius: 5px;

                    &:hover {
                        background: #374252;
                        transition: all 0.5s;
                    }
                }
            }
        }
    }
}

.dk-batch-actions-menu-wrapper {
    background: #f7f2f2;
    border: 1px solid #a19a9a;
    border-radius: 5px;
    bottom: 60px;
    box-shadow: 0 22px 104px -6px rgb(0 0 0 / 34%);
    display: flex;
    flex-direction: row;
    height: 63px;
    left: 50%;
    transform: translate(-50%);
    position: fixed;
    transition: bottom .2s ease;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 999;
    width: 100%;
    max-width: 262px;

    .dk-num-of-actions_wrapper {
        border-left: 1px solid #a19a9a;
        color: #fff;
        background: #009393;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-size: 24px;
        line-height: 60px;
        max-width: 70px;
        min-width: 70px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
        text-align: center;
        white-space: nowrap;
    }

    .dk-batch-actions-item {
        background: #fff;
        color: #333;
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border-left: 1px solid #a19a9a;
        font-size: 12px;
        white-space: nowrap;
        min-width: 70px;
        max-width: 70px;
    }

    .dk-batch-actions-delete-item {
        display: flex;
        width: 50px;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-left: 1px solid #d4d4d4;
        background-color: #fff;
        color: #333;
    }
}