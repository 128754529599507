.dkg-cashFlow-dashboardMain {
    margin-top: 120px;

    .dkg-cashFlow-dashboardRow {
        .dkg-cashFlow-dashboardCol {
            .dkg-cashFlow-dashboard-topHead {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dkg-cashFlow-LeftCon {
                    .dk-kpiCase-yearsDropdown {
                        margin-top: 20px;
                        margin-bottom: 5px;

                        .dropdown-toggle {
                            background: #f2f2f2 !important;
                            border-radius: 3px;
                            box-shadow: none;
                            color: #333 !important;
                            font-size: 13px;
                            margin: 0;
                            min-width: 110px;
                            padding: 9px 10px;
                            z-index: 99999;
                            border: 1px solid #ddd;

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            background: #fff;
                            border: none;
                            border-radius: 0;
                            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                            margin: 35px 0 0 !important;
                            min-width: 110px;
                            padding: 0;
                            -webkit-transform: inherit !important;
                            transform: inherit !important;
                            z-index: 99999;

                            a {
                                padding: 6px 10px;
                                font-size: 13px;

                                &:hover {
                                    background: #f8f9fa;
                                }
                            }

                            a.active {
                                background: #3c97b6;
                            }
                        }
                    }
                }

                .dkg-cashFlow-RightCon {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .dkg-cashflow-block {
                        min-width: 130px;

                        .dkg-title {
                            font-size: 15px;
                            width: 100%;
                            text-align: center;
                        }

                        .dkg-blockCon {
                            background-color: #f2f2f2;
                            height: 37px;
                            border-radius: 5px;
                            border: 1px solid #ddd;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            div {
                                font-size: 14px;
                            }

                        }
                    }
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;
                    flex-wrap: wrap;
                    background-color: #f2f2f2;
                    border: 1px solid #ddd;

                    li {
                        display: inline-block;
                        list-style-type: none;
                        width: 100%;
                        max-width: 9.999%;

                        a {
                            background: #f2f2f2;
                            border-right: 1px solid #ddd;
                            color: #333;
                            cursor: pointer;
                            float: left;
                            padding: 7px 15px;
                            text-align: center;
                            width: 100%;

                            &.active {
                                background: #5A4C72;
                                border-right: 1px solid #5A4C72;
                                color: #fff;
                            }

                            &:first-child {
                                border-left: none;
                            }

                            &:last-child {
                                border-right: 1px solid #ddd;
                            }
                        }
                    }
                }
            }

            .dk-cashFlow-dashboard-yearMain {
                // max-width: 1600px;
                // margin: auto;
                // margin-top: 125px;
                // padding: 0 15px;
                // padding-left: 6px;
                width: 100%;
                height: calc(100vh - 180px);
                overflow-y: auto;
                margin-top: 0;
                padding: 15px;
                padding-top: 0;

                .dk-leftBoxes-main {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;

                    .dk-leftBoxes-panel {
                        width: 100%;
                        display: flex;
                        align-items: start;

                        .dk-leftBoxes-cols {
                            background: #fff;
                            border: 1px solid #ddd;
                            border-radius: 5px;
                            width: 100%;
                            margin: 12px;
                            // height: 295px;

                            .dkg-cashflow-suMM-panel {
                                ul {
                                    margin-top: 1rem;
                                    // margin-bottom: 1.5rem;
                                    margin-bottom: 0;

                                    li {
                                        background: #fff;
                                        // border-bottom: 1px solid #ddd;
                                        font-size: 12px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        list-style-type: none;
                                        padding: 8px 10px;
                                        text-align: left;
                                        width: 100%;

                                        &:nth-child(02) {
                                            margin-bottom: 1rem;
                                        }


                                        .dkg-total-orange-color {
                                            color: #3a9469;
                                            text-transform: uppercase;

                                        }

                                        .dkg-total-green-color {
                                            color: #d91010;
                                            text-transform: uppercase;

                                        }

                                        .dkg-total-blue-color {
                                            color: #333;
                                            text-transform: uppercase;

                                        }

                                        span.leftItem {
                                            font-size: 12px;
                                        }

                                        span.rightItem {
                                            color: #333;
                                            display: inline-block;
                                            font-size: 12px;
                                            text-align: center;


                                            &.dkg-total-orange-counter {
                                                color: #3a9469;
                                            }

                                            &.dkg-total-green-counter {
                                                color: #d91010;
                                            }

                                            &.dkg-total-blue-counter {
                                                color: #333;
                                            }
                                        }

                                        &:last-child {

                                            border-bottom: none;
                                            border-top: 1px solid #ddd;
                                            padding: 8px 5px;
                                            text-transform: capitalize;
                                            font-size: 12px;

                                            .leftItem {
                                                color: #26597d;
                                                text-transform: capitalize;
                                            }

                                            .rightItem {
                                                color: #26597d !important;
                                                text-transform: capitalize;
                                            }
                                        }

                                        // &:hover {
                                        //     background-color: #5A4C72;

                                        //     .leftItem {
                                        //         color: #fff;
                                        //     }

                                        //     .rightItem {
                                        //         color: #fff !important;
                                        //     }
                                        // }
                                    }
                                }

                                .dkg-cashflow-dashboard-table-89 {
                                    margin-bottom: 0;
                                    border: none;

                                    tbody {
                                        tr {
                                            td {
                                                text-align: center;
                                                border: none;
                                                border-bottom: 1px solid #ddd;
                                                padding: 8px 5px;
                                                text-transform: capitalize;
                                                font-size: 12px;
                                                color: #26597d;

                                                &:nth-child(01) {
                                                    // border-right: 1px solid #ddd;
                                                    text-align: left;
                                                    min-width: 95px;
                                                }

                                                &:nth-child(02) {
                                                    color: #26597d;
                                                    text-align: right;
                                                }
                                            }

                                            &:first-child {
                                                td {
                                                    border-bottom: none;
                                                    border-top: none !important;
                                                }
                                            }

                                            &:last-child {
                                                td {
                                                    border-bottom: none;
                                                }
                                            }

                                            &:first-child {
                                                td {
                                                    border-top: 1px solid #ddd;
                                                }
                                            }

                                            // &:hover {
                                            //     background-color: #5A4C72;

                                            //     td {
                                            //         color: #fff;

                                            //         &:nth-child(02) {
                                            //             color: #fff;
                                            //         }
                                            //     }
                                            // }
                                        }
                                    }
                                }
                            }

                            .title {
                                border-bottom: 1px solid #ddd;
                                background: #f2f2f2;
                                text-transform: uppercase;
                                font-size: 14px;
                                display: block;
                                text-align: center;
                                padding: 8px 0;
                                border-radius: 5px 5px 0 0;
                            }
                        }
                    }
                }

                // .dk-rightBoxes-main {
                //     width: 16%;
                //     margin-left: 6px;

                //     .dk-leftBoxes-panel {
                //         .dk-leftBoxes-cols {
                //             background: #f2f2f2;
                //             border: 1px solid #cfcfcf;
                //             border-radius: 5px;
                //             width: 100%;
                //             margin: 6px 0;

                //             .title {
                //                 border-bottom: 1px solid #ccc;
                //                 background: #ddd;
                //                 text-transform: uppercase;
                //                 font-size: 14px;
                //                 display: block;
                //                 text-align: center;
                //                 padding: 8px 0;
                //                 border-radius: 5px 5px 0 0;
                //             }

                //             .dk-textPanel {
                //                 min-height: 625px;
                //             }
                //         }
                //     }
                // }

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}