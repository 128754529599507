.dk-notificationsModal {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 620px !important;
        max-width: 620px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dk-notificationsModalHeader {
                // background-color: #234158;
                background-color: #3c97b6;
                padding: 12px 10px;
                border-radius: 0;
                width: 100%;
                border-bottom: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .dk-plusicon-con {
                    color: #fff;
                    cursor: pointer;
                }

                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: relative;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    width: 100%;
                    text-align: center;

                    .dk-plusicon-con {
                        position: absolute;
                        right: -20px;
                        cursor: pointer;

                        i {
                            color: #e3dfdf;
                            font-size: 16px;
                        }
                    }
                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: 0;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dk-notificationsModalBody {
                background-color: #eaeaea;
                padding: 25px;
                height: calc(100vh - 45px);
                overflow-y: auto;

                &.dkg-cummnicationlogs-modalbody {
                    padding: 0;

                    .dk-saveresetbtnspec {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
    
                        button {
                            display: inline-block;
                            text-transform: uppercase;
                            padding: 8px 15px;
                            font-weight: 500;
                            font-size: 14px;
                            outline: none;
                            cursor: pointer;
                            border-radius: 5px;
                            min-width: 120px;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
    
                        button.dk-resetjobBtn {
                            color: #fff;
                            background-color: #6c757d;
                            border: none;
                        }
    
                        button.btn.dk-createjobBtn {
                            background: #26597d;
                            border: none;
                            color: #fff;
                            margin-right: 0;
                        }
                    }

                    .dkg-cumm-log-ul {
                        margin-bottom: 0;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        width: 100%;

                        li {
                            width: 100%;
                            max-width: 20%;

                            a {
                                display: inline-block;
                                width: 100%;
                                background-color: #3c97b6;
                                color: #fff;
                                text-align: center;
                                font-weight: 400;
                                letter-spacing: 0.3px;
                                font-size: 14px;
                                font-family: Verdana, sans-serif;
                                transition: all 0.5s;
                                height: 44px;
                                line-height: 44px;
                                white-space: nowrap;

                                &.active {
                                    background-color: #eaeaea;
                                    color: #333;
                                }
                            }
                        }
                    }

                    .dkg-cumm-log-maintabCon {
                        .dkg-cumm-log-mainRow {
                            margin: 0;

                            .dkg-cumm-log-navmainCol {
                                padding: 0;

                                .dkg-cumm-log-navpills {
                                    .dkg-cumm-log-navitem {
                                        width: 100%;
                                        max-width: 20%;

                                        .dkg-cumm-log-navlink {
                                            background-color: #3c97b6;
                                            color: #fff;
                                            text-align: center;
                                            width: 100%;
                                            font-size: 14px;
                                            padding: 14px 8px;
                                            border-right: none;
                                            border-bottom: none;
                                            border-radius: 0;

                                            &.active {
                                                background-color: #eaeaea;
                                                color: #333;
                                                border-right: #ddd;
                                            }
                                        }
                                    }
                                }
                            }

                            .dkg-cumm-log-tabcontantentCol {
                                padding: 0;
                            }
                        }
                    }
                }

                .dk-notification-panel {
                    li.dk-emailNotification-box {
                        background: #234158;

                        .dk-notificationLeft-panel {
                            .dk-profile {
                                border-right: 1px solid #1d5989;
                            }
                        }

                        .dk-dateTime-cols {
                            border-left: 1px solid #1d5989;

                            span {
                                border-bottom: 1px solid #1d5989;
                            }
                        }
                    }

                    li {
                        background: #b55355;
                        color: #ddd;
                        border-radius: 3px;
                        list-style-type: none;
                        padding: 0;
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-left: 4px solid #2e97b6;
                        cursor: pointer;

                        &:hover {
                            background: #144b76;
                            transition: all 0.5s;
                        }

                        .dk-notificationLeft-panel {
                            width: 80%;
                            padding: 0 15px;
                            padding-left: 10px;
                            display: flex;
                            align-items: center;

                            .dk-profile {
                                display: flex;
                                height: 70px;
                                margin-right: 10px;
                                border-right: 1px solid #ddd;
                                justify-content: center;
                                align-items: center;
                                padding-right: 10px;

                                img {
                                    display: inline-block;
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 50%;
                                }
                            }
                        }

                        .dk-title {
                            font-size: 14px;
                            font-weight: 600;
                            display: flex;
                            align-items: center;

                            small {
                                font-size: 11px;

                                svg {
                                    position: relative;
                                    bottom: 1px;
                                }
                            }
                        }

                        .dk-dateTime-cols {
                            font-size: 12px;
                            flex-wrap: wrap;
                            border-left: 1px solid #ddd;
                            padding-left: 0;
                            height: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            max-width: 120px;

                            span {
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #ddd;
                                color: #ddd;
                                padding: 7px 0;
                                width: 100%;
                                justify-content: center;

                                &:last-child {
                                    border-bottom: none;
                                }

                                svg {
                                    display: inline-block;
                                    margin-right: 2px;
                                    position: relative;
                                    top: 0;
                                }
                            }
                        }

                        p {
                            display: inline-block;
                            margin-top: 5px;
                            margin-bottom: 8px;
                            font-size: 14px;
                            color: #ddd;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 80%;
                        }
                    }
                }

                .dk-taskprocess-cardCon {

                    // height: calc(100vh - 205px);
                    // overflow-y: auto;
                    &:first-child {
                        .dk-taskprocess-card {
                            margin-top: 0;
                        }
                    }

                    .dk-taskprocess-card {
                        background-color: #fff;
                        border-radius: 5px;
                        padding: 0;
                        margin: 12px 15px;

                        .dk-taskprocess-card-body {
                            display: flex;
                            justify-content: flex-start;

                            .dk-user-imgCon {
                                width: 90px;
                                border: 1px solid #ddd;
                                padding: 0.5rem;
                                text-align: center;
                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;

                                img {
                                    width: 45px;
                                    height: 45px;
                                    border-radius: 50%;
                                    border: 2px solid #ccc;
                                }

                                .dk-announce-clock {
                                    // font-weight: 600;
                                    font-size: 12px;
                                    white-space: nowrap;
                                    position: relative;
                                    top: 2px;
                                }
                            }

                            .dk-inprogrestextCon {
                                display: flex;
                                align-items: center;
                                border: 1px solid #ddd;
                                border-left: none;
                                padding: 0.5rem;
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;

                                .dk-recruiterName {
                                    margin-bottom: 0.35rem;
                                    font-size: 16px;
                                    margin-top: 7px;
                                }

                                .dk-taskdetails {
                                    font-size: 14px;
                                    text-align: justify;
                                }
                            }

                            .dk-counter-commentCon {
                                display: flex;
                                justify-content: flex-start;
                                margin-top: 7px;

                                .dk-counterCon {
                                    padding-right: 10px;
                                }

                                .dk-commentCon {
                                    display: flex;

                                    i {
                                        margin-top: 3px;
                                    }

                                    .dk-commentNumber {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 22px;
                                        height: 22px;
                                        border-radius: 50%;
                                        font-size: 13px;
                                        background-color: #ccc;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-notificationsModal.fade .modal-dialog {
    right: -620px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-notificationsModal.show .modal-dialog {
    right: 0;
}

.modal.dk-notificationsModal {
    z-index: 9999 !important;
}