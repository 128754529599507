.page-margin-top {
    margin-top: 160px !important;
}

.pageSummaryDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.dk-candinatePipeMain.page-margin-top.dkg-pipeline-mainCon {
    margin-top: 93px !important;

    &.dkg-pipelines-mainpageCon {
        margin-top: 160px !important;

        &.dkg-dt-pageCon-14 ,
        &.dkg-fulldatabase-tableCon{
            margin-top: 125px !important;
        }
    }

    &.dkg-pipelines-main-tableCon {
        margin-top: 0 !important;
    }

    .dk-pipelinePageHead {
        .dk-allpipelinetabCon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 0.85rem;

            .dk-pipelineCetre {
                .dk-pipelineTabsCon {
                    width: 100%;

                    ul {

                        list-style: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        flex-wrap: nowrap;
                        margin-bottom: 0;
                        margin-top: 4px;

                        li {
                            min-width: 11.11%;
                            // max-width: 11.11%;
                            margin: 0 5px auto;
                            background: #fff;
                            border: 1px solid #ddd;
                            text-align: center;
                            border-radius: 35px;

                            a {
                                font-size: 13px !important;
                                padding: 10px;
                                white-space: nowrap;
                                line-height: 29px;
                                color: #4d4a4a;
                                font-size: 0.82rem;

                                span {
                                    padding-left: 5px;
                                }
                            }
                        }

                        li.newCVtiveTab,
                        li.newCVtiveTab:hover {
                            background-color: #5c737d;
                            border: 1px solid #5c737d;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }

                        li.toprocessTab,
                        li.toprocessTab:hover {
                            background-color: #796698;
                            border: 1px solid #796698;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }

                        li.screeningTab,
                        li.screeningTab:hover {
                            background-color: #55496b;
                            border: 1px solid #55496b;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }

                        li.recruiterTab,
                        li.recruiterTab:hover {
                            background-color: #2d9b99;
                            border: 1px solid #2d9b99;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }

                        li.account-managerTab,
                        li.account-managerTab:hover {
                            background-color: #286967;
                            border: 1px solid #286967;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }

                        li.offerTab,
                        li.offerTab:hover {
                            background-color: #629bd3;
                            border: 1px solid #629bd3;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }

                        li.escalatedTab,
                        li.escalatedTab:hover {
                            background-color: #670004;
                            border: 1px solid #670004;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }

                        li.onholdTab,
                        li.onholdTab:hover {
                            background-color: #7f5347;
                            border: 1px solid #7f5347;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }

                        li.pipelineclosedTab,
                        li.pipelineclosedTab:hover {
                            background-color: #e2445c;
                            border: 1px solid #e2445c;

                            &.active {
                                border: 2px solid #FFA500;
                            }

                            a {
                                color: #fff;
                            }
                        }
                    }
                }

            }
        }

        .dk-pipelineLeft {
            display: flex;
            align-items: center;

            .dk-mailshotsSearch {
                .input-group {
                    width: 100%;
                    max-width: 145px;
                    min-width: 145px;

                    input {
                        padding: 0.5rem 0.55rem;
                        background-color: #fff !important;
                        margin: 0;
                        box-shadow: none;
                        border: 1px solid #ddd;
                        line-height: 1.3rem;
                        height: 35px;
                        border-radius: 0;
                        text-align: left;

                        &:focus::placeholder {
                            color: transparent;
                        }
                    }

                    .input-group-append {
                        margin-left: -1px;

                        button {
                            padding: 0.5rem 0.55rem;
                            background-color: #fff !important;
                            margin: 0;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            line-height: 1.3rem;
                            height: 35px;
                            border-radius: 0;

                            &:hover {
                                background-color: #234158 !important;

                                i {
                                    color: #fff;
                                }
                            }

                            i {
                                color: #6c757d;
                            }
                        }
                    }
                }
            }

            button.dk-cricleBtn,
            .dk-checkduppopup,
            .dk-newCVRightFilter {
                button.dk-reoderBtn {
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: #fff !important;
                    color: #333;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    outline: none;
                    box-shadow: none;
                    cursor: pointer;

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                    }
                }

                i.fa.fa-filter {
                    font-size: 12px;
                    margin-top: 3px;
                }

                i {
                    margin-top: 3px;
                }
            }

            button.dk-cricleBtn,
            .dk-checkduppopup {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #eaeaea !important;
                color: #333;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;

                button.dk-reoderBtn {
                    background-color: transparent;
                    border: none;

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                    }
                }

                i.fa.fa-filter {
                    font-size: 12px;
                    margin-top: 3px;
                }

                i {
                    margin-top: 3px;
                }
            }

            button.dk-reoderBtn,
            .dkg-left-arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #fff !important;
                color: #6c757d;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;

                &:hover {
                    outline: 0;
                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                }

                i {
                    margin-top: 3px;
                }

                i.fa.fa-filter {
                    font-size: 12px;
                    margin-top: 3px;
                }

                &.dk-pipeline-manualparse {
                    cursor: pointer;
                    position: relative;

                    input[type=file] {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 9;
                        cursor: pointer;
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }

        .dk-filterTagsCon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .dk-pipeline-pagetitle {
                margin-top: 7px;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
            }

            .dk-TitleCon {
                display: flex;
                align-items: center;

                .dk-TitleTab {
                    a {
                        color: #333;
                        padding: 0 0 10px;
                        font-size: 16px;
                        font-weight: 600;
                        margin-right: 25px;
                        border: none;
                    }

                    &:first-child {
                        position: relative;

                        &::after {
                            content: "/";
                            position: absolute;
                            right: 6px;
                            top: -3px;
                            color: #333;
                            font-size: 19px;
                            font-weight: 600;
                        }
                    }

                    &.active {
                        a {
                            background: 0 0;
                            border: none;
                            color: #007bff;
                            position: relative;

                            &::after {
                                content: "";
                                width: 100%;
                                height: 2px;
                                background: #2e97b6;
                                position: absolute;
                                left: 0;
                                bottom: 4px;
                                display: none;
                            }
                        }
                    }
                }
            }

            .dk-filterbtnGrp {
                margin-top: 2px;

                .dk-filterbtntext {
                    background: #366995;
                    font-size: 12px !important;
                    color: #fff;
                    padding: 5px 7px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                    line-height: 10px;
                    text-transform: inherit;

                    &.btn-primary {
                        background: #366995 !important;
                    }
                }

                .dk-filterbtnicon {
                    background: #234158;
                    font-size: 11px;
                    padding: 5px 7px;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;

                    &.btn-primary {
                        background-color: #234158 !important;
                    }
                }
            }
        }

        .dk-pipelineCetre {
            .dk-pipelineTabsCon {
                width: 100%;

                ul {

                    list-style: none;
                    display: flex;
                    width: 100%;
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-bottom: 0;
                    margin-top: 4px;

                    li {
                        min-width: 15.66%;
                        // max-width: 15.80%;
                        margin: 0 5px auto;
                        background: #eaeaea;
                        border: 1px solid #ddd;
                        text-align: center;
                        border-radius: 35px;

                        a {
                            font-size: 13px;
                            padding: 10px;
                            white-space: nowrap;
                            line-height: 30px;
                            color: #4d4a4a;
                            font-size: 0.82rem;

                            span {
                                padding-left: 5px;
                            }
                        }
                    }

                    li.newCVtiveTab.active,
                    li.newCVtiveTab:hover {
                        background-color: #5c737d;
                        border: 1px solid #5c737d;

                        a {
                            color: #fff;
                        }
                    }

                    li.toprocessTab.active,
                    li.toprocessTab:hover {
                        background-color: #56496C;
                        border: 1px solid #56496C;

                        a {
                            color: #fff;
                        }
                    }

                    li.recruiterTab.active,
                    li.recruiterTab:hover {
                        background-color: #2d9b99;
                        border: 1px solid #2d9b99;

                        a {
                            color: #fff;
                        }
                    }

                    li.account-managerTab.active,
                    li.account-managerTab:hover {
                        background-color: #286967;
                        border: 1px solid #286967;

                        a {
                            color: #fff;
                        }
                    }

                    li.offerTab.active,
                    li.offerTab:hover {
                        background-color: #629bd3;
                        border: 1px solid #629bd3;

                        a {
                            color: #fff;
                        }
                    }

                    li.escalatedTab.active,
                    li.escalatedTab:hover {
                        background-color: #670004;
                        border: 1px solid #670004;

                        a {
                            color: #fff;
                        }
                    }
                }
            }

        }

        .dk-pipelineright {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .dk-btnCicleCon {
                display: flex;

                button.dk-cricleBtn,
                .dk-btnCicle,
                button.dk-reoderBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: #fff !important;
                    color: #6c757d;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    outline: none;
                    box-shadow: none;

                    a {
                        // display: flex;
                        // justify-content: center;
                        // align-items: center;
                        // width: 35px;
                        // height: 35px;
                        // line-height: 35px;
                        // background: #fff !important;
                        // color: #6c757d !important;
                        // border: 1px solid #ddd;
                        // border-radius: 50%;
                        // outline: none;
                        // box-shadow: none;

                        i {
                            color: #6c757d !important;
                            font-size: 14px;
                            // margin-left: 5px;
                        }
                    }

                    &:hover {
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                    }

                    i.fa.fa-filter {
                        font-size: 12px;
                        margin-top: 3px;
                    }

                    &.dk-pipeline-manualparse {
                        cursor: pointer;
                        position: relative;

                        input[type=file] {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            z-index: 9;
                            cursor: pointer;
                            width: 35px;
                            height: 35px;
                        }
                    }
                }

                .dk-pipe-prfind-Con {
                    position: relative;
                }

                .dk-flagfilterIcon {
                    .dk-lhs-FlagFilter {
                        .dropdown {
                            button {
                                background-color: #fff;
                                margin: 0;
                                padding: 0;
                                width: 35px;
                                height: 35px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: none;
                                border-radius: 50%;
                                color: #6c757d;

                                &:hover {
                                    outline: 0;
                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                }

                                &::after {
                                    display: none;
                                }

                                i {
                                    font-size: 14px;
                                }
                            }

                            .dk-lhsflagnoDropMenu.dropdown-menu {
                                position: absolute !important;
                                background-color: #234158;
                                transform: inherit !important;
                                right: 45px !important;
                                top: -18px !important;
                                left: inherit !important;
                                margin: 0 !important;
                                padding: 0 !important;
                                border: 1px solid #808080;

                                &:after {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-color: transparent #234158 transparent transparent;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    line-height: 0;
                                    _border-color: #000 #234158 #000 #000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    position: absolute;
                                    right: -10px;
                                    top: 26px;
                                    transform: rotate(59deg);
                                }

                                .d-flex {
                                    padding: 0;

                                    .dropdown-item {
                                        padding: 0.25rem 0.5rem;
                                        border-right: 1px solid #808080;

                                        &:last-child {
                                            border-right: none;
                                        }

                                        i {
                                            display: block;
                                            width: 100%;
                                            text-align: center;
                                            font-size: 13px;
                                        }

                                        .recNoCount {
                                            color: #fff;
                                            display: block;
                                            text-align: center;
                                            width: 100%;
                                            font-size: 12px;
                                        }

                                        &:hover {
                                            .recNoCount {
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .dkg-candfilterIconCon {
                    position: relative;

                    .dkg-candfilter-dropdown {
                        .dkg-candfilter-dropdown-toggle {
                            margin: 0;
                            padding: 0;
                            box-shadow: none;
                            background-color: #fff !important;
                            border: 1px solid #ddd;
                            text-transform: capitalize;
                            font-size: 13px;
                            color: #6c757d;
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                outline: 0;
                                box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                            }

                            &::after {
                                display: none;
                            }

                            i {
                                font-size: 14px;
                            }
                        }

                        .dkg-candfilter-dropdown-menu {
                            position: absolute !important;
                            background-color: #234158;
                            transform: inherit !important;
                            right: 50px !important;
                            top: -18px !important;
                            left: inherit !important;
                            margin: 0 !important;
                            padding: 0 !important;
                            display: flex;
                            align-items: inherit;
                            border: 1px solid #808080;

                            &:after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-color: transparent #234158 transparent transparent;
                                border-style: solid;
                                border-width: 8.5px 12px 8.5px 0;
                                line-height: 0;
                                _border-color: #000 #234158 #000 #000;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                position: absolute;
                                right: -10px;
                                top: 26px;
                                transform: rotate(59deg);
                            }

                            .dkg-candfilter-dropdown-item {
                                padding: 0.25rem 0.5rem;
                                width: 100%;
                                text-align: center;
                                color: #fff;
                                border-right: 1px solid #808080;

                                &:last-child {
                                    border-right: none;
                                }

                                &:hover {
                                    background-color: #f2f2f2 !important;

                                    .proflieCount {
                                        color: #333 !important;

                                    }
                                }

                                svg {
                                    width: 13px;
                                    height: 13px;
                                }

                                .proflieCount {
                                    color: #fff;
                                    display: block;
                                    font-size: 12px;
                                    // padding: 4px 0 0;
                                    text-align: center;
                                    width: 100%;
                                }

                                &:hover {
                                    background-color: #234158;

                                    .proflieCount {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }

                .pipeline-cafileterCon {
                    position: relative;

                    .dropdownFilter {
                        .dropdown-toggle {
                            margin: 0;
                            padding: 0.5rem 0.45rem;
                            box-shadow: none;
                            background-color: #3C3939;
                            // background-color: #3C3939 !important;
                            text-transform: capitalize;
                            min-width: 145px;
                            font-size: 13px;
                            //color: #fff !important;

                            &::after {
                                display: block;
                                position: absolute !important;
                                color: #6c757d;
                                position: absolute;
                                right: 10px;
                                top: 16px;
                                font-size: 1.2rem;
                            }
                        }

                        .dropdown-menu {
                            padding: 0;
                            border-radius: 0;
                            max-height: 480px;
                            overflow-y: auto;
                            position: absolute !important;
                            right: 5px !important;

                            .dropdown-item {
                                padding: 8px 10px;
                                font-size: 13px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .dk-casecounter {
                                    display: inline-block;
                                    background: #3a506b;
                                    color: #fff;
                                    padding: 2px 5px;
                                    min-width: 30px;
                                    text-align: center;
                                    font-size: 11px;
                                }

                                &:hover {
                                    background-color: #eaeaea !important;
                                    color: #333 !important;

                                }
                            }
                        }

                        // .crossUserFilter {
                        //     position: absolute;
                        //     color: #000;
                        //     top: -5px;
                        //     right: -1px;
                        //     font-size: 15px !important;
                        //     cursor: pointer;
                        // }
                    }
                }

                .dk-trickfilterIcon {

                    .dkg-top-right-pipe-trickFilter {
                        .dk-pipeline-idDropdownMain {
                            .dropdown-toggle {
                                margin: 0;
                                padding: 0;
                                box-shadow: none;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 35px;
                                height: 35px;
                                line-height: 35px;
                                background: #fff !important;
                                color: #6c757d;
                                border: 1px solid #ddd;
                                border-radius: 50%;

                                &:hover {
                                    outline: 0;
                                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                                }

                                svg {
                                    width: 16px;
                                    height: 16px;
                                }

                                &::after {
                                    display: none;
                                }
                            }

                            .dk-pipe-lhstricknoDropMenu.dropdown-menu {
                                position: absolute !important;
                                background-color: #234158;
                                transform: inherit !important;
                                right: 50px !important;
                                top: -18px !important;
                                left: inherit !important;
                                margin: 0 !important;
                                padding: 0 !important;
                                display: flex;
                                align-items: inherit;
                                border: 1px solid #808080;

                                .dropdown-item {
                                    padding: 0.25rem 0.5rem;
                                    width: 100%;
                                    text-align: center;
                                    color: #fff;
                                    border-right: 1px solid #808080;

                                    &:last-child {
                                        border-right: none;
                                    }

                                    &:hover {
                                        color: #333;
                                    }

                                    .dk-idDropdown-cols {
                                        span {
                                            svg {
                                                width: 13px;
                                                height: 13px;
                                            }
                                        }

                                        span.dkg-tricker {
                                            display: block;
                                            font-size: 12px;
                                        }
                                    }

                                    &:after {
                                        content: "";
                                        width: 0;
                                        height: 0;
                                        border-color: transparent #234158 transparent transparent;
                                        border-style: solid;
                                        border-width: 8.5px 12px 8.5px 0;
                                        line-height: 0;
                                        _border-color: #000 #234158 #000 #000;
                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        position: absolute;
                                        right: -10px;
                                        top: 26px;
                                        transform: rotate(59deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dk-qulaifyRightFilter {

                .ui.selection.dropdown.dk-overdues-task-slect,
                .ui.selection.dropdown.dk-overdues-task-slect:hover {
                    border-radius: 0;
                    min-width: 135px;
                    background-color: #eaeaea !important;
                    border: 1px solid #ddd;

                    .dropdown.icon {
                        top: 0.8581em;
                        color: #605a5a;
                    }
                }

                .ui.selection.dropdown .menu {
                    border-radius: 0;
                    min-width: 200px;
                    min-height: 16rem;
                    position: absolute;
                    right: 0;
                    left: inherit;
                    overflow: inherit;

                    .item {
                        font-size: 0.9125rem;
                        min-height: 33px;
                        line-height: 19px;

                        span.description {
                            border-radius: 0;
                            font-size: 0.8125rem;
                            background: #3a506b;
                        }
                    }

                    .active.item {
                        font-weight: normal;
                    }

                    .item:hover {
                        background-color: #eaeaea !important;
                        color: #333 !important;
                    }
                }

                .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
                .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
                    font-size: 14px;
                    color: #605a5a;
                }
            }
        }
    }

    .dk-idDropdownMain.dropdown {
        display: inline-block;
        margin-left: 0 !important;
        // position: relative;
        // top: 1px;

        .dropdown-toggle {
            margin: 0 !important;
            padding: 0;
            background: transparent;
            border: none;
            box-shadow: none;
            text-transform: inherit;
            font-size: 13px;
            width: 100%;
            min-width: 15px;
            max-width: 15px;

            span {
                img {
                    width: 100%;
                    max-width: 20px;
                }
            }

            svg {
                font-size: 15px;
            }

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            padding: 0;
            margin: 0 !important;
            transform: inherit !important;
            display: flex;
            min-width: 100px;
            left: 26px !important;
            top: -27px !important;
            border: 1px solid #808080;
            background-color: #234158 !important;

            &::before {
                border-color: transparent #234158 transparent transparent;
                _border-color: #000 #234158 #000 #000;
                border-style: solid;
                border-width: 6.5px 12px 6.5px 0;
                content: "";
                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                height: 0;
                left: -12px;
                line-height: 0px;
                position: absolute;
                top: 27px;
                width: 0;
            }

            a {
                border: none;
                font-size: 16px;
                padding: 4px 9px;
                text-align: center;
                width: 100%;
                border-right: 1px solid #808080;
                background-color: #234158 !important;

                .dkg-tricker {
                    color: #fff !important;
                }

                // width: 40px;
                &:hover {
                    background-color: #f2f2f2 !important;

                    span.dkg-tricker {
                        color: #333 !important;
                    }

                }

                &:last-child {
                    border-right: none;
                }

                img {
                    max-width: 15px;
                    width: 100%;
                    display: inline-block;
                    margin-left: 7px;
                    margin-top: -3px;
                }

                span.dkg-tricker {
                    display: block;
                    font-size: 12px;
                    color: #333;
                }
            }
        }
    }

    .pipe-newCVTableCon {
        margin-top: 6px;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh - 237px);
        padding-bottom: 0;
        position: relative;

        &.dkg-dt-tableCon234 {
            height: calc(100vh - 212px) !important;
        }

        .dkpipeline-newCVTable {
            margin-bottom: 0;

            thead tr th {
                background: #485569;
                color: #fff;
                font-size: 13px;
                text-align: center;
                white-space: nowrap;
                padding: 9px 7px;
                border: 1px solid #808080;
                border-bottom: none;
                vertical-align: middle;
                position: sticky;
                top: 0;
                // border-top: none;
                z-index: 9;

                &:nth-child(5) {
                    min-width: 120px;
                    max-width: 120px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:nth-child(6),
                &:nth-child(7) {
                    min-width: 210px;
                    max-width: 210px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:nth-child(09),
                &:nth-child(10) {
                    border: 1px solid #808080 !important;
                    border-top: none;
                }

                &:nth-child(19) {
                    min-width: 90px;
                }

                &:nth-child(20) {
                    min-width: 120px;
                }

                &:nth-child(21),
                &:nth-child(22),
                &:nth-child(23) {
                    min-width: 90px;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                &.dk-cursor-pointer {
                    cursor: pointer;
                }
            }

            tbody tr td {
                background: #fff;
                color: #4d4a4a;
                font-size: 13px;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
                padding: 0 7px !important;
                border: 1px solid #ddd;
                vertical-align: middle;
                text-transform: capitalize;
                position: relative;

                &.dkg-maindatatable-td {
                    padding: 0 !important;
                }

                .dkg-jobtitle-cols,
                .cursor-pointer {
                    &:hover {
                        color: #629bd3;
                    }
                }

                input {
                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }

                &:nth-child(5) {
                    min-width: 120px;
                    max-width: 120px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:nth-child(6),
                &:nth-child(7) {
                    min-width: 210px;
                    max-width: 210px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:nth-child(08) {
                    padding: 0 !important;
                    border: 1px solid #808080;
                }

                &:nth-child(09),
                &:nth-child(10),
                &:nth-child(11) {
                    border: 1px solid #808080;
                    padding: 0 !important;
                }

                &:nth-child(20) {
                    min-width: 90px;
                }

                &:nth-child(21) {
                    min-width: 120px;
                }

                .dk-useFilterCon {
                    cursor: pointer;

                    .dropdown {
                        .dk-pipleuserFilterMenu.dropdown-menu {
                            border: 1px solid #234158;
                            background: #234158;

                            &::after {
                                border-color: transparent #234158 transparent transparent;
                            }

                            .d-flex {
                                .dropdown-item {
                                    .tooltipMain {
                                        .filterImg {
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .pipleFilterCols {
                    display: flex;

                    .dk-chevron-rightIcon {
                        // margin-right: 8px;
                        width: 100%;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin-right: 2px !important;

                        i {
                            background: #297483;
                            padding: 5px;
                            text-align: center;
                            border-radius: 2px;
                            color: #fff;
                            margin-right: 2px;
                            cursor: pointer;
                        }
                    }

                    .dk-checkinputIcon {
                        margin-left: 0.8rem;
                        margin-right: 0.35rem;
                        position: relative;
                        &.dkg-usericon-cols{
                            margin-left: 0.3rem;
                        }
                        input[type="checkbox"] {
                            position: absolute;
                            top: 6px;
                            right: -4px;
                            cursor: pointer;
                            -webkit-appearance: none;
                            appearance: none;
                            background: #eaeaea;
                            border-radius: 1px;
                            box-sizing: border-box;
                            width: 14px;
                            height: 14px;
                            border-width: 0;
                            transition: all 0.3s linear;
                            border: solid #ddd 1px;
                            z-index: 0;
                        }

                        input[type="checkbox"]:checked {
                            background-color: #34976e !important;
                        }
                        .dkg-pipeline-usericon{
                            svg{
                                color: #d3d3d3;
                                margin-top: 5px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    .opencaledericon {
                        cursor: pointer;
                        margin-left: 0.5rem;
                        margin-right: 0;
                        margin-top: 3px;
                        display: none;

                        i {
                            color: #a9a8a8;
                        }
                    }

                    .dkg-add-remove-btnCon {
                        display: flex;
                        align-items: center;
                        position: relative;
                        top: 3px;

                        .dkg-plusicon {
                            border: none;
                            outline: 0;
                            font-size: 11px;
                            color: #3a9469;
                            background-color: transparent;

                            i {
                                font-size: 12px;
                            }
                        }

                        .dkg-minusicon {
                            border: none;
                            outline: 0;
                            font-size: 11px;
                            color: #d91010;
                            background-color: transparent;

                            i {
                                font-size: 12px;
                            }
                        }
                    }

                    // .openFlagOptions {
                    //     .dk-pipeFlagFilterCon {
                    //         .dropdown {
                    //             .dk-pipleuserFilterMenu.dropdown-menu {
                    //                 top: -30px !important;

                    //                 &::after {
                    //                     top: 27px !important;
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }

                    .openFlagOptions,
                    .openCricleOptions {
                        margin-left: 0.65rem;
                        cursor: pointer;
                        margin-top: 4px;
                        margin-right: 0.15rem;

                        i {
                            color: #297483;
                            font-size: 15px !important;
                        }

                        .dropdown {
                            .btn {
                                margin: 0;
                                padding: 0;
                                box-shadow: none;

                                &::after {
                                    display: none;
                                }
                            }

                            .dropdown-menu.show {
                                padding: 0;
                                left: 26px !important;
                                top: -15px !important;
                                transform: inherit !important;
                                width: 100%;
                                min-width: 100px;
                                background-color: #234158;
                                color: #fff;
                                border: 1px solid #808080;

                                a {
                                    padding: 4px 7px;
                                    color: #fff;
                                    border-right: 1px solid #808080;

                                    &:last-child {
                                        border-right: none;
                                    }

                                    .dkg-prof-ind-counter,
                                    .dk-flag-select-text {
                                        color: #fff;
                                    }

                                    &:hover {
                                        background-color: #f2f2f2 !important;

                                        .dkg-prof-ind-counter,
                                        .dk-flag-select-text {
                                            color: #333;
                                        }
                                    }
                                }

                                &::after {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-color: transparent #234158 transparent transparent;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    line-height: 0;
                                    _border-color: #000 #234158 #000 #000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    position: absolute;
                                    left: -12px;
                                    top: 15px !important;
                                }
                            }
                        }
                    }

                    .dk-checkDropdown {
                        margin-left: 0.65rem;
                        margin-right: 0.35rem;

                        .dk-idDropdownMain {
                            .dropdown-toggle {
                                svg {
                                    margin-top: 6px;
                                }
                            }
                        }
                    }
                }

                .dk-tableImgCricle {
                    img {
                        min-height: 22px;
                        border-radius: 50%;
                        height: 28px;
                        width: 28px;
                    }
                }

                .commentBoxCon {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .numCon {
                        width: 25px;
                        height: 25px;
                        background-color: #234158;
                        color: #fff;
                        border-radius: 50%;
                        line-height: 25px;
                        cursor: pointer;

                        &:hover {
                            background-color: #fff;
                            color: #333;
                        }
                    }

                    &.zeroComment {
                        .numCon.zeroCommentCount {
                            color: #eaeaea;
                            background-color: #eaeaea !important;

                            &:hover {
                                background-color: #234158 !important;
                                color: #fff;
                            }
                        }
                    }
                }

                input[type="text"] {
                    border: none;
                    background-color: #fff;
                    color: #000;
                    font-size: 12px;
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 0;

                    &:focus {
                        border: 1px solid #333;
                    }
                }

                .caseDiv {
                    height: 40px;
                    line-height: 40px;
                    cursor: pointer;
                    padding: 0 7px;
                }

                &.bt-0 {
                    border-top: none;
                    color: #fff;
                    border-bottom: 1px solid #ddd;
                }

                .dkg-statusDropCon-234 {
                    .dkg-statusDropdown {
                        position: relative;

                        .dkg-statusDropdown-toggle,
                        .dkg-statusDropdown-toggle:hover {
                            box-shadow: none;
                            background-color: transparent;
                            padding: 0 !important;
                            margin: 0;
                            white-space: nowrap;
                            font-size: 13px;
                            text-transform: capitalize;
                            color: #fff;
                            width: 100%;

                            .caseDiv {
                                height: 40px;
                                line-height: 40px !important;
                                cursor: pointer;
                                padding: 0 7px !important;
                                min-width: 80px;

                            }

                            &::after {
                                display: none;
                            }

                            .caseDiv {
                                min-height: 35px;
                                line-height: 35px;
                                padding: 0 10px;
                            }
                        }

                        .dkg-statusDropdownMenu {
                            border-radius: 0;
                            padding: 0;
                            transform: translate(-1px, 35px) !important;

                            // border: none;
                            .dkg-statusDropdownItem {
                                text-align: left;
                                font-size: 13px;
                                padding: 6px 10px;
                                border-bottom: 1px solid #aaa;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }

            tbody tr td:nth-child(06) {
                a {
                    text-align: left;
                    color: #4d4a4a;

                    &:hover {
                        color: #2c71b2;
                        cursor: pointer;
                    }
                }
            }

            // tbody tr td:nth-child(10), tbody tr td:nth-child(11), tbody tr td:nth-child(12){
            //     background-color:#5c737d;
            //     color: #fff;
            //     cursor: pointer;
            //     padding: 0;
            // }
            tbody tr:last-child td {
                border-bottom: 1px solid #808080;
            }

            tfoot {
                tr td {
                    background: #fff;
                    color: #4d4a4a;
                    font-size: 14px;
                    text-align: center;
                    white-space: nowrap;
                    padding: 9px 15px !important;
                    vertical-align: middle;
                    border: none;
                    z-index: 1;

                    // &:nth-child(21) {
                    //     border-left: 1px solid #ddd;
                    // }
                    &:nth-child(20),
                    &:nth-child(21),
                    &:nth-child(22),
                    &:nth-child(23),
                    &:nth-child(24) {
                        border-right: 1px solid #ddd;
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-track {
            background: #F1F1F1;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ddd;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 40%;
        max-width: 40%;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 40%;
        max-width: 40%;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .col-md-3.dk-pipelineLeftCols,
    .col-md-3.dk-pipelineRightCols {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .col-md-6.dk-pipelineCenterCols {
        flex: 0 0 40%;
        max-width: 40%;
    }
}

.dk-pipepaginationCon {
    flex: 0 0 100%;
    max-width: 100%;
    background-color: #44546a;
    position: fixed;
    width: 100%;
    bottom: 0;

    ul {
        list-style: none;
        padding: 6px 15px;
        margin-bottom: 0;

        li {
            color: #fff;
            z-index: 999;
            font-size: 13px;
        }
    }
}

.tableFilterRoundButton {
    box-shadow: none;
    padding: 0 !important;
    margin: 0;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    line-height: 30px;
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: .81rem;

    i {
        font-size: 1rem;
    }

    img {
        width: 100%;
        border-radius: 50%;
    }
}

.dk-pipeFlagFilterCon {
    .dropdown {
        button.dk-pipeflagfliterbtn.dropdown-toggle.btn {
            box-shadow: none;
            background-color: transparent;
            padding: 0 !important;
            margin: 0;

            i {
                font-size: 14px;
            }
        }

        button.dk-pipeflagfliterbtn.dropdown-toggle::after {
            display: none;
        }

        .dk-pipleFlagFilterMenu.dropdown-menu {
            padding: 5px;
            left: 7px !important;
            top: -18px !important;
            transform: translate(22px, 7px) !important;
            -webkit-transform: translate(22px, 7px) !important;
            background-color: #fff;

            a.dropdown-item {
                padding: 0;
                display: inline-block;
                margin: 0 3px;
                text-align: center;
            }
        }

        .dk-pipleuserFilterMenu.dropdown-menu.show {
            min-width: fit-content !important;

            &::after {
                top: 13px !important;
            }

            a.dropdown-item {
                display: inline-block;
                text-align: center;
                padding: 4px 10px !important;
                border-right: 1px solid #ddd;

                &:hover {
                    background-color: #234158 !important;

                    span,
                    .dk-flag-select-text {
                        color: #fff;
                    }

                }

                span {
                    display: block;
                    font-size: 12px;
                    color: #333;
                }

                .dk-flag-select-text {
                    display: block;
                    font-size: 12px;
                    color: #333;
                }
            }
        }

        .dk-pipleFlagFilterMenu.dropdown-menu:after {
            content: "";
            width: 0;
            height: 0;
            border-color: transparent #234158 transparent transparent;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            line-height: 0;
            _border-color: #000 #234158 #000 #000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            position: absolute;
            left: -12px;
            top: 8px;
        }
    }
}

.dk-pipe-profileind-Dropdown {
    .dk-pipe-profileind-Dropdown-toggle {
        box-shadow: none;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #ddd;
        margin: 0;
        padding: 0;

        &::after {
            display: none;
        }

        i {
            font-size: 13px;
        }
    }

    .dk-pipe-profileind-DropdownMenu {
        padding: 0;
        transition: inherit !important;
        position: absolute !important;
        left: 45px !important;
        top: -55px !important;
        background-color: #234158 !important;

        &:after {
            border-color: transparent #234158 transparent transparent;
            _border-color: #000 #234158 #000 #000;
            border-style: solid;
            border-width: 8.5px 12px 8.5px 0;
            content: "";
            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
            height: 0;
            left: -12px;
            line-height: 0;
            position: absolute;
            top: 24px;
            width: 0;
        }

        .d-flex {
            .dk-profileind-DropdownItem {
                display: inline-block;
                margin: 0 3px;
                padding: 8px 5px !important;
                width: 35px;

                i {
                    width: 100%;
                    text-align: center;
                    font-size: 1.23rem;
                }

                .proflieCount {
                    color: #333;
                    display: block;
                    font-size: 12px;
                    padding: 4px 0 0;
                    text-align: center;
                    width: 100%;
                    font-size: 1rem;
                }
            }
        }
    }
}

.pagination {
    // background-color: #485569 !important;
    background-color: transparent !important;
    border-radius: 0 !important;

    .dk-resPagination {
        width: 100%;

        .dk-resLeftPag {
            .dk-texCounter {
                color: #fff !important;
            }
        }

        .pagination-btns {
            nav {
                ul {
                    margin-top: 0 !important;

                    li {
                        min-width: auto !important;
                        width: auto !important;
                        margin: 0 !important;
                        border: 0px !important;
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }
}

.dk-resPaginationCon {
    .dk-resPagination {
        .dk-resLeftPag {
            .dk-texCounter {
                color: #fff !important;
            }
        }
    }
}