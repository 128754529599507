.dk-duplicateMain{
    padding: 25px 0 0;
    margin-top:160px;
    height: calc(100vh - 185px);
    overflow-y: hidden;
    h3.title{
        text-align: center;
        color: #333;
        text-transform: uppercase;
        font-size: 20px;
        font-weight:600;
        margin-bottom:20px;
    }
    .dk-hadForm{
        width: 100%;
        max-width: 800px;
        margin: auto;
        form{
            .dk-duplicate-inputCon{
                label{
                    width: 100%;
                    text-align: center;
                }
                input{
                    background: #eaeaea;
                    border: 1px solid #ddd;
                    margin: 0 5px;
                    border-radius: 3px;
                    height: 38px;
                    font-size: 14px;
                    padding: 0 10px;
                    font-weight: normal;
                }
            }
            .dkg-srh-dupli-btnCon{
                display: flex;
            }
            .makeStyles-root-5{
                align-items: inherit;
                margin-right: 0.5rem;
                
            }
            .btn.dk-srchBtn{
                margin: 0;
            }
            
            button.btn.dk-srchBtn.MuiButton-containedPrimary{
                display: flex;
                background: #3c97b6;
                border: none;
                height: 38px;
                line-height: 36px;
                color: #fff;
                width: 100%;
                max-width: 105px;
                border-radius: 3px;
                outline: none;
                margin-left: 15px;
                line-height: 11px;
                font-weight: 600;
                margin-top: 28px;
                &:hover{
                    background: #2e5a7c;
                    transition: all 0.5s;
                }
            }
              
            button.dk-dupli-clearbtn{
                box-shadow: none;
                margin: 0;
                min-width: 90px;
                display: inline-block;
                background: transparent;
                padding: 0;
                margin-left: 3px !important;
                margin-top: 29px;
                &:hover{
                    border: none;
                    height: 38px;
                    color: #fff;
                    border-radius: 3px;
                    outline: none;
                    line-height: 11px;
                    font-weight: 600;
                    background-color: #2e5a7c;
                    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.20), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                }
            }
            a{
                display: inline-block;
                background: transparent;
                border: none;
                height: 38px;
                line-height: 36px;
                color: #fff;
                width: 100%;
                max-width: 75px;
                border-radius: 3px;
                outline: none;
                margin-left: 10px;
                text-align: center;
                &:hover{
                    background: #fff;
                    color: #333;
                    transition: all 0.5s;
                }
            }
        }
    }
    .dk-duplicate-filterpageCon{
        padding: 15px;
        width: 100%;
        max-width: 1200px;
        margin: auto;
        .dk-duplicateTableCon{
            overflow-y: auto;
            overflow-x: auto;
            height: calc(100vh - 280px);
            padding-bottom: 0;
            position: relative;
            table.dk-duplicateTable{
                border:none !important;
                margin-bottom: 0;
                tr{
                    th{
                        text-align: center;
                        vertical-align: middle;
                        color: #fff;
                        background: #2e5a7c;
                        font-weight: 500;
                        border-color: #60839e;
                        border-right-color: #4483af;
                        border-bottom-color: #4483af;
                        border-top:1px solid #4483af;
                        border-left-color: #4483af;
                        border-bottom: none;
                        padding-top:10px;
                        padding-bottom:10px;
                        vertical-align: middle;
                        position: sticky;
                        top: 0;
                        z-index: 9;
                    }
                    td{
                        background: #eaeaea;
                        border: 1px solid #ddd;
                        color: #333;
                        text-align: center;
                        vertical-align: middle;
                        font-size: 12px;
                        font-weight: normal;
                        padding: 5px 15px;
                        .dk-recImg{
                            img{
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                            }
                        }
                        &.statusClass{
                            div{
                                height: 41px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-duplicateMain{
        margin-top: 148px;
        .title{
            background-color: #359ab8;
            color: #fff !important;
            padding: 9px 10px;
            font-size: 15px !important;
        }
        .dk-hadForm{
            div{
                .dkg-mo-duplicateFromCon{
                    display: block  !important;
                    .dk-duplicate-inputCon{
                        color: #333;
                        &.no-lastinputCon{
                            margin-right: 0.5rem !important;
                        }
                    }
                    .dkg-srh-dupli-btnCon {
                        display: flex;
                        justify-content: center;
                    }
                }
                .no-duplicate{
                    width: 100%;
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
        .dk-duplicate-filterpageCon{
            .dk-duplicateTableCon{
                .dk-duplicateTable{
                    thead{
                        tr th {
                            white-space: nowrap;
                        }
                    }
                    tbody{
                        tr td{
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}
