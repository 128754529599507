.dk-caseDrpDwn{
    .dropdown{
        .caseStstoggle.dropdown-toggle{
            width: 100%;
            box-shadow: none;
            color: #fff;
            font-size: 12px;
            text-align: center;
            background-color: #FFF;
            border: 1px solid #333;
            height: 35px;
            line-height: 9px;
            border-radius: 0;
            text-transform: capitalize;
            margin: 0;
            &::after{
                display: inline-block;
                position: absolute;
                right: 10px;
                top: 15px;
                font-size: 1.05rem;
                color: #d5d5d5;
            }
        }
        
        .caseStatusDropMenu{
            display: block;
            width: 100%;
            border-radius: 0;
            padding: 0;
            border-color: currentcolor #96c8da #96c8da;
            border-top: #96c8da;
            box-shadow: 0 2px 3px 0 rgba(0,0 ,0.38,0.15);
            .dropdown-item{
                border-radius: 0;
                border: none;
                border-bottom: 1px solid #aaa;
                text-align: left;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 10px;
                &:last-child{
                    border-bottom: none;
                }
            }

        }
    }
}