.dkg-crm-main-pageCon{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 47px;
    height: calc(100vh - 47px);
    background-color: #fff;
    .dkg-crm-main-contentCon{
        width: 100%;
        max-width: 1050px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;   
        .dkg-crm-card-container {
            display: flex;
            justify-content: center;
            width: 100%;
            .dkg-crm-main-card {
                width: 100%;
                height: 250px;
                background-color: #ffffff;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                font-family: Arial, sans-serif;
                font-size: 1.25rem;
                color: #fff;
                margin: 15px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .dkg-usertype-image-crop {
                    position: relative;
                    background-color: #E6EBEE;
                    width: 100px;
                    height: 100px;
                    overflow: hidden;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 1px 1px 5px #ddd;
                    .dkg-usertype-image{
                        height: 70px;
                        width: 70px;
                        overflow: hidden;
                        background-size: cover;
                        // width: 120px;
                        // border-radius: 50%;
                        // display: block;
                        // margin-left: auto;
                        // margin-right: auto;
                        // margin-bottom: 5px;
                    }
                }
                .dkg-acc-type-tabcon{
                    margin-top: 20px;
                    .dkg-acc-type-tabname{
                        position: relative;
                        display: inline-block;
                        margin: 15px;
                        padding: 9px 20px;
                        text-align: center;
                        font-size: 16px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        text-decoration: none;
                        color: #eaeaea;
                        border: 1px solid #7a7a7a !important;
                        cursor: pointer;
                        transition: ease-out 0.5s;
                        -webkit-transition: ease-out 0.5s;
                        -moz-transition: ease-out 0.5s;
                        background-color: transparent !important;
                        box-shadow: none;
                        border-radius: 5px;
                        &:hover{
                            border: 1px solid #884c5e !important;
                            color: #fff;
                        }
                    }
                }
            }
            &:nth-child(01){
                .dkg-crm-main-card{
                    &:nth-child(01){
                        background-color: #884c5e;
                        .dkg-acc-type-tabcon{
                            margin-top: 20px;
                            .dkg-acc-type-tabname{
                                border: 1px solid #c8b3b3 !important;
                                &:hover{
                                    border: 1px solid #4d3737 !important;
                                }
                            }
                        }
                    }
                    &:nth-child(02){
                        background-color: #2b8faf;
                        .dkg-acc-type-tabcon{
                            margin-top: 20px;
                            .dkg-acc-type-tabname{
                                border: 1px solid #c8b3b3 !important;
                                &:hover{
                                    border: 1px solid #884c5e !important;
                                }
                            }
                        }
                    }
                    &:nth-child(03){
                        background-color: #01796f;
                        .dkg-acc-type-tabcon{
                            margin-top: 20px;
                            .dkg-acc-type-tabname{
                                border: 1px solid #c8b3b3 !important;
                                &:hover{
                                    border: 1px solid #884c5e !important;
                                }
                            }
                        }
                    }
                }
            }
            &:nth-child(02){
                .dkg-crm-main-card{
                    
                    &:nth-child(01){
                        background-color: #5a4c72;
                    }
                    &:nth-child(02){
                        background-color: #144773;
                        .dkg-acc-type-tabcon{
                            margin-top: 20px;
                            .dkg-acc-type-tabname{
                                border: 1px solid #c8b3b3 !important;
                                &:hover{
                                    border: 1px solid #884c5e !important;
                                }
                            }
                        }
                    }
                    &:nth-child(03){
                        background-color: #5c737d;
                        .dkg-usertype-image-crop{
                            .dkg-usertype-image{
                                position: relative;
                                top: 7px;
                            }
                        }
                        .dkg-acc-type-tabcon{
                            margin-top: 20px;
                            .dkg-acc-type-tabname{
                                border: 1px solid #c8b3b3 !important;
                                &:hover{
                                    border: 1px solid #747474 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
   
}
@media only screen and (min-width: 768px) {
    /* For Laptop phones: */
    .dkg-crm-main-pageCon{
        .dkg-crm-main-contentCon{
            display: block !important;
        }
        .dkg-crm-main-mo-contentCon{
            display: none !important;
        }
    }
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .dk-mdHeaderMain .dk-mbtopheaderCon .dk-mdHeader-rightPanel .dkg-login-faq-btnCon .dkg-login-faq-btn {
        padding: 4px 10px;
        font-size: 12px;
        top: -1px;
    }
    .dkg-crm-main-pageCon{
        .dkg-crm-main-contentCon{
            display: none !important;
        }
        .dkg-crm-main-mo-contentCon{
            width: 100%;
            max-width: inherit;
            margin: 0 auto !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;   
            .dkg-mo-headerTitle{
                font-size: 17px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
                padding: 0.7rem 0.95rem;
                padding-top: 1.15rem;
            }
            .dkg-crm-mo-card-container {
                display: flex;
                justify-content: center;
                width: 100%;
                .dkg-crm-main-mo-card{
                    width: 100%;
                    height: 145px;
                    background-color: #ffffff;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    font-family: Arial, sans-serif;
                    font-size: 1.25rem;
                    color: #fff;
                    margin: 15px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    &:hover{
                        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.8);
                    }
                    .dkg-usertype-image-crop {
                        position: relative;
                        top: 5px;
                        background-color: #E6EBEE;
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 1px 1px 5px #ddd;
                        .dkg-usertype-image{
                            height: 38px;
                            width: 38px;
                            overflow: hidden;
                            background-size: cover;
                            // width: 120px;
                            // border-radius: 50%;
                            // display: block;
                            // margin-left: auto;
                            // margin-right: auto;
                            // margin-bottom: 5px;
                        }
                    }
                    .dkg-acc-type-tabcon{
                        margin-top: 16px;
                        .dkg-acc-type-tabname{
                            position: relative;
                            display: inline-block;
                            margin: 0;
                            padding: 5px;
                            text-align: center;
                            font-size: 0.85rem;
                            text-transform: uppercase;
                            // letter-spacing: 1px;
                            text-decoration: none;
                            color: #f3f3f3;
                            border: none !important;
                            transition: ease-out 0.5s;
                            -webkit-transition: ease-out 0.5s;
                            -moz-transition: ease-out 0.5s;
                            background-color: transparent !important;
                            box-shadow: none;
                            border-radius: 5px;
                            // width: 120px;
                            // &:hover{
                            //     border: 1px solid #884c5e !important;
                            //     color: #fff;
                            // }
                            .dkg-mo-box-btmtitle{
                                display: block;
                            }
                        }
                    }
                    &.dkg-mangemement-bg{
                        background-color: #884c5e !important;
                        padding: 0;
                        cursor: pointer;
                    }
                    &.dkg-recruiter-bg{
                        background-color: #2b8faf !important;
                        padding: 0;
                        cursor: pointer;
                    }
                    &.dkg-candidate-bg{
                        background-color: #5c737d !important;
                        padding: 0;
                        cursor: pointer;
                    }
                    &.dkg-client-bg{
                        background-color: #144773 !important;
                        padding: 0;
                        cursor: pointer;
                    }
                    &.dkg-supplier-bg{
                        background-color: #01796f !important;
                        padding: 0;
                        cursor: pointer;
                    }
                    &.dkg-partner-bg{
                        background-color: #5a4c72 !important;
                        padding: 0;
                        cursor: pointer;
                    }
                    // &:hover{
                    //     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px ;
                    //     opacity: 0.12;
                    // }
                }
               
            }
        }
       
    }
  }