.dk-mypipecheckduplicateModal {
    .modal-dialog {
        min-width: 70%;

        .modal-content {
            border-radius: 5px;
            background-color: transparent;

            .dkg-myduplicateMdoal-header-12 {
                background-color: #485569;
                padding: 12px 10px;
                border-radius: 5px 5px 0 0;

                .modal-title {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #fff;
                }

                .close {
                    text-shadow: none;
                    opacity: 1;
                    color: #fff;
                }
            }

            .dkg-myduplicateMdoal-Body-12 {
                background-color: #fff;
                padding: 30px 25px 22px;
                border-radius: 0 0 5px 5px;

                .dk-duplicateFormCon {
                    form {
                        .dk-duplicateFormGruop {
                            margin-bottom: 1.25rem;
                            position: relative;

                            input.form-control {
                                border: 1px solid #ddd;
                                font-size: 15px;
                                padding: 20px 15px;
                                border-radius: 0;

                                &:focus {
                                    box-shadow: none;
                                    outline: 0;
                                }
                            }

                            .dkg-crosssbtnCon14 {
                                position: absolute;
                                right: -8px;
                                top: -10px;
                                cursor: pointer;
                            }
                        }

                        .duplicateBtnCon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 0.5rem;
                            flex-direction: row-reverse;

                            button.dk-srchBtn {
                                color: #fff;
                                background-color: #485569;
                                padding: 8px 18px;
                                border-radius: 5px;
                                font-size: 14px;
                            }

                            button.dk-srchBtn.clear {
                                color: #333;
                                background-color: #FFF;
                                border: 1px solid #485569;
                                box-shadow: none;
                                margin-right: 20px;

                                &:hover {
                                    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
                                }
                            }
                        }

                        .no-duplicate {
                            padding: 0;
                            margin: 0;
                            font-weight: bolder;
                            color: green;
                            text-align: center;
                            font-size: 14px;
                        }

                        .dk-check-dupplicate-tableCon {
                            height: calc(100vh - 265px);
                            overflow-y: auto;
                            overflow-x: hidden;
                            padding-bottom: 0;
                            position: relative;

                            .dk-check-dupplicate-table {
                                margin-bottom: 0;

                                thead {
                                    tr th {
                                        text-align: center;
                                        vertical-align: middle;
                                        color: #fff;
                                        background: #3CA8BD;
                                        border: 1px solid #ccc;
                                        padding: 8px 10px;
                                        font-size: 13px;
                                        vertical-align: middle;
                                        position: sticky;
                                        top: 0;
                                        white-space: nowrap;
                                        z-index: 9;
                                    }
                                }

                                tbody {
                                    tr td {
                                        background: #f1f1f1;
                                        color: #4d4a4a;
                                        font-size: 13px;
                                        font-weight: 400;
                                        text-align: center;
                                        white-space: nowrap;
                                        padding: 0px;
                                        border-left: 1px solid #ccc;
                                        vertical-align: middle;
                                        text-transform: capitalize;

                                        .dk-dup-recimgCon {
                                            position: relative;

                                            img.recruiter-img {
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                            }
                                        }

                                        .profile-location-text {
                                            color: #000;
                                            font-weight: 600;

                                        }

                                        .caseDiv {
                                            height: 35px;
                                            line-height: 35px;
                                            color: #FFF
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}