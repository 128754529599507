.dk-mlresPageCon {
    margin-top: 125px;

    .dk-mlreSTopHead {
        .dk-mlresLeftHead {
            display: flex;

            .dk-resSrchInpuBbox {
                width: 100%;
                max-width: 160px;

                .input-group {
                    input {
                        border-radius: 0;
                        background-color: #eaeaea;
                        border-right: none !important;
                        border: 1px solid #ddd;

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }
                    }

                    .input-group-prepend {
                        .input-group-text {
                            color: #808080;
                        }
                    }
                }
            }

            .dk-resCrcleIconCon {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background-color: #eaeaea;
                border: 1px solid #ddd;
                display: flex;
                justify-content: center;
                align-items: center;

                .dk-resCrcleIcon {
                    font-size: 14px;
                    color: #333;
                }
            }

            .dk-mlresuserfilterCon1 {
                .dk-resuserFilterCon {
                    position: relative;

                    .crossUserFilter {
                        position: absolute;
                        right: -9px;
                        top: -4px;
                    }
                }
            }
        }

        .dk-resPageTitleCon {
            h2.dk-resPageTitle {
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 600;
                margin-top: 4px;
                margin-bottom: 0;
                width: 100%;
                text-align: center;
            }
        }

        .dk-mlresRighttHead {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .dk-rightBtnCon {
                display: flex;
                align-items: center;

                a {
                    color: #333;
                    background: #eaeaea;
                    border: 1px solid #ddd;
                    text-transform: uppercase;
                    font-size: 14px;
                    outline: none;
                    max-width: 150px;
                    padding: 0.5rem 0.7rem;
                    box-shadow: none;
                }

                .dk-clients-right-statusFilter {
                    .dk-cli-status-dropdown {
                        .dropdown-toggle {
                            box-shadow: none;
                            background-color: #eaeaea !important;
                            border: 1px solid #ddd;
                            color: #333 !important;
                            margin: 0;
                            padding: 0.4rem 0.35rem;
                            min-width: 95px;
                            text-transform: capitalize;
                            font-size: 14px;
                        }

                        .dropdown-menu {
                            border-radius: 0;
                            padding: 0;
                            border: none;
                            transform: translate(0, 40px) !important;

                            .dropdown-item {
                                padding: 6px 8px;
                                font-size: 13px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border: none;

                                .dkg-lv-statuscounter {
                                    border-radius: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: #fff;
                                    background-color: #234158;
                                    padding: 1px 5px;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }

                .dk-res-statusfilterCon {
                    .dk-clients-right-statusFilter {
                        .crossUserFilter {
                            position: absolute;
                            color: #000;
                            top: -8px;
                            right: 5px;
                            font-size: 15px !important;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .dk-mlresTableCon {
        margin-top: 5px;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh - 225px);
        padding-bottom: 0;
        position: relative;

        .dk-mlresTable {
            margin-bottom: 0;

            thead {
                tr th {
                    border-bottom: none;
                    padding: 10px 15px;
                    background: #234158;
                    color: #fff;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 13px;
                    vertical-align: middle;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    z-index: 9;
                    white-space: nowrap;
                }
            }

            tbody {
                tr td {
                    padding: 5px 15px;
                    white-space: nowrap;
                    background-color: #f9f9f9;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 13px;

                    .dk-recImgCon {
                        img {
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                        }
                    }

                    .dk-statusDrpCon {
                        .dropdown {
                            .dk-statusbtn {
                                min-width: 90px;
                                background-color: #31a886;
                                margin: 0;
                                padding: 0.5rem 1rem;
                                color: #fff;
                                font-size: .85rem;

                                &::after {
                                    display: none;
                                }
                            }

                            .dk-statusMenu {
                                border-radius: 0;
                                padding: 0;

                                .dk-statusActive {
                                    background-color: #31a886;
                                    color: #fff;
                                    font-size: 0.9rem;
                                    padding: 6px 10px;
                                }

                                .dk-statusInactive {
                                    background-color: #d8345f;
                                    color: #fff;
                                    font-size: 0.9rem;
                                    padding: 6px 10px;
                                }
                            }
                        }

                        &.dk-statusInactive {
                            .dropdown {
                                .dk-statusbtn {
                                    min-width: 90px;
                                    background-color: #d8345f !important;
                                    margin: 0;
                                    padding: 0.5rem 1rem;
                                    color: #fff;
                                    font-size: .85rem;

                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .dk-resAction {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        a {
                            color: #333;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            font-size: 14px;
                            border-radius: 3px;

                            &.dk-view-btn {
                                background-color: #2196f3;
                                color: #fff;
                            }

                            &.dk-edit-btn {
                                background-color: #fee69c;
                                color: #333;
                            }

                            &.dk-delete-btn {
                                background-color: #dc3545;
                                color: #fff;
                            }
                        }
                    }

                    &:nth-child(04) {
                        text-align: left;
                        min-width: 180px;
                    }
                }
            }
        }
    }
}