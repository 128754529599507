.dkg-listview-compysumm-Modal {
    .modal-dialog {
        max-width: 700px;
        min-width: 700px;

        .modal-content {
            background-color: transparent;
            border-radius: 5px;

            .dkg-listview-compysumm-ModalHeader {
                background-color: #26597d;
                display: flex;
                align-items: center;
                border-bottom: none;
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                justify-content: center;
                padding: 10px;

                .modal-title {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                    width: 100%;
                }

                .close {
                    color: #fff;
                    opacity: 1;
                    text-shadow: none;

                    &:hover {
                        opacity: 0.9;
                    }
                }

            }

            .dkg-listview-compysumm-ModalBody {
                background-color: #fff;
                border-radius: 0 0 5px 5px;
                padding: 0;

                .dkg-listview-company-editor12 {
                    .wrapper-class {
                        &.rdw-editor-wrapper {
                            padding: 0;
                            border: none;

                            .toolbar-class {
                                color: #333;
                            }
                        }

                        .rdw-editor-main {
                            height: calc(100vh - 480px);
                            min-height: inherit;
                            max-height: inherit;
                            overflow-y: auto;

                            .DraftEditor-root {
                                .DraftEditor-editorContainer {
                                    .public-DraftEditor-content {
                                        padding: 1px 15px;
                                    }
                                }
                            }
                        }
                    }
                }

                .dkg-compaysbt-btnCon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 0;

                    div {
                        div {
                            .dkg-create-payment-taskbtn {
                                color: #fff !important;
                                background-color: #26597d !important;
                            }
                        }
                    }

                }

                &.dkg-performance-ModalBody {
                    height: calc(100vh - 480px);
                    padding: 15px;
                }
            }

            .dkg-projectslist-modalBody {
                background-color: #fff;

                .nav-tabs {
                    margin-bottom: 0 !important;
                    border: none;
                    flex-wrap: nowrap;

                    a {
                        width: 100%;
                        text-align: center;
                        border-radius: 0;
                        outline: none;
                        padding: 9px 0;
                        border: none !important;

                        &:nth-child(01) {
                            background: #69594a;
                            color: #fff;
                        }

                        &:nth-child(02) {
                            background: #363f4c;
                            color: #fff;
                        }

                        &:nth-child(03) {
                            background: #dc3545;
                            color: #fff;
                        }
                    }
                }

                .tab-content {
                    .tab-pane {
                        height: calc(100vh - 310px);
                        overflow-y: auto;

                        .dk-toolsValuesPanel {
                            margin-top: 15px;

                            .d-flex {
                                .dk-sno {
                                    display: inline-block;
                                    width: 40px;
                                    height: 37px;
                                    border: 1px solid #ddd;
                                    border-bottom: none;
                                    border-right: none;
                                    text-align: center;
                                    line-height: 35px;
                                }

                                input {
                                    border-radius: 0;
                                    height: 37px;
                                    border: 1px solid #ddd !important;
                                    background: #f2f2f2;
                                    box-shadow: none !important;
                                }

                                .dk-toolsPanel-updateIcon {
                                    display: inline-block;
                                    width: 45px;
                                    border: 1px solid #ddd;
                                    border-bottom: none;
                                    border-left: none;
                                    text-align: center;
                                    line-height: 35px;
                                    cursor: pointer;

                                    i {
                                        padding: 7px;
                                        text-align: center;
                                        color: #fff;
                                        background-color: #28a745;
                                        border-color: #28a745;
                                    }
                                }
                            }

                            ul {
                                margin: 0;
                                padding: 0;

                                .Mukul {
                                    .dk-valueFields {
                                        .dk-handle {
                                            background-color: rgb(54, 63, 76);
                                            width: 50px;
                                            text-align: center;
                                            line-height: 35px;
                                            color: white;
                                            cursor: all-scroll;
                                        }

                                        input {
                                            border-radius: 0;
                                            height: 35px;
                                            border: 1px solid #ddd !important;
                                            background: #f2f2f2;
                                            box-shadow: none !important;
                                        }
                                    }
                                }
                            }

                            .dk-res-reorder-save-btnCon {
                                margin-top: 20px;

                                div {
                                    div {
                                        .dk-toolsPanelSaveBtn {
                                            background-color: #172a38 !important;
                                            color: #fff;
                                        }
                                    }
                                }
                            }

                            .d-flex {
                                input {
                                    border-radius: 0;
                                    height: 37px;
                                    border: 1px solid #ddd !important;
                                    background: #f2f2f2;
                                    box-shadow: none !important;
                                }

                                .dk-toolsPanel-updateIcon {
                                    display: inline-block;
                                    width: 45px;
                                    border: 1px solid #ddd;
                                    border-left: none;
                                    text-align: center;
                                    line-height: 35px;
                                    cursor: pointer;

                                    i.fa-trash-alt {
                                        background-color: #dc3545;
                                        border-color: #dc3545;
                                        padding: 7px;
                                        text-align: center;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}