.dkg-comppolicy-PageCont {
    margin-top: 165px;

    .dk-clntsPageTitle h2 {
        margin: 12px 0;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        padding: 0;
        margin: 0;
        text-transform: uppercase;

        img {
            width: 23px;
            height: 23px;
            margin-right: 10px;
            margin-top: -5px;
        }
    }

    .dk-cliLeftheaderCont input {
        max-width: 120px;
    }

    & input.form-control.searchTxtBox {
        border: dotted 1px #ccc;
        font-size: 13px;
        padding: 0.375rem 0.5rem;
        height: 30px;
        border-radius: 0;
    }

    & button.btn.dk-mbclientsrchInput {
        border: 1px dotted #ccc;
        border-left: 0;
        margin-top: 4px;
        border-radius: 0;
        padding: 0.35rem 0.5rem;
        height: 30px;
        margin: 0;
        box-shadow: none;
    }

    & .dk-ClieAddNewBtn {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            display: inline-block;
            border: 1px solid #eaeaea;
            background-color: #eaeaea;
            color: #333;
            outline: 0;
            width: 100%;
            max-width: 110px;
            text-align: center;
            padding: 3px 0;
        }
    }

    .dkg-compolicytableCon {
        padding-left: 0;
        padding-right: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow-y: auto;
        height: 100vh;
        max-height: 75vh;
        padding-bottom: 60px;

        .dkg-compolicytable {
            thead tr th {
                border-bottom: none;
                padding: 6px 18px;
                background: #fff;
                text-align: center;
                vertical-align: middle;
                font-size: 14px;
                font-weight: 400;
                border-top: 0;
            }
        }

        .dkg-compolicytable tbody {
            // box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding-left: 15px;
            padding-right: 15px;

            tr:first-child td {
                border-top: transparent;
            }

            tr td {
                padding: 5px 18px !important;
                white-space: nowrap;
                background-color: #eaeaea !important;
                font-weight: 400;
                text-align: center;
                vertical-align: middle;
                font-size: 14px;

                &:first-child {
                    width: 55px;
                }

                .dk-clupdeProfile i {
                    background: #297483;
                    padding: 3px 5px;
                    text-align: center;
                    border-radius: 2px;
                    color: #fff;
                    cursor: pointer;
                }

                a.dk-cllistStatusbtnt {
                    margin: 0;
                    border-radius: 3px;
                    border: none;
                    width: 100%;
                    min-width: 85px;
                    padding: 8px 0;
                    color: #fff !important;
                    outline: 0;
                    cursor: pointer;
                    font-size: 11px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    display: inline-block;
                    font-weight: 500;
                    text-align: center;
                    vertical-align: middle;
                }

                a.dk-clientsActBtn {
                    background-color: #1b6867;
                }

                .dk-clistViewTableIcn .dk-cllistViewTabImg {
                    min-height: 22px;
                    height: 30px;
                    width: 45px;
                }

                .dk-CliDetailAction {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                    justify-content: center;

                    a.dk-view-btn,
                    a.dk-view-btn:hover {
                        background: #fff;
                        background-color: #007bff;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-align-items: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 16px;
                        border-radius: 3px;
                    }

                    a.dk-view-btn i,
                    a.dk-view-btn:hover i {
                        color: #fff;
                    }

                    a.dk-edit-btn,
                    a.dk-edit-btn:hover {
                        background: #ccc;
                        background-color: #fee69c;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-align-items: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 16px;
                        border-radius: 3px;
                    }

                    a.dk-edit-btn i,
                    a.dk-edit-btn:hover i {
                        color: #333;
                    }

                    a.dk-delete-btn,
                    a.dk-delete-btn:hover {
                        display: -webkit-flex;
                        display: flex;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-align-items: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        background: #ccc;
                        line-height: 30px;
                        font-size: 16px;
                        border-radius: 3px;
                        background-color: #dc3545;
                        color: #fff;
                    }
                }
            }
        }
    }
}