
.statustransdropdwn{
    .dropdown{
        position: relative;
        button.dk-transStatusfliterbtn.dropdown-toggle.btn,
        button.dk-transStatusfliterbtn.dropdown-toggle.btn:hover {
            box-shadow: none;
            background-color: transparent;
            padding: 9px 15px !important;
            margin: 0;
            white-space: nowrap;
            font-size: 13px;
            text-transform: capitalize;
            color: #fff;
        }
        button.dk-transStatusfliterbtn.dropdown-toggle::after{
            display: none;
        }
        .caseStatusMenu.dropdown-menu{
            border-radius: 0;
            padding: 0;
            transform: translate(-1px, 35px) !important;
            // border: none;
            .dropdown-item{
                text-align: left;
                font-size: 13px;
                padding: 6px 10px;
                height: 34px;
                border-bottom: 1px solid #aaa;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}