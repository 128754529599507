.dk-budgetitlePageCon {
    margin-top: 125px;
}

.dk-budgetitlePageCon h2.dk-budgetPageTitle {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin: 0 0 1rem;
}

.flex-row.nav.nav-pills.dk-adsCreditTabs {
    justify-content: center;
    width: 100%;
}

.flex-row.nav.nav-pills.dk-adsCreditTabs .dk-budgetTab.nav-item {
    width: 100%;
    max-width: 8.33%;
}

.nav-pills.dk-adsCreditTabs .dk-budgetTab.nav-item .nav-link {
    height: 35px;
    width: 100%;
    background: #eaeaea;
    color: #333;
    padding: 5px 25px;
    float: left;
    border: 1px solid #ccc;
    border-right: none;
    cursor: pointer;
    text-align: center;
    border-radius: 0;
    outline: 0;
}

.nav-pills.dk-adsCreditTabs .dk-budgetTab.nav-item .nav-link.active,
.nav-pills.dk-adsCreditTabs .dk-budgetTab.nav-item .show>.nav-link {
    color: #fff;
    background: #252c37;
    border: 1px solid #252c37;
    border-right: none;
}

.dk-budgetPageCon .dk-budgetTopLeft {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.dk-budgetPageCon .dk-budgetTopLeft .dk-mailshotsSearch .input-group {
    width: 100%;
    max-width: 140px;
    margin-right: 3px;
}

.dk-budgetPageCon .dk-budgetTopLeft .dk-mailshotsSearch .input-group input {
    border: 1px solid #ddd !important;
    background-color: #eaeaea;
    padding: 0 10px;
    padding-right: 30px;
    box-shadow: none !important;
    border-radius: 0;
    height: 35px;
}

.dk-budgetPageCon .dk-budgetTopLeft button.dk-budgetfilter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #eaeaea !important;
    color: #333;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
}

.dk-budgetPageCon .dk-budgetTopLeft .dkg-budget-yearsDropdownCon .dkg-budget-yearsDropdown .dkg-budget-years-toggle {
    background: #f2f2f2 !important;
    border-radius: 3px;
    box-shadow: none;
    color: #333 !important;
    font-size: 13px;
    margin: 0;
    min-width: 110px;
    padding: 9px 10px;
    z-index: 99999;
    border: 1px solid #ddd;
    height: 35px;
    line-height: 17px;

}

.dk-budgetPageCon .dk-budgetTopLeft .dkg-budget-yearsDropdownCon .dkg-budget-yearsDropdown .dkg-budget-years-toggle::after {
    display: none;
}

.dk-budgetPageCon .dk-budgetTopLeft .dkg-budget-yearsDropdownCon .dkg-budget-yearsDropdown .dkg-budget-dropdownMenu {
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin: 35px 0 0 !important;
    min-width: 110px;
    padding: 0;
    -webkit-transform: inherit !important;
    transform: inherit !important;
    z-index: 99999;
}

.dk-budgetPageCon .dk-budgetTopLeft .dkg-budget-yearsDropdownCon .dkg-budget-yearsDropdown a {
    padding: 6px 10px;
    font-size: 13px;


}

.dk-budgetPageCon .dk-budgetTopLeft .dkg-budget-yearsDropdownCon .dkg-budget-yearsDropdown a:hover {
    background: #f8f9fa;
}

.dk-budgetPageCon .dk-budgetTopRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dk-budgetPageCon .dk-budgetTopRight button {
    display: inline-block;
    background: #eaeaea;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 0;
    outline: 0;
    font-size: 15px;
    font-weight: 400;
}

.dk-budgetPageCon .dk-budgetTopRight .dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect {
    min-width: 120px;
    height: 34px;
    background-color: #eaeaea !important;
}

.dk-budgetPageCon .dk-budgetTopRight .dk-reSLoginFilter .ui.selection.active.dropdown .menu {
    border-radius: 0;
}

.dk-budgetPageCon .dk-budgetTopRight .dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect:hover {
    background-color: #eaeaea;
}

.dk-budgetPageCon .dk-budgetTopRight .dk-reSLoginFilter .ui.default.dropdown.dk-overdues-task-slect:not(.button)>.text,
.dk-budgetPageCon .dk-budgetTopRight .dk-reSLoginFilter .ui.dropdown.dk-overdues-task-slect:not(.button)>.default.text {
    color: #333;
}

.dk-budgetPageCon .dk-budgetTopRight .dk-reSLoginFilter .dk-resloginfilterDrop .dk-resloginfilter-toggle {
    border-radius: 0;
    background-color: #eaeaea;
    border: 1px solid #ddd;
    color: #797474;
    font-size: 12px;
    box-shadow: none;
    margin: 0;
    padding: 0.5rem 0.35rem;
    min-width: 100px;
    text-transform: uppercase;
    font-weight: bold;
}

.dk-budgetPageCon .dk-budgetTopRight .dk-reSLoginFilter .dk-resloginfilterDrop .dk-resloginfilter-toggle::after {
    position: absolute;
    right: 6px;
    top: 15px;
    color: #797474;
}

.dk-reSLoginFilter .dk-resloginfilterDrop .dk-resloginfilterDropMenu {
    border-radius: 0;
    padding: 0;
}

.dk-reSLoginFilter .dk-resloginfilterDrop .dropdown-item {
    background-color: #eaeaea;
    border-top: 1px solid #ddd;
    padding: 0.5rem 0.4rem;
    font-size: 13px;
}

.dk-reSLoginFilter .dk-resloginfilterDrop .dropdown-item:first-child {
    border-top: none;
}

.dk-reSLoginFilter .dk-resloginfilterDrop .dropdown-item:hover {
    background-color: #f1f1f1;
}

.dk-budgetPageCon .dk-budgetTableCont {
    cursor: pointer;
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100vh - 215px);
    position: relative;
    margin-top: 1rem;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable {
    margin-bottom: 0;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable thead tr th {
    background: #252c37;
    color: #e2dada;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 15px;
    border: 1px solid #616767;
    vertical-align: middle;
    position: sticky;
    top: 0;
    /* position: sticky;
    top: 0; */
    z-index: 11;
    white-space: nowrap;
    text-transform: uppercase;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td {
    background: #2c394b;
    color: #bdb4b4;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 5px 15px;
    border: 1px solid #5a6169;
    vertical-align: middle;
    white-space: nowrap;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adstractAction a.dk-edit-btn {
    color: #fee69c;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adstractAction a.dk-edit-btn:hover {
    box-shadow: 0 12px 16px 0 rgb(0 0 0 / 24%), 0 17px 50px 0 rgb(0 0 0 / 19%) !important;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adsStatusBtn .dropdown .btn-success {
    background-color: #31a886 !important;
    box-shadow: none;
    padding: 0;
    margin: 0;
    color: #fff;
    height: 40px;
    width: 100%;
    border-radius: 0;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adsStatusBtn.dk-varbtnBg .dropdown .btn-success {
    background-color: #dc3545 !important;
    color: #fff;
    padding: 0;
    margin: 0;
    color: #fff;
    padding: 0;
    margin: 0;
    height: 40px;
    width: 100%;
    border-radius: 0;

}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-toggle::after {
    display: none;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu {
    padding: 0;
    border-radius: 0;
    min-width: 120px;
    max-width: 120px;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu a.dropdown-item:first-child {
    background-color: #31a886 !important;
    color: #fff;
    padding: 7px 10px;
    text-align: center;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu a.dropdown-item:last-child {
    background-color: #dc3545 !important;
    color: #fff;
    padding: 7px 10px;
    text-align: center;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td .dk-adstractAction {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr:last-child td:nth-child(01),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr:last-child td:nth-child(02),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr:last-child td:nth-child(03),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr:last-child td:nth-child(08){
    background-color: transparent;
    border: none;
} */

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable thead tr th:nth-child(02),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td:nth-child(02),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable thead tr th:nth-child(05),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td:nth-child(05),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable thead tr th:nth-child(06),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td:nth-child(06),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable thead tr th:nth-child(07),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td:nth-child(07) {
    min-width: 80px;
    max-width: 80px;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tbody tr td:nth-child(02) {
    padding: 0;
}

.flex-row.dk-adsCreditTabs.dk-budgetTabs.nav.nav-pills {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0;
}

.dk-budgetPageCon.dk-adsCredtTableCon .tab-content {
    overflow-x: hidden;
}

.dk-budgetTableCont {
    position: relative;
}

.dk-budgetTableCont .dk-budgetTable tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #252c37;
    color: #e2dada;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 15px !important;
    border: 1px solid #616767;
    vertical-align: middle;
}

.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tfoot tr td:nth-child(01),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tfoot tr td:nth-child(02),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tfoot tr td:nth-child(03),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tfoot tr td:nth-child(04),
.dk-budgetPageCon .dk-budgetTableCont .dk-budgetTable tfoot tr td:nth-child(08) {
    background-color: #fff;
    border: none;
}