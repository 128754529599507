.dkg-rec-db-delete-modal-24 {
    .modal-dialog {
        min-width: 30%;
        max-width: 30%;

        .modal-content {
            .dkg-rec-db-delete-modalbody-24 {
                padding: 20px 25px;
                border-radius: 5px 5px;

                p {
                    color: #e2445b;
                    display: block;
                    font-size: 15px;
                    font-weight: 400;
                    padding: 15px 0;
                }

                .dkg-recdb-deletebtnCon {
                    display: flex;
                    justify-content: flex-end;

                    .dkg-rec-db-no-btn {
                        background: transparent;
                        border: 1px solid #333;
                        border-radius: 5px;
                        color: #333;
                        display: inline-block;
                        max-width: 90px;
                        outline: none;
                        padding: 7px 0;
                        transition: all .5s;
                        width: 100%;

                        &:hover {
                            background: #535353;
                            border-color: #535353;
                            color: #fff;
                        }
                    }

                    .dkg-rec-db-yes-btn {
                        background-color: #e2445b;
                        border: 1px solid #333;
                        border-radius: 5px;
                        color: #fff;
                        display: inline-block;
                        max-width: 90px;
                        outline: none;
                        padding: 7px 0;
                        transition: all .5s;
                        width: 100%;
                    }
                }
            }
        }
    }
}