.dk-caseStausTabCon{
    color: #333;
    margin-top: 10px;
    position: relative;
    &::after{
        content: "";
        width: 0.1px;
        height: 100%;
        background: #a6a6a6;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        display: inline-block;
        top: 0;
    }
    .dk-profileDates-br{
        height: calc(100vh - 360px);
        
    }
    .dk-caseStausFromGrp{
        .dk-nativedatepicker-grid{
            .dk-nativedatepicker{
                .MuiButtonBase-root{
                    outline: none;
                    span{
                        opacity: 0;
                    }
                    &::after{
                        content: '';
                        background: url('../../../../../../../assets/images/calendar.png') no-repeat;
                        padding: 9px 9px;
                        background-size: 15px;
                        position: absolute;
                        right: 4px;
                        top:7px;
                        pointer-events: none;
                    }
                }
            }
        }
    }
    .dk-caseStausFromGrp{
        margin-bottom: 0.75rem;
        margin-left: 15px;
        label{
            text-align: left;
            text-transform: uppercase;
            width: 100%;
            max-width: 130px;
            font-size: 12px;
            margin-bottom: 0;
            white-space: nowrap;
        }
        input{
            text-align: center;
        }
    }
}