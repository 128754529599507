.dkg-office365Modal {
    color: #333;

    .modal-dialog {
        .modal-content {
            border: none;
            border-radius: 5px;
            background-color: transparent;

            .dkg-office365ModalHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #26597d;
                padding: 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: none;

                .modal-title {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                }

                .close {
                    text-shadow: none;
                    color: #fff;
                    opacity: 1;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                &.dkg-edit-office365ModalHeader {
                    background-color: #fee69c;

                    .modal-title {
                        color: #333;
                    }

                    .close {
                        color: #333;

                    }
                }

                &.dkg-view-office365ModalHeader {
                    background-color: #2196f3;

                    .modal-title {
                        color: #fff;
                    }

                    .close {
                        color: #fff;

                    }
                }

                &.dkg-reorder-office365ModalHeader {
                    background-color: #26597d;

                    .modal-title {
                        color: #fff;
                    }

                    .close {
                        color: #fff;

                    }
                }
            }

            .dkg-office365ModalBody {
                background-color: #fff;
                border-radius: 0 0 5px 5px;

                form {
                    .form-group {
                        display: flex;
                        align-items: center;

                        label {
                            margin-bottom: 0.75rem;
                            min-width: 125px;
                            font-size: 13px;
                        }

                        .dk-resDropDown {
                            width: 100%;

                            input.form-control {
                                border-radius: 0;
                                height: 31px;
                                text-align: center;
                                border: 1px solid #ddd;
                            }

                            .dk-logins-categoryDropdown {
                                .dropdown-toggle.btn.btn-primary {
                                    box-shadow: none;
                                    background-color: #fff !important;
                                    border: 1px solid #ddd;
                                    color: #686565;
                                    margin: 0;
                                    padding: 0.43rem 0.75rem;
                                    text-transform: capitalize;
                                    font-size: 12px;
                                    width: 100%;

                                    &::after {
                                        position: absolute;
                                        right: 10px;
                                        top: 15px;
                                    }
                                }

                                .dropdown-menu {
                                    border-radius: 0;
                                    margin: 0;
                                    padding: 0;
                                    width: 100%;

                                    .dk-logins-categoryDropdown-Item {
                                        background-color: #fff;
                                        border-bottom: 1px solid #ddd;
                                        font-size: 12px;
                                        padding: 7px 8px;

                                        &:hover {
                                            background-color: #f2f2f2;
                                        }

                                        &:last-child {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-inputBoxCon {
                            display: flex;
                            width: 100%;

                            input.form-control {
                                border-radius: 0;
                                height: 31px;
                                text-align: center;
                                border: 1px solid #ddd;
                            }

                            span {
                                button {
                                    display: inline-block;
                                    border: none;
                                    background-color: #26597d;
                                    color: #fff;
                                    height: 31px;
                                    padding: 2px 7px;
                                    border-radius: 0;
                                    font-size: 0.89rem;
                                }
                            }
                        }
                    }

                    .dk-sbmitbtnCon {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .dk-sbmitbtn {
                            display: inline;
                            background: #26597d !important;
                            border: none;
                            color: #fff;
                            text-transform: uppercase;
                            padding: 8px 25px;
                            font-weight: 500;
                            font-size: 14px;
                            outline: none;
                            cursor: pointer;
                            border-radius: 5px;

                            &.dk-edit-sbmitbtn {
                                background: #fee69c !important;
                                color: #333 !important;
                                font-weight: 600;
                            }
                        }

                    }
                }

                &.dkg-reorder-office365ModalBody {
                    .dk-toolsReorderPanel {
                        ul {
                            margin: 0;
                            padding: 0;

                            .Mukul {

                                // background-color: #eaeaea;
                                .dk-valueFields {
                                    .dk-handle {
                                        background-color: rgb(54, 63, 76);
                                        width: 50px;
                                        text-align: center;
                                        line-height: 35px;
                                        color: white;
                                        cursor: all-scroll;
                                    }

                                    input {
                                        height: 35px;
                                        border-radius: 0px;
                                        border: 1px solid #ddd;
                                        border-bottom: none;
                                        background: #fff;
                                        box-shadow: none;
                                        text-align: center;
                                    }

                                }

                                &:last-child {
                                    .dk-valueFields {
                                        input {
                                            border-bottom: 1px solid #ddd;
                                        }
                                    }
                                }
                            }

                        }

                        .d-flex {
                            .dk-toolsPanelSaveBtn {
                                background: #165a77;
                                box-shadow: none;
                                color: #fff;
                                font-size: 14px;
                                outline: none;
                                padding: 8px 12px 7px;
                                border-radius: 4px;
                                border: none;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}