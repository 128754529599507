.dkg-cand-myreferals-mainCon{
    margin-top: 0;
    background-color: #eaeaea;
    // height: calc(100vh - 152px);
    width: 100%;
    padding: 15px;
    padding-top: 20px;
    .dkg-cand-myreferals-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .dkg-cand-myreferals-headerleftCon{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .dk-mailshotsSearch{
                .input-group{
                    max-width: 145px;
                    min-width: 145px;
                    width: 100%;
                    input{
                        background-color: #fff!important;
                        border: 1px solid #ddd;
                        border-radius: 0;
                        box-shadow: none;
                        height: 35px;
                        line-height: 1.3rem;
                        margin: 0;
                        padding: 0.5rem 0.55rem;
                        text-align: left;
                    }
                    .input-group-append{
                        button{
                            background-color: #fff!important;
                            border: 1px solid #ddd;
                            border-radius: 0;
                            box-shadow: none;
                            height: 35px;
                            line-height: 1.3rem;
                            margin: 0;
                            padding: 0.5rem 0.55rem;
                            i{
                                color: #6c757d;
                            }
                        }
                    }
                }
            }
            .dkg-refresh-btn{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50%;
                box-shadow: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
            .dkg-referal-addnew-btnCon{
                .dkg-referal-addnew-btn{
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    color: #848484;
                    display: inline-block;
                    font-size: 12px;
                    height: 33px;
                    line-height: 10px;
                    margin-left: 10px;
                    outline: none;
                    text-align: center;
                    width: 33px;
                }
            }
        }
        .dkg-cand-myreferals-centreCon{
            .dkg-docs-title{
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                color: #6c757d;
                display: flex;
                align-items: center;

                // .dkg-infoicon{
                //     color: #6c757d;
                //     width: 24px;
                //     height: 24px;
                //     cursor: pointer;
                // }
            }
        }
        .dkg-cand-myreferals-rightCon{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dkg-refresh-btn{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50%;
                box-shadow: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
            .dkg-add-new-btn{
               background-color: #fff;
                border-radius: 0;
                color: #6c757d;
                display: inline-block;
                font-family: Roboto,sans-serif;
                font-size: 14px;
                padding: 8px 15px;
                text-decoration: none;
                white-space: nowrap;
                svg{
                    color: #6c757d;
                }
            }
            .dkg-referal-status-drpdwn{
                
                .dkg-referal-status-drpdwn-toggle{
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid #ddd;
                    padding: 6px 10px;
                    text-transform: capitalize;
                    font-size: 13px;
                    background-color: #fff !important;
                    color: #6c757d;
                    border-radius: 3px;
                    min-width: 120px;
                    position: relative;
                    &::after {
                        position: absolute;
                        top: 15px;
                        right: 10px;
                        color: #6c757d;
                    }
                }
                .dkg-referal-status-drpdwn-menu{
                    border-radius: 0;
                    padding: 0;
                    z-index: 99999;
                    .dkg-referal-status-item{
                        padding: 8px 10px;
                        border-bottom: 1px solid transparent;
                        font-size: 13px;
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        .dkg-lv-statuscounter {
                            position: absolute;
                            right: 8px;
                            color: #fff;
                            background-color: #234158;
                            padding: 1px 5px;
                            font-size: 12px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
    .dkg-document-tableCon{
        margin-top: 6px;
        .dkg-document-table{
            thead{
                tr{
                    th{
                        background-color: #1f394c;
                        padding: 10px 0.75rem;
                        vertical-align: middle;
                        font-weight: normal;
                        font-size: 14px;
                        border: 1px solid #8a8a8a;
                        text-align: center;
                        color: #ddd;
                        white-space: nowrap;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        background-color: #fff;
                        padding: 10px 0.75rem;
                        vertical-align: middle;
                        font-weight: normal;
                        font-size: 14px;
                        border: 1px solid #ddd;
                        text-align: center;
                        color: #333;
                        white-space: nowrap;
                        .dkg-cand-myreferals-cols{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-action-btn{
                                color: white;
                                padding: 0.4em 1em;
                                border: none;
                                border-radius: 4px;
                                &:hover {
                                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                    transition: all ease 0.5s;
                                }
                                &.open-btn{
                                    background-color: #ffb64d;
                                }
                                &.download-btn{
                                    background-color: #28a745;
                                }
                                &.edit-btn{
                                    background-color: #007bff;
                                }
                                &.delete-btn{
                                    color: #dc3545;
                                    background: none;
                                    border: 1px solid #dc3545;
                                    &:hover{
                                        background-color: #dc3545;
                                        color: #fff;
                                    }

                                }
                            }
                        }
                        .dkg-referal-status-drpdwn{
                            .dkg-referal-status-drpdwn-toggle{
                                margin: 0 !important;
                                padding: 0;
                                color: #fff;
                                width: 100%;
                                min-width: 85px;
                                max-width: 85px;
                                font-size: 0.85rem;
                                text-transform: capitalize;
                                &::after {
                                    display: none;
                                }

                                .statusDiv {
                                    border-radius: 3px;
                                    padding: 6px 5px;
                                }
                            }
                            .dkg-referal-status-drpdwn-menu{
                                padding: 0;
                                border-radius: 0;
                                margin: 0 !important;
                                top: 34px !important;
                                transform: inherit !important;
                                left: 0 !important;
                                right: inherit !important;
                                border: none;
                                min-width: 130px;
                                max-width: 130px;
                                .dkg-referal-status-item{
                                    display: block;
                                    width: 100%;
                                    font-size: 14px;
                                    padding: 6px 12px;
                                    border-bottom: transparent;
                                }
                            }
                        }
                        .dkg-agreements-btn-con,
                        .dkg-buy-btn-con{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-agreements-btn,
                            .dkg-buy-btn{
                                background-color: #3c97b6;
                                color: #fff;
                                padding: 0.5rem 0.75rem;
                                text-transform: capitalize;
                                white-space: nowrap;
                                .dkg-invoice-icon{
                                    margin-left: 0.5rem;
                                    position: relative;
                                    top: -1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}