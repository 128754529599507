.dk-workflow-numupdateModal{
    .modal-dialog{
        width: 100%;
        max-width: 380px;
        .modal-content{
            border-radius: 5px;
            border: none;
            .dk-workflow-numupdateModalHeader{
                padding: 7px 15px;
                background: #d9d9d9;
                border-radius: 5px 5px 0 0;
                position: relative;
                display: none;
                .modal-title {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                    color: #333;
                }
                .close {
                    padding: 0;
                    color: #333;
                    opacity: 1;
                    text-shadow: none;
                    font-size: 24px;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    outline: 0;
                    margin: 0;
                    &:hover{
                        opacity: 0.9;                        
                    }
                }
            }
            .dk-workflow-numupdateModalBody{
                padding: 20px 30px;
                background-color: #f2f2f2;
                border-radius: 6px;
                .dk-numupdateCon{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 0.5rem;
                    .firstInput {
                        max-width: 100px;
                        label {
                            width: 100%;
                            text-align: center;
                        }
                        input.form-control {
                            border-radius: 0;
                            text-align: center;
                            background-color: #e2e7f6;
                            outline: 0;
                            box-shadow: none;
                            border: 1px solid #ddd;
                        }
                    }
                    .SecondInput{
                        max-width: 100px;
                        margin-left: 10px;
                        label {
                            width: 100%;
                            text-align: center;
                        }
                        input.form-control {
                            border-radius: 0;
                            text-align: center;
                            box-shadow: none;
                            border: 1px solid #ddd;
                            background-color: #fff;
                        }
                    }
                    .dk-workflowBtnCon{
                        width: auto;
                        margin-top: 28px;
                        margin-left: 8px;
                        text-align: center;
                        button {
                            background: #2b8faf;
                            font-size: 14px;
                            padding: 6px 30px;
                            text-decoration: none;
                            box-shadow: none;
                            min-width: 90px;
                            box-shadow: none;
                            letter-spacing: 1px;
                            color: #fff;
                            border-radius: 35px;
                            &:hover {
                                background: #234158;
                                transition: all .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}