.dkg-specsumm-mainPage {
    margin-top: 120px;

    .dkg-specsumm-mainRow {
        display: flex;

        .dkg-specsumm-mainCols {
            width: 100%;
            max-width: 100%;
            background: #eaeaea;
            border: 1px solid #dadada;
            margin: 0 9px;
            align-items: center;
            font-size: 12px;
            text-align: center;
            border-radius: 5px;
            padding: 0;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            .dkg-spec-header {
                background-color: #eaeaea;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding: 7px 10px;
                border-bottom: 1px solid #dadada;
                font-weight: 700;
                text-transform: uppercase;
                color: #6c757d;
            }

            .dkg-spec-body {
                height: calc(100vh - 210px);
                overflow-y: auto;

                ul {
                    li {
                        list-style: none;
                    }
                }
            }
        }
    }
}