.dkg-getStarted-workModelMain {
    margin-top: 120px;
    padding: 0;
    position: relative;
    padding: 0 15px;

    .dk-recuiters-kpi-Leftpanel {
        background-color: #fff;
        width: 100%;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            width: 100%;
            list-style: none;
            padding: 0;
            margin: auto;
            max-width: 1600px;
            background-color: #fff;

            li {
                max-width: 9.99%;
                width: 100%;
                list-style-type: none;
                display: inline-block;
                width: 100%;
                max-width: 9.9999% !important;
                height: 44px;
                line-height: 44px;
                text-align: center;

                a {
                    // border-radius: 0;
                    // color: #333;
                    // font-size: 14px;
                    // padding: 0.65rem 1rem;
                    // text-align: center;
                    // text-transform: capitalize;
                    // width: 100%;
                    display: inline-block;
                    width: 100%;
                    color: #333;
                    text-align: center;
                    font-weight: 400;
                    letter-spacing: 0.3px;
                    font-size: 15px;
                    font-family: Verdana, sans-serif;
                    transition: all 0.5s;
                    height: 44px;
                    line-height: 44px;
                    white-space: nowrap;
                    background-color: #fff;

                    &.active {
                        color: #3c97b6;
                        position: relative;
                        font-weight: 600;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -2px;
                            height: 1px;
                            width: 66%;
                            background: #3c97b6;
                            left: 25px;
                        }
                    }

                    &:after {
                        border-bottom: 3px solid #3c97b6;
                        width: 100%;
                    }
                }
            }

        }
    }

    .dkg-workModel-mainCon {
        background-color: #eaeaea;
        height: calc(100vh - 195px);
        padding: 25px;
        margin-top: 0;
        overflow-y: auto;

        .dkg-workModel-tableCon {
            display: flex;
            width: 100%;

            .dkg-workModel-table-1 {
                thead {
                    tr {
                        th {
                            border: 1px solid #bbb;
                            border-bottom: none;
                            border-top: none;
                            padding: 11px 10px;
                            text-align: center;
                            text-transform: uppercase;
                            vertical-align: middle;
                            white-space: nowrap;
                            background: #2e75b6;
                            color: #fff;
                            font-size: 14px;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            background: #fff;
                            border: 1px solid #ddd;
                            padding: 10px 15px;
                            text-align: center;
                            vertical-align: middle;
                            white-space: nowrap;
                            font-size: 13px;

                            &:nth-child(02) {
                                text-align: left;
                            }

                            &:nth-child(03) {
                                text-align: center;
                            }

                            .checkCross-icon.text-red {
                                color: red;
                            }

                            .checkCross-icon.text-green {
                                color: green;
                            }
                        }
                    }
                }

                &.model-1 {
                    thead {
                        tr {
                            th {
                                background-color: #3c96b5;
                            }
                        }
                    }
                }

                &.model-3 {
                    thead {
                        tr {
                            th {
                                background-color: #26596d;
                            }
                        }
                    }
                }
            }

            .dk-workModel-overviewMain {
                width: 100%;
                /* max-width: 1000px; */
                // margin: 30px auto;
                // margin-top: 30px;
                padding-top: 15px;

                .dkg-workmodel-cols {
                    width: 100%;
                    // max-width: 30%;
                    padding: 0 15px;

                    .title {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        text-transform: uppercase;
                        width: 100%;
                        // max-width: 180px;
                        min-height: 55px;
                        text-align: center;
                        position: relative;
                        font-weight: bold;

                        .dkg-workmodel-subtitle {
                            position: absolute;
                            display: block;
                            bottom: 17px;
                        }
                    }

                    .workModel-panel {
                        width: 100%;
                        cursor: pointer;
                        height: calc(100vh - 330px);
                        background-color: #fff;

                    }
                }


            }
        }
    }
}