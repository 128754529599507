.dkg-addnew-paymenttask-modal-21 {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 600px !important;
        max-width: 600px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dkg-addnew-payment-modalheader-21 {
                // background-color: #234158;
                background-color: #234158;
                border-radius: 0;
                padding: 14px 15px !important;
                width: 100%;
                border-bottom: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: relative;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    width: 100%;
                    text-align: center;


                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: -5px;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dkg-addnew-payment-modalbody-21 {
                background-color: #eaeaea;
                padding: 15px;
                height: calc(100vh - 45px);
                overflow-y: auto;

                .form-group {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.25rem;

                    label {
                        font-size: 13px;
                        margin-bottom: 0;
                        min-width: 150px;

                        .dk-globalUserDropdown {
                            &.dkg-payment-taskbydropDown {
                                .dropdown-toggle.btn {
                                    background-color: #fff !important;
                                    border: 1px solid #ddd !important;
                                    width: 35px;
                                    height: 35px;

                                    &:hover {
                                        color: #333 !important;
                                    }
                                }

                                .dk-adstracker-DrpMenu12 {
                                    &.dkg-payment-taskbydropDownMenu {
                                        color: inherit;
                                    }
                                }
                            }

                            &.dkg-payment-taskfordropDown {
                                .dropdown-toggle.btn {
                                    background-color: #fff !important;
                                    border: 1px solid #ddd !important;
                                    width: 35px;
                                    height: 35px;

                                    &:hover {
                                        color: #333 !important;
                                    }
                                }

                                .dk-adstracker-DrpMenu12 {
                                    &.dkg-payment-taskfordropDownMenu {
                                        left: inherit !important;
                                        margin: 0 !important;
                                        padding: 0 !important;
                                        position: absolute !important;
                                        right: 50px !important;
                                        top: 9px !important;
                                        transform: inherit !important;
                                        right: 145px !important;
                                        top: -1px !important;

                                        .d-flex {
                                            height: 36px !important;
                                            align-items: center;
                                        }

                                        // &::after {
                                        //     border-color: transparent #234158 transparent transparent;
                                        //     _border-color: #000 #234158 #000 #000;
                                        //     border-style: solid;
                                        //     border-width: 8.5px 12px 8.5px 0;
                                        //     content: "";
                                        //     _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        //     height: 0;
                                        //     line-height: 0;
                                        //     position: absolute;
                                        //     right: -10px;
                                        //     top: 26px;
                                        //     -webkit-transform: rotate(59deg);
                                        //     transform: rotate(59deg);
                                        //     width: 0;
                                        // }

                                        &::before {
                                            left: inherit !important;
                                            right: -10px !important;
                                            top: 8px;
                                            transform: rotate(59deg);
                                        }
                                    }
                                }
                            }
                        }

                    }

                    .dkg-taskby-u12 {
                        &.dkg-move-centre {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .dkg-taskby-imgCon {
                            .dkg-taskby-img {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                            }
                        }
                    }

                    .dkg-payment-task-drop12 {
                        width: 100%;

                        .dropdown {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;

                            .dropdown-toggle {
                                box-shadow: none;
                                background-color: #fff;
                                padding: 7px 10px;
                                width: 100%;
                                display: flex;
                                justify-content: space-around;
                                align-items: center;
                                text-transform: capitalize;
                                font-size: 13px;
                                color: gray;
                                margin-left: 0;
                                margin-right: 0;
                                border: 1px solid #ddd;
                                margin: 0;

                                &:after {
                                    color: gray;
                                    display: inline-block;
                                    font-size: 16px;
                                    position: absolute;
                                    right: 11px;
                                    top: 16px;
                                }
                            }

                            .dropdown-menu {
                                border-radius: 0;
                                padding: 0;
                                width: 100%;
                                overflow-y: auto;
                                max-height: 220px;

                                .dropdown-item {
                                    border-bottom: 1px solid #ddd;
                                    padding: 5px 7px;

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }

                        &.dkg-payment-task-readonly-drop12 {
                            .dropdown {
                                .dropdown-toggle {
                                    background-color: #f2f2f2;

                                }
                            }
                        }

                        &.dkg-invoice-addnewPopup {
                            .MuiGrid-root.MuiGrid-container {
                                .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
                                    width: 100%;
                                    margin-top: 0;
                                    margin-bottom: 0;

                                    .MuiFormHelperText-root.Mui-error {
                                        display: none;
                                    }

                                    .MuiInputBase-root.MuiInput-root {
                                        input {
                                            background-color: #fff;
                                        }

                                        .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                            margin-left: 0;

                                            .MuiButtonBase-root.MuiIconButton-root {
                                                padding: 5px 6px;
                                                background: #fff;
                                                border-radius: 0;
                                                border: 1px solid #ddd;
                                                border-left: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.dkg-invoice-addnewPopup.dkg-hidelabel-text {
                            .MuiGrid-root.MuiGrid-container {
                                .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
                                    width: 100%;
                                    margin-top: 0;
                                    margin-bottom: 0;

                                    .MuiInputBase-root.MuiInput-root {
                                        input {
                                            background-color: #fff;
                                            color: #26597d;
                                        }

                                        .MuiInput-underline.Mui-error:after {
                                            // display: none;
                                            border-bottom: none !important;
                                        }
                                    }

                                    .MuiFormHelperText-root.Mui-error {
                                        display: none;
                                    }

                                    .MuiInput-underline:after {
                                        border-bottom-color: transparent !important;
                                    }
                                }
                            }
                        }

                        &.dkg-invoice-addnewPopup.dkg-edit-sales-books-calender {
                            display: flex;
                            position: relative;
                            align-items: center;

                            .MuiGrid-root.MuiGrid-container {
                                .MuiFormControl-root {
                                    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                        position: relative;

                                        &::after {
                                            border-bottom: none;
                                        }

                                        input {
                                            border-right: none;
                                            height: 18px;
                                        }

                                        .MuiFormHelperText-root.Mui-error {
                                            display: none;

                                            &::after {
                                                border-bottom: none;
                                            }
                                        }

                                        .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                            position: absolute;

                                            .MuiButtonBase-root.MuiIconButton-root {
                                                border-left: 1px solid #ddd;
                                            }
                                        }
                                    }
                                }
                            }

                            .dkg-invo-trashIcon {
                                background-color: #fff;
                                padding: 5px 8px;
                                border: 1px solid #ddd;
                                cursor: pointer;

                                svg {
                                    color: rgba(0, 0, 0, 0.54);

                                }
                            }
                        }



                    }

                    .dkg-recuiter-dropdwnCon-12 {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        &.dkg-rightopen-dropdownCon {
                            .dkg-cand-recuiter-dropdown-123 {
                                .dkg-cand-recuiter-dropdown-toggle-123 {
                                    i {
                                        width: 35px;
                                        height: 35px;
                                        border: 1px solid #ddd;
                                        border-radius: 50%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        &:hover {
                                            color: #333;
                                        }
                                    }
                                }

                                .dkg-cand-recuiter-dropdownmenu-123 {
                                    padding: 8px 5px;
                                    background: #f2f2f2;
                                    border-radius: 3px;
                                    transform: inherit !important;
                                    right: 48px !important;
                                    left: inherit !important;
                                    top: -3px !important;

                                    &::before {
                                        position: absolute;
                                        left: inherit !important;
                                        right: -10px !important;
                                        top: 8px !important;
                                        transform: rotate(57deg) !important;
                                    }

                                    .d-flex {
                                        display: flex !important;
                                        flex-direction: row-reverse !important;
                                    }
                                }
                            }
                        }
                    }

                    input {
                        border: 1px solid #ddd;
                        color: grey;

                        &.dkg-payment-attach-input {
                            background-color: #fff;
                            width: 100%;
                            padding: 7px 10px;
                            height: 42px;
                        }
                    }

                    .dkg-addnew-date-timeCon {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .MuiFormControl-root {
                            &.MuiFormControl-marginNormal {
                                margin: 0;
                                width: 100%;
                            }

                            .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                input {
                                    text-align: center;
                                    background-color: #fff;
                                }

                                .MuiInputAdornment-positionEnd {
                                    margin-left: 0;

                                    .MuiIconButton-root {
                                        padding: 6px 7px;
                                        background-color: #26597d;
                                        border-radius: 0;
                                        border: 1px solid #ccc;
                                        border-left: 0;

                                        .MuiIconButton-label {
                                            svg {
                                                color: #fff;
                                                font-size: 1.25rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .dkg-readonly-datetime-picker {
                            color: #234158;

                            .MuiFormControl-root {
                                &.MuiFormControl-marginNormal {
                                    margin: 0;
                                    width: 100%;
                                }

                                .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                                    input {
                                        text-align: center;
                                        background-color: #f2f2f2;
                                    }

                                    .MuiInputAdornment-positionEnd .MuiIconButton-root {
                                        cursor: text;
                                    }
                                }
                            }

                        }
                    }

                    .dkg-payment-task-readonly-drop12 {
                        input {
                            background-color: #f2f2f2;
                        }
                    }

                    .dkg-euro-btnCon {
                        .dkg-euro-btn {
                            background-color: #26597d;
                            border: none;
                            border-radius: 0;
                            color: #fff;
                            display: inline-block;
                            font-size: 1rem;
                            height: 32px;
                            padding: 2px 10px;
                        }
                    }

                    .dkg-file-inputCon-24 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        flex-direction: row-reverse;

                        .dkg-file-input__input {
                            width: 0.1px;
                            height: 0.1px;
                            opacity: 0;
                            overflow: hidden;
                            position: absolute;
                            z-index: -1;
                        }

                        .dkg-file-input-label {
                            cursor: pointer;
                            display: inline-flex;
                            align-items: center;
                            border-radius: 4px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #fff;
                            font-size: 14px;
                            padding: 10px 12px;
                            background-color: #4245a8;
                            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

                            svg {
                                height: 16px;
                                margin-right: 4px;
                            }
                        }
                    }

                    textarea {
                        border: 1px solid #ddd;
                        text-align: justify;

                        &.dkg-readonly-paymentnotes {
                            background-color: #f2f2f2;
                        }
                    }

                    #dkg-edit-FileUpload {
                        display: flex;
                        width: 100%;
                        align-items: center;

                        .dkg-edit-wrapper {
                            background-color: #fff;
                            width: 100%;
                            border: 1px solid #ddd;

                            .dkg-edit-uploaded {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                padding: 6px 10px;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                    color: green;
                                }

                                .dkg-edit-file {
                                    display: flex;
                                    flex-direction: column;

                                    .dkg-edit-file__name {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        align-items: baseline;
                                        width: 300px;
                                        line-height: 0;
                                        color: #0c3214;
                                        font-size: 18px;
                                        letter-spacing: 1.5px;

                                        p {
                                            margin-bottom: 0;
                                            font-size: 13px;
                                            color: grey;
                                        }

                                        .fa-times {
                                            color: grey;
                                            cursor: pointer;

                                            :hover {
                                                cursor: pointer;
                                                opacity: 0.8;
                                            }

                                        }



                                        .fa-file-pdf {
                                            padding: 15px;
                                            font-size: 40px;
                                            color: #0c3214;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .MuiFormControl-root {
                        .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                            &::before {
                                border-bottom: none;
                            }

                            input {
                                text-align: center;
                            }
                        }
                    }
                    div{
                        div{
                            .dkg-referral-addnew-btn{
                                background: #1f394c;
                                color: #fff;
                                border: 1px solid #1f394c;
                                text-align: center;
                                display: inline-block;
                                padding: 5px 12px;
                                text-transform: uppercase;
                                font-size: 13px;
                                line-height: 1.5385;
                                border-radius: 0.1875rem;
                                &:hover{
                                    background-color: #234158;
                                }
                            }
                        }
                    }
                }

                .dkg-create-payment-taskbtncon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;

                    div {
                        div {
                            button.dkg-create-payment-taskbtn {
                                background: #234158;
                                color: #fff;
                                border: 1px solid #234158;
                                text-align: center;
                                display: inline-block;
                                padding: 5px 12px;
                                text-transform: uppercase;
                                font-size: 13px;
                                line-height: 1.5385;
                                border-radius: 0.1875rem;
                                margin-left: 120px;

                                &:hover {
                                    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dkg-addnew-paymenttask-modal-21.fade .modal-dialog {
    right: -40%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dkg-addnew-paymenttask-modal-21.show .modal-dialog {
    right: 0;
}

.modal.dkg-addnew-paymenttask-modal-21 {
    z-index: 9999 !important;
}

.MuiDialog-root {
    z-index: 9999 !important;
}