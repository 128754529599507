.dk-getstarted-mainPageCon{
    margin-top: 169px;
    padding: 0 45px;
    padding-bottom: 0;
    .dkg-getstarted-pageCon{
        .dkg-getstarted-rowCon{
            &:first-child{
                .dkg-getstarted-leftCon{
                    .dkg-getstarted-boxCon{
                        margin-top: 0;
                    }
                }
            }
            .dkg-getstarted-leftCon{
                display: flex;                
                .dkg-getstarted-boxCon{
                    width: 100%;
                    max-width: 100%;
                    background: #eaeaea;
                    border: 1px solid #dadada;
                    margin-right: 30px;
                    margin-top: 30px;
                    align-items: center;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 5px;
                    padding: 0;
                    &:last-child{
                        margin-right: 0;
                    }
                    .dkg-getstarted-Header{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        border-radius: 5px 5px 0 0;
                        background-color: #eaeaea;
                        border-bottom: 1px solid #ddd;
                        .dkg-getstarted-Title{
                            font-size: 16px;
                            padding: 8px 10px;
                            color: #646060;
                            font-weight: 600;
                            width: 100%;
                            text-align: center;
                        }
                    }
                    .dkg-getstarted-Body {
                        background-color: #f0f0f0;
                        border-radius: 0 0 5px 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .dk-getStartedIcon{
                            width: 100%;
                            max-width: 110px;
                            padding: 0 20px;
                            border-right: 1px solid #ddd;
                            margin-right: 12px;
                            min-height: 125px;
                            display: flex;
                            align-items: center;
                            background: #eaeaea;
                            img{
                                width: 100%;
                                max-width:50px;
                                margin: auto;
                                filter: saturate(0.6);
                            }
                        }
                        .dk-getstartedbox-title{
                            font-size: 16px;
                            padding: 8px 10px;
                            color: #646060;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .dkg-getstarted-rightCon{
            margin-top: 0;
            .dkg-getstarted-rightboxCon{
                width: 100%;
                max-width: 100%;
                background: #eaeaea;
                border: 1px solid #dadada;
                align-items: center;
                font-size: 12px;
                text-align: center;
                border-radius: 5px;
                padding: 0;
                .dkg-getstarted-Header{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    border-radius: 5px 5px 0 0;
                    background-color: #eaeaea;
                    border-bottom: 1px solid #ddd;
                    .dkg-getstarted-Title{
                        font-size: 16px;
                        padding: 8px 10px;
                        color: #646060;
                        font-weight: 600;
                    }
                }
                .dkg-getstarted-Body {
                    background-color: #eaeaea;
                    border-radius: 0 0 5px 5px;
                    min-height:510px;
                    .dk-getstartedbox-title{
                        font-size: 16px;
                        padding: 8px 10px;
                        color: #646060;
                        font-weight: 600;
                    }
                    .dk-getstartedLogin-table{
                        padding: 10px;
                        table{
                            border:none !important;
                            margin-bottom: 0;
                            tr{
                                &:last-child{
                                    td{
                                        border-bottom:1px solid #ddd;
                                    }
                                }
                                td{
                                    border:1px solid #ddd;
                                    vertical-align: middle;
                                    padding: 5px 8px;
                                    font-size: 14px;
                                    border-right: transparent;
                                    border-bottom: none;
                                    text-align: left;
                                    background: #f0f0f0;
                                    &:nth-child(01){
                                        width: 9%;
                                        text-align: center;
                                    }
                                    &:nth-child(02){
                                        width: 12%;
                                        text-align: center;
                                    }
                                    &:last-child{
                                        border-right: 1px solid #ddd;
                                        width:38%;
                                    }
                                    .dk-softIcon{
                                        img{
                                            width: 32px;
                                            padding: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
