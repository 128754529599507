.dkg-todays-adstrackermainCon-14 {
    background-color: #fff !important;
    margin-top: 75px !important;
    padding: 0 15px;

    .dk-todayAdsTableHeaderCon24 {
        .dk-todayadsPageHead {
            .dk-todayadsLeft {
                align-items: center;
                display: flex;

                .dk-mailshotsSearch {
                    .input-group {
                        margin-right: 8px;
                        max-width: 140px;
                        width: 100%;

                        .d-flex {
                            input {
                                background-color: #eaeaea;
                                border: 1px solid #ddd !important;
                                border-radius: 0;
                                box-shadow: none !important;
                                height: 35px;
                                padding: 0 30px 0 10px;
                            }
                        }
                    }
                }

                button {
                    align-items: center;
                    background: #eaeaea !important;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    box-shadow: none;
                    color: #333;
                    display: flex;
                    height: 35px;
                    justify-content: center;
                    outline: none;
                    text-align: center;
                    width: 35px;
                }
            }

            h2.title {
                color: #333;
                font-size: 20px;
                font-weight: 600;
                left: 50%;
                margin: 0;
                position: absolute;
                text-align: center;
                text-transform: uppercase;
                top: 8px;
                -webkit-transform: translate(-50%);
                transform: translate(-50%);
            }

            .dk-todayadsTopRight {
                .dk-reSLoginFilter {
                    .dk-cli-status-dropdown {
                        .dropdown-toggle {
                            background-color: #fff !important;
                            border-radius: inherit;
                            box-shadow: none;
                            color: #333;
                            font-size: 14px;
                            margin: 0;
                            min-width: 110px;
                            padding: 5px 10px;
                            text-transform: capitalize;
                            width: inherit;
                        }

                        .dropdown-menu {
                            border-radius: 0;
                            margin: 0;
                            padding: 0;

                            .dropdown-item {
                                padding: 8px 10px;
                                position: relative;

                                .dkg-lv-statuscounter {
                                    background-color: #234158;
                                    color: #fff;
                                    font-size: 12px;
                                    padding: 1px 5px;
                                    position: absolute;
                                    right: 8px;
                                }
                            }
                        }
                    }
                }

                button {
                    background: #eaeaea;
                    border: 1px solid #ddd;
                    border-radius: 0;
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 400;
                    outline: 0;
                    padding: 5px 10px;
                }
            }
        }

        .dk-todayAdsTableCon-14 {
            margin-top: 6px;
            overflow-y: auto;
            overflow-x: auto;
            height: calc(100vh - 160px);
            padding-bottom: 0;
            position: relative;

            .dk-todayAdsTable-14 {
                margin-bottom: 0;

                thead {
                    tr th {
                        background: #252c37;
                        border: 1px solid #616767;
                        border-bottom: none;
                        color: #e2dada;
                        font-size: 14px;
                        padding: 9px 15px;
                        position: -webkit-sticky;
                        position: sticky;
                        text-align: center;
                        top: 0;
                        vertical-align: middle;
                        white-space: nowrap;
                        z-index: 9;
                    }
                }

                tbody {
                    tr td {
                        background: #2c394b !important;
                        border: 1px solid #5a6169;
                        color: #bdb4b4;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 5px 15px;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        .dk-smsTableCheckbox {
                            align-items: center;
                            display: flex;
                            justify-content: space-evenly;

                            input {
                                margin-right: 0.5rem;
                            }

                            input[type=checkbox] {
                                -webkit-appearance: none;
                                appearance: none;
                                background: #fff;
                                border: 1px solid #ddd;
                                border-radius: 0;
                                box-sizing: border-box;
                                box-sizing: content-box;
                                cursor: pointer;
                                height: 12px;
                                position: relative;
                                transition: all .3s linear;
                                width: 12px;
                            }
                        }

                        .dk-todayAdimgCon {
                            img.img-todayAdCricle {
                                border-radius: 50%;
                                height: 30px;
                                min-height: 22px;
                                width: 30px;
                            }
                        }

                        .dk-adsStatusBtn {
                            .dropdown {
                                button {
                                    color: #fff !important;
                                    margin: 0;
                                    min-width: 102px;
                                    padding: 0.375rem 1rem !important;

                                    &::after {
                                        display: none;
                                    }
                                }

                                .dropdown-menu {
                                    border-radius: 0;
                                    max-width: 120px;
                                    min-width: 120px;
                                    padding: 0;

                                    a.dropdown-item {
                                        &:first-child {
                                            background-color: #31a886 !important;
                                            color: #fff;
                                        }

                                        &:last-child {
                                            background-color: #dc3545 !important;
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-adstractAction {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}