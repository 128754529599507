.modal-dialog.dk-detviewmodal-90w {
    transform: translate(125%, 0px);
    min-width: 95%;
    position: fixed;
    right: 2px;
    top: 0;
    margin: 0 !important;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    .modal-content {
        background-color: transparent;

        .dk-detViewheader {
            border-radius: 0;
            padding: 3px 0;
            background-color: #26597d;
            border-bottom: none;
            color: #fff;
            padding: 0.5rem 1rem;
            border-radius: 0;
            font-size: 0.95rem;

            .dk-detViewTitle {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 16px;
                .dkg-clientid-con{
                    font-size: 16px;
                    white-space: nowrap;
                }
                .modalTitleCon {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    padding: 0;
                    margin: 0;
                    color: #fff;

                    a {
                        i {
                            color: #fff;
                        }
                    }
                }
            }

            .close {
                color: #fff;
                opacity: 1;
                margin: 0;
                padding: 0;
                position: absolute;
                right: 12px;
                top: 4px;

                &:hover {
                    background-color: #eaeaea;
                    color: #333;
                }
            }
        }

        .modal-body {
            background-color: #fff;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            height: calc(100vh - 35px);
            overflow-y: auto;
            padding: 0;

            .dk-cliDetVPageCon.dklistViewPopContent {
                margin-top: 0;

                .dk-clientDetViewTopSec {
                    height: inherit !important;
                    overflow-y: inherit !important;

                    @media only screen and (min-width: 1200px) {
                        .col-md-2.col-sm-12.dk-ClieDetViewSideMenu {
                            flex: 0 0 11.66667%;
                            max-width: 11.66667%;

                            &.dkg-clientifo-navpills-Con {
                                flex: 0 0 100%;
                                max-width: 100%;
                                padding: 0;

                                .nav.nav-pills {
                                    width: 100%;
                                    max-width: inherit;
                                    margin-bottom: 0;
                                    padding: 0;
                                    height: inherit;

                                    .nav-item {
                                        width: 100%;
                                        // max-width: 9.09%;
                                        max-width: 8.333%;
                                        .nav-link {
                                            text-align: center !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @media only screen and (min-width: 1200px) {
                        .col-md-10.col-sm-12.dk-ClieDetViewMainCont {
                            flex: 0 0 88.33333%;
                            max-width: 88.33333%;

                            &.dkg-clientifo-rightpanel-Con {
                                flex: 0 0 100%;
                                max-width: 100%;
                                .tab-content{
                                    .dkg-client-tabpane-567{
                                        .dkg-payment-qt-pageCon{
                                            &.dkg-comman-training-pageCon{
                                                &.dkg-comman-training-234{
                                                    margin-top: 20px !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .dk-DetViewBottomSecCon {

                        height: calc(100vh - 35px);
                        overflow-y: auto;
                    }
                }
            }

        }
    }
}

.modal.fade .modal-dialog.dk-detviewmodal-90w {
    right: -95%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show .modal-dialog.dk-detviewmodal-90w {
    right: 0;
}

.modal {
    z-index: 9999 !important;
}