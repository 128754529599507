.dk-MonthlyTableCont {
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden !important;
    height: calc(100vh - 165px);
    padding-bottom: 0;
    margin-top: 10px;
    position: relative;
}

.dk-dailyTrackTopLeft {
    margin-top: 10px;
}

.dk-dailyTrackTopLeft .dk-dateTimeDay {
    border: 1px solid #ccc;
    padding: 6px 10px;
    font-size: 14px;
    background: #f2f2f2;
    min-width: 140px;
    max-width: 170px;
}

.dk-dailyTrackTopLeft .dk-dateTimeDay span.dk-calIcon {
    font-size: 16px;
    margin-left: 16px;
}

.dk-MonthlyTableCont .dk-MonthlyTable thead tr th {
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 1px 9px;
    background-color: #eaeaea;
    color: #333;
    border: 1px solid #999;
    vertical-align: middle;
    min-width: 60px;
    max-width: 60px;
    position: sticky;
    top: 0;
    z-index: 99;
}

.dk-appstrackTableSearch .input-group {
    width: 100%;
    margin-right: 3px;
}

.dk-MonthlyTableCont .dk-MonthlyTable {
    margin-bottom: 0;
}

.dk-MonthlyTableCont .dk-MonthlyTable {
    margin-bottom: 0;
}

.dk-MonthlyTableCont .dk-MonthlyTable thead tr th img.dk-dailytablehimg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.dk-MonthlyTableCont .dk-MonthlyTable tbody tr td {
    background: #252c37;
    color: #bdb4b4;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    padding: 7px 15px;
    border: .5px solid #726e6e;
    vertical-align: middle;
}

.dk-MonthlyTableCont .dk-MonthlyTable tfoot tr td {
    position: sticky;
    bottom: 0;
    background: #3a3a52;
    color: #e2dada;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 15px !important;
    border: 1px solid #616767;
    vertical-align: middle;
    border: none;
}