.dkg-comppolicy-PageCont {
    margin-top: 116px;

    .dk-clntsPageTitle {
        position: relative;
        top: 1px;

        h2 {
            margin: 12px 0;
            display: block;
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            padding: 0;
            margin: 0;
            text-transform: uppercase;
        }
    }

    .dk-dkClileftGap {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .dk-cliLeftheaderCont {
            input {
                max-width: 120px;
            }
        }

        .dk-ClieAddNewBtn {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                display: inline-block;
                border: 1px solid #eaeaea;
                background-color: #eaeaea;
                color: #848484;
                outline: 0;
                width: 100%;
                text-align: center;
                padding: 4px 8px;
                border: 1px solid #ccc;
                border-radius: 3px;
            }
        }
    }

    .dkg-clientlist-rightCon {
        .dkg-clientList-dropCon {
            .dkg-clientList-dropdown {
                .dkg-clientList-dropdown-toggle {
                    padding: 0;
                    color: #848484;
                    text-transform: uppercase;
                    box-shadow: none;
                    border: 1px solid #ccc;
                    background: #eaeaea;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 130px;
                    height: 33px;

                    &::after {
                        color: #848484;
                        position: absolute;
                        right: 6px;
                        top: 14px;
                    }
                }

                .dkg-clientList-dropdownMenu {
                    border: 1px solid #ddd;
                    border-radius: 0;
                    width: 100%;
                    min-width: 125px;
                    max-width: 125px;
                    padding: 0;
                    transform: inherit !important;
                    top: 37px !important;
                    margin: 0 !important;
                    right: inherit !important;
                    height: auto;
                    overflow-y: auto;
                    z-index: 99999;
                    background: #f2f2f2;

                    a {
                        display: block;
                        padding: 6px 12px;
                        font-size: 14px;
                        background: #f2f2f2;
                        border-bottom: 1px solid transparent;
                        position: relative;

                        .dropdown-filter-count {
                            position: absolute;
                            right: 5px;
                            top: 8px;
                            font-weight: 600;
                            background-color: #3c4c62;
                            border-radius: 2px;
                            color: #fff;
                            font-size: 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 5px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }

                .dkg-clientList-dropdownMenu::-webkit-scrollbar {
                    width: 5px;
                }

                .dkg-clientList-dropdownMenu::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                .dkg-clientList-dropdownMenu::-webkit-scrollbar-thumb {
                    background-color: #f2f2f2 !important;
                    outline: 0px solid slategrey;
                }
            }
        }
    }

    .dk-reloadBtn {
        display: inline-block;
        width: 100%;
        max-width: 40px;
        height: 33px;
        background: #eaeaea;
        border-radius: 3px;
        margin-right: 8px;
        border: 1px solid #ccc;
        outline: none;
        color: #848484;

        svg {
            color: #848484;
        }
    }

    & input.form-control.searchTxtBox {
        border: solid 1px #ccc;
        font-size: 13px;
        padding: 0.375rem 0.5rem;
        height: 30px;
        border-radius: 0;
    }

    & button.btn.dk-mbclientsrchInput {
        border: 1px solid #ccc;
        border-left: 0;
        margin-top: 4px;
        border-radius: 0;
        height: 30px;
        margin: 0;
        box-shadow: none;
        background: #eaeaea;
        width: 100%;
        max-width: 30px;
        color: #848484;
    }

    & .dk-ClieAddNewBtn {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            display: inline-block;
            border: 1px solid #eaeaea;
            background-color: #eaeaea;
            color: #333;
            outline: 0;
            width: 100%;
            max-width: 110px;
            text-align: center;
            padding: 5px 13px;
            border: 1px solid #ccc;
            border-radius: 3px;
        }
    }

    .dkg-compolicytableCon {
        padding-left: 0;
        padding-right: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow-y: auto;
        height: 100vh;
        height: calc(100vh - 207px);
        overflow-y: auto;
        margin-top: 8px;

        .dkg-compolicytable {
            thead tr th {
                background: #485569;
                border: 1px solid gray;
                border-bottom: none;
                color: #fff;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 400;
                padding: 10px 12px;
                position: sticky;
                text-align: center;
                top: 0;
                vertical-align: middle;
                white-space: nowrap;
                z-index: 9999;
            }
        }

        .dkg-compolicytable tbody {
            // box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding-left: 15px;
            padding-right: 15px;

            tr:first-child td {
                border-top: transparent;
            }

            tr td {
                padding: 8px 18px !important;
                white-space: nowrap;
                background-color: #eaeaea !important;
                font-weight: 400;
                text-align: center;
                vertical-align: middle;
                font-size: 14px;
                border-bottom: 1px solid #e1dbdb !important;

                &:first-child {
                    width: 55px;
                }

                .dk-clupdeProfile i {
                    background: #297483;
                    padding: 3px 5px;
                    text-align: center;
                    border-radius: 2px;
                    color: #fff;
                    cursor: pointer;
                }

                a.dk-cllistStatusbtnt {
                    margin: 0;
                    border-radius: 3px;
                    border: none;
                    width: 100%;
                    min-width: 85px;
                    padding: 8px 0;
                    color: #fff !important;
                    outline: 0;
                    cursor: pointer;
                    font-size: 11px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    display: inline-block;
                    font-weight: 500;
                    text-align: center;
                    vertical-align: middle;
                }

                a.dk-clientsActBtn {
                    background-color: #3a9469;
                }

                .dk-clistViewTableIcn .dk-cllistViewTabImg {
                    min-height: 22px;
                    height: 30px;
                    width: 45px;
                }

                .dkg-reports-cols {
                    display: inline-block;
                    background-color: #fff;
                    border-radius: 50%;
                    cursor: pointer;
                    height: 19px;
                    width: 19px;
                    line-height: 16px;

                    i {
                        color: #6a6464;
                        cursor: pointer;
                        font-size: 10px;
                    }
                }

                .dk-satus-SlectDropDwn {
                    .dropdown {
                        .dropdown-toggle {
                            background-color: #31a886;
                            color: #fff;
                            font-size: .85rem;
                            margin: 0;
                            min-width: 90px;
                            padding: 0.5rem 1rem;
                            text-transform: capitalize;

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            border-radius: 0;
                            padding: 0;
                            transform: translate(18.75px, 33.75px) !important;

                            .dropdown-item {
                                font-size: .9rem;
                                padding: 6px 10px;
                            }
                        }
                    }
                }

                .dk-CliDetailAction {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                    justify-content: center;

                    a.dk-view-btn,
                    a.dk-view-btn:hover {
                        background: #fff;
                        background-color: #007bff;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-align-items: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 16px;
                        border-radius: 3px;
                    }

                    a.dk-view-btn i,
                    a.dk-view-btn:hover i {
                        color: #fff;
                    }

                    a.dk-edit-btn {
                        color: #333;
                        background-color: transparent;

                        &:hover {
                            background: #ccc;
                            background-color: #fee69c;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-justify-content: center;
                            justify-content: center;
                            -webkit-align-items: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            font-size: 16px;
                            border-radius: 3px;
                        }
                    }

                    a.dk-edit-btn i,
                    a.dk-edit-btn:hover i {
                        color: #6a6464;
                    }

                    a.dk-delete-btn {
                        color: #6a6464;
                        background-color: transparent;

                        &:hover {
                            display: -webkit-flex;
                            display: flex;
                            -webkit-justify-content: center;
                            justify-content: center;
                            -webkit-align-items: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            background: #ccc;
                            line-height: 30px;
                            font-size: 16px;
                            border-radius: 3px;
                            background-color: #dc3545;
                            color: #fff;
                        }
                    }
                }

                &:nth-child(08),
                &:nth-child(09) {
                    text-align: center;
                }
            }
        }
    }
}