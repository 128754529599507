.dk-clientInvoiceDashboard-main {
    margin-top: 120px;

    .dk-clientInvoice-flex {
        width: 100%;
        // max-width: 1600px;
        // margin: auto;
        padding: 0 7px;

        .dk-box-cols {
            width: 100%;
            height: calc(100vh - 165px);

            .d-flex.w-100 {
                height: 50%;

                .dk-invoice-box {
                    display: flex;
                    align-items: start;
                    background: #eaeaea;
                    border: 1px solid #dadada;
                    border-radius: 5px;
                    font-size: 12px;
                    width: 100%;
                    margin: 8px;

                    .title {
                        align-items: center;
                        background-color: #eaeaea;
                        border-bottom: 1px solid #dadada;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        color: #6c757d;
                        display: flex;
                        font-weight: 700;
                        justify-content: space-between;
                        padding: 7px 10px;
                        text-transform: uppercase;
                        width: 100%;
                    }

                    ul {
                        margin-bottom: 0;
                    }

                    ul.h-lg {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}