.dk-master-moduleCon {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    h4.title {
        text-align: center;
        display: block;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        margin-bottom: 10px;
    }

    @media(max-width:992px) {
        height: auto;
        top: 150px;
    }

    // top: 60px;
    .dk-module-blocksContainer.dk-adminContainer {
        width: 100%;
        max-width: 1000px;
        margin: auto;
        margin-top: 60px;

        h3.title {
            display: done;
            margin-bottom: 0;
            margin-top: 0;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            color: #333;
            position: absolute;
            left: 38%;
            transform: translate(-50%);
            top: 10px;
        }

        h4.sbtitle {
            text-align: right;
            position: absolute;
            right: 30px;
            top: 15px;
            font-size: 22px;
            font-weight: 600;
            color: #333;
        }

        .dk-topHeadTitle {
            position: relative;
            padding: 10px 0 40px;
            z-index: 9;
        }

        .dk-portalCols {
            width: 100%;
            display: flex;
            justify-content: center;

            .dk_portalBox_1 {
                background: #3C4C62;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_2 {
                background: #258FB4;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_3 {
                background: #4c5f59;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_4 {
                background: #4c5f59;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_5 {
                background-color: #4c5f59;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_6 {
                background: #5C737D;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_7 {
                background: #4c5f59;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_8 {
                background: #007D7F;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_9 {
                background: #B55355;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_support {
                background: #4c5f59;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_frelancerec {
                background: #57496D;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_frlancerresource {
                background-color: #4c5f59;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_supplierbgcolor {
                background-color: #4c5f59;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .dk_portalBox_8 {
                background-color: #4c5f59;

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }

        .dk-portalBox {
            width: 100%;
            max-width: 25%;
            min-height: 125px;
            background: #eee;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px;

            div {
                display: block;
                width: 100%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333;
                width: 100%;
                height: 100%;

                &:hover .dk-Portalicons img {
                    transition: all 0.5s;
                    transform: scale(0.88);
                }
            }

            .dk-Portalicons {
                text-align: center;
                margin-bottom: 10px;

                img {
                    width: 100%;
                    max-width: 40px;
                    transition: all 0.5s;
                    transform: scale(1);
                }
            }

            .dk-Portalicons.PortaliconsBtn {
                button {
                    background: #f2f2f2;
                    border: none;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    border-radius: 3px;
                    margin: 0 2px;
                    outline: none;
                    color: #067185;
                }
            }

            .dk-PortalTitle {
                font-size: 17px;
                text-transform: capitalize;
                font-weight: 400;
                display: block;
                text-align: center;
                color: #fff;
            }
        }

        .dkg-adver-top-box {
            width: 100%;
            max-width: 25%;
            min-height: 125px;
            border-radius: 6px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            margin: 15px;

            .title {
                background: #eaeaea;
                border-radius: 5px 5px 0 0;
                display: block;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0;
                padding: 6px 10px;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
                border: 1px solid #ddd;
                // border-bottom: none;
            }

            .dk-textPanel {
                align-items: center;
                display: flex;
                font-size: 28px;
                font-weight: 600;
                justify-content: center;
                padding: 0;
                text-align: center;
                width: 100%;
                background-color: #eaeaea;
                border: 1px solid #ddd;
                border-top: none;
                height: 91px;
                border-radius: 0 0 5px 5px;

                .dkg-accounting-boxtable {
                    border-top: none;
                    margin-bottom: 0;

                    tbody {
                        tr {
                            td {
                                border: none;
                                height: 91px;
                                line-height: 62px;
                                font-size: 26px;
                                font-weight: 600;

                                .dkg-red-counter {
                                    color: #4c5f59;
                                }

                                .dkg-green-counter {
                                    color: #4c5f59 !important;
                                }
                            }
                        }
                    }

                    &.dkg-monthly-budget-table {
                        tbody {
                            tr {
                                td {
                                    border: none;
                                    height: 91px;
                                    line-height: 62px;
                                    font-size: 17px;

                                    &:first-child {
                                        border-right: 1px solid #ddd;
                                    }

                                    .dkg-red-counter {
                                        color: #ff0000;
                                    }

                                    .dkg-green-counter {
                                        color: #3a9469 !important;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}


.dk-portalMain {

    // padding: 60px 0;
    // padding-bottom: 204px;
    // background-color: #d8d8d8;
    .enrolment_process_img_sec {
        width: 100%;
        min-height: 622px;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .enrolment_process_content {
        position: absolute;
        top: -115px;
        left: 0;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                width: 410px;
                position: absolute;
                list-style-type: none;

                .enrolment_icon_sec {
                    position: relative;
                    float: right;
                    margin-left: 15px;

                    img {
                        max-width: 100%;
                    }
                }

                .enrolment_icon {
                    position: absolute;
                    content: "";
                    top: 31%;
                    left: -12px;
                    right: 0;
                    margin: 0 auto;
                    display: inline-block;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    text-align: center;
                }

                &:nth-child(01) {
                    left: 605px;
                    top: 120px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-right: 15px;
                        -webkit-transform: rotate(124deg);
                        transform: rotate(124deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-124deg);
                        transform: rotate(-124deg);
                    }
                }

                &:nth-child(02) {
                    left: 762px;
                    top: 310px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(176deg);
                        transform: rotate(176deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-176deg);
                        transform: rotate(-176deg);
                    }
                }

                &:nth-child(03) {
                    top: 542px;
                    left: 714px;

                    .enrolment_icon_sec {
                        float: left;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(224deg);
                        transform: rotate(224deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-224deg);
                        transform: rotate(-224deg);
                    }
                }

                &:nth-child(04) {
                    text-align: center;
                    top: 634px;
                    left: 440px;
                    width: 257px;
                    text-align: center;

                    .enrolment_icon_sec {
                        position: relative;
                        float: none;
                        margin-left: 0;
                        margin-right: 15px;
                        -webkit-transform: rotate(270deg);
                        transform: rotate(270deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-270deg);
                        transform: rotate(-270deg);
                    }
                }

                &:nth-child(05) {
                    left: 5px;
                    top: 522px;
                    text-align: right;
                    text-align: right;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(-39deg);
                        transform: rotate(-39deg);
                        float: right;
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(39deg);
                        transform: rotate(39deg);
                    }
                }

                &:nth-child(06) {
                    text-align: right;
                    top: 300px;
                    left: -34px;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(7deg);
                        transform: rotate(7deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-7deg);
                        transform: rotate(-7deg);
                    }
                }

                &:nth-child(07) {
                    text-align: right;
                    top: 120px;
                    left: 105px;

                    .enrolment_icon_sec {
                        -webkit-transform: rotate(58deg);
                        transform: rotate(58deg);
                    }

                    .enrolment_icon {
                        -webkit-transform: rotate(-58deg);
                        transform: rotate(-58deg);
                        top: 36%;
                    }
                }

                .enrolment_text {
                    h4 {
                        font-size: 16px;
                        font-weight: 800;
                        text-transform: uppercase;
                        color: #003f51;
                        margin: 0 0 5px;
                        padding-top: 15px;
                    }

                    p {
                        line-height: 20px;
                        color: #333 !important;
                        margin: 0;
                        font-weight: 400;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-master-moduleCon {
        .dk-module-blocksContainer {
            padding: 0 15px;

            .dk-portalCols {
                display: block;

                .dk-portalBox {
                    width: 100%;
                    max-width: 100%;
                    min-width: 100%;
                    margin: 15px 0 !important;
                    min-height: 125px;
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}


.all-none {
    opacity: 0;
    pointer-events: none;
}