.dk-invoiceBoxMain-container {
    margin-top: 135px;
    padding: 0 10px;

    .dk-invoiceBox-Cols-1 {
        width: 100%;
        max-width: 20%;

        &:nth-child(02) {
            max-width: 40%;
        }

        .dk-invoiceBox-columns {
            align-items: center;
            background: #eaeaea;
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 12px;
            margin: 0 15px;
            padding: 0;
            text-align: center;

            .sbtitle {
                align-items: center;
                background-color: #eaeaea;
                border-bottom: 1px solid #ddd;
                border-radius: 5px 5px 0 0;
                color: #646060;
                display: flex;
                font-family: Segoe UI, Arial, sans-serif !important;
                font-size: 16px;
                font-weight: 600;
                justify-content: center;
                padding: 8px 10px;
                text-transform: uppercase;
                width: 100%;
            }

            .dk-invoiceBox-columns-panel {
                align-items: center;
                background-color: #eaeaea;
                border-radius: 0 0 5px 5px;
                display: flex;
                justify-content: center;
            }
        }

        .dk-invoiceBox-col {
            align-items: center;
            background: #eaeaea;
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 12px;
            margin: 0 15px 30px;
            padding: 0;
            text-align: center;
            width: 100%;

            .sbtitle {
                align-items: center;
                background-color: #eaeaea;
                border-bottom: 1px solid #ddd;
                border-radius: 5px 5px 0 0;
                color: #646060;
                display: flex;
                font-family: Segoe UI, Arial, sans-serif !important;
                font-size: 16px;
                font-weight: 600;
                justify-content: center;
                padding: 8px 10px;
                text-transform: uppercase;
                width: 100%;
            }

            .dk-invoiceBox-columns-panel {
                align-items: center;
                background-color: #eaeaea;
                border-radius: 0 0 5px 5px;
                display: flex;
                justify-content: center;
            }
        }
    }
}