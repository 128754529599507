.dkg-teams-mainpageCon {
    margin-top: 160px;

    .dkg-teams-mainbox-flex {
        padding: 0 7px;
        width: 100%;

        .dkg-teams-cols {
            height: calc(100vh - 165px);
            width: 100%;

            .d-flex.w-100 {
                height: calc(100vh - 210px);

                .dkg-teams-box {
                    display: block;
                    background-color: #f2f2f2;
                    border: none;
                    border-radius: 5px;
                    font-size: 12px;
                    margin: 8px 15px;
                    width: 100%;

                    // &.dkg-first-box {
                    //     background-color: #89298D;

                    // }

                    // &.dkg-sec-box {
                    //     background-color: #1D4851;
                    // }

                    // &.dkg-three-box {
                    //     background-color: #01ADCF;
                    // }

                    .title {
                        background-color: transparent;
                        border: 1px solid transparent;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        color: #6c757d;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 700;
                        padding: 7px 10px;
                        text-transform: uppercase;
                        width: 100%;
                        font-size: 15px;
                    }

                    ul {
                        border-bottom: 0;
                        border-radius: 5px;

                        .dkg-content-con {
                            .dkg-content-title {
                                color: #6c757d;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                font-weight: normal;
                                padding: 7px 10px;
                                text-transform: capitalize;
                                width: 100%;
                                font-size: 14px;
                                background-color: #f2f2f2;
                                border: none;
                                font-weight: 700;
                                border-radius: 5px 5px 0 0;
                            }

                            .dkg-teams-tableCon {
                                // border-top: none;
                                // border-bottom: none;
                                background-color: #f2f2f2;
                                border: none;

                                &.teams-first-cols {
                                    background-color: #89298D;
                                }

                                &.teams-sconds-cols {
                                    background-color: #1D4851;
                                }

                                &.teams-thrid-cols {
                                    background-color: #01ADCF;
                                }

                                .dkg-teams-table {
                                    margin-bottom: 2rem;
                                    width: 100%;
                                    max-width: 90%;
                                    margin: 0 auto;

                                    tbody {
                                        tr {
                                            td {
                                                background-color: #fff;
                                                padding: 9px 7px;
                                                font-size: 13px;
                                                border: 1px solid #ddd;

                                                &:first-child {
                                                    // border-left: none;
                                                    width: 30%;
                                                    text-align: center;
                                                    font-weight: bold;
                                                    border-left: none;
                                                }

                                                &:last-child {
                                                    // border-right: none;
                                                    width: 70%;
                                                    border-right: none;
                                                }
                                            }

                                            &:first-child {
                                                td {
                                                    border-top: none;

                                                    &:first-child {
                                                        border-top-left-radius: 5px;
                                                    }

                                                    &:last-child {
                                                        border-top-right-radius: 5px;
                                                    }
                                                }
                                            }

                                            &:last-child {
                                                td {
                                                    border-bottom: none;

                                                    &:first-child {
                                                        border-bottom-left-radius: 5px;
                                                    }

                                                    &:last-child {
                                                        border-bottom-right-radius: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .dkg-subcontent-con {
                            .dkg-content-subtitle {
                                color: #6c757d;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                font-weight: 700;
                                padding: 12px 10px;
                                // padding-bottom: 6px;
                                text-transform: capitalize;
                                width: 100%;
                                font-size: 14px;
                                background-color: #f2f2f2;
                                // border: 1px solid #dadada;
                                border-bottom: none;
                            }

                            .dkg-teams-recuiterCon {
                                border: none;
                                background-color: #f2f2f2;

                                &.teams-first-cols {
                                    background-color: #89298D;
                                    height: calc(100vh - 538px);
                                    border-radius: 0 0 5px 5px;
                                }

                                &.teams-sconds-cols {
                                    background-color: #1D4851;
                                    height: calc(100vh - 538px);
                                    border-radius: 0 0 5px 5px;
                                }

                                &.teams-thrid-cols {
                                    background-color: #01ADCF;
                                    height: calc(100vh - 538px);
                                    border-radius: 0 0 5px 5px;
                                }

                                .dkg-recuiter-table {
                                    margin-bottom: 0;
                                    width: 100%;
                                    max-width: 90%;
                                    margin: 0 auto;

                                    thead {
                                        tr {
                                            th {
                                                background-color: #fff;
                                                padding: 9px 7px;
                                                font-size: 13px;
                                                // border: 1px solid #ddd;
                                                border: none;
                                                // font-weight: 700;

                                                &:first-child {
                                                    // border-left: none;
                                                    width: 30%;
                                                    text-align: center;
                                                }

                                                &:last-child {
                                                    // border-right: none;
                                                    width: 70%;
                                                }
                                            }
                                        }
                                    }

                                    tbody {
                                        tr {
                                            td {
                                                background-color: #fff;
                                                padding: 9px 7px;
                                                font-size: 13px;
                                                border: 1px solid #ddd;

                                                &:first-child {
                                                    // border-left: none;
                                                    width: 30%;
                                                    text-align: center;
                                                    font-weight: bold;
                                                    border-left: none;
                                                }

                                                &:last-child {
                                                    // border-right: none;
                                                    width: 70%;
                                                    border-right: none;
                                                }
                                            }

                                            &:first-child {
                                                td {
                                                    border-top: none;

                                                    &:first-child {
                                                        border-top-left-radius: 5px;
                                                    }

                                                    &:last-child {
                                                        border-top-right-radius: 5px;
                                                    }
                                                }
                                            }

                                            &:last-child {
                                                td {
                                                    border-bottom: none;

                                                    &:first-child {
                                                        border-bottom-left-radius: 5px;
                                                    }

                                                    &:last-child {
                                                        border-bottom-right-radius: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}