.dkg-payment-faq-pageCon {
    margin-top: 30px;
    position: relative;
    width: 100%;
    .dkg-faq-catgory-activeTitle{
        font-weight: 600;
        padding: 0;
        margin: 0;
        color: #2e97b6;
        font-size: 18px;
        width: 100%;
        text-align: center;
        position: absolute;
        top: -35px;
    }
    .dkg-candprescreen-mainRow {
        background-color: #eaeaea;
        border-top: 1px solid #ddd;
        margin: 0;

        .col-md-2.dkg-cand-prescreen-faq-tabCon {
            flex: 0 0 13.666667%;
            max-width: 13.666667%;

            .dkg-cand-prescreen-faq-tab-navpills {
                margin-bottom: 0;
                width: 100%;
                background-color: #c1c1c1;
                height: calc(100vh - 125px);

                .dkg-cand-prescreen-faq-tab-nav-item {
                    .dkg-cand-prescreen-faq-tab-navlink {
                        background-color: #c1c1c1;
                        border-bottom: 1px solid #ccc;
                        color: #333;
                        display: block;
                        font-family: Roboto, sans-serif !important;
                        font-size: 15px;
                        font-weight: 400;
                        padding: 11px 15px;
                        text-transform: inherit;
                        width: 100%;
                        border-radius: 0;

                        &.active {
                            background-color: #eaeaea;
                            color: #333;
                        }
                    }
                }
            }
        }

        .col-md-10.dkg-cand-prescreen-centre-tabCon {
            flex: 0 0 86.333333%;
            max-width: 86.333333%;

            .dkg-payment-faq-tab-mainCon {
                margin-top: 3.1rem !important;
                position: relative;
                width: 100%;

                .dkg-faq-catgory-activeTitle {
                    padding: inherit;
                    position: absolute;
                    top: -32px;
                    left: 60%;
                    transform: translateX(-60%);
                    color: #333;
                    font-weight: 600;
                    font-size: 17px;
                }
            }
        }

    }

    .dkg-payment-faq-tab-mainCon {
        .dkg-payment-faq-tabCon {
            @media (min-width: 768px) {
                &.col-md-2.dkg-faq-sideMenuCon-234 {
                    flex: 0 0 19.666667%;
                    max-width: 19.666667%;
                }
            }

            .dkg-payment-faq-tab-navpills {
                .dkg-payment-faq-tab-nav-item {
                    border-radius: 0;

                    &:last-child {
                        border-radius: 0 0 5px 5px;
                    }

                    .dkg-payment-faq-tab-navlink {
                        // margin: 0 10px 0 0;
                        padding: 10px 15px;
                        display: block;
                        cursor: pointer;
                        background: #24475f;
                        color: #fff;
                        border-radius: 0;
                        border-bottom: 1px solid #607f92 !important;
                        position: relative;
                        border: 0;
                        font-size: 14px;

                        &.active {
                            background: #1f3748;

                            &:before {
                                content: "";
                                width: 0;
                                height: 0;
                                border-bottom: 10px solid transparent;
                                border-top: 10px solid transparent;
                                border-left: 12px solid #1f3748;
                                position: absolute;
                                top: 12px;
                                right: -12px;
                            }
                        }

                        i {
                            font-size: 20px;
                            margin-right: 8px;
                            color: #ddd;
                        }
                    }

                    &:first-child {
                        .dkg-payment-faq-tab-navlink {
                            border-radius: 5px 5px 0 0;

                            &.active {
                                background: #1f3748;
                                border-radius: 5px 5px 0 0;
                            }

                        }
                    }

                    &:last-child {
                        .dkg-payment-faq-tab-navlink {
                            border-radius: 0 0 5px 5px;

                            &.active {
                                background: #1f3748;
                                border-radius: 0 0 5px 5px;
                            }

                        }
                    }
                }
            }

        }

        // .dkg-cand-prescreen-faq-tabCon {
        //     .dkg-cand-prescreen-faq-tab-navpills {
        //         .dkg-cand-prescreen-faq-tab-nav-item {
        //             border-radius: 0;

        //             &:last-child {
        //                 border-radius: 0 0 5px 5px;
        //             }

        //             .dkg-cand-prescreen-faq-tab-navlink {
        //                 margin: 0 10px 0 0;
        //                 padding: 10px 15px;
        //                 display: block;
        //                 cursor: pointer;
        //                 background: #24475f;
        //                 color: #fff;
        //                 border-radius: 0;
        //                 border-bottom: 1px solid #607f92 !important;
        //                 position: relative;
        //                 border: 0;

        //                 &.active {
        //                     background: #1f3748;

        //                     &:before {
        //                         content: "";
        //                         width: 0;
        //                         height: 0;
        //                         border-bottom: 10px solid transparent;
        //                         border-top: 10px solid transparent;
        //                         border-left: 12px solid #1f3748;
        //                         position: absolute;
        //                         top: 12px;
        //                         right: -12px;
        //                     }
        //                 }

        //                 i {
        //                     font-size: 20px;
        //                     margin-right: 8px;
        //                     color: #ddd;
        //                 }
        //             }

        //             &:first-child {
        //                 .dkg-cand-prescreen-faq-tab-navlink {
        //                     border-radius: 5px 5px 0 0;

        //                     &.active {
        //                         background: #1f3748;
        //                         border-radius: 5px 5px 0 0;
        //                     }

        //                 }
        //             }

        //             &:last-child {
        //                 .dkg-cand-prescreen-faq-tab-navlink {
        //                     border-radius: 0 0 5px 5px;

        //                     &.active {
        //                         background: #1f3748;
        //                         border-radius: 0 0 5px 5px;
        //                     }

        //                 }
        //             }
        //         }
        //     }

        // }

        .dkg-payment-faq-tabCon-10 {
            @media (min-width: 768px) {
                &.col-md-10.dkg-faq-rightMenuCon-234 {
                    flex: 0 0 80.333333%;
                    max-width: 80.333333%;
                }
            }

            .dkg-payment-faq-tabcontent {
                color: #1f3748;

                div {
                    color: #24475f;

                    .dkg-screen-faq-accodionCon {
                        color: #1f3748;
                        // border-bottom: 1px solid #4d5a6b;
                        border: inherit !important;

                        .dkg-screen-faq-accodion {
                            border: none !important;
                            border-bottom: 1px solid #4d5a6b !important;
                            background-color: #1f3748;
                            color: #fff;
                            border-bottom: 1px solid #4d5a6b;
                            min-height: 52px;
                            position: relative;

                            .MuiAccordionSummary-content {
                                p {
                                    color: #fff;
                                    font-size: 14px;
                                }
                            }

                            // .dkg-screen-faq-accodion-suMM {
                            //     background-color: #1f3748;
                            //     color: #fff;
                            //     border-bottom: 1px solid #4d5a6b;
                            //     min-height: 52px;

                            //     .MuiIconButton-root.MuiAccordionSummary-expandIcon {
                            //         .MuiIconButton-label {
                            //             color: #fff;

                            //         }
                            //     }
                            // }

                            // &:first-child {
                            //     .dkg-screen-faq-accodion-suMM {
                            //         border-radius: 5px 5px 0 0;

                            //         div {
                            //             p {
                            //                 font-size: 15px !important;
                            //             }
                            //         }
                            //     }
                            // }

                            // &:last-child {
                            //     .dkg-screen-faq-accodion-suMM {
                            //         border-radius: 0 0 5px 5px;
                            //     }
                            // }

                            // .MuiCollapse-root.MuiCollapse-entered {
                            //     .MuiCollapse-wrapper {
                            //         .MuiCollapse-wrapperInner {
                            //             div {
                            //                 .MuiAccordionDetails-root {
                            //                     background-color: #24475f;
                            //                     color: #fff;
                            //                 }
                            //             }
                            //         }
                            //     }
                            // }
                            .MuiAccordionSummary-content.Mui-expanded {
                                p.MuiTypography-root.MuiTypography-body1 {
                                    color: #fff;
                                    font-size: 14px;
                                    font-family: inherit;
                                }
                            }

                            .MuiAccordionSummary-expandIconWrapper.Mui-expanded,
                            .MuiAccordionSummary-expandIconWrapper {
                                position: absolute;
                                right: 10px;

                                .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
                                    color: #fff;
                                }
                            }
                        }

                        &:first-child {
                            .dkg-screen-faq-accodion {
                                border-radius: 5px 5px 0 0 !important;
                            }
                        }

                        &:last-child {
                            .dkg-screen-faq-accodion {
                                border-radius: 0 0 5px 5px;
                            }
                        }

                        .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
                            .MuiCollapse-wrapper.MuiCollapse-vertical {
                                .MuiCollapse-wrapperInner.MuiCollapse-vertical {
                                    .MuiAccordion-region {
                                        .MuiAccordionDetails-root {
                                            background-color: #24475f;
                                            border-radius: 0 0 5px 5px;

                                            p.MuiTypography-root.MuiTypography-body1 {
                                                color: #fff;
                                            }

                                            textarea.dkg-faq-answertextArea {
                                                border: none;
                                                outline: 0;
                                                box-shadow: none;
                                                color: #fff;
                                                background-color: #24475f !important;
                                                font-size: 14px;
                                                resize: none;
                                                border: none !important;
                                                width: 100% !important;
                                                cursor: text;
                                                caret-color: #24475f;

                                                // min-height: 180px;
                                                // max-height: 190px;
                                                // overflow-y: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}