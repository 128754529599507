.dk-registration-containerMain {
    margin-top: 123px;
    display: flex;

    .dk-registrationLeftpanel {
        background: #eaeaea;
        height: calc(100vh - 124px);
        margin-right: 0;
        margin-top: 0;
        max-width: 170px;
        overflow-y: auto;
        padding: 0 !important;
        width: 100%;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                width: 100%;

                &:last-child {
                    a {
                        border-bottom: none;
                    }
                }

                a {
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    color: #000;
                    font-size: 12px;
                    line-height: 15px;
                    margin: 0;
                    padding: 12px 12px;
                    text-transform: uppercase;
                    display: block;
                    font-weight: 400;
                    border-bottom: 1px solid #ccc;
                }

                a.active {
                    background: #374252;
                    color: #fff;
                }
            }
        }
    }

    .dk-registration-rightPanel {
        background: #374252;
        margin-top: 0;
        padding: 20px;
        width: 100%;

        .dk-registrationHead {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dk-searchCols {
                max-width: 160px;
                position: relative;
                width: 100%;

                input {
                    border: 1px solid #ccc;
                    height: 32px;
                    padding: 0 12px;
                    text-align: left;

                    &:focus::placeholder {
                        opacity: 0;
                    }
                }

                button {
                    background: transparent;
                    border: 1px solid #ddd;
                    color: #757575;
                    display: inline-block;
                    font-size: 13px;
                    height: 32px;
                    outline: none;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 0;
                    width: 32px;
                }
            }

            .title {
                color: #fff;
                font-size: 15px;
                font-weight: 600;
                padding: 11px 0;
                text-align: center;
                text-transform: uppercase;
            }

            .dk-searchIocn {
                margin-right: 8px;

                a {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    border: 1px solid #818181;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #818181;

                    &:hover {
                        background: #818181;
                        color: #374252;
                        transition: all 0.5s;
                    }
                }
            }

            .dk-registration-addNew {
                a {
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    color: #333;
                    display: inline-block;
                    padding: 5px 13px;

                    &:hover {
                        background: #396a95;
                        border-color: #8f8f8f;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
            }
        }

        .dk-registration-table {
            margin-top: 1px;
            margin-bottom: 0;
            height: calc(100vh - 255px);
            overflow-y: auto;

            table {
                border: none;
                margin-bottom: 0;

                tr {
                    th {
                        background: #396a95;
                        border: 1px solid #8f8f8f !important;
                        border-bottom: none;
                        color: #fff;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 10px 12px;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;
                        position: sticky;
                        top: 0;
                        z-index: 9999;
                    }

                    td {
                        background: #fff;
                        border: 1px solid #ddd;
                        font-size: 12px;
                        font-weight: 400;
                        padding: 5px 8px;
                        position: relative;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        .dk-smsTableCheckbox {
                            width: 45px;
                            margin: auto;

                            .dk-chkSendouts {
                                margin: 0;
                            }
                        }

                        .dk-financeAction {
                            a {
                                border-radius: 3px;
                                color: #fff;
                                display: inline-block;
                                height: 30px;
                                line-height: 30px;
                                margin: 0 3px;
                                text-align: center;
                                width: 30px;
                            }

                            a.viewBtn {
                                background: #ffb11c;
                            }

                            a.downloadBtn {
                                background: #3a9469;
                            }
                        }

                    }
                }
            }
        }
    }
}

.tooltipText {
    display: none;
    background: #333;
    border-radius: 3px;
    color: #fff;
    display: none;
    font-size: 11px;
    padding: 2px 8px;
    text-transform: capitalize;
    top: 42px;
    white-space: nowrap;
    z-index: 99;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);

    &::after {
        border-color: transparent transparent #333;
        _border-color: #000 #000 #333 #000;
        border-style: solid;
        border-width: 0 7.5px 12px;
        content: "";
        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
        height: 0;
        line-height: 0px;
        top: -11px;
        width: 0;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
}

.dk-tasksLeftpanel {
    background: #eaeaea;
    height: calc(100vh - 166px);
    margin-right: 0;
    margin-top: 0;
    max-width: 170px;
    overflow-y: auto;
    padding: 0 !important;
    width: 100%;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            width: 100%;

            a {
                background-color: transparent;
                border: 0;
                border-radius: 0;
                color: #000;
                font-size: 12px;
                line-height: 15px;
                margin: 0;
                padding: 12px 12px;
                text-transform: uppercase;
                display: block;
                font-weight: 400;
                border-bottom: 1px solid #ccc;
            }

            a.active {
                background: #374252;
                color: #fff;
            }
        }
    }
}

.dk-yearsAddNewTask-modal.dk-addNewDkgStaffModal {
    .modal-dialog {
        .modal-content {
            .modal-body {
                .form-group {
                    label {
                        &:first-child {
                            min-width: 195px;
                            max-width: 195px;
                        }

                        .MuiGrid-container {
                            width: 100%;

                            .MuiFormControl-root {
                                background: #fff;
                                border: none;
                                margin: 0;
                                width: 100%;

                                .MuiInputBase-root {
                                    padding-left: 10px;
                                    width: 100%;

                                    &::before {
                                        display: none !important;
                                    }

                                    &::after {
                                        display: none !important;
                                    }

                                    input {
                                        padding: 1px 0;
                                        text-align: center;
                                    }

                                    .MuiInputAdornment-root {
                                        margin-left: 0;
                                        position: absolute;
                                        right: 0;

                                        button {
                                            outline: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-sendoutsSearch-modal.dk-dkgLegalSearchModal {
    .modal-dialog {
        max-width: 500px;
        min-width: 500px;

        .modal-content {
            .modal-body {
                .dk-sendoutFilterTabs-main.dk-legalSearch-modalBody {
                    .dk-sendoutTabs-nav {
                        max-width: 220px;
                        min-width: 220px;

                        .nav-item {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}