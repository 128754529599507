.dkg-hrdashboard-pageCon{
    .dkg-hrtopboxCon{
        margin-bottom: 25px;
        .dkg-hrtopbox-Header{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 5px 5px 0 0;
            background-color: #eaeaea;
            border-bottom: 1px solid #bdbcbc;
            .dkg-hrtopbox-Title{
                font-size: 16px;
                padding: 8px 10px;
                color: #716767;
                font-weight: 600;
                position: relative;
            }
        }
        .dkg-hrtopbox-Body{
            background-color: #eaeaea;
            border-radius: 0 0 5px 5px;
            min-height: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            h5.dk-hrtopbox-title{
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
    .dkg-hrbottomboxCon{
        .dkg-hrbottombox-Header{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 5px 5px 0 0;
            background-color: #eaeaea;
            border-bottom: 1px solid #bdbcbc;
            .dkg-hrbottombox-Title{
                font-size: 16px;
                padding: 8px 10px;
                color: #716767;
                font-weight: 600;
                position: relative;
            }
        }
        .dkg-hrbottombox-Body{
            background-color: #eaeaea;
            border-radius: 0 0 5px 5px;
            min-height: calc(100vh - 440px);
            display: flex;
            justify-content: center;
            align-items: center;
            h5.dk-hrbottombox-title{
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}