.dkg-recuiterskpiMainCon {
    // background-color: #374252;
    margin-top: 143px;
    padding: 0;

    .dkg-recuiters-tabsCon {
        .dk-recuiters-kpi-Leftpanel {
            ul {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 0;
                width: 100%;
                list-style: none;
                padding: 0;
                margin: auto;
                max-width: 1600px;

                li {
                    max-width: 9.99%;
                    width: 100%;
                    list-style-type: none;
                    display: inline-block;
                    width: 100%;
                    max-width: 9.9999% !important;
                    height: 44px;
                    line-height: 44px;
                    text-align: center;

                    a {
                        // border-radius: 0;
                        // color: #333;
                        // font-size: 14px;
                        // padding: 0.65rem 1rem;
                        // text-align: center;
                        // text-transform: capitalize;
                        // width: 100%;
                        display: inline-block;
                        width: 100%;
                        color: #333;
                        text-align: center;
                        font-weight: 400;
                        letter-spacing: 0.3px;
                        font-size: 14px;
                        font-family: Verdana, sans-serif;
                        transition: all 0.5s;
                        height: 44px;
                        line-height: 44px;
                        white-space: nowrap;

                        &.active {
                            background-color: #fff;
                            color: #3c97b6;
                            position: relative;
                            font-weight: 600;

                            &:after {
                                background: #3c97b6;
                                bottom: 5px;
                                content: "";
                                height: 2px;
                                left: 30px;
                                position: absolute;
                                width: 60%;
                            }
                        }
                    }
                }

            }
        }
    }

    .dkg-recuiters-tableCon-234 {
        background-color: #374252;

        .dk-sendouts-rightPanel {
            padding: 0 !important;

            .dk-sendoutsyears-containerMain {
                margin-top: 0 !important;

                .dk-sendoutsyears-rightPanel {
                    padding: 0 15px;

                    .dk-sendoutsHead {
                        .dk-sendoutsyears-yearsDropdown {
                            .dk-searchBox {
                                input {
                                    background-color: #3C4C62;
                                    border: 1px solid #808080;
                                }

                                button {
                                    border-left: 1px solid #808080;
                                }
                            }

                            .dk-sendoutsAmRc-dropdown {
                                div {
                                    div {
                                        .dropdown {
                                            .dropdown-toggle {
                                                background-color: #3C4C62 !important;
                                                border: 1px solid #808080 !important;
                                                color: #848484 !important;
                                            }
                                        }
                                    }
                                }
                            }

                            .addMonthBtn {
                                background-color: #3C4C62;
                                border: 1px solid #808080;
                                color: #848484 !important;

                                &:hover {
                                    color: #848484 !important;
                                }
                            }
                        }

                        .title {
                            color: #d9d9d9;
                        }

                        .dk-sendoutyears-rightPanel-btn {
                            .dk-sensdouts-searchCols {
                                .dk-cricleBtn {
                                    background-color: #3C4C62 !important;
                                    border: 1px solid #808080 !important;

                                    &:hover {
                                        color: #848484 !important;
                                    }
                                }
                            }

                            .dk-sensdouts-searchCols {
                                button {
                                    background-color: #3C4C62 !important;
                                    border: 1px solid #808080 !important;

                                    &:hover {
                                        color: #848484 !important;
                                    }
                                }
                            }

                            .dk-categoryfilterCon {
                                div {
                                    .dropdown {
                                        .dropdown-toggle {
                                            background-color: #3C4C62 !important;
                                            border: 1px solid #808080 !important;
                                            color: #848484 !important;
                                        }
                                    }
                                }
                            }

                        }
                    }

                    .dk-sendoutsyears-table {
                        height: calc(100vh - 281px) !important;

                        .table {
                            thead {
                                tr {
                                    th {
                                        color: #b9b5b5 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dk-kpiCase-rightPanel {
                background: #374252;
                margin-top: 0;
                padding: 12px 0px;
                width: 100%;

                .dk-kpiCaseHead {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 18px;
                    margin-bottom: 12px;

                    .dk-sendoutCaseSt-rightPanel {
                        display: flex;
                        align-items: center;

                        button.dk-filterBtn {
                            border: 1px solid #7d7d87;
                            background: transparent;
                            margin-right: 10px;
                            border-radius: 50%;
                            color: #7d7d87;
                            display: inline-block;
                            font-size: 15px;
                            height: 35px;
                            line-height: 35px;
                            margin-left: 12px;
                            text-align: center;
                            width: 35px;
                            min-width: 35px;
                            outline: none;

                            &:hover {
                                background: #7d7d87;
                                transition: all 0.5s;
                                color: #374252;
                            }
                        }

                        .dropdown {
                            .dropdown-toggle {
                                margin: 0;
                                border-radius: 3px;
                                background: #2e75b6 !important;
                                color: #fff !important;
                                font-size: 13px;
                                box-shadow: none;
                                font-family: "Roboto", sans-serif !important;
                                padding: 7px 10px;
                                min-width: 110px;

                                &::after {
                                    display: none;
                                }
                            }

                            .dropdown-menu {
                                transform: inherit !important;
                                min-width: 110px;
                                border-radius: 0;
                                background: #fff;
                                border: none;
                                padding: 0;
                                margin: 35px 0 0 !important;
                                box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
                                z-index: 99999;

                                a {
                                    padding: 6px 10px;
                                    font-size: 13px;
                                }

                                a.active {
                                    font-size: 14px;
                                    background: #2e75b6;
                                }
                            }
                        }
                    }

                    .dk-kpiCase-yearsDropdown {
                        display: flex;
                        align-items: center;

                        .dk-searchBox {
                            max-width: 190px;
                        }

                        .addMonthBtn {
                            border: 1px solid #7d7d87;
                            border-radius: 50%;
                            color: #7d7d87;
                            display: inline-block;
                            font-size: 15px;
                            height: 35px;
                            line-height: 35px;
                            margin-left: 12px;
                            text-align: center;
                            width: 35px;
                            min-width: 35px;

                            &:hover {
                                background: #7d7d87;
                                transition: all 0.5s;
                                color: #374252;
                            }
                        }

                        .dropdown-toggle {
                            background: #3c97b6 !important;
                            border-radius: 3px;
                            box-shadow: none;
                            color: #fff !important;
                            font-size: 13px;
                            margin: 0;
                            min-width: 110px;
                            padding: 7px 10px;
                            z-index: 99999;

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            background: #fff;
                            border: none;
                            border-radius: 0;
                            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                            margin: 35px 0 0 !important;
                            min-width: 110px;
                            padding: 0;
                            -webkit-transform: inherit !important;
                            transform: inherit !important;
                            z-index: 99999;

                            a {
                                padding: 6px 10px;
                                font-size: 13px;

                                &:hover {
                                    background: #f8f9fa;
                                }
                            }

                            a.active {
                                background: #3c97b6;
                            }
                        }
                    }

                    .dk-kpiCase-months {
                        display: flex;
                        justify-content: flex-end;
                        margin-left: 0;
                        width: 100%;

                        ul {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            justify-content: flex-end;
                            width: 87%;

                            li {
                                display: inline-block;
                                list-style-type: none;
                                width: 100%;
                                min-width: 8.33%;
                                max-width: 8.33%;

                                &:first-child {
                                    a {
                                        border-left: 1px solid #616167;
                                    }
                                }

                                a {
                                    background: #4a5057;
                                    border-bottom: 1px solid #616167;
                                    border-right: 1px solid #616167;
                                    border-top: 1px solid #616167;
                                    color: #fff;
                                    cursor: pointer;
                                    float: left;
                                    height: 35px;
                                    padding: 7px 10px;
                                    text-align: center;
                                    width: 100%;
                                }

                                a.active {
                                    background: #3c97b6;
                                }

                            }
                        }

                        .addMonthBtn {
                            border: 1px solid #7d7d87;
                            border-radius: 50%;
                            color: #7d7d87;
                            display: inline-block;
                            font-size: 15px;
                            height: 35px;
                            line-height: 35px;
                            margin-left: 12px;
                            text-align: center;
                            width: 35px;

                            &:hover {
                                background: #7d7d87;
                                color: #fff;
                                transition: all .5s;
                            }
                        }
                    }
                }

                .dk-kpiCaseSummary-table {
                    margin-top: 8px;
                    margin-bottom: 0;
                    height: calc(100vh - 272px);
                    overflow-y: auto;

                    table {
                        border: none;
                        margin-bottom: 0;

                        tr {
                            th {
                                background: #3c97b6 !important;
                                border: 1px solid #a0a0a0 !important;
                                border-bottom: none;
                                color: #fff;
                                font-size: 13px;
                                font-weight: 400;
                                padding: 10px 12px;
                                text-align: center;
                                vertical-align: middle;
                                white-space: nowrap;
                                position: sticky;
                                top: 0;
                                z-index: 9999;

                                &:nth-child(02),
                                &:nth-child(03),
                                &:nth-child(04),
                                &:nth-child(05),
                                &:nth-child(06),
                                &:nth-child(07),
                                &:nth-child(08),
                                &:nth-child(09),
                                &:nth-child(10),
                                &:nth-child(11),
                                &:nth-child(12) {
                                    width: 8.5%;
                                }
                            }

                            td.cursor-p {
                                cursor: pointer;
                            }

                            td {
                                background: #fff;
                                border: 0.5px solid #ddd;
                                font-size: 13px;
                                font-weight: 400;
                                padding: 5px 8px;
                                position: relative;
                                text-align: center;
                                vertical-align: middle;
                                white-space: nowrap;

                                &:nth-child(02),
                                &:nth-child(03),
                                &:nth-child(04),
                                &:nth-child(05),
                                &:nth-child(06),
                                &:nth-child(07),
                                &:nth-child(08),
                                &:nth-child(09),
                                &:nth-child(10),
                                &:nth-child(11),
                                &:nth-child(12) {
                                    width: 8.5%;
                                }

                                &.dkg-assigned-tdbg {
                                    background-color: #5c737d;
                                    color: #fff;
                                    border: 1px solid #989898;
                                }

                                &.dkg-qualified-tdbg {
                                    background-color: #26596d;
                                    color: #fff;
                                    border: 1px solid #989898;
                                }

                                &.dkg-jobspecs-tdbg {
                                    background-color: #2d9b99;
                                    color: #fff;
                                    border: 1px solid #989898;
                                }

                                &.dkg-sendouts-tdbg {
                                    background-color: #316a67;
                                    color: #fff;
                                    border: 1px solid #989898;
                                }

                                &.dkg-jooffers-tdbg {
                                    background-color: #629bd3;
                                    color: #fff;
                                    border: 1px solid #989898;
                                }

                                &.dkg-refused-tdbg {
                                    background-color: #a84d4d;
                                    color: #fff;
                                    border: 1px solid #989898;
                                }

                                &.dkg-joined-tdbg {
                                    background-color: #4768b0;
                                    color: #fff;
                                    border: 1px solid #989898;
                                }

                                &.dkg-credit-tdbg {
                                    background-color: #da3d5e;
                                    color: #fff;
                                    border: 1px solid #989898;
                                }

                                .dk-smsTableCheckbox {
                                    .dk-chkSendouts {
                                        margin: 0 3px;
                                    }
                                }

                                .dk-text-red {
                                    color: #ff3547;
                                }

                                .dk-text-green {
                                    color: #00c851;
                                }


                                input.dk-chkSendouts {
                                    -webkit-appearance: none;
                                    appearance: none;
                                    background: #e4e8ec;
                                    border: 1px solid #ccc;
                                    border-radius: 0;
                                    box-sizing: border-box;
                                    box-sizing: content-box;
                                    cursor: pointer;
                                    height: 12px;
                                    position: relative;
                                    transition: all .3s linear;
                                    width: 12px;
                                }

                                .dk-sales-updatedUser {
                                    position: relative;

                                    img {
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 50%;
                                    }

                                    &:hover {
                                        .tooltipText {
                                            display: inline-block;
                                        }
                                    }
                                }

                                .dk-salesSendouts-users {
                                    .dropdown-toggle {
                                        background-color: transparent !important;
                                        border-radius: 50%;
                                        box-shadow: none !important;
                                        color: #333;
                                        margin: 0;
                                        padding: 0;

                                        &::after {
                                            display: none;
                                        }

                                        img {
                                            border-radius: 50%;
                                            left: 0;
                                            position: relative;
                                            top: 0;
                                            width: 35px;
                                        }
                                    }

                                    .dropdown-menu {
                                        background: #f2f2f2;
                                        border-radius: 3px;
                                        left: 85px !important;
                                        min-width: -webkit-fit-content;
                                        min-width: -moz-fit-content;
                                        min-width: fit-content;
                                        padding: 8px 5px;
                                        right: 0 !important;
                                        top: 0 !important;
                                        -webkit-transform: inherit !important;
                                        transform: inherit !important;
                                        width: -webkit-fit-content;
                                        width: -moz-fit-content;
                                        width: fit-content;

                                        &::before {
                                            border-color: transparent #ccc transparent transparent;
                                            _border-color: #000 #ccc #000 #000;
                                            border-style: solid;
                                            border-width: 8.5px 12px 8.5px 0;
                                            content: "";
                                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                            height: 0;
                                            left: -12px;
                                            line-height: 0;
                                            position: absolute;
                                            top: 8px;
                                            width: 0;
                                        }

                                        a {
                                            display: inline-block;
                                            margin: -5px 3px;
                                            padding: 0;
                                            width: 30px;
                                            background-color: transparent;
                                            border: 0;
                                            clear: both;
                                            font-weight: 400;
                                            text-align: inherit;
                                            white-space: nowrap;

                                            img {
                                                border-radius: 50%;
                                                height: 30px;
                                                width: 30px;
                                            }
                                        }

                                    }
                                }
                            }
                        }

                        tfoot.dkg-recuiters-footer-231 {

                            .sumtr {
                                td.transparent {
                                    border: none;
                                }

                                td {
                                    background: transparent;
                                    color: #fff;
                                    padding: 11px 8px;
                                    border-color: #a9a9a9;
                                }
                            }

                            td.transparent {
                                border: none;
                            }

                            td {
                                background: transparent;
                                color: #fff;
                                padding: 11px 8px;
                                font-weight: 600;
                                border: 1px solid #989898;
                            }
                        }
                    }
                }

            }

            .dk-kpiCase-rightPanel.dk-assignedBg {
                .dk-kpiCaseHead {
                    .dk-kpiCase-yearsDropdown {
                        .dropdown {
                            .dropdown-toggle {
                                background: #3c97b6 !important;
                            }
                        }
                    }

                    .dk-kpiCase-months {
                        ul {
                            li {
                                a.active {
                                    background: #3c97b6;
                                }
                            }
                        }
                    }
                }

                .dk-kpiCaseSummary-table {
                    table {
                        tr {
                            th {
                                background: #3c97b6 !important;
                            }
                        }
                    }
                }
            }
        }
    }

}

.dk-sendouts-containerMain.dk-jobSpecsContainer-main.dkg-new-recuiterskpi-pagecon89 {
    margin-top: 142px !important;
    flex: 0 0 100%;
    max-width: 100%;

    .dk-tasksLeftpanel {
        flex: 0 0 10%;
        max-width: 10%;
    }

    .dk-sendouts-rightPanel {
        padding-bottom: 0 !important;
        flex: 0 0 90%;
        max-width: 90%;

        &.dkg-recuiters-suMM-rightpanel {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .dk-recuiters-kpi-Leftpanel {
        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            width: 100%;
            list-style: none;
            padding: 0;
            margin: auto;
            max-width: 1600px;

            li {
                max-width: 9.99%;
                width: 100%;
                list-style-type: none;
                display: inline-block;
                width: 100%;
                max-width: 9.9999% !important;
                height: 44px;
                line-height: 44px;
                text-align: center;

                a {
                    // border-radius: 0;
                    // color: #333;
                    // font-size: 14px;
                    // padding: 0.65rem 1rem;
                    // text-align: center;
                    // text-transform: capitalize;
                    // width: 100%;
                    display: inline-block;
                    width: 100%;
                    color: #333;
                    text-align: center;
                    font-weight: 400;
                    letter-spacing: 0.3px;
                    font-size: 14px;
                    font-family: Verdana, sans-serif;
                    transition: all 0.5s;
                    height: 44px;
                    line-height: 44px;
                    white-space: nowrap;

                    &.active {
                        background-color: #374252;
                        color: #fff;
                    }
                }
            }

        }
    }

}

.dkg-new-recuiterskpi-main89 {
    .dkg-new-recuiterskpi-con89 {
        padding-right: 5px;

        .dk-sendoutsyears-containerMain {
            // margin-right: 110px !important;
            margin-top: 0 !important;

            .dk-sendoutsyears-rightPanel {
                padding: 0 !important;

                .dk-sendoutsHead {
                    margin-top: 0 !important;

                    .dk-sendoutsyears-yearsDropdown {
                        .dk-searchBox {
                            input {
                                background-color: #3C4C62;
                                border: 1px solid #808080;
                            }

                            button {
                                border-left: 1px solid #808080;
                            }
                        }

                        .dk-sendoutsAmRc-dropdown {
                            div {
                                div {
                                    .dropdown {
                                        .dropdown-toggle {
                                            background-color: #3C4C62 !important;
                                            border: 1px solid #808080 !important;
                                            color: #848484 !important;
                                        }
                                    }
                                }
                            }
                        }

                        .addMonthBtn {
                            background-color: #3C4C62;
                            border: 1px solid #808080;
                            color: #848484 !important;

                            &:hover {
                                color: #848484 !important;
                            }
                        }
                    }

                    .title {
                        color: #d9d9d9;
                    }

                    .dk-sendoutyears-rightPanel-btn {
                        .dk-sensdouts-searchCols {
                            .dk-cricleBtn {
                                background-color: #3C4C62 !important;
                                border: 1px solid #808080 !important;

                                &:hover {
                                    color: #848484 !important;
                                }
                            }
                        }

                        .dk-sensdouts-searchCols {
                            button {
                                background-color: #3C4C62 !important;
                                border: 1px solid #808080 !important;

                                &:hover {
                                    color: #848484 !important;
                                }
                            }
                        }

                        .dk-categoryfilterCon {
                            div {
                                .dropdown {
                                    .dropdown-toggle {
                                        background-color: #3C4C62 !important;
                                        border: 1px solid #808080 !important;
                                        color: #848484 !important;
                                    }
                                }
                            }
                        }

                    }
                }

                .dk-sendoutsyears-table {
                    height: calc(100vh - 240px) !important;

                    .table {
                        thead {
                            tr {
                                th {
                                    color: #b9b5b5 !important;
                                }
                            }
                        }
                    }

                    .dk-resPaginationCon.pagination {
                        .dk-resPagination {
                            .dk-resLeftPag {
                                padding-right: 10.4%;

                                .dk-texCounter {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}