.dkg-recutierdb-pageCon {
    margin-top: 160px;

    .dk-recutierdb-head {
        .dk-recutierdbLeftCon {
            align-items: center;
            display: flex;

            .dk-dkgHead-seach {
                max-width: 150px;
                position: relative;
                width: 100%;

                .input-group {
                    input {
                        border: 1px solid #ddd !important;
                        border-radius: 0;
                        padding: 0 30px 0 10px;
                        border-right: none;

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }
                    }

                    .input-group-prepend {
                        .input-group-text {
                            border-left: none;
                        }
                    }
                }
            }

            .dk-resuserFilterCon {
                .dkg-user-db-dropdown-24 {
                    .dkg-user-db-toggle-24 {
                        background: #eaeaea !important;
                        color: #333;
                        border: 1px solid #ddd !important;
                        border-radius: 50%;
                        box-shadow: none !important;
                        font-size: 15px;
                        height: 35px;
                        margin: 0;
                        outline: none;
                        padding: 0;
                        width: 35px;

                        i {
                            font-size: 15px;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .dkg-user-db-menu-24 {
                        background: #f2f2f2;
                        left: 45px !important;
                        min-width: auto;
                        padding: 5px 8px;
                        right: inherit !important;
                        top: -5px !important;
                        -webkit-transform: inherit !important;
                        transform: inherit !important;
                        display: flex;

                        &:before {
                            border-color: transparent #ccc transparent transparent;
                            border-style: solid;
                            border-width: 7.5px 10px 7.5px 0;
                            content: "";
                            height: 0;
                            left: -10px;
                            line-height: 0;
                            position: absolute;
                            top: 16px;
                            width: 0;
                        }

                        .dropdown-item.dkg-user-db-item {
                            display: inline-block;
                            margin: 0 3px;
                            padding: 0;

                            img.recruiter_img {
                                border-radius: 50%;
                                height: 35px;
                                max-width: 35px;
                                width: 35px;

                                &:hover {
                                    border-radius: 50%;
                                    height: 35px;
                                    max-width: 35px;
                                    width: 35px;
                                }
                            }
                        }
                    }
                }
            }

            .mailshotIcon {
                a {
                    align-items: center;
                    background: #eaeaea;
                    border-color: #eaeaea;
                    border-radius: 50%;
                    color: #333;
                    display: flex;
                    height: 35px;
                    justify-content: center;
                    width: 35px;
                }
            }
        }

        .dkg-recutierdb-title {
            font-size: 18px;
            font-weight: 700;
            left: 50%;
            margin: 0;
            position: absolute;
            text-align: center;
            top: 8px;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            text-transform: uppercase;
        }

        .dk-recutierdbRightCon {
            a {
                background: #f2f2f2;
                border: 1px solid #ccc;
                border-radius: 3px;
                color: #333;
                display: inline-block;
                outline: 0;
                padding: 6px 17px;
            }
        }
    }

    .dkg-recutierdbTableCon {
        margin-top: 0.5rem;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh - 237px);
        padding-bottom: 0;
        position: relative;

        .dkg-recutierdbTable {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        background: #26597d;
                        border: 1px solid #4483af;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                        padding: 10px;
                        text-align: center;
                        white-space: nowrap;
                        border-bottom: none;

                    }
                }
            }

            tbody {
                tr {
                    td {
                        background: #eaeaea;
                        color: #333;
                        font-size: 14px;
                        padding: 12px 10px;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        .dkg-rec-db-dropdown-24 {
                            .dkg-rec-db-toggle-24 {
                                background: #eaeaea !important;
                                color: #333;
                                box-shadow: none !important;
                                font-size: 15px;
                                height: 35px;
                                margin: 0;
                                outline: none;
                                padding: 0;
                                width: 35px;

                                img {
                                    width: 100%;
                                    height: auto;
                                    border-radius: 50%;
                                    border: 1px solid #ddd !important;

                                }

                                &::after {
                                    display: none;
                                }
                            }
                        }

                        .dkg-rec-db-imgCon-24 {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                background: #eaeaea !important;
                                color: #333;
                                box-shadow: none !important;
                                font-size: 15px;
                                height: 35px;
                                margin: 0;
                                outline: none;
                                padding: 0;
                                width: 35px;
                                border-radius: 50%;
                                border: 1px solid #ddd !important;
                            }
                        }

                        .dkg-db-candidateName {
                            color: #26597d !important;
                            font-weight: 700;
                            text-shadow: 0 0 10px #fbcf53;
                        }

                        .dkg-mailchipCon {
                            img {
                                cursor: pointer;
                                width: 25px;
                                height: auto;
                            }
                        }

                        .dkg-dbaction-cols {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            a {
                                align-items: center;
                                border-radius: 3px;
                                color: #fff;
                                display: flex;
                                font-size: 14px;
                                height: 30px;
                                justify-content: center;
                                line-height: 30px;
                                margin: 0 3px !important;
                                outline: none;
                                width: 30px;

                                &.dk-upload {
                                    background: #ccc;
                                    color: #333;
                                }

                                &.dk-edit {
                                    background: #2d7ee2 !important;
                                    color: #fff !important;
                                }

                                &.dk-deleteBtn {
                                    background-color: #dc3545 !important;
                                    color: #fff !important;
                                }

                                &.dk-report {
                                    background: #fbcf53;
                                    color: #333;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}