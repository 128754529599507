.dk-legalModule-main {
    display: flex;
    align-items: center;
    margin-top: 100px;

    .dk-legalModule-container {
        width: 100%;
        max-width: 765px;
        margin: auto;
        margin-top: 60px;

        h3.title {
            color: #333;
            display: block;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
            text-transform: uppercase;
        }

        .dk-legalBox {
            align-items: center;
            display: flex;
            min-height: 125px;
            justify-content: center;
            width: 100%;
            margin: 0 15px 25px;

            a {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                color: #fff;
                text-decoration: none;

                .dk-legalTitle {
                    color: #fff;
                    display: block;
                    font-size: 17px;
                    font-weight: 400;
                    text-align: center;
                    text-transform: capitalize;
                }

                .dk-Portalicons {
                    text-align: center;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        max-width: 40px;
                        transition: all 0.5s;
                        transform: scale(1);
                    }
                }
            }
        }
    }
}