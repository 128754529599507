.dk-summaryTrainingMain{
    margin-top: 140px;
    .col-md-3{
        flex: 20%;
        max-width: 20%;
    }
    .dk-trainingMainCols{
        width: 100%;
        max-width: 1220px;
        margin: auto;
        h3.title{
            font-size: 22px;
            text-transform: uppercase;
            font-weight: 700;
            margin:0 0 18px;
            padding-top:18px;
            text-align: center;
            color: #505050;
        }
        .os-box{
            border-radius: 5px;
            padding:10px 10px;
            margin: 0 0 30px;
            cursor: pointer;
            transition: all 0.5s;            
            a{
                color: #fff;
                display: block;
                text-align: center;
                padding: 15px 0;
                cursor: pointer;
                h3{
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    font-weight:500;
                    color: #fff;
                }
                figure{
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 0;
                    img{
                        transform:scale(1);
                        -webkit-transition: all .4s ease-in-out;
                        max-width:90px;
                        max-width:45px;
                        max-height:45px;
                    }
                }
                &:hover{
                    figure{
                        img{
                            transform: scale(0.8);
                            transition: all .4s ease-in-out;
                        }
                    }
                }
            }
            
        }
    }
    
}

.dk-summaryTrainingMain.dk-summaryTrainingContainer{
    .dk-trainingMainCols{
        max-width: 1000px;
        .col-md-3{
            flex:25%;
            max-width:25%;
        }
    }
}

.dk-jobCheckDetailModal.modal.right{
    .modal-dialog{
        .modal-content{
            .modal-header{
                .close{
                    &:hover{
                        background: transparent !important;
                    }
                }
            }
        }
    }
}