.dkg-vistor-login-logs-con{
    margin-top: 125px;
    padding: 0 15px;
    width: 100%;
    .dkg-myreferals-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
        .dkg-myreferals-headerleftCon{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .dk-mailshotsSearch{
                .input-group{
                    max-width: 145px;
                    min-width: 145px;
                    width: 100%;
                    input{
                        background-color: #fff!important;
                        border: 1px solid #ddd;
                        border-radius: 0;
                        box-shadow: none;
                        height: 35px;
                        line-height: 1.3rem;
                        margin: 0;
                        padding: 0.5rem 0.55rem;
                        text-align: left;
                    }
                    .input-group-append{
                        button{
                            background-color: #fff!important;
                            border: 1px solid #ddd;
                            border-radius: 0;
                            box-shadow: none;
                            height: 35px;
                            line-height: 1.3rem;
                            margin: 0;
                            padding: 0.5rem 0.55rem;
                            i{
                                color: #6c757d;
                            }
                        }
                    }
                }
            }
            .dkg-refresh-btn{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50%;
                box-shadow: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
            .dkg-referal-addnew-btnCon{
                .dkg-referal-addnew-btn{
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    color: #848484;
                    display: inline-block;
                    font-size: 12px;
                    height: 33px;
                    line-height: 10px;
                    margin-left: 10px;
                    outline: none;
                    text-align: center;
                    width: 33px;
                }
            }
        }
        .dkg-myreferals-centreCon{
            .dkg-docs-title{
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                color: #6c757d;
                display: flex;
                align-items: center;

                // .dkg-infoicon{
                //     color: #6c757d;
                //     width: 24px;
                //     height: 24px;
                //     cursor: pointer;
                // }
            }
        }
        .dkg-myreferals-rightCon{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dkg-refresh-btn{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 50%;
                box-shadow: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
            .dkg-add-new-btn{
               background-color: #fff;
                border-radius: 0;
                color: #6c757d;
                display: inline-block;
                font-family: Roboto,sans-serif;
                font-size: 14px;
                padding: 8px 15px;
                text-decoration: none;
                white-space: nowrap;
                svg{
                    color: #6c757d;
                }
            }
            .dkg-referal-status-drpdwn{
                
                .dkg-referal-status-drpdwn-toggle{
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid #ddd;
                    padding: 6px 10px;
                    text-transform: capitalize;
                    font-size: 13px;
                    background-color: #fff !important;
                    color: #6c757d;
                    border-radius: 3px;
                    min-width: 120px;
                    position: relative;
                    &::after {
                        position: absolute;
                        top: 15px;
                        right: 10px;
                        color: #6c757d;
                    }
                }
                .dkg-referal-status-drpdwn-menu{
                    border-radius: 0;
                    padding: 0;
                    z-index: 99999;
                    .dkg-referal-status-item{
                        padding: 8px 10px;
                        border-bottom: 1px solid transparent;
                        font-size: 13px;
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        .dkg-lv-statuscounter {
                            position: absolute;
                            right: 8px;
                            color: #fff;
                            background-color: #234158;
                            padding: 1px 5px;
                            font-size: 12px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
            .dkg-cand-logs-filtercon{
                display: flex;
                align-items: center;
                .dkg-candlogs-filter-icon{
                    align-items: center;
                    background: #f2f2f2 !important;
                    border: 1px solid #ddd;
                    border-right: none;
                    box-shadow: none;
                    color: #6c757d;
                    display: flex;
                    height: 34px;
                    justify-content: center;
                    outline: none;
                    width: 32px;
                    i{
                        color: #6c757d;
                    }
                }
                .dk-refreral-filter-btn{
                    align-items: center;
                    background: #f2f2f2 !important;
                    border: 1px solid #ddd;
                    box-shadow: none;
                    color: #6c757d;
                    display: flex;
                    height: 34px;
                    justify-content: center;
                    outline: none;
                    width: 80px;
                }
            }
        }
    }
    .dkg-vistor-login-logs-tableCon{
        margin-top: 6px;
        position: relative;
        height: calc(100vh - 215px);
        overflow-y: auto;
        overflow-x: auto;
        .dkg-vistor-login-logs-table{
            thead{
                tr{
                    th{
                        background-color: #26597d;
                        border: 1px solid #ddd;
                        border-bottom: none;
                        color: #fff;
                        font-size: 13px;
                        padding: 9px 7px;
                        position: -webkit-sticky;
                        position: sticky;
                        text-align: center;
                        top: 0;
                        vertical-align: middle;
                        white-space: nowrap;
                        z-index: 9;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        background-color: #fff;
                        border: 1px solid #ddd;
                        color: #4d4a4a;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 9px 7px !important;
                        position: relative;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;
                        .dkg-jobseeker-action-cols{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .dkg-actions-item{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 22px;
                                height: 22px;
                                cursor: pointer;
                                i{
                                    font-size: 0.85rem;
                                    color: #696666;
                                }
                                &:hover{
                                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                    transition: all 0.5s ease;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dk-resPaginationCon {
        background-color: rgba(0, 0, 0, 0) !important;
        border-radius: 0 !important;
        bottom: 10px;
        left: 0;
        padding: 0 15px;
        position: fixed;
        z-index: 999;
        width: 100%;
        .dk-resPagination {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
            .dk-resLeftPag{
                .dk-texCounter {
                    color: #333 !important;
                    font-size: 13px;
                }
            }
            .dk-resrightPag {
                nav{
                    ul {
                        display: flex;
                        list-style: none;
                        margin-bottom: 0;
                        margin-top: 0 !important;
                        li {
                            background-color: #fff;
                            border: 0 !important;
                            border-radius: 0 !important;
                            margin: 0 !important;
                            min-width: auto !important;
                            width: auto !important;
                            display: inline-block;
                            height: 30px;
                            line-height: 26px;
                            text-align: center;
                            button.MuiPaginationItem-page {
                                border: 1px solid #ddd;
                                border-top: none;
                                transition: none;
                            }
                            button.Mui-selected{
                                background: #2c394b;
                                color: #fff;
                                height: 30px;
                                line-height: 26px;
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}