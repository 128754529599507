
.dk-dv-searchDropDwn{
    color: #333;
    width: 100%;
    .dropdown{
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        position: relative;
        width: 100%;
        a{
            font-size: 13px;
            text-transform: capitalize;
            color: #333;
            width: 100%;
            padding: 3px 10px;
            background-color: #fff;
            border: 1px solid #ccc;
            &::after{
                content: "35";
                display: block;
                position: absolute;
                right: 10px;
                font-size: 15px;
                top: 13px;
                color: #787373;
                margin-left: 0.255em;
                content: "";
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
            }
        }
        .dropdown-menu.dk-dv-searchDropDwnMenu {
            transform: translate(0, 37px) !important;
            display: block;
            border-radius: 0;
            padding: 0;
            border-top: none;
            border-color: #96c8da;
            box-shadow: 0 2px 3px 0 rgba(0.34,0.36,38,0.15);
            width: 100%;
            input.form-control{
                border-radius: 0;
                border: none;
                border-bottom: 1px solid #808080;
                font-size: 14px;
            }
            input.form-control:focus{
                box-shadow: none;
                outline: 0;
            }
            ul{
                margin-bottom: 0;
                min-height: 220px;
                max-height: 220px;
                overflow-y: auto;
                .dropdown-item{
                    border: none;
                    border-bottom: 1px solid #ccc;
                    padding: 0.5rem 0.4rem ;
                    background-color: #f2f2f2;
                    color: #333;
                    &::after{
                        display: none;
                    }
                }
                .dropdown-item:first-child{
                    border-top: none;
                }
                .dropdown-item:hover{
                    background-color: #fff;
                    color: #333;
                }
                .dropdown-item::after{
                    display: none;
                }
            }
        }
    }
}
