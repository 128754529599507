.dk-projectsSummary-main {
    margin-top: 115px;

    .dk-projectsSummary {
        padding: 0;
        width: 100%;
        max-width: 1280px;
        margin: auto;

        .dk-projectSummary-head {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 18px;

            .d-flex {
                width: 100%;
                max-width: 406px;
            }

            .dk-summaryDate-filter {
                margin-right: 8px;
                width: 100%;
                max-width: 172px;

                label {
                    margin: 0;
                    margin-bottom: 3px;
                }

                .dk-nativedatepicker-grid {
                    .MuiFormControl-root {
                        border: 1px solid #ddd;
                        margin: 0;
                        background: #eaeaea;

                        input {
                            padding-left: 10px;
                            height: 24px;
                        }

                        .MuiInput-root {
                            &::before {
                                display: none;
                            }

                            &::after {
                                display: none;
                            }
                        }
                    }

                }
            }

            .dk-summaryDate-filterBtn {
                button {
                    padding: 0 8px;
                    display: inline-block;
                    width: 45px;
                    height: 39px;
                    line-height: 39px;
                    background: #396a95;
                    color: #fff;
                    border-radius: 3px;
                    outline: none;
                    text-align: center;
                    border: none;
                    margin-top: 24px;

                    &:hover {
                        background: #204667;
                        transition: all 0.5s;
                    }
                }
            }
        }

        .dk-projectSummaryCols {
            align-items: center;
            background: #eaeaea;
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 12px;
            max-width: 100%;
            margin-bottom: 30px;
            padding: 0;
            text-align: center;
            width: 100%;

            .title {
                align-items: center;
                background-color: #dadada;
                border-bottom: 1px solid #ddd;
                border-radius: 5px 5px 0 0;
                display: flex;
                justify-content: center;
                width: 100%;
                color: #646060;
                font-size: 16px;
                font-weight: 700;
                min-height: 35px;
                padding: 8px 10px;
                font-family: Segoe UI, Arial, sans-serif !important;
            }

            .dk-projectSummary-panel {
                align-items: center;
                background-color: #eaeaea;
                border-radius: 0 0 5px 5px;
                display: flex;
                justify-content: center;
                min-height: 180px;
            }
        }
    }
}