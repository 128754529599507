.dk-budgetPage-mainCon {
    margin-top: 125px;

    h2.dk-budgetPageTitle {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        margin: 0 0 1rem;
    }

    .dkg-budget-top-headerCon {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dk-budgetTopLeft {
            display: flex;
            align-items: center;
            flex-direction: row;

            .dkg-budget-yearsDropdownCon {
                .dkg-budget-yearsDropdown {
                    .dkg-budget-years-toggle {
                        background: #f2f2f2 !important;
                        border-radius: 3px;
                        box-shadow: none;
                        color: #333 !important;
                        font-size: 13px;
                        margin: 0;
                        min-width: 110px;
                        padding: 9px 10px;
                        z-index: 99999;
                        border: 1px solid #ddd;
                        height: 35px;
                        line-height: 17px;

                        &::after {
                            display: none;
                        }
                    }

                    .dkg-budget-dropdownMenu {
                        background: #fff;
                        border: none;
                        border-radius: 0;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                        margin: 35px 0 0 !important;
                        min-width: 110px;
                        padding: 0;
                        -webkit-transform: inherit !important;
                        transform: inherit !important;
                        z-index: 99999;

                        a {
                            padding: 6px 10px;
                            font-size: 13px;

                            &:hover {
                                background: #f8f9fa;
                            }
                        }

                        a.active {
                            background: #3c97b6;
                        }
                    }
                }
            }

            .dk-mailshotsSearch {
                display: flex;

                .input-group {
                    width: 100%;
                    max-width: 140px;
                    margin-right: 3px;

                    input {
                        border: 1px solid #ddd !important;
                        background-color: #eaeaea;
                        padding: 0 10px;
                        padding-right: 30px;
                        box-shadow: none !important;
                        border-radius: 0;
                        height: 35px;
                    }
                }
            }

            button.dk-budgetfilter {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                background: #eaeaea !important;
                color: #333;
                text-align: center;
                border: 1px solid #ddd;
                border-radius: 50%;
                outline: none;
                box-shadow: none;
            }
        }

        .dk-creTrackTopCon {
            .dk-creTrackTabsCon {
                .dk-budgetYears-Tabs {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    list-style: none;
                    width: 100%;
                    margin-bottom: 0;

                    .dk-budgetYear {
                        width: 100%;
                        max-width: 8.33%;

                        .dkg-months-link {
                            height: 35px;
                            width: 100%;
                            background: #eaeaea;
                            color: #333;
                            padding: 5px 25px;
                            float: left;
                            border: 1px solid #ccc;
                            border-right: none;
                            cursor: pointer;
                            text-align: center;
                            border-radius: 0;
                            outline: 0;

                            &.active {
                                color: #fff;
                                background: #252c37;
                                border: 1px solid #252c37;
                                border-right: none;
                            }
                        }

                        &:last-child {
                            border-right: 1px solid #ddd;
                        }
                    }
                }

                h2.dk-budgetPageTitle {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #333;
                    margin: 0 0 1rem;
                    margin-bottom: 0;
                }

            }
        }

        .dk-budgetTopRight-Con {
            .dk-budgetTopRight {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                button.dkg-budget-reorder {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-align-items: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                    background: #eaeaea !important;
                    color: #333;
                    text-align: center;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    outline: none;
                    box-shadow: none;
                }

                .dk-reSLoginFilter {
                    position: relative;

                    i {
                        position: absolute;
                        right: -8px;
                        top: -13px;
                        cursor: pointer;
                    }

                    .dk-resloginfilterDrop {
                        .dk-resloginfilter-toggle {
                            border-radius: 0;
                            background-color: #eaeaea;
                            border: 1px solid #ddd;
                            color: #797474;
                            font-size: 12px;
                            box-shadow: none;
                            margin: 0;
                            padding: 0.5rem 0.35rem;
                            min-width: 100px;
                            text-transform: uppercase;
                            font-weight: bold;

                            &::after {
                                position: absolute;
                                right: 6px;
                                top: 15px;
                                color: #797474;
                            }
                        }

                        .dk-resloginfilterDropMenu {
                            border-radius: 0;
                            padding: 0;
                            min-width: 100px !important;

                            .dropdown-item {
                                background-color: #eaeaea;
                                border-top: 1px solid #ddd;
                                padding: 0.5rem 0.4rem;
                                font-size: 13px;
                                position: relative;

                                &:first-child {
                                    border-top: none;
                                }

                                &:hover {
                                    background-color: #f1f1f1;
                                }

                                .dkg-monthly-budget {
                                    position: absolute;
                                    right: 10px;
                                    top: 7px;
                                    color: #2c71b2;
                                }
                            }
                        }

                    }
                }
            }
        }

    }

    .dk-budgetTableCont {
        cursor: pointer;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh - 215px);
        position: relative;
        margin-top: 1rem;

        .dk-budgetTable {
            margin-bottom: 0;

            thead tr th {
                background: #252c37;
                color: #e2dada;
                font-size: 14px;
                text-align: center;
                padding: 9px 15px;
                border: 1px solid #616767;
                vertical-align: middle;
                position: sticky;
                top: 0;
                z-index: 11;
                white-space: nowrap;
                text-transform: uppercase;
            }

            tbody {
                tr {
                    td {
                        background: #2c394b;
                        color: #bdb4b4;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        padding: 5px 15px;
                        border: 1px solid #5a6169;
                        vertical-align: middle;
                        white-space: nowrap;

                        &:nth-child(02),
                        &:nth-child(03) {
                            padding: 0;
                        }

                        .caseDiv {
                            min-height: 40px;
                            line-height: 40px;
                            min-width: 80px;
                        }

                        .dk-adsStatusBtn .dropdown .dropdown-toggle.btn {
                            box-shadow: none;
                            padding: 0;
                            margin: 0;
                            color: #fff;
                            height: 40px;
                            width: 100%;
                            border-radius: 0;
                        }

                        .dk-adsStatusBtn.dk-varbtnBg .dropdown .dropdown-toggle.btn {
                            color: #fff;
                            padding: 0;
                            margin: 0;
                            color: #fff;
                            padding: 0;
                            margin: 0;
                            height: 40px;
                            width: 100%;
                            border-radius: 0;

                        }

                        .dk-adsStatusBtn .dropdown .dropdown-toggle::after {
                            display: none;
                        }

                        .dk-adsStatusBtn .dropdown .dropdown-menu {
                            padding: 0;
                            border-radius: 0;
                            min-width: 120px;
                            max-width: 120px;

                            a.dropdown-item {
                                color: #fff;
                                padding: 7px 10px;
                                text-align: center;
                            }
                        }

                        .dk-adstractAction {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .dk-edit-btn {
                                &:hover {
                                    box-shadow: 0 12px 16px 0 rgb(0, 0, 0, 0.24), 0 17px 50px 0 rgb(0, 0, 0, 0.19) !important;
                                }
                            }

                            .dk-delete-btn {
                                &:hover {
                                    box-shadow: 0 12px 16px 0 rgb(0, 0, 0, 0.24), 0 17px 50px 0 rgb(0, 0, 0, 0.19) !important;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}