.dk-coMMTophead{
    h2.title{
        font-size: 15px;
        line-height: 1.5;
        width: 100%;
        text-align: center;
    }
}
.dk-commnTableCon{
    margin-top: 1rem;
    .dk-commnTable{
        thead{
            tr th{
                font-size: 13px;
                font-weight: normal;
                color: #fff;
                background: #485569;
                padding: 0.5rem;
                border: 1px solid #dddddd;
                text-align: center;
                white-space: nowrap;

            }
        }
        tbody{
            tr td{
                background-color: #fff;
                    color: #333;
                    font-size: 12px;
                    text-align: center;
                    padding: 5px 10px;
                    border: 1px solid #ddd;
                    border-bottom: none;
                    vertical-align: middle;
                    white-space: nowrap;
                    .recImgCon{
                        img{
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                        }
                    }
                    .typebodyColumn{
                        i{
                            color: #333;
                        }
                    }
            }
            tr td:nth-child(01),tr td:nth-child(02){
                width: 80px;
            }
            tr td:nth-child(03),tr td:nth-child(04){
                width: 100px;
            }
        }
    }
}