.dkg-application-view-modal{
    .modal-dialog{
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 600px !important;
        max-width: 600px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        .modal-content{
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dkg-app-progress-modal-header{
                background-color: #3C97B6;
                border-radius: 0;
                padding: 14px 15px !important;
                width: 100%;
                border-bottom: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &.dkg-app-widthraw-modal-header{
                    background-color: #ca464f;
                    
                }
                &.dkg-caseupdated-header{
                    background-color: #3f9e6f;
                }
                &.dkg-job-application-modal-header{
                    background-color: #d58b5d;
                }
                .dkg-app-progress-plusicon {
                    color: #fff;
                    position: relative;
                    left: 5px;
                    font-size: 16px;
                }
                .modal-title {
                    color: #fff;
                    text-transform: capitalize;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: relative;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    width: 100%;
                    text-align: center;


                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: 0;
                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }
            .dkg-app-progress-modal-body{
                background-color: #eaeaea;
                padding: 15px;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dkg-cand-progress-app-mainCon{
                    width: 100%;
                    padding-left: 2.25rem;
                    .dkg-progress-app-listCon{
                        position: relative;
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        .dkg-progress-app-list{
                            position: relative;
                            min-height: 75px;
                            counter-increment: list;
                            padding-left: 0.5rem;
                            justify-content: center;
                            align-content: center;
                            position: middle;
                            &:before {
                                content: "";
                                position: absolute;
                                left: -1.55rem;
                                top: 52px;
                                height: 80%;
                                width: 1px;
                                border-left: 1px solid #848484;
                            }
                            &:after {
                                content: counter(list);
                                position: absolute;
                                top: 26px;
                                left: -2.5rem;
                                width: 26px;
                                height: 26px;
                                border-radius: 50%;
                                background: transparent;
                                color: #848484;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 2rem;
                                text-align: center;
                                border: 1px solid #848484;
                            }
                            &.current{
                                &:after {
                                    background: #fff;
                                    color: #777;
                                    font-weight: bold;
                                }
                            }
                            &:last-child{
                                &:before{
                                    height: 30%;
                                }
                            }
                            .dkg-progress-app-card-Con{
                                background-color: #fff;
                                color: #333;
                                border-radius: 5px;
                                height: 75px;
                                margin-bottom: 0.75rem;
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                .dkg-app-progress-imgcols{
                                    width: 70px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 0 10px;
                                    .dkg-app-progress-img{
                                        width: 40px;
                                        height: 40px;
                                        display: inline-block;
                                        border-radius: 50%;
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        border: 1px solid #ddd;

                                    }
                                }
                                .dkg-app-porgess-statuscols{
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    .dkg-app-status-firstCols,
                                    .dkg-app-status-seocndCols,
                                    .dkg-app-status-thirdCols{
                                        width: 100%;
                                        text-align: center;
                                        .dkg-status-title{
                                            position: relative;
                                            top: 3px;
                                            font-size: 12px;
                                        }
                                        .dkg-progress-status-item{
                                            height: 30px;
                                            line-height: 15px;
                                            border-right: 1px solid #ddd;
                                            margin-top: 5px;
                                        }
                                        .dkg-progess-app-date{
                                            font-size: 12px; 
                                            margin-top: 12px;
                                        }
                                        .dkg-progess-app-time{
                                            font-size: 12px; 
                                            margin-top: 5px;
                                        }

                                    }
                                    .dkg-app-status-firstCols,
                                    .dkg-app-status-seocndCols{
                                        .dkg-progress-status-item{
                                            border-right: 1px solid #f2f2f2;
                                        }
                                    }
                                }
                                .dkg-app-progress-date-cols{
                                    width: 195px;
                                    display: flex;
                                    justify-content: center;
                                    display: none;
                                    .dkg-progess-app-date{
                                        position: relative;
                                        top: 12px;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
                .dkg-case-progress-form-con{
                    .dkg-profile-frmgrp{
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 1rem;
                        padding: 0 15px;
                        .dkg-profile-label{
                            min-width: 220px;
                            max-width: 220px;
                            margin-bottom: 0;
                            font-size: 13px;
                            .dkg-profile-star{
                                color: #e2445b;
                            }
                        }
                        .dkg-app-select-dropdown-Con{
                            width: 100%;
                            .dkg-app-select-dropdown{
                                width: 100%;
                                .dkg-app-select-dropdown-toggle{
                                    margin: 0;
                                    width: 100%;
                                    box-shadow: none;
                                    text-align: center;
                                    border-radius: 0;
                                    text-transform: capitalize;
                                    position: relative;
                                    font-size: 0.9487rem;
                                    border: 1px solid #ddd;
                                    background-color: #f2f2f2;
                                    color: #666;
                                    height: calc(1.6em + 0.75rem + 2px);
                                    padding: 0.375rem 0.75rem;
                                    border-radius: 0;
                                    &::after{
                                        position: absolute;
                                        top: 14px;
                                        right: 12px;
                                        font-size: 18px;
                                        color: #666;
                                    }
                                }
                                .dkg-app-select-dropdown-menu{
                                    display: block;
                                    width: 100%;
                                    border-radius: 0;
                                    padding: 0;
                                    border-color: currentcolor #96c8da #96c8da;
                                    border-top: #96c8da;
                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                    .dkg-app-select-dropdown-item{
                                        border-radius: 0;
                                        border: none;
                                        border-bottom: 1px solid #ddd;
                                        text-align: left;
                                        font-weight: 500;
                                        font-size: 13px;
                                        padding: 9px 10px;
                                        color: #666;
                                        &:last-child{
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                            .dkg-app-select-dropdown ,.dk-jobssearchFilter{
                                width: 100%;
                                position: relative;
                                .dkg-jobapplied-title{
                                    color: #2e75b6;
                                    position: relative;
                                    top: 0.45rem;
                                    font-size: 0.9rem;
                                }
                                .dkg-app-select-dropdown-toggle,
                                .dkg-widthdraw-app-toggle{
                                    background-color: #fff;
                                    margin: 0;
                                    width: 100%;
                                    box-shadow: none;
                                    font-size: 14px;
                                    text-align: center;
                                    height: 32px;
                                    line-height: 16px;
                                    display: inline-block;
                                    border-radius: 0;
                                    text-transform: capitalize;
                                    padding: 9px 10px;
                                    position: relative;
                                    color: #777;
                                    width: 100%;
                                    &::after {
                                        display: inline-block;
                                        margin-left: 0.255em;
                                        vertical-align: 0.255em;
                                        content: "";
                                        border-top: 0.3em solid;
                                        border-right: 0.3em solid transparent;
                                        border-bottom: 0;
                                        border-left: 0.3em solid transparent;
                                        color: #8a8a8a;
                                        position: absolute;
                                        top: 14px;
                                        right: 10px;
                                        font-size: 18px;
                                    }
                                    a{
                                        color: #777;
                                        &::after {
                                            display: inline-block;
                                            margin-left: 0.255em;
                                            vertical-align: 0.255em;
                                            content: "";
                                            border-top: 0.3em solid;
                                            border-right: 0.3em solid transparent;
                                            border-bottom: 0;
                                            border-left: 0.3em solid transparent;
                                            color: #8a8a8a;
                                            position: absolute;
                                            top: 27px;
                                            right: 10px;
                                            font-size: 18px;
                                        }
                                    }
                                    // &::after{
                                    //     position: absolute;
                                    //     top: 26px;
                                    //     right: 12px;
                                    //     font-size: 18px;
                                    //     color: #777;
                                    // }
                                }
                                .dropdown{
                                    background-color: #fff;
                                    margin: 0;
                                    width: 100%;
                                    box-shadow: none;
                                    font-size: 14px;
                                    text-align: center;
                                    height: 32px;
                                    line-height: 16px;
                                    display: inline-block;
                                    border-radius: 0;
                                    text-transform: capitalize;
                                    padding: 9px 10px;
                                    position: relative;
                                    color: #777;
                                    width: 100%;
                                    a{
                                        color: #777;
                                        &::after {
                                            display: inline-block;
                                            margin-left: 0.255em;
                                            vertical-align: 0.255em;
                                            content: "";
                                            border-top: 0.3em solid;
                                            border-right: 0.3em solid transparent;
                                            border-bottom: 0;
                                            border-left: 0.3em solid transparent;
                                            color: #8a8a8a;
                                            position: absolute;
                                            top: 17px;
                                            right: 10px;
                                            font-size: 18px;
                                        }
                                    }
                                }
                                .dkg-app-select-dropdown-menu ,
                                .dropdown-menu{
                                    position: absolute;
                                    left: 0;
                                    display: block;
                                    width: 100%;
                                    border-radius: 0;
                                    padding: 0;
                                    border-color: currentcolor #96c8da #96c8da;
                                    border-top: #96c8da;
                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
                                    z-index: 999999;
                                    .dkg-app-select-dropdown-item{
                                        border-radius: 0;
                                        border: none;
                                        border-bottom: 1px solid #ddd;
                                        text-align: left;
                                        font-weight: 500;
                                        font-size: 13px;
                                        padding: 5px 7px;
                                        color: #333;
                                    }
                                    .dkg-job-applied-ul{
                                        max-height: 220px;
                                        overflow-y: auto;
                                        a{
                                            border-radius: 0;
                                            border: none;
                                            border-bottom: 1px solid #aaa;
                                            text-align: left;
                                            font-weight: 500;
                                            font-size: 13px;
                                            padding: 9px 10px;
                                            color: #333;
                                            height: 45px;
                                            &:last-child{
                                                border-bottom: none;
                                            }
                                        }
                                        .dkg-jobapplied-dropitem{
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            width: 100%;
                                            .dkg-joapplied-cross{
                                                svg{
                                                    opacity: 0.85;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:last-child{
                            margin-bottom: 1rem;
                        }
                        .dkg-app-user-dropdrownCon{
                            position: relative;
                            .dkg-app-user-dropdrown{
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .dkg-app-user-dropdrown-toggle{
                                    align-items: center;
                                    background-color: transparent;
                                    // border: 1px solid #ddd;
                                    border-radius: 50%;
                                    box-shadow: none;
                                    color: #8a8a8a;
                                    display: flex;
                                    height: 35px;
                                    justify-content: center;
                                    margin: 0;
                                    padding: 0;
                                    position: relative;
                                    width: 35px;
                                    svg{
                                        width: 22px;
                                        height: 22px;
                                    }
                                    &:after {
                                        position: absolute;
                                        top: 17px;
                                        right: -10px;
                                        font-size: 19px;
                                        display: none;
                                    }
                                }
                                .dkg-app-user-dropdrown-menu{
                                    // align-items: center;
                                    // background-color: #f2f2f2;
                                    // border: 1px solid #dddd;
                                    // display: flex;
                                    // left: inherit!important;
                                    // margin: 0!important;
                                    // min-width: inherit;
                                    // padding: 0!important;
                                    // position: absolute!important;
                                    // right: 68px !important;
                                    // top: -7px !important;
                                    // transform: inherit!important;
                                    background-color: #fff;
                                    left: 24px!important;
                                    padding: 5px;
                                    top: -10px!important;
                                    transform: translate(22px)!important;
                                    -webkit-transform: translate(22px)!important;
                                    &:before {
                                        border-color: #0000 #fff #0000 #0000;
                                        _border-color: #000 #fff #000 #000;
                                        border-style: solid;
                                        border-width: 8.5px 12px 8.5px 0;
                                        content: "";
                                        _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                        height: 0;
                                        position: absolute;
                                        top: 19px;
                                        left: -12px;
                                        line-height: 0;
                                        width: 0;
                                    }
                                    .d-flex{
                                        .dkg-app-user-dropdrown-item,
                                        .dropdown-item{
                                            display: inline-block;
                                            height: 35px;
                                            margin: 5px 3px;
                                            padding: 0;
                                            width: 35px;
                                           .dkg-app-user-img{
                                                border-radius: 50%;
                                                height: 35px;
                                                width: 35px
                                           }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .dkg-caseprogrees-textAreaCon{
                    padding:  15px;
                    .dkg-caseprogress-header{
                        background-color: #ddd;
                        border: 1px solid #ccc;
                        display: block;
                        padding: 7px 10px;
                        text-align: center;
                        text-transform: uppercase;
                        .dkg-caseprogress-title{
                            color: #333;
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                    .dkg-caseprogress-subtitleCon{
                        background-color: #fff;
                        border: 1px solid #ddd;
                        border-top: none;
                        display: block;
                        padding: 7px 10px;
                        text-align: center;
                        .dkg-caseprogress-subtitle{
                            color: #666;
                            font-size: 13px;
                        }
                        .dkg-caseprogess-dflex{
                            display: flex;
                            .dkg-caseprogess-date {
                                display: inline;
                                font-size: 0.85rem;
                                font-weight: 400;
                                margin-right: 10px;
                                color: #666;
                                i {
                                    display: inline-block;
                                    margin-right: 8px;
                                    color: #999;
                                }
                            }
                        }
                    }
                    .dkg-caseprogrees-textarea{
                        width: 100%;
                        .dkg-cp-frmcontrol-textarea{
                            background-color: #fff;
                            border: 1px solid #ddd;
                            border-top: none;
                            height: 160px;
                            overflow-y: auto;
                            text-align: left;
                        }
                    }
                    .dkg-cp-save-btncon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin: 1rem 0;
                        div{
                            div{
                                .dkg-cp-save-btn{
                                    background-color: #3C97B6;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
                .dkg-caseupdated-calenderCon{
                    .SingleDatePicker{
                        div{
                            .SingleDatePickerInput{
                                .DateInput{
                                    width: inherit;
                                    .DateInput_input{
                                        font-size: 0.85rem;
                                        padding: 7px 10px;
                                        border: 1px solid #ddd;
                                        width: 100%;
                                        text-align: center;
                                    
                                    }
                                }
                                // .SingleDatePicker_picker{
                                //     .DayPicker{
                                //         width: 240px !important;
                                //         div{
                                //             div{
                                //                 width: 240px !important;
                                //             }
                                //         }
                                //     }
                                // }
                            }
                        }
                    }
                }
                &.dkg-datetime-modalbody{
                    display: flex;
                    justify-content: space-between;
                    .dkg-caseupdated-savebtncon{
                        div{
                            div{
                                .dkg-caseupdated-savebtn{
                                    background-color: #3f9e6f;
                                }
                            }
                        }
                    }
                }
            }
            
            .dkg-app-widthraw-modal-body{
                background-color: #eaeaea;
                padding: 15px;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dkg-app-widthraw-desc-con{
                    .dkg-app-withdraw-title{
                        font-size: 20px;
                        text-align: center;
                        font-weight: 600;
                        margin: 25px 0;
                        margin-bottom: 55px;
                    }
                    .dkg-app-widthraw-desc{
                        font-size: 1.15rem;
                        margin-bottom: 1.25rem;
                    }
                }
                .dkg-app-withdraw-ulCon{
                    .dkg-app-withdraw-ul{
                        margin-bottom: 2rem;
                        .dkg-app-withdraw-li{
                            list-style: none;
                            display: flex;
                            margin-bottom: 0.5rem;
                            .dkg-app-withdraw-checkbox{
                                margin-right: 0.5rem;
                                input[type="checkbox"]{
                                    background-color: white;
                                    border-radius: 0;
                                    width: 14px !important;
                                    height: 14px !important;
                                    &:checked {
                                        background-color: #03a786; /* bright green */
                                    }
                                
                                }
                                &.checkbox-md{
                                    input[type="checkbox"]{
                                        width: 14px;
                                        height: 14px;
                                    }    
                                }
                                .dkg-resetbtn{
                                    background-color: #ca464f;
                                    color: #fff;
                                    padding: 6px 16px;
                                    font-size: 0.875rem;
                                    min-width: 65px;
                                    box-sizing: border-box;
                                    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                    font-weight: 500;
                                    line-height: 1.75;
                                    border-radius: 4px;
                                    letter-spacing: 0.02857em;
                                    text-transform: uppercase;
                                }
                            }
                            .dkg-app-withdraw-reson{
                                font-size: 14px;
                                position: relative;
                                top: -2px;                            
                            }
                        }
                    }
                    .dkg-appwithdraw-textarea-Con{
                        .dkg-appwithdraw-textarea{
                            width: 100%;
                            text-align: left;
                            border: 1px solid #ddd;
                            height: 120px;
                            padding: 5px 10px;
                            font-size: 13px;
                        }
                    }
                }
                .dkg-appwithdraw-btnCon{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 25px;
                    div{
                        div{
                            .dkg-appwithdraw-btn{
                                background-color: #ca464f;
                                color: #fff;
                                &.dkg-view-app-btn{
                                    background-color: #d58b5d;
                                }
                            }

                            .dkg-editjob-btn{
                                background-color: #3c97b6;
                            }
                        }
                    }
                    &.dkg-viewapp-btnCon{
                        justify-content: center;
                    }
                }
                .dkg-jobapp-label{
                    font-size: 13px;
                    color: #333;
                    display: block;
                }
                .dkg-editview-app-con{
                    display: flex;
                    align-items: center;
                   
                    .dkg-view-app-icon{
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #ddd;
                        cursor: pointer;
                        svg{
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
        }
    }
    &.dkg-app-progress-modal-234{
        .modal-dialog{
            min-width: 720px !important;
            max-width: 720px;
            .modal-content {
                .dkg-app-progress-modal-body{
                    padding: 25px 15px;
                }
            }
        }
    }
}
.modal.dkg-application-view-modal.fade .modal-dialog {
    right: -600px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}
.modal.dkg-application-view-modal.dkg-app-progress-modal-234.fade .modal-dialog {
    right: -700px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}
.modal.dkg-application-view-modal.show .modal-dialog,
.modal.dkg-application-view-modal.dkg-app-progress-modal-234.show .modal-dialog {
    right: 0;
}

.modal.dkg-application-view-modal {
    z-index: 9999 !important;
}