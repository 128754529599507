.dk-adsTrackPageCon {
    margin-top: 140px;
    position: fixed;
}

.dk-adsTrackPageCon .dk-adshrboardsCon {
    padding-bottom: 1rem !important;
}

.flex-row.nav.nav-pills.dk-adsTrackerhrTabs {
    justify-content: space-between;
}

.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-item.dk-adsTrackerhrTab {
    width: 100%;
    max-width: 11.5%;
}

.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-item.dk-adsTrackerhrTab:first-child,
.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-item.dk-adsTrackerhrTab:last-child {
    width: 100%;
    max-width: 2.005%;
}

.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-item.dk-adsTrackerhrTab .nav-link {
    height: 30px;
    line-height: 20px;
    background-color: #fff;
    color: #333;
    padding: 5px 25px;
    border: 1px solid #ddd;
    cursor: pointer;
    text-align: center;
    border-radius: 35px;
    white-space: nowrap;
    font-size: 0.9rem;
}

.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-item.dk-adsTrackerhrTab:first-child .nav-link,
.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-item.dk-adsTrackerhrTab:last-child .nav-link {
    width: 30px;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 0;
    height: 30px;
    display: inline-block;
    line-height: 28px;
    text-align: center;
}

.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-item.dk-adsTrackerhrTab:last-child {
    text-align: right;
}

.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-link.active,
.dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .show>.nav-link {
    background-color: #374252 !important;
    border: 1px solid #374252 !important;
    color: #e2dada !important;
    position: relative;
    border-left: none;
}

/* .dk-adsTrackPageCon .dk-adshrboardsCon .nav-pills.dk-adsTrackerhrTabs .nav-link.active::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 10px 0 10px;
    border-color: #2b4a55 transparent transparent transparent;
    line-height: 0;
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translate(-50%);
} */
.dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackLeft {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackLeft .dk-mailshotsSearch .input-group {
    width: 100%;
    max-width: 140px;
    margin-right: 8px;
}

.dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackLeft .dk-mailshotsSearch .input-group input {
    border: 1px solid #ddd !important;
    background-color: #eaeaea;
    padding: 0 10px;
    padding-right: 30px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0;
    height: 35px;
}

/* .dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackLeft .dk-mailshotsSearch .input-group .input-group-append button{
    padding: 0.35rem .65rem;
    margin: 0;
    background-color: #eaeaea !important;
    color: #333;
    box-shadow: none;
}
.dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackLeft .dk-mailshotsSearch .input-group .input-group-append button::placeholder{
    font-size: 12px;
} */
.dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackLeft button.filterTable,
.dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackLeft .dk-filterUserTabs.dropdown .btn-default.dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #eaeaea !important;
    color: #333;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
    margin-right: 10px;
}

.dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackLeft .dk-filterUserTabs.dropdown .dropdown-toggle::after {
    display: none;
}

.dk-adsTrackPageCon .dk-adsTrackPageHead {
    position: relative;
    margin-bottom: 0.5rem;
}

.dk-adsTrackPageCon .dk-adsTrackPageHead h2.title {
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    top: 8px;
    text-transform: uppercase;
}

.dk-adsTrackPageCon .dk-adsTrackPageHead .dk-adsTrackTopRight button {
    display: inline-block;
    background: #eaeaea;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 0;
    outline: 0;
    font-size: 15px;
    font-weight: 400;
}

.dk-adsTrackerPageBg {
    /* background-color: #374252; */
    padding: 5px 15px;
    height: 100vh;
}

.dk-adsTrackPageHead .dk-adsTrackTopRight .dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect,
.dk-adsTrackPageHead .dk-adsTrackTopRight .dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect:hover {
    min-width: 120px;
    border: 1px solid #ddd !important;
    height: 34px;
    background-color: #eaeaea !important;
}

.dk-adsTrackPageHead .dk-adsTrackTopRight .dk-reSLoginFilter .ui.dropdown>.text {
    display: inline-block;
    transition: none;
    font-weight: 400;
    font-size: 14px;
}

.dk-adsTrackPageHead .dk-adsTrackTopRight .dk-reSLoginFilter .ui.selection.dropdown.dk-overdues-task-slect>.dropdown.icon {
    opacity: 1;
    color: #333;
    top: 0.7581em;
}

.dk-adsTrackPageHead .dk-adsTrackTopRight .dk-reSLoginFilter .ui.selection.active.dropdown .menu {
    border-radius: 0 !important;
}

.dk-adsTrackTableCon {
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100vh - 163px);
    padding-bottom: 0;
    position: relative;
}

.dk-adsTrackTableCon .dk-adsTrackTable {
    margin-bottom: 0;
}

.dk-adsTrackTableCon .dk-adsTrackTable thead tr th {
    background: #252c37;
    color: #e2dada;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    padding: 9px 10px;
    border-right: 1px solid #616767;
    border-left: 0;
    border-bottom: none;
    border-top: 0;
    vertical-align: middle;
    position: sticky;
    top: 0;
    z-index: 9;
    box-sizing: border-box;
    /* background: #252c37;
    color: #e2dada;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    line-height: 9px;
    border-left: 1px solid #616767;
    border-bottom: 0;
    vertical-align: middle;
    position: sticky;
    top: 0;
    z-index: 999;
    min-width: 85px; */
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td {
    background: #2C394B !important;
    color: #bdb4b4;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    padding: 5px 15px;
    border: 1px solid #5a6169;
    vertical-align: middle;
    box-sizing: border-box;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td .dk-adsRecImgCon img.dk-adsTrackImg {
    min-height: 22px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td .dk-adsStatusBtn .dropdown .btn-success {
    background-color: #31a886 !important;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td .dk-adsStatusBtn .dropdown button {
    margin: 0;
    padding: .375rem 1rem !important;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-toggle::after {
    display: none;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu {
    padding: 0;
    border-radius: 0;
    min-width: 120px;
    max-width: 120px;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu a.dropdown-item:first-child {
    background-color: #31a886 !important;
    color: #fff;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td .dk-adsStatusBtn .dropdown .dropdown-menu a.dropdown-item:last-child {
    background-color: #dc3545 !important;
    color: #fff;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td .dk-adstractAction {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dk-adstractAction a.dk-edit-btn,
.dk-adstractAction a.dk-view-btn,
.dk-adstractAction a.dk-delete-btn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    border-radius: 3px;
}

.dk-adstractAction a.dk-view-btn {
    /* background-color: #2196f3; */
    color: #2196f3;
}

.dk-adstractAction a.dk-edit-btn {
    /* background-color: #fee69c; */
    color: #fee69c;
    ;
}

.dk-adstractAction a.dk-delete-btn {
    /* background-color: #dc3545; */
    color: #dc3545;
}

.dk-resPaginationCon {
    background-color: #fff;
    position: fixed;
    bottom: 3px;
    width: 100%;
    left: 0;
    z-index: 999;
    padding: 0 15px;
}

.dk-resPaginationCon .dk-resPagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dk-resPaginationCon .dk-resPagination .dk-resLeftPag .dk-texCounter {
    color: #333 !important;
    z-index: 999;
    font-size: 13px;
}

.dk-resPaginationCon .dk-resPagination .dk-resrightPag ul {
    list-style: none;
    margin-bottom: 0;
    display: flex;
}

.dk-resPaginationCon .dk-resPagination .dk-resrightPag ul li {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-left: none;
    text-align: center;
    line-height: 26px;
}

.dk-resPaginationCon .dk-resPagination .dk-resrightPag ul li:first-child {
    border-left: 1px solid #ccc;
}

.dk-resPaginationCon .dk-resPagination .dk-resrightPag ul li a {
    color: #333;
}

.dk-resPaginationCon .dk-resPagination .dk-resrightPag ul li.active {
    background-color: #2c394b;
}

.dk-resPaginationCon .dk-resPagination .dk-resrightPag ul li.active a {
    color: #fff;
}

.dk-resPaginationCon .dk-resPagination .dk-resrightPag .MuiPagination-root .MuiPagination-ul li button.Mui-selected,
.dk-resPaginationCon .dk-resPagination .dk-resrightPag .MuiPagination-root .MuiPagination-ul li button:hover {
    background: #2c394b;
    color: #fff;
    height: 30px;
    width: 30px;
    line-height: 26px;
}

.dk-resPaginationCon .dk-resPagination .dk-resrightPag .MuiPagination-root .MuiPagination-ul li button.MuiPaginationItem-page {
    transition: none;
    border: 1px solid #ddd;
    border-top: none;
}

.table-select-user-img {
    width: 35px;
    border-radius: 50%;
}

.dk-adsStatusBtn .dropdown-menu.show .dropdown-item {
    height: auto;
}

.countDatat {
    margin-left: 7px;
}

.MuiPagination-ul li button.Mui-selected,
.MuiPagination-ul li button:hover {
    background: #2c394b;
}

.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td:nth-child(07) {
    text-align: left;
}

.dk-smsTableCheckbox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.dk-smsTableCheckbox input {
    margin-right: 0.5rem;
}

.dk-smsTableCheckbox input[type=checkbox]:checked {
    background: #31a886 !important;
}

input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background: #b7b4b4;
    border-radius: 0;
    box-sizing: border-box;
    position: relative;
    box-sizing: content-box;
    width: 12px;
    height: 12px;
    border-width: 0;
    transition: all 0.3s linear;
    border: solid #ccc 1px;
}

span.description {
    color: #fff !important;
    background: #2c394b;
    border-radius: 5px;
    min-width: 20px;
    min-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    padding: 0 6px;
}

.dk-adsTrackTableCon .dk-adsTrackTable thead tr th:nth-child(01) {
    column-width: 75px;
}

.dk-adsTrackTableCon .dk-adsTrackTable thead tr th:nth-child(02),
.dk-adsTrackTableCon .dk-adsTrackTable thead tr th:nth-child(03),
.dk-adsTrackTableCon .dk-adsTrackTable thead tr th:nth-child(04) {
    column-width: 120px;
}

.dk-adsTrackTableCon .dk-adsTrackTable thead tr th:nth-child(05) {
    column-width: 110px;
}

.dk-adsTrackTableCon .dk-adsTrackTable thead tr th:nth-child(06) {
    column-width: 95px;
}

/* .dk-adsTrackTableCon .dk-adsTrackTable thead tr th:nth-child(07) {
    column-width: 95px;
    text-align: center;
} */

.dk-adsTrackTableCon .dk-adsTrackTable thead tr th:nth-child(08),
.dk-adsTrackTableCon .dk-adsTrackTable tbody tr td:nth-child(08) {
    column-width: 522px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .col-md-3.dk-leveadstopLeftCols,
    .col-md-3.dk-leveadstopRightCols {
        flex: 0 0 22%;
        max-width: 22%;
    }

    .col-md-6.dk-leveadstopCenterCols {
        flex: 0 0 56%;
        max-width: 56%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .col-md-3.dk-leveadstopLeftCols,
    .col-md-3.dk-leveadstopRightCols {
        flex: 0 0 22%;
        max-width: 22%;
    }

    .col-md-6.dk-leveadstopCenterCols {
        flex: 0 0 56%;
        max-width: 56%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .col-md-3.dk-leveadstopLeftCols,
    .col-md-3.dk-leveadstopRightCols {
        flex: 0 0 22%;
        max-width: 22%;
    }

    .col-md-6.dk-leveadstopCenterCols {
        flex: 0 0 56%;
        max-width: 56%;
    }
}