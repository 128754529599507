.dk-rolesresponPageMain {
    margin-top: 110px;
    padding: 0 15px;

    .dk-pageheader {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        margin-bottom: 10px;

        h3.title {
            font-weight: 500;
            margin: 0;
            position: absolute;
            left: 50%;
            top: 20px;
            transform: translate(-50%, -50%);
            font-size: 19px;

            @media(max-width:767px) {
                width: 100%;
                text-align: center !important;
            }
        }

        .dk-teamrolerightBtnCon {
            margin-top: 35px;

            .dk-teamroleseditBtn {
                a {
                    display: inline-block;
                    background: #f2f2f2;
                    color: #333;
                    border: 1px solid #ccc;
                    padding: 6px 12px;
                    white-space: nowrap;
                    border-radius: 3px;
                    outline: 0;
                    width: 100%;
                    text-align: center;
                    position: relative;
                }
            }
        }
    }

    .dk-rolesresponTableCon.dk-mainmodule {
        table {
            margin-bottom: 0;
            border-bottom: none;
            border: none;
            border-left: 1px solid transparent;

            thead.dk-rolesTeamHeader {
                tr {
                    th {
                        border: 1px solid #877c7c !important;

                        &:nth-child(01) {
                            border: none !important;
                        }
                    }
                }
            }

            tr {
                th {
                    color: #333;
                    background: #E7E6E6;
                    border: 1px solid #ddbbbb;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
                    text-transform: uppercase;
                    border-bottom: none;
                    white-space: nowrap;

                    &:first-child {
                        border: 1px solid transparent !important;
                        border-right: 1px solid #ccc !important;
                        border-bottom: 1px solid #ccc !important;
                        border-left: none !important;
                        background-color: transparent;
                    }

                    &:nth-child(02) {
                        background-color: #5C737D;
                        color: #fff;
                    }

                    &:nth-child(03) {
                        background-color: #57496d;
                        color: #fff;
                    }

                    &:nth-child(04) {
                        background-color: #007d7f;
                        color: #fff;
                    }

                    &:nth-child(05) {
                        background-color: #007274;
                        color: #fff;
                    }

                    &:nth-child(06) {
                        background-color: #396A95;
                        color: #fff;
                    }
                }

                td {
                    color: #f2f2f2;
                    border: 1px solid #857d7d;
                    font-size: 13px;
                    font-weight: 400;
                    padding: 6px 12px !important;
                    text-align: center;
                    word-spacing: -1px;
                    border-top: none !important;
                    vertical-align: middle;
                    width: 16.66%;

                    &:nth-child(01) {
                        text-transform: uppercase;
                        background-color: #eaeaea;
                        color: #333;
                        width: 11%;
                        border: 1px solid #ccc !important;
                        border-right: none !important;
                        border-left: 1px solid #ccc !important;
                        text-align: center;
                    }

                    &:nth-child(02) {
                        background: #5C737D;
                    }

                    &:nth-child(03) {
                        background-color: #57496d;
                    }

                    &:nth-child(04) {
                        background-color: #007d7f;
                    }

                    &:nth-child(05) {
                        background-color: #007274;
                    }

                    &:nth-child(06) {
                        background-color: #396a95;
                    }

                    .dk-teamImgCols {
                        display: flex;
                        padding: 0 0.5rem;
                        justify-content: center;

                        .dk-team1 {
                            img {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                                border: 1px solid #dddd;
                            }
                        }
                    }

                    p {
                        margin: 10px 0;
                    }
                }

                td.team2BG {
                    background-color: #007d7f;
                }

                td.team2BG.light {
                    background-color: #57496d;
                }

                td.team3BG {
                    background-color: #007d7f;
                }

                td.team4BG {
                    background-color: #396A95;
                }

                &:nth-child(07) {
                    td {
                        text-align: left;
                    }
                }
            }
        }
    }
}

.dk-rolesresponPageMain.dk-edit-rolesresponPageMain {
    table {
        tr {
            td {
                cursor: pointer;
            }
        }
    }
}

.dk-teamrolesjobsummaryModal {
    .modal-dialog {
        min-width: 60%;

        .modal-content {
            border-radius: 5px;

            .modal-header {
                padding: 7px 15px;
                background: #d9d9d9;
                border-radius: 5px 5px 0 0;

                .modal-title {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                    color: #333;
                    font-family: 'Poppins', sans-serif;
                }

                .close {
                    padding: 0;
                    color: #333;
                    opacity: 1;
                    text-shadow: none;
                    font-size: 24px;
                    position: absolute;
                    right: 28px;
                    top: 24px;
                    outline: 0;
                }
            }

            .modal-body {
                .dk-editRoles-modalBoy {
                    .dk-teamRole-editor {
                        .ck-content {
                            min-height: 120px;
                        }
                    }

                    button.dk-saveBtn {
                        display: inline-block;
                        background: #234158;
                        border: 1px solid #234158;
                        padding: 10px 18px;
                        border: none;
                        color: #fff;
                        font-weight: 500;
                        font-size: 14px;
                        border-radius: 3px;
                        outline: 0;
                        margin: 15px 0 10px;
                        text-transform: uppercase;

                        &:hover {
                            background: #007D7F;
                            transition: all 0.5s;
                        }
                    }

                    .dk-addRecuirters {
                        .dk-addMoreUser {
                            font-size: 11px;
                            background: #d5cccc;
                            border-radius: 50%;
                            line-height: 30px;
                            width: 30px;
                            height: 30px;
                            color: #6c6c6c;
                            cursor: pointer;
                            text-align: center;
                            font-weight: 500;
                            display: inline-block;
                            margin: 0 8px;
                        }

                        .dk-addUserDropdown {
                            display: inline-block;

                            .dropdown-toggle {
                                margin: 0;
                                padding: 0;
                                box-shadow: none;
                                border: none;
                                width: 30px;
                                height: 30px;
                                display: inline-block;

                                &::after {
                                    display: none;
                                }

                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }

                            .dropdown-menu {
                                padding: 5px 7px;
                                transform: inherit !important;
                                left: 45px !important;
                                right: inherit !important;
                                box-shadow: none;
                                border: 1px solid #234158;
                                top: -8px !important;
                                background: #234158;

                                &::before {
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 8px 12px 8px 0;
                                    border-color: transparent #234158 transparent transparent;
                                    line-height: 0px;
                                    _border-color: #000000 #cccccc #000000 #000000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                                    position: absolute;
                                    left: -12px;
                                    top: 15px;
                                }

                                a {
                                    img {
                                        width: 35px;
                                        height: 35px;
                                        border-radius: 50%;
                                    }

                                    margin-right: 8px;

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-rolesresponPageMain.dk-trainingTeamRolesMain {
    margin-top: 110px;

    .dk-pageheader {
        position: relative;

        .dk-backBtn-cols {
            position: absolute;
            left: 0;
            display: inline-block;
            white-space: nowrap;

            a {
                max-width: 9.09% !important;
                display: inline-block;
                text-align: center;
                color: #333;

                &:hover {
                    color: #3c97b6;
                }
            }
        }
    }
}

.dk-rolesresponPageMain.dk-trainingTeamRolesMain.dk-trainingTeamRolesMainRecruitment {
    margin-top: 110px;

    @media(max-width:991px) {
        margin-top: 175px
    }

    .dk-rolesresponTableCon {
        @media(max-width:1199px) {
            overflow-x: auto;
        }

        table {
            @media(max-width:1199px) {
                width: 1200px;
            }
        }
    }
}