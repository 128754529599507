.dk-actonrecordModal {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        // width: 50%;
        min-width: 620px !important;
        max-width: 620px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            // overflow-y: auto;
            border-radius: 0px;

            .dk-actonrecordModalHeader {
                background-color: #234158;
                padding: 12px 10px;
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: none;
                position: relative;

                .dk-userimgCon {
                    position: absolute;
                    left: 15px;
                    top: 10px;

                    img.dk-userimg {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                }

                .modal-title {
                    color: #fff;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    opacity: 0.95;
                    font-size: 16px;
                    position: relative;
                }

                .dk-date-dayCon {
                    position: absolute;
                    top: 15px;
                    right: 15%;
                    color: #fff;
                }

                .close {
                    text-shadow: none;
                    color: #fff;
                    margin: 0;
                    padding: 0;
                    width: 40px;
                    height: 50px;
                    opacity: 0.9;
                    position: absolute;
                    right: 3px;
                    top: 0px;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dk-actonrecordModalBody {
                background-color: #eaeaea;
                padding: 0;
                height: calc(100vh - 40px);

                .dk-tasktrackingPillsCon {
                    .nav-dk-tasktrackingPills {
                        margin-bottom: 0;
                        border-bottom: none;

                        .dk-tasktrackingNavItem {
                            width: 100%;
                            max-width: 20%;

                            .nav-link {
                                background-color: #2685A5;
                                color: #fff;
                                text-align: center;
                                width: 100%;
                                font-size: 14px;
                                padding: 9px 8px;
                                border-right: 1px solid #777676;
                                border-bottom: none;

                                &.active {
                                    background-color: #eaeaea;
                                    color: #333;
                                    border-right: #ddd;
                                }
                            }
                        }
                    }
                }

                .dk-tasktracktabContent {
                    padding: 15px;

                    .dk-tasktracktabpane {
                        &.dk-trackingTabPane {
                            .dk-dailyTrackingTabMain {
                                margin-top: 25px;

                                .form-group {
                                    margin-bottom: 1rem;

                                    .dk-select-timeDropdwn {
                                        .dk-select-time-btn {
                                            box-shadow: none;
                                            margin: 0;
                                            padding: 6px 10px;
                                            width: 100%;
                                            background-color: #f2f2f2;
                                            border: 1px solid #c5c5c5;
                                            font-size: 13px;
                                            text-transform: capitalize;
                                            border-radius: 0;
                                            color: #000;
                                            text-align: left;

                                            &::after {
                                                position: absolute;
                                                right: 10px;
                                                top: 15px;
                                            }
                                        }

                                        .dk-selecttimeMenu {
                                            border-radius: 0;
                                            width: 100%;
                                            padding: 0;
                                            background-color: #f2f2f2;

                                            .dropdown-item {
                                                padding: 0.35rem 0.75rem;
                                                border-bottom: 1px solid #ccc;
                                                font-size: 13px;

                                                &:last-child {
                                                    border-bottom: none;
                                                }
                                            }
                                        }
                                    }

                                    .dk-datepickerInputFrm {
                                        input {
                                            border: 1px solid #c5c5c5;
                                            background-color: #f2f2f2;
                                            color: #000;
                                            border-radius: 0;
                                            padding: 12px 10px;
                                            font-size: 13px;
                                            height: 33px;

                                            &:focus {
                                                outline: 0;
                                                box-shadow: none;
                                            }

                                            &::placeholder {
                                                /* Chrome, Firefox, Opera, Safari 10.1+ */
                                                color: #000;
                                                opacity: 1;
                                                /* Firefox */
                                            }

                                            &:-ms-input-placeholder {
                                                /* Internet Explorer 10-11 */
                                                color: #000;
                                            }

                                            &::-ms-input-placeholder {
                                                /* Microsoft Edge */
                                                color: #000;
                                            }
                                        }

                                        .input-group-append {
                                            .input-group-text {
                                                border-radius: 0;
                                                border: 1px solid #c5c5c5;
                                                border-left: none;

                                                i {
                                                    color: #333;
                                                }
                                            }
                                        }
                                    }

                                    &.dk-subjectFrmGrp {
                                        .input-group {
                                            .input-group-append {
                                                .input-group-text {
                                                    padding: 0 0.5rem;
                                                    background-color: #ccc;
                                                    height: 36px;
                                                    border: 1px solid #c5c5c5;
                                                    border-right: none;
                                                }
                                            }

                                            input {
                                                border: 1px solid #c5c5c5;
                                                background-color: #f2f2f2;
                                                color: #000;
                                                border-radius: 0;
                                                padding: 12px 10px;
                                                font-size: 13px;
                                                height: 36px;

                                                &:focus {
                                                    outline: 0;
                                                    box-shadow: none;
                                                }

                                                &::placeholder {
                                                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                                                    color: #000;
                                                    opacity: 1;
                                                    /* Firefox */
                                                }

                                                &:-ms-input-placeholder {
                                                    /* Internet Explorer 10-11 */
                                                    color: #000;
                                                }

                                                &::-ms-input-placeholder {
                                                    /* Microsoft Edge */
                                                    color: #000;
                                                }
                                            }
                                        }
                                    }

                                    &.dk-trackingFrmGrp {
                                        textarea {
                                            height: auto;
                                            border-radius: 0;

                                            &:focus {
                                                outline: 0;

                                            }
                                        }
                                    }
                                }

                                .dk-rightActivitybtnCon {
                                    display: flex;
                                    justify-content: center;
                                    width: 100%;

                                    .dk-rightActivitybtn {
                                        background: #2685A5;
                                        color: #fff;
                                        border: 1px solid #2685A5;
                                        width: 100px;
                                        text-align: center;
                                        display: inline-block;
                                        padding: 5px 12px;
                                        text-transform: uppercase;
                                        font-size: 13px;
                                        line-height: 1.5385;
                                        border-radius: 0.1875rem;
                                    }
                                }

                                .dk-activityListCont {
                                    margin-top: 25px;

                                    ul {
                                        list-style: none;
                                        padding-left: 0;
                                        margin-bottom: 0;
                                        max-height: 230px;
                                        overflow-y: auto;

                                        li.dk-activityList {
                                            display: flex;
                                            flex-flow: row wrap;
                                            font-size: 13px;
                                            border: 1px solid #c5c5c5;
                                            margin: 12px 0;
                                            background-color: #f2f2f2;

                                            .dk-timeCon {
                                                padding: 0.75rem 0.5rem;
                                                border-right: 1px solid #c5c5c5;
                                                white-space: nowrap;
                                                min-width: 75px;
                                                text-align: center;
                                            }

                                            .dk-dateCon {
                                                padding: 0.75rem 0.5rem;
                                                border-right: 1px solid #c5c5c5;
                                                white-space: nowrap;
                                                min-width: 80px;
                                                text-align: center;
                                            }

                                            .subjectCon {
                                                padding: 0.75rem 0.5rem;
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.dk-main-notesTabPane {
                            .dk-notes-main-tabCon {
                                .dk-addnewFrmGrp {
                                    margin-bottom: 1rem;

                                    label {
                                        color: #787777;
                                        font-size: 0.9125rem;
                                        text-align: left;
                                    }

                                    textarea {
                                        border: 1px solid #c5c5c5;
                                        padding-left: 10px;
                                        padding-right: 10px;
                                        background-color: #f2f2f2;
                                        font-size: 14px;
                                        border-radius: 0;

                                        &:focus {
                                            box-shadow: none;
                                            outline: 0;
                                        }
                                    }
                                }

                                .dk-taskactsavebtnCon {
                                    display: flex;
                                    justify-content: center;
                                    width: 100%;

                                    .dk-taskactsavebtn {
                                        background: #2685A5;
                                        color: #fff;
                                        border: 1px solid #2685A5;
                                        width: 100px;
                                        text-align: center;
                                        display: inline-block;
                                        padding: 5px 12px;
                                        text-transform: uppercase;
                                        font-size: 13px;
                                        line-height: 1.5385;
                                        border-radius: 0.1875rem;
                                    }
                                }

                                .dk-shownotesListSec {
                                    margin-top: 1.25rem;

                                    ul.dk-shownotesListCon {
                                        color: #333;
                                        list-style: none;
                                        padding-inline-start: 0;
                                        margin-bottom: 0;
                                        max-height: 330px;
                                        overflow-y: auto;

                                        li.dk-shownotesList {
                                            background-color: #f2f2f2;
                                            padding: 7px;
                                            border: 1px solid #ddd;
                                            margin: 12px 0;
                                            cursor: pointer;

                                            .react-contextmenu-wrapper {
                                                .dk-totasktitle {
                                                    text-align: justify;
                                                    font-size: 13px;
                                                }
                                            }

                                            .dk-notesrightDropMenu {
                                                background-color: #fff;
                                                min-width: 130px;
                                                border-radius: 0;
                                                padding: 0;

                                                .react-contextmenu-item {
                                                    border-bottom: 1px solid #ddd;
                                                    padding: 6px 12px;
                                                    font-size: 14px;
                                                    cursor: pointer;

                                                    &:hover {
                                                        background: #2980b9;
                                                        color: #fff !important;

                                                        i {
                                                            color: #fff;
                                                        }
                                                    }

                                                    i {
                                                        display: inline-block;
                                                        width: 18px;
                                                        color: #2980b9;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.dk-create-task-TabPane {
                            .dk-task-tab-MainCon {
                                margin-top: 5px;

                                .dk-create-newtask-header {
                                    background: lightgrey;
                                    color: #fff;
                                    padding: 6px 0 5px 13px;
                                    border: 1px solid lightgrey;
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    position: relative;

                                    .dk-reseticonCon {
                                        position: absolute;
                                        left: 15px;
                                        cursor: pointer;

                                        i {
                                            color: #333;
                                            cursor: pointer;
                                            opacity: 0.85;
                                        }
                                    }

                                    .modal-title {
                                        margin-top: 0;
                                        font-size: 15px;
                                        font-weight: 500;
                                        font-family: Roboto, sans-serif;
                                        text-align: center;
                                        text-transform: uppercase;
                                        color: #333;
                                        opacity: 0.85;
                                    }
                                }

                                .dk-create-newtask-body {
                                    background-color: #fff;
                                    padding: 15px;
                                    max-height: calc(100vh - 145px);
                                    overflow-y: auto;

                                    .from-group {
                                        margin-bottom: 0.5rem;

                                        label {
                                            font-size: 13px;
                                            display: block;
                                            margin-bottom: 2px;
                                            color: #737373;
                                            text-align: left;
                                        }

                                        .dk-tasktype-dropdown {
                                            .dk-tasktype-dropdown-toggle {
                                                box-shadow: none;
                                                border: 1px solid #c1c9dc;
                                                padding: 5px 14px;
                                                height: 34px;
                                                border-radius: 3px;
                                                background: #fff;
                                                margin-top: 0px;
                                                font-family: Verdana, sans-serif;
                                                font-size: 14px;
                                                text-transform: capitalize;
                                                line-height: 22px;
                                                color: #797474;
                                                width: 100%;
                                                position: relative;
                                                margin: 0;

                                                &::after {
                                                    position: absolute;
                                                    right: 10px;
                                                    top: 15px;
                                                }
                                            }

                                            .dk-tasktype-dropdown-menu {
                                                padding: 0;
                                                border-radius: 0;
                                                width: 100%;
                                                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                                                border: none;

                                                .dropdown-item {
                                                    padding: 7px 10px;
                                                    font-size: 13px;
                                                    color: #333;
                                                }
                                            }
                                        }

                                        .dk-taskByCon {
                                            border-radius: 3px;
                                            height: 35px;
                                            padding: 0 10px !important;
                                            min-height: 35px;
                                            line-height: 35px;
                                            box-shadow: none;
                                            border: 1px solid rgba(34, 36, 38, .15);
                                            display: flex;
                                            align-items: center;

                                            .dk-selectator-textlength {
                                                display: flex;

                                                &:first-child {
                                                    margin-right: 0.5rem;
                                                }

                                                &:last-child {
                                                    margin-right: 0;
                                                }

                                                .dk-selected-items {
                                                    display: flex;
                                                    background-color: #fafafa;
                                                    border: 1px solid #c1c9dc;
                                                    width: 44px;
                                                    justify-content: space-between;
                                                    height: 25px;
                                                    align-items: center;
                                                    padding: 3px 5px;
                                                    border-radius: 2px;
                                                    position: relative;

                                                    .userImgCont {
                                                        img {
                                                            width: 15px;
                                                            height: 15px;
                                                        }
                                                    }

                                                    .dk-selecteditem-remove {
                                                        display: inline-block;
                                                        font-weight: 700;
                                                        color: rgba(0, 0, 0, .75);
                                                        margin: 0 0 0 5px;
                                                        cursor: pointer;
                                                        font-size: 60%;
                                                        line-height: 10px;
                                                        vertical-align: top;
                                                        border-radius: 0 2px 2px 0;
                                                        position: absolute;
                                                        right: 0;
                                                        top: 0;
                                                        bottom: 0;
                                                        padding: 7px 5px 4px;
                                                    }
                                                }
                                            }
                                        }

                                        .dk-taskSwitch {
                                            color: #333;

                                            li.tg-list-item {
                                                list-style-type: none;
                                            }
                                        }
                                    }

                                    .form-group.dk-create-date-timeFrmGrp {
                                        label {
                                            font-size: 13px;
                                            display: block;
                                            color: #737373;
                                            text-align: left;

                                            &.dk-TaskDate {
                                                font-size: 13px;
                                                display: block;
                                                margin-bottom: 2px;
                                                color: #737373;
                                                text-align: left;

                                                input {
                                                    border-top-right-radius: 0;
                                                    border-bottom-right-radius: 0;
                                                    border-right: none;
                                                    height: 35px;
                                                }
                                            }

                                            &.dk-TaskTime {
                                                font-size: 13px;
                                                display: block;
                                                margin-bottom: 2px;
                                                color: #737373;
                                                text-align: left;

                                                input {
                                                    border-top-left-radius: 0;
                                                    border-bottom-left-radius: 0;
                                                    height: 35px;
                                                }
                                            }

                                            &.dk-taskChoosefile {
                                                position: relative;
                                                border: 1px solid #ddd;
                                                border-radius: 3px;

                                                .file-select-name {
                                                    font-size: 12px;
                                                    display: inline-block;
                                                    position: absolute;
                                                    top: 8px;
                                                    left: 5px;
                                                }

                                                input {
                                                    width: 100%;
                                                    height: 35px;
                                                    position: relative;
                                                    z-index: 99;
                                                    opacity: 0;
                                                    border-radius: 3px;
                                                    border: 1px solid #ddd !important;
                                                    box-shadow: none !important;
                                                }

                                                span {
                                                    background: #d6dce5;
                                                    padding: 0 25px;
                                                    display: inline-block;
                                                    height: 35px;
                                                    line-height: 35px;
                                                    position: absolute;
                                                    top: 0;
                                                    right: 0;
                                                    z-index: 9;
                                                    width: 100%;
                                                    cursor: pointer;
                                                }
                                            }

                                            .dk-subjectFrmControl {
                                                border-radius: 3px;
                                                height: 35px;
                                                padding: 0 10px !important;
                                                min-height: 35px;
                                                line-height: 35px;
                                            }
                                        }

                                        textarea.form-control {
                                            min-height: 125px;
                                            border: 1px solid #c1c9dc !important;
                                            padding: 12px 14px;
                                            background: #fff;
                                            border-radius: 3px;
                                            font-size: 14px;
                                            color: #4f5152;
                                            font-family: Roboto, sans-serif;
                                        }
                                    }

                                    .form-group.dk-createNewTaskFooter {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .dk-create-new-taskbtn {
                                            background-color: darkgrey !important;
                                            border: none;
                                            border-radius: 2px;
                                            display: inline-block;
                                            height: 36px;
                                            line-height: 36px;
                                            padding: 0 2rem;
                                            text-transform: uppercase;
                                            vertical-align: middle;
                                            -webkit-tap-highlight-color: transparent;
                                            font-size: 1rem;
                                            outline: 0;
                                            text-decoration: none;
                                            color: #333 !important;
                                            text-align: center;
                                            letter-spacing: .5px;
                                            transition: .2s ease-out;
                                            margin-top: 0;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }

                        color: #333;

                        .dk-todolistPillsCon {
                            .nav-dk-todolistPills {
                                color: #eaeaea;

                                .dk-todolistNavItem {
                                    width: 100%;
                                    max-width: 20%;
                                    white-space: nowrap;

                                    .nav-link {
                                        text-align: center;
                                        width: 100%;
                                        font-size: 13px;
                                        padding: 8px 5px;
                                        border: 1px solid #ccc;
                                        border-right: none;
                                        color: #333;
                                        background-color: #fff;
                                        border-radius: 0;
                                        cursor: pointer;

                                        &.dk-todoallBg {
                                            color: #234158;
                                        }

                                        &.active.dk-todoallBg {
                                            background-color: #234158;
                                            border: 1px solid #234158;
                                            color: #fff;
                                            border-right: none;
                                            cursor: pointer;
                                        }

                                        &.todoListBg {
                                            color: #2685a5;
                                        }

                                        &.active.todoListBg {
                                            background-color: #2685a5;
                                            border: 1px solid #2685a5;
                                            color: #fff;
                                            border-right: none;
                                            cursor: pointer;
                                        }

                                        &.inprogressBg {
                                            color: #D58B5D;
                                        }

                                        &.active.inprogressBg {
                                            background-color: #D58B5D;
                                            border: 1px solid #D58B5D;
                                            color: #fff;
                                            border-right: none;
                                            cursor: pointer;
                                        }

                                        &.completedBg {
                                            color: #3A9469;
                                        }

                                        &.active.completedBg {
                                            background-color: #3A9469;
                                            border: 1px solid #3A9469;
                                            color: #fff;
                                            border-right: none;
                                            cursor: pointer;
                                        }

                                        &.todoaddNewBg {
                                            color: #234158;
                                        }

                                        &.active.todoaddNewBg {
                                            background-color: #f2f2f2;
                                            border: 1px solid #ccc;
                                            color: #333;
                                            cursor: pointer;
                                        }

                                        & i {
                                            color: #787777;
                                        }
                                    }

                                    &:last-child {
                                        .nav-link {
                                            border-right: 1px solid #ccc;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-todolistContent {
                            .dk-todolistpane {
                                padding: 15px 0;

                                &.sumary-Tabpane {
                                    .dk-summary-staticsCon {
                                        .dk-todo-summarytable {
                                            margin-bottom: 1rem;

                                            thead {
                                                tr th {
                                                    background-color: #234158;
                                                    border: 1px solid #808080;
                                                    color: #fff;
                                                    text-align: center;
                                                    font-size: 13px;
                                                    padding: 0.45rem 0.5rem;
                                                }
                                            }

                                            tbody {
                                                tr td {
                                                    background-color: #f1f1f1;
                                                    border: 1px solid #ddd;
                                                    color: #333;
                                                    text-align: center;
                                                    font-size: 13px;
                                                    padding: 0.45rem 0.5rem;
                                                }
                                            }
                                        }
                                    }

                                    .dk-createnewtask-Con {
                                        .dk-createnewtaskTitle {
                                            margin-bottom: 0.5rem;

                                            h2 {
                                                width: 100%;
                                                text-align: left;
                                                font-size: 17px;
                                                text-transform: capitalize;
                                                font-weight: 500;
                                            }
                                        }

                                        .dk-createnewtask-listCon {
                                            list-style: none;
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 1.5rem;

                                            .dk-createnewtask-list {
                                                width: 100%;
                                                max-width: 25%;
                                                cursor: pointer;
                                                background: #f1f1f1;
                                                padding: 0.35rem;
                                                border: 1px solid #dddd;
                                                border-right: none;

                                                &:last-child {
                                                    border-right: 1px solid #ddd;
                                                }

                                                &.active {
                                                    background-color: #2685A5;
                                                    color: #fff;
                                                }

                                                .dk-img-text-con {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;

                                                    img {
                                                        width: 20px;
                                                        height: 20px;
                                                    }

                                                    .tasktype-text {
                                                        font-size: 14px;
                                                        text-transform: capitalize;
                                                        margin-left: 0.75rem;
                                                    }
                                                }
                                            }
                                        }

                                        .dk-taskprocess-cardCon {
                                            height: calc(100vh - 330px);
                                            overflow-y: auto;

                                            .dk-taskprocess-card {
                                                background-color: #f1f1f1;
                                                border-radius: 5px;
                                                padding: .5rem .75rem;
                                                margin: 12px 0;

                                                .dk-taskprocess-card-body {
                                                    display: flex;
                                                    justify-content: flex-start;

                                                    .dk-user-imgCon {
                                                        width: 70px;
                                                        height: 70px;
                                                        display: none;

                                                        img {
                                                            width: 100%;
                                                            height: auto;
                                                            border-radius: 50%;
                                                            border: 2px solid #ccc;
                                                        }
                                                    }

                                                    .dk-inprogrestextCon {
                                                        margin-left: 7px;

                                                        .dk-recruiterName {
                                                            margin-bottom: 0.35rem;
                                                            font-size: 16px;
                                                            margin-top: 7px;
                                                        }

                                                        .dk-taskdetails {
                                                            font-size: 14px;
                                                            text-align: justify;
                                                        }
                                                    }

                                                    .dk-counter-commentCon {
                                                        display: flex;
                                                        justify-content: flex-start;
                                                        margin-top: 7px;

                                                        .dk-counterCon {
                                                            padding-right: 10px;
                                                        }

                                                        .dk-commentCon {
                                                            display: flex;

                                                            i {
                                                                margin-top: 3px;
                                                            }

                                                            .dk-commentNumber {
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;
                                                                width: 22px;
                                                                height: 22px;
                                                                border-radius: 50%;
                                                                font-size: 13px;
                                                                background-color: #ccc;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.dk-todolistpaneAddNew {
                                    .dk-addnewFrmGrp {
                                        margin-bottom: 1rem;

                                        label {
                                            color: #787777;
                                            font-size: 0.9125rem;
                                            text-align: left;
                                        }

                                        textarea {
                                            border: 1px solid #c5c5c5;
                                            padding-left: 10px;
                                            padding-right: 10px;
                                            background-color: #f2f2f2;
                                            font-size: 14px;
                                            border-radius: 0;

                                            &:focus {
                                                box-shadow: none;
                                                outline: 0;
                                            }
                                        }
                                    }

                                    .dk-taskactsavebtnCon {
                                        display: flex;
                                        justify-content: center;
                                        width: 100%;

                                        .dk-taskactsavebtn {
                                            background: #2685A5;
                                            color: #fff;
                                            border: 1px solid #2685A5;
                                            width: 100px;
                                            text-align: center;
                                            display: inline-block;
                                            padding: 5px 12px;
                                            text-transform: uppercase;
                                            font-size: 13px;
                                            line-height: 1.5385;
                                            border-radius: 0.1875rem;
                                        }
                                    }
                                }

                                .dk-todolistContainer {
                                    margin: 0;
                                    padding: 0;
                                    list-style: none;
                                    list-style-position: inside;
                                    height: calc(100vh - 170px);
                                    overflow-y: auto;

                                    li {
                                        margin: 12px 0;

                                        .dk-tasklist {
                                            width: 100%;
                                            display: flex;
                                            flex-wrap: wrap;
                                            line-height: 1.58rem;

                                            .dk-taslistCon {
                                                .react-contextmenu-wrapper {
                                                    display: inline-block;

                                                    .dk-todo-dropdowncon {
                                                        .dropdown {
                                                            .dk-todo-dropdownbtn.dropdown-toggle {
                                                                margin: 0;
                                                                padding: 0;
                                                                box-shadow: none;
                                                                background-color: transparent;
                                                                margin-left: 4px;
                                                                font-size: 14px;
                                                                text-transform: capitalize;
                                                                font-weight: 600;

                                                                &::after {
                                                                    display: none;
                                                                }
                                                            }

                                                            .dk-todo-dropdown-menu {
                                                                padding: 0;
                                                                border-radius: 0;

                                                                .dropdown-item {
                                                                    padding: 0.35rem 0.75rem;
                                                                    border-bottom: 1px solid #ccc;

                                                                    &:last-child {
                                                                        border-bottom: none;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .todorightDropmenu {
                                                border-radius: 0;
                                                background-color: #fff;
                                                min-width: 130px;
                                                z-index: 999;

                                                .react-contextmenu-item {
                                                    border-bottom: 1px solid #ddd;
                                                    padding: 6px 12px;
                                                    font-size: 14px;
                                                    cursor: pointer;

                                                    &:hover {
                                                        background: #2980b9;
                                                        color: #fff !important;

                                                        i {
                                                            color: #fff;
                                                        }
                                                    }

                                                    i {
                                                        display: inline-block;
                                                        width: 18px;
                                                        color: #2980b9;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .dk-todolistPillsCon {
                    .nav-dk-todolistPills {
                        color: #eaeaea;
                        position: relative;

                        .dk-todolistNavItem {
                            width: 100%;
                            max-width: 20%;
                            white-space: nowrap;

                            &.dkg-task-modal-nav-item {
                                max-width: 25% !important;
                            }

                            .nav-link {
                                background-color: #fff;
                                color: #333;
                                text-align: center;
                                width: 100%;
                                font-size: 14px;
                                padding: 14px 8px;
                                border-right: 1px solid #ddd;
                                border-bottom: none;

                                &.active {
                                    background-color: #eaeaea;
                                    color: #333;
                                    border-right: #ddd;

                                    span {
                                        color: #fff;
                                    }
                                }

                                span {
                                    font-size: 14px;
                                    margin-left: 0.35rem;
                                    font-weight: 600;

                                    &.dk-todolistNo {
                                        // background-color: #2685a5;
                                        color: #2685a5;
                                    }

                                    &.dk-inprohressNo {
                                        color: #D58B5D;
                                    }

                                    &.dk-compeletedNo {
                                        color: #3A9469;
                                    }
                                }

                                // &.dk-todoallBg{
                                //     color: #234158;
                                // }
                                // &.active.dk-todoallBg {
                                //     background-color: #234158;
                                //     border: 1px solid #234158;
                                //     color: #fff;
                                //     border-right: none;
                                //     cursor: pointer;
                                // }
                                // &.todoListBg{
                                //     color: #2685a5;
                                // }
                                // &.active.todoListBg {
                                //     background-color: #2685a5;
                                //     border: 1px solid #2685a5;
                                //     color: #fff;
                                //     border-right: none;
                                //     cursor: pointer;
                                // }
                                // &.inprogressBg{
                                //     color: #D58B5D;
                                // }
                                // &.active.inprogressBg {
                                //     background-color: #D58B5D;
                                //     border: 1px solid #D58B5D;
                                //     color: #fff;
                                //     border-right: none;
                                //     cursor: pointer;
                                // }
                                // &.completedBg{
                                //     color: #3A9469;
                                // }
                                // &.active.completedBg {
                                //     background-color: #3A9469;
                                //     border: 1px solid #3A9469;
                                //     color: #fff;
                                //     border-right: none;
                                //     cursor: pointer;
                                // }
                                // &.todoaddNewBg{
                                //     color: #234158;
                                // }
                                // &.active.todoaddNewBg {
                                //     background-color: #f2f2f2;
                                //     border: 1px solid #ccc;
                                //     color: #333;
                                //     cursor: pointer;
                                // }
                                // & i{
                                //     color: #787777;
                                // }
                            }

                            &.dkg-adnew-notes-tab {
                                position: absolute;
                                left: 10px;
                                top: -54px;

                                .nav-link {
                                    background-color: transparent !important;
                                    color: #fff !important;
                                    border-right: none;
                                    text-align: left;

                                    i {
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 50%;
                                        background-color: #eaeaea;
                                        color: #333;

                                        &:hover {
                                            opacity: 0.75;
                                            background-color: #eaeaea;
                                            color: #333;
                                        }
                                    }
                                }
                            }

                            // &:last-child {
                            //     .nav-link {
                            //         border-right: 1px solid #ccc;

                            //         i {
                            //             color: #808080;
                            //         }
                            //     }
                            // }
                        }
                    }
                }

                .dk-todolistContent {
                    .dk-todolistpane {
                        padding: 20px 15px;
                        height: calc(100vh - 75px);
                        overflow-y: auto;

                        /* Hide scrollbar for Chrome, Safari and Opera */
                        &::-webkit-scrollbar {
                            cursor: pointer;
                            scrollbar-width: none;
                        }

                        /* Hide scrollbar for IE, Edge and Firefox */
                        // & {
                        //     -ms-overflow-style: none;
                        //     scrollbar-width: none;
                        // }

                        &.sumary-Tabpane {
                            .dk-summary-staticsCon {
                                margin-top: 15px;
                                margin-bottom: 25px;

                                .dk-todo-summarytable {
                                    margin-bottom: 1rem;

                                    thead {
                                        tr th {
                                            background: #252c37;
                                            color: #e2dada;
                                            border: 1px solid #616767;
                                            text-align: center;
                                            font-size: 13px;
                                            padding: 0.45rem 0.5rem;
                                            width: 100%;
                                            max-width: 16.66%;
                                        }
                                    }

                                    tbody {
                                        tr td {
                                            background: #2c394b;
                                            color: #bdb4b4;
                                            border: 1px solid #5a6169;
                                            text-align: center;
                                            font-size: 13px;
                                            padding: 0.45rem 0.5rem;
                                            white-space: nowrap;
                                            width: 100%;
                                            max-width: 16.66%;
                                        }
                                    }
                                }
                            }

                            .dk-createnewtask-Con {
                                .dk-createnewtaskTitle {
                                    margin-bottom: 0.5rem;

                                    h2 {
                                        width: 100%;
                                        text-align: left;
                                        font-size: 17px;
                                        text-transform: capitalize;
                                        font-weight: 500;
                                    }
                                }

                                .dk-createnewtask-listCon {
                                    list-style: none;
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 1.5rem;

                                    .dk-createnewtask-list {
                                        width: 100%;
                                        max-width: 25%;
                                        cursor: pointer;
                                        background: #f1f1f1;
                                        padding: 0.35rem;
                                        border: 1px solid #dddd;
                                        border-right: none;

                                        &:last-child {
                                            border-right: 1px solid #ddd;
                                        }

                                        &.active {
                                            background-color: #2685A5;
                                            color: #fff;
                                        }

                                        .dk-img-text-con {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            img {
                                                width: 20px;
                                                height: 20px;
                                            }

                                            .tasktype-text {
                                                font-size: 14px;
                                                text-transform: capitalize;
                                                margin-left: 0.75rem;
                                            }
                                        }
                                    }
                                }

                                .dk-taskprocess-cardCon {
                                    height: calc(100vh - 275px);
                                    overflow-y: auto;

                                    .dk-taskprocess-card {
                                        background-color: #f1f1f1;
                                        border-radius: 5px;
                                        padding: .5rem .75rem;
                                        margin: 12px 0;

                                        .dk-taskprocess-card-body {
                                            display: flex;
                                            justify-content: flex-start;

                                            .dk-user-imgCon {
                                                width: 70px;
                                                height: 70px;
                                                display: none;

                                                img {
                                                    width: 100%;
                                                    height: auto;
                                                    border-radius: 50%;
                                                    border: 2px solid #ccc;
                                                }
                                            }

                                            .dk-inprogrestextCon {
                                                margin-left: 7px;

                                                .dk-recruiterName {
                                                    margin-bottom: 0.35rem;
                                                    font-size: 16px;
                                                    margin-top: 7px;
                                                }

                                                .dk-taskdetails {
                                                    font-size: 14px;
                                                    text-align: justify;
                                                }
                                            }

                                            .dk-counter-commentCon {
                                                display: flex;
                                                justify-content: flex-start;
                                                margin-top: 7px;

                                                .dk-counterCon {
                                                    padding-right: 10px;
                                                }

                                                .dk-commentCon {
                                                    display: flex;

                                                    i {
                                                        margin-top: 3px;
                                                    }

                                                    .dk-commentNumber {
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        width: 22px;
                                                        height: 22px;
                                                        border-radius: 50%;
                                                        font-size: 13px;
                                                        background-color: #ccc;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .dk-creatnewtask-boxCon {
                                    background-color: #eaeaea;
                                    // height: calc(100vh - 520px);
                                    // overflow-y: scroll;

                                    /* Hide scrollbar for Chrome, Safari and Opera */
                                    &::-webkit-scrollbar {
                                        display: none;
                                    }

                                    /* Hide scrollbar for IE, Edge and Firefox */
                                    & {
                                        -ms-overflow-style: none;
                                        /* IE and Edge */
                                        scrollbar-width: none;
                                        /* Firefox */
                                    }

                                    .dk-createnewtaskTitle {
                                        margin-bottom: 0.75rem;

                                        h2 {
                                            width: 100%;
                                            text-align: center;
                                            font-size: 17px;
                                            text-transform: capitalize;
                                            font-weight: 500;
                                        }
                                    }

                                    .dk-creatnewtask-boxs {
                                        display: flex;
                                        width: 100%;
                                        margin-bottom: 18px;

                                        &:last-child {
                                            margin-bottom: 18px;
                                        }

                                        .dk-creatnewtask-box {
                                            width: 100%;
                                            max-width: 100%;
                                            min-height: 100px;
                                            background: #ffff;
                                            border: 1px solid #ddd;
                                            margin: 0 10px;
                                            font-size: 12px;
                                            border-radius: 5px;
                                            padding: 15px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            cursor: pointer;

                                            &.active-task-box {
                                                filter: grayscale(1);
                                                background-color: #d5d2d2;
                                                box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
                                            }

                                            &:hover {
                                                background-color: #d5d2d2;
                                                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                            }

                                            a {
                                                cursor: pointer;

                                                .dk-tasktype-imgcon {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;

                                                    img {
                                                        width: 40px;
                                                        height: 40px;
                                                    }
                                                }

                                                .dk-tasktype-title {
                                                    font-size: 12px;
                                                    padding-top: 10px;
                                                    color: #333;
                                                    white-space: nowrap;
                                                    text-align: center;
                                                    text-transform: uppercase;
                                                }
                                            }
                                        }
                                    }
                                }

                                .dkg-createnewtaskbox-89 {
                                    margin: 8px 6px;

                                    .dk-create-newtask-body {
                                        background-color: #fff;
                                        padding: 10px 15px;

                                        // padding: 15px;
                                        // max-height: calc(100vh - 145px);
                                        // overflow-y: auto;
                                        .from-group {
                                            margin-bottom: 1.25rem;

                                            label {
                                                font-size: 13px;
                                                display: block;
                                                margin-bottom: 2px;
                                                color: #737373;
                                                text-align: center;

                                                &.dk-cre-newtask-label-center {
                                                    text-align: center;
                                                }
                                            }

                                            .dk-taskText {
                                                display: block;
                                                width: 100%;
                                                background: #D58B5D;
                                                border: 1px solid #D58B5D;
                                                color: #fff;
                                                height: 32px;
                                                text-align: center;
                                                line-height: 32px;
                                                font-size: 13px;
                                                text-transform: uppercase;
                                                border-radius: 3px;
                                            }



                                            input {
                                                border: 1px solid #c1c9dc;

                                                &:focus {
                                                    outline: 0;
                                                    box-shadow: none;
                                                }

                                                &.dk-tasktype-input[readonly] {
                                                    background-color: #e9ecef;
                                                    border: 1px solid #c1c9dc;
                                                    text-align: center;
                                                    border-radius: 3px;
                                                    text-transform: uppercase;
                                                    color: #333;

                                                    &::placeholder {
                                                        color: #333;
                                                        opacity: 1;
                                                        /* Firefox */
                                                    }

                                                    &:-ms-input-placeholder {
                                                        /* Internet Explorer 10-11 */
                                                        color: #fff;
                                                    }

                                                    &::-ms-input-placeholder {
                                                        /* Microsoft Edge */
                                                        color: #fff;
                                                    }
                                                }
                                            }

                                            .dk-tasktype-dropdown {
                                                .dk-tasktype-dropdown-toggle {
                                                    box-shadow: none;
                                                    border: 1px solid #c1c9dc;
                                                    padding: 5px 14px;
                                                    height: 34px;
                                                    border-radius: 3px;
                                                    background: #fff;
                                                    margin-top: 0px;
                                                    font-family: Verdana, sans-serif;
                                                    font-size: 14px;
                                                    text-transform: capitalize;
                                                    line-height: 22px;
                                                    color: #797474;
                                                    width: 100%;
                                                    position: relative;
                                                    margin: 0;

                                                    &::after {
                                                        position: absolute;
                                                        right: 10px;
                                                        top: 15px;
                                                    }
                                                }

                                                .dk-tasktype-dropdown-menu {
                                                    padding: 0;
                                                    border-radius: 0;
                                                    width: 100%;
                                                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                                                    border: none;

                                                    .dropdown-item {
                                                        padding: 7px 10px;
                                                        font-size: 13px;
                                                        color: #333;
                                                    }
                                                }
                                            }

                                            .dk-taskByCon {
                                                border-radius: 3px;
                                                height: 35px;
                                                padding: 0 10px !important;
                                                min-height: 35px;
                                                line-height: 35px;
                                                background: #e9ecef;
                                                box-shadow: none;
                                                border: 1px solid rgba(34, 36, 38, .15);
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 100%;

                                                .dk-selectator-textlength {
                                                    display: flex;
                                                    justify-content: center;
                                                    width: 100%;

                                                    &:first-child {
                                                        margin-right: 0.5rem;
                                                    }

                                                    &:last-child {
                                                        margin-right: 0;
                                                    }

                                                    .dk-selected-items {
                                                        display: flex;
                                                        background-color: #e9ecef;
                                                        border: 1px solid #c1c9dc;
                                                        width: auto;
                                                        justify-content: center;
                                                        height: 25px;
                                                        align-items: center;
                                                        padding: 3px 5px;
                                                        border-radius: 2px;
                                                        position: relative;

                                                        .userImgCont {
                                                            img {
                                                                width: 25px;
                                                                height: 23px;
                                                            }
                                                        }

                                                        .dk-selecteditem-remove {
                                                            display: inline-block;
                                                            font-weight: 700;
                                                            color: rgba(0, 0, 0, .75);
                                                            margin: 0 0 0 5px;
                                                            cursor: pointer;
                                                            font-size: 60%;
                                                            line-height: 10px;
                                                            vertical-align: top;
                                                            border-radius: 0 2px 2px 0;
                                                            position: absolute;
                                                            right: 0;
                                                            top: 0;
                                                            bottom: 0;
                                                            padding: 7px 5px 4px;
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-taskSwitch {
                                                color: #333;

                                                li.tg-list-item {
                                                    list-style-type: none;
                                                }
                                            }
                                        }

                                        .dk-caseText {
                                            display: block;
                                            width: 100%;
                                            background: #D58B5D;
                                            border: 1px solid #D58B5D;
                                            color: #fff;
                                            height: 32px;
                                            text-align: center;
                                            line-height: 32px;
                                            font-size: 13px;
                                            text-transform: uppercase;
                                            border-radius: 3px;
                                        }

                                        .form-group.dk-create-date-timeFrmGrp {
                                            margin-bottom: 1.25rem;

                                            label {
                                                font-size: 13px;
                                                display: block;
                                                color: #737373;
                                                text-align: left;
                                                margin-bottom: 1px;

                                                .input-group {
                                                    .input-group-prepend {
                                                        &.dkg-crete-new-task-idCon {
                                                            cursor: pointer;
                                                            border-right: none;

                                                            .input-group-text {
                                                                border-right: none;
                                                            }
                                                        }
                                                    }
                                                }

                                                .input-group-prepend {
                                                    .input-group-text {
                                                        background: #e9ecef;
                                                    }
                                                }

                                                .input-group {
                                                    .form-control.dk-subjectFrmControl {
                                                        border: 1px solid #c1c9dc;
                                                        text-align: left;
                                                        background: #e9ecef;
                                                    }
                                                }

                                                &.dk-cre-newtask-label-center {
                                                    text-align: center;
                                                }

                                                &.dk-TaskDate {
                                                    font-size: 13px;
                                                    display: block;
                                                    margin-bottom: 2px;
                                                    color: #737373;
                                                    text-align: left;

                                                    input {
                                                        border-top-right-radius: 0;
                                                        border-bottom-right-radius: 0;
                                                        border-right: none;
                                                        height: 35px;
                                                    }
                                                }

                                                &.dk-TaskTime {
                                                    font-size: 13px;
                                                    display: block;
                                                    margin-bottom: 2px;
                                                    color: #737373;
                                                    text-align: left;

                                                    input {
                                                        border-top-left-radius: 0;
                                                        border-bottom-left-radius: 0;
                                                        height: 35px;
                                                    }
                                                }

                                                &.dk-taskChoosefile {
                                                    position: relative;
                                                    border: 1px solid #ddd;
                                                    border-radius: 3px;
                                                    text-align: center;
                                                    transition: all 0.5s;

                                                    &:hover {

                                                        span {
                                                            background: #f2f2f2;
                                                        }
                                                    }

                                                    .file-select-name {
                                                        font-size: 12px;
                                                        display: inline-block;
                                                        position: absolute;
                                                        top: 8px;
                                                        left: 5px;
                                                    }

                                                    input {
                                                        width: 100%;
                                                        height: 35px;
                                                        position: relative;
                                                        z-index: 99;
                                                        opacity: 0;
                                                        border-radius: 3px;
                                                        border: 1px solid #ddd !important;
                                                        box-shadow: none !important;
                                                        cursor: pointer;
                                                    }

                                                    span {
                                                        background: #e9ecef;
                                                        padding: 0 25px;
                                                        display: inline-block;
                                                        height: 35px;
                                                        line-height: 35px;
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        z-index: 9;
                                                        width: 100%;
                                                        cursor: pointer;
                                                        color: #333;

                                                        &:hover {
                                                            background-color: #fff;
                                                        }
                                                    }
                                                }

                                                .dk-subjectFrmControl {
                                                    border-radius: 3px;
                                                    height: 35px;
                                                    padding: 0 10px !important;
                                                    min-height: 35px;
                                                    line-height: 35px;
                                                }

                                                &.dk-meeting-date-timelabel {
                                                    text-align: center;
                                                }
                                            }

                                            .dk-nativedatepicker-grid {
                                                .dk-nativedatepicker {
                                                    margin: 0;
                                                    padding: 0;
                                                    border: 1px solid #c1c9dc;
                                                    background: #e9ecef;
                                                    border-top-left-radius: 3px;
                                                    border-bottom-left-radius: 3px;

                                                    .MuiInputBase-root {
                                                        .MuiInputAdornment-root {
                                                            .MuiIconButton-root {
                                                                padding: 0;
                                                                margin: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-nativetimeepicker-grid {
                                                .dk-nativetimeepicker {
                                                    margin: 0;
                                                    padding: 0;
                                                    border: 1px solid #c1c9dc;
                                                    background: #e9ecef;
                                                    border-top-right-radius: 3px;
                                                    border-bottom-right-radius: 3px;
                                                    *border-left: none !important;

                                                    .MuiInputBase-root {
                                                        .MuiInputAdornment-root {
                                                            .MuiIconButton-root {
                                                                padding: 0;
                                                                margin: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .MuiFormControl-root {
                                                padding: 0 6px;
                                                width: 100%;

                                                .MuiInputBase-root {
                                                    &.MuiInput-underline:before {
                                                        border: none !important;
                                                        height: 33px;
                                                        line-height: 0.81rem;
                                                        border-top-right-radius: 0;
                                                        border-bottom-right-radius: 0;
                                                        border-right: none !important;
                                                    }

                                                    &.MuiInput-underline:after {
                                                        border: none !important;
                                                        height: 33px;
                                                        line-height: 0.81rem;
                                                    }

                                                    input {
                                                        color: #333;
                                                        height: 25px;
                                                        text-align: center;
                                                        padding: 4px 0;
                                                        font-size: 13px;

                                                        &::-webkit-calendar-picker-indicator {
                                                            margin-left: 0px;
                                                        }

                                                        &[type="date"]::-webkit-inner-spin-button,
                                                        &[type="date"]::-webkit-calendar-picker-indicator,
                                                        &[type="time"]::-webkit-inner-spin-button,
                                                        &[type="time"]::-webkit-calendar-picker-indicator {
                                                            // display: none;
                                                            // -webkit-appearance: none;
                                                            color: #333;
                                                        }
                                                    }

                                                    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                                        margin-left: 0 !important;
                                                    }

                                                    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                                        .MuiButtonBase-root.MuiIconButton-root {
                                                            padding: 0.4rem 0.23rem;
                                                            border-radius: 0;

                                                            &:hover {
                                                                background-color: #e9ecef;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            & textarea.form-control {
                                                min-height: 125px;
                                                border: 1px solid #c1c9dc !important;
                                                padding: 5px 10px;
                                                background: #e9ecef;
                                                border-radius: 3px;
                                                font-size: 14px;
                                                color: #595959;
                                                font-family: Roboto, sans-serif;
                                                text-align: left;

                                                &:focus::placeholder {
                                                    opacity: 0;
                                                }

                                                &::-webkit-input-placeholder {
                                                    color: #595959;
                                                }

                                                &:-moz-placeholder {
                                                    /* Firefox 18- */
                                                    color: #595959;
                                                }

                                                &::-moz-placeholder {
                                                    /* Firefox 19+ */
                                                    color: #595959;
                                                }

                                                &:-ms-input-placeholder {
                                                    color: #595959;
                                                }

                                                &::placeholder {
                                                    color: #595959;
                                                }
                                            }
                                        }

                                        .form-group.dk-createNewTaskFooter {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            .dk-create-new-taskbtn {
                                                background-color: #26597d;
                                                border: none;
                                                border-radius: 2px;
                                                display: inline-block;
                                                height: 36px;
                                                line-height: 36px;
                                                padding: 0 2rem;
                                                text-transform: uppercase;
                                                vertical-align: middle;
                                                -webkit-tap-highlight-color: transparent;
                                                font-size: 1rem;
                                                outline: 0;
                                                text-decoration: none;
                                                color: #fff;
                                                text-align: center;
                                                letter-spacing: .5px;
                                                transition: .2s ease-out;
                                                margin-top: 0;
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.dk-todolistpaneAddNew {
                            .dk-addnewFrmGrp {
                                margin-bottom: 1rem;

                                label {
                                    color: #787777;
                                    font-size: 0.9125rem;
                                    text-align: left;
                                }

                                textarea {
                                    border: 1px solid #c5c5c5;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    background-color: #f2f2f2;
                                    font-size: 14px;
                                    border-radius: 0;
                                    text-align: left;

                                    &:focus {
                                        box-shadow: none;
                                        outline: 0;
                                    }
                                }
                            }

                            .dk-taskactsavebtnCon {
                                display: flex;
                                justify-content: center;
                                width: 100%;

                                .dk-taskactsavebtn {
                                    background: #2685A5;
                                    color: #fff;
                                    border: 1px solid #2685A5;
                                    width: 100px;
                                    text-align: center;
                                    display: inline-block;
                                    padding: 5px 12px;
                                    text-transform: uppercase;
                                    font-size: 13px;
                                    line-height: 1.5385;
                                    border-radius: 0.1875rem;
                                }
                            }
                        }

                        .dk-todolistContainer {
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            list-style-position: inside;
                            height: calc(100vh - 170px);
                            overflow-y: auto;

                            li {
                                margin: 12px 0;
                                background-color: #234158;
                                border-radius: 5px;
                                padding: 0.5rem 0.75rem;
                                border: 1px solid #234158;
                                color: #fff;

                                .dk-tasklist {
                                    width: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    line-height: 1.58rem;

                                    .dk-taslistCon {
                                        .react-contextmenu-wrapper {
                                            display: inline-block;

                                            .dk-todo-dropdowncon {
                                                .dropdown {
                                                    .dk-todo-dropdownbtn.dropdown-toggle {
                                                        margin: 0;
                                                        padding: 0;
                                                        box-shadow: none;
                                                        background-color: transparent;
                                                        margin-left: 4px;
                                                        font-size: 14px;
                                                        text-transform: capitalize;
                                                        font-weight: 600;

                                                        &::after {
                                                            display: none;
                                                        }
                                                    }

                                                    .dk-todo-dropdown-menu {
                                                        padding: 0;
                                                        border-radius: 0;

                                                        .dropdown-item {
                                                            padding: 0.35rem 0.75rem;
                                                            border-bottom: 1px solid #ccc;

                                                            &:last-child {
                                                                border-bottom: none;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .todorightDropmenu {
                                        border-radius: 0;
                                        background-color: #fff;
                                        min-width: 130px;
                                        z-index: 999;

                                        .react-contextmenu-item {
                                            border-bottom: 1px solid #ddd;
                                            padding: 6px 12px;
                                            font-size: 14px;
                                            cursor: pointer;
                                            color: #333;

                                            &:hover {
                                                background: #2980b9;
                                                color: #fff !important;

                                                i {
                                                    color: #fff;
                                                }
                                            }

                                            i {
                                                display: inline-block;
                                                width: 18px;
                                                color: #2980b9;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-actonrecordModal.fade .modal-dialog {
    right: -600px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-actonrecordModal.show .modal-dialog {
    right: 0;
}

.modal.dk-actonrecordModal {
    z-index: 9999 !important;
}

// START CSS FOR LEFT MODAL

.modal.dkg-create-todo-task-modal {
    color: #000;
    .modal-dialog {
        position: fixed;
        margin: auto;
        left: 0;
        right: inherit !important;
        top: -15px;
        // width: 50%;
        min-width: 620px !important;
        max-width: 620px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            // overflow-y: auto;
            border-radius: 0px;

            .dkg-create-todo-task-modal-header {
                background-color: #234158;
                padding: 12px 10px;
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: none;
                position: relative;

                .dk-userimgCon {
                    position: absolute;
                    left: 15px;
                    top: 10px;

                    img.dk-userimg {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                }

                .modal-title {
                    color: #fff;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    opacity: 0.95;
                    font-size: 16px;
                    position: relative;
                }

                .dk-date-dayCon {
                    position: absolute;
                    top: 15px;
                    right: 15%;
                    color: #fff;
                }

                .close {
                    text-shadow: none;
                    color: #fff;
                    margin: 0;
                    padding: 0;
                    width: 40px;
                    height: 50px;
                    opacity: 0.9;
                    position: absolute;
                    right: 3px;
                    top: 0px;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dkg-create-todo-task-modal-body {
                background-color: #eaeaea;
                padding: 0;
                height: calc(100vh - 40px);

                .dk-tasktrackingPillsCon {
                    .nav-dk-tasktrackingPills {
                        margin-bottom: 0;
                        border-bottom: none;

                        .dk-tasktrackingNavItem {
                            width: 100%;
                            max-width: 20%;

                            .nav-link {
                                background-color: #2685A5;
                                color: #fff;
                                text-align: center;
                                width: 100%;
                                font-size: 14px;
                                padding: 9px 8px;
                                border-right: 1px solid #777676;
                                border-bottom: none;

                                &.active {
                                    background-color: #eaeaea;
                                    color: #333;
                                    border-right: #ddd;
                                }
                            }
                        }
                    }
                }

                .dk-tasktracktabContent {
                    padding: 15px;

                    .dk-tasktracktabpane {
                        &.dk-trackingTabPane {
                            .dk-dailyTrackingTabMain {
                                margin-top: 25px;

                                .form-group {
                                    margin-bottom: 1rem;

                                    .dk-select-timeDropdwn {
                                        .dk-select-time-btn {
                                            box-shadow: none;
                                            margin: 0;
                                            padding: 6px 10px;
                                            width: 100%;
                                            background-color: #f2f2f2;
                                            border: 1px solid #c5c5c5;
                                            font-size: 13px;
                                            text-transform: capitalize;
                                            border-radius: 0;
                                            color: #000;
                                            text-align: left;

                                            &::after {
                                                position: absolute;
                                                right: 10px;
                                                top: 15px;
                                            }
                                        }

                                        .dk-selecttimeMenu {
                                            border-radius: 0;
                                            width: 100%;
                                            padding: 0;
                                            background-color: #f2f2f2;

                                            .dropdown-item {
                                                padding: 0.35rem 0.75rem;
                                                border-bottom: 1px solid #ccc;
                                                font-size: 13px;

                                                &:last-child {
                                                    border-bottom: none;
                                                }
                                            }
                                        }
                                    }

                                    .dk-datepickerInputFrm {
                                        input {
                                            border: 1px solid #c5c5c5;
                                            background-color: #f2f2f2;
                                            color: #000;
                                            border-radius: 0;
                                            padding: 12px 10px;
                                            font-size: 13px;
                                            height: 33px;

                                            &:focus {
                                                outline: 0;
                                                box-shadow: none;
                                            }

                                            &::placeholder {
                                                /* Chrome, Firefox, Opera, Safari 10.1+ */
                                                color: #000;
                                                opacity: 1;
                                                /* Firefox */
                                            }

                                            &:-ms-input-placeholder {
                                                /* Internet Explorer 10-11 */
                                                color: #000;
                                            }

                                            &::-ms-input-placeholder {
                                                /* Microsoft Edge */
                                                color: #000;
                                            }
                                        }

                                        .input-group-append {
                                            .input-group-text {
                                                border-radius: 0;
                                                border: 1px solid #c5c5c5;
                                                border-left: none;

                                                i {
                                                    color: #333;
                                                }
                                            }
                                        }
                                    }

                                    &.dk-subjectFrmGrp {
                                        .input-group {
                                            .input-group-append {
                                                .input-group-text {
                                                    padding: 0 0.5rem;
                                                    background-color: #ccc;
                                                    height: 36px;
                                                    border: 1px solid #c5c5c5;
                                                    border-right: none;
                                                }
                                            }

                                            input {
                                                border: 1px solid #c5c5c5;
                                                background-color: #f2f2f2;
                                                color: #000;
                                                border-radius: 0;
                                                padding: 12px 10px;
                                                font-size: 13px;
                                                height: 36px;

                                                &:focus {
                                                    outline: 0;
                                                    box-shadow: none;
                                                }

                                                &::placeholder {
                                                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                                                    color: #000;
                                                    opacity: 1;
                                                    /* Firefox */
                                                }

                                                &:-ms-input-placeholder {
                                                    /* Internet Explorer 10-11 */
                                                    color: #000;
                                                }

                                                &::-ms-input-placeholder {
                                                    /* Microsoft Edge */
                                                    color: #000;
                                                }
                                            }
                                        }
                                    }

                                    &.dk-trackingFrmGrp {
                                        textarea {
                                            height: auto;
                                            border-radius: 0;

                                            &:focus {
                                                outline: 0;

                                            }
                                        }
                                    }
                                }

                                .dk-rightActivitybtnCon {
                                    display: flex;
                                    justify-content: center;
                                    width: 100%;

                                    .dk-rightActivitybtn {
                                        background: #2685A5;
                                        color: #fff;
                                        border: 1px solid #2685A5;
                                        width: 100px;
                                        text-align: center;
                                        display: inline-block;
                                        padding: 5px 12px;
                                        text-transform: uppercase;
                                        font-size: 13px;
                                        line-height: 1.5385;
                                        border-radius: 0.1875rem;
                                    }
                                }

                                .dk-activityListCont {
                                    margin-top: 25px;

                                    ul {
                                        list-style: none;
                                        padding-left: 0;
                                        margin-bottom: 0;
                                        max-height: 230px;
                                        overflow-y: auto;

                                        li.dk-activityList {
                                            display: flex;
                                            flex-flow: row wrap;
                                            font-size: 13px;
                                            border: 1px solid #c5c5c5;
                                            margin: 12px 0;
                                            background-color: #f2f2f2;

                                            .dk-timeCon {
                                                padding: 0.75rem 0.5rem;
                                                border-right: 1px solid #c5c5c5;
                                                white-space: nowrap;
                                                min-width: 75px;
                                                text-align: center;
                                            }

                                            .dk-dateCon {
                                                padding: 0.75rem 0.5rem;
                                                border-right: 1px solid #c5c5c5;
                                                white-space: nowrap;
                                                min-width: 80px;
                                                text-align: center;
                                            }

                                            .subjectCon {
                                                padding: 0.75rem 0.5rem;
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.dk-main-notesTabPane {
                            .dk-notes-main-tabCon {
                                .dk-addnewFrmGrp {
                                    margin-bottom: 1rem;

                                    label {
                                        color: #787777;
                                        font-size: 0.9125rem;
                                        text-align: left;
                                    }

                                    textarea {
                                        border: 1px solid #c5c5c5;
                                        padding-left: 10px;
                                        padding-right: 10px;
                                        background-color: #f2f2f2;
                                        font-size: 14px;
                                        border-radius: 0;

                                        &:focus {
                                            box-shadow: none;
                                            outline: 0;
                                        }
                                    }
                                }

                                .dk-taskactsavebtnCon {
                                    display: flex;
                                    justify-content: center;
                                    width: 100%;

                                    .dk-taskactsavebtn {
                                        background: #2685A5;
                                        color: #fff;
                                        border: 1px solid #2685A5;
                                        width: 100px;
                                        text-align: center;
                                        display: inline-block;
                                        padding: 5px 12px;
                                        text-transform: uppercase;
                                        font-size: 13px;
                                        line-height: 1.5385;
                                        border-radius: 0.1875rem;
                                    }
                                }

                                .dk-shownotesListSec {
                                    margin-top: 1.25rem;

                                    ul.dk-shownotesListCon {
                                        color: #333;
                                        list-style: none;
                                        padding-inline-start: 0;
                                        margin-bottom: 0;
                                        max-height: 330px;
                                        overflow-y: auto;

                                        li.dk-shownotesList {
                                            background-color: #f2f2f2;
                                            padding: 7px;
                                            border: 1px solid #ddd;
                                            margin: 12px 0;
                                            cursor: pointer;

                                            .react-contextmenu-wrapper {
                                                .dk-totasktitle {
                                                    text-align: justify;
                                                    font-size: 13px;
                                                }
                                            }

                                            .dk-notesrightDropMenu {
                                                background-color: #fff;
                                                min-width: 130px;
                                                border-radius: 0;
                                                padding: 0;

                                                .react-contextmenu-item {
                                                    border-bottom: 1px solid #ddd;
                                                    padding: 6px 12px;
                                                    font-size: 14px;
                                                    cursor: pointer;

                                                    &:hover {
                                                        background: #2980b9;
                                                        color: #fff !important;

                                                        i {
                                                            color: #fff;
                                                        }
                                                    }

                                                    i {
                                                        display: inline-block;
                                                        width: 18px;
                                                        color: #2980b9;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.dk-create-task-TabPane {
                            .dk-task-tab-MainCon {
                                margin-top: 5px;

                                .dk-create-newtask-header {
                                    background: lightgrey;
                                    color: #fff;
                                    padding: 6px 0 5px 13px;
                                    border: 1px solid lightgrey;
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    position: relative;

                                    .dk-reseticonCon {
                                        position: absolute;
                                        left: 15px;
                                        cursor: pointer;

                                        i {
                                            color: #333;
                                            cursor: pointer;
                                            opacity: 0.85;
                                        }
                                    }

                                    .modal-title {
                                        margin-top: 0;
                                        font-size: 15px;
                                        font-weight: 500;
                                        font-family: Roboto, sans-serif;
                                        text-align: center;
                                        text-transform: uppercase;
                                        color: #333;
                                        opacity: 0.85;
                                    }
                                }

                                .dk-create-newtask-body {
                                    background-color: #fff;
                                    padding: 15px;
                                    max-height: calc(100vh - 145px);
                                    overflow-y: auto;

                                    .from-group {
                                        margin-bottom: 0.5rem;

                                        label {
                                            font-size: 13px;
                                            display: block;
                                            margin-bottom: 2px;
                                            color: #737373;
                                            text-align: left;
                                        }

                                        .dk-tasktype-dropdown {
                                            .dk-tasktype-dropdown-toggle {
                                                box-shadow: none;
                                                border: 1px solid #c1c9dc;
                                                padding: 5px 14px;
                                                height: 34px;
                                                border-radius: 3px;
                                                background: #fff;
                                                margin-top: 0px;
                                                font-family: Verdana, sans-serif;
                                                font-size: 14px;
                                                text-transform: capitalize;
                                                line-height: 22px;
                                                color: #797474;
                                                width: 100%;
                                                position: relative;
                                                margin: 0;

                                                &::after {
                                                    position: absolute;
                                                    right: 10px;
                                                    top: 15px;
                                                }
                                            }

                                            .dk-tasktype-dropdown-menu {
                                                padding: 0;
                                                border-radius: 0;
                                                width: 100%;
                                                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                                                border: none;

                                                .dropdown-item {
                                                    padding: 7px 10px;
                                                    font-size: 13px;
                                                    color: #333;
                                                }
                                            }
                                        }

                                        .dk-taskByCon {
                                            border-radius: 3px;
                                            height: 35px;
                                            padding: 0 10px !important;
                                            min-height: 35px;
                                            line-height: 35px;
                                            box-shadow: none;
                                            border: 1px solid rgba(34, 36, 38, .15);
                                            display: flex;
                                            align-items: center;

                                            .dk-selectator-textlength {
                                                display: flex;

                                                &:first-child {
                                                    margin-right: 0.5rem;
                                                }

                                                &:last-child {
                                                    margin-right: 0;
                                                }

                                                .dk-selected-items {
                                                    display: flex;
                                                    background-color: #fafafa;
                                                    border: 1px solid #c1c9dc;
                                                    width: 44px;
                                                    justify-content: space-between;
                                                    height: 25px;
                                                    align-items: center;
                                                    padding: 3px 5px;
                                                    border-radius: 2px;
                                                    position: relative;

                                                    .userImgCont {
                                                        img {
                                                            width: 15px;
                                                            height: 15px;
                                                        }
                                                    }

                                                    .dk-selecteditem-remove {
                                                        display: inline-block;
                                                        font-weight: 700;
                                                        color: rgba(0, 0, 0, .75);
                                                        margin: 0 0 0 5px;
                                                        cursor: pointer;
                                                        font-size: 60%;
                                                        line-height: 10px;
                                                        vertical-align: top;
                                                        border-radius: 0 2px 2px 0;
                                                        position: absolute;
                                                        right: 0;
                                                        top: 0;
                                                        bottom: 0;
                                                        padding: 7px 5px 4px;
                                                    }
                                                }
                                            }
                                        }

                                        .dk-taskSwitch {
                                            color: #333;

                                            li.tg-list-item {
                                                list-style-type: none;
                                            }
                                        }
                                    }

                                    .form-group.dk-create-date-timeFrmGrp {
                                        label {
                                            font-size: 13px;
                                            display: block;
                                            color: #737373;
                                            text-align: left;

                                            &.dk-TaskDate {
                                                font-size: 13px;
                                                display: block;
                                                margin-bottom: 2px;
                                                color: #737373;
                                                text-align: left;

                                                input {
                                                    border-top-right-radius: 0;
                                                    border-bottom-right-radius: 0;
                                                    border-right: none;
                                                    height: 35px;
                                                }
                                            }

                                            &.dk-TaskTime {
                                                font-size: 13px;
                                                display: block;
                                                margin-bottom: 2px;
                                                color: #737373;
                                                text-align: left;

                                                input {
                                                    border-top-left-radius: 0;
                                                    border-bottom-left-radius: 0;
                                                    height: 35px;
                                                }
                                            }

                                            &.dk-taskChoosefile {
                                                position: relative;
                                                border: 1px solid #ddd;
                                                border-radius: 3px;

                                                .file-select-name {
                                                    font-size: 12px;
                                                    display: inline-block;
                                                    position: absolute;
                                                    top: 8px;
                                                    left: 5px;
                                                }

                                                input {
                                                    width: 100%;
                                                    height: 35px;
                                                    position: relative;
                                                    z-index: 99;
                                                    opacity: 0;
                                                    border-radius: 3px;
                                                    border: 1px solid #ddd !important;
                                                    box-shadow: none !important;
                                                }

                                                span {
                                                    background: #d6dce5;
                                                    padding: 0 25px;
                                                    display: inline-block;
                                                    height: 35px;
                                                    line-height: 35px;
                                                    position: absolute;
                                                    top: 0;
                                                    right: 0;
                                                    z-index: 9;
                                                    width: 100%;
                                                    cursor: pointer;
                                                }
                                            }

                                            .dk-subjectFrmControl {
                                                border-radius: 3px;
                                                height: 35px;
                                                padding: 0 10px !important;
                                                min-height: 35px;
                                                line-height: 35px;
                                            }
                                        }

                                        textarea.form-control {
                                            min-height: 125px;
                                            border: 1px solid #c1c9dc !important;
                                            padding: 12px 14px;
                                            background: #fff;
                                            border-radius: 3px;
                                            font-size: 14px;
                                            color: #4f5152;
                                            font-family: Roboto, sans-serif;
                                        }
                                    }

                                    .form-group.dk-createNewTaskFooter {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .dk-create-new-taskbtn {
                                            background-color: darkgrey !important;
                                            border: none;
                                            border-radius: 2px;
                                            display: inline-block;
                                            height: 36px;
                                            line-height: 36px;
                                            padding: 0 2rem;
                                            text-transform: uppercase;
                                            vertical-align: middle;
                                            -webkit-tap-highlight-color: transparent;
                                            font-size: 1rem;
                                            outline: 0;
                                            text-decoration: none;
                                            color: #333 !important;
                                            text-align: center;
                                            letter-spacing: .5px;
                                            transition: .2s ease-out;
                                            margin-top: 0;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }

                        color: #333;

                        .dk-todolistPillsCon {
                            .nav-dk-todolistPills {
                                color: #eaeaea;

                                .dk-todolistNavItem {
                                    width: 100%;
                                    max-width: 20%;
                                    white-space: nowrap;

                                    .nav-link {
                                        text-align: center;
                                        width: 100%;
                                        font-size: 13px;
                                        padding: 8px 5px;
                                        border: 1px solid #ccc;
                                        border-right: none;
                                        color: #333;
                                        background-color: #fff;
                                        border-radius: 0;
                                        cursor: pointer;

                                        &.dk-todoallBg {
                                            color: #234158;
                                        }

                                        &.active.dk-todoallBg {
                                            background-color: #234158;
                                            border: 1px solid #234158;
                                            color: #fff;
                                            border-right: none;
                                            cursor: pointer;
                                        }

                                        &.todoListBg {
                                            color: #2685a5;
                                        }

                                        &.active.todoListBg {
                                            background-color: #2685a5;
                                            border: 1px solid #2685a5;
                                            color: #fff;
                                            border-right: none;
                                            cursor: pointer;
                                        }

                                        &.inprogressBg {
                                            color: #D58B5D;
                                        }

                                        &.active.inprogressBg {
                                            background-color: #D58B5D;
                                            border: 1px solid #D58B5D;
                                            color: #fff;
                                            border-right: none;
                                            cursor: pointer;
                                        }

                                        &.completedBg {
                                            color: #3A9469;
                                        }

                                        &.active.completedBg {
                                            background-color: #3A9469;
                                            border: 1px solid #3A9469;
                                            color: #fff;
                                            border-right: none;
                                            cursor: pointer;
                                        }

                                        &.todoaddNewBg {
                                            color: #234158;
                                        }

                                        &.active.todoaddNewBg {
                                            background-color: #f2f2f2;
                                            border: 1px solid #ccc;
                                            color: #333;
                                            cursor: pointer;
                                        }

                                        & i {
                                            color: #787777;
                                        }
                                    }

                                    &:last-child {
                                        .nav-link {
                                            border-right: 1px solid #ccc;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-todolistContent {
                            .dk-todolistpane {
                                padding: 15px 0;

                                &.sumary-Tabpane {
                                    .dk-summary-staticsCon {
                                        .dk-todo-summarytable {
                                            margin-bottom: 1rem;

                                            thead {
                                                tr th {
                                                    background-color: #234158;
                                                    border: 1px solid #808080;
                                                    color: #fff;
                                                    text-align: center;
                                                    font-size: 13px;
                                                    padding: 0.45rem 0.5rem;
                                                }
                                            }

                                            tbody {
                                                tr td {
                                                    background-color: #f1f1f1;
                                                    border: 1px solid #ddd;
                                                    color: #333;
                                                    text-align: center;
                                                    font-size: 13px;
                                                    padding: 0.45rem 0.5rem;
                                                }
                                            }
                                        }
                                    }

                                    .dk-createnewtask-Con {
                                        .dk-createnewtaskTitle {
                                            margin-bottom: 0.5rem;

                                            h2 {
                                                width: 100%;
                                                text-align: left;
                                                font-size: 17px;
                                                text-transform: capitalize;
                                                font-weight: 500;
                                            }
                                        }

                                        .dk-createnewtask-listCon {
                                            list-style: none;
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 1.5rem;

                                            .dk-createnewtask-list {
                                                width: 100%;
                                                max-width: 25%;
                                                cursor: pointer;
                                                background: #f1f1f1;
                                                padding: 0.35rem;
                                                border: 1px solid #dddd;
                                                border-right: none;

                                                &:last-child {
                                                    border-right: 1px solid #ddd;
                                                }

                                                &.active {
                                                    background-color: #2685A5;
                                                    color: #fff;
                                                }

                                                .dk-img-text-con {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;

                                                    img {
                                                        width: 20px;
                                                        height: 20px;
                                                    }

                                                    .tasktype-text {
                                                        font-size: 14px;
                                                        text-transform: capitalize;
                                                        margin-left: 0.75rem;
                                                    }
                                                }
                                            }
                                        }

                                        .dk-taskprocess-cardCon {
                                            height: calc(100vh - 330px);
                                            overflow-y: auto;

                                            .dk-taskprocess-card {
                                                background-color: #f1f1f1;
                                                border-radius: 5px;
                                                padding: .5rem .75rem;
                                                margin: 12px 0;

                                                .dk-taskprocess-card-body {
                                                    display: flex;
                                                    justify-content: flex-start;

                                                    .dk-user-imgCon {
                                                        width: 70px;
                                                        height: 70px;
                                                        display: none;

                                                        img {
                                                            width: 100%;
                                                            height: auto;
                                                            border-radius: 50%;
                                                            border: 2px solid #ccc;
                                                        }
                                                    }

                                                    .dk-inprogrestextCon {
                                                        margin-left: 7px;

                                                        .dk-recruiterName {
                                                            margin-bottom: 0.35rem;
                                                            font-size: 16px;
                                                            margin-top: 7px;
                                                        }

                                                        .dk-taskdetails {
                                                            font-size: 14px;
                                                            text-align: justify;
                                                        }
                                                    }

                                                    .dk-counter-commentCon {
                                                        display: flex;
                                                        justify-content: flex-start;
                                                        margin-top: 7px;

                                                        .dk-counterCon {
                                                            padding-right: 10px;
                                                        }

                                                        .dk-commentCon {
                                                            display: flex;

                                                            i {
                                                                margin-top: 3px;
                                                            }

                                                            .dk-commentNumber {
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;
                                                                width: 22px;
                                                                height: 22px;
                                                                border-radius: 50%;
                                                                font-size: 13px;
                                                                background-color: #ccc;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.dk-todolistpaneAddNew {
                                    .dk-addnewFrmGrp {
                                        margin-bottom: 1rem;

                                        label {
                                            color: #787777;
                                            font-size: 0.9125rem;
                                            text-align: left;
                                        }

                                        textarea {
                                            border: 1px solid #c5c5c5;
                                            padding-left: 10px;
                                            padding-right: 10px;
                                            background-color: #f2f2f2;
                                            font-size: 14px;
                                            border-radius: 0;

                                            &:focus {
                                                box-shadow: none;
                                                outline: 0;
                                            }
                                        }
                                    }

                                    .dk-taskactsavebtnCon {
                                        display: flex;
                                        justify-content: center;
                                        width: 100%;

                                        .dk-taskactsavebtn {
                                            background: #2685A5;
                                            color: #fff;
                                            border: 1px solid #2685A5;
                                            width: 100px;
                                            text-align: center;
                                            display: inline-block;
                                            padding: 5px 12px;
                                            text-transform: uppercase;
                                            font-size: 13px;
                                            line-height: 1.5385;
                                            border-radius: 0.1875rem;
                                        }
                                    }
                                }

                                .dk-todolistContainer {
                                    margin: 0;
                                    padding: 0;
                                    list-style: none;
                                    list-style-position: inside;
                                    height: calc(100vh - 170px);
                                    overflow-y: auto;

                                    li {
                                        margin: 12px 0;

                                        .dk-tasklist {
                                            width: 100%;
                                            display: flex;
                                            flex-wrap: wrap;
                                            line-height: 1.58rem;

                                            .dk-taslistCon {
                                                .react-contextmenu-wrapper {
                                                    display: inline-block;

                                                    .dk-todo-dropdowncon {
                                                        .dropdown {
                                                            .dk-todo-dropdownbtn.dropdown-toggle {
                                                                margin: 0;
                                                                padding: 0;
                                                                box-shadow: none;
                                                                background-color: transparent;
                                                                margin-left: 4px;
                                                                font-size: 14px;
                                                                text-transform: capitalize;
                                                                font-weight: 600;

                                                                &::after {
                                                                    display: none;
                                                                }
                                                            }

                                                            .dk-todo-dropdown-menu {
                                                                padding: 0;
                                                                border-radius: 0;

                                                                .dropdown-item {
                                                                    padding: 0.35rem 0.75rem;
                                                                    border-bottom: 1px solid #ccc;

                                                                    &:last-child {
                                                                        border-bottom: none;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .todorightDropmenu {
                                                border-radius: 0;
                                                background-color: #fff;
                                                min-width: 130px;
                                                z-index: 999;

                                                .react-contextmenu-item {
                                                    border-bottom: 1px solid #ddd;
                                                    padding: 6px 12px;
                                                    font-size: 14px;
                                                    cursor: pointer;

                                                    &:hover {
                                                        background: #2980b9;
                                                        color: #fff !important;

                                                        i {
                                                            color: #fff;
                                                        }
                                                    }

                                                    i {
                                                        display: inline-block;
                                                        width: 18px;
                                                        color: #2980b9;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .dk-todolistPillsCon {
                    .nav-dk-todolistPills {
                        color: #eaeaea;
                        position: relative;

                        .dk-todolistNavItem {
                            width: 100%;
                            max-width: 20%;
                            white-space: nowrap;

                            &.dkg-task-modal-nav-item {
                                max-width: 25% !important;
                            }

                            .nav-link {
                                background-color: #fff;
                                color: #333;
                                text-align: center;
                                width: 100%;
                                font-size: 14px;
                                padding: 14px 8px;
                                border-right: 1px solid #ddd;
                                border-bottom: none;

                                &.active {
                                    background-color: #eaeaea;
                                    color: #333;
                                    border-right: #ddd;

                                    span {
                                        color: #fff;
                                    }
                                }

                                span {
                                    font-size: 14px;
                                    margin-left: 0.35rem;
                                    font-weight: 600;

                                    &.dk-todolistNo {
                                        // background-color: #2685a5;
                                        color: #2685a5;
                                    }

                                    &.dk-inprohressNo {
                                        color: #D58B5D;
                                    }

                                    &.dk-compeletedNo {
                                        color: #3A9469;
                                    }
                                }

                                // &.dk-todoallBg{
                                //     color: #234158;
                                // }
                                // &.active.dk-todoallBg {
                                //     background-color: #234158;
                                //     border: 1px solid #234158;
                                //     color: #fff;
                                //     border-right: none;
                                //     cursor: pointer;
                                // }
                                // &.todoListBg{
                                //     color: #2685a5;
                                // }
                                // &.active.todoListBg {
                                //     background-color: #2685a5;
                                //     border: 1px solid #2685a5;
                                //     color: #fff;
                                //     border-right: none;
                                //     cursor: pointer;
                                // }
                                // &.inprogressBg{
                                //     color: #D58B5D;
                                // }
                                // &.active.inprogressBg {
                                //     background-color: #D58B5D;
                                //     border: 1px solid #D58B5D;
                                //     color: #fff;
                                //     border-right: none;
                                //     cursor: pointer;
                                // }
                                // &.completedBg{
                                //     color: #3A9469;
                                // }
                                // &.active.completedBg {
                                //     background-color: #3A9469;
                                //     border: 1px solid #3A9469;
                                //     color: #fff;
                                //     border-right: none;
                                //     cursor: pointer;
                                // }
                                // &.todoaddNewBg{
                                //     color: #234158;
                                // }
                                // &.active.todoaddNewBg {
                                //     background-color: #f2f2f2;
                                //     border: 1px solid #ccc;
                                //     color: #333;
                                //     cursor: pointer;
                                // }
                                // & i{
                                //     color: #787777;
                                // }
                            }

                            &.dkg-adnew-notes-tab {
                                position: absolute;
                                left: 10px;
                                top: -54px;

                                .nav-link {
                                    background-color: transparent !important;
                                    color: #fff !important;
                                    border-right: none;
                                    text-align: left;

                                    i {
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 50%;
                                        background-color: #eaeaea;
                                        color: #333;

                                        &:hover {
                                            opacity: 0.75;
                                            background-color: #eaeaea;
                                            color: #333;
                                        }
                                    }
                                }
                            }

                            // &:last-child {
                            //     .nav-link {
                            //         border-right: 1px solid #ccc;

                            //         i {
                            //             color: #808080;
                            //         }
                            //     }
                            // }
                        }
                    }
                }

                .dk-todolistContent {
                    .dk-todolistpane {
                        padding: 20px 15px;
                        height: calc(100vh - 75px);
                        overflow-y: auto;

                        /* Hide scrollbar for Chrome, Safari and Opera */
                        &::-webkit-scrollbar {
                            cursor: pointer;
                            scrollbar-width: none;
                        }

                        /* Hide scrollbar for IE, Edge and Firefox */
                        // & {
                        //     -ms-overflow-style: none;
                        //     scrollbar-width: none;
                        // }

                        &.sumary-Tabpane {
                            .dk-summary-staticsCon {
                                margin-top: 15px;
                                margin-bottom: 25px;

                                .dk-todo-summarytable {
                                    margin-bottom: 1rem;

                                    thead {
                                        tr th {
                                            background: #252c37;
                                            color: #e2dada;
                                            border: 1px solid #616767;
                                            text-align: center;
                                            font-size: 13px;
                                            padding: 0.45rem 0.5rem;
                                            width: 100%;
                                            max-width: 16.66%;
                                        }
                                    }

                                    tbody {
                                        tr td {
                                            background: #2c394b;
                                            color: #bdb4b4;
                                            border: 1px solid #5a6169;
                                            text-align: center;
                                            font-size: 13px;
                                            padding: 0.45rem 0.5rem;
                                            white-space: nowrap;
                                            width: 100%;
                                            max-width: 16.66%;
                                        }
                                    }
                                }
                            }

                            .dk-createnewtask-Con {
                                .dk-createnewtaskTitle {
                                    margin-bottom: 0.5rem;

                                    h2 {
                                        width: 100%;
                                        text-align: left;
                                        font-size: 17px;
                                        text-transform: capitalize;
                                        font-weight: 500;
                                    }
                                }

                                .dk-createnewtask-listCon {
                                    list-style: none;
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 1.5rem;

                                    .dk-createnewtask-list {
                                        width: 100%;
                                        max-width: 25%;
                                        cursor: pointer;
                                        background: #f1f1f1;
                                        padding: 0.35rem;
                                        border: 1px solid #dddd;
                                        border-right: none;

                                        &:last-child {
                                            border-right: 1px solid #ddd;
                                        }

                                        &.active {
                                            background-color: #2685A5;
                                            color: #fff;
                                        }

                                        .dk-img-text-con {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            img {
                                                width: 20px;
                                                height: 20px;
                                            }

                                            .tasktype-text {
                                                font-size: 14px;
                                                text-transform: capitalize;
                                                margin-left: 0.75rem;
                                            }
                                        }
                                    }
                                }

                                .dk-taskprocess-cardCon {
                                    height: calc(100vh - 275px);
                                    overflow-y: auto;

                                    .dk-taskprocess-card {
                                        background-color: #f1f1f1;
                                        border-radius: 5px;
                                        padding: .5rem .75rem;
                                        margin: 12px 0;

                                        .dk-taskprocess-card-body {
                                            display: flex;
                                            justify-content: flex-start;

                                            .dk-user-imgCon {
                                                width: 70px;
                                                height: 70px;
                                                display: none;

                                                img {
                                                    width: 100%;
                                                    height: auto;
                                                    border-radius: 50%;
                                                    border: 2px solid #ccc;
                                                }
                                            }

                                            .dk-inprogrestextCon {
                                                margin-left: 7px;

                                                .dk-recruiterName {
                                                    margin-bottom: 0.35rem;
                                                    font-size: 16px;
                                                    margin-top: 7px;
                                                }

                                                .dk-taskdetails {
                                                    font-size: 14px;
                                                    text-align: justify;
                                                }
                                            }

                                            .dk-counter-commentCon {
                                                display: flex;
                                                justify-content: flex-start;
                                                margin-top: 7px;

                                                .dk-counterCon {
                                                    padding-right: 10px;
                                                }

                                                .dk-commentCon {
                                                    display: flex;

                                                    i {
                                                        margin-top: 3px;
                                                    }

                                                    .dk-commentNumber {
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        width: 22px;
                                                        height: 22px;
                                                        border-radius: 50%;
                                                        font-size: 13px;
                                                        background-color: #ccc;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .dk-creatnewtask-boxCon {
                                    background-color: #eaeaea;
                                    // height: calc(100vh - 520px);
                                    // overflow-y: scroll;

                                    /* Hide scrollbar for Chrome, Safari and Opera */
                                    &::-webkit-scrollbar {
                                        display: none;
                                    }

                                    /* Hide scrollbar for IE, Edge and Firefox */
                                    & {
                                        -ms-overflow-style: none;
                                        /* IE and Edge */
                                        scrollbar-width: none;
                                        /* Firefox */
                                    }

                                    .dk-createnewtaskTitle {
                                        margin-bottom: 0.75rem;

                                        h2 {
                                            width: 100%;
                                            text-align: center;
                                            font-size: 17px;
                                            text-transform: capitalize;
                                            font-weight: 500;
                                        }
                                    }

                                    .dk-creatnewtask-boxs {
                                        display: flex;
                                        width: 100%;
                                        margin-bottom: 18px;

                                        &:last-child {
                                            margin-bottom: 18px;
                                        }

                                        .dk-creatnewtask-box {
                                            width: 100%;
                                            max-width: 100%;
                                            min-height: 100px;
                                            background: #ffff;
                                            border: 1px solid #ddd;
                                            margin: 0 10px;
                                            font-size: 12px;
                                            border-radius: 5px;
                                            padding: 15px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            cursor: pointer;

                                            &.active-task-box {
                                                filter: grayscale(1);
                                                background-color: #d5d2d2;
                                                box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
                                            }

                                            &:hover {
                                                background-color: #d5d2d2;
                                                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                            }

                                            a {
                                                cursor: pointer;

                                                .dk-tasktype-imgcon {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;

                                                    img {
                                                        width: 40px;
                                                        height: 40px;
                                                    }
                                                }

                                                .dk-tasktype-title {
                                                    font-size: 12px;
                                                    padding-top: 10px;
                                                    color: #333;
                                                    white-space: nowrap;
                                                    text-align: center;
                                                    text-transform: uppercase;
                                                }
                                            }
                                        }
                                    }
                                }

                                .dkg-createnewtaskbox-89 {
                                    margin: 8px 6px;

                                    .dk-create-newtask-body {
                                        background-color: #fff;
                                        padding: 10px 15px;

                                        // padding: 15px;
                                        // max-height: calc(100vh - 145px);
                                        // overflow-y: auto;
                                        .from-group {
                                            margin-bottom: 1.25rem;

                                            label {
                                                font-size: 13px;
                                                display: block;
                                                margin-bottom: 2px;
                                                color: #737373;
                                                text-align: center;

                                                &.dk-cre-newtask-label-center {
                                                    text-align: center;
                                                }
                                            }

                                            .dk-taskText {
                                                display: block;
                                                width: 100%;
                                                background: #D58B5D;
                                                border: 1px solid #D58B5D;
                                                color: #fff;
                                                height: 32px;
                                                text-align: center;
                                                line-height: 32px;
                                                font-size: 13px;
                                                text-transform: uppercase;
                                                border-radius: 3px;
                                            }



                                            input {
                                                border: 1px solid #c1c9dc;

                                                &:focus {
                                                    outline: 0;
                                                    box-shadow: none;
                                                }

                                                &.dk-tasktype-input[readonly] {
                                                    background-color: #e9ecef;
                                                    border: 1px solid #c1c9dc;
                                                    text-align: center;
                                                    border-radius: 3px;
                                                    text-transform: uppercase;
                                                    color: #333;

                                                    &::placeholder {
                                                        color: #333;
                                                        opacity: 1;
                                                        /* Firefox */
                                                    }

                                                    &:-ms-input-placeholder {
                                                        /* Internet Explorer 10-11 */
                                                        color: #fff;
                                                    }

                                                    &::-ms-input-placeholder {
                                                        /* Microsoft Edge */
                                                        color: #fff;
                                                    }
                                                }
                                            }

                                            .dk-tasktype-dropdown {
                                                .dk-tasktype-dropdown-toggle {
                                                    box-shadow: none;
                                                    border: 1px solid #c1c9dc;
                                                    padding: 5px 14px;
                                                    height: 34px;
                                                    border-radius: 3px;
                                                    background: #fff;
                                                    margin-top: 0px;
                                                    font-family: Verdana, sans-serif;
                                                    font-size: 14px;
                                                    text-transform: capitalize;
                                                    line-height: 22px;
                                                    color: #797474;
                                                    width: 100%;
                                                    position: relative;
                                                    margin: 0;

                                                    &::after {
                                                        position: absolute;
                                                        right: 10px;
                                                        top: 15px;
                                                    }
                                                }

                                                .dk-tasktype-dropdown-menu {
                                                    padding: 0;
                                                    border-radius: 0;
                                                    width: 100%;
                                                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                                                    border: none;

                                                    .dropdown-item {
                                                        padding: 7px 10px;
                                                        font-size: 13px;
                                                        color: #333;
                                                    }
                                                }
                                            }

                                            .dk-taskByCon {
                                                border-radius: 3px;
                                                height: 35px;
                                                padding: 0 10px !important;
                                                min-height: 35px;
                                                line-height: 35px;
                                                background: #e9ecef;
                                                box-shadow: none;
                                                border: 1px solid rgba(34, 36, 38, .15);
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 100%;

                                                .dk-selectator-textlength {
                                                    display: flex;
                                                    justify-content: center;
                                                    width: 100%;

                                                    &:first-child {
                                                        margin-right: 0.5rem;
                                                    }

                                                    &:last-child {
                                                        margin-right: 0;
                                                    }

                                                    .dk-selected-items {
                                                        display: flex;
                                                        background-color: #e9ecef;
                                                        border: 1px solid #c1c9dc;
                                                        width: auto;
                                                        justify-content: center;
                                                        height: 25px;
                                                        align-items: center;
                                                        padding: 3px 5px;
                                                        border-radius: 2px;
                                                        position: relative;

                                                        .userImgCont {
                                                            img {
                                                                width: 25px;
                                                                height: 23px;
                                                            }
                                                        }

                                                        .dk-selecteditem-remove {
                                                            display: inline-block;
                                                            font-weight: 700;
                                                            color: rgba(0, 0, 0, .75);
                                                            margin: 0 0 0 5px;
                                                            cursor: pointer;
                                                            font-size: 60%;
                                                            line-height: 10px;
                                                            vertical-align: top;
                                                            border-radius: 0 2px 2px 0;
                                                            position: absolute;
                                                            right: 0;
                                                            top: 0;
                                                            bottom: 0;
                                                            padding: 7px 5px 4px;
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-taskSwitch {
                                                color: #333;

                                                li.tg-list-item {
                                                    list-style-type: none;
                                                }
                                            }
                                        }

                                        .dk-caseText {
                                            display: block;
                                            width: 100%;
                                            background: #D58B5D;
                                            border: 1px solid #D58B5D;
                                            color: #fff;
                                            height: 32px;
                                            text-align: center;
                                            line-height: 32px;
                                            font-size: 13px;
                                            text-transform: uppercase;
                                            border-radius: 3px;
                                        }

                                        .form-group.dk-create-date-timeFrmGrp {
                                            margin-bottom: 1.25rem;

                                            label {
                                                font-size: 13px;
                                                display: block;
                                                color: #737373;
                                                text-align: left;
                                                margin-bottom: 1px;

                                                .input-group {
                                                    .input-group-prepend {
                                                        &.dkg-crete-new-task-idCon {
                                                            cursor: pointer;
                                                            border-right: none;

                                                            .input-group-text {
                                                                border-right: none;
                                                            }
                                                        }
                                                    }
                                                }

                                                .input-group-prepend {
                                                    .input-group-text {
                                                        background: #e9ecef;
                                                    }
                                                }

                                                .input-group {
                                                    .form-control.dk-subjectFrmControl {
                                                        border: 1px solid #c1c9dc;
                                                        text-align: left;
                                                        background: #e9ecef;
                                                    }
                                                }

                                                &.dk-cre-newtask-label-center {
                                                    text-align: center;
                                                }

                                                &.dk-TaskDate {
                                                    font-size: 13px;
                                                    display: block;
                                                    margin-bottom: 2px;
                                                    color: #737373;
                                                    text-align: left;

                                                    input {
                                                        border-top-right-radius: 0;
                                                        border-bottom-right-radius: 0;
                                                        border-right: none;
                                                        height: 35px;
                                                    }
                                                }

                                                &.dk-TaskTime {
                                                    font-size: 13px;
                                                    display: block;
                                                    margin-bottom: 2px;
                                                    color: #737373;
                                                    text-align: left;

                                                    input {
                                                        border-top-left-radius: 0;
                                                        border-bottom-left-radius: 0;
                                                        height: 35px;
                                                    }
                                                }

                                                &.dk-taskChoosefile {
                                                    position: relative;
                                                    border: 1px solid #ddd;
                                                    border-radius: 3px;
                                                    text-align: center;
                                                    transition: all 0.5s;

                                                    &:hover {

                                                        span {
                                                            background: #f2f2f2;
                                                        }
                                                    }

                                                    .file-select-name {
                                                        font-size: 12px;
                                                        display: inline-block;
                                                        position: absolute;
                                                        top: 8px;
                                                        left: 5px;
                                                    }

                                                    input {
                                                        width: 100%;
                                                        height: 35px;
                                                        position: relative;
                                                        z-index: 99;
                                                        opacity: 0;
                                                        border-radius: 3px;
                                                        border: 1px solid #ddd !important;
                                                        box-shadow: none !important;
                                                        cursor: pointer;
                                                    }

                                                    span {
                                                        background: #e9ecef;
                                                        padding: 0 25px;
                                                        display: inline-block;
                                                        height: 35px;
                                                        line-height: 35px;
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        z-index: 9;
                                                        width: 100%;
                                                        cursor: pointer;
                                                        color: #333;

                                                        &:hover {
                                                            background-color: #fff;
                                                        }
                                                    }
                                                }

                                                .dk-subjectFrmControl {
                                                    border-radius: 3px;
                                                    height: 35px;
                                                    padding: 0 10px !important;
                                                    min-height: 35px;
                                                    line-height: 35px;
                                                }

                                                &.dk-meeting-date-timelabel {
                                                    text-align: center;
                                                }
                                            }

                                            .dk-nativedatepicker-grid {
                                                .dk-nativedatepicker {
                                                    margin: 0;
                                                    padding: 0;
                                                    border: 1px solid #c1c9dc;
                                                    background: #e9ecef;
                                                    border-top-left-radius: 3px;
                                                    border-bottom-left-radius: 3px;

                                                    .MuiInputBase-root {
                                                        .MuiInputAdornment-root {
                                                            .MuiIconButton-root {
                                                                padding: 0;
                                                                margin: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .dk-nativetimeepicker-grid {
                                                .dk-nativetimeepicker {
                                                    margin: 0;
                                                    padding: 0;
                                                    border: 1px solid #c1c9dc;
                                                    background: #e9ecef;
                                                    border-top-right-radius: 3px;
                                                    border-bottom-right-radius: 3px;
                                                    *border-left: none !important;

                                                    .MuiInputBase-root {
                                                        .MuiInputAdornment-root {
                                                            .MuiIconButton-root {
                                                                padding: 0;
                                                                margin: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .MuiFormControl-root {
                                                padding: 0 6px;
                                                width: 100%;

                                                .MuiInputBase-root {
                                                    &.MuiInput-underline:before {
                                                        border: none !important;
                                                        height: 33px;
                                                        line-height: 0.81rem;
                                                        border-top-right-radius: 0;
                                                        border-bottom-right-radius: 0;
                                                        border-right: none !important;
                                                    }

                                                    &.MuiInput-underline:after {
                                                        border: none !important;
                                                        height: 33px;
                                                        line-height: 0.81rem;
                                                    }

                                                    input {
                                                        color: #333;
                                                        height: 25px;
                                                        text-align: center;
                                                        padding: 4px 0;
                                                        font-size: 13px;

                                                        &::-webkit-calendar-picker-indicator {
                                                            margin-left: 0px;
                                                        }

                                                        &[type="date"]::-webkit-inner-spin-button,
                                                        &[type="date"]::-webkit-calendar-picker-indicator,
                                                        &[type="time"]::-webkit-inner-spin-button,
                                                        &[type="time"]::-webkit-calendar-picker-indicator {
                                                            // display: none;
                                                            // -webkit-appearance: none;
                                                            color: #333;
                                                        }
                                                    }

                                                    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                                        margin-left: 0 !important;
                                                    }

                                                    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                                        .MuiButtonBase-root.MuiIconButton-root {
                                                            padding: 0.4rem 0.23rem;
                                                            border-radius: 0;

                                                            &:hover {
                                                                background-color: #e9ecef;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            & textarea.form-control {
                                                min-height: 125px;
                                                border: 1px solid #c1c9dc !important;
                                                padding: 5px 10px;
                                                background: #e9ecef;
                                                border-radius: 3px;
                                                font-size: 14px;
                                                color: #595959;
                                                font-family: Roboto, sans-serif;
                                                text-align: left;

                                                &:focus::placeholder {
                                                    opacity: 0;
                                                }

                                                &::-webkit-input-placeholder {
                                                    color: #595959;
                                                }

                                                &:-moz-placeholder {
                                                    /* Firefox 18- */
                                                    color: #595959;
                                                }

                                                &::-moz-placeholder {
                                                    /* Firefox 19+ */
                                                    color: #595959;
                                                }

                                                &:-ms-input-placeholder {
                                                    color: #595959;
                                                }

                                                &::placeholder {
                                                    color: #595959;
                                                }
                                            }
                                        }

                                        .form-group.dk-createNewTaskFooter {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            .dk-create-new-taskbtn {
                                                background-color: #26597d;
                                                border: none;
                                                border-radius: 2px;
                                                display: inline-block;
                                                height: 36px;
                                                line-height: 36px;
                                                padding: 0 2rem;
                                                text-transform: uppercase;
                                                vertical-align: middle;
                                                -webkit-tap-highlight-color: transparent;
                                                font-size: 1rem;
                                                outline: 0;
                                                text-decoration: none;
                                                color: #fff;
                                                text-align: center;
                                                letter-spacing: .5px;
                                                transition: .2s ease-out;
                                                margin-top: 0;
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.dk-todolistpaneAddNew {
                            .dk-addnewFrmGrp {
                                margin-bottom: 1rem;

                                label {
                                    color: #787777;
                                    font-size: 0.9125rem;
                                    text-align: left;
                                }

                                textarea {
                                    border: 1px solid #c5c5c5;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    background-color: #f2f2f2;
                                    font-size: 14px;
                                    border-radius: 0;
                                    text-align: left;

                                    &:focus {
                                        box-shadow: none;
                                        outline: 0;
                                    }
                                }
                            }

                            .dk-taskactsavebtnCon {
                                display: flex;
                                justify-content: center;
                                width: 100%;

                                .dk-taskactsavebtn {
                                    background: #2685A5;
                                    color: #fff;
                                    border: 1px solid #2685A5;
                                    width: 100px;
                                    text-align: center;
                                    display: inline-block;
                                    padding: 5px 12px;
                                    text-transform: uppercase;
                                    font-size: 13px;
                                    line-height: 1.5385;
                                    border-radius: 0.1875rem;
                                }
                            }
                        }

                        .dk-todolistContainer {
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            list-style-position: inside;
                            height: calc(100vh - 170px);
                            overflow-y: auto;

                            li {
                                margin: 12px 0;
                                background-color: #234158;
                                border-radius: 5px;
                                padding: 0.5rem 0.75rem;
                                border: 1px solid #234158;
                                color: #fff;

                                .dk-tasklist {
                                    width: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    line-height: 1.58rem;

                                    .dk-taslistCon {
                                        .react-contextmenu-wrapper {
                                            display: inline-block;

                                            .dk-todo-dropdowncon {
                                                .dropdown {
                                                    .dk-todo-dropdownbtn.dropdown-toggle {
                                                        margin: 0;
                                                        padding: 0;
                                                        box-shadow: none;
                                                        background-color: transparent;
                                                        margin-left: 4px;
                                                        font-size: 14px;
                                                        text-transform: capitalize;
                                                        font-weight: 600;

                                                        &::after {
                                                            display: none;
                                                        }
                                                    }

                                                    .dk-todo-dropdown-menu {
                                                        padding: 0;
                                                        border-radius: 0;

                                                        .dropdown-item {
                                                            padding: 0.35rem 0.75rem;
                                                            border-bottom: 1px solid #ccc;

                                                            &:last-child {
                                                                border-bottom: none;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .todorightDropmenu {
                                        border-radius: 0;
                                        background-color: #fff;
                                        min-width: 130px;
                                        z-index: 999;

                                        .react-contextmenu-item {
                                            border-bottom: 1px solid #ddd;
                                            padding: 6px 12px;
                                            font-size: 14px;
                                            cursor: pointer;
                                            color: #333;

                                            &:hover {
                                                background: #2980b9;
                                                color: #fff !important;

                                                i {
                                                    color: #fff;
                                                }
                                            }

                                            i {
                                                display: inline-block;
                                                width: 18px;
                                                color: #2980b9;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dkg-create-todo-task-modal.fade .modal-dialog {
    left: -600px !important;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dkg-create-todo-task-modal.show .modal-dialog {
    left: 0 !important;
}

.modal.dkg-create-todo-task-modal {
    z-index: 9999 !important;
}

//END CSS FOR LEFT MODAL