.dk-reorderCategoryMain{
    .modal-dialog{
        .modal-content{
            border-radius: 5px;
            background-color: transparent;
            .dk-reorderCategoryHeader{
                padding: 8px 10px;
                background-color: #37404d;
                color: #fff;
                border-radius: 5px 5px 0 0;
                .modal-title {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                }
                .close {
                    padding: 0;
                    position: absolute;
                    right: 6px;
                    top: 10px;
                    margin: 0;
                    opacity: 1;
                    text-shadow: none;
                    color: #fff;
                    font-size: 25px;
                    outline: none;
                    font-weight: 500;
                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
            .dk-reorderCategoryBody{
                background-color: #fff;
                border-radius: 0 0 5px 5px;
                .dk-toolsReorderPanel{
                    ul{
                        margin-bottom: 0;
                        .Mukul{
                            // background-color: #eaeaea;
                            .dk-valueFields{
                                .dk-handle{
                                    background-color: rgb(54, 63, 76);
                                    width: 50px;
                                    text-align: center;
                                    line-height: 35px;
                                    color: white;
                                    cursor: all-scroll;
                                }
                                input {
                                    height: 35px;
                                    border-radius: 0px;
                                    border: 1px solid #ddd;
                                    border-bottom: none;
                                    background: #f2f2f2;
                                    box-shadow: none;
                                    text-align: left;
                                }

                            }
                            &:last-child{
                                .dk-valueFields{
                                    input {
                                        border-bottom: 1px solid #ddd;
                                    }
                                }
                            }
                        }

                    }
                    .d-flex{
                        .dk-toolsPanelSaveBtn {
                            background: #165a77;
                            box-shadow: none;
                            color: #fff;
                            font-size: 14px;
                            outline: none;
                            padding: 8px 12px 7px;
                            border-radius: 4px;
                            border: none;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}