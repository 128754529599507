.dkg-bonus-infoModal {
    .modal-dialog {
        max-width: 500px;

        .modal-content {
            border-radius: 5px;

            .dkg-bonus-infoModalHeader {
                align-items: center;
                background-color: #eaeaea;
                border-bottom: none;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                display: flex;
                justify-content: center;
                padding: 10px;

                .modal-title {
                    color: #333;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                    width: 100%;
                }

                button {
                    opacity: 1;
                    color: #333;

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                    }
                }
            }
        }
    }
}