.modal-dialog.dk-adsCreAddNewPop{
    width: 280px;

}
.modal-dialog.dk-adsCreAddNewPop .modal-content{
    box-shadow: none;
    background-color: transparent;
}
.modal-dialog.dk-adsCreAddNewPop .modal-content .modal-body{
    padding: 20px 30px;
    background-color: #F2F2F2;
    border-radius: 6px;
}
.modal-body.dk-compaginsCreateNew form .form-group .dk-globalUserDropdown.dropdown button{
    box-shadow: none;
    background-color: #eaeaea !important;
    border: 1px solid #ddd;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}
.modal-body.dk-compaginsCreateNew form .form-group .dk-globalUserDropdown.dropdown button::after{
    display: none;
}
.modal-body.dk-compaginsCreateNew form .form-group .dk-globalUserDropdown.dropdown button i{
    color: #333;
}
.modal-body.dk-compaginsCreateNew form .form-group .dk-globalUserDropdown.dropdown .dropdown-menu {
    -webkit-transform: translate(45px,-5px)!important;
    transform: translate(45px,-5px)!important;
    padding: 5px;
}
.modal-body.dk-compaginsCreateNew form .form-group .dk-globalUserDropdown.dropdown .dropdown-menu a.dropdown-item {
    padding: 0;
    margin: 0 3px;
}
.modal-body.dk-compaginsCreateNew form .form-group .dk-globalUserDropdown.dropdown .dropdown-menu a.dropdown-item img {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.modal-body.dk-compaginsCreateNew form .form-group .dk-globalUserDropdown.dropdown .dropdown-menu:after {
    content: "";
    width: 0;
    height: 0;
    border-color: transparent #ccc transparent transparent;
    border-style: solid;
    border-width: 8.5px 12px 8.5px 0;
    line-height: 0;
    _border-color: #000 #ccc #000 #000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
    position: absolute;
    left: -12px;
    top: 15px;
}
.dk-adsCreditInCon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
}
.dk-adsCreditInCon .firstInput{
    max-width: 100px;
}
.dk-adsCreditInCon .firstInput label{
    width: 100%;
    text-align: center;
}
.dk-adsCreditInCon .firstInput input.form-control{
    border-radius: 0;
    text-align: center;
    background-color: #E2E7F6;
    outline: 0;
}
.dk-adsCreditInCon .firstInput input.form-control:focus{
    box-shadow: none;
}
.dk-adsCreditInCon .SecondInput{
    max-width: 100px;
}
.dk-adsCreditInCon .SecondInput label{
    width: 100%;
    text-align: center;
}
.dk-adsCreditInCon .SecondInput input.form-control{
    border-radius: 0;
    text-align: center;
}
.dk-adsCreditBtnCon{
    width: 100%;
    text-align: center;
}
.dk-adsCreditBtnCon .dk-calcsaveBtn{
    border-radius: 35px;
    color: #fff !important;
    padding: 8px 20px;
    letter-spacing: 1px;
    background: #5e737d !important;
    min-width: 90px;
    box-shadow: none;
}

.dk-adsCreditBtnCon .dk-calcsaveBtn:hover{
    background-color: #374252 !important;
    transition: all .5s;
    text-decoration: none;
    box-shadow: none;
}