.dk-trainingModule-main {
    margin-top: 110px;
    width: 100%;

    h3.title {
        text-align: center;
        display: block;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        margin-bottom: 20px;
    }

    .dk-trainingModule-cols {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 765px;
        margin: auto;

        .dk-trainingModule-box {
            display: inline-block;
            margin: 0 15px 25px;
            width: 100%;

            a {
                padding: 30px 10px;
                background: #fefefe;
                border-radius: 8px;
                min-height: 125px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;

                .dk-trainingLogo.text-inherit {
                    text-transform: capitalize;
                }

                .dk-trainingLogo {
                    text-align: center;
                    display: block;
                    text-transform: uppercase;
                    font-size: 16px;
                    text-align: center;
                    font-weight: 500;

                    span {
                        display: block;
                        margin-bottom: 5px;
                        width: 100%;

                        img {
                            max-width: 40px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.dk-moduleHead {
    display: inline-flex;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 15px;

    li {
        list-style-type: none;
        width: 100%;
        max-width: 170px;
        min-width: 170px;

        span {
            text-align: center;
            display: block;
            padding: 0 0 5px;
            font-size: 16px;
        }

        .dropdown {
            .dropdown-toggle {
                padding: 5px 15px;
                margin: 0;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: none;
                background: #ddd;
                border-left: 1px solid #b9b9b9;
                font-size: 12px;
                white-space: nowrap;
                font-weight: 300;
                width: 100%;

                &::after {
                    position: absolute;
                    right: 12px;
                    top: 16px;
                    font-size: 16px;
                    display: none;
                }

                &:hover {
                    background: #ddd;
                }
            }

            .dropdown-menu {
                padding: 0;
                border-radius: 0;
                border: none;
                transform: inherit !important;
                top: 28px !important;
                width: 100%;

                a {
                    background: #f2f2f2;
                    padding: 8px 12px;
                    border-bottom: 1px solid #ccc;
                    font-size: 12px;
                    text-align: left;
                    width: 100%;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            .dropdown-item {
                &:hover {
                    background: #ddd;
                }
            }
        }

        a {
            color: #333;
            text-transform: uppercase;
            font-size: 12px;
            white-space: nowrap;
            font-weight: 400;
            text-align: center;
            padding: 5px 15px;
            display: inline-block;
            background: #ddd;
            width: 100%;
        }

        a.active {
            background: #3c4c62;
            color: #fff;
        }

        &:first-child {
            a {
                border-radius: 5px 0 0 5px;
            }
        }

        &:last-child {
            .dropdown {
                .dropdown-toggle {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }
}

.trainingModule-flexMian {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}