.modal-dialog.dk-addnewtitlecampaign{
    min-width: 35%;
}
.modal-dialog.dk-addnewtitlecampaign .modal-content{
    box-shadow: none;
    background-color: transparent;
}
.modal-dialog.dk-addnewtitlecampaign .modal-content .modal-body.camp-addnewTitlebody{
    padding: 20px 30px;
    background-color: #F2F2F2;
    border-radius: 6px;
}
.modal-dialog.dk-addnewtitlecampaign .modal-content .modal-body.camp-addnewTitlebody .dk-newtitleinputCon{
    display: block;
    padding-bottom: 1.5rem;
}
.dk-newtitleinputCon .firstInput{
    width: 100%;
}
.dk-newtitleinputCon .firstInput label{
    width: 100%;
    text-align: left;
}
.dk-newtitleinputCon .firstInput input.form-control{
    border-radius: 0;
    text-align: center;
    background-color: #E2E7F6;
    outline: 0;
    margin-bottom: 1rem;
}
.dk-newtitleinputCon .firstInput input.form-control:focus{
    box-shadow: none;
}
.dk-newtitleinputCon .SecondInput{
    width: 100%;
}
.dk-newtitleinputCon .SecondInput label{
    width: 100%;
    text-align: left;
}
.dk-newtitleinputCon .SecondInput input.form-control{
    border-radius: 0;
    text-align: center;
}
.dk-adsCreditBtnCon{
    width: 100%;
    text-align: center;
}
.dk-adsCreditBtnCon .dk-calcsaveBtn{
    border-radius: 35px;
    color: #fff !important;
    padding: 8px 20px;
    letter-spacing: 1px;
    background: #5e737d !important;
    min-width: 90px;
    box-shadow: none;
}
.dk-adsCreditBtnCon .dk-calcsaveBtn:hover{
    background-color: #374252 !important;
    transition: all .5s;
    text-decoration: none;
    box-shadow: none;
}