.dkg-savedads-mainpageCon{
    margin-top: 120px ;
    height: calc(100vh - 120px);
    padding: 0 15x;
    .dk-Adsbox.dkg-livejobs-adsboxRow {
        padding-top: 0.51rem;
        .dkg-livejobs-adsboxCol {
            .dkg-livejobs-adsboxRow-234 {
                height: calc(100vh - 127px) !important;
                .dkg-livejobs-adsboxleftSide {
                    background-color: #244158;
                    flex: 0 0 14.666667%;
                    padding-left: 15px;
                    padding-right: 0;
                    margin-top: 14px;
                    height: calc(100vh - 250px);

                    .dkg-livejobs-adsbox-navpills {
                        display: block !important;
                        width: 100%;
                        background-color: #fff;

                        .nav-item {
                            max-width: inherit;

                            .nav-link {
                                background-color: #244158 !important;
                                border-bottom: 1px solid #3e5b73 !important;
                                color: #fff;
                                text-align: left !important;
                                border-radius: 0;
                                &.active {
                                    background-color: #eaeaea !important;
                                    color: #333;
                                }
                            }

                            &:first-child {
                                border-top: none;
                                .nav-link{
                                    &.active{
                                        border-top: 1px solid #ddd !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .dkg-livejobs-adsboxRightSide {
                    flex: 0 0 83.333333%;
                    max-width: 83.333333%;
                    margin-left: 2%;
                    .tab-content{
                        color: inherit;
                        .dkg-taxcalculator-tabpane{
                            .dkg-salary-tab-box{
                                margin-top: 14px;
                                .dkg-salary-tab-header{
                                    background: #dddddd;
                                    border: 1px solid #cecece;
                                    border-bottom: none;
                                    width: 100%;
                                    padding: 5px;
                                    height: 40px;
                                    border-radius: 5px 5px 0 0;
                                    position: relative;
                                    .dkg-salary-header-title{
                                        text-align: center;
                                        color: #3e3e3e;
                                        font-size: 20px;
                                        font-weight: normal;
                                    }
                                }
                                .dkg-salary-tabbody{
                                    background-color: #ddd;
                                    border: 1px solid #cecece;
                                    border-radius: 0 0 5px 5px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 200px;
                                    margin-top: 100px;
                                    .dkg-income-inputgrp{
                                        width: 100%;
                                        max-width: 95%;
                                        margin: 0 auto;
                                        display: flex;
                                        align-items: center;
                                        .dkg-input-url{
                                            margin: 0;
                                            border: 1px solid #ddd;
                                            padding: 8px 15px;
                                            height: 38px;
                                            text-align: left;
                                        }
                                        .dkg-open-url-btn{
                                            background: #26597d;
                                            border: none;
                                            color: #fff;
                                            border-radius: 0;
                                            cursor: pointer;
                                            display: inline-block;
                                            font-size: 14px;
                                            font-weight: 500;
                                            min-width: 80px;
                                            outline: none;
                                            padding: 8px 15px;
                                            text-transform: uppercase;
                                            white-space: nowrap;
                                            height: 38px;
                                            margin: 0;
                                            &:hover{
                                                box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                        .tab-pane{
                            .dk-ClentViewTabPane{
                                .dkg-interviewnotes-editor12 {
                                    margin-top: 1rem;

                                    .dkg-jobtitleInput {

                                        &:focus,
                                        &:focus-visible {
                                            box-shadow: none;
                                            outline: 0;
                                        }
                                    }

                                    .wrapper-class {
                                        padding: 0 !important;
                                        border: none !important;

                                        .rdw-editor-toolbar.toolbar-class {
                                            margin-bottom: 0;

                                            .rdw-inline-wrapper {
                                                .rdw-option-wrapper {

                                                    &:nth-child(05),
                                                    &:nth-child(06),
                                                    &:nth-child(07) {
                                                        display: none;
                                                    }
                                                }
                                            }

                                            .rdw-option-wrapper.rdw-option-disabled {
                                                display: none;
                                            }
                                        }

                                        .editor-class.rdw-editor-main {
                                            min-height: inherit !important;
                                            max-height: inherit !important;
                                            background-color: #fff;
                                            height: calc(100vh - 291px);
                                            padding: 15px;
                                            .DraftEditor-root{
                                                .DraftEditor-editorContainer{
                                                    .public-DraftEditor-content{
                                                        div{
                                                            div{
                                                                .public-DraftStyleDefault-block{
                                                                    span{
                                                                        background-color: transparent !important;
                                                                        color: inherit !important;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .dkg-cost-explain-Con{
                                    padding-top: 15px;
                                    .dkg-cost-explain-header{
                                        background: #ddd;
                                        border: 1px solid #cecece;
                                        border-radius: 5px 5px 0 0;
                                        height: 40px;
                                        padding: 5px;
                                        position: relative;
                                        width: 100%;
                                        .dkg-cost-explain-title{
                                            color: #3e3e3e;
                                            font-size: 20px;
                                            font-weight: 400;
                                            text-align: center;
                                        }
                                    }
                                    .dkg-cost-explain-body{
                                        .dkg-cost-explain-textArea{
                                            height: calc(100vh - 290px);
                                            border-top: none;
                                            border: 1px solid #cecece;
                                            border-radius: 0 0 5px 5px;
                                            padding: 5px 10px;
                                            &:focus{
                                                outline: 0;
                                                box-shadow: none;
                                            }
                                        }
                                        .dkg-open-url-btnCon{
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            width: 100%;
                                            .dkg-open-url-btn{
                                                background-color: #234158;
                                                border: 1px solid #234158;
                                                border-radius: 0.1875rem;
                                                color: #fff;
                                                display: inline-block;
                                                font-size: 13px;
                                                line-height: 1.5385;
                                                padding: 5px 12px;
                                                text-align: center;
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                    
                                }
                                .dkg-savebtnCon {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    margin-top: 20px;

                                    div {

                                        div {
                                            .dkg-create-payment-taskbtn {
                                                background: #234158;
                                                border: 1px solid #234158;
                                                border-radius: 0.1875rem;
                                                color: #fff;
                                                display: inline-block;
                                                font-size: 13px;
                                                line-height: 1.5385;
                                                margin-left: 120px;
                                                padding: 5px 12px;
                                                text-align: center;
                                                text-transform: uppercase;

                                            }
                                        }
                                    }
                                }

                            }
                            .summaryTabPane {

                                .dk-suMMLeftCon {
                                    // background: #eaeaea;
                                    background-color: #eaeaea;
                                    border: 1px solid #cecece;
                                    border-radius: 5px;
                                    padding: 15px;
                                    margin: 0;
                                    height: calc(100vh - 121px);
                                    overflow-y: auto;
                                    .dkg-company-rigt-dessc{
                                        background-color: #fff;
                                        padding: 10px 15px;
                                        border-radius: 5px;
                                        height: 150px;
                                        overflow-y: auto;
                                    }
                                    &.dkg-editcom-summbG {
                                        background-color: #eaeaea;
                                    }

                                    .dk-topinputGroup {
                                        .input-group {
                                            .input-group-text {
                                                background: #ddd;
                                                border: 1px solid #cecece;
                                                border-right: 0px;
                                                border-radius: 0px;
                                                padding: 0.775rem 1.2rem;
                                                font-size: .95rem;
                                                min-width: 140px;
                                            }

                                            input {
                                                padding: 0.775rem 1.2rem;
                                                border-radius: 0;
                                                height: 44px;
                                                font-size: 1.12rem;
                                                color: #333;

                                                &:focus {
                                                    outline: 0;
                                                    box-shadow: none;
                                                }
                                            }
                                        }
                                    }

                                    .dk-toprightGroup {
                                        display: flex;

                                        .firstBox {
                                            display: flex;
                                            margin-top: 3px;

                                            span {
                                                margin-top: 8px;
                                                margin-right: 8px;
                                            }

                                            .dk-AmDropDwnCon {
                                                color: #333;

                                                .dk-AmDropDwn.dropdown {
                                                    .dk-AmDropDwnToggle {
                                                        img {
                                                            width: 37px;
                                                            height: 37px;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .secondBox {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            height: 37px;
                                            width: 37px;
                                            border: 1px solid #cecece;
                                            border-radius: 50%;
                                            background: #2685a5;
                                            margin-top: 2px;
                                            position: relative;
                                            margin-left: 4px;

                                            span.counterNumber {
                                                color: #fff;

                                            }
                                        }

                                        .thridBox {
                                            padding-left: 0.64rem;

                                            .AmImgConDrop {
                                                .dk-suMMSrchDropDwnCon {
                                                    .dk-suMMSrchDropDwn {
                                                        a {
                                                            img {
                                                                width: 37px;
                                                                height: 37px;
                                                                border-radius: 50%;
                                                                border: 2px dotted #999;
                                                            }
                                                        }
                                                    }

                                                    .dk-suMMSrchDropDwnMenu {
                                                        border-radius: 0;
                                                        padding: 0;

                                                        input {
                                                            border-bottom: 1px solid #ccc;
                                                        }

                                                        ul {
                                                            padding: 0;
                                                            max-height: 220px;
                                                            overflow-y: auto;

                                                            .dk-assignRecruitersCon {
                                                                p {
                                                                    padding: 0.25rem 1rem;
                                                                    margin-bottom: 0;
                                                                    font-size: 0.95rem;
                                                                }

                                                                .dk-reclistItem {
                                                                    padding: 0.25rem 1rem;

                                                                    &.active {
                                                                        .dk-assignRecCon {
                                                                            span {
                                                                                color: #fff;
                                                                            }
                                                                        }
                                                                    }

                                                                    .dk-assignRecCon {
                                                                        display: flex;
                                                                        position: relative;
                                                                        align-items: center;

                                                                        img.recruiterimg {
                                                                            width: 35px;
                                                                            height: 35px;
                                                                            border-radius: 50%;
                                                                            border: 2px dotted #ddd;
                                                                            margin-right: 10px;
                                                                        }

                                                                        span {
                                                                            font-size: 0.85rem;
                                                                            font-weight: bold;
                                                                            color: #333;
                                                                        }

                                                                        .crossIcon {
                                                                            position: absolute;
                                                                            right: 10px;

                                                                            i {
                                                                                font-size: 16px;
                                                                                color: #333;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            .dk-remaingRecruiters {
                                                                p {
                                                                    padding: 0.25rem 1rem;
                                                                    margin-bottom: 0;
                                                                    font-size: 0.95rem;
                                                                }

                                                                .dk-reclistItem {
                                                                    padding: 0.25rem 1rem;

                                                                    &.active {
                                                                        .dk-unassignRecCon {
                                                                            span {
                                                                                color: #fff;
                                                                            }
                                                                        }
                                                                    }

                                                                    .dk-unassignRecCon {
                                                                        img.recruiterimg {
                                                                            width: 35px;
                                                                            height: 35px;
                                                                            border-radius: 50%;
                                                                            border: 2px dotted #ddd;
                                                                            margin-right: 10px;
                                                                        }

                                                                        span {
                                                                            font-size: 0.85rem;
                                                                            opacity: .8;
                                                                            font-weight: bold;
                                                                            color: #212529;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .dk-suMMformGroupCon {
                                        display: flex;
                                        width: 100%;

                                        label {
                                            align-items: center;
                                            min-width: 90px;
                                            font-size: 0.90rem;
                                            white-space: nowrap;
                                        }

                                        .dk-statusDrpDwnCon {
                                            width: 100%;
                                            display: flex;

                                            .dk-simpleDrpDwnCon {
                                                .dk-simpleDrpDwn {
                                                    .dk-simpleDrpDwnToggle {
                                                        background-color: #fff;
                                                        border: 1px solid #ddd;
                                                    }
                                                }
                                            }

                                            .dk-statusDrpDwn {
                                                width: 100%;

                                                .dk-statusDrpDwnToggle {
                                                    box-shadow: none;
                                                    display: flex;
                                                    width: 100%;
                                                    margin: 0;
                                                    padding: 0.35rem 0.75rem;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 0;
                                                    text-transform: capitalize;
                                                    font-size: 0.95rem;

                                                    &::after {
                                                        display: none;
                                                    }
                                                }

                                                .dk-statusDrpDwnMenu {
                                                    border-radius: 0;
                                                    padding: 0;
                                                    width: 100%;

                                                    .dropdown-item {
                                                        padding: 0.35rem 0.75rem;
                                                        font-size: 0.9rem;
                                                    }
                                                }
                                            }
                                        }

                                        .dk-simpleDrpDwnCon {
                                            width: 100%;

                                            .dk-simpleDrpDwn {
                                                .dk-simpleDrpDwnToggle {
                                                    color: #333;
                                                    box-shadow: none;
                                                    white-space: nowrap;
                                                    margin: 0;
                                                    background-color: #fff;
                                                    border: 1px solid #ccc;
                                                    padding: 0.40rem 0.75rem;
                                                    text-transform: capitalize;
                                                    display: flex;
                                                    width: 100%;
                                                    justify-content: center;
                                                    font-size: 0.90rem;
                                                    position: relative;

                                                    &::after {
                                                        position: absolute;
                                                        right: 5px;
                                                        top: 13px;
                                                    }
                                                }

                                                .dk-simpleDrpDwnMenu {
                                                    border-radius: 0;
                                                    max-height: 180px;
                                                    width: 100%;
                                                    overflow-y: auto;
                                                    padding: 0;
                                                    border-color: #96c8da;
                                                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                    .dropdown-item {
                                                        padding: 0.35rem 0.75rem;
                                                        font-size: 0.9rem;
                                                    }

                                                    input {
                                                        border-bottom: 1px solid #ccc;
                                                    }
                                                }
                                            }
                                        }

                                        // dk-statusDrpDwnCon
                                        input {
                                            display: flex;
                                            width: 100%;
                                            margin: 0;
                                            padding: 0.35rem 0.75rem;
                                            justify-content: center;
                                            align-items: center;
                                            border-radius: 0;
                                            text-transform: capitalize;
                                            font-size: 0.95rem;
                                            text-align: center;

                                            &:focus {
                                                outline: 0;
                                                box-shadow: none;
                                            }
                                        }

                                        .input-group {
                                            input {
                                                border-right: 0;
                                            }

                                            .input-group-prepend {
                                                background-color: #fff;

                                                .input-group-text {
                                                    background-color: #fff;
                                                }
                                            }
                                        }
                                    }

                                    .dkg-editcompany-textArea-Con {
                                        .dkg-title {
                                            background: #dddddd;
                                            border: 1px solid #cecece;
                                            border-bottom: none;
                                            width: 100%;
                                            padding: 5px;
                                            height: 40px;
                                            border-radius: 5px 5px 0 0;

                                            h3 {
                                                text-align: center;
                                                color: #3e3e3e;
                                                font-size: 20px;
                                                font-weight: normal;
                                                margin-bottom: 0;
                                                width: 100%;
                                            }
                                        }

                                        .dkg-editcompany-textArea {
                                            border-radius: 0 0 5px;
                                        }
                                    }
                                }

                                .dk-jobsuMMBottomSec {
                                    color: #3e3e3e;

                                    .dk-jobsuMMBottomBoxBg {
                                        color: #333;

                                        .dkg-salary-curr-dropdown-toggle {
                                            color: red;

                                            .dk-simpleDrpDwnCon {
                                                .dk-simpleDrpDwn {
                                                    .dk-simpleDrpDwnToggle {
                                                        min-width: 90px;
                                                        height: 39px;
                                                        border: 1px solid #ddd;
                                                        line-height: 24px;
                                                    }
                                                }
                                            }
                                        }

                                        .dk-suMMformGroupCon {
                                            min-height: 32px;
                                        }

                                        .dk-BoxHeader {
                                            background: #dddddd;
                                            border: 1px solid #cecece;
                                            border-bottom: none;
                                            width: 100%;
                                            padding: 5px;
                                            height: 40px;
                                            border-radius: 5px 5px 0 0;

                                            h3 {
                                                text-align: center;
                                                color: #3e3e3e;
                                                font-size: 20px;
                                                font-weight: normal;
                                                position: relative;
                                                .dkg-pophover-icon{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    width: 18px;
                                                    height: 18px;
                                                    background-color: #eaeaea;
                                                    position: absolute;
                                                    top: 3px;
                                                    right: 65px;
                                                    border-radius: 50%;
                                                    border: 1px solid #ddd;
                                                    cursor: pointer;
                                                    svg{
                                                        width: 14px;
                                                        height: 14px;
                                                    }
                                                }
                                            }
                                        }

                                        .dk-boxBody {
                                            background: #eaeaea;
                                            border: 1px solid #cecece;
                                            border-radius: 0 0 5px 5px;
                                            padding: 15px;
                                            &.dkg-livejobs-boxbody{
                                                padding-top: 15px;
                                            }
                                            .dk-suMMformGroupCon {
                                                display: flex;
                                                width: 100%;
                                                margin-bottom: 0.3rem;

                                                .label-hover:hover {
                                                    color: blue;
                                                }

                                                input {
                                                    border-bottom: 1px solid #ccc;
                                                }

                                                label {
                                                    align-items: center;
                                                    font-size: 0.90rem;
                                                    white-space: nowrap;
                                                    padding-right: 0.5rem;
                                                    min-width: 110px !important;
                                                    &.dkg-detil-rightlabel{
                                                        min-width: 135px !important;
                                                    }
                                                    &.dkg-gross-slary-label{
                                                        cursor: pointer;
                                                    }
                                                    svg{
                                                        width: 15px;
                                                        height: 15px;
                                                    }
                                                    
                                                }

                                                label.dk-benfitslabel {
                                                    min-width: 20px;
                                                    max-width: 20px;
                                                }

                                                label.relocationlabel {
                                                    min-width: 147px !important;
                                                }

                                                .dk-simpleDrpDwnCon {
                                                    width: 100%;

                                                    .dk-simpleDrpDwn {
                                                        .dk-simpleDrpDwnToggle {
                                                            color: #333;
                                                            box-shadow: none;
                                                            white-space: nowrap;
                                                            margin: 0;
                                                            background-color: #fff;
                                                            border: 1px solid #ccc;
                                                            padding: 0.35rem 0.75rem;
                                                            text-transform: capitalize;
                                                            display: flex;
                                                            width: 100%;
                                                            justify-content: center;
                                                            font-size: 0.90rem;
                                                            position: relative;

                                                            &::after {
                                                                position: absolute;
                                                                right: 5px;
                                                                top: 13px;
                                                            }
                                                        }

                                                        .dk-simpleDrpDwnMenu {
                                                            border-radius: 0;
                                                            max-height: 180px;
                                                            width: 100%;
                                                            overflow-y: auto;
                                                            padding: 0;
                                                            border-color: #96c8da;
                                                            box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                            .dropdown-item {
                                                                padding: 0.35rem 0.75rem;
                                                                font-size: 0.9rem;
                                                            }
                                                        }
                                                    }
                                                }

                                                .dkg-salary-curr-dropdown-toggle {
                                                    .dk-simpleDrpDwn {
                                                        .dk-simpleDrpDwnToggle {
                                                            color: #333;
                                                            box-shadow: none;
                                                            white-space: nowrap;
                                                            margin: 0;
                                                            background-color: #fff;
                                                            border: 1px solid #ccc;
                                                            padding: 0.35rem 0.75rem;
                                                            text-transform: capitalize;
                                                            display: flex;
                                                            width: 100%;
                                                            justify-content: center;
                                                            font-size: 0.90rem;
                                                            position: relative;

                                                            &::after {
                                                                position: absolute;
                                                                right: 5px;
                                                                top: 17px;
                                                            }
                                                        }

                                                        .dk-simpleDrpDwnMenu {
                                                            border-radius: 0;
                                                            max-height: 180px;
                                                            width: 100%;
                                                            overflow-y: auto;
                                                            padding: 0;
                                                            border-color: #96c8da;
                                                            box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                            .dropdown-item {
                                                                padding: 0.35rem 0.75rem;
                                                                font-size: 0.9rem;
                                                            }
                                                        }
                                                    }
                                                }

                                                input {
                                                    display: flex;
                                                    width: 100%;
                                                    margin: 0;
                                                    padding: 0.35rem 0.75rem;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 0;
                                                    text-transform: capitalize;
                                                    font-size: 0.95rem;
                                                    text-align: center;

                                                    &:focus {
                                                        outline: 0;
                                                        box-shadow: none;
                                                    }
                                                }

                                                input.dk-benfitsInput {
                                                    max-height: 30px;
                                                }

                                                .input-group {
                                                    input {
                                                        border-right: 0;
                                                    }

                                                    .input-group-prepend {
                                                        background-color: #fff;

                                                        .input-group-text {
                                                            background-color: #fff;
                                                        }
                                                    }

                                                    .dkg-salary-curr-dropdown {
                                                        .dkg-salary-curr-dropdown-toggle {
                                                            margin: 0;
                                                            box-shadow: none;
                                                            border: 1px solid #ccc;
                                                            background-color: #fff;
                                                            padding: 10px 12px;
                                                            font-size: 0.95rem;

                                                            svg {
                                                                width: 13px;
                                                                height: 13px;
                                                            }
                                                        }

                                                        .dkg-salary-curr-dropdown-menu {
                                                            border-radius: 0;
                                                            width: 100%;
                                                            padding: 0;
                                                            margin: 0;
                                                            max-height: 230px;
                                                            overflow-y: auto;

                                                            .dkg-salary-curr-dropdown-item {
                                                                text-align: left;
                                                                padding: 7px 9px;
                                                                border-bottom: 1px solid #ccc;
                                                                font-size: 0.95rem;

                                                                svg {
                                                                    width: 14px;
                                                                    height: 14px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .dk-boxKpi {
                                            background: #eaeaea;
                                            border-top: 1px solid #b9b9b9;
                                            border-radius: 0 0 5px 5px;
                                            // height: calc(100vh - 512px);
                                            // overflow-y: auto;
                                            .dk-suMMformGroupCon {
                                                display: flex;
                                                border-bottom: 1px solid #cecece;
                                                width: 100%;
                                                margin-bottom: 0rem;
                                                &:last-child{
                                                    border-radius: 0 0 5px 5px;
                                                }
                                                .label-hover:hover {
                                                    color: blue;
                                                }

                                                input {
                                                    border-bottom: 1px solid #ccc;
                                                }

                                                label {
                                                    align-items: center;
                                                    min-width: 117px;
                                                    font-size: 0.90rem;
                                                    white-space: nowrap;
                                                    padding-right: 0.5rem;
                                                }

                                                label.dk-benfitslabel {
                                                    min-width: 20px;
                                                    max-width: 20px;
                                                }

                                                label.relocationlabel {
                                                    min-width: 125px;
                                                }

                                                .dk-simpleDrpDwnCon {
                                                    width: 100%;

                                                    .dk-simpleDrpDwn {
                                                        .dk-simpleDrpDwnToggle {
                                                            color: #333;
                                                            box-shadow: none;
                                                            white-space: nowrap;
                                                            margin: 0;
                                                            background-color: #fff;
                                                            border: 1px solid #ccc;
                                                            padding: 0.35rem 0.75rem;
                                                            text-transform: capitalize;
                                                            display: flex;
                                                            width: 100%;
                                                            justify-content: center;
                                                            font-size: 0.90rem;
                                                            position: relative;

                                                            &::after {
                                                                position: absolute;
                                                                right: 5px;
                                                                top: 13px;
                                                            }
                                                        }

                                                        .dk-simpleDrpDwnMenu {
                                                            border-radius: 0;
                                                            max-height: 180px;
                                                            width: 100%;
                                                            overflow-y: auto;
                                                            padding: 0;
                                                            border-color: #96c8da;
                                                            box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                            .dropdown-item {
                                                                padding: 0.35rem 0.75rem;
                                                                font-size: 0.9rem;
                                                            }
                                                        }
                                                    }
                                                }

                                                input {
                                                    display: flex;
                                                    width: 100%;
                                                    margin: 0;
                                                    padding: 0.35rem 0.75rem;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 0;
                                                    text-transform: capitalize;
                                                    font-size: 0.95rem;
                                                    text-align: center;

                                                    &:focus {
                                                        outline: 0;
                                                        box-shadow: none;
                                                    }
                                                }

                                                input.dk-benfitsInput {
                                                    max-height: 30px;
                                                }

                                                .input-group {
                                                    input {
                                                        border-right: 0;
                                                    }

                                                    .input-group-prepend {
                                                        background-color: #fff;

                                                        .input-group-text {
                                                            background-color: #fff;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .dkg-editcompany-headerCon {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-width: 1px 1px 0px;
                                        border-top-style: solid;
                                        border-right-style: solid;
                                        border-bottom-style: initial;
                                        border-left-style: solid;
                                        border-top-color: rgb(204, 204, 204);
                                        border-right-color: rgb(204, 204, 204);
                                        border-bottom-color: initial;
                                        border-left-color: rgb(204, 204, 204);
                                        border-image: initial;
                                        padding: 11px 8px;
                                        position: relative;
                                        background-color: #eaeaea;

                                        .dkg-editcompany-leftCon {
                                            position: absolute;
                                            left: 10px;
                                            top: 6px;

                                            .dk-simpleDrpDwnCon {
                                                width: 100%;

                                                .dk-simpleDrpDwn {
                                                    .dk-simpleDrpDwnToggle {
                                                        color: #333;
                                                        box-shadow: none;
                                                        white-space: nowrap;
                                                        margin: 0;
                                                        background-color: #eaeaea;
                                                        border: 1px solid #ccc;
                                                        padding: 0.40rem 0.75rem;
                                                        text-transform: capitalize;
                                                        display: flex;
                                                        width: 100%;
                                                        justify-content: flex-start;
                                                        font-size: 0.90rem;
                                                        position: relative;
                                                        min-width: 180px;

                                                        &::after {
                                                            position: absolute;
                                                            right: 5px;
                                                            top: 13px;
                                                        }
                                                    }

                                                    .dk-simpleDrpDwnMenu {
                                                        border-radius: 0;
                                                        max-height: 180px;
                                                        width: 100%;
                                                        overflow-y: auto;
                                                        padding: 0;
                                                        border-color: #96c8da;
                                                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0.38, 0.15);

                                                        .dropdown-item {
                                                            padding: 0.35rem 0.75rem;
                                                            font-size: 0.9rem;
                                                        }

                                                        input {
                                                            border-bottom: 1px solid #ccc;
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }

                                    .dkg-edit-jobfiles-mainCon {
                                        .dkg-add-new-btnCon {
                                            margin-bottom: 5px;

                                            .dkg-addnew-btn {
                                                box-shadow: none;
                                                border: 1px solid #ccc;
                                                background-color: #fff;
                                                color: #333;
                                                font-size: 13px;
                                                text-transform: capitalize;
                                                padding: 7px 9px;
                                                // min-width: 110px;
                                                margin: 0;
                                            }
                                        }

                                        .dkg-edit-jobfiles-tableCon {
                                            .dkg-edit-jobfiles-table {
                                                thead {
                                                    tr {
                                                        th {
                                                            background: #485569;
                                                            border: 1px solid gray;
                                                            border-bottom: none;
                                                            color: #fff;
                                                            font-size: 13px;
                                                            padding: 9px 7px;
                                                            position: -webkit-sticky;
                                                            position: sticky;
                                                            text-align: center;
                                                            top: 0;
                                                            vertical-align: middle;
                                                            white-space: nowrap;
                                                            z-index: 9;
                                                        }
                                                    }
                                                }

                                                tbody {

                                                    tr {
                                                        td {
                                                            background: #fff;
                                                            border: 1px solid #ddd;
                                                            color: #4d4a4a;
                                                            font-size: 13px;
                                                            font-weight: 400;
                                                            padding: 9px 7px;
                                                            position: relative;
                                                            text-align: center;
                                                            text-transform: capitalize;
                                                            vertical-align: middle;
                                                            white-space: nowrap;

                                                            .dkg-viewfile-iconCon {
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;

                                                                .dkg-viewfile-icon {
                                                                    border-radius: 3px;
                                                                    font-size: 14px;
                                                                    height: 30px !important;
                                                                    line-height: 30px;
                                                                    width: 30px !important;
                                                                    background-color: #2196f3;
                                                                    color: #fff;
                                                                }
                                                            }

                                                            .dkg-file-actions {
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;

                                                                .dkg-viewfile-icon {
                                                                    border-radius: 3px;
                                                                    font-size: 14px;
                                                                    height: 30px !important;
                                                                    line-height: 30px;
                                                                    width: 30px !important;
                                                                    background-color: #2196f3;
                                                                    color: #fff;
                                                                }

                                                                .dkg-download-icon {
                                                                    border-radius: 3px;
                                                                    font-size: 14px;
                                                                    height: 30px !important;
                                                                    line-height: 30px;
                                                                    width: 30px !important;
                                                                    background-color: #31a886;
                                                                    color: #fff;
                                                                }

                                                                .dkg-editfile-icon {
                                                                    border-radius: 3px;
                                                                    font-size: 14px;
                                                                    height: 30px;
                                                                    line-height: 30px;
                                                                    width: 30px;
                                                                    background-color: #fee69c;
                                                                    color: #333;
                                                                }

                                                                .dkg-deletefile-icon {
                                                                    border-radius: 3px;
                                                                    font-size: 14px;
                                                                    height: 30px;
                                                                    line-height: 30px;
                                                                    width: 30px;
                                                                    background-color: #dc3545;
                                                                    color: #fff;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}