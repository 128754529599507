.dk-bibby-financial-Faq-main {
    margin-top: 120px;
    padding: 0 15px;

    h3.title {
        text-align: center;
        display: block;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333;
        margin-bottom: 20px;
    }

    .dk-bibby-financial-Tabs-main {
        display: flex;

        .dk-bibby-financial-Tabs-nav {
            width: 100%;
            max-width: 200px;
            margin-right: 40px;

            .nav {
                .nav-item {
                    display: block;
                    width: 100%;

                    &:first-child {
                        a {
                            border-radius: 5px 5px 0 0;
                        }
                    }

                    &:last-child {
                        a {
                            border-radius: 0 0 5px 5px;
                        }
                    }

                    a {
                        padding: 10px 12px;
                        display: block;
                        color: #fff;
                        background: #24475f;
                        border-bottom: 1px solid #527286;
                        position: relative;
                    }

                    a.active {
                        background: #1f3748;

                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 13px 0 14px 18px;
                            border-color: transparent transparent transparent #1f3744;
                            line-height: 0px;
                            _border-color: #000000 #000000 #000000 #1f3744;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            right: -15px;
                            top: 8px;
                        }
                    }
                }
            }
        }

        .dk-bibby-financial-Tabs-panel {
            width: 100%;

            .dkg-screen-faq-accodionCon {
                color: #1f3748;

                .dkg-screen-faq-accodion {
                    border: none !important;
                    border-bottom: 1px solid #4d5a6b !important;

                    .dkg-screen-faq-accodion-suMM {
                        background-color: #1f3748;
                        color: #fff;
                        border-bottom: 1px solid #4d5a6b;
                        min-height: 52px;

                        .MuiIconButton-root.MuiAccordionSummary-expandIcon {
                            .MuiIconButton-label {
                                color: #fff;

                            }
                        }
                    }

                    &:first-child {
                        .dkg-screen-faq-accodion-suMM {
                            border-radius: 5px 5px 0 0;

                            div {
                                p {
                                    font-size: 15px !important;
                                }
                            }
                        }
                    }

                    &:last-child {
                        .dkg-screen-faq-accodion-suMM {
                            border-radius: 0 0 5px 5px;
                        }
                    }

                    .MuiCollapse-root.MuiCollapse-entered {
                        .MuiCollapse-wrapper {
                            .MuiCollapse-wrapperInner {
                                div {
                                    .MuiAccordionDetails-root {
                                        background-color: #24475f;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}