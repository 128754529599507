.dk-bOverviewyears-containerMain {
    &.dkg-business-overview-pageCon {
        margin-top: 125px;

        .dk-bOverviewyears-table {
            height: calc(100vh - 235px) !important;

            .table.table-bordered.dkg-business-joined-table {
                color: #333;
            }
        }
    }
}