.dk-jobsSummMainPageCon {
    margin-top: 145px;
    position: relative;
    padding: 0 15px;
    width: 100%;
    // overflow-x: auto;
    // margin: auto;
    .dk-jobsuMMTopHead {
        display: flex;
        align-items: center;
        justify-content: center;

        .dk-jobsuMMTopLeft {
            display: flex;
            align-items: center;

            .dk-IconCon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                background-color: #eaeaea;
                border: 1px solid #ddd;
                border-radius: 50%;
                position: relative;

                i {
                    color: #5a5a5a;
                }

                svg {
                    color: #5a5a5a;
                    font-size: 18px;
                }

                .dk-jobssearchFilter {
                    .dropdown {
                        position: inherit;

                        a {
                            i {
                                color: #6c757d;
                            }
                        }

                        .dropdown-menu.show {
                            transform: inherit !important;
                            border-radius: 0;
                            padding-top: 0;
                            min-height: 230px;
                            max-height: 230px;
                            overflow-y: auto;
                            overflow-x: hidden;
                            right: inherit !important;
                            left: 0 !important;
                            top: 37px !important;
                            width: 100%;

                            input.form-control {
                                border-radius: 0;
                                border: none;
                                border-bottom: 1px solid #ddd;
                            }

                            input.form-control:focus {
                                outline: 0;
                                box-shadow: none;
                                border-bottom: 1px solid #333;
                            }
                        }

                        .dropdown-menu {
                            .dropdown-item {
                                padding: 5px 10px;
                                background: #fff !important;
                                min-height: 28px;
                                font-size: 12px;
                                color: #333;
                                white-space: nowrap;
                                text-transform: capitalize;

                                span.counterNum {
                                    float: right;
                                    background: #3a506b;
                                    color: #fff !important;
                                    padding: 2px 5px;
                                    min-width: 30px;
                                    text-align: center;
                                    font-size: 11px;
                                }

                            }
                        }

                        .dropdown-menu::-webkit-scrollbar {
                            width: 8px;
                        }

                        .dropdown-menu::-webkit-scrollbar-track {
                            background: #fff;
                        }

                        .dropdown-menu::-webkit-scrollbar-thumb {
                            background-color: #eaeaea;
                            border-radius: 4px;
                        }
                    }
                }
            }

            .dk-Filter-Droddwn.dropdown {
                .dkflterbtn.dropdown-toggle {
                    background-color: #eaeaea;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: none;
                    border: 1px solid #ddd;

                    svg {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    i {
                        font-size: 16px;
                        color: #6c757d;
                    }
                }

                .dkflterbtn.dropdown-toggle::after {
                    display: none;
                }

                .dropdown-menu {
                    transform: inherit !important;
                    top: -10px !important;
                    left: 53px !important;
                    margin: 0 !important;
                    box-shadow: none;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    padding: 0;
                    min-width: 100px;
                    display: flex;
                    align-items: center;

                    .dropdown-item {
                        padding: 0.25rem 0.75rem;

                        span {
                            display: block;
                            text-align: center;
                        }

                        span {
                            display: block;
                            text-align: center;

                            i {
                                color: #6c757d;
                                font-size: 15px;
                            }
                        }
                    }
                }

                .dropdown-menu::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7.5px 10px 7.5px 0;
                    border-color: transparent #ccc transparent transparent;
                    line-height: 0;
                    position: absolute;
                    left: -10px;
                    top: 35%;
                }
            }

            .jobsuMMRightFilter {
                .ui.selection.dropdown.dk-overdues-task-slect {
                    border-radius: 0;
                    min-width: 130px;
                }

                .ui.selection.dropdown .menu {
                    border-radius: 0;
                }
            }
        }

        .dk-jobsuMMTopCntre {
            h2.title {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                color: #333;
                text-transform: uppercase;
            }
        }

        .dk-jobsuMMTopRight {
            display: flex;
            align-items: center;

            .jobsuMMRightFilter {
                .ui.selection.dropdown.dk-overdues-task-slect {
                    border-radius: 0;
                    min-width: 130px;
                }

                .ui.selection.dropdown .menu {
                    border-radius: 0;
                }
            }
        }
    }

    .dk-jobsuMMBoxCon {
        flex: 0 0 100%;
        position: relative;
        width: 100%;
        padding-left: 0;
        height: calc(100vh - 190px);
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 1.83rem;
        .dk-jobsLeftBoxCon {
            display: flex;

            .dk-jobsLeftTopBoxCols {
                .headerCon {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border: 1px solid #dadada;
                    border-bottom: none;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #6c757d;

                    .title {
                        font-size: 13px;
                    }

                    .totalCount {
                        font-size: 13px;
                    }
                }
                margin:0 8px;
                width: 100%;
                ul {
                    list-style: none;
                    margin-bottom: 0;
                    align-items: center;
                    font-size: 12px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    height: 119px;
                    overflow-y: auto;
                    border-bottom-right-radius: 5px;
                    flex-wrap: wrap;
                    background-color: #eaeaea;
                    border: 1px solid #dadada;
                    padding-left: 0;

                    &.dk-top-headertitlelist {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }

                    &::-webkit-scrollbar {
                        width: 9px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #eaeaea;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #ddd;
                        border-radius: 4px;
                    }

                    &.dk-nohoveronlist {
                        li {
                            cursor: pointer !important;

                            &:hover {
                                background-color: #234158;
                                color: #fff;
                                cursor: text;

                                span {
                                    color: #fff;
                                }

                                i {
                                    color: #fff;
                                }
                            }
                            .caseStatusCounter{
                                color: #fff;
                            }
                        }
                    }

                    li {
                        width: 100%;
                        border-bottom: none;
                        padding: 7px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 13px;

                        &:hover {
                            background: #234158;
                            color: #fff;
                            cursor: pointer;

                            span {
                                color: #fff;
                            }
                        }

                        .leftItem {
                            img.recImg {
                                width: 22px;
                                height: 22px;
                                border-radius: 50%;
                                margin-right: 0.5rem;
                            }

                            i {
                                font-size: 16px;
                                width: 22px;
                                color: #565d63;
                            }
                        }

                        span {
                            display: inline-block;
                            width: 35px;
                            height: 25px;
                            text-align: center;
                            line-height: 25px;
                            color: #2c71b2;
                            border-radius: 3px;
                            font-size: 13px;
                        }
                    }

                    li:last-child {
                        border-bottom: none;
                    }
                }
                &:nth-child(01){
                    margin-left: 0;
                }
            }
        }

        .dk-jobsLeftBottomBoxCon {
            display: flex;

            .dk-jobsLeftBoxCon.dk-jobsLeftbottomBoxCols {
                .dk-jobsLeftTopBoxCols {
                    margin: 0;
                }
            }

            .dk-jobsLeftbottomBoxCols {
                margin: 0 8px;
                width: 100%;
                position: relative;
                &:nth-child(01){
                    margin-left: 0;
                }
                .headerCon {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border: 1px solid #dadada;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #6c757d;

                    .srchIcon {
                        i {
                            font-size: 14px;
                        }

                        .dk-jobssearchFilter {
                            .dropdown {
                                position: inherit;

                                a {
                                    i {
                                        color: #6c757d;
                                    }
                                }

                                .dropdown-menu.show {
                                    transform: inherit !important;
                                    border-radius: 0;
                                    padding-top: 0;
                                    min-height: 230px;
                                    max-height: 230px;
                                    overflow-y: auto;
                                    overflow-x: hidden;
                                    right: inherit !important;
                                    left: 0 !important;
                                    top: 29px !important;
                                    width: 100%;

                                    input.form-control {
                                        border-radius: 0;
                                        border: none;
                                        border-bottom: 1px solid #ddd;
                                    }

                                    input.form-control:focus {
                                        outline: 0;
                                        box-shadow: none;
                                        border-bottom: 1px solid #333;
                                    }
                                }

                                .dropdown-menu {
                                    .dropdown-item {
                                        padding: 5px 10px;
                                        background: #fff !important;
                                        min-height: 28px;
                                        font-size: 12px;
                                        color: #333;
                                        white-space: nowrap;

                                        span.counterNum {
                                            float: right;
                                            background: #3a506b;
                                            color: #fff !important;
                                            padding: 2px 5px;
                                            min-width: 30px;
                                            text-align: center;
                                            font-size: 11px;
                                        }

                                    }
                                }

                                .dropdown-menu::-webkit-scrollbar {
                                    width: 8px;
                                }

                                .dropdown-menu::-webkit-scrollbar-track {
                                    background: #fff;
                                }

                                .dropdown-menu::-webkit-scrollbar-thumb {
                                    background-color: #eaeaea;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }

                    .title {
                        font-size: 13px;
                    }

                    .totalCount {
                        font-size: 13px;
                    }
                }

                .listsContainer {
                    min-height: 367px;
                    max-height: 367px;
                    overflow-y: auto;
                    background-color: #eaeaea;

                    ul {
                        padding-left: 0;
                        list-style: none;
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        flex-wrap: wrap;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        background-color: #eaeaea;
                        border-bottom: none;
                        border-top: none;

                        li {
                            width: 100%;
                            border-bottom: none;
                            padding: 4px 10px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 13px;
                            cursor: pointer;

                            &:hover {
                                background: #234158;
                                color: #fff;

                                span {
                                    color: #fff;
                                }
                            }

                            .leftItem {
                                img.recImg {
                                    width: 22px;
                                    height: 22px;
                                    border-radius: 50%;
                                    margin-right: 0.5rem;
                                }
                            }

                            span {
                                display: inline-block;
                                width: 35px;
                                height: 25px;
                                text-align: center;
                                line-height: 25px;
                                color: #2c71b2;
                                border-radius: 3px;
                                font-size: 13px;
                            }
                        }

                        li:first-child {
                            border-top: none;
                        }

                        :last-child {
                            border-bottom: none;
                        }
                    }
                }

                .listsContainer::-webkit-scrollbar {
                    width: 10px;
                }

                .listsContainer::-webkit-scrollbar-track {
                    background: #eaeaea;
                }

                .listsContainer::-webkit-scrollbar-thumb {
                    background-color: #ddd;
                    border-radius: 4px;
                }
            }
        }

        .dk-jobsRightBoxCon {
            display: flex;

            .dk-jobsRightBoxCols {
                margin: 0 8px;
                width: 100%;
                position: relative;

                .headerCon {
                    background-color: #eaeaea;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 7px 10px;
                    border: 1px solid #dadada;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #6c757d;

                    .srchIcon {
                        i {
                            font-size: 14px;
                        }

                        .dk-jobssearchFilter {
                            .dropdown {
                                position: inherit;

                                a {
                                    i {
                                        color: #6c757d;
                                    }
                                }

                                .dropdown-menu.show {
                                    transform: inherit !important;
                                    border-radius: 0;
                                    padding-top: 0;
                                    min-height: 470px;
                                    max-height: 470px;
                                    overflow-y: auto;
                                    overflow-x: hidden;
                                    right: inherit !important;
                                    left: 0 !important;
                                    top: 29px !important;
                                    width: 100%;

                                    input.form-control {
                                        border-radius: 0;
                                        border: none;
                                        border-bottom: 1px solid #ddd;
                                    }

                                    input.form-control:focus {
                                        outline: 0;
                                        box-shadow: none;
                                        border-bottom: 1px solid #333;
                                    }
                                }

                                .dropdown-menu {
                                    .dropdown-item {
                                        padding: 5px 10px;
                                        background: #fff !important;
                                        min-height: 28px;
                                        font-size: 12px;
                                        color: #333;
                                        white-space: nowrap;
                                        text-transform: capitalize;

                                        span.counterNum {
                                            float: right;
                                            background: #3a506b;
                                            color: #fff !important;
                                            padding: 2px 5px;
                                            min-width: 30px;
                                            text-align: center;
                                            font-size: 11px;
                                        }

                                    }
                                }

                                .dropdown-menu::-webkit-scrollbar {
                                    width: 8px;
                                }

                                .dropdown-menu::-webkit-scrollbar-track {
                                    background: #fff;
                                }

                                .dropdown-menu::-webkit-scrollbar-thumb {
                                    background-color: #eaeaea;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }

                    .title {
                        font-size: 14px;
                    }

                    .totalCount {
                        font-size: 14px;
                    }
                }

                .listsContainer {
                    min-height: 510px;
                    max-height: 510px;
                    overflow-y: auto;

                    ul {
                        list-style: none;
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        flex-wrap: wrap;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        background-color: #eaeaea;
                        border: 1px solid #dadada;
                        border-top: none;
                        white-space: nowrap;
                        padding-left: 0;

                        li {
                            width: 100%;
                            border-bottom: 1px solid #dadada;
                            padding: 7px 10px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 12px;

                            .leftItem {
                                img.recImg {
                                    width: 22px;
                                    height: 22px;
                                    border-radius: 50%;
                                    margin-right: 0.5rem;
                                }
                            }

                            span {
                                display: inline-block;
                                width: 35px;
                                height: 25px;
                                text-align: center;
                                line-height: 25px;
                                color: #2c71b2;
                                border-radius: 3px;
                                font-size: 13px;
                            }
                        }

                        li:first-child {
                            border-top: none;
                        }

                        :last-child {
                            border-bottom: none;
                        }
                    }
                }

                .listsContainer::-webkit-scrollbar {
                    width: 10px;
                }

                .listsContainer::-webkit-scrollbar-track {
                    background: #eaeaea;
                }

                .listsContainer::-webkit-scrollbar-thumb {
                    background-color: #ddd;
                    border-radius: 4px;
                }
            }
        }
    }
    .dk-jobsuMMBoxCon::-webkit-scrollbar {
        width: 10px;
        cursor: pointer !important;
    }

    .dk-jobsuMMBoxCon::-webkit-scrollbar-track {
        background: #F1F1F1;
        cursor: pointer !important;
    }

    .dk-jobsuMMBoxCon::-webkit-scrollbar-thumb {
        background-color: #ddd;
        border-radius: 4px;
        cursor: pointer !important;
    }

    // .dk-jobsuMMBoxCon::-webkit-scrollbar {
    //     display: none;
    // }
}

.headerCon-filterBg {
    background-color: #234158 !important;
    color: #fff !important;
    .dk-dbsuMM-filterHeader{
        .dk-dbsuMM-filterHeader{
            span{
                i{
                    cursor: pointer;
                }
            }
        }
    }
}

.dk-opacity-none {
    opacity: 0;
}